<template>
  <b-container class="synergy">
    <b-row align-v="center" align-h="center">
      <b-col class="image-col" md="6" sm="12" order="2" order-md="1">
        <div class="image-col-inner-container">
          <!-- Fixed image on mobile and tablet -->
          <div class="d-block d-lg-none">
            <b-img-lazy
              fluid
              center
              :src="image.src"
              :alt="image.alt"
            ></b-img-lazy>
          </div>
          <!-- Animated orbital image on desktop -->
          <div class="d-none d-lg-block">
            <div class="synergy-circle-big">
              <div class="synergy-bullet-1"></div>
              <div class="synergy-bullet-2"></div>
              <div class="synergy-bullet-3"></div>
            </div>
            <div class="synergy-circle-medium">
              <div class="synergy-bullet-1"></div>
              <div class="synergy-bullet-2"></div>
            </div>
            <div class="synergy-circle-small">
              <div class="synergy-bullet-1"></div>
            </div>
            <div class="synergy-logo">
              <b-img-lazy :src="require('@mizogoo/assets/img/mizogoo_logo_square_white.png')" alt="mizogoo" />
            </div>
          </div>
        </div>
      </b-col>
      <b-col class="text-col" md="6" sm="12" order="1" order-md="2">
        <div class="inner-container">
          <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
          <div class="heading-container">
            <div v-for="(headingPart, index) in heading" :key="index" class="heading-inner-container">
              <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
            </div>
          </div>
          <div v-scrollanimation="{name: 'fade-up', delay: 250}">
            <p>{{ description }}</p>
            <div class="synergy-list-container">
              <b-row
                v-for="(synergy, index) in synergyList"
                :key="index"
                align-v="center"
                :class="[{'reverse-row': (index % 2 !== 0)}, 'synergy-list-row']"
                no-gutters
              >
                <b-col cols="5" md="6">
                  <div class="synergy-list-feature">
                    {{ synergy.feature }}
                  </div>
                </b-col>
                <b-col cols="2" md="1" class="arrow-col">
                  <b-img-lazy
                    :src="require('@mizogoo/assets/img/front/global/decorations/red_arrow.svg')"
                    alt=""
                    class="red-arrow-up"
                  />
                  <b-img-lazy
                    :src="require('@mizogoo/assets/img/front/global/decorations/red_arrow.svg')"
                    alt=""
                    class="red-arrow-down"
                  />
                </b-col>
                <b-col cols="5" class="benefits-col">
                  <div class="benefit mb-3">{{ synergy.benefits[0] }}</div>
                  <div class="benefit">{{ synergy.benefits[1] }}</div>
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HomeSynergy",
  props: ["subheading", "heading", "description", "synergyList", "image"]
}
</script>

<style lang="scss" scoped>
.synergy {
  margin-bottom: 8rem;

  // Image section
  .image-col-inner-container {
    position: relative;
    height: 100%;

    .synergy-circle-big,
    .synergy-circle-medium,
    .synergy-circle-small {
      border: 3px solid $mizogoo-gray-light;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      border-radius: 50%;
    }

    .synergy-circle-big {
      position: relative;
      width: 500px;
      height: 500px;
      outline: 45px solid #F3F3F3;
      animation: synergy-bullet-big-1 70s linear infinite;
    }
    .synergy-circle-medium {
      width: 380px;
      height: 380px;
      animation: synergy-bullet-big-1 70s linear infinite;
      animation-direction: reverse;
    }
    .synergy-circle-small {
      width: 260px;
      height: 260px;
      animation: synergy-bullet-big-1 70s linear infinite;
    }

    .synergy-bullet-1,
    .synergy-bullet-2,
    .synergy-bullet-3 {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      margin: auto;
      width: 12px;
      height: 12px;
      background: $mizogoo;
      outline: 2px solid $mizogoo;
      outline-offset: 0.1rem;
      border-radius: 50%;
    }

    .synergy-circle-big {
      .synergy-bullet-1 {
        transform: translateX(-250px);
      }
      .synergy-bullet-2 {
        transform: translateX(-80px) translateY(235px);
      }
      .synergy-bullet-3 {
        transform: translateX(205px) translateY(-140px);
      }
    }

    .synergy-circle-medium {
      .synergy-bullet-1 {
        transform: translateX(190px);
      }
      .synergy-bullet-2 {
        transform: translateX(-40px) translateY(185px);
      }
    }

    .synergy-circle-small {
      .synergy-bullet-1 {
        transform: translateX(-130px);
      }
    }

    @keyframes synergy-bullet-big-1 {
      to {
        transform: rotate(360deg);
      }
    }

    .synergy-logo {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 140px;
      background-color: $mizogoo;
      border-radius: 50%;

      img {
        width: 60px;
      }
    }
  }

  // Text section
  .text-col {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-left: 5rem;

    .synergy-list-container {
      .synergy-list-row {
        margin-bottom: 1.5rem;

        .synergy-list-feature {
          padding: 0.4rem 1rem;
          background-color: $mizogoo-gray-light;
          border-radius: 50px;
          font-weight: 500;
        }

        .red-arrow-up,
        .red-arrow-down {
          width: 100%;
          max-width: 60px;
          margin-left: 5px;
        }

        .red-arrow-down {
          -moz-transform: scale(1, -1);
          -webkit-transform: scale(1, -1);
          -o-transform: scale(1, -1);
          -ms-transform: scale(1, -1);
          transform: scale(1, -1);
        }

        .benefits-col {
          padding-left: 1rem;

          .benefit {
            line-height: 1.2;
          }
        }

        &.reverse-row {
          flex-direction: row-reverse;

          .arrow-col {
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
          }

          .benefits-col {
            padding-left: 0;
            padding-right: 1rem;
            text-align: right;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .synergy {
    .text-col {
      padding-left: 15px;
      padding-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .synergy {
    .synergy-list-feature {
      text-align: center;
    }
  }
}
</style>
