<template>
  <b-container class="testimonials">
    <b-row>
      <b-col md="4" sm="12" class="pt-md-4">
        <div class="heading-container">
          <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
          <div
            v-for="(headingPart, index) in heading"
            :key="index"
            class="heading-inner-container"
          >
            <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
          </div>
        </div>
        <div class="partners-container">
          <b-row>
            <b-col v-for="partner in partners" :key="partner.name" cols="4">
              <div class="partner-container">
                <b-img-lazy :src="partner.imageSrc" :alt="partner.name" />
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col md="4" sm="12" class="pl-md-5 pb-md-3">
        <div class="testimonial-photo">
          <b-img-lazy
            :src="testimonials[currentTestimonialId].imageSrc"
            :alt="testimonials[currentTestimonialId].author"
          />
        </div>
      </b-col>
      <b-col md="4" sm="12" class="pl-md-5 pt-5 pt-sm-2 mt-md-5 pb-md-3 pb-md-5">
        <div class="testimonial-container">
          <div class="testimonial-text-container">
            <div class="testimonial-quotation">
              {{ testimonials[currentTestimonialId].quotation }}
            </div>
            <div class="testimonial-author">
              {{ testimonials[currentTestimonialId].author }}
            </div>
            <div class="testimonial-position">
              {{ testimonials[currentTestimonialId].position }}
            </div>
          </div>
          <div class="testimonial-arrow-container">
            <i class="fas fa-arrow-left mr-2" @click="previousTestimonial"></i>
            <i class="fas fa-arrow-right" @click="nextTestimonial"></i>
          </div>
        </div>
      </b-col>
    </b-row>
    
    <b-row align-h="between" class="key-numbers">
      <b-col
        v-for="(number, index) in keyNumbers"
        :key="index"
        cols="6"
        sm="auto"
      >
        <div class="inner-container">
          <p class="number">{{ number.number }}</p>
          <p class="caption">{{ number.caption }}</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HomeTestimonial",
  props: ["subheading", "heading", "partners", "testimonials", "keyNumbers"],
  data() {
    return {
      currentTestimonialId: 0,
    }
  },
  methods: {
    previousTestimonial() {
      if (this.currentTestimonialId === 0) {
        this.currentTestimonialId = this.testimonials.length - 1;
      } else {
        this.currentTestimonialId--;
      }
    },
    nextTestimonial() {
      if (this.currentTestimonialId + 1 === this.testimonials.length) {
        this.currentTestimonialId = 0;
      } else {
        this.currentTestimonialId++;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.testimonials {
  padding-top: 5rem;
  padding-bottom: 5rem;
  
  .partners-container {
    padding-top: 4rem;
    padding-bottom: 2rem;

    .partner-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      background-color: #e9e9e9;
      border-radius: 10px;

      img {
        height: 40px;
        width: auto;
        max-width: 80%;
        object-fit: contain;
      }
    }
  }

  .testimonial-photo {
    height: 100%;
    max-height: 500px;
    border-radius: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }

  .testimonial-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .testimonial-text-container {
      .testimonial-quotation {
        position: relative;
        margin-bottom: 2rem;
        z-index: 1;

        &::before {
          content: url(../../../../assets/img/front/home/quote.png);
          position: absolute;
          left: -35px;
          top: -25px;
          z-index: -1;
        }
      }

      .testimonial-author {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      .testimonial-position {
        margin-bottom: 2rem;
      }
    }

    .testimonial-arrow-container {
      .fa-arrow-left,
      .fa-arrow-right {
        padding: 0.7rem;
        background-color: $mizogoo-gray-light;
        border-radius: 50%;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;

        &:hover {
          background-color: color.adjust($mizogoo-gray-light, $lightness: -5%);
        }
      }
    }
  }

  .key-numbers {
    margin-top: 3rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    
      .inner-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .number {
          font-size: 2rem !important;
          font-weight: 600 !important;
        }

        .caption {
          margin-bottom: 0;
        }
      }
    }
  }

  @media screen and (max-width: 576px) {
    .partners-container {
      padding-top: 2rem;
    }

    .testimonial-container {
      .testimonial-quotation {
        padding-left: 2rem;

        &::before {
          left: 0;
        }
      }
    }

    .key-numbers {
      .inner-container {
        align-items: center!important;
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important;
        text-align: center;
      }
    }
  }
}
</style>
