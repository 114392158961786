import ArticleCategoryAPI from "../api/article_category";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All, One
    isLoading: false,
    error: null,
    // Data
    articleCategories: []
  },
  getters: {
    // Fetch All
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
    hasError(state) {
      return state.error !== null;
    },
    // Data
    articleCategories(state) {
      return state.articleCategories;
    },
    hasArticleCategory(state) {
      return state.articleCategories.length > 0;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoading = true;
      state.error = null;
      state.articleCategories = [];
    },
    [FETCH_ALL_SUCCESS](state, articleCategories) {
      state.isLoading = false;
      state.error = null;
      state.articleCategories = articleCategories;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.articleCategories = [];
    },
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await ArticleCategoryAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.articleCategories);
          return response.data.articleCategories;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await ArticleCategoryAPI.findOne(payload.articleCategory);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.articleCategory;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
  }
}
