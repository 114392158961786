<template>
  <div>
    <home-top-banner
      :heading="topBanner.heading"
      :description="topBanner.description"
      :buttons="topBanner.buttons"
    />

    <home-service
      :subheading="services.subheading"
      :heading="services.heading"
      :services="services.services"
    />

    <home-advantage
      :subheading="advantages.subheading"
      :heading="advantages.heading"
      :description="advantages.description"
      :advantageList="advantages.advantageList"
      :image="advantages.image"
    />

    <home-synergy
      :subheading="synergy.subheading"
      :heading="synergy.heading"
      :description="synergy.description"
      :synergyList="synergy.synergyList"
      :image="synergy.image"
    />

    <products-overview />

    <home-testimonial
      :subheading="this.testimonials.subheading"
      :heading="this.testimonials.heading"
      :partners="this.testimonials.partners"
      :testimonials="this.testimonials.testimonials"
      :keyNumbers="this.testimonials.keyNumbers"
    />

    <div class="separator"></div>

    <home-faq
      :subheading="this.faq.subheading"
      :heading="this.faq.heading"
      :description="this.faq.description"
      :questions="this.faq.questions"
    />
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import HomeTopBanner from "./HomeTopBanner.vue";
import HomeService from "./HomeService.vue";
import HomeAdvantage from "./HomeAdvantage.vue";
import HomeSynergy from "./HomeSynergy.vue";
import ProductsOverview from "@mizogoo/components/front/layout/products/product/ProductsOverview";
import HomeTestimonial from "./HomeTestimonial.vue";
import HomeFaq from "./HomeFAQ.vue";

export default {
  mixins: [MetaMixin],
  name: "HomePage",
  components: {
    HomeTopBanner,
    HomeService,
    HomeAdvantage,
    HomeSynergy,
    ProductsOverview,
    HomeTestimonial,
    HomeFaq,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.home.title");
    },
    metaDescription() {
      return this.$t("front.metadata.home.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.home.topBanner.heading"),
        description: this.$t("front.home.topBanner.description"),
        buttons: [
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
          {
            label: this.$t("front.navbar.askDemo"),
            outline: true,
          },
        ],
      };
    },
    services() {
      return {
        subheading: this.$t("front.home.services.subheading"),
        heading: this.$tm("front.home.services.heading"),
        services: [
          {
            id: 0,
            image: {
              fixedSrc: require("@mizogoo/assets/img/front/home/services/create.png"),
              animatedSrc: require("@mizogoo/assets/img/front/home/services/create.gif"),
              backgroundSrc: require("@mizogoo/assets/img/front/home/services/circles1.png"),
              alt: "",
            },
            text: this.$t("front.home.services.services[0]"),
            button: {
              link: "create",
              label: this.$t("front.home.services.buttonLabel"),
            },
          },
          {
            id: 1,
            image: {
              fixedSrc: require("@mizogoo/assets/img/front/home/services/qrcode.png"),
              animatedSrc: require("@mizogoo/assets/img/front/home/services/qrcode.gif"),
              backgroundSrc: require("@mizogoo/assets/img/front/home/services/circles2.png"),
              alt: "",
            },
            text: this.$t("front.home.services.services[1]"),
            button: {
              link: "pdf_menu",
              label: this.$t("front.home.services.buttonLabel"),
            },
          },
          {
            id: 2,
            image: {
              fixedSrc: require("@mizogoo/assets/img/front/home/services/app.png"),
              animatedSrc: require("@mizogoo/assets/img/front/home/services/app.gif"),
              backgroundSrc: require("@mizogoo/assets/img/front/home/services/circles3.png"),
              alt: "",
            },
            text: this.$t("front.home.services.services[2]"),
            button: {
              link: "app",
              label: this.$t("front.home.services.buttonLabel"),
            },
          },
          {
            id: 3,
            image: {
              fixedSrc: require("@mizogoo/assets/img/front/home/services/translation.png"),
              animatedSrc: require("@mizogoo/assets/img/front/home/services/translation.gif"),
              backgroundSrc: require("@mizogoo/assets/img/front/home/services/circles4.png"),
              alt: "",
            },
            text: this.$t("front.home.services.services[3]"),
            button: {
              link: "translation",
              label: this.$t("front.home.services.buttonLabel"),
            },
          },
        ],
      };
    },
    advantages() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/home/mizogoo_presentation_youtube_thumbnail.png"),
          alt: this.$t("front.home.advantages.imageAlt"),
        },
        subheading: this.$t("front.home.advantages.subheading"),
        heading: this.$tm("front.home.advantages.heading"),
        description: this.$t("front.home.advantages.description"),
        advantageList: this.$tm("front.home.advantages.advantageList"),
      };
    },
    synergy() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/home/synergy_orbital_dots.png"),
          alt: this.$t("front.home.synergy.imageAlt"),
        },
        subheading: this.$t("front.home.synergy.subheading"),
        heading: this.$tm("front.home.synergy.heading"),
        description: this.$t("front.home.synergy.description"),
        synergyList: [
          {
            feature: this.$t("front.home.synergy.synergyList[0].feature"),
            benefits: this.$tm("front.home.synergy.synergyList[0].benefits"),
          },
          {
            feature: this.$t("front.home.synergy.synergyList[1].feature"),
            benefits: this.$tm("front.home.synergy.synergyList[1].benefits"),
          },
        ],
      };
    },
    testimonials() {
      return {
        subheading: this.$t("front.home.testimonials.subheading"),
        heading: this.$tm("front.home.testimonials.heading"),
        testimonials: [
          {
            id: 0,
            quotation: this.$t("front.home.testimonials.testimonials[0].quotation"),
            author: this.$t("front.home.testimonials.testimonials[0].author"),
            position: this.$t("front.home.testimonials.testimonials[0].position"),
            imageSrc: require("@mizogoo/assets/img/front/home/partners/testimonial_fromagivores.png"),
          },
          {
            id: 1,
            quotation: this.$t("front.home.testimonials.testimonials[1].quotation"),
            author: this.$t("front.home.testimonials.testimonials[1].author"),
            position: this.$t("front.home.testimonials.testimonials[1].position"),
            imageSrc: require("@mizogoo/assets/img/front/home/partners/testimonial_mercure_hotel.png"),
          },
        ],
        partners: [
          {
            name: "Sysco",
            imageSrc: require("@mizogoo/assets/img/front/home/partners/sysco_logo.png"),
          },
          {
            name: "Berry",
            imageSrc: require("@mizogoo/assets/img/front/home/partners/berry_logo.png"),
          },
          {
            name: "Ladurée",
            imageSrc: require("@mizogoo/assets/img/front/home/partners/laduree_logo.png"),
          },
          {
            name: "Les Fromagivores",
            imageSrc: require("@mizogoo/assets/img/front/home/partners/fromagivores_logo.png"),
          },
          {
            name: "Distriboissons",
            imageSrc: require("@mizogoo/assets/img/front/home/partners/distriboissons_logo.png"),
          },
          {
            name: "Mercure Hotel",
            imageSrc: require("@mizogoo/assets/img/front/home/partners/mercure_logo.png"),
          },
        ],
        keyNumbers: [
          {
            number: this.$t("front.keyNumbers[0].number"),
            caption: this.$t("front.keyNumbers[0].caption"),
          },
          {
            number: this.$t("front.keyNumbers[1].number"),
            caption: this.$t("front.keyNumbers[1].caption"),
          },
          {
            number: this.$t("front.keyNumbers[2].number"),
            caption: this.$t("front.keyNumbers[2].caption"),
          },
          {
            number: this.$t("front.keyNumbers[3].number"),
            caption: this.$t("front.keyNumbers[3].caption"),
          },
        ],
      };
    },
    faq() {
      let questions = [];
      let questionNb = this.$tm("front.home.faq.questions").length - 1;
      for (let i = 0; i <= questionNb; i++) {
        let question = {
          id: i,
          question: this.$tm("front.home.faq.questions")[i].question,
          answer: this.$tm("front.home.faq.questions")[i].answer,
        };
        questions.push(question);
      }

      return {
        subheading: this.$t("front.home.faq.subheading"),
        heading: this.$tm("front.home.faq.heading"),
        questions: questions,
      };
    },
  },
};
</script>
