<template>
  <div
    @mouseover="setActiveFeatureId(id)"
    :class="['app-feature-item', alignment == 'left' ? 'align-left' : 'align-right']"
  >
    <div class="feature-id" :class="{ active: activeFeatureId === id }">
      {{ id }}
    </div>
    <h4>{{ heading }}</h4>
    <p>{{ description }}</p>
    <router-link
      :to="{ name: link }"
      class="feature-link"
      :class="{ active: activeFeatureId === id }"
    >
      <span>
        {{ $t("front.app.features.featuresLink") }}
      </span>
      <i class="fas fa-arrow-right pl-2"></i>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "AppFeatureItem",
  props: ["id", "activeFeatureId", "heading", "description", "link", "alignment"],
  emits: ["setActiveFeatureId"],
  methods: {
    setActiveFeatureId(id) {
      this.$emit("setActiveFeatureId", id);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-feature-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem 1rem;
  text-decoration: none;

  &.align-right {
    align-items: flex-end;
    text-align: right;
  }

  &.align-left {
    align-items: flex-start;
    text-align: left;
  }

  .feature-id {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: 0.8rem;
    font-size: 1.5rem;
    font-weight: 600;
    background-color: $mizogoo-gray-light;
    color: $mizogoo-gray-dark;
    border-radius: 5px;
    transition: 0.2s ease-in-out;

    &.active {
      background-color: $primary;
      color: $white;
    }
  }

  h4 {
    margin-bottom: 0.6rem;
    font-size: 1.2rem !important;
  }

  p {
    margin-bottom: 0.6rem;
    font-size: 1rem !important;
    font-weight: 400;
  }

  .feature-link {
    color: $mizogoo-gray-dark;
    transition: 0.2s ease-in-out;

    &.active {
      color: $primary;
    }
  }
}

@media screen and (max-width: 992px) {
  .app-feature-item {
    align-items: center !important;
    text-align: center !important;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
</style>
