<template>
  <div>
    <b-row class="pb-5" ref="articlesContainer">
      <b-col
        v-for="article in filteredArticles"
        :key="article.id"
        lg="4"
        md="6"
        class="d-flex align-items-stretch"
      >
        <ArticleCard
          :article="article"
          :articleCategoryName="getArticleCategoryName(article)"
        />
      </b-col>
    </b-row>
    <div v-if="!allArticlesLoaded" ref="scrollObserver"></div>
  </div>
</template>

<script>
import ArticleCard from "@mizogoo/components/front/pages/BlogPage/ArticleCard";

export default {
  name: "ArticlesContainer",
  props: ["filteredArticles", "articleCategoryName", "allArticlesLoaded"],
  emits: ["loadNextArticles"],
  components: {
    ArticleCard,
  },
  data() {
    return {
      loadNextArticlesObserver: null,
    };
  },
  methods: {
    getArticleCategoryName(article) {
      return this.articleCategoryName(article);
    },
    handleIntersect(entry) {
      if (entry.isIntersecting) {
        this.$emit("loadNextArticles");
      }
    },
  },
  mounted() {
    this.loadNextArticlesObserver = new IntersectionObserver(
      (entries) => {
        this.handleIntersect(entries[0]);
      },
      {
        root: null,
        threshold: 0,
      }
    );

    this.loadNextArticlesObserver.observe(this.$refs.scrollObserver);
  },
  beforeUnmount() {
    this.loadNextArticlesObserver.disconnect();
  },
};
</script>
