// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subheading[data-v-602ec5f0] {
  margin-top: 1rem;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/LegalPage/Index.vue","webpack://./Index.vue"],"names":[],"mappings":"AAKA;EACE,gBAAA;EACA,0BAAA;ACJF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.subheading {\n  margin-top: 1rem;\n  text-decoration: underline;\n}\n",".subheading {\n  margin-top: 1rem;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
