import GrapeApi from "../api/grape";
import Vue from "vue";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",
  
  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isLoadingAction: false,
    error: null,
    grapes: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isLoadingAction(state) {
      return state.isLoadingAction;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasGrapes(state) {
      return state.grapes.length > 0;
    },
    grapes(state) {
      return state.grapes;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoading = true;
      state.error = null;
      state.grapes = [];
    },
    [FETCH_ALL_SUCCESS](state, grapes) {
      state.isLoading = false;
      state.error = null;
      state.grapes = grapes;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.grapes = [];
    },
    // Creating
    [CREATING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [CREATING_SUCCESS](state, grape) {
      state.isLoadingAction = false;
      state.error = null;
      state.grapes.push(grape);
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Updating
    [UPDATING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [UPDATING_SUCCESS](state, grape) {
      state.isLoadingAction = false;
      state.error = null;
      const grapeToUpdate = state.grapes.find(
        (element) => element.id === grape.id
      );
      grapeToUpdate.translations = grape.translations;
    },
    [UPDATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Removing
    [REMOVING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [REMOVING_SUCCESS](state, grapeId) {
      state.isLoadingAction = false;
      state.error = null;
      state.grapes = state.grapes.filter((element) => element.id !== grapeId);
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await GrapeApi.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.grapes);
          return response.data.grapes;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await GrapeApi.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.grape);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await GrapeApi.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.grape);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await GrapeApi.remove(payload.id);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.id);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
  },
};
