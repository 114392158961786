// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wide-popover {
  min-width: 350px;
  font-weight: 300;
}
.wide-popover ul {
  margin-top: 0.5rem;
  list-style: initial !important;
  padding-left: 20px;
}
.indicative-ex-1 {
  font-weight: 300;
}
.indicative-ex-2 {
  margin-top: 0.5rem;
  font-weight: 500;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/ProductPage/Customizable.vue","webpack://./Customizable.vue"],"names":[],"mappings":"AAKA;EACE,gBAAA;EACA,gBAAA;ACJF;ADME;EACE,kBAAA;EACA,8BAAA;EACA,kBAAA;ACJJ;ADQA;EACE,gBAAA;ACLF;ADQA;EACE,kBAAA;EACA,gBAAA;EACA,kBAAA;ACLF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.wide-popover {\n  min-width: 350px;\n  font-weight: 300;\n\n  ul {\n    margin-top: 0.5rem;\n    list-style: initial !important;\n    padding-left: 20px;\n  }\n}\n\n.indicative-ex-1 {\n  font-weight: 300;\n}\n\n.indicative-ex-2 {\n  margin-top: 0.5rem;\n  font-weight: 500;\n  font-style: italic;\n}\n",".wide-popover {\n  min-width: 350px;\n  font-weight: 300;\n}\n.wide-popover ul {\n  margin-top: 0.5rem;\n  list-style: initial !important;\n  padding-left: 20px;\n}\n\n.indicative-ex-1 {\n  font-weight: 300;\n}\n\n.indicative-ex-2 {\n  margin-top: 0.5rem;\n  font-weight: 500;\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
