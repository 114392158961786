import LocaleFrontAPI from "../api/locale_front";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    localeFronts: [],
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasLocaleFronts (state) {
      return state.localeFronts.length > 0;
    },
    localeFronts (state) {
      return state.localeFronts;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.localeFronts = [];
    },
    [FETCH_ALL_SUCCESS] (state, localeFronts) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.localeFronts = localeFronts;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.localeFronts = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await LocaleFrontAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.localeFronts);
          return response.data.localeFronts;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
  }
}
