<template>
  <b-form-group
    :id="id + '-input-group'"
    :label="name"
    :label-for="id"
    :label-class="inputRequired ? 'required' : ''"
  >
    <b-form-checkbox-group
      :id="id"
      :name="id"
      v-model="inputValue"
      :class="{ 'is-invalid': errorMessage }"
    >
      <b-form-checkbox v-for="(choice, index) in choices" :key="index" :value="choice.id">
        <span v-if="choice.text != ''">{{ choice.text }}</span>
        <span v-if="choice.name != ''">{{ choice.name }}</span>
      </b-form-checkbox>
    </b-form-checkbox-group>
    <b-form-text :id="id + '-help-block'">
      {{ help }}
    </b-form-text>
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "FormCheckbox",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    help: {
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    choices: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: Array,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(
      "formCheckbox-" + props.id,
      props.rules,
      {
        label: props.name,
        syncVModel: true,
        validateOnMount: false,
        validateOnValueUpdate: false,
      }
    );

    return {
      inputValue,
      errorMessage,
    };
  },
  computed: {
    inputRequired() {
      if (typeof this.rules["required"] !== "undefined") {
        if (this.rules["required"] === true) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
