<template>
  <b-container class="feature-3-blocks text-center">
    <b-row align-v="center" align-h="center" class="main-explanations">
      <b-col>
        <h3>{{ subheading }}</h3>
        <h2>{{ heading }}</h2>
        <p>{{ description }}</p>
      </b-col>
    </b-row>

    <b-row class="secondary-explanations">
      <b-col v-for="col in cols" :key="col.id" lg="4" md="12">
        <feature-small-block
          :id="col.id"
          :icon="col.icon"
          :heading="col.heading"
          :description="col.description"
          :link="col.link"
        ></feature-small-block>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col class="text-center">
        <p>{{ extraInfo }}</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FeatureSmallBlock from "./FeatureSmallBlock";

export default {
  name: "Feature3Blocks",
  props: ["subheading", "heading", "description", "cols", "extraInfo"],
  components: {
    FeatureSmallBlock
  }
};
</script>

<style lang="scss" scoped>
.feature-3-blocks {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.main-explanations {
  margin-bottom: 1rem;
}
.main-explanations p {
  max-width: 920px;
  margin: auto;
}
.secondary-explanations > div {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.secondary-explanations p {
  font-size: 1rem;
}
</style>
