import WineColor from "../api/wine_color";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    wineColors: [],
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasWineColors (state) {
      return state.wineColors.length > 0;
    },
    wineColors (state) {
      return state.wineColors;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.wineColors = [];
    },
    [FETCH_ALL_SUCCESS] (state, wineColors) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.wineColors = wineColors;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.wineColors = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await WineColor.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.wineColors);
          return response.data.wineColors;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
  }
}
