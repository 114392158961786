<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <!-- Image decoration -->
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <!-- Image -->
      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-text-left
      :image="this.featureTextLeft.image"
      :subheading="this.featureTextLeft.subheading"
      :heading="this.featureTextLeft.heading"
      :description="this.featureTextLeft.description"
    ></feature-text-left>

    <b-container class="main-explanations mb-3">
      <b-row>
        <b-col>
          <h3>{{ this.features.subheading }}</h3>
          <h2>{{ this.features.heading }}</h2>
          <p>{{ this.features.description }}</p>
        </b-col>
      </b-row>
    </b-container>

    <app-feature-grid :items="this.features.features"></app-feature-grid>

    <!-- Empty div which is used to create an offset when clicking on
    a link containing an anchor to the highlighted block below -->
    <div id="demo" class="pt-5 pb-5"></div>

    <highlighted-block
      :subheading="this.highlight.subheading"
      :heading="this.highlight.heading"
      :description="this.highlight.description"
      :image="this.highlight.image"
    ></highlighted-block>

    <banner
      :image="this.banner.image"
      :subheading="this.banner.subheading"
      :heading="this.banner.heading"
      :description="this.banner.description"
      :extraInfo="this.banner.extraInfo"
      :button="this.banner.button"
      :overlay="this.banner.overlay"
    ></banner>

    <b-container class="how-to-use">
      <b-row>
        <b-col>
          <div class="heading-container">
            <div
              v-for="(headingPart, index) in howToUse.heading"
              :key="index"
              class="heading-inner-container"
            >
              <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
            </div>
          </div>
          <p v-scrollanimation="{ name: 'fade-up' }">{{ howToUse.description }}</p>
        </b-col>
      </b-row>
      <b-row class="how-to-use-steps" align-h="center">
        <b-col
          md="4"
          v-for="step in howToUse.steps"
          :key="step.id"
          class="how-to-use-step"
        >
          <b-img :src="step.image.src" :alt="step.image.alt"></b-img>
          <h4>{{ step.heading }}</h4>
          <p>{{ step.description }}</p>
        </b-col>
      </b-row>
    </b-container>

    <div class="separator"></div>

    <features-presentation-video></features-presentation-video>

    <key-numbers></key-numbers>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import AppFeatureGrid from "@mizogoo/components/front/layout/features/appFeatures/AppFeatureGrid";
import HighlightedBlock from "@mizogoo/components/front/layout/HighlightedBlock";
import Banner from "@mizogoo/components/front/layout/Banner";
import FeaturesPresentationVideo from "@mizogoo/components/front/layout/features/FeaturesPresentationVideo";
import KeyNumbers from "@mizogoo/components/front/layout/KeyNumbers";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "AppPage",
  components: {
    TopBanner,
    FeatureTextLeft,
    AppFeatureGrid,
    HighlightedBlock,
    Banner,
    FeaturesPresentationVideo,
    KeyNumbers,
    InternalLinks,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.app.title");
    },
    metaDescription() {
      return this.$t("front.metadata.app.description");
    },
    // Retrieves menu intégration product id based on its reference
    getMenuIntegrationProductId() {
      let res = null;
      let products = this.$store.getters["product/products"];
      if (products != undefined) {
        let menuIntegrationProduct = products.find((item) => item.reference == "ccdm");
        if (menuIntegrationProduct != undefined) {
          res = menuIntegrationProduct.id;
        }
      }
      return res;
    },
    topBanner() {
      return {
        heading: this.$tm("front.app.topBanner.heading"),
        description: this.$t("front.app.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/app/banner_app.jpg"),
          alt: this.$t("front.app.topBanner.imageAlt"),
        },
        imageDecoration: require("@mizogoo/assets/img/front/app/banner_app_animation_text.gif"),
        buttons: [
          {
            link: "app",
            anchor: "#demo",
            label: this.$t("front.navbar.tryApp"),
          },
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextLeft() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/digital_menu_app.png"),
          alt: this.$t("front.app.featureTextLeft.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.app.featureTextLeft.subheading"),
        heading: this.$tm("front.app.featureTextLeft.heading"),
        description: this.$t("front.app.featureTextLeft.description"),
      };
    },
    features() {
      return {
        subheading: this.$t("front.app.features.subheading"),
        heading: this.$tm("front.app.features.heading"),
        description: this.$t("front.app.features.description"),
        features: {
          cols: [
            {
              colItems: [
                {
                  id: 1,
                  heading: this.$tm("front.app.features.features[0].heading"),
                  description: this.$t("front.app.features.features[0].description"),
                  link: "app_enriched",
                },
                {
                  id: 2,
                  heading: this.$tm("front.app.features.features[1].heading"),
                  description: this.$t("front.app.features.features[1].description"),
                  link: "app_enriched",
                },
                {
                  id: 3,
                  heading: this.$tm("front.app.features.features[2].heading"),
                  description: this.$t("front.app.features.features[2].description"),
                  link: "app_winepairing",
                },
              ],
            },
            {
              colItems: [
                {
                  id: 0,
                  images: [
                    {
                      featureId: 1,
                      src: require("@mizogoo/assets/img/front/app/features/app_features_enriched.png"),
                      alt: this.$t("front.app.features.features[0].imageAlt"),
                    },
                    {
                      featureId: 2,
                      src: require("@mizogoo/assets/img/front/app/features/app_features_highlighted_items.png"),
                      alt: this.$t("front.app.features.features[1].imageAlt"),
                    },
                    {
                      featureId: 3,
                      src: require("@mizogoo/assets/img/front/app/features/app_features_winepairing.png"),
                      alt: this.$t("front.app.features.features[2].imageAlt"),
                    },
                    {
                      featureId: 4,
                      src: require("@mizogoo/assets/img/front/app/features/app_features_translation.png"),
                      alt: this.$t("front.app.features.features[3].imageAlt"),
                    },
                    {
                      featureId: 5,
                      src: require("@mizogoo/assets/img/front/app/features/app_features_allergens.png"),
                      alt: this.$t("front.app.features.features[4].imageAlt"),
                    },
                    {
                      featureId: 6,
                      src: require("@mizogoo/assets/img/front/app/features/app_features_games.png"),
                      alt: this.$t("front.app.features.features[5].imageAlt"),
                    },
                  ],
                  main: true,
                },
              ],
            },
            {
              colItems: [
                {
                  id: 4,
                  heading: this.$tm("front.app.features.features[3].heading"),
                  description: this.$t("front.app.features.features[3].description"),
                  link: "app_enriched",
                },
                {
                  id: 5,
                  heading: this.$tm("front.app.features.features[4].heading"),
                  description: this.$t("front.app.features.features[4].description"),
                  link: "app_enriched",
                },
                {
                  id: 6,
                  heading: this.$tm("front.app.features.features[5].heading"),
                  description: this.$t("front.app.features.features[5].description"),
                  link: "app_games",
                },
              ],
            },
          ],
        },
      };
    },
    highlight() {
      return {
        subheading: this.$t("front.app.highlight.subheading"),
        heading: this.$tm("front.app.highlight.heading"),
        description: this.$t("front.app.highlight.description"),
        image: {
          src: require("@mizogoo/assets/img/front/app/mizogoo_app_exemple_restaurant.png"),
          alt: this.$t("front.app.highlight.imageAlt"),
          maxHeight: 280,
        },
      };
    },
    banner() {
      return {
        image: {
          background: {
            src: require("@mizogoo/assets/img/front/global/banners/digital_menu_creation.jpg"),
            alt: this.$t("front.app.banner.imageAlt"),
          },
        },
        subheading: this.$t("front.app.banner.subheading"),
        heading: this.$tm("front.app.banner.heading"),
        description: this.$t("front.app.banner.description"),
        extraInfo: this.$t("front.app.banner.extraInfo"),
        button: {
          label: this.$t("front.app.banner.buttonLabel"),
          link: this.getMenuIntegrationProductId != null ? "product" : "catalog",
          param: this.getMenuIntegrationProductId,
        },
        overlay: true,
      };
    },
    howToUse() {
      return {
        heading: this.$tm("front.app.howToUse.heading"),
        description: this.$t("front.app.howToUse.description"),
        steps: [
          {
            id: 0,
            image: {
              src: require("@mizogoo/assets/img/front/app/how-to-use/1_digital_menu_add_data.gif"),
              alt: this.$t("front.app.howToUse.steps[0].imageAlt"),
              maxHeight: 300,
            },
            heading: this.$tm("front.app.howToUse.steps[0].heading"),
            description: this.$t("front.app.howToUse.steps[0].description"),
          },
          {
            id: 1,
            image: {
              src: require("@mizogoo/assets/img/front/app/how-to-use/2_scan_app_qr_code.gif"),
              alt: this.$t("front.app.howToUse.steps[1].imageAlt"),
              maxHeight: 300,
            },
            heading: this.$tm("front.app.howToUse.steps[1].heading"),
            description: this.$t("front.app.howToUse.steps[1].description"),
          },
          {
            id: 2,
            image: {
              src: require("@mizogoo/assets/img/front/app/how-to-use/3_app_features.gif"),
              alt: this.$t("front.app.howToUse.steps[2].imageAlt"),
              maxHeight: 300,
            },
            heading: this.$tm("front.app.howToUse.steps[2].heading"),
            description: this.$t("front.app.howToUse.steps[2].description"),
          },
        ],
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "app_enriched",
            label: this.$t("front.internalLinks.buttons.appEnriched"),
          },
          {
            id: 1,
            link: "app_winepairing",
            label: this.$t("front.internalLinks.buttons.appWinepairing"),
          },
          {
            id: 2,
            link: "app_games",
            label: this.$t("front.internalLinks.buttons.appGames"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.how-to-use {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.main-explanations,
.how-to-use {
  h2,
  h3,
  p {
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
}

.step-container {
  position: relative;

  .step-anchor {
    position: absolute;
    top: -150px;
    display: block;
    visibility: hidden;
  }
}

.how-to-use-steps {
  padding-top: 2rem;
  text-align: center;

  .how-to-use-step {
    padding: 0.7rem 2rem;

    img {
      width: 200px;
      height: auto;
    }

    h4 {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    p {
      font-size: 1rem !important;
      margin-bottom: 0 !important;
    }
  }
}
</style>
