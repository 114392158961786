<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <!-- Shining animation -->
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-text-left
      :image="this.featureTextLeft.image"
      :subheading="this.featureTextLeft.subheading"
      :heading="this.featureTextLeft.heading"
      :description="this.featureTextLeft.description"
      :button="this.featureTextLeft.button"
    ></feature-text-left>

    <feature-text-right
      :image="this.featureTextRight.image"
      :subheading="this.featureTextRight.subheading"
      :heading="this.featureTextRight.heading"
      :description="this.featureTextRight.description"
      :button="this.featureTextRight.button"
    ></feature-text-right>

    <div class="separator"></div>

    <feature-3-blocks
      :subheading="this.feature3Blocks.subheading"
      :heading="this.feature3Blocks.heading"
      :description="this.feature3Blocks.description"
      :cols="this.feature3Blocks.cols"
      :extraInfo="this.feature3Blocks.extraInfo"
    ></feature-3-blocks>

    <div class="separator"></div>

    <features-presentation-video></features-presentation-video>

    <key-numbers></key-numbers>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import FeatureTextRight from "@mizogoo/components/front/layout/features/FeatureTextRight";
import Feature3Blocks from "@mizogoo/components/front/layout/features/3Blocks/Feature3Blocks";
import FeaturesPresentationVideo from "@mizogoo/components/front/layout/features/FeaturesPresentationVideo";
import KeyNumbers from "@mizogoo/components/front/layout/KeyNumbers";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "PrintingPage",
  components: {
    TopBanner,
    FeatureTextLeft,
    FeatureTextRight,
    Feature3Blocks,
    FeaturesPresentationVideo,
    KeyNumbers,
    InternalLinks,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.print.title");
    },
    metaDescription() {
      return this.$t("front.metadata.print.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.print.topBanner.heading"),
        description: this.$t("front.print.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/print/banner_print_restaurant.png"),
          alt: this.$t("front.print.topBanner.imageAlt"),
        },
        imageDecoration: require("@mizogoo/assets/img/front/print/banner_print_animation.gif"),
        buttons: [
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextLeft() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/print/printing_menu_process.png"),
          alt: this.$t("front.print.featureTextRight.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.print.featureTextLeft.subheading"),
        heading: this.$tm("front.print.featureTextLeft.heading"),
        description: this.$t("front.print.featureTextLeft.description"),
      };
    },
    featureTextRight() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/print/restaurant_menu_print_exemple.png"),
          alt: this.$t("front.print.featureTextRight.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.print.featureTextRight.subheading"),
        heading: this.$tm("front.print.featureTextRight.heading"),
        description: this.$t("front.print.featureTextRight.description"),
      };
    },
    feature3Blocks() {
      return {
        subheading: this.$t("front.print.feature3Blocks.subheading"),
        heading: this.$tm("front.print.feature3Blocks.heading"),
        description: this.$t("front.print.feature3Blocks.description"),
        cols: [
          {
            id: 0,
            icon: {
              src: require("@mizogoo/assets/img/front/global/drawings/drawn_france.png"),
              alt: this.$t("front.print.feature3Blocks.cols[id].iconAlt"),
            },
            heading: this.$tm("front.print.feature3Blocks.cols[0].heading"),
            description: this.$t("front.print.feature3Blocks.cols[0].description"),
          },
          {
            id: 1,
            icon: {
              src: require("@mizogoo/assets/img/front/global/drawings/drawn_truck.png"),
              alt: this.$t("front.print.feature3Blocks.cols[1].iconAlt"),
            },
            heading: this.$tm("front.print.feature3Blocks.cols[1].heading"),
            description: this.$t("front.print.feature3Blocks.cols[1].description"),
          },
          {
            id: 2,
            icon: {
              src: require("@mizogoo/assets/img/front/global/drawings/drawn_medal.png"),
              alt: this.$t("front.print.feature3Blocks.cols[2].iconAlt"),
            },
            heading: this.$tm("front.print.feature3Blocks.cols[2].heading"),
            description: this.$t("front.print.feature3Blocks.cols[2].description"),
          },
        ],
        extraInfo: this.$t("front.print.feature3Blocks.extraInfo"),
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "create",
            label: this.$t("front.internalLinks.buttons.create"),
          },
          {
            id: 1,
            link: "printing",
            label: this.$t("front.internalLinks.buttons.print"),
          },
          {
            id: 2,
            link: "analysis",
            label: this.$t("front.internalLinks.buttons.analysis"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ],
      };
    },
  },
};
</script>
