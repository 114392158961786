<template>
  <b-container fluid class="banner-container" :class="{ centered: centered }">
    <div class="absolute-slide" v-scrollanimation="{ name: 'sliding' }"></div>
    <div v-scrollanimation="{ name: 'opacity', delay: 450 }">
      <div v-if="overlay" class="banner-overlay"></div>
      <b-img
        :src="this.image.background.src"
        :alt="this.image.background.alt"
        class="absolute-background-image"
      ></b-img>
      <b-img
        v-if="this.image.animation"
        :src="this.image.animation.src"
        class="absolute-background-image"
      ></b-img>
    </div>

    <b-container class="banner-inner-container">
      <b-row>
        <b-col v-scrollanimation="{ name: 'fade-up', delay: 800 }">
          <h3>{{ subheading }}</h3>
          <h2>{{ heading }}</h2>
          <p>{{ description }}</p>
          <div class="extra-info">{{ extraInfo }}</div>
          <router-link
            v-if="button"
            :to="{ name: button.link, params: { id: button.param } }"
            custom
            v-slot="{ navigate }"
          >
            <span @click="navigate" role="link" class="btn">{{ button.label }}</span>
          </router-link>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "Banner",
  props: [
    "image",
    "subheading",
    "heading",
    "description",
    "extraInfo",
    "button",
    "overlay",
    "centered",
  ],
};
</script>

<style lang="scss" scoped>
.banner-container {
  position: relative;
  padding: 0;
  margin-top: 5rem;
  margin-bottom: 5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  color: #fff;
  overflow: hidden;
  border-radius: 30px;

  .banner-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .banner-inner-container {
    padding-top: 7rem;
    padding-bottom: 7rem;
    position: relative;
    z-index: 2;

    h2,
    p {
      max-width: 800px;
    }

    h3 {
      color: #fff !important;
    }

    .extra-info {
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: uppercase;
    }

    .btn {
      margin-top: 2rem;
      color: #fff;
      border: 2px solid #fff !important;
      border-radius: 2px;
    }

    .btn:hover {
      background-color: #fff;
      color: #151f29;
    }
  }

  &.centered {
    text-align: center;

    .banner-inner-container {
      h2,
      p {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

.absolute-background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.absolute-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $primary;
  z-index: 5;
}
</style>
