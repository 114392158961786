<template>
  <b-form-group
    :id="id + '-input-group'"
    :label="name"
    :label-for="id"
    :label-class="inputRequired ? 'required' : ''"
  >
    <div class="custom-form-select-container">
      <b-form-select
        :id="id"
        :name="id"
        v-model="inputValue"
        :class="{ 'is-invalid': errorMessage }"
        value-field="id"
        text-field="name"
      >
        <b-form-select-option
          v-for="(option, index) in options"
          :key="index"
          :value="option[valueField]"
          :disabled="option[disabledField]"
        >
          {{ option[textField] }}
        </b-form-select-option>
      </b-form-select>
    </div>
    <b-form-text :id="id + '-help-block'">
      {{ help }}
    </b-form-text>
    <b-form-text :id="id + '-live-feedback'" class="invalid-feedback-text">
      {{ errorMessage }}
    </b-form-text>
  </b-form-group>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "FormSelect",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    textField: {
      type: String,
      default: "text",
    },
    valueField: {
      type: String,
      default: "id",
    },
    disabledField: {
      type: String,
      default: "disabled",
    },
    placeholder: {
      type: String,
    },
    help: {
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    modelValue: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(
      "formSelect-" + props.id,
      props.rules,
      {
        label: props.name,
        syncVModel: true,
        validateOnMount: false,
        validateOnValueUpdate: false,
      }
    );

    return {
      inputValue,
      errorMessage,
    };
  },
  computed: {
    inputRequired() {
      if (typeof this.rules["required"] !== "undefined") {
        if (this.rules["required"] === true) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
