<template>
  <!-- Empty message (absolutely nothing created yet) -->
  <div v-if="!isEmptyFilter" class="empty-container">
    <h2>{{ heading }}</h2>
    <p>{{ message }}</p>
    <div class="row align-items-center">
      <template v-if="buttonLabel && buttonLink">
        <div class="m-auto">
          <router-link class="btn btn-primary m-2" :to="{ name: buttonLink }">
            {{ buttonLabel }}
          </router-link>
        </div>
      </template>
      <template v-if="importButtonLabel && importButtonLink">
        <div class="m-auto">
          <router-link class="btn btn-primary m-2" :to="{ name: importButtonLink }">
            {{ importButtonLabel }}
          </router-link>
        </div>
      </template>
    </div>
  </div>

  <!-- Empty filter message (when a search return no result) -->
  <div v-else class="empty-filter-container">
    <h2>{{ heading }}</h2>
    <p>{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: "EmptyMessage",
  props: ["heading", "message", "buttonLabel", "buttonLink", "importButtonLabel", "importButtonLink", "isEmptyFilter"],
};
</script>

<style lang="scss" scoped>
.empty-container {
  background-color: $mizogoo-gray-light;
}

.empty-container,
.empty-filter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  text-align: center;

  h2,
  p {
    max-width: 600px;
  }

  p {
    margin-bottom: 2rem;
  }
}
</style>
