// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.commitments-container h4 {
  margin-bottom: 1rem !important;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/SocialCommitmentsPage/Index.vue","webpack://./Index.vue"],"names":[],"mappings":"AAME;EACE,8BAAA;ACLJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.commitments-container {\n  h4 {\n    margin-bottom: 1rem!important;\n  }\n}\n",".commitments-container h4 {\n  margin-bottom: 1rem !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
