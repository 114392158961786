<template>
  <div>
    <top-banner :heading="this.topBanner.heading">
      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <b-container class="secondary-page-container legal-page">
      <b-row>
        <b-col cols="12" class="mb-4">
          <p v-html="$t('front.webinar.subscription.subHeading')"></p>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="mb-4 col-md-6 offset-md-3">
          <Form as="" ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit="handleSubmit(onSubmit)">
              <FlashBag :message="errorAction" v-if="hasErrorAction" />
              <FlashBag :message="message" v-if="hasMessage" variant="success" />

              <FormInput
                type="text"
                id="email"
                name="Email"
                :rules="{ required: true }"
                v-model="form.email"
              />

              <FormInput
                type="text"
                id="firstname"
                name="Prénom"
                v-model="form.firstname"
              />

              <FormInput type="text" id="lastname" name="Nom" v-model="form.lastname" />

              <FormInput type="text" id="phone" name="Téléphone" v-model="form.phone" />

              <vue-recaptcha
                ref="recaptcha"
                @verify="onCaptchaVerified"
                @expired="onCaptchaExpired"
                size="invisible"
                :sitekey="recaptchaPublicKey"
                :loadRecaptchaScript="true"
              >
              </vue-recaptcha>

              <div class="mt-4 text-center submit-button">
                <button
                  type="submit"
                  class="btn btn-outline-primary"
                  :disabled="isLoadingAction"
                >
                  {{ webinar.buttonLabel }}
                  <b-spinner v-if="isLoadingAction" small class="ml-1"></b-spinner>
                </button>
              </div>
            </b-form>
          </Form>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { nextTick } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FlashBag from "@mizogoo/components/FlashBag";
import FormInput from "@mizogoo/components/form/FormInput";

export default {
  mixins: [MetaMixin],
  name: "WebinarPageSubscription",
  components: {
    TopBanner,
    FlashBag,
    FormInput,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        email: null,
        firstname: null,
        lastname: null,
        phone: null,
        recaptchaToken: null,
      },
      message: null,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.webinar.subscription.title");
    },
    metaDescription() {
      return this.$t("front.metadata.webinar.subscription.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.webinar.subscription.topBanner.heading"),
        image: {
          src: require("@mizogoo/assets/img/front/webinar/banner_webinar.png"),
          alt: this.$t("front.webinar.subscription.topBanner.imageAlt"),
        },
      };
    },
    webinar() {
      return {
        buttonLabel: this.$t("front.webinar.subscription.buttonLabel"),
      };
    },
    // Creating
    isLoadingAction() {
      return this.$store.getters["webinar/isLoadingAction"];
    },
    hasErrorAction() {
      return this.$store.getters["webinar/hasErrorAction"];
    },
    errorAction() {
      return this.$store.getters["webinar/errorAction"];
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      const self = this;
      self.status = "submitting";
      self.$refs.recaptcha.reset();

      this.form.recaptchaToken = recaptchaToken;

      const response = await this.$store.dispatch("webinar/create", {
        form: this.form,
      });

      if (!this.hasErrorAction) {
        this.message = response.message;
        this.form.email = null;
        this.form.firsname = null;
        this.form.lastname = null;
        this.form.phone = null;

        nextTick(() => {
          this.$refs.observer.resetForm();
        });
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>

<style lang="scss" scoped></style>
