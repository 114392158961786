import CartElementAPI from "../api/cartElement";

const
  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING_QUANTITY = "UPDATING_QUANTITY",
  UPDATING_QUANTITY_SUCCESS = "UPDATING_QUANTITY_SUCCESS",
  UPDATING_QUANTITY_ERROR = "UPDATING_QUANTITY_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR";

export default {
  namespaced: true,
  state: {
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating Quantity
    isLoadingUpdatingQuantity: false,
    errorUpdatingQuantity: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
  },
  getters: {
    // Creating
    isLoadingCreating(state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating(state) {
      return state.errorCreating !== null;
    },
    errorCreating(state) {
      return state.errorCreating;
    },
    // Updating Quantity
    isLoadingUpdatingQuantity(state) {
      return state.isLoadingUpdatingQuantity;
    },
    hasErrorUpdatingQuantity(state) {
      return state.errorUpdatingQuantity !== null;
    },
    errorUpdatingQuantity(state) {
      return state.errorUpdatingQuantity;
    },
    // Removing
    isLoadingRemoving(state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving(state) {
      return state.errorRemoving !== null;
    },
    errorRemoving(state) {
      return state.errorRemoving;
    },
  },
  mutations: {
    // Creating
    [CREATING](state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS](state) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating Quantity
    [UPDATING_QUANTITY](state) {
      state.isLoadingUpdatingQuantity = true;
      state.errorUpdatingQuantity = null;
    },
    [UPDATING_QUANTITY_SUCCESS](state, data) {
      state.isLoadingUpdatingQuantity = false;
      state.errorUpdatingQuantity = null;
      this.dispatch("cart/providingCartData", data.cart)
      this.dispatch("cart/providingPricesData", data.prices);
    },
    [UPDATING_QUANTITY_ERROR](state, error) {
      state.isLoadingUpdatingQuantity = false;
      state.errorUpdatingQuantity = error;
    },
    // Removing
    [REMOVING](state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS](state, data) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      this.dispatch("cart/providingCartData", data.cart)
      this.dispatch("cart/providingPricesData", data.prices);
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
  },
  actions: {
    // Creating
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await CartElementAPI.create(payload.productId, payload.data);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data);
          return response.data;
        } else {
          commit(CREATING_ERROR);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR);
        return null;
      }
    },
    // Updating Quantity
    async updatingQuantity({ commit }, payload) {
      commit(UPDATING_QUANTITY);
      try {
        let response = await CartElementAPI.updatingQuantity(payload.cartElement, payload.data);
        if (response.data.success === true) {
          commit(UPDATING_QUANTITY_SUCCESS, { cart: response.data.cart, prices: response.data.prices });
          return response.data;
        } else {
          commit(UPDATING_QUANTITY_ERROR);
          return null;
        }
      } catch (error) {
        commit(UPDATING_QUANTITY_ERROR);
        return null;
      }
    },
    // Removing
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await CartElementAPI.remove(payload.cartElement);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, { cart: response.data.cart, prices: response.data.prices });
          return null;
        } else {
          commit(REMOVING_ERROR);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR);
        return null;
      }
    },
  }
}
