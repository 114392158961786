// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  top: 0;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/App.vue","webpack://./App.vue"],"names":[],"mappings":"AAOA;EACE,MAAA;ACNF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n// Fix bootstrap tooltip position (known issue)\n// https://github.com/bootstrap-vue/bootstrap-vue/issues/1732\n.tooltip {\n  top: 0;\n}\n",".tooltip {\n  top: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
