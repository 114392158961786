<template>
  <b-container fluid class="internal-links">
    <div class="decoration-container">
      <div class="absolute-decoration-white-background"></div>
      <div class="absolute-decoration-solid-grey-left"></div>
      <div class="absolute-decoration-solid-grey-right"></div>
      <div class="absolute-decoration-dotted-red"></div>
    </div>
    <b-container class="main-content text-center">
      <b-row align-v="center" align-h="center">
        <b-col>
          <h3>{{ subheading }}</h3>
          <h2>{{ heading }}</h2>
          <div class="buttons-container">
            <b-row
              v-for="(row, rowIndex) in numberOfRows"
              :key="rowIndex"
              class="justify-content-md-center"
            >
              <b-col
                v-for="button in buttons.slice((row - 1) * 3, row * 3)"
                :key="button.id"
                cols="12"
                lg="4"
              >
                <router-link
                  :to="{ name: button.link }"
                  custom
                  v-slot="{ navigate }"
                >
                  <div class="button-container" @click="navigate" @keypress.enter="navigate">
                    {{ button.label }}
                  </div>
                </router-link>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "SeoText",
  props: ["subheading", "heading", "buttons"],
  computed: {
    numberOfRows() {
      return Math.ceil(this.buttons.length / 3);
    },
  },
};
</script>

<style lang="scss" scoped>
/* Main content style */
.internal-links {
  position: relative;
  padding-top: 7rem;
  padding-bottom: 5rem;
  margin-top: 5rem;
  overflow: hidden;
}
.main-content {
  z-index: 3;
}
.internal-links h2,
.internal-links p {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}
.buttons-container {
  margin: 2rem 4rem;

  .button-container {
    padding: 1rem;
    margin: 15px 0!important;
    border: 1px solid $mizogoo-gray-dark;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.15s ease-in-out;

    &:hover {
      background-color: $mizogoo-gray-light;
    }
  }
}

/* Absolute decorations style */
.absolute-decoration-solid-grey-left,
.absolute-decoration-solid-grey-right,
.absolute-decoration-dotted-red,
.absolute-decoration-white-background {
  position: absolute;
}
.absolute-decoration-solid-grey-left,
.absolute-decoration-solid-grey-right {
  background-color: $mizogoo-gray-light;
  top: 10%;
  width: 12%;
  height: 84%;
  z-index: 1;
}
.absolute-decoration-solid-grey-left {
  left: 0;
}
.absolute-decoration-solid-grey-right {
  right: 0;
}
.absolute-decoration-dotted-red {
  content: url(../../../assets/img/front/global/decorations/doted_decoration_colored.svg);
  top: 0;
  right: 0;
  z-index: 0;
}
.absolute-decoration-white-background {
  width: 100%;
  height: 100%;
  background-color: $white;
  top: 25px;
}

/* Decorations only display on desktop */
@media screen and (max-width: 1200px) {
  .absolute-decoration-solid-grey-left,
  .absolute-decoration-solid-grey-right,
  .absolute-decoration-dotted-red {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .buttons-container {
    margin: 2rem;
  }

  .absolute-decoration-white-background {
    left: 0;
  }
}
</style>
