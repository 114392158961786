import axios from "axios";
import Tool from "@mizogoo/class/tool";

export default {
  findDocuments(data) {
    return axios.post('/api/do-it-for-me/documents', Tool.createFormData(data));
  },
  relatedDocumentCreate(data) {
    return axios.post('/api/do-it-for-me/related-document/create',
      Tool.createFormData({tmpUserId: data.tmpUserId, file: data.file}),
      {
        onUploadProgress: function (progressEvent) {
          data.store.dispatch('doItForMe/setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }.bind(this)
      });
  },
  moveDocuments(data) {
    return axios.post('/api/do-it-for-me/' + data.creationId + '/move-documents', Tool.createFormData({ files: data.files }));
  },
}
