<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
    >
      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>
  
    <div class="commitments-container">
      <div v-for="(commitment, index) in commitments" :key="index" class="commitment-container">
        <b-container>
          <b-row>
            <b-col cols="12" lg="5">
              <div class="heading-container">
                <div class="number-part">
                  <span>0</span>
                  <span>{{ index + 1 }}</span>
                  <span class="dot">.</span>
                </div>
                <h2>{{ commitment.heading }}</h2>
              </div>
              <div class="absolute-decoration-dotted-red" />
            </b-col>
            <b-col cols="12" lg="7">
              <div v-html="commitment.description">
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div v-if="index != commitments.length - 1" class="separator" />
      </div>
    </div>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";

export default {
  mixins: [MetaMixin],
  name: "SocialCommitmentsPage",
  components: {
    TopBanner,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.socialCommitments.title");
    },
    topBanner() {
      return {
        heading: this.$tm("front.socialCommitments.topBanner.heading"),
        description: this.$t("front.socialCommitments.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/social-commitments/banner_social_commitments.jpg"),
          alt: this.$t("front.aboutUs.topBanner.imageAlt"),
        },
      };
    },
    commitments() {
      return [
        {
          heading: this.$tm("front.socialCommitments.commitments[0].heading"),
          description:
            "<h4>" + this.$t("front.socialCommitments.commitments[0].description.subheading") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[0].description.text") + "</p>"
        },
        {
          heading: this.$tm("front.socialCommitments.commitments[1].heading"),
          description:
            "<h4>" + this.$t("front.socialCommitments.commitments[1].description.subheading") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[1].description.text") + "</p>"
        },
        {
          heading: this.$tm("front.socialCommitments.commitments[2].heading"),
          description: 
            "<h4>" + this.$t("front.socialCommitments.commitments[2].description.subheading1") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[2].description.text1") + "</p>" +
            "<div class=\"video-wrapper embed-responsive embed-responsive-16by9 mb-5\">" +
            "<iframe width='560' height='315' title='YouTube video player' frameborder='0' allowfullscreen " +
              "src='https://www.youtube-nocookie.com/embed/DZnWYPM8dzg?controls=0&rel=0' " +
              "allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'" +
            "></iframe>" +
            "</div>" +
            "<h4>" + this.$t("front.socialCommitments.commitments[2].description.subheading2") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[2].description.text2") + "</p>" +
            "<h4>" + this.$t("front.socialCommitments.commitments[2].description.subheading3") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[2].description.text3") + "</p>"
        },
        {
          heading: this.$tm("front.socialCommitments.commitments[3].heading"),
          description:
            "<h4>" + this.$t("front.socialCommitments.commitments[3].description.subheading") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[3].description.text") + "</p>"
        },
        {
          heading: this.$tm("front.socialCommitments.commitments[4].heading"),
          description:
            "<h4>" + this.$t("front.socialCommitments.commitments[4].description.subheading") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[4].description.text") + "</p>"
        },
        {
          heading: this.$tm("front.socialCommitments.commitments[5].heading"),
          description:
            "<h4>" + this.$t("front.socialCommitments.commitments[5].description.subheading") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[5].description.text") + "</p>"
        },
        {
          heading: this.$tm("front.socialCommitments.commitments[6].heading"),
          description:
            "<h4>" + this.$t("front.socialCommitments.commitments[6].description.subheading") + "</h4>" +
            "<p>" + this.$t("front.socialCommitments.commitments[6].description.text") + "</p>"
        },
      ]
    }
  },
}
</script>

<style lang="scss" scoped>
.commitments-container {
  margin-top: 6rem;
  padding-bottom: 10rem;

  .commitment-container {
    .heading-container {
      position: relative;
      margin-right: 1.5rem;
      padding: 1.5rem;
      background-color: $mizogoo-gray-light;
      z-index: 1;

      .number-part {
        font-size: 4rem;
        font-weight: bold;
        letter-spacing: -10px;
      }
      .number-part .dot {
        color: $mizogoo;
      }

      h2 {
        margin-left: 2.5rem;
      }
    }

    .absolute-decoration-dotted-red {
      content: url(../../../../assets/img/front/global/decorations/doted_decoration_colored.svg);
      position: absolute;
      top: 25px;
      left: -20px;
      z-index: 0;
    }
  }

  .separator {
    margin-top: 8rem!important;
    margin-bottom: 8rem!important;
  }

  @media screen and (max-width: 992px) {
    .absolute-decoration-dotted-red {
      display: none;
    }

    .separator {
      margin-top: 4rem!important;
      margin-bottom: 4rem!important;
    }
  }
}
</style>

<style lang="scss">
.commitments-container {
  h4 {
    margin-bottom: 1rem!important;
  }
}
</style>
