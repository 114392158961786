// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-container .custom-tabs .nav-tabs {
  margin-bottom: 2rem;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/ProductPage/ProductDescription.vue","webpack://./ProductDescription.vue"],"names":[],"mappings":"AAME;EACE,mBAAA;ACLJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.product-container {\n  .custom-tabs .nav-tabs {\n    margin-bottom: 2rem;\n  }\n}\n",".product-container .custom-tabs .nav-tabs {\n  margin-bottom: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
