import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll() {
    return axios.post("/api/account/beer/category", Tool.createFormData());
  },
  findOne(data) {
    return axios.post("/api/account/beer/category/" + data, Tool.createFormData());
  },
  create(data) {
    return axios.post("/api/account/beer/category/create", Tool.createFormData(data));
  },
  update(data) {
    return axios.post("/api/account/beer/category/update/" + data.id, Tool.createFormData(data));
  },
  remove(data) {
    return axios.post("/api/account/beer/category/remove/" + data, Tool.createFormData());
  },
  toggleBeersActivation (data) {
    return axios.post("/api/account/beer/category/toggle_beers_activation/" + data.beerCategory.id, Tool.createFormData(data));
  }
};
