<template>
  <b-container fluid class="feature-text-right">
    <div class="absolute-decoration-solid-grey-right"></div>
    <div class="absolute-decoration-dotted-red"></div>
    <b-container>
      <b-row align-v="center" align-h="center">
        <b-col order-lg="2" class="text-col" lg="6" md="12">
          <div class="inner-container">
            <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
            <div class="heading-container">
              <div
                v-for="(headingPart, index) in heading"
                :key="index"
                class="heading-inner-container"
              >
                <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
              </div>
            </div>
            <div v-scrollanimation="{name: 'fade-up', delay: 250}">
              <p>{{ description }}</p>
              <template v-if="bullets">
                <ul class="bullets">
                  <li v-for="bullet in bullets" :key="bullet.id">
                    <i class="fas fa-circle"></i>
                    {{ bullet }}
                  </li>
                </ul>
              </template>
            </div>
            <router-link
              v-if="button"
              :to="{name: button.link}"
              v-scrollanimation="{name: 'fade-up', delay: 250}"
              custom
              v-slot="{ navigate }"
            >
              <button class="btn btn-outline-primary" @click="navigate" @keypress.enter="navigate">
                {{ button.label }}
              </button>
            </router-link>
          </div>
        </b-col>
        <b-col order-lg="1" class="image-col" :class="{'image-padding' : image.padding != false}" lg="6" md="12">
          <b-img-lazy
            fluid
            center
            :src="image.src"
            :alt="image.alt"
            :style="{ maxHeight: image.maxHeight + 'px' }"
          ></b-img-lazy>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "FeatureTextRight",
  props: ["image", "subheading", "heading", "description", "bullets", "button"],
};
</script>

<style lang="scss" scoped>
.feature-text-right {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-left: 0;
  padding-right: 0;
}
.image-col {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #f8f8f8;
  z-index: 2;
}
.image-padding {
  padding-right: 5rem;
}
.text-col {
  padding-top: 5rem;
  padding-bottom: 5rem;
  padding-left: 5rem;

  .inner-container {
    position: relative;
    z-index: 2;

    .bullets {
      li {
        display: flex;
        margin-left: 1rem;
        margin-bottom: 0.4rem;
        font-size: 1.05rem;

        i {
          margin-top: 9px;
          font-size: 8px;
          margin-right: 1rem;
        }
      }
    }

    .btn {
      margin-top: 1.5rem;
    }
  }
}

/* Absolute decoration style */
.absolute-decoration-solid-grey-right,
.absolute-decoration-dotted-red {
  position: absolute;
}
.absolute-decoration-solid-grey-right {
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  background-color: #e9e9e9;
  z-index: 1;
}
.absolute-decoration-dotted-red {
  content: url(../../../../assets/img/front/global/decorations/doted_decoration_colored.svg);
  top: -50px;
  right: 0;
  transform: rotate(90deg) translate(100%, 0);
  transform-origin: 100% 0;
  z-index: 0;
}

@media screen and (max-width: 992px) {
  .text-col,
  .image-col {
    padding-left: 15px;
    padding-right: 15px;
  }
  .absolute-decoration-solid-grey-right {
    width: 50%;
    height: 50%;
  }
}
@media screen and (max-width: 576px) {
  .absolute-decoration-dotted-red {
    transform: initial;
    transform-origin: initial;
  }
}
@media screen and (max-width: 400px) {
  .absolute-decoration-solid-grey-right {
    width: 50%;
    height: 65%;
  }
}
</style>
