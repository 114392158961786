// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mizogoo-presentation-video-modal {
  padding: 0;
  border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/HomePage/HomeAdvantage.vue","webpack://./HomeAdvantage.vue"],"names":[],"mappings":"AAKA;EACE,UAAA;EACA,mBAAA;ACJF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.mizogoo-presentation-video-modal {\n  padding: 0;\n  border-radius: 20px;\n}\n",".mizogoo-presentation-video-modal {\n  padding: 0;\n  border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
