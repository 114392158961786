import InstitutionDocumentHomeAPI from "../api/institution_document_home";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR";

export default {
  namespaced: true,
  state: {
    institutionDocuments: null,
    isLoadingInstitutionDocuments: false,
    errorInstitutionDocuments: null,
  },
  getters: {
    institutionDocuments (state) {
      return state.institutionDocuments;
    },
    isLoadingInstitutionDocuments (state) {
      return state.isLoadingInstitutionDocuments;
    },
    hasErrorInstitutionDocuments (state) {
      return state.errorInstitutionDocuments !== null;
    },
    errorInstitutionDocuments (state) {
      return state.errorInstitutionDocuments;
    },
  },
  mutations: {
    [FETCH_ALL] (state) {
      state.isLoadingInstitutionDocuments = true;
      state.errorInstitutionDocuments = null;
      state.institutionDocuments = null;
    },
    [FETCH_ALL_SUCCESS] (state, institutionDocuments) {
      state.isLoadingInstitutionDocuments = false;
      state.errorInstitutionDocuments = null;
      state.institutionDocuments = institutionDocuments;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingInstitutionDocuments = false;
      state.errorInstitutionDocuments = error;
      state.institutionDocuments = null;
    }
  },
  actions: {
    async findAll ({ commit }, payload) {
      commit(FETCH_ALL);
      try {
        let response = await InstitutionDocumentHomeAPI.findAll(payload.institutionId);
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.institutionDocuments);
          return response.data;
        } else {
          commit(FETCH_ALL_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR, error);
        return null;
      }
    }
  }
};
