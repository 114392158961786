// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.extra-info[data-v-1803ec20] {
  margin-top: 1rem;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/global/contact-form/ContactForm.vue","webpack://./ContactForm.vue"],"names":[],"mappings":"AAKA;EACE,gBAAA;EACA,iBAAA;ACJF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.extra-info {\n  margin-top: 1rem;\n  font-size: 0.9rem;\n}\n",".extra-info {\n  margin-top: 1rem;\n  font-size: 0.9rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
