<template>
  <div class="step-container">
    <ul class="step-list-inline">
      <li
        v-for="step in steps"
        class="step-list-inline-item"
        :class="{ 'step-list-inline-item-active': step.name === active }"
        :key="step.id"
      >
        <router-link
          v-if="step.id <= currentStep.id"
          :to="{ name: step.to }"
        >
          {{ step.id }}<span class="dot">.</span> {{ step.label }}
        </router-link>

        <template v-else> {{ step.id }}. {{ step.label }} </template>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CartPageStep",
  props: ["active"],
  data() {
    return {
      currentStep: null,
      steps: [
        {
          id: 1,
          name: "cart",
          label: this.$t("front.cart.cart"),
          to: "cart",
        },
        {
          id: 2,
          name: "delivery",
          label: this.$t("front.cart.delivery"),
          to: "cart_address",
        },
        {
          id: 3,
          name: "payment",
          label: this.$t("front.cart.payment"),
          to: "cart_command",
        },
      ],
    };
  },
  created() {
    this.currentStep = this.steps.find(
      (element) => element.name === this.active
    );
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .step-list-inline-item {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    color: $mizogoo-gray-medium;

    a {
      color: $mizogoo-gray-medium;
    }
  }

  .step-list-inline-item-active {
    a {
      font-weight: bold;
      font-size: 1.1rem;
      color: $mizogoo-gray-dark;

      .dot {
        color: $mizogoo;
      }
    }

    &:after {
      content: "";
      display: inline-block;
      vertical-align: middle;
      height: 2px;
      width: 50px;
      margin-left: 5px;
      background-color: $mizogoo-gray-dark;
    }
  }
}

@media (max-width: 991.98px) {
  .step-list-inline {
    padding-left: 0;
    list-style: none;

    .step-list-inline-item {
      display: inline-block;
      padding-left: 0.4rem;
      padding-right: 0.4rem;

      &:not(:last-child) {
        margin-right: $list-inline-padding;
      }
    }

    .step-list-inline-item-active:after {
      display: none;
    }
  }
}
</style>
