import ProductReviewAPI from "../api/product_review";

const FETCH = "FETCH",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_ERROR = "FETCH_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    productReviews: [],
  },
  getters: {
    hasError(state) {
      return state.error !== null;
    },
    hasProductReviews(state) {
      return state.productReviews.length > 0;
    },
  },
  mutations: {
    // Fetch
    [FETCH](state) {
      state.isLoading = true;
      state.error = null;
      state.productReviews = [];
    },
    [FETCH_SUCCESS](state, productReviews) {
      state.isLoading = false;
      state.error = null;
      state.productReviews = productReviews;
    },
    [FETCH_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.productReviews = [];
    },
  },
  actions: {
    // Fetch
    async find({ commit }, payload) {
      commit(FETCH);
      try {
        let response = await ProductReviewAPI.find(payload.product);
        if (response.data.success === true) {
          commit(FETCH_SUCCESS, response.data.productReviews);
          return response.data.productReviews;
        } else {
          commit(FETCH_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(FETCH_ERROR);
        return null;
      }
    },
  },
};
