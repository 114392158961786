<template>
  <b-container class="order-confirmation-container">
    <Loading v-if="isLoading"/>

    <template v-else>
      <!-- Errors -->
      <b-row v-if="hasErrorValidated">
        <b-col class="mb-4 text-center">
          <FlashBag :message="errorValidated" v-if="hasErrorValidated"/>
        </b-col>
      </b-row>

      <template v-if="payment">
        <b-row class="justify-content-center mb-4">
          <b-col class="text-center">
            <h2>{{ $t('front.cart.thanks') }}</h2>
            <p>{{ $t('front.cart.orderConfirmation') }}</p>
          </b-col>
        </b-row>

        <b-row class="pt-2 pb-4 text-center">
          <b-col>
            <p class="font-weight-bold mb-2">
              {{ $t('front.cart.productNumber') }}
            </p>
            <p>{{ orders.length }}</p>
          </b-col>
          <b-col>
            <p class="font-weight-bold mb-2">
              {{ $t('front.cart.date') }}
            </p>
            <p>{{ $d(new Date(orders[0].createdAt.date), 'digitExtended') }}</p>
          </b-col>
          <b-col>
            <p class="font-weight-bold mb-2">
              {{ $t('front.cart.totalAmount') }}
            </p>
            <p>{{ formatPrice(payment.amount) }}</p>
          </b-col>
          <b-col>
            <p class="font-weight-bold mb-2">
              {{ $t('front.cart.paymentMethod') }}
            </p>
            <p>{{ payment.paymentMethod.name }}</p>
          </b-col>
        </b-row>

        <b-row class="mt-4">
          <b-col>
            <b-row
                v-for="order in orders"
                :key="'order-' + order.id"
                class="order-element-container"
            >
              <b-col>
                <b-col sm="12" md="2">
                  <b-img
                      :src="productFile(order.product).src"
                      :alt="productFile(order.product).alt"
                  />
                </b-col>
              </b-col>

              <b-col sm="12" md="5">
                <template v-if="order.creation !== null">
                  <span class="font-weight-bold">
                    {{ order.creation.name }}
                  </span>
                </template>
                <template v-else>
                  <span class="font-weight-bold">{{ order.product.name }}</span>
                </template>

                <ul v-if="order.orderFeatures.length > 0">
                  <li
                      v-for="orderFeature in order.orderFeatures"
                      :key="orderFeature.id"
                  >
                    <span class="text-muted"
                    >{{ orderFeature.feature.name }}:</span
                    >
                    {{ orderFeature.value }}
                    <span
                        v-if="orderFeature.productFeatureValue.isFormatVariable"
                    >
                      ({{ order.width }}x{{ order.height }}cm)
                    </span>
                  </li>
                </ul>
              </b-col>

              <b-col sm="12" md="5">
                <div v-if="order.orderAttributs.length > 0">
                  <ul>
                    <li
                        v-for="orderAttribut in order.orderAttributs"
                        :key="orderAttribut.id"
                    >
                      <span class="text-muted">{{ orderAttribut.name }}:</span>
                      {{ orderAttribut.valueName }}
                    </li>
                  </ul>
                </div>

                <div v-if="order.amountPromotionHt > 0">
                  <span class="text-muted">{{ order.promotionName }}</span>
                  {{ order.promotionDescription }} (-{{ formatPrice(order.amountPromotionHt) }})
                </div>
              </b-col>
            </b-row>

            <b-row class="pt-4 mt-4">
              <b-col class="d-flex justify-content-center">
                <router-link
                    class="btn btn-outline-primary"
                    :to="{ name: user === null ? 'home' : 'workspace' }"
                >
                  {{ $t('front.cart.returnHome') }}
                </router-link>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </template>
    </template>
  </b-container>
</template>

<script>
import Loading from '@mizogoo/components/workspace/layout/Loading'
import FlashBag from '@mizogoo/components/FlashBag'

export default {
  name: 'CartPageValidated',
  components: {
    Loading,
    FlashBag,
  },
  computed: {
    isLoading () {
      return this.$store.getters['cart/isLoadingValidated']
    },
    hasErrorValidated () {
      return this.$store.getters['cart/hasErrorValidated']
    },
    errorValidated () {
      return this.$store.getters['cart/errorValidated']
    },
    orders () {
      return this.$store.getters['cart/orders']
    },
    payment () {
      return this.$store.getters['cart/payment']
    },
  },
  methods: {
    // Product
    productFile (product) {
      return {
        src: product.file.thumbnails['100_100'],
        alt: product.name,
      }
    },
  },
  async created () {
    await this.$store.dispatch('cart/findValidated', {
      reference: this.$route.params.reference,
    })
  },
  mounted () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', false)
    this.$store.dispatch('global/fetchFrontHeaderVisible', false)
    this.$store.dispatch('global/fetchFrontFooterVisible', false)
  },
  beforeUnmount () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', true)
    this.$store.dispatch('global/fetchFrontHeaderVisible', true)
    this.$store.dispatch('global/fetchFrontFooterVisible', true)
  },
}
</script>

<style lang="scss" scoped>
.order-confirmation-container {
  padding-top: 8rem;
  padding-bottom: 8rem;

  .order-element-container {
    border-bottom: 1px solid $gray-300;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;

    &:first-child {
      border-top: 1px solid $gray-300;
    }
  }
}
</style>
