import axios from 'axios'
import Tool from '@mizogoo/class/tool'

export default {
  findCart () {
    return axios.post('/api/cart', Tool.createFormData())
  },
  updateAddress (data) {
    return axios.post('/api/cart/update/address', Tool.createFormData(data))
  },
  findPaymentMethods () {
    return axios.post('/api/cart/payment-methods', Tool.createFormData())
  },
  findValidated (reference) {
    return axios.post('/api/cart/validated/' + reference, Tool.createFormData())
  },
  addPromotion (data) {
    return axios.post('/api/cart/add/promotion', Tool.createFormData(data))
  },
  removePromotion () {
    return axios.post('/api/cart/remove/promotion', Tool.createFormData())
  },
}
