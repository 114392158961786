<template>
  <b-container class="secondary-page-container legal-page">
    <b-row>
      <b-col cols="12" class="mb-4">
        <h1 class="text-center">{{ $t("front.review.creation.heading") }}</h1>
        <p>{{ $t("front.review.creation.subheading") }}</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mb-4">
        <Form as="" ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit="handleSubmit(onSubmit)">
            <FlashBag :message="errorAction" v-if="hasErrorAction" />
            <FlashBag :message="message" v-if="hasMessage" variant="success" />

            <FormRating
              id="rating"
              name="Comment évaluez-vous notre service de conception de cartes en ligne ? "
              :rules="{ required: true }"
              v-model="form.rating"
              :stars="5"
              size="lg"
              variant="mizogoo"
              inline
            />

            <FormTextarea
              id="content"
              name="Souhaitez-vous apporter plus de précision ?"
              v-model="form.content"
              rows="4"
            />

            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              :sitekey="recaptchaPublicKey"
              :loadRecaptchaScript="true"
            >
            </vue-recaptcha>

            <div class="mt-4 text-center submit-button">
              <button
                type="submit"
                class="btn btn-outline-primary"
                :disabled="isLoadingAction"
              >
                {{ review.buttonLabel }}
                <b-spinner v-if="isLoadingAction" small class="ml-1"></b-spinner>
              </button>
            </div>
          </b-form>
        </Form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { nextTick } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import MetaMixin from "@mizogoo/mixins/meta.js";
import FlashBag from "@mizogoo/components/FlashBag";
import FormRating from "@mizogoo/components/form/FormRating";
import FormTextarea from "@mizogoo/components/form/FormTextarea";

export default {
  mixins: [MetaMixin],
  name: "ReviewPage",
  components: {
    FlashBag,
    FormRating,
    FormTextarea,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        type: "CREATION",
        rating: null,
        content: null,
        recaptchaToken: null,
      },
      message: null,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.review.creation.title");
    },
    metaDescription() {
      return this.$t("front.metadata.review.creation.description");
    },
    review() {
      return {
        buttonLabel: this.$t("front.review.creation.buttonLabel"),
      };
    },
    // Creating
    isLoadingAction() {
      return this.$store.getters["review/isLoadingAction"];
    },
    hasErrorAction() {
      return this.$store.getters["review/hasErrorAction"];
    },
    errorAction() {
      return this.$store.getters["review/errorAction"];
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      const self = this;
      self.status = "submitting";
      self.$refs.recaptcha.reset();

      this.form.recaptchaToken = recaptchaToken;

      const response = await this.$store.dispatch("review/create", {
        form: this.form,
      });

      if (!this.hasErrorAction) {
        this.message = response.message;
        this.form.rating = null;
        this.form.content = null;

        nextTick(() => {
          this.$refs.observer.resetForm();
        });
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>

<style lang="scss" scoped></style>
