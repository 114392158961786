import { nextTick } from 'vue'

export default {
  beforeMount(el, binding, vnode) {
    // IntersectionObserver options
    const options = {
      rootMargin: '-120px', // rootMargin make an offset (the observer will be triggered 120px after the bottom line)
    };

    // Animation options
    let animationName = binding.value.name;
    let animationDelay = binding.value.delay ? binding.value.delay : 0;

    if (animationName == 'fade-up-appear') {
      // Wait for the page to be loaded
      nextTick(() => {
        // Since this is a particular animation, we have to use a wrapper
        // (a container with a hidden overflow to make a 'reveal' animation)
        const wrapper = document.createElement('div');
        wrapper.style.position = 'relative';
        wrapper.style.overflow = 'hidden';
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el);
        animationName = 'fade-up';

        // Add the class to the inner element
        el.classList.add('before-' + animationName);
        el.style.transitionDelay = animationDelay * 0.001 + 's';

        let animatedScrollObserver = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting || entry.intersectionRatio > 0) {
              entry.target.children[0].classList.add(animationName);
              observer.unobserve(entry.target)
            }
          })
        }, options);

        animatedScrollObserver.observe(wrapper);
      });

    } else {
      el.classList.add('before-' + animationName);
      el.style.transitionDelay = animationDelay * 0.001 + 's';

      let animatedScrollObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            el.classList.add(animationName);
            observer.unobserve(entry.target)
          }
        })
      });

      animatedScrollObserver.observe(el);
    }
  },
};
