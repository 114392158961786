import { createApp } from 'vue'

import {
  AlertPlugin,
  ButtonPlugin,
  CardPlugin,
  CollapsePlugin,
  DropdownPlugin,
  EmbedPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormTextareaPlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  ModalPlugin,
  NavbarPlugin,
  PopoverPlugin,
  ProgressPlugin,
  SpinnerPlugin,
  TablePlugin,
  TabsPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBVisiblePlugin
} from 'bootstrap-vue'

import './gtm.js'
import App from './App'
import VueGtag from "vue-gtag"
import i18n from './i18n'
import LoadScript from "vue-plugin-load-script"

import clickOutside from '@mizogoo/directives/click-outside'
import scrollAnimation from '@mizogoo/directives/scroll-animation'

import { router } from '@mizogoo/router'
import store from '@mizogoo/store'

import { Field, Form } from 'vee-validate'

import VueSocialSharing from 'vue-social-sharing'

import toolMixin from  '@mizogoo/mixins/tool'
import globalMixin from  '@mizogoo/mixins/global'

import axios from "@mizogoo/plugins/axios";
import setupVeeValidate from "@mizogoo/plugins/vee-validate";

/* eslint-disable no-new */
const app = createApp({
  components: { App },
  template: '<App/>',
  mounted() {
    //hide livechat on /mobile/app & /pdfmenu/institution pages
    if (!this.$route.path.includes('/mobile/app') && !this.$route.path.includes('/pdfmenu/institution')) {
      let liveChatScript = document.createElement("script")
      let liveChatNoScript = document.createElement("noscript")
      let content = document.createTextNode(`window.__lc = window.__lc || {};window.__lc.license = 14228811;;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))`)
      liveChatScript.appendChild(content)
      liveChatNoScript.append('<a href="https://www.livechat.com/chat-with/14228811/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>')
      document.body.appendChild(liveChatScript)
      document.body.appendChild(liveChatNoScript)
    }

    // Google AdSense
    let adsenseScript = document.createElement("script");
    adsenseScript.async = true;
    adsenseScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2674192338820553";
    adsenseScript.crossOrigin = "anonymous";
    document.head.appendChild(adsenseScript);
  }
})

//filter warnings to remove most of vue-bootstrap warnings
app.config.warnHandler = (msg, instance, trace) =>
  ![
    '<B'
  ].some((component) => trace.includes(component) || msg.includes(component)) &&
  console.warn('[Vue warn]: '.concat(msg)+' '.concat(trace))

app.config.compilerOptions.whitespace = 'preserve'
app.config.globalProperties.axios = axios

app.use(AlertPlugin)
app.use(ButtonPlugin)
app.use(CardPlugin)
app.use(CollapsePlugin)
app.use(DropdownPlugin)
app.use(EmbedPlugin)
app.use(FormCheckboxPlugin)
app.use(FormGroupPlugin)
app.use(FormInputPlugin)
app.use(FormPlugin)
app.use(FormSelectPlugin)
app.use(FormTextareaPlugin)
app.use(ImagePlugin)
app.use(InputGroupPlugin)
app.use(LayoutPlugin)
app.use(ModalPlugin)
app.use(NavbarPlugin)
app.use(PopoverPlugin)
app.use(ProgressPlugin)
app.use(SpinnerPlugin)
app.use(TablePlugin)
app.use(TabsPlugin)
app.use(ToastPlugin)
app.use(TooltipPlugin)
app.use(VBVisiblePlugin)

app.use(router)
app.use(store)
app.use(i18n)
app.use(LoadScript)

app.use(VueGtag, {
  config: { id: "AW-10851025598" }
})
app.use(clickOutside)
app.use(VueSocialSharing)

app.mixin(toolMixin)
app.mixin(globalMixin)

app.directive('scrollanimation', scrollAnimation)

app.component('Field', Field)
app.component('Form', Form)

setupVeeValidate(i18n);

router.isReady().then(() => { app.mount("#mizogoo") })
