<template>
  <div class="feature-mobile-container">
    <div class="background-decoration"></div>
    <b-img
      class="feature-illustration"
      :src="activeFeatureImage.src"
      :alt="activeFeatureImage.alt"
    ></b-img>
  </div>
</template>

<script>
export default {
  name: "AppFeatureMobile",
  props: ["images", "activeFeatureId"],
  computed: {
    activeFeatureImage() {
      return this.images.find(
        (image) => image.featureId == this.activeFeatureId
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.feature-mobile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  
  .background-decoration {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 85%;
    height: 60%;
    background-color: $mizogoo-gray-light;
    z-index: 1;
  }

  .feature-illustration {
    position: relative;
    max-width: 320px;
    z-index: 2;
  }
}

@media screen and (max-width: 992px) {
  .feature-mobile-container {
    display: none;
  }
}
</style>
