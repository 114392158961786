// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-group[data-v-1544e6c6] {
  display: flex;
  align-items: center;
}
.input-group .inputWithIcon[data-v-1544e6c6] {
  padding-right: 58px;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/form/FormInput.vue","webpack://./FormInput.vue"],"names":[],"mappings":"AAKA;EACE,aAAA;EACA,mBAAA;ACJF;ADME;EACE,mBAAA;ACJJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.input-group {\n  display: flex;\n  align-items: center;\n\n  .inputWithIcon {\n    padding-right: 58px;\n  }\n}\n",".input-group {\n  display: flex;\n  align-items: center;\n}\n.input-group .inputWithIcon {\n  padding-right: 58px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
