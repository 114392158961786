<template>
  <ul class="pagination">
    <!-- Previous page -->
    <li
      class="page-item"
      :class="{ disabled: isInFirstPage }"
    >
      <button
        type="button"
        @click="onClickPage(currentPage - 1)"
        :disabled="isInFirstPage"
        class="page-link"
        id="previous-page-button"
      >
        <i class="fas fa-angle-left"></i>
      </button>
    </li>

    <!-- Page 1 -->
    <li
      class="page-item"
      :class="{ active: isPageActive(1) }"
    >
      <button
        type="button"
        @click="onClickPage(1)"
        :disabled="isPageActive(1)"
        class="page-link"
      >
        1
      </button>
    </li>

    <!-- Ellipsis before middle pages -->
    <li v-if="shouldShowStartEllipsis" class="page-item ellipsis">
      ...
    </li>

    <!-- Middle pages -->
    <li
      v-for="page in middlePages"
      :key="page.name"
      class="page-item"
      :class="{ active: isPageActive(page.name) }"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="isPageActive(page.name)"
        class="page-link"
      >
        {{ page.name }}
      </button>
    </li>

    <!-- Ellipsis after middle pages -->
    <li v-if="shouldShowEndEllipsis" class="page-item ellipsis">
      ...
    </li>

    <!-- Last page -->
    <li
      class="page-item"
      :class="{ active: isPageActive(totalPage) }"
    >
      <button
        type="button"
        @click="onClickPage(totalPage)"
        :disabled="isPageActive(totalPage)"
        class="page-link"
      >
        {{ totalPage }}
      </button>
    </li>

    <!-- Next page -->
    <li
      class="page-item"
      :class="{ disabled: isInLastPage }"
    >
      <button
        type="button"
        @click="onClickPage(currentPage + 1)"
        :disabled="isInLastPage"
        class="page-link"
        id="next-page-button"
      >
        <i class="fas fa-angle-right"></i>
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 5,
    },
    totalPage: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  emits: ["pagechanged"],
  computed: {
    middlePages() {
      const range = [];
      const start = Math.max(2, this.currentPage - Math.floor((this.maxVisibleButtons - 2) / 2));
      const end = Math.min(this.totalPage - 1, start + this.maxVisibleButtons - 3);

      for (let i = start; i <= end; i++) {
        range.push({ name: i });
      }

      return range;
    },
    shouldShowStartEllipsis() {
      return this.totalPage > this.maxVisibleButtons && this.currentPage > Math.ceil((this.maxVisibleButtons - 2) / 2) + 1;
    },
    shouldShowEndEllipsis() {
      return (
        this.totalPage > this.maxVisibleButtons &&
        this.currentPage < this.totalPage - Math.ceil((this.maxVisibleButtons - 2) / 2)
      );
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPage;
    },
  },
  methods: {
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;

  .page-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out;

    .page-link {
      width: 40px;
      height: 40px;
      color: $mizogoo-gray-dark;
      background-color: transparent;
      border-color: transparent;
    }

    &.disabled {
      .page-link {
        color: #6c757d;
        background-color: transparent!important;
      }
    }

    &.active {
      background-color: $mizogoo!important;

      .page-link {
        color: #fff!important;
      }
    }

    &:hover:not(.ellipsis):not(.disabled) {
      background-color: $mizogoo-gray-light;
    }
  }

  .ellipsis {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: $mizogoo-gray-dark;
  }

  li:has(> #previous-page-button) {
    margin-right: 2rem;
  }

  li:has(> #next-page-button) {
    margin-left: 2rem;
  }
}
</style>
