<template>
  <div id="prices-and-payment" class="w-100">
    <div id="prices-form" class="md:w-1/3 w-full md:mb-8">
      <div v-if="!selectedSubscription" class="loading-container">
        <Loading />
      </div>

      <b-container>
        <b-row>
          <b-col cols="6" class="payement-section">
            <h2>{{ $t("workspace.checkoutModal.fillInPayementInfo") }}</h2>
            <div id="payment-form-container" class="w-full">
              <form id="payment-form">
                <!-- Card number -->
                <label for="card-number">
                  {{ $t("front.cart.cardNumber") }}
                </label>
                <div id="card-number" name="card-number" class="field empty"></div>
                <!-- Errors (invalid card number, etc.) -->
                <p id="card-errors" role="alert"></p>
                <b-row>
                  <b-col sm="6">
                    <!-- Expiry date -->
                    <label for="card-expiry">
                      {{ $t("front.cart.expiryDate") }}
                    </label>
                    <div id="card-expiry" name="card-expiry" class="field"></div>
                  </b-col>
                  <b-col sm="6">
                    <!-- Card CVC -->
                    <label for="card-cvc">
                      {{ $t("front.cart.cvc") }}
                    </label>
                    <div id="card-cvc" name="card-cvc" class="field"></div>
                  </b-col>
                </b-row>

                <b-button
                  id="premium-subscription-start"
                  variant="primary"
                  type="submit"
                  class="mt-3"
                  :disabled="isLoading || isLoadingCreating || isLoadingUpdating"
                >
                  {{ $t("workspace.subscription.subscribe") }}
                  <b-spinner
                    v-if="isLoading || isLoadingCreating || isLoadingUpdating"
                    small
                    class="ml-1"
                  ></b-spinner>
                </b-button>
                <!-- Terms and conditions (automatically accepted by subscribing) -->
                <div class="terms mt-4">
                  {{ $t("workspace.subscription.acceptTerms") }}
                  <router-link
                    :to="{ name: 'terms_sale' }"
                    custom
                    exact
                    class="dark-link"
                    target="_blank"
                  >
                    {{ $t("workspace.subscription.termsSale") }}
                  </router-link>
                  {{ $t("workspace.subscription.acceptPrivacy") }}
                  <router-link
                    :to="{ name: 'privacy' }"
                    custom
                    exact
                    class="dark-link"
                    target="_blank"
                  >
                    {{ $t("workspace.subscription.privacy") }}
                  </router-link>
                  .
                </div>
              </form>
            </div>
          </b-col>

          <b-col
            v-if="selectedSubscription"
            offset="1"
            cols="5"
            class="subscription-section"
          >
            <!-- Selected subscription -->
            <h2>{{ $t("workspace.checkoutModal.subscriptionDetail") }}</h2>
            <!-- header : title and price -->
            <div class="subscription-header">
              <div class="subscription-name">
                {{ $t("workspace.checkoutModal.subscription") }}
                {{ selectedSubscription.name }}
              </div>
              <div class="subscription-price">
                <div class="d-inline">
                  {{ formatPrice(selectedSubscription.priceHt) }}
                </div>
                <div v-if="selectedSubscription.isMonthly">
                  {{ $t("workspace.subscription.perMonth") }}
                </div>
                <div v-else>{{ $t("workspace.subscription.perYear") }}</div>
              </div>
            </div>
            <template v-if="user.currentSubscription">
              <template v-if="upgradeSubscriptionUpcomingInvoice(selectedSubscription)">
                <div class="d-flex flex-row">
                  <div class="d-flex justify-content-end flex-fill">
                    <span class="subscription-price">
                      <p class="font-weight-bold">
                        {{
                          $t("workspace.checkoutModal.prorata", {
                            prorataAmount: formatPrice(
                              upgradeSubscriptionUpcomingInvoice(selectedSubscription)
                                .amount_due
                            ),
                          })
                        }}
                      </p>
                    </span>
                  </div>
                </div>
              </template>
            </template>
            <div
              v-for="feature in selectedSubscriptionFeatures"
              :key="feature.id"
              class="feature-list-item"
            >
              <div class="active-feature">
                <span class="feature-icon fas fa-check"></span>
                <span class="feature-text">{{ feature.name }}</span>
              </div>
            </div>

            <!-- content : plan explanations -->
            <div class="mt-4">
              <template v-if="selectedSubscription.isMonthly">
                {{ $t("workspace.subscription.cancelAtAnyMoment") }}.
              </template>
              <template v-else>
                {{ $t("workspace.subscription.oneYearCommitment") }}.
              </template>
            </div>

            <div
              class="mt-3 change-plan-button"
              @click="$bvModal.show('subscription-modal')"
            >
              {{ $t("workspace.checkoutModal.changeSubscription") }}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { loadStripeCheckout } from "./load-checkout";
import Loading from "@mizogoo/components/workspace/layout/Loading";

export default {
  name: "StripeSubscription",
  components: {
    Loading,
  },
  props: {
    subscriptions: {
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      stripe: null,
      customer: null,
      price: null,
      elements: null,
      card: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
    };
  },
  computed: {
    ...mapState({
      selectedSubscriptionConstName: (state) =>
        state.subscriptionModal.selectedSubscription.constName,
      selectedSubscriptionFeatures: (state) =>
        state.subscriptionModal.selectedSubscription.features,
    }),
    ...mapGetters("accountSubscription", {
      isLoadingCreating: "isLoadingCreating",
      hasErrorCreating: "hasErrorCreating",
      errorCreating: "errorCreating",
      isLoadingUpdating: "isLoadingUpdating",
      hasErrorUpdating: "hasErrorUpdating",
      errorUpdating: "errorUpdating",
    }),
    selectedSubscription() {
      return this.subscriptions.find(
        (subscription) => subscription.constName === this.selectedSubscriptionConstName
      );
    },
    style() {
      return {
        base: {
          color: "#151f29",
          fontFamily: "Cera Pro",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#6c757d",
          },
        },
        invalid: {
          fontFamily: "Cera Pro",
          color: "#151f29",
        },
      };
    },
  },
  methods: {
    stripeElements() {
      loadStripeCheckout(this.pk, "v3", () => {
        const options = {
          locale: "fr",
        };
        this.stripe = window.Stripe(process.env.STRIPE_PUBLIC_KEY, options);

        this.elements = this.stripe.elements();

        this.cardNumber = this.elements.create("cardNumber", {
          style: this.style,
        });
        this.cardNumber.update({ placeholder: "XXXX XXXX XXXX XXXX" });
        this.cardNumber.mount("#card-number");

        this.cardNumber.on("focus", () => {
          let el = document.getElementById("card-errors");
          el.classList.add("focused");
        });

        this.cardNumber.on("blur", () => {
          let el = document.getElementById("card-errors");
          el.classList.remove("focused");
        });

        this.cardNumber.on("change", (event) => {
          this.displayError(event);
        });

        this.cardExpiry = this.elements.create("cardExpiry", {
          style: this.style,
        });
        this.cardExpiry.mount("#card-expiry");

        this.cardExpiry.on("focus", () => {
          let el = document.getElementById("card-errors");
          el.classList.add("focused");
        });

        this.cardExpiry.on("blur", () => {
          let el = document.getElementById("card-errors");
          el.classList.remove("focused");
        });

        this.cardExpiry.on("change", (event) => {
          this.displayError(event);
        });

        this.cardCvc = this.elements.create("cardCvc", { style: this.style });
        this.cardCvc.mount("#card-cvc");

        this.cardCvc.on("focus", () => {
          let el = document.getElementById("card-errors");
          el.classList.add("focused");
        });

        this.cardCvc.on("blur", () => {
          let el = document.getElementById("card-errors");
          el.classList.remove("focused");
        });

        this.cardCvc.on("change", (event) => {
          this.displayError(event);
        });

        let paymentForm = document.getElementById("payment-form");
        if (paymentForm) {
          paymentForm.addEventListener("submit", (evt) => {
            evt.preventDefault();

            this.isLoading = true;
            const card = this.cardNumber;

            // If a previous payment was attempted, get the lastest invoice
            const latestInvoicePaymentIntentStatus = localStorage.getItem(
              "latestInvoicePaymentIntentStatus"
            );

            if (latestInvoicePaymentIntentStatus === "requires_payment_method") {
              const invoiceId = localStorage.getItem("latestInvoiceId");
              const isPaymentRetry = true;
              // create new payment method & retry payment on invoice with new payment method
              this.createPaymentMethod({
                card,
                isPaymentRetry,
                invoiceId,
              });
            } else {
              // create new payment method & create subscription
              this.createPaymentMethod({ card });
            }
          });
        }
      });
    },
    displayError(event) {
      this.isLoading = false;
      let displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = "";
      }
    },
    createPaymentMethod({ card, isPaymentRetry, invoiceId }) {
      this.stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            name: this.user.email,
          },
        })
        .then((result) => {
          if (result.error) {
            this.displayError(result);
          } else {
            if (isPaymentRetry) {
              // Update the payment method and retry invoice payment
              this.retryInvoiceWithNewPaymentMethod({
                customerId: this.user.stripeId,
                paymentMethodId: result.paymentMethod.id,
                invoiceId: invoiceId,
                priceId: this.selectedSubscription.stripeId,
              });
            } else {
              if (
                this.user.currentSubscription &&
                this.user.currentSubscription.isValid &&
                !this.user.currentSubscription.isFree
              ) {
                // Update the subscription
                this.updateSubscription({
                  subscriptionId: this.user.currentSubscription.stripeId,
                  newPriceId: this.selectedSubscription.stripeId,
                });
              } else {
                // Create the subscription
                this.createSubscription({
                  customerId: this.user.stripeId,
                  paymentMethodId: result.paymentMethod.id,
                  priceId: this.selectedSubscription.stripeId,
                });
              }
            }
          }
        });
    },
    handlePaymentThatRequiresCustomerAction({
      subscription,
      invoice,
      priceId,
      paymentMethodId,
      isRetry,
    }) {
      if (
        (subscription && subscription.status === "active") ||
        subscription.status === "trialing"
      ) {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      }

      // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
      // If it's a retry, the payment intent will be on the invoice itself.
      let paymentIntent = invoice
        ? invoice.payment_intent
        : subscription.latest_invoice.payment_intent;

      if (
        paymentIntent.status === "requires_action" ||
        (isRetry === true && paymentIntent.status === "requires_payment_method")
      ) {
        return this.stripe
          .confirmCardPayment(paymentIntent.client_secret, {
            payment_method: paymentMethodId,
          })
          .then((result) => {
            if (result.error) {
              // start code flow to handle updating the payment details
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              throw result;
            } else {
              if (result.paymentIntent.status === "succeeded") {
                // There's a risk of the customer closing the window before callback
                // execution. To handle this case, set up a webhook endpoint and
                // listen to invoice.paid. This webhook endpoint returns an Invoice.
                return {
                  priceId: priceId,
                  subscription: subscription,
                  invoice: invoice,
                  paymentMethodId: paymentMethodId,
                };
              }
            }
          });
      } else {
        // No customer action needed
        return { subscription, priceId, paymentMethodId };
      }
    },
    handleRequiresPaymentMethod({ subscription, paymentMethodId, priceId }) {
      if (subscription.status === "active" || subscription.status === "trialing") {
        // subscription is active, no customer actions required.
        return { subscription, priceId, paymentMethodId };
      } else if (
        subscription.latest_invoice.payment_intent.status === "requires_payment_method"
      ) {
        // Using localStorage to store the state of the retry here
        // (feel free to replace with what you prefer)
        // Store the latest invoice ID and status
        localStorage.setItem("latestInvoiceId", subscription.latest_invoice.id);
        localStorage.setItem(
          "latestInvoicePaymentIntentStatus",
          subscription.latest_invoice.payment_intent.status
        );
        throw { error: { message: "Your card was declined." } };
      } else {
        return { subscription, priceId, paymentMethodId };
      }
    },
    async onSubscriptionComplete(result) {
      this.isLoading = false;
      // Payment was successful. Provision access to your service.
      // Remove invoice from localstorage because payment is now complete.
      this.clearCache();
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // the product your customer subscribed to.
      // Get the product by using result.subscription.price.product
      await this.$store.dispatch("accountSubscription/create", {
        form: {
          subscription: this.selectedSubscription.id,
          subscriptionStripeId: result.subscription.id,
        },
      });
      this.$gtag.event("conversion", {
        send_to: "AW-10851025598/L8q8CLvL7YcYEL6FlrYo",
        event_category: "Subscribe",
      });
      this.$bvModal.show("subscription-confirmation-modal");
    },
    async onUpdateComplete(result) {
      this.isLoading = false;
      // Payment was successful. Provision access to your service.
      // Remove invoice from localstorage because payment is now complete.
      this.clearCache();
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // the product your customer subscribed to.
      // Get the product by using result.subscription.price.product
      await this.$store.dispatch("accountSubscription/update", {
        form: {
          subscription: this.selectedSubscription.id,
          subscriptionStripeId: result.id,
        },
      });
      this.$bvModal.show("subscription-confirmation-modal");
    },
    createSubscription({ customerId, paymentMethodId, priceId }) {
      const formData = new FormData();
      formData.append("customerId", JSON.stringify(customerId));
      formData.append("paymentMethodId", JSON.stringify(paymentMethodId));
      formData.append("priceId", JSON.stringify(priceId));

      return (
        fetch("/api/payment/stripe/subscription/create-subscription", {
          method: "post",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned
          // by Stripe. Add the addional details we need.
          .then((result) => {
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              subscription: result,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
            };
          })
          // Some payment methods require a customer to do additional
          // authentication with their financial institution.
          // Eg: 2FA for cards.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // If attaching this card to a Customer object succeeds,
          // but attempts to charge the customer fail. You will
          // get a requires_payment_method error.
          .then(this.handleRequiresPaymentMethod)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.displayError(error);
          })
      );
    },
    async updateSubscription({ subscriptionId, newPriceId }) {
      const formData = new FormData();
      formData.append("subscriptionId", JSON.stringify(subscriptionId));
      formData.append("newPriceId", JSON.stringify(newPriceId));
      formData.append(
        "status",
        JSON.stringify(this.user.currentSubscription.stripeSubscription.status)
      );

      return (
        fetch("/api/payment/stripe/subscription/update-subscription", {
          method: "post",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          .then((response) => {
            return response;
          })
          // No more actions required. Provision your service for the user.
          .then(this.onUpdateComplete)
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.displayError(error);
          })
      );
    },
    retryInvoiceWithNewPaymentMethod({
      customerId,
      paymentMethodId,
      invoiceId,
      priceId,
    }) {
      const formData = new FormData();
      formData.append("customerId", JSON.stringify(customerId));
      formData.append("paymentMethodId", JSON.stringify(paymentMethodId));
      formData.append("invoiceId", JSON.stringify(invoiceId));

      return (
        fetch("/api/payment/stripe/subscription/retry-invoice", {
          method: "post",
          body: formData,
        })
          .then((response) => {
            return response.json();
          })
          // If the card is declined, display an error to the user.
          .then((result) => {
            if (result.error) {
              // The card had an error when trying to attach it to a customer
              throw result;
            }
            return result;
          })
          // Normalize the result to contain the object returned
          // by Stripe. Add the addional details we need.
          .then((result) => {
            return {
              // Use the Stripe 'object' property on the
              // returned result to understand what object is returned.
              invoice: result,
              paymentMethodId: paymentMethodId,
              priceId: priceId,
              isRetry: true,
            };
          })
          // Some payment methods require a customer to be on session
          // to complete the payment process. Check the status of the
          // payment intent to handle these actions.
          .then(this.handlePaymentThatRequiresCustomerAction)
          // No more actions required. Provision your service for the user.
          .then(this.onSubscriptionComplete)
          .catch((error) => {
            // An error has happened. Display the failure to the user here.
            // We utilize the HTML element we created.
            this.displayError(error);
          })
      );
    },
    clearCache() {
      localStorage.clear();
    },
    // Upgrade Subscription Upcoming Invoice
    upgradeSubscriptionUpcomingInvoice(subscription) {
      if (
        typeof this.user.currentSubscription.stripeUpgradeSubscriptionUpcomingInvoices[
          subscription.id
        ] !== "undefined"
      ) {
        return this.user.currentSubscription.stripeUpgradeSubscriptionUpcomingInvoices[
          subscription.id
        ];
      }

      return null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.stripeElements();
    });
  },
};
</script>

<style scoped lang="scss">
@import "../../../assets/css/stripe.scss";

#prices-and-payment {
  overflow-y: auto;
  max-height: 650px;

  .loading-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 25px;
    z-index: 2;
  }

  .payement-section {
    padding: 4rem;

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 2rem;
    }

    .terms {
      font-size: 0.9rem;

      .dark-link {
        color: $mizogoo-gray-dark;
      }
    }
  }

  .subscription-section {
    background-color: #e9e9e9;
    padding: 4rem;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;

    h2 {
      font-size: 1.2rem;
      text-decoration: underline;
      margin-bottom: 2rem;
    }

    .subscription-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      padding: 7px 12px;
      border: 1px solid #ccc;
      border-radius: 5px;

      .subscription-name {
        display: inline;
        flex: 3;
        font-weight: bold;
      }

      .subscription-price {
        display: flex;
        flex-direction: column;
        align-items: end;
        flex: 2;
        font-weight: bold;
        margin-left: 0.5rem;
        text-align: right;
      }
    }

    .feature-list-item {
      border: 0;
      margin-bottom: 0.7rem;
      color: #151f29;

      .active-feature {
        display: flex;
        align-items: center;

        .feature-text {
          text-align: left;
        }

        .feature-icon {
          margin-top: 0.4rem;
          margin-right: 0.8rem;
          width: 15px;
          height: 15px;
          color: #ff395e;
        }
      }
    }

    .change-plan-button {
      color: #ff395e;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
