import ProductReviewSummaryAPI from "../api/product_review_summary";

const FETCH = "FETCH",
  FETCH_SUCCESS = "FETCH_SUCCESS",
  FETCH_ERROR = "FETCH_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    productReviewSummary: null,
  },
  getters: {
    hasError(state) {
      return state.error !== null;
    },
  },
  mutations: {
    // Fetch
    [FETCH](state) {
      state.isLoading = true;
      state.error = null;
      state.productReviewSummary = null;
    },
    [FETCH_SUCCESS](state, productReviewSummary) {
      state.isLoading = false;
      state.error = null;
      state.productReviewSummary = productReviewSummary;
    },
    [FETCH_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.productReviewSummary = null;
    },
  },
  actions: {
    // Fetch
    async find({ commit }, payload) {
      commit(FETCH);
      try {
        let response = await ProductReviewSummaryAPI.find(payload.product);
        if (response.data.success === true) {
          commit(FETCH_SUCCESS, response.data.productReviewSummary);
          return response.data.productReviewSummary;
        } else {
          commit(FETCH_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(FETCH_ERROR);
        return null;
      }
    },
  },
};
