<template>
  <input
    ref="input"
    type="number"
    :value="currentValue"
    :min="min"
    :max="max"
    :step="step"
    :placeholder="placeholder"
    autocomplete="off"
    @change="change"
    @paste="paste"
  />
</template>

<script>
const isNaN = Number.isNaN || window.isNaN;
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/;

export default {
  name: "InputNumber",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    min: {
      type: Number,
      default: -Infinity,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    rounded: Boolean,
    step: {
      type: Number,
      default: 1,
    },
    modelValue: {
      type: Number,
      default: NaN,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      currentValue: NaN,
    };
  },
  methods: {
    change(event) {
      this.setValue(Math.min(this.max, Math.max(this.min, event.target.value)));
    },
    paste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData && !REGEXP_NUMBER.test(clipboardData.getData("text"))) {
        event.preventDefault();
      }
    },
    setValue(value) {
      const oldValue = this.currentValue;
      let newValue = this.rounded ? Math.round(value) : value;

      if (this.min <= this.max) {
        newValue = Math.min(this.max, Math.max(this.min, newValue));
      }

      if (this.step > 0) {
        const mod = newValue % this.step;

        if (mod !== 0) {
          newValue += this.step - mod;
        }
      }

      this.currentValue = newValue;
      if (newValue === oldValue) {
        this.$refs.input.value = newValue;
      }

      if (oldValue !== newValue && !isNaN(oldValue)) {
        this.$emit("update:modelValue", newValue, oldValue);
      }
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          !(isNaN(newValue) && typeof oldValue === "undefined") &&
          newValue !== this.currentValue
        ) {
          this.setValue(newValue);
        }
      },
    },
  },
};
</script>
