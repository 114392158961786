<template>
  <b-alert
    show
    :dismissible="dismissible"
    :variant="variant"
    class="mt-2 mb-2">
    <div v-if="errorIsArray">
      <ul class="list-unstyled mb-0">
        <li
          v-for="(message, index) in getMessage"
          :key="index">
          {{ message }}
        </li>
      </ul>
    </div>
    <div v-else v-html="getMessage">
    </div>
  </b-alert>
</template>

<script>
export default {
  name: "FlashBag",
  props: {
    message: {
      required: true
    },
    variant: {
      type: String,
      default: 'danger'
    },
    dismissible: {
      type: Boolean,
      default() {
        return false
      }
    },
  },
  computed: {
    errorIsArray() {
      return Array.isArray(this.message)
    },
    getMessage() {
      if (typeof this.message === 'undefined' || !this.message) {
        return this.$t('global.errorMessage')
      }

      return this.message
    }
  }
}
</script>
