import axios from 'axios'
import Tool from '@mizogoo/class/tool'

export default {
  findOne (creation) {
    return axios.post('/api/creation/' + creation, Tool.createFormData())
  },
  create (data) {
    return axios.post('/api/creation/create', Tool.createFormData(data))
  },
  update (creation, data) {
    return axios.post('/api/creation/update/' + creation, Tool.createFormData(data))
  },
  updateTemplate (creation, data) {
    return axios.post('/api/creation/update/' + creation + '/template', Tool.createFormData(data))
  },
  remove (data) {
    return axios.post('/api/creation/remove/' + data.id, Tool.createFormData())
  },
  rename (data) {
    return axios.post('/api/creation/rename/' + data.id, Tool.createFormData(data))
  },
  createComment (creationId, data) {
    return axios.post('/api/creation/' + creationId + '/comment/create', Tool.createFormData(data))
  },
  relatedDocumentCreate (data) {
    return axios.post('/api/creation/' + data.creation + '/related-document/create',
      Tool.createFormData(data.form),
      {
        onUploadProgress: function (progressEvent) {
          data.store.dispatch('creation/setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }.bind(this)
      })
  },
}
