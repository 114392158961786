<template>
  <b-container
    class="subscription-column text-center"
    :class="{ recommended: recommended, current: current }"
  >
    <div class="subscription-column-content-container">
      <div class="subscription-column-header">
        <div class="subscription-column-name">{{ name }}</div>
        <div class="subscription-column-price-container">
          <template v-if="formerPrice">
            <span class="subscription-column-price strikethrough"
              >{{ formerPrice }}€</span
            >
          </template>
          <span class="subscription-column-price">{{ price }}€</span>
          <span class="subscription-column-subprice">
            /{{
              billingFrequency === "MONTHLY"
                ? $t("front.subscription.global.month")
                : $t("front.subscription.global.year")
            }}
          </span>
        </div>
        <div class="subscription-column-extra-info">{{ extraInfo }}</div>
        <div class="subscription-column-description">{{ description }}</div>
      </div>

      <div class="subscription-column-features">
        <div class="feature-list">
          <template v-if="extraFeature">
            <div class="feature-list-item subscription-column-extra-feature">
              {{ extraFeature }}
            </div>
          </template>
          <div
            v-for="feat in features"
            :key="feat.id"
            class="feature-list-item"
            :class="{ 'recommended-feature': feat.functionality === functionality }"
          >
            <div class="active-feature">
              <span class="feature-text">{{ feat.name }}</span>
              <span class="feature-icon fas fa-check"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="subscription-column-button" :class="{ 'd-none': current }">
      <button
        class="btn btn-outline-primary"
        :class="subscriptionButtonId"
        @click="displayCheckoutModal"
      >
        {{ button.label }}
      </button>
    </div>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "SubscriptionColumn",
  props: [
    "id",
    "name",
    "price",
    "formerPrice",
    "billingFrequency",
    "description",
    "extraInfo",
    "extraFeature",
    "features",
    "button",
    "recommended",
    "current",
    "functionality",
  ],
  computed: {
    userHadFreePremiumTrial() {
      let hadFreePremiumTrial = false;
      if (this.user) {
        if (this.user.hadFreePremiumTrial) {
          hadFreePremiumTrial = true;
        } else if (this.user.currentSubscription !== null) {
          if (
            this.user.currentSubscription.isValid &&
            this.user.currentSubscription.isFree
          ) {
            hadFreePremiumTrial = true;
          } else {
            if (
              this.user.currentSubscription.stripeSubscription &&
              this.user.currentSubscription.stripeSubscription.status === "trialing"
            ) {
              hadFreePremiumTrial = true;
            }
          }
        }
      }
      return hadFreePremiumTrial;
    },
    subscriptionButtonId() {
      return (
        "subscribe-" + this.id.toLowerCase() + "-" + this.billingFrequency.toLowerCase()
      );
    },
  },
  methods: {
    ...mapMutations("subscriptionModal", {
      setSelectedSubscriptionConstName: "SET_SELECTED_SUBSCRIPTION_CONST_NAME",
      setSelectedSubscriptionFeatures: "SET_SELECTED_SUBSCRIPTION_FEATURES",
    }),
    displayCheckoutModal() {
      if (this.user) {
        if (this.userHadFreePremiumTrial) {
          let constName = this.id;
          if (this.billingFrequency === "YEARLY") {
            constName = constName + "_" + "ANNUAL";
          }
          this.setSelectedSubscriptionConstName(constName);
          this.setSelectedSubscriptionFeatures(this.features);
          this.$bvModal.show("checkout-modal");
        } else {
          this.$bvModal.show("premium-only-modal");
        }
      } else {
        window.localStorage.setItem("displaySubscriptionModal", true);
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* General column style */
.subscription-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  padding: 2rem !important;
  max-width: 390px !important;
  height: 100%;
  outline: 3px solid transparent;
  transition: outline 0.25s ease;
  position: relative;
  overflow: hidden;
  z-index: 2;
  border-radius: 15px;

  &:hover {
    outline: 3px solid #ff395e;
  }

  &.recommended {
    overflow: initial;
    outline: 3px solid #ff395e;

    &::before {
      content: "Recommandée";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 4px 16px;
      color: #fff;
      background: $mizogoo;
      border-radius: 50px;
    }
  }

  &.current {
    overflow: initial;
    outline: 3px solid $mizogoo-gray-medium;

    &::before {
      content: "Actuelle";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 4px 16px;
      color: #fff;
      background: $mizogoo-gray-medium;
      border-radius: 50px;
    }
  }

  .subscription-column-content-container {
    /* Column header style */
    .subscription-column-header {
      width: 100%;
      margin: auto;
      margin-bottom: 1rem;

      .subscription-column-name {
        width: 100%;
        font-weight: 600;
        color: #c2c2c2;
        text-transform: uppercase;
      }

      .subscription-column-price-container {
        .subscription-column-price {
          width: 100%;
          position: relative;
          font-size: 2.6rem;
          font-weight: bold;
          line-height: 1.2;
          color: #151f29;

          &.strikethrough {
            font-size: 1.5rem;

            &::before {
              content: "";
              width: 50px;
              height: 2px;
              display: inline-block;
              position: absolute;
              top: 0;
              bottom: 0;
              right: 0;
              left: 0;
              margin: auto;
              background-color: $mizogoo-gray-dark;
              transform: rotate(-25deg);
            }
          }
        }

        .subscription-column-subprice {
          font-weight: bold;
          color: $mizogoo-gray-dark;
        }
      }

      .subscription-column-extra-info {
        margin-top: 0.5rem;
        color: #c2c2c2;
        font-style: italic;
      }

      .subscription-column-description {
        margin-top: 2rem;
        color: $mizogoo-gray-dark;
        font-style: italic;
      }

      .subscription-upgrade {
        margin-top: 2rem;
        padding: 0.4rem;
        color: $mizogoo-gray-dark;
        background-color: $mizogoo-gray-light;
        border-radius: 4px;
      }
    }

    /* Column features style */
    .subscription-column-features {
      width: 100%;
      margin: auto;
      margin-top: 1rem;

      .subscription-column-extra-feature {
        text-align: left;
        text-decoration: underline;
      }

      .feature-list {
        width: 100%;
        font-weight: 300;

        .feature-list-item {
          border: 0;
          padding: 0.5rem;
          color: #151f29;

          &.recommended-feature {
            background-color: #fee4e9;
            border-radius: 5px;
          }

          .active-feature {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .feature-text {
              text-align: left;
            }

            .feature-icon {
              margin-left: 1rem;
              width: 15px;
              height: 15px;
              color: #ff395e;
            }
          }
        }
      }
    }
  }

  .subscription-column-button {
    margin-top: 2rem;
    justify-content: center;

    .btn {
      padding: 10px 28px;
      border: 2px solid #ff395e;
      border-radius: 150px;
      font-weight: 500;
    }
  }
}
</style>
