<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-text-left
      :image="this.featureTextLeft.image"
      :subheading="this.featureTextLeft.subheading"
      :heading="this.featureTextLeft.heading"
      :description="this.featureTextLeft.description"
    ></feature-text-left>

    <long-text
      :subheading="this.longText.subheading"
      :heading="this.longText.heading"
      :description="this.longText.description"
      :blocs="this.longText.blocs"
      :images="this.longText.images"
    ></long-text>

    <steps :heading="this.steps.heading" :steps="this.steps.steps"></steps>

    <div class="separator"></div>

    <features-presentation-video></features-presentation-video>

    <key-numbers></key-numbers>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import LongText from "@mizogoo/components/front/layout/LongText";
import Steps from "@mizogoo/components/front/layout/features/steps/Steps";
import FeaturesPresentationVideo from "@mizogoo/components/front/layout/features/FeaturesPresentationVideo";
import KeyNumbers from "@mizogoo/components/front/layout/KeyNumbers";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "AnalysisPage",
  components: {
    TopBanner,
    FeatureTextLeft,
    LongText,
    Steps,
    FeaturesPresentationVideo,
    KeyNumbers,
    InternalLinks,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.analysis.title");
    },
    metaDescription() {
      return this.$t("front.metadata.analysis.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.analysis.topBanner.heading"),
        description: this.$t("front.analysis.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/analysis/banner_restaurant_menu_analysis.jpg"),
          alt: this.$t("front.analysis.topBanner.imageAlt"),
        },
        buttons: [
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextLeft() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/analysis/analysis_charts_and_data.png"),
          alt: this.$t("front.analysis.featureTextLeft.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.analysis.featureTextLeft.subheading"),
        heading: this.$tm("front.analysis.featureTextLeft.heading"),
        description: this.$t("front.analysis.featureTextLeft.description"),
      };
    },
    longText() {
      return {
        subheading: this.$t("front.analysis.longText.subheading"),
        heading: this.$tm("front.analysis.longText.heading"),
        description: this.$t("front.analysis.longText.description"),
        blocs: [
          {
            heading: this.$tm("front.analysis.longText.blocs[0].heading"),
            description: this.$t("front.analysis.longText.blocs[0].description"),
          },
          {
            heading: this.$tm("front.analysis.longText.blocs[1].heading"),
            description: this.$t("front.analysis.longText.blocs[1].description"),
          },
          {
            heading: this.$tm("front.analysis.longText.blocs[2].heading"),
            description: this.$t("front.analysis.longText.blocs[2].description"),
          },
          {
            heading: this.$tm("front.analysis.longText.blocs[3].heading"),
            description: this.$t("front.analysis.longText.blocs[3].description"),
          },
        ],
        images: [
          {
            id: 0,
            src: require("@mizogoo/assets/img/front/analysis/restaurant_slate.jpg"),
            alt: this.$t("front.analysis.longText.imageAlt"),
          },
        ],
      };
    },
    steps() {
      return {
        heading: this.$tm("front.analysis.steps.heading"),
        steps: [
          {
            id: 0,
            heading: this.$tm("front.analysis.steps.steps[0].heading"),
            description: this.$t("front.analysis.steps.steps[0].description"),
          },
          {
            id: 1,
            heading: this.$tm("front.analysis.steps.steps[1].heading"),
            description: this.$t("front.analysis.steps.steps[1].description"),
          },
          {
            id: 2,
            heading: this.$tm("front.analysis.steps.steps[2].heading"),
            description: this.$t("front.analysis.steps.steps[2].description"),
          },
        ],
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "create",
            label: this.$t("front.internalLinks.buttons.create"),
          },
          {
            id: 1,
            link: "printing",
            label: this.$t("front.internalLinks.buttons.print"),
          },
          {
            id: 2,
            link: "analysis",
            label: this.$t("front.internalLinks.buttons.analysis"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ],
      };
    },
  },
};
</script>
