<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <!-- Border decoration -->
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-text-left
      :image="this.featureTextLeft.image"
      :subheading="this.featureTextLeft.subheading"
      :heading="this.featureTextLeft.heading"
      :description="this.featureTextLeft.description"
    ></feature-text-left>

    <feature-text-right
      :image="this.featureTextRight.image"
      :subheading="this.featureTextRight.subheading"
      :heading="this.featureTextRight.heading"
      :description="this.featureTextRight.description"
    ></feature-text-right>

    <div class="separator"></div>

    <steps :heading="this.steps.heading" :steps="this.steps.steps"></steps>

    <div class="separator"></div>

    <features-presentation-video></features-presentation-video>

    <key-numbers></key-numbers>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import FeatureTextRight from "@mizogoo/components/front/layout/features/FeatureTextRight";
import Steps from "@mizogoo/components/front/layout/features/steps/Steps";
import FeaturesPresentationVideo from "@mizogoo/components/front/layout/features/FeaturesPresentationVideo";
import KeyNumbers from "@mizogoo/components/front/layout/KeyNumbers";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "AppGamesPage",
  components: {
    TopBanner,
    FeatureTextLeft,
    FeatureTextRight,
    Steps,
    FeaturesPresentationVideo,
    KeyNumbers,
    InternalLinks,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.appGames.title");
    },
    metaDescription() {
      return this.$t("front.metadata.appGames.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.appGames.topBanner.heading"),
        description: this.$t("front.appGames.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/app/app_games/banner_app_games.jpg"),
          alt: this.$t("front.appGames.topBanner.imageAlt"),
        },
        buttons: [
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextLeft() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/app_games/app_games_quiz.png"),
          alt: this.$t("front.appGames.featureTextLeft.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.appGames.featureTextLeft.subheading"),
        heading: this.$tm("front.appGames.featureTextLeft.heading"),
        description: this.$t("front.appGames.featureTextLeft.description"),
      };
    },
    featureTextRight() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/app_games/app_games_quizzes.png"),
          alt: this.$t("front.appGames.featureTextRight.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.appGames.featureTextRight.subheading"),
        heading: this.$tm("front.appGames.featureTextRight.heading"),
        description: this.$t("front.appGames.featureTextRight.description"),
      };
    },
    steps() {
      return {
        heading: this.$tm("front.appGames.steps.heading"),
        steps: [
          {
            id: 0,
            heading: this.$tm("front.appGames.steps.steps[0].heading"),
            description: this.$t("front.appGames.steps.steps[0].description"),
          },
          {
            id: 1,
            heading: this.$tm("front.appGames.steps.steps[1].heading"),
            description: this.$t("front.appGames.steps.steps[1].description"),
          },
          {
            id: 2,
            heading: this.$tm("front.appGames.steps.steps[2].heading"),
            description: this.$t("front.appGames.steps.steps[2].description"),
          },
        ],
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "app_enriched",
            label: this.$t("front.internalLinks.buttons.appEnriched"),
          },
          {
            id: 1,
            link: "app_winepairing",
            label: this.$t("front.internalLinks.buttons.appWinepairing"),
          },
          {
            id: 2,
            link: "app_games",
            label: this.$t("front.internalLinks.buttons.appGames"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ],
      };
    },
  },
};
</script>

<style></style>
