import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll() {
    return axios.post("/api/account/wine/category", Tool.createFormData());
  },
  findOne(data) {
    return axios.post("/api/account/wine/category/" + data, Tool.createFormData());
  },
  create(data) {
    return axios.post("/api/account/wine/category/create", Tool.createFormData(data));
  },
  update(data) {
    return axios.post("/api/account/wine/category/update/" + data.id, Tool.createFormData(data));
  },
  remove(data) {
    return axios.post("/api/account/wine/category/remove/" + data, Tool.createFormData());
  },
  toggleWinesActivation (data) {
    return axios.post("/api/account/wine/category/toggle_wines_activation/" + data.wineCategory.id, Tool.createFormData(data));
  }
};
