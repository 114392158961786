import FinderAPI from "../api/finder";

const
  // Folder
  FETCH_ALL_FOLDER = "FETCH_ALL_FOLDER",
  FETCH_ALL_FOLDER_SUCCESS = "FETCH_ALL_FOLDER_SUCCESS",
  FETCH_ALL_FOLDER_ERROR = "FETCH_ALL_FOLDER_ERROR",

  CREATING_FOLDER = "CREATING_FOLDER",
  CREATING_FOLDER_SUCCESS = "CREATING_FOLDER_SUCCESS",
  CREATING_FOLDER_ERROR = "CREATING_FOLDER_ERROR",

  UPDATING_FOLDER = "UPDATING_FOLDER",
  UPDATING_FOLDER_SUCCESS = "UPDATING_FOLDER_SUCCESS",
  UPDATING_FOLDER_ERROR = "UPDATING_FOLDER_ERROR",

  REMOVING_FOLDER = "REMOVING_FOLDER",
  REMOVING_FOLDER_SUCCESS = "REMOVING_FOLDER_SUCCESS",
  REMOVING_FOLDER_ERROR = "REMOVING_FOLDER_ERROR",

  // File
  FETCH_ALL_FILE = "FETCH_ALL_FILE",
  FETCH_ALL_FILE_SUCCESS = "FETCH_ALL_FILE_SUCCESS",
  FETCH_ALL_FILE_ERROR = "FETCH_ALL_FILE_ERROR",

  CREATING_FILE = "CREATING_FILE",
  CREATING_FILE_SUCCESS = "CREATING_FILE_SUCCESS",
  CREATING_FILE_ERROR = "CREATING_FILE_ERROR",

  SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS",

  UPDATING_FILE = "UPDATING_FILE",
  UPDATING_FILE_SUCCESS = "UPDATING_FILE_SUCCESS",
  UPDATING_FILE_ERROR = "UPDATING_FILE_ERROR",

  UPDATING_FILE_FOLDER = "UPDATING_FILE_FOLDER",
  UPDATING_FILE_FOLDER_SUCCESS = "UPDATING_FILE_FOLDER_SUCCESS",
  UPDATING_FILE_FOLDER_ERROR = "UPDATING_FILE_FOLDER_ERROR",

  REMOVING_FILE = "REMOVING_FILE",
  REMOVING_FILE_SUCCESS = "REMOVING_FILE_SUCCESS",
  REMOVING_FILE_ERROR = "REMOVING_FILE_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All Folder
    isLoadingFetchAllFolder: false,
    errorFetchAllFolder: null,
    folders: [],
    // Creating Folder
    isLoadingCreatingFolder: false,
    errorCreatingFolder: null,
    // Updating Folder
    isLoadingUpdatingFolder: false,
    errorUpdatingFolder: null,
    // Removing Folder
    isLoadingRemovingFolder: false,
    errorRemovingFolder: null,
    // Fetch All File
    isLoadingFetchAllFile: false,
    errorFetchAllFile: null,
    files: [],
    // Creating File
    isLoadingCreatingFile: false,
    errorCreatingFile: null,
    uploadProgress: 0,
    // Updating File (rename)
    isLoadingUpdatingFile: false,
    errorUpdatingFile: null,
    // Updating File Folder
    isLoadingUpdatingFileFolder: false,
    errorUpdatingFileFolder: null,
    // Removing File
    isLoadingRemovingFileFolder: false,
    errorRemovingFileFolder: null,
  },
  getters: {
    // Fetch All Folder
    isLoadingFetchAllFolder(state) {
      return state.isLoadingFetchAllFolder;
    },
    hasErrorFetchAllFolder(state) {
      return state.errorFetchAllFolder !== null;
    },
    errorFetchAllFolder(state) {
      return state.errorFetchAllFolder;
    },
    hasFolders(state) {
      return state.folders.length > 0;
    },
    folders(state) {
      return state.folders;
    },
    // Creating Folder
    isLoadingCreatingFolder(state) {
      return state.isLoadingCreatingFolder;
    },
    hasErrorCreatingFolder(state) {
      return state.errorCreatingFolder !== null;
    },
    errorCreatingFolder(state) {
      return state.errorCreatingFolder;
    },
    // Updating Folder
    isLoadingUpdatingFolder(state) {
      return state.isLoadingUpdatingFolder;
    },
    hasErrorUpdatingFolder(state) {
      return state.errorUpdatingFolder !== null;
    },
    errorUpdatingFolder(state) {
      return state.errorUpdatingFolder;
    },
    // Removing Folder
    isLoadingRemovingFolder(state) {
      return state.isLoadingRemovingFolder;
    },
    hasErrorRemovingFolder(state) {
      return state.errorRemovingFolder !== null;
    },
    errorRemovingFolder(state) {
      return state.errorRemovingFolder;
    },
    // Fetch All File
    isLoadingFetchAllFile(state) {
      return state.isLoadingFetchAllFile;
    },
    hasErrorFetchAllFile(state) {
      return state.errorFetchAllFile !== null;
    },
    errorFetchAllFile(state) {
      return state.errorFetchAllFile;
    },
    hasFiles(state) {
      return state.files.length > 0;
    },
    files(state) {
      return state.files;
    },
    // Creating File
    isLoadingCreatingFile(state) {
      return state.isLoadingCreatingFile;
    },
    hasErrorCreatingFile(state) {
      return state.errorCreatingFile !== null;
    },
    errorCreatingFile(state) {
      return state.errorCreatingFile;
    },
    uploadProgress(state) {
      return state.uploadProgress;
    },
    // Updating File
    isLoadingUpdatingFile(state) {
      return state.isLoadingUpdatingFile;
    },
    hasErrorUpdatingFile(state) {
      return state.errorUpdatingFile !== null;
    },
    errorUpdatingFile(state) {
      return state.errorUpdatingFile;
    },
    // Updating File Folder
    isLoadingUpdatingFileFolder(state) {
      return state.isLoadingUpdatingFileFolder;
    },
    hasErrorUpdatingFileFolder(state) {
      return state.errorUpdatingFileFolder !== null;
    },
    errorUpdatingFileFolder(state) {
      return state.errorUpdatingFileFolder;
    },
    // Rmoveing File
    isLoadingRemovingFile(state) {
      return state.isLoadingRemovingFile;
    },
    hasErrorRemovingFile(state) {
      return state.errorRemovingFile !== null;
    },
    errorRemovingFile(state) {
      return state.errorRemovingFile;
    },
  },
  mutations: {
    // Fetch All Folder
    [FETCH_ALL_FOLDER](state) {
      state.isLoadingFetchAllFolder = true;
      state.errorFetchAllFolder = null;
      state.folders = [];
    },
    [FETCH_ALL_FOLDER_SUCCESS](state, folders) {
      state.isLoadingFetchAllFolder = false;
      state.errorFetchAllFolder = null;
      state.folders = folders;
    },
    [FETCH_ALL_FOLDER_ERROR](state, error) {
      state.isLoadingFetchAllFolder = false;
      state.errorFetchAllFolder = error;
      state.folders = [];
    },
    // Creating Folder
    [CREATING_FOLDER](state) {
      state.isLoadingCreatingFolder = true;
      state.errorCreatingFolder = null;
    },
    [CREATING_FOLDER_SUCCESS](state) {
      state.isLoadingCreatingFolder = false;
      state.errorCreatingFolder = null;
    },
    [CREATING_FOLDER_ERROR](state, error) {
      state.isLoadingCreatingFolder = false;
      state.errorCreatingFolder = error;
    },
    // Updating Folder
    [UPDATING_FOLDER](state) {
      state.isLoadingUpdatingFolder = true;
      state.errorUpdatingFolder = null;
    },
    [UPDATING_FOLDER_SUCCESS](state) {
      state.isLoadingUpdatingFolder = false;
      state.errorUpdatingFolder = null;
    },
    [UPDATING_FOLDER_ERROR](state, error) {
      state.isLoadingUpdatingFolder = false;
      state.errorUpdatingFolder = error;
    },
    // Removing Folder
    [REMOVING_FOLDER](state) {
      state.isLoadingRemovingFolder = true;
      state.errorRemovingFolder = null;
    },
    [REMOVING_FOLDER_SUCCESS](state, data) {
      state.isLoadingRemovingFolder = false;
      state.errorRemovingFolder = null;

      state.folders = data.folders;
      state.files = data.files;
    },
    [REMOVING_FOLDER_ERROR](state, error) {
      state.isLoadingRemovingFolder = false;
      state.errorRemovingFolder = error;
    },
    // Fetch All File
    [FETCH_ALL_FILE](state) {
      state.isLoadingFetchAllFile = true;
      state.errorFetchAllFile = null;
      state.files = [];
    },
    [FETCH_ALL_FILE_SUCCESS](state, files) {
      state.isLoadingFetchAllFile = false;
      state.errorFetchAllFile = null;
      state.files = files;
    },
    [FETCH_ALL_FILE_ERROR](state, error) {
      state.isLoadingFetchAllFile = false;
      state.errorFetchAllFile = error;
      state.files = [];
    },
    // Creaing File
    [CREATING_FILE](state) {
      state.isLoadingCreatingFile = true;
      state.errorCreatingFile = null;
    },
    [CREATING_FILE_SUCCESS](state, file) {
      state.isLoadingCreatingFile = false;
      state.errorCreatingFile = null;
      state.files.push(file);
    },
    [CREATING_FILE_ERROR](state, error) {
      state.isLoadingCreatingFile = false;
      state.errorCreatingFile = error;
    },
    [SET_UPLOAD_PROGRESS](state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },
    // Updating File
    [UPDATING_FILE](state) {
      state.isLoadingUpdatingFile = true;
      state.errorUpdatingFile = null;
    },
    [UPDATING_FILE_SUCCESS](state) {
      state.isLoadingUpdatingFile = false;
      state.errorUpdatingFile = null;
    },
    [UPDATING_FILE_ERROR](state, error) {
      state.isLoadingUpdatingFile = false;
      state.errorUpdatingFile = error;
    },
    // Updating File Folder
    [UPDATING_FILE_FOLDER](state) {
      state.isLoadingUpdatingFileFolder = true;
      state.errorUpdatingFileFolder = null;
    },
    [UPDATING_FILE_FOLDER_SUCCESS](state) {
      state.isLoadingUpdatingFileFolder = false;
      state.errorUpdatingFileFolder = null;
    },
    [UPDATING_FILE_FOLDER_ERROR](state, error) {
      state.isLoadingUpdatingFileFolder = false;
      state.errorUpdatingFileFolder = error;
    },
    // Removing File
    [REMOVING_FILE](state) {
      state.isLoadingRemovingFile = true;
      state.errorRemovingFile = null;
    },
    [REMOVING_FILE_SUCCESS](state) {
      state.isLoadingRemovingFile = false;
      state.errorRemovingFile = null;
    },
    [REMOVING_FILE_ERROR](state, error) {
      state.isLoadingRemovingFile = false;
      state.errorRemovingFile = error;
    },
  },
  actions: {
    // File
    async findAllFolder({ commit }) {
      commit(FETCH_ALL_FOLDER);
      try {
        let response = await FinderAPI.findAllFolder();
        if (response.data.success === true) {
          commit(FETCH_ALL_FOLDER_SUCCESS, response.data.folders);
          return response.data.folders;
        } else {
          commit(FETCH_ALL_FOLDER_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_FOLDER_ERROR);
        return null;
      }
    },
    async createFolder({ commit }, payload) {
      commit(CREATING_FOLDER);
      try {
        let response = await FinderAPI.createFolder(payload.data);
        if (response.data.success === true) {
          commit(CREATING_FOLDER_SUCCESS);
          return response.data.folder;
        } else {
          commit(CREATING_FOLDER_ERROR);
          return null;
        }
      } catch (error) {
        commit(CREATING_FOLDER_ERROR);
        return null;
      }
    },
    async updateFolder({ commit }, payload) {
      commit(UPDATING_FOLDER);
      try {
        let response = await FinderAPI.updateFolder(payload.data);
        if (response.data.success === true) {
          commit(UPDATING_FOLDER_SUCCESS);
          return response.data.folder;
        } else {
          commit(UPDATING_FOLDER_ERROR);
          return null;
        }
      } catch (error) {
        commit(UPDATING_FOLDER_ERROR);
        return null;
      }
    },
    async removeFolder({ commit }, payload) {
      commit(REMOVING_FOLDER);
      try {
        let response = await FinderAPI.removeFolder(payload.data);
        if (response.data.success === true) {
          commit(REMOVING_FOLDER_SUCCESS, response.data);
          return null;
        } else {
          commit(REMOVING_FOLDER_ERROR);
          return null;
        }
      } catch (error) {
        commit(REMOVING_FOLDER_ERROR);
        return null;
      }
    },
    // File
    async findAllFile({ commit }) {
      commit(FETCH_ALL_FILE);
      try {
        let response = await FinderAPI.findAllFile();
        if (response.data.success === true) {
          commit(FETCH_ALL_FILE_SUCCESS, response.data.documents);
          return response.data.documents;
        } else {
          commit(FETCH_ALL_FILE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_FILE_ERROR);
        return null;
      }
    },
    async creatingFile({ commit }, payload) {
      commit(CREATING_FILE);
      try {
        let response = await FinderAPI.createFile({
          store: this,
          form: payload.form,
        });
        if (response.data.success === true) {
          commit(CREATING_FILE_SUCCESS, response.data.document);
          return response.data.document;
        } else {
          commit(CREATING_FILE_ERROR);
          return null;
        }
      } catch (error) {
        commit(CREATING_FILE_ERROR);
        return null;
      }
    },
    setUploadProgress({ commit }, uploadProgress) {
      commit(SET_UPLOAD_PROGRESS, uploadProgress);
    },
    async updateFile({ commit }, payload) {
      commit(UPDATING_FILE);
      try {
        let response = await FinderAPI.updateFile(payload.data);
        if (response.data.success === true) {
          commit(UPDATING_FILE_SUCCESS);
          return response.data.document;
        } else {
          commit(UPDATING_FILE_ERROR);
          return null;
        }
      } catch (error) {
        commit(UPDATING_FILE_ERROR);
        return null;
      }
    },
    async updateFileFolder({ commit }, payload) {
      commit(UPDATING_FILE_FOLDER);
      try {
        let response = await FinderAPI.updateFileFolder(payload.data);
        if (response.data.success === true) {
          commit(UPDATING_FILE_FOLDER_SUCCESS);
          return response.data.document;
        } else {
          commit(UPDATING_FILE_FOLDER_ERROR);
          return null;
        }
      } catch (error) {
        commit(UPDATING_FILE_FOLDER_ERROR);
        return null;
      }
    },
    async removeFile({ commit }, payload) {
      commit(REMOVING_FILE);
      try {
        let response = await FinderAPI.removeFile(payload.data);
        if (response.data.success === true) {
          commit(REMOVING_FILE_SUCCESS);
          return response.data.document;
        } else {
          commit(REMOVING_FILE_ERROR);
          return null;
        }
      } catch (error) {
        commit(REMOVING_FILE_ERROR);
        return null;
      }
    },
  },
};
