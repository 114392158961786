import ReviewAPI from "../api/review";

const
  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR";

export default {
  namespaced: true,
  state: {
    // Creating
    isLoadingAction: false,
    errorAction: null,
  },
  getters: {
    // Creating
    isLoadingAction(state) {
      return state.isLoadingAction
    },
    errorAction(state) {
      return state.errorAction
    },
    hasErrorAction(state) {
      return state.errorAction !== null;
    },
  },
  mutations: {
    // Creating
    [CREATING](state) {
      state.isLoadingAction = true;
      state.errorAction = null;
    },
    [CREATING_SUCCESS](state) {
      state.isLoadingAction = false;
      state.errorAction = null;
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.errorAction = error;
    },
  },
  actions: {
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await ReviewAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
  },
};
