<template>
  <div class="mt-3 mb-3">
    <!-- Feature name -->
    <div class="feature-name">
      {{ step.name }} ({{ $t("workspace.creation.form.price").toLowerCase() }})
    </div>

    <!-- Quantity selection (only displays the 8 first initialy) -->
    <div class="quantities-container">
      <div
        v-for="price in limitedPrices"
        :key="price.id"
        @click.prevent.stop="onClickQuantity(price)"
        :class="['quantity-container', { 'active-quantity-container': quantityIsSelected(price) }]"
      >
        <div>
          {{ price.quantity }} ex.
        </div>
        <div class="quantity">
          {{ formatPrice(price.priceHt) }}
        </div>
      </div>
      
      <!-- Remaining quantities (only displayed when the collapse button is pressed) -->
      <b-collapse v-model="allPricesVisible" class="quantities-container w-100">
        <div
          v-for="price in remainingPrices"
          :key="price.id"
          @click.prevent.stop="onClickQuantity(price)"
          :class="['quantity-container', { 'active-quantity-container': quantityIsSelected(price) }]"
          :style="{width: 'calc(50% - 6px)'}"
        >
          <div>
            {{ price.quantity }} ex.
          </div>
          <div class="quantity">
            {{ formatPrice(price.priceHt) }}
          </div>
        </div>

        <!-- Ask for custom quantity -->
        <router-link
          v-if="maxQuantity"  
          :to="{ name: 'contact' }"
          target="_blank"
          class="quantity-container custom-quantity"
          >
            + de {{ maxQuantity.quantity }}, contactez nous
        </router-link>
      </b-collapse>

      <!-- Toggle collapse button -->
      <div v-if="remainingPrices.length > 0" class="show-more" @click="allPricesVisible = !allPricesVisible">
        <template v-if="allPricesVisible">
          {{ $t('front.product.showLess') }}
        </template>
        <template v-else>
          {{ $t('front.product.showMore') }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomizableQuantity",
  props: ["step", "prices", "maxQuantity", "currentQuantity"],
  data() {
    return {
      allPricesVisible: false,
    }
  },
  computed: {
    limitedPrices() {
      return this.prices.slice(0, 6);
    },
    remainingPrices() {
      return this.prices.slice(6);
    },
  },
  methods: {
    onClickQuantity(price) {
      this.$emit('onClickQuantity', price);
    },
    quantityIsSelected(price) {
      return price.quantity === this.currentQuantity;
    },
  }
}
</script>

<style lang="scss" scoped>
.quantity-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // Have to remove gap size of 12px
  // 6px for each items since there is 2 per lines
  width: calc(50% - 6px);

  @media (max-width: 576px) {
    width: 100%;
  }

  .quantity {
    font-weight: bold;
  }
}

.custom-quantity {
  justify-content: center!important;
  color: $mizogoo-gray-dark;
  font-weight: 300;

  &:hover {
    text-decoration: none;
  }
}

.show-more {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
  
  &:hover {
    color: color.adjust($mizogoo-gray-dark, $lightness: -5%);
  }
}
</style>
