import InstitutionHomeAPI from "../api/institution_home";

const
  FETCH_INSTITUTION = "FETCH_INSTITUTION",
  FETCH_INSTITUTION_SUCCESS = "FETCH_INSTITUTION_SUCCESS",
  FETCH_INSTITUTION_ERROR = "FETCH_INSTITUTION_ERROR";

export default {
  namespaced: true,
  state: {
    institution: null,
    // Institution
    isLoadingInstitution: false,
    errorInstitution: null,
  },
  getters: {
    institution (state) {
      return state.institution;
    },
    // Institution
    isLoadingInstitution (state) {
      return state.isLoadingInstitution;
    },
    hasErrorInstitution (state) {
      return state.errorInstitution !== null;
    },
    errorInstitution (state) {
      return state.errorInstitution;
    },
  },
  mutations: {
    // Institution
    [FETCH_INSTITUTION] (state) {
      state.isLoadingInstitution = true;
      state.errorInstitution = null;
      state.institution = null;
    },
    [FETCH_INSTITUTION_SUCCESS] (state, institution) {
      state.isLoadingInstitution = false;
      state.errorInstitution = null;
      state.institution = institution;
    },
    [FETCH_INSTITUTION_ERROR] (state, error) {
      state.isLoadingInstitution = false;
      state.errorInstitution = error;
      state.institution = null;
    }
  },
  actions: {
    // Institution
    async fetchInstitution ({ commit }, payload) {
      commit(FETCH_INSTITUTION);
      try {
        let response = await InstitutionHomeAPI.institution(payload);

        if (response.data.success === true) {
          commit(FETCH_INSTITUTION_SUCCESS, response.data.institution);
          return response.data;
        } else {
          commit(FETCH_INSTITUTION_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_INSTITUTION_ERROR, error);
        return null;
      }
    }
  }
};
