import AccountProductCategoryPositionAPI from "../api/account_product_category_position";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    isCached: false,
    isLoading: false,
    isLoadingAction: false,
    error: null,
    productCategoryPositions: [],
  },
  getters: {
    isLoading (state) {
      return state.isLoading;
    },
    isLoadingAction (state) {
      return state.isLoadingAction;
    },
    hasError (state) {
      return state.error !== null;
    },
    error (state) {
      return state.error;
    },
    hasProductCategoryPositions (state) {
      return state.productCategoryPositions.length > 0;
    },
    productCategoryPositions (state) {
      return state.productCategoryPositions;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoading = true;
      state.error = null;
      state.productCategoryPositions = [];
    },
    [FETCH_ALL_SUCCESS] (state, productCategoryPositions) {
      state.isLoading = false;
      state.error = null;
      state.productCategoryPositions = productCategoryPositions;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoading = false;
      state.error = error;
      state.productCategoryPositions = [];
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [UPDATING_SUCCESS] (state, productCategoryPositions) {
      state.isLoadingAction = false;
      state.error = null;
      state.productCategoryPositions = productCategoryPositions;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.productCategoryPositions = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.accountProductCategoryPosition.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountProductCategoryPositionAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.productCategoryPositions);
            return response.data.productCategoryPositions;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountProductCategoryPositionAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.productCategoryPositions);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
