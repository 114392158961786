import InstitutionQrCodeHomeAPI from "../api/institution_qrcode_home";

const
  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR";

export default {
  namespaced: true,
  state: {
    institutionQrCode: null,
    isLoadingInstitutionQrCode: false,
    errorInstitutionQrCode: null,
  },
  getters: {
    institutionQrCode (state) {
      return state.institutionQrCode;
    },
    isLoadingInstitutionQrCode (state) {
      return state.isLoadingInstitutionQrCode;
    },
    hasErrorInstitutionQrCode (state) {
      return state.errorInstitutionQrCode !== null;
    },
    errorInstitutionQrCode (state) {
      return state.errorInstitutionQrCode;
    },
  },
  mutations: {
    [FETCH_ONE] (state) {
      state.isLoadingInstitutionQrCode = true;
      state.errorInstitutionQrCode = null;
      state.institutionQrCode = null;
    },
    [FETCH_ONE_SUCCESS] (state, institutionQrCode) {
      state.isLoadingInstitutionQrCode = false;
      state.errorInstitutionQrCode = null;
      state.institutionQrCode = institutionQrCode;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingInstitutionQrCode = false;
      state.errorInstitutionQrCode = error;
      state.institutionQrCode = null;
    }
  },
  actions: {
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await InstitutionQrCodeHomeAPI.findOne(payload.institutionQrCodeId);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS, response.data.institutionQrCode);
          return response.data;
        } else {
          commit(FETCH_ONE_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR, error);
        return null;
      }
    }
  }
};
