import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  create (data) {
    return axios.post("/api/account/subscription/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/subscription/update", Tool.createFormData(data));
  },
  invoicePdf (data) {
    return axios.post("/api/account/subscription/invoice/pdf", Tool.createFormData(data));
  },
};
