import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findOne (institutionQrCode) {
    return axios.post("/api/account/institution/qrcode/" + institutionQrCode, Tool.createFormData());
  },
  update (data) {
    return axios.post("/api/account/institution/qrcode/update", Tool.createFormData(data));
  },
  createPdf (data) {
    return axios.post("/api/account/institution/qrcode/pdf", Tool.createFormData(data));
  },
};
