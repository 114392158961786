<template>
  <b-container fluid class="products-overview-container">
    <b-container>
      <!-- Header (presentation text) -->
      <b-row align-v="end" align-h="between" class="mb-4">
        <b-col lg="8">
          <h3 v-scrollanimation="{ name: 'fade-up-appear' }">
            {{ $t("front.home.products.subheading") }}
          </h3>
          <div class="heading-container">
            <div
              v-for="(headingPart, index) in this.$tm('front.home.products.heading')"
              :key="index"
              class="heading-inner-container"
            >
              <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
            </div>
          </div>
          <div v-scrollanimation="{ name: 'fade-up', delay: 250 }">
            <p>{{ this.$t("front.home.products.description") }}</p>
          </div>
        </b-col>
        <b-col lg="4" class="text-right d-none d-md-block">
          <router-link :to="{ name: 'catalog' }" class="btn btn-outline-primary mb-3">
            <span>{{ $t("front.home.products.seeAllProducts") }}</span>
          </router-link>
        </b-col>
      </b-row>
      <!-- Product list -->
      <template v-if="isLoadingProductCategories">
        <div class="spinner-container">
          <b-spinner />
        </div>
      </template>
      <template v-else-if="hasErrorProductCategory">
        <div class="error-container">
          <b-alert show variant="danger">{{ errorProductCategories }}</b-alert>
        </div>
      </template>
      <template v-else-if="hasProductCategories && filteredProducts">
        <b-row>
          <b-col lg="9">
            <b-row>
              <b-col lg="4" sm="6">
                <ProductOverviewItem
                  v-if="filteredProducts[0]"
                  :product="filteredProducts[0].product"
                />
              </b-col>
              <b-col lg="4" sm="6">
                <ProductOverviewItem
                  v-if="filteredProducts[1]"
                  :product="filteredProducts[1].product"
                />
              </b-col>
              <b-col lg="4" sm="6">
                <ProductOverviewItem
                  v-if="filteredProducts[2]"
                  :product="filteredProducts[2].product"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="8" sm="6">
                <ProductOverviewItem
                  v-if="filteredProducts[3]"
                  :product="filteredProducts[3].product"
                />
              </b-col>
              <b-col lg="4" sm="6">
                <ProductOverviewItem
                  v-if="filteredProducts[4]"
                  :product="filteredProducts[4].product"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="3" sm="6">
            <ProductOverviewItem
              v-if="filteredProducts[5]"
              :product="filteredProducts[5].product"
              :fullHeight="true"
            />
          </b-col>
        </b-row>
        <b-row class="d-block d-md-none">
          <b-col class="text-center">
            <router-link :to="{ name: 'catalog' }" class="btn btn-outline-primary mt-3">
              <span>{{ $t("front.home.products.seeAllProducts") }}</span>
            </router-link>
          </b-col>
        </b-row>
      </template>
    </b-container>
  </b-container>
</template>

<script>
import ProductOverviewItem from "@mizogoo/components/front/layout/products/product/ProductOverviewItem";

export default {
  name: "ProductsOverview",
  components: {
    ProductOverviewItem,
  },
  computed: {
    // Product Category
    isLoadingProductCategories() {
      return this.$store.getters["product/isLoadingProductCategories"];
    },
    hasErrorProductCategory() {
      return this.$store.getters["product/hasErrorProductCategory"];
    },
    errorProductCategories() {
      return this.$store.getters["product/errorProductCategories"];
    },
    hasProductCategories() {
      return this.$store.getters["product/hasProductCategories"];
    },
    productCategories() {
      return this.$store.getters["product/productCategories"];
    },
    filteredProducts() {
      let filteredProducts = [];
      if (this.productCategories.length > 0) {
        for (let i = 0; i < this.productCategories.length; i++) {
          for (let j = 0; j < this.productCategories[i].products.length; j++) {
            switch (this.productCategories[i].products[j].constName) {
              case "MENU_PLA":
                filteredProducts.push({
                  id: 0,
                  product: this.productCategories[i].products[j],
                });
                break;
              case "MENU_PEL__A4":
                filteredProducts.push({
                  id: 1,
                  product: this.productCategories[i].products[j],
                });
                break;
              case "FLYER":
                filteredProducts.push({
                  id: 2,
                  product: this.productCategories[i].products[j],
                });
                break;
              case "TABLE_SET_PAPER":
                filteredProducts.push({
                  id: 3,
                  product: this.productCategories[i].products[j],
                });
                break;
              case "STANDARD_LOYALTY_CARD":
                filteredProducts.push({
                  id: 4,
                  product: this.productCategories[i].products[j],
                });
                break;
              case "MENU_HOLDER_TEXTURED":
                filteredProducts.push({
                  id: 5,
                  product: this.productCategories[i].products[j],
                });
                break;
              default:
                break;
            }
          }
        }
        return filteredProducts.sort((a, b) => a.id - b.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.products-overview-container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 7rem;
  padding-bottom: 6rem;
  background-color: $mizogoo-gray-light;
  border-radius: 50px;
}

@media screen and (max-width: 576px) {
  .products-overview-container {
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-radius: 30px;
  }
}
</style>
