<template>
  <div class="steps">
    <b-container>
      <b-row class="heading">
        <b-col>
          <h3 v-if="subheading">{{ subheading }}</h3>
          <h2>{{ heading }}</h2>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid class="steps-inner-container">
      <b-img
        :src="require('@mizogoo/assets/img/front/global/decorations/step_line.svg')"
        class="absolute-line"
      ></b-img>
      <b-container>
        <b-row>
          <b-col v-for="step in steps" :key="step.id" lg="4" md="12">
            <step-block
              :id="step.id"
              :heading="step.heading"
              :description="step.description"
            ></step-block>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import StepBlock from "@mizogoo/components/front/layout/features/steps/StepBlock";

export default {
  name: "Steps",
  components: {
    StepBlock
  },
  props: ["subheading", "heading", "steps"]
};
</script>

<style lang="scss" scoped>
.steps {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.steps-inner-container {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
}
.steps-inner-container .row {
  display: flex;
  flex-wrap: wrap;
}
.steps-inner-container .row [class*='col-'] {
  display: flex;
  flex-direction: column;
}
.absolute-line {
  position: absolute;
  top: 2.5rem;
  left: -1rem;
}

.heading {
  text-align: center;
}

@media screen and (max-width: 992px) {
  .steps-inner-container {
    padding-top: 2rem;
  }
  .absolute-line {
    display: none;
  }
}
</style>
