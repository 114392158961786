<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <!-- Image decoration -->
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <!-- Image -->
      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-text-left
      :image="this.featureTextLeft.image"
      :subheading="this.featureTextLeft.subheading"
      :heading="this.featureTextLeft.heading"
      :description="this.featureTextLeft.description"
    ></feature-text-left>

    <feature-text-right
      :image="this.featureTextRight.image"
      :subheading="this.featureTextRight.subheading"
      :heading="this.featureTextRight.heading"
      :description="this.featureTextRight.description"
    ></feature-text-right>

    <!-- Empty div which is used to create an offset when clicking on
    a link containing an anchor to the highlighted block below -->
    <div id="demo" class="pt-5 pb-5"></div>

    <highlighted-block
      :subheading="this.highlight.subheading"
      :heading="this.highlight.heading"
      :description="this.highlight.description"
      :image="this.highlight.image"
    ></highlighted-block>

    <div class="separator"></div>

    <steps :heading="this.steps.heading" :steps="this.steps.steps"></steps>

    <banner
      :image="this.banner.image"
      :subheading="this.banner.subheading"
      :heading="this.banner.heading"
      :description="this.banner.description"
      :extraInfo="this.banner.extraInfo"
      :button="this.banner.button"
      :overlay="this.banner.overlay"
    ></banner>

    <features-presentation-video></features-presentation-video>

    <key-numbers></key-numbers>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import FeatureTextRight from "@mizogoo/components/front/layout/features/FeatureTextRight";
import HighlightedBlock from "@mizogoo/components/front/layout/HighlightedBlock";
import Steps from "@mizogoo/components/front/layout/features/steps/Steps";
import Banner from "@mizogoo/components/front/layout/Banner";
import FeaturesPresentationVideo from "@mizogoo/components/front/layout/features/FeaturesPresentationVideo";
import KeyNumbers from "@mizogoo/components/front/layout/KeyNumbers";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "PdfMenu",
  components: {
    TopBanner,
    FeatureTextLeft,
    FeatureTextRight,
    HighlightedBlock,
    Steps,
    Banner,
    FeaturesPresentationVideo,
    KeyNumbers,
    InternalLinks,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.pdfMenu.title");
    },
    metaDescription() {
      return this.$t("front.metadata.pdfMenu.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.pdfMenu.topBanner.heading"),
        description: this.$t("front.pdfMenu.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/pdf-menu/banner_free_pdf_menu.jpg"),
          alt: this.$t("front.pdfMenu.topBanner.imageAlt"),
        },
        buttons: [
          {
            link: "pdf_menu",
            anchor: "#demo",
            label: this.$t("front.navbar.tryPdfMenu"),
          },
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextLeft() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/pdf-menu/pdf_menu_personalization.png"),
          alt: this.$t("front.pdfMenu.featureTextLeft.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.pdfMenu.featureTextLeft.subheading"),
        heading: this.$tm("front.pdfMenu.featureTextLeft.heading"),
        description: this.$t("front.pdfMenu.featureTextLeft.description"),
      };
    },
    featureTextRight() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/pdf-menu/where_to_put_qrcode.jpg"),
          alt: this.$t("front.pdfMenu.featureTextRight.imageAlt"),
        },
        subheading: this.$t("front.pdfMenu.featureTextRight.subheading"),
        heading: this.$tm("front.pdfMenu.featureTextRight.heading"),
        description: this.$t("front.pdfMenu.featureTextRight.description"),
      };
    },
    highlight() {
      return {
        subheading: this.$t("front.pdfMenu.highlight.subheading"),
        heading: this.$tm("front.pdfMenu.highlight.heading"),
        description: this.$t("front.pdfMenu.highlight.description"),
        image: {
          src: require("@mizogoo/assets/img/front/pdf-menu/mizogoo_pdf_menu_exemple_restaurant.png"),
          alt: this.$t("front.pdfMenu.highlight.imageAlt"),
          maxHeight: 280,
        },
      };
    },
    steps() {
      return {
        heading: this.$tm("front.pdfMenu.steps.heading"),
        steps: [
          {
            id: 0,
            heading: this.$tm("front.pdfMenu.steps.steps[0].heading"),
            description: this.$t("front.pdfMenu.steps.steps[0].description"),
          },
          {
            id: 1,
            heading: this.$tm("front.pdfMenu.steps.steps[1].heading"),
            description: this.$t("front.pdfMenu.steps.steps[1].description"),
          },
          {
            id: 2,
            heading: this.$tm("front.pdfMenu.steps.steps[2].heading"),
            description: this.$t("front.pdfMenu.steps.steps[2].description"),
          },
        ],
      };
    },
    banner() {
      return {
        image: {
          background: {
            src: require("@mizogoo/assets/img/front/global/banners/restaurant_menu.jpg"),
            alt: this.$t("front.pdfMenu.banner.imageAlt"),
          },
        },
        subheading: this.$t("front.pdfMenu.banner.subheading"),
        heading: this.$tm("front.pdfMenu.banner.heading"),
        description: this.$t("front.pdfMenu.banner.description"),
        button: {
          label: this.$t("front.pdfMenu.banner.buttonLabel"),
          link: "app",
        },
        overlay: true,
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "create",
            label: this.$t("front.internalLinks.buttons.create"),
          },
          {
            id: 1,
            link: "printing",
            label: this.$t("front.internalLinks.buttons.print"),
          },
          {
            id: 2,
            link: "analysis",
            label: this.$t("front.internalLinks.buttons.analysis"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ],
      };
    },
  },
};
</script>

<style></style>
