<template>
  <b-container class="highlighted-block-container">
    <div class="absolute-decoration-dotted-red"></div>
    <div class="absolute-decoration-loop-white"></div>
    <div class="inner-container">
      <b-container class="highlighted-block-inner-container">
        <b-row>
          <b-col md="7" sm="12">
            <h3>{{ highlight.subheading }}</h3>
            <h2>{{ highlight.heading }}</h2>
            <p>{{ highlight.description }}</p>
            <router-link
              :to="{ name: 'social_commitments' }"
              custom
              v-slot="{ navigate }"
            >
              <button class="btn btn-outline-primary mt-2" @click="navigate" @keypress.enter="navigate">
                {{ highlight.button.label }}
              </button>
            </router-link>
          </b-col>
          <b-col md="5" sm="12" class="d-flex align-items-center">
            <b-img-lazy
              fluid
              center
              :src="highlight.image.src"
              :alt="highlight.image.alt"
              :style="{ maxHeight: highlight.image.maxHeight + 'px'}"
              class="mt-2 mb-2"
            ></b-img-lazy>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "SocialCommitments",
  computed: {
    highlight() {
      return {
        subheading: this.$t("front.aboutUs.socialCommitments.subheading"),
        heading: this.$t("front.aboutUs.socialCommitments.heading"),
        description: this.$t("front.aboutUs.socialCommitments.description"),
        image: {
          src: require("@mizogoo/assets/img/front/about-us/save_the_planet.png"),
          alt: "",
          maxHeight: 280,
        },
        button: {
          label: this.$t("front.aboutUs.socialCommitments.buttonLabel"),
        },
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.highlighted-block-container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;

  .absolute-decoration-dotted-red {
    content: url(../../../assets/img/front/global/decorations/doted_decoration_colored.svg);
    position: absolute;
    bottom: 40px;
    left: -35px;
    z-index: 1;
  }

  .absolute-decoration-loop-white {
    content: url(../../../assets/img/front/global/decorations/small_loop_decoration.svg);
    position: absolute;
    top: 0;
    right: -90px;
    z-index: 3;
  }

  .inner-container {
    position: relative;
    padding: 6rem;
    background-color: $mizogoo-gray-light;
    z-index: 2;
  }
}

@media screen and (max-width: 992px) {
  .highlighted-block-container {
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .inner-container {
    padding: 3rem 2rem!important;
  }
}
</style>
