import AccountInstitutionReviewAPI from "../api/account_institution_review";

const FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    isCached: false,
    isLoading: false,
    isLoadingAction: false,
    errorFetchAll: null,
    institutionReviews: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isLoadingAction(state) {
      return state.isLoadingAction;
    },
    hasErrorFetchAll(state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll(state) {
      return state.errorFetchAll;
    },
    hasInstitutionReviews(state) {
      return state.institutionReviews.length > 0;
    },
    institutionReviews(state) {
      return state.institutionReviews;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoading = true;
      state.errorFetchAll = null;
      state.institutionReviews = [];
    },
    [FETCH_ALL_SUCCESS](state, institutionReviews) {
      state.isLoading = false;
      state.errorFetchAll = null;
      state.institutionReviews = institutionReviews;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoading = false;
      state.errorFetchAll = error;
      state.institutionReviews = [];
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.institutionReviews = [];
    },
  },
  actions: {
    // Fetch All
    async findAll({ commit }) {
      if (!this.state.accountInstitutionReview.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountInstitutionReviewAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.institutionReviews);
            return response.data.institutionReviews;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  },
};
