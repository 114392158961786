<template>
  <div id="front">
    <div id="cursor">
      <div id="cursor-outer"></div>
      <div id="cursor-inner"></div>
    </div>
    <Header />

    <main role="main">
      <router-view />
    </main>

    <Footer />
    <CookieConsent />

    <PremiumOnlyModal
      :functionality="premiumOnlyModalFunctionality"
      :heading="premiumOnlyModalHeading"
      :description="premiumOnlyModalDescription"
      :image="{
        src: premiumOnlyModalImage.src,
        alt: premiumOnlyModalImage.alt,
      }"
    />

    <SubscriptionModal :functionality="subscriptionModalFunctionality" />

    <template v-if="user">
      <SubscriptionConfirmationModal />
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AnimatedCursor from "animated-cursor";
import Header from "@mizogoo/components/front/layout/Header";
import Footer from "@mizogoo/components/front/layout/Footer";
import CookieConsent from "@mizogoo/components/global/cookie-consent/CookieConsent";
import PremiumOnlyModal from "@mizogoo/components/global/modal/PremiumOnlyModal";
import SubscriptionModal from "@mizogoo/components/global/modal/SubscriptionModal";
import SubscriptionConfirmationModal from "@mizogoo/components/global/modal/SubscriptionConfirmationModal";

let acOptions = {
  cursorInnerSelector: "#cursor-inner",
  cursorOuterSelector: "#cursor-outer",
  color: "#ff395e",
  outerAlpha: 0,
  outerBorderSize: 1,
  size: {
    inner: 8,
    outer: 38,
  },
  hoverScale: {
    inner: 0.5,
    outer: 1.4,
  },
  clickScale: {
    inner: 1.4,
    outer: 0.1,
  },
  cursorInnerStyles: {
    zIndex: 9999999,
  },
  cursorOuterStyles: {
    zIndex: 9999999,
  },
  hideNativeCursor: false,
};

export default {
  name: "Front",
  components: {
    Header,
    Footer,
    CookieConsent,
    PremiumOnlyModal,
    SubscriptionModal,
    SubscriptionConfirmationModal,
  },
  computed: {
    ...mapState({
      premiumOnlyModalFunctionality: (state) => state.premiumOnlyModal.functionality,
      premiumOnlyModalHeading: (state) => state.premiumOnlyModal.heading,
      premiumOnlyModalDescription: (state) => state.premiumOnlyModal.description,
      premiumOnlyModalImage: (state) => state.premiumOnlyModal.image,
    }),
    ...mapState({
      subscriptionModalFunctionality: (state) => state.subscriptionModal.functionality,
    }),
  },
  async mounted() {
    await this.$store.dispatch("subscription/findAll");
    await this.$store.dispatch("highlightedMessage/findThreeLast");
    await this.$store.dispatch("product/findProductCategories");
    await this.$store.dispatch("article/findTwoLast");

    let cursorInner = document.querySelector(acOptions.cursorInnerSelector);
    if (cursorInner) {
      const ac = AnimatedCursor(acOptions);
      ac.init();
    }
  },
};
</script>

<style lang="scss">
@import "@mizogoo/assets/css/front/front.scss";
</style>
