<template>
  <b-container id="customizable-product">
    <b-row v-if="hasError">
      <b-col>
        <FlashBag v-if="hasErrorProductCategory" :message="errorProductCategories"/>
        <FlashBag v-if="hasErrorProduct" :message="errorProduct"/>
        <FlashBag v-if="hasErrorMatrixCategories" :message="errorMatrixCategories"/>
        <FlashBag v-if="hasErrorModelCategories" :message="errorModelCategories"/>
        <FlashBag v-if="hasErrorFeature" :message="errorFeatures"/>
        <FlashBag v-if="hasErrorFeatureValues" :message="errorFeatureValues"/>
        <FlashBag v-if="hasErrorAttribut" :message="errorAttributs"/>
        <FlashBag v-if="hasErrorPrices" :message="errorPrices"/>
      </b-col>
    </b-row>

    <b-row>
      <!-- Product preview (images and breadcrumb) -->
      <b-col class="product-preview-col-container" md="5">
        <ProductPreview
            :product="product"
            :currentFeatureValues="currentFeatureValues"
            :featureValuesHasChanged="featureValuesHasChanged"
        />
      </b-col>

      <!-- Product feature selection -->
      <b-col class="product-configuration-container" md="7">
        <div class="product-configuration-inner-container">
          <!-- Product main informations (name and description) -->
          <div class="main-product-info-container">
            <h2 class="product-name">{{ product.name }}</h2>
            <!-- Average score -->
            <template v-if="product.productReviewSummary">
              <div class="average-score-container" @click="onClickShowReviews">
                <div class="average-stars-container">
                  <div
                      class="average-stars"
                      :style="'--percent: ' + (product.productReviewSummary.averageRating / 5) * 100 + '%'"
                  ></div>
                  <div class="absolute-stars">
                    <template v-for="i in 5" :key="'star-' + i">
                      <img
                          class="star"
                          :src="require('@mizogoo/assets/img/star_border.png')"
                      />
                    </template>
                  </div>
                </div>
                <div class="score">
                  {{ product.productReviewSummary.averageRating.toFixed(1) }}
                </div>
                <div>({{ product.productReviewSummary.totalReviews }} {{ $t('front.product.rating.reviews') }})</div>
              </div>
            </template>
            <p class="product-tagline">{{ product.description }}</p>
            <p class="product-description">
              <div class="product-description-show-more" @click="onClickShowFullDescription">
                {{ $t('front.product.showMore') }}
              </div>
              <span v-html="product.longDescription"></span>
            </p>
          </div>

          <!-- Initial loading -->
          <template v-if="isInitialLoading && isLoading">
            <div class="loading-overlay"/>
            <div class="loading-initial">
              <b-spinner/>
            </div>
          </template>

          <!-- Feature loading (displayed when changing product features) -->
          <div v-if="!isInitialLoading && isLoading" class="loading-prices">
            <b-spinner/>
          </div>

          <div
              v-if="!isInitialLoading"
              v-for="step in steps"
              :key="'step-' + step.id"
          >
            <template v-if="step.type === 'feature'">
              <template v-if="step.constName === 'FORMAT' || step.printComConstName === 'size' || step.printComConstName === 'fold'">
                <CustomizableFormat
                    :step="step"
                    :currentFeatureValues="currentFeatureValues"
                    :nbStepBeforeFeature="nbStepBeforeFeature"
                    @onClickFeatureValue="onClickFeatureValue"
                />
              </template>
              <template v-else>
                <CustomizableFeature
                    :step="step"
                    :currentFeatureValues="currentFeatureValues"
                    :nbStepBeforeFeature="nbStepBeforeFeature"
                    @onClickFeatureValue="onClickFeatureValue"
                />
              </template>
            </template>

            <template v-if="step.type === 'option'">
              <CustomizableAttribute
                  :step="step"
                  :currentAttributeValues="currentAttributValues"
                  :nbStepBeforeAttribute="nbStepBeforeAttribut"
                  @onClickAttributeValue="onClickAttributValue"
              />
            </template>

            <template v-if="step.type === 'quantity'">
              <CustomizableQuantity
                  :step="step"
                  :prices="prices"
                  :maxQuantity="maxQuantity"
                  :currentQuantity="currentQuantity"
                  @onClickQuantity="onClickQuantity"
              />
            </template>
          </div>

          <div v-if="product.isPrintable" class="product-price-container">
            <div class="product-price">
              <template v-if="product.isDeliverable">
                {{ $t('front.product.productPriceWithoutDelivery') }}
              </template>
              <template v-else>
                {{ $t('front.product.productPrice') }}
              </template>
              <span id="indicative-price">
                {{ $t('front.product.indicative') }}
              </span>
              <b-popover
                  target="indicative-price"
                  triggers="hover"
                  placement="lefttop"
              >
                <div class="indicative-ex-1">
                  {{ $t('front.product.indicativeExplanations1') }}
                </div>
                <div class="indicative-ex-2">
                  {{ $t('front.product.indicativeExplanations2') }}
                </div>
              </b-popover>
              <span v-if="currentPrice" class="product-price-value">{{
                  formatPrice(currentPrice.priceHt)
                }}</span>
            </div>

            <div class="product-team-for-the-planet">
              <span>{{ $t('front.product.with') }}</span>
              <img :src="require('@mizogoo/assets/img/products/team_for_the_planet_logo.png')"/>
              <span>Team for the Planet,</span>
              {{ $t('front.product.teamForThePlanet1') }}<br/>
              {{ $t('front.product.teamForThePlanet2') }}
              <i class="far fa-question-circle" id="team-for-the-planet-popover"></i>
              <b-popover
                  target="team-for-the-planet-popover"
                  triggers="hover"
                  placement="lefttop"
              >
                <div class="indicative-ex-1">
                  {{ $t('front.product.teamForThePlanetExplanations1') }}
                </div>
                <div class="indicative-ex-2">
                  <a href="https://join-time.com/fr" target="_blank">
                    {{ $t('front.product.teamForThePlanetExplanations2') }}
                  </a>
                </div>
              </b-popover>
            </div>
          </div>

          <div class="product-configuration-buttons-container">
            <div
                v-if="product.constName === 'BACKGROUND_SHEET'"
                class="product-add-cart-button"
                :class="isButtonEnabled ? null : 'disabled-product-button'"
                @click.prevent.stop="isButtonEnabled ? onClickAddCart() : null"
            >
              <div class="product-button-heading">
                {{ $t('front.product.addCart') }}
              </div>
              <i class="fas fa-angle-right"></i>
            </div>

            <template v-if="!hasMatrices && !hasModels">
              <div
                  v-if="product.isMenuMakable"
                  class="product-personalize-button"
                  :class="isButtonEnabled ? null : 'disabled-product-button'"
                  @click.prevent.stop="isButtonEnabled ? onClickPersonnalized() : null"
              >
                <div>
                  <div class="product-button-heading">
                    {{ $t('front.product.personalizeProduct') }}
                  </div>
                  <div class="product-button-description">
                    {{ $t('front.product.accessProduct') }}
                  </div>
                </div>
                <i class="fas fa-angle-right"></i>
              </div>
            </template>
            <template v-else>
              <div
                  v-if="product.isMenuMakable"
                  class="product-template-button"
                  :class="isButtonEnabled ? null : 'disabled-product-button'"
                  @click.prevent.stop="isButtonEnabled ? onClickTemplate() : null"
              >
                <div>
                  <div class="product-button-heading">
                    {{ $t('front.product.templateProduct') }}
                  </div>
                  <div class="product-button-description">
                    {{ $t('front.product.accessTemplate') }}
                  </div>
                </div>
                <i class="fas fa-angle-right"></i>
              </div>
            </template>

            <div
                id="product-do-it-for-me-button"
                class="product-do-it-for-me-button"
                :class="isButtonEnabled ? null : 'disabled-product-button'"
                @click.prevent.stop="isButtonEnabled ? onClickDoItForMe() : null"
            >
              <div>
                <div class="product-button-heading">
                  {{ $t('front.product.doItForMe') }} {{ $t('front.product.additionalCosts') }}
                </div>
                <div class="product-button-description">
                  {{ $t('front.product.delegateCreation') }}
                </div>
              </div>
              <i class="fas fa-angle-right"></i>
            </div>

            <div
                v-if="product.isPrintable"
                id="product-print-my-file-button"
                class="product-print-my-file-button"
                :class="isButtonEnabled ? null : 'disabled-product-button'"
                @click.prevent.stop="isButtonEnabled ? onClickPrintMyFile() : null"
            >
              <div>
                <div class="product-button-heading">
                  {{ $t('front.product.printMyFile') }} {{ $t('front.product.additionalCosts') }}
                </div>
                <div class="product-button-description">
                  {{ $t('front.product.printMyFileDescription') }}
                </div>
              </div>
              <i class="fas fa-angle-right"></i>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MetaMixin from '@mizogoo/mixins/meta.js'
import FlashBag from '@mizogoo/components/FlashBag'
import ProductPreview from '@mizogoo/components/front/pages/ProductPage/ProductPreview'
import CustomizableFormat from '@mizogoo/components/front/pages/ProductPage/CustomizableFormat'
import CustomizableFeature from '@mizogoo/components/front/pages/ProductPage/CustomizableFeature'
import CustomizableAttribute from '@mizogoo/components/front/pages/ProductPage/CustomizableAttribute'
import CustomizableQuantity from '@mizogoo/components/front/pages/ProductPage/CustomizableQuantity'

export default {
  mixins: [MetaMixin],
  name: 'ProductPageCustomizable',
  components: {
    FlashBag,
    ProductPreview,
    CustomizableFormat,
    CustomizableFeature,
    CustomizableAttribute,
    CustomizableQuantity,
  },
  props: ['product', 'matchProductName'],
  emits: ['showDescriptionTab'],
  data () {
    return {
      isInitialLoading: true,
      savedProductSettings: null,
      nbStepBeforeFeature: null,
      nbStepBeforeAttribut: null,
      steps: [],
      currentFeatureValues: [],
      currentAttributValues: [],
      currentQuantity: null,
      featureValuesHasChanged: false,
    }
  },
  computed: {
    ...mapState({
      errorProductCategories: (state) => state.product.errorProductCategories,
      productCategories: (state) => state.product.productCategories,
      errorProduct: (state) => state.product.errorProduct,
      isLoadingHasMatrices: (state) => state.product.isLoadingHasMatrices,
      errorHasMatrices: (state) => state.product.errorHasMatrices,
      hasMatrices: (state) => state.product.hasMatrices,
      isLoadingHasModels: (state) => state.product.isLoadingHasModels,
      errorHasModels: (state) => state.product.errorHasModels,
      hasModels: (state) => state.product.hasModels,
      isLoadingFeatures: (state) => state.product.isLoadingFeatures,
      errorFeatures: (state) => state.product.errorFeatures,
      features: (state) => state.product.features,
      isLoadingFeatureValues: (state) => state.product.isLoadingFeatureValues,
      errorFeatureValues: (state) => state.product.errorFeatureValues,
      featureValues: (state) => state.product.featureValues,
      isLoadingAttributs: (state) => state.product.isLoadingAttributs,
      errorAttributs: (state) => state.product.errorAttributs,
      isLoadingPrices: (state) => state.product.isLoadingPrices,
      errorPrices: (state) => state.product.errorPrices,
      prices: (state) => state.product.prices,
      isLoadingCreatingCartElement: (state) => state.cartElement.isLoadingCreating,
      errorCreatingCartElement: (state) => state.cartElement.errorCreating,
    }),
    ...mapGetters('product', {
      hasErrorProductCategory: 'hasErrorProductCategory',
      hasErrorProduct: 'hasErrorProduct',
      hasErrorMatrixCategories: 'hasErrorMatrixCategories',
      hasErrorModelCategories: 'hasErrorModelCategories',
      hasErrorFeature: 'hasErrorFeature',
      hasErrorFeatureValues: 'hasErrorFeatureValues',
      hasErrorAttribut: 'hasErrorAttribut',
      hasAttribut: 'hasAttribut',
      hasErrorPrices: 'hasErrorPrices',
      minQuantity: 'minQuantity',
      maxQuantity: 'maxQuantity',
    }),
    ...mapGetters('cartElement', {
      hasErrorCreatingCartElement: 'hasErrorCreating',
    }),
    // Global
    isLoading () {
      return (
          this.isLoadingHasMatrices ||
          this.isLoadingHasModels ||
          this.isLoadingFeatures ||
          this.isLoadingFeatureValues ||
          this.isLoadingAttributs ||
          this.isLoadingPrices
      )
    },
    hasError () {
      return (
          this.hasErrorProductCategory ||
          this.hasErrorProduct ||
          this.hasErrorFeature ||
          this.hasErrorFeatureValues ||
          this.hasErrorAttribut ||
          this.hasErrorPrices
      )
    },
    metaTitle () {
      return this.$t(`front.metadata.products.${this.matchProductName}.title`)
    },
    metaDescription () {
      return this.$t(`front.metadata.products.${this.matchProductName}.description`)
    },
    attributs () {
      return this.$store.getters['product/attributs'].filter(
          (element) => element.constName !== 'MAKING' && element.constName !== 'FILE_CHECK'
      )
    },
    currentPrice () {
      return this.prices.find((element) => element.quantity === this.currentQuantity)
    },
    isButtonEnabled () {
      if (this.product.isPrintable) {
        return (
            this.isLoadingPrices === false &&
            this.isLoadingCreatingCartElement === false &&
            this.currentPrice
        )
      } else {
        return (
            this.isLoadingCreatingCartElement === false
        )
      }
    },
  },
  methods: {
    // Step
    loadingSteps () {
      let id = 0

      this.nbStepBeforeFeature = id

      for (const key in this.features) {
        if (this.features.hasOwnProperty(key)) {
          this.steps.push({
            id: id,
            type: 'feature',
            name: this.features[key].name,
            featureId: this.features[key].id,
            featureValues: [],
            constName: this.features[key].constName,
            printComConstName: this.features[key].printComConstName,
          })

          id++
        }
      }

      if (this.hasAttribut) {
        this.nbStepBeforeAttribut = id

        for (const key in this.attributs) {
          if (this.attributs.hasOwnProperty(key)) {
            this.steps.push({
              id: id,
              type: 'option',
              name: this.attributs[key].name,
              attributId: this.attributs[key].id,
              attributValues: this.attributs[key].attributValues,
            })

            // On vérifie s'il y a des attributs pour une pré chargement
            if (
                this.savedProductSettings &&
                this.savedProductSettings.productAttributeValues
            ) {
              const attributValue = this.attributs[
                  key
                  ].attributValues.find((attributValue) =>
                  this.savedProductSettings.productAttributeValues.find(
                      (currentAttributValue) => currentAttributValue.id === attributValue.id
                  )
              )

              if (typeof attributValue !== 'undefined') {
                this.currentAttributValues[
                id - this.nbStepBeforeAttribut
                    ] = attributValue
              }
            } else {
              this.currentAttributValues[id - this.nbStepBeforeAttribut] = this.attributs[
                  key
                  ].attributValues[0]
            }

            id++
          }
        }
      }

      if (this.product.isPrintable) {
        this.steps.push({
          id: id++,
          type: 'quantity',
          name: 'Quantité',
        })
      }

      this.$nextTick(() => {
        this.buildFirstFeatureValue()
      })
    },
    // Feature Value
    buildFirstFeatureValue () {
      // On récupère la première featureValue de la liste
      let firstFeatureValue = this.featureValues[Object.keys(this.featureValues)[0]]

      // On récupère la step liée à cette featureValue
      let step = this.steps.find(
          (element) => element.featureId === firstFeatureValue.feature.id
      )

      // On vérifie s'il y a une feature pour une pré chargement
      if (
          this.savedProductSettings &&
          this.savedProductSettings.productFeatureValueKeys
      ) {
        if (
            typeof this.featureValues[
                this.savedProductSettings.productFeatureValueKeys[
                step.id - this.nbStepBeforeFeature
                    ]
                ] !== 'undefined'
        ) {
          firstFeatureValue = this.featureValues[
              this.savedProductSettings.productFeatureValueKeys[
              step.id - this.nbStepBeforeFeature
                  ]
              ]
        }
      }

      // On asigne à la step la liste complète des featureValues
      step.featureValues = this.featureValues

      // On définit la featureValue comme valeur de base
      this.currentFeatureValues[step.id - this.nbStepBeforeFeature] = firstFeatureValue

      // Si la première featureValue à des enfants on build
      if (Object.keys(firstFeatureValue.children).length) {
        this.buildFeatureValues(firstFeatureValue.children)
      } else {
        this.findMatrixes()
        this.findPrices()
      }
    },
    buildFeatureValues (featureValues) {
      // On récupère la première featureValue de la liste (des enfants)
      let firstFeatureValue = featureValues[Object.keys(featureValues)[0]]

      // On récupère la step liée à cette featureValue
      let step = this.steps.find(
          (element) => element.featureId === firstFeatureValue.feature.id
      )

      // On vérifie s'il y a une feature pour une pré chargement
      if (
          this.savedProductSettings &&
          this.savedProductSettings.productFeatureValueKeys
      ) {
        if (
            typeof featureValues[
                this.savedProductSettings.productFeatureValueKeys[
                step.id - this.nbStepBeforeFeature
                    ]
                ] !== 'undefined'
        ) {
          firstFeatureValue =
              featureValues[
                  this.savedProductSettings.productFeatureValueKeys[
                  step.id - this.nbStepBeforeFeature
                      ]
                  ]
        }
      }

      // On assigne à la step la liste complète des featureValues
      step.featureValues = featureValues

      // On définit la featureValue comme valeur de base
      this.currentFeatureValues[step.id - this.nbStepBeforeFeature] = firstFeatureValue

      // Si la première featureValue à des enfants on rappel la methode
      if (Object.keys(firstFeatureValue.children).length) {
        this.buildFeatureValues(firstFeatureValue.children)
      } else {
        this.findMatrixes()
        this.findPrices()
        this.featureValuesHasChanged = !this.featureValuesHasChanged
      }
    },
    onClickFeatureValue (step, featureValue) {
      this.currentFeatureValues[
      step.id - this.nbStepBeforeFeature
          ] = featureValue

      if (Object.keys(featureValue.children).length) {
        this.checkCurrentFeatureValue(step.id + 1, featureValue.children)
      } else {
        this.findMatrixes()
        this.findPrices()
      }

      this.featureValuesHasChanged = !this.featureValuesHasChanged
    },
    checkCurrentFeatureValue (currentStepId, featureValues) {
      // On récupère la step liée à currentStepId
      let step = this.steps.find((element) => element.id === currentStepId)

      // On assigne à la step la liste complète des featureValues
      step.featureValues = featureValues

      // On récupère la currentFeatureValue liée à la step
      let currentFeatureValue = this.currentFeatureValues[
      step.id - this.nbStepBeforeFeature
          ]

      // On vérifie si la currentFeatureValue existe dans la liste featureValues
      if (
          typeof featureValues[
          currentFeatureValue.position + '~' + currentFeatureValue.id
              ] === 'undefined'
      ) {
        // Si elle n'existe pas on réassigne la première
        this.currentFeatureValues[step.id - this.nbStepBeforeFeature] =
            featureValues[Object.keys(featureValues)[0]]
      } else {
        // Sinon on la met à jour
        this.currentFeatureValues[step.id - this.nbStepBeforeFeature] =
            featureValues[currentFeatureValue.position + '~' + currentFeatureValue.id]
      }

      // Si la currentFeatureValue à des enfants on rappel la methode
      if (
          Object.keys(
              this.currentFeatureValues[step.id - this.nbStepBeforeFeature].children
          ).length
      ) {
        this.checkCurrentFeatureValue(
            currentStepId + 1,
            this.currentFeatureValues[step.id - this.nbStepBeforeFeature].children
        )
      } else {
        this.findMatrixes()
        this.findPrices()
      }
    },
    // Attribut
    onClickAttributValue (step, attributValue) {
      this.currentAttributValues[
      step.id - this.nbStepBeforeAttribut
          ] = attributValue

      this.findPrices()
    },
    // Matrix
    async findMatrixes () {
      let productFeatureValueIds = []
      for (const currentFeatureValue of this.currentFeatureValues) {
        productFeatureValueIds.push(currentFeatureValue.id)
      }
      await this.$store.dispatch('product/hasMatrices', {
        data: {
          currentFeatureValueIds: productFeatureValueIds,
        },
      })
    },
    // Price
    async findPrices () {
      if (this.product.isPrintable) {
        let productFeatureValueKeys = []
        for (const currentFeatureValue of this.currentFeatureValues) {
          productFeatureValueKeys.push(
              currentFeatureValue.position + '~' + currentFeatureValue.id
          )
        }

        let attributValues = []
        for (const attributValue of this.currentAttributValues) {
          attributValues.push(attributValue.id)
        }

        await this.$store.dispatch('product/findPrices', {
          product: this.product,
          data: {
            allProductFeatureValues: this.featureValues,
            productFeatureValues: productFeatureValueKeys,
            attributValues: attributValues,
          },
        })
      } else {
        let productFeatureValueFormat = null
        for (const currentFeatureValue of this.currentFeatureValues) {
          if (currentFeatureValue.feature.constName === 'FORMAT' || currentFeatureValue.feature.printComConstName === 'size') {
            productFeatureValueFormat = currentFeatureValue.id
          }
        }

        let attributValues = []
        for (const attributValue of this.currentAttributValues) {
          attributValues.push(attributValue.id)
        }

        await this.$store.dispatch('product/findPrices', {
          product: this.product,
          data: {
            productFeatureValueFormat: productFeatureValueFormat,
            attributValues: attributValues,
            isMockup: true,
          },
        })
      }

      if (!this.currentQuantity && this.savedProductSettings) {
        this.currentQuantity = this.savedProductSettings.productQuantity
      } else if (this.minQuantity) {
        let hasSameCurrentQuantity = this.prices.find((price) => price.quantity == this.currentQuantity)
        if (!hasSameCurrentQuantity) {
          this.currentQuantity = this.minQuantity.quantity
        }
      }

      // Initial loading is ending when we have prices
      this.isInitialLoading = false
    },
    // Quantity
    onClickQuantity (price) {
      this.currentQuantity = price.quantity
    },
    // Save product data in the session storage
    saveProductData () {
      let productFeatureValueKeys = []
      for (const currentFeatureValue of this.currentFeatureValues) {
        productFeatureValueKeys.push(
            currentFeatureValue.position + '~' + currentFeatureValue.id
        )
      }

      localStorage.setItem(
          'savedProductSettings',
          JSON.stringify({
            productId: this.product.id,
            productName: this.product.name,
            productThumbnail: this.product.file.thumbnails['350_250'],
            productFeatureValueKeys: productFeatureValueKeys,
            productFeatureValues: this.currentFeatureValues,
            productAttributeValues: this.currentAttributValues,
            productQuantity: this.currentQuantity,
            productPrice: this.currentPrice ? this.currentPrice.priceHt : 0,
            productIsPrintable: this.product.isPrintable,
            productIsDeliverable: this.product.isDeliverable,
            productMenuMakers: this.product.productMenuMakers,
          })
      )
    },
    // Personnalized
    async onClickPersonnalized () {
      this.saveProductData()

      // Navigates to the builder (the creation will be created just before accessing the page, inside the router)
      this.$router.push({ name: 'redirect_menubuilder' })
    },
    // Template
    async onClickTemplate () {
      this.saveProductData()
      this.$router.push({ name: 'product_template', params: { productId: this.product.id } })
    },
    // Do it for me
    async onClickDoItForMe () {
      this.saveProductData()
      this.$router.push({ name: 'product_do_it_for_me', params: { productId: this.product.id } })
    },
    // Print my file
    async onClickPrintMyFile () {
      this.saveProductData()
      this.$router.push({ name: 'product_print_my_file', params: { productId: this.product.id } })
    },
    // Add cart
    async onClickAddCart () {
      let productFeatueValueKeys = []
      for (const currentFeatureValue of this.currentFeatureValues) {
        productFeatueValueKeys.push(
            currentFeatureValue.position + '~' + currentFeatureValue.id
        )
      }

      let attributValues = []
      for (const attributValue of this.currentAttributValues) {
        attributValues.push(attributValue.id)
      }

      await this.$store.dispatch('cartElement/create', {
        productId: this.product.id,
        data: {
          productFeatureValues: productFeatueValueKeys,
          attributValues: attributValues,
          quantity: this.currentQuantity,
        },
      })

      if (!this.hasErrorCreatingCartElement) {
        return this.$router.push({ name: 'cart' })
      }
    },
    onClickShowReviews () {
      document.querySelector('#product-reviews').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    },
    onClickShowFullDescription () {
      document.querySelector('#full-description').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })

      this.$emit('showDescriptionTab')
    },
  },
  async created () {
    // Try to retrieve product settings (page reload, back from template or do it for me pages)
    let savedProductSettings = JSON.parse(
        localStorage.getItem('savedProductSettings')
    )

    // Check if this is the same product
    if (savedProductSettings && savedProductSettings.productId === this.product.id) {
      this.savedProductSettings = savedProductSettings
    }

    // Anyway, remove saved data
    localStorage.removeItem('savedProductSettings')

    await Promise.all([
      this.$store.dispatch('product/hasModels', { productId: this.product.id }),
      this.$store.dispatch('product/findFeatures', { product: this.product }),
      this.$store.dispatch('product/findFeatureValues', { product: this.product }),
      this.$store.dispatch('product/findAttributs', { productId: this.product.id }),
    ])

    this.loadingSteps()
  },
  mounted () {
    // Add a listener to save product settings before unload (in case of a refresh)
    window.addEventListener('beforeunload', this.saveProductData)
  },
  beforeUnmount () {
    window.removeEventListener('beforeunload', this.saveProductData)
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.product-configuration-inner-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2.5rem 0 2.5rem 2rem;
  font-size: 1rem !important;

  @media (max-width: 576px) {
    padding: 0rem;
  }

  p {
    font-size: 1rem !important;
  }

  .main-product-info-container {
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      .product-name {
        display: none;
      }
    }

    .product-name {
      margin-bottom: 0.5rem !important;
    }

    .average-score-container {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 0;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      .score {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }

    .product-tagline {
      font-style: italic;
    }

    .product-description {
      --line-clamp: 4;
      display: -webkit-box;
      -webkit-line-clamp: var(--line-clamp);
      line-clamp: var(--line-clamp);
      -webkit-box-orient: vertical;
      hyphens: auto;
      overflow: hidden;
      width: 100%;
      white-space: initial !important;
    }

    .product-description::before {
      content: '';
      float: right;
      height: calc((var(--line-clamp) - 1) * 1lh);
    }

    .product-description .product-description-show-more {
      clear: both;
      float: right;
      margin-left: 1rem;
      margin-right: 3rem;
      text-decoration: underline;
      color: $mizogoo-gray-dark;
    }
  }

  .feature-name,
  .attribute-name {
    text-transform: uppercase;
  }

  .formats-container,
  .features-container,
  .attributes-container,
  .quantities-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0 12px;

    &.collapsing {
      display: flex !important;
      flex-wrap: wrap !important;
      gap: 0 12px !important;
    }

    .format-container,
    .feature-container,
    .attribute-container,
    .quantity-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem 1.5rem;
      margin-top: 12px;
      background-color: $mizogoo-gray-light;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;
      border: 2px solid transparent;
      border-radius: 10px;

      &:hover {
        background-color: color.adjust($mizogoo-gray-light, $lightness: -5%);
      }

      &.active-format-container,
      &.active-feature-container,
      &.active-attribute-container,
      &.active-quantity-container {
        border-color: $mizogoo;
      }
    }
  }

  .product-price-container {
    margin-top: 1rem;

    .product-price-value {
      margin-left: 0.8rem;
      font-size: 1.3rem;
      font-weight: 600;
    }

    #indicative-price {
      border: 0;
      border-bottom: 2px solid $mizogoo-gray-medium;
      border-style: dashed;
    }
  }

  .product-team-for-the-planet {
    margin-top: 1rem;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

.product-configuration-buttons-container {
  margin-top: 3rem;

  .product-personalize-button,
  .product-template-button,
  .product-do-it-for-me-button,
  .product-print-my-file-button,
  .product-add-cart-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out;

    .product-button-heading {
      font-weight: 500;
    }

    .fas {
      font-size: 1.5rem;
    }
  }

  .product-personalize-button,
  .product-template-button,
  .product-add-cart-button {
    background-color: $mizogoo;
    color: #fff;

    &:hover:not(.disabled-product-button) {
      background-color: color.adjust($mizogoo, $lightness: -5%);
    }
  }

  .product-do-it-for-me-button,
  .product-print-my-file-button {
    background-color: $mizogoo-gray-light;

    &:hover:not(.disabled-product-button) {
      background-color: color.adjust($mizogoo-gray-light, $lightness: -5%);
    }
  }

  .disabled-product-button {
    opacity: 0.8;
    cursor: initial;
  }
}

.loading-overlay,
.loading-prices {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}

.loading-initial {
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-prices {
  backdrop-filter: blur(2px);
}

table.table-quantity {
  tr:hover {
    cursor: pointer;
  }

  tr.selected {
    outline: 2px solid $mizogoo;
  }
}

.summary-action-container {
  margin-top: 2rem;
  padding: 2rem;
  background-color: $mizogoo-gray-light;

  h2 {
    margin-bottom: 1rem !important;
    font-size: 1.2rem !important;
  }

  p {
    margin-top: 1.6rem !important;
    margin-bottom: 2rem !important;
    font-size: 1rem !important;
    white-space: initial !important;
  }
}
</style>

<style lang="scss">
.wide-popover {
  min-width: 350px;
  font-weight: 300;

  ul {
    margin-top: 0.5rem;
    list-style: initial !important;
    padding-left: 20px;
  }
}

.indicative-ex-1 {
  font-weight: 300;
}

.indicative-ex-2 {
  margin-top: 0.5rem;
  font-weight: 500;
  font-style: italic;
}
</style>
