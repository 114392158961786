import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll() {
    return axios.post("/api/article/category", Tool.createFormData());
  },
  findOne(articleCategory) {
    return axios.post("/api/article/category/" + articleCategory, Tool.createFormData());
  },
};
