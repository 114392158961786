import Vue from "vue";
import AccountMenuCategoryAPI from "../api/account_menu_category";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  TOGGLE_MENUS_ACTIVATION = "TOGGLE_MENUS_ACTIVATION",
  TOGGLE_MENUS_ACTIVATION_SUCCESS = "TOGGLE_MENUS_ACTIVATION_SUCCESS",
  TOGGLE_MENUS_ACTIVATION_ERROR = "TOGGLE_MENUS_ACTIVATION_ERROR",
  
  ADD_ITEM_INTO_CATEGORY = "ADD_ITEM_INTO_CATEGORY",
  REMOVE_ITEM_FROM_CATEGORY = "REMOVE_ITEM_FROM_CATEGORY",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    menuCategories: [],
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Toggle Menus Activation
    isLoadingToggleMenusActivation: false,
    errorToggleMenusActivation: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasMenuCategories (state) {
      return state.menuCategories.length > 0;
    },
    menuCategories (state) {
      return state.menuCategories;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Toggle Menus Activation
    isLoadingToggleMenusActivation (state) {
      return state.isLoadingToggleMenusActivation;
    },
    hasErrorToggleMenusActivation (state) {
      return state.errorToggleMenusActivation !== null;
    },
    errorToggleMenusActivation (state) {
      return state.errorToggleMenusActivation;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.menuCategories = [];
    },
    [FETCH_ALL_SUCCESS] (state, menuCategories) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.menuCategories = menuCategories;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.menuCategories = [];
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state, menuCategory) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.menuCategories.push(menuCategory);
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state, menuCategory) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      let menuCategoryToUpdate = state.menuCategories.find(element => element.id === menuCategory.id);
      menuCategoryToUpdate.translations = menuCategory.translations;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, menuCategory) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.menuCategories = state.menuCategories.filter(element => element.id !== menuCategory);
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Toggle Menus Activation
    [TOGGLE_MENUS_ACTIVATION] (state) {
      state.isLoadingToggleMenusActivation = true;
      state.errorToggleMenusActivation = null;
    },
    [TOGGLE_MENUS_ACTIVATION_SUCCESS] (state, payload) {
      state.isLoadingToggleMenusActivation = false;
      state.errorToggleMenusActivation = null;

      const menusFromCategory = this.getters['accountMenu/menus'].filter(element => element.menuCategoryId === payload.menuCategory.id);

      for (let i = 0; i < menusFromCategory.length; i++) {
        let menuToActivate = menusFromCategory[i];
        menuToActivate.active = payload.value;
      }
    },
    [TOGGLE_MENUS_ACTIVATION_ERROR] (state, error) {
      state.isLoadingToggleMenusActivation = false;
      state.errorToggleMenusActivation = error;
    },
    // Add item into category
    [ADD_ITEM_INTO_CATEGORY] (state, menu) {
      let menuCategoryToUpdate = state.menuCategories.find(element => element.id === menu.menuCategoryId);
      menuCategoryToUpdate.menus.push(menu);
    },
    // Remove item from category
    [REMOVE_ITEM_FROM_CATEGORY] (state, menu) {
      let menuCategoryToUpdate = state.menuCategories.find(element => element.id === menu.menuCategoryId);
      let menuToRemoveIndex = menuCategoryToUpdate.menus.findIndex(element => element.id === menu.id);
      menuCategoryToUpdate.menus.splice(menuToRemoveIndex, 1);
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.menuCategories = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.accountMenuCategory.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountMenuCategoryAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.menuCategories);
            return response.data.menuCategories;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR, error);
          return null;
        }
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountMenuCategoryAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.menuCategory);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountMenuCategoryAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.menuCategory);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountMenuCategoryAPI.remove(payload.menuCategory);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.menuCategory);
          return response.data;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async toggleMenusActivation ({ commit }, payload) {
      commit(TOGGLE_MENUS_ACTIVATION);
      try {
        let response = await AccountMenuCategoryAPI.toggleMenusActivation(payload.form);
        if (response.data.success === true) {
          commit(TOGGLE_MENUS_ACTIVATION_SUCCESS, payload.form);
          return response.data;
        } else {
          commit(TOGGLE_MENUS_ACTIVATION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLE_MENUS_ACTIVATION_ERROR, error);
        return null;
      }
    },
    async addItemIntoCategory ({ commit }, payload) {
      commit(ADD_ITEM_INTO_CATEGORY, payload.menu);
    },
    async removeItemFromCategory ({ commit }, payload) {
      commit(REMOVE_ITEM_FROM_CATEGORY, payload.menu);
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
