<template>
  <div class="step-block">
    <div class="number-part">
      <span>0</span>
      <span>{{ id + 1 }}</span>
      <span class="dot">.</span>
    </div>
    <div class="text-part">
      <h4>{{ heading }}</h4>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepBlock",
  props: ["id", "heading", "description"]
};
</script>

<style lang="scss" scoped>
.step-block {
  height: 100%;
  padding: 1rem 2rem 2rem 2rem;
  background-color: $mizogoo-gray-light;
  border-radius: 25px;
}
.number-part {
  font-size: 5rem;
  font-weight: bold;
  letter-spacing: -12px;
}
.number-part .dot {
  color: $mizogoo;
}
.text-part {
  padding-left: 2rem;
}

@media screen and (max-width: 992px) {
  .step-block {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important;
    max-width: 400px;
    margin: auto;
  }
}
</style>
