<template>
  <div class="cart-summary-container">
    <div class="cart-rows-container">
      <!-- Promotion -->
      <h4 class="mb-4">{{ $t("front.cart.promotion.title") }}</h4>

      <template v-if="promotion">
        <b-row class="mb-4">
          <b-col cols="12">
            <p>
              <strong>{{ promotion.code }}</strong>
            </p>
            <p>
              <span>{{ promotion.description }}</span>
              <router-link :to="{ name: 'promotions' }" class="more-info-link">
                <span>{{ $t("workspace.global.learnMore") }}</span>
              </router-link>
            </p>
          </b-col>
          <b-col cols="12" class="text-center">
            <button
              class="btn btn-outline-primary p-2"
              @click.prevent.stop="onRemovePromotion()"
              :disabled="isLoadingRemovingPromotion"
            >
              <template v-if="!isLoadingRemovingPromotion">
                {{ $t("front.cart.promotion.remove") }}
              </template>
              <b-spinner v-if="isLoadingRemovingPromotion" small class="ml-1"></b-spinner>
            </button>
          </b-col>
        </b-row>
      </template>
      <template v-else>
        <Form as="" ref="observerPromotion" v-slot="{ handleSubmit }">
          <b-form @submit="handleSubmit(onSubmitAddPromotion)">
            <b-row class="mt-4 mb-4">
              <b-col cols="12">
                <FormInput
                  :name="$t('front.cart.promotion.code')"
                  :displayName="false"
                  type="text"
                  id="promotion"
                  :placeholder="$t('front.cart.promotion.code') + '...'"
                  :rules="{ required: true }"
                  v-model="form.promotion.code"
                >
                  <template v-slot:helper>
                    <template v-if="message">
                      <p class="p-0 m-0" style="white-space: normal !important">
                        {{ message }}
                        <router-link :to="{ name: 'promotions' }" class="more-info-link">
                          <span>{{ $t("workspace.global.learnMore") }}</span>
                        </router-link>
                      </p>
                    </template>
                  </template>
                </FormInput>
              </b-col>

              <b-col cols="12">
                <div class="text-center">
                  <div v-if="isLoadingAddPromotion">
                    {{ $t("global.loading") }}
                  </div>
                  <button type="submit" class="btn btn-outline-primary p-2" v-else>
                    Appliquer
                  </button>
                </div>
              </b-col>
            </b-row>
          </b-form>
        </Form>
      </template>

      <!-- Summary title -->
      <h4 class="mb-4">{{ $t("front.cart.summary") }}</h4>

      <!-- Cart elements -->
      <template v-if="cartElements && !isIndex">
        <b-row
          v-for="element in cartElements"
          :key="'element-' + element.id"
          class="cart-element-container"
        >
          <b-col cols="4" class="p-0">
            <b-img
              :src="productFile(element.product).src"
              :alt="productFile(element.product).alt"
              fluid
            />
          </b-col>

          <b-col cols="8" class="pr-0 text-right text-lg-left">
            <div class="cart-element-info-container">
              <div class="font-weight-bold">
                <template v-if="element.creation !== null">
                  <template v-if="element.creation.name !== null">
                    {{ element.creation.name }}
                  </template>
                  <template v-else>
                    {{ $t("workspace.creation.noName") }}
                  </template>
                </template>
                <template v-else>
                  {{ element.product.name }}
                </template>
              </div>

              <template v-if="element.creation !== null">
                <div>
                  {{ element.product.name }}
                  <span class="ml-1 mr-1">–</span>
                  <span
                    v-for="(productFeatureValue, index) in element.creation
                      .productFeatureValues"
                    :key="productFeatureValue.id"
                  >
                    {{ productFeatureValue.value }}
                    <template
                      v-if="index !== element.creation.productFeatureValues.length - 1"
                    >
                      <span class="ml-1 mr-1">–</span>
                    </template>
                    <template v-if="productFeatureValue.isFormatVariable">
                      ({{ element.creation.width }}x{{ element.creation.height }}cm)
                    </template>
                  </span>
                </div>
                <div>
                  {{ formatPrice(prices[element.id].priceHt) }} ({{ element.quantity }}
                  ex.)
                </div>
              </template>
            </div>
          </b-col>
        </b-row>
      </template>

      <!-- Prices -->
      <div class="cart-summary-row">
        <div>{{ $t("front.cart.subTotal") }}</div>
        <div>{{ formatPrice(prices.amountElementsHt) }}</div>
      </div>
      <div class="cart-summary-row">
        <div>{{ $t("front.cart.shippingFees") }}</div>
        <div>{{ formatPrice(prices.amountDeliveryHt) }}</div>
      </div>
      <div class="cart-summary-row">
        <div>{{ $t("front.cart.tva") }}</div>
        <div>{{ formatPrice(prices.amountTax) }}</div>
      </div>

      <div class="cart-summary-row">
        <div>{{ $t("front.cart.total") }}</div>
        <div>{{ formatPrice(prices.amountTtc) }}</div>
      </div>
    </div>

    <div class="text-center">
      <template v-if="currentStep === 'cart'">
        <router-link :to="{ name: 'cart_address' }" custom v-slot="{ navigate }">
          <button
            class="btn btn-outline-primary"
            @click="navigate"
            @keypress.enter="navigate"
          >
            {{ $t("front.cart.order") }}
          </button>
        </router-link>
      </template>
      <template v-else-if="currentStep === 'delivery'">
        <button
          class="btn btn-outline-primary"
          @click.prevent.stop="onSubmit"
          :disabled="isLoading"
        >
          {{ $t("front.cart.payment") }}
          <b-spinner v-if="isLoading" small class="ml-1"></b-spinner>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import FormInput from "@mizogoo/components/form/FormInput";

export default {
  name: "CartPageSummary",
  components: {
    FormInput,
  },
  props: ["currentStep", "prices", "cartElements", "isIndex"],
  emits: ["onSubmit"],
  data() {
    return {
      isLoading: false,
      form: {
        promotion: {
          code: null,
        },
      },
      message: null,
    };
  },
  computed: {
    // Fetch Add Promotion
    isLoadingAddPromotion() {
      return this.$store.getters["cart/isLoadingAddPromotion"];
    },
    hasErrorAddPromotion() {
      return this.$store.getters["cart/hasErrorAddPromotion"];
    },
    errorAddPromotion() {
      return this.$store.getters["cart/errorAddPromotion"];
    },
    hasMessage() {
      return this.message !== null;
    },
    // Removing Promotion
    isLoadingRemovingPromotion() {
      return this.$store.getters["cart/isLoadingRemovingPromotion"];
    },
    hasErrorRemovingPromotion() {
      return this.$store.getters["cart/hasErrorRemovingPromotion"];
    },
    errorRemovingPromotion() {
      return this.$store.getters["cart/errorRemovingPromotion"];
    },
    // Get Promotion
    promotion() {
      let promotion = null;

      for (let cartElement of this.cartElements) {
        if (cartElement.promotion) {
          promotion = cartElement.promotion;
        }
      }

      return promotion;
    },
  },
  methods: {
    productFile(product) {
      return {
        src: product.file.thumbnails["100_100"],
        alt: product.name,
      };
    },
    async onSubmit() {
      this.isLoading = true;
      await this.$emit("onSubmit");
      this.isLoading = false;
    },
    // Add Promotion
    async onSubmitAddPromotion() {
      if (await this.$refs.observerPromotion.validate()) {
        this.message = null;

        const response = await this.$store.dispatch("cart/addPromotion", {
          form: this.form,
        });

        if (!this.hasErrorAddPromotion) {
          this.message = response.message;
        }

        this.form.promotion.code = null;
      }
    },
    // Remove Promotion
    async onRemovePromotion() {
      await this.$store.dispatch("cart/removePromotion");
    },
  },
};
</script>

<style lang="scss" scoped>
.cart-summary-container {
  padding: 1rem 0.5rem;

  .cart-rows-container {
    margin-top: 2rem;
    margin-bottom: 2rem;

    .cart-element-container {
      width: 100%;
      margin: auto;
      padding-top: 1.2rem;
      padding-bottom: 1.2rem;
      border-bottom: 1px solid #dbdbdb;
    }

    .cart-summary-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      border-bottom: 1px solid #dbdbdb;

      &:last-child {
        font-weight: bold;
        border-bottom: none;
      }
    }
  }

  .more-info-link {
    margin-right: 1rem;
    color: $mizogoo-gray-dark;
    text-decoration: underline;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $mizogoo-gray-medium;
    }
  }
}
</style>
