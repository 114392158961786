const
  SET_FUNCTIONALITY = "SET_FUNCTIONALITY",
  SET_SELECTED_SUBSCRIPTION_CONST_NAME = "SET_SELECTED_SUBSCRIPTION_CONST_NAME",
  SET_SELECTED_SUBSCRIPTION_FEATURES = "SET_SELECTED_SUBSCRIPTION_FEATURES";

export default {
  namespaced: true,
  state: {
    functionality: null,
    selectedSubscription: {
      constName: null,
      features: [],
    }
  },
  mutations: {
    [SET_FUNCTIONALITY](state, functionality) {
      state.functionality = functionality;
    },
    [SET_SELECTED_SUBSCRIPTION_CONST_NAME](state, constName) {
      state.selectedSubscription.constName = constName;
    },
    [SET_SELECTED_SUBSCRIPTION_FEATURES](state, features) {
      state.selectedSubscription.features = features;
    },
  },
}