import AllergenAPI from "../api/allergen";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    allergens: [],
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasAllergens (state) {
      return state.allergens.length > 0;
    },
    allergens (state) {
      return state.allergens;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.allergens = [];
    },
    [FETCH_ALL_SUCCESS] (state, allergens) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.allergens = allergens;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.allergens = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.allergen.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AllergenAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.allergens);
            return response.data.allergens;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
  }
}
