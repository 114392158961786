// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mizogoo > #qr-code {
  min-height: 90vh;
}
#mizogoo > #qr-code div.qr-code {
  min-height: 90vh;
}
#mizogoo > #qr-code div.qr-code:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0);
}
#mizogoo > #qr-code h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
#mizogoo > #qr-code h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
#mizogoo > #qr-code h3 {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
#mizogoo > #qr-code h4 {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
#mizogoo > #qr-code h5 {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
#mizogoo > #qr-code h6 {
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
#mizogoo > #qr-code p {
  font-size: 1.1rem;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/assets/css/qr-code/qr-code.scss"],"names":[],"mappings":"AAeA;EACE,gBAAA;AAdF;AAgBE;EACE,gBAAA;AAdJ;AAgBI;EACE,UAAA;EACA,+CAAA;AAdN;AAkBE;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAhBJ;AAmBE;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;AAjBJ;AAoBE;EACE,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AAlBJ;AAqBE;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;AAnBJ;AAsBE;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;AApBJ;AAuBE;EACE,iBAAA;EACA,iBAAA;EACA,mBAAA;AArBJ;AAwBE;EACE,iBAAA;AAtBJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n// @import '../../../../../node_modules/@fortawesome/fontawesome-free/css/all.min.css';\n// @import '../fonts';\n\n// @import '../../../../../node_modules/bootstrap/scss/functions';\n// @import '../variables';\n\n// @import '../global';\n\n// @import '../../../../../node_modules/bootstrap/scss/bootstrap';\n// @import '../../../../../node_modules/bootstrap-vue/src/index.scss';\n\n#mizogoo > #qr-code {\n  min-height: 90vh;\n\n  div.qr-code {\n    min-height: 90vh;\n\n    &:focus {\n      outline: 0;\n      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0);\n    }\n  }\n\n  h1 {\n    font-size: 2rem;\n    font-weight: bold;\n    margin-bottom: 2rem;\n  }\n\n  h2 {\n    font-size: 1.2rem;\n    font-weight: bold;\n    margin-bottom: 1rem;\n  }\n\n  h3 {\n    font-size: .9rem;\n    font-weight: bold;\n    text-transform: uppercase;\n    margin-bottom: 1rem;\n  }\n\n  h4 {\n    font-size: .9rem;\n    font-weight: bold;\n    margin-bottom: 1rem;\n  }\n\n  h5 {\n    font-size: .9rem;\n    font-weight: bold;\n    margin-bottom: 1rem;\n  }\n\n  h6 {\n    font-size: .9rem;\n    font-weight: bold;\n    margin-bottom: 1rem;\n  }\n\n  p {\n    font-size: 1.1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
