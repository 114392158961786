<template>
  <div v-if="isLoading" class="loading">
    <div class="text-center">
      <b-spinner label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RedirectCart',
  data () {
    return {
      isLoading: true,
    }
  },
  methods: {
    /**
     * Create cart element and add comments to the creation
     * @params creationId
     */
    async handleDoItForMe (creationId) {
      const savedProductSettings = JSON.parse(localStorage.getItem('savedProductSettings'))
      const tmpDoItForMe = JSON.parse(localStorage.getItem('tmpDoItForMe-' + savedProductSettings.productId))

      if (savedProductSettings && tmpDoItForMe) {
        if (savedProductSettings.productIsPrintable) {
          let productFeatureValueKeys = []
          for (const currentFeatureValue of savedProductSettings.productFeatureValues) {
            productFeatureValueKeys.push(currentFeatureValue.position + '~' + currentFeatureValue.id)
          }

          let attributeValueIds = []
          for (const attributeValue of savedProductSettings.productAttributeValues) {
            attributeValueIds.push(attributeValue.id)
          }

          await this.$store.dispatch('cartElement/create', {
            productId: savedProductSettings.productId,
            data: {
              creation: creationId,
              productFeatureValues: productFeatureValueKeys,
              attributValues: attributeValueIds,
              quantity: savedProductSettings.productQuantity,
            },
          })
        } else {
          await this.$store.dispatch('cartElement/create', {
            productId: savedProductSettings.productId,
            data: {
              creation: creationId,
              isMockup: true,
            },
          })
        }

        if (tmpDoItForMe.comments) {
          await this.$store.dispatch('creation/createComment', {
            creationId: creationId,
            form: {
              content: tmpDoItForMe.comments
            },
          })
        }

        // Move attached files inside the creation
        if (tmpDoItForMe.files.length > 0) {
          await this.$store.dispatch('doItForMe/moveDocuments', {
            creationId: creationId,
            files: tmpDoItForMe.files,
          })
        }
      }
    },
    /**
     * Create cart element and add comments to the creation
     * @params creationId
     */
    async handlePrintMyFile (creationId) {
      const savedProductSettings = JSON.parse(localStorage.getItem('savedProductSettings'))
      const tmpPrintMyFile = JSON.parse(localStorage.getItem('tmpPrintMyFile-' + savedProductSettings.productId))

      if (savedProductSettings && tmpPrintMyFile) {
        if (savedProductSettings.productIsPrintable) {
          let productFeatureValueKeys = []
          for (const currentFeatureValue of savedProductSettings.productFeatureValues) {
            productFeatureValueKeys.push(currentFeatureValue.position + '~' + currentFeatureValue.id)
          }

          let attributeValueIds = []
          for (const attributeValue of savedProductSettings.productAttributeValues) {
            attributeValueIds.push(attributeValue.id)
          }

          await this.$store.dispatch('cartElement/create', {
            productId: savedProductSettings.productId,
            data: {
              creation: creationId,
              productFeatureValues: productFeatureValueKeys,
              attributValues: attributeValueIds,
              quantity: savedProductSettings.productQuantity,
            },
          })
        } else {
          await this.$store.dispatch('cartElement/create', {
            productId: savedProductSettings.productId,
            data: {
              creation: creationId,
              isMockup: true,
            },
          })
        }

        if (tmpPrintMyFile.comments) {
          await this.$store.dispatch('creation/createComment', {
            creationId: creationId,
            form: {
              content: tmpPrintMyFile.comments
            },
          })
        }

        // Move attached files inside the creation
        if (tmpPrintMyFile.files.length > 0) {
          await this.$store.dispatch('doItForMe/moveDocuments', {
            creationId: creationId,
            files: tmpPrintMyFile.files,
          })
        }
      }
    }
  },
  async mounted () {
    // Hide header and footer
    let header = document.getElementById('header')
    let footer = document.getElementById('footer')

    if (header) { header.style.display = 'none' }
    if (footer) { footer.style.display = 'none' }

    try {
      const dataCreation = await this.createCreation()
      if (dataCreation) {
        try {
          await this.handleDoItForMe(dataCreation.creation.id)
          await this.handlePrintMyFile(dataCreation.creation.id)
          // Delete product settings and do it for me data from the local storage
          localStorage.removeItem('savedProductSettings')
          localStorage.removeItem('tmpDoItForMe-' + dataCreation.creation.product.id)
          localStorage.removeItem('tmpPrintMyFILE-' + dataCreation.creation.product.id)
          this.$router.replace({ name: 'cart' })
        } catch (error) {
          console.error('Erreur lors de handleDoItForMe OR handlePrintMyFile :', error)
          this.$router.replace({ name: 'catalog' })
        }
      }
    } catch (error) {
      console.error('Erreur lors de la création :', error)
      this.$router.replace({ name: 'catalog' })
    }

    this.isLoading = false
  },
  beforeUnmount () {
    // Display again header and footer
    let header = document.getElementById('header')
    let footer = document.getElementById('footer')

    if (header) { header.style.display = 'inherit' }
    if (footer) { footer.style.display = 'inherit' }
  }
}
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}
</style>
