export default {
  createFormData (data) {
    const formData = new FormData()

    if (typeof data !== 'undefined') {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (Array.isArray(data[key])) {
            for (const keyData in data[key]) {
              if (data[key].hasOwnProperty(keyData)) {
                if (Object.prototype.toString.call(data[key][keyData]) === '[object File]') {
                  formData.append(key + '[]', data[key][keyData])
                } else {
                  formData.append(key + '[' + keyData + ']', JSON.stringify(data[key][keyData]))
                }
              }
            }
          } else {
            if (Object.prototype.toString.call(data[key]) === '[object File]') {
              formData.append(key, data[key])
            } else {
              formData.append(key, JSON.stringify(data[key]))
            }
          }
        }
      }
    }

    let apiKey = process.env.API_KEY;
    apiKey = Buffer.from(apiKey.toString()).toString('base64')

    formData.append('apiKey', JSON.stringify(apiKey));

    return formData
  }
}
