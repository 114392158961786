import AccountAnalysisAPI from "../api/account_analysis";

const
  FETCH_ANALYSES = "FETCH_ANALYSES",
  FETCH_ANALYSES_SUCCESS = "FETCH_ANALYSES_SUCCESS",
  FETCH_ANALYSES_ERROR = "FETCH_ANALYSES_ERROR",

  FETCH_ONE_ANALYSIS = "FETCH_ONE_ANALYSIS",
  FETCH_ONE_ANALYSIS_SUCCESS = "FETCH_ONE_ANALYSIS_SUCCESS",
  FETCH_ONE_ANALYSIS_ERROR = "FETCH_ONE_ANALYSIS_ERROR",

  CREATING_ANALYSIS = "CREATING_ANALYSIS",
  CREATING_ANALYSIS_SUCCESS = "CREATING_ANALYSIS_SUCCESS",
  CREATING_ANALYSIS_ERROR = "CREATING_ANALYSIS_ERROR",

  REMOVING_ANALYSIS = "REMOVING_ANALYSIS",
  REMOVING_ANALYSIS_SUCCESS = "REMOVING_ANALYSIS_SUCCESS",
  REMOVING_ANALYSIS_ERROR = "REMOVING_ANALYSIS_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isLoadingAction: false,
    error: null,
    analyses: []
  },
  getters: {
    isLoading (state) {
      return state.isLoading;
    },
    isLoadingAction (state) {
      return state.isLoadingAction;
    },
    hasError (state) {
      return state.error !== null;
    },
    error (state) {
      return state.error;
    },
    hasAnalyses (state) {
      return state.analyses.length > 0;
    },
    analyses (state) {
      return state.analyses;
    }
  },
  mutations: {
    // Fetch all
    [FETCH_ANALYSES] (state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_ANALYSES_SUCCESS] (state, analyses) {
      state.isLoading = false;
      state.error = null;
      state.analyses = analyses;
    },
    [FETCH_ANALYSES_ERROR] (state, error) {
      state.isLoading = false;
      state.error = error;
      state.analyses = [];
    },
    // Fetch one
    [FETCH_ONE_ANALYSIS] (state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_ONE_ANALYSIS_SUCCESS] (state, analysis) {
      state.isLoading = false;
      state.error = null;
      state.analyses.unshift(analysis);
    },
    [FETCH_ONE_ANALYSIS_ERROR] (state, error) {
      state.isLoading = false;
      state.error = error;
      state.analyses = [];
    },
    // Create
    [CREATING_ANALYSIS] (state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [CREATING_ANALYSIS_SUCCESS] (state, analysis) {
      state.isLoadingAction = false;
      state.error = null;
      state.analyses.unshift(analysis);
    },
    [CREATING_ANALYSIS_ERROR] (state, error) {
      state.isLoadingAction = false;
      state.error = error;
      state.analyses = [];
    },
    // Remove
    [REMOVING_ANALYSIS] (state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [REMOVING_ANALYSIS_SUCCESS] (state, analysisId) {
      state.isLoadingAction = false;
      state.error = null;
      state.analyses = state.analyses.filter(element => element.id !== analysisId);
    },
    [REMOVING_ANALYSIS_ERROR] (state, error) {
      state.isLoadingAction = false;
      state.error = error;
      state.analyses = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ANALYSES);
      try {
        let response = await AccountAnalysisAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ANALYSES_SUCCESS, response.data.analyses);
          return response.data.analyses;
        } else {
          commit(FETCH_ANALYSES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ANALYSES_ERROR);
        return null;
      }
    },
    async findOne ({ commit }, payload ) {
      commit(FETCH_ONE_ANALYSIS);
      try {
        let response = await AccountAnalysisAPI.findOne(payload.analysisId);
        if (response.data.success === true) {
          commit(FETCH_ONE_ANALYSIS_SUCCESS, response.data.analysis);
          return response.data.analysis;
        } else {
          commit(FETCH_ONE_ANALYSIS_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ANALYSES_ERROR);
        return null;
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING_ANALYSIS);
      try {
        let response = await AccountAnalysisAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_ANALYSIS_SUCCESS, response.data.analysis);
          return response.data.analysis;
        } else {
          commit(CREATING_ANALYSIS_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ANALYSIS_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING_ANALYSIS);
      try {
        let response = await AccountAnalysisAPI.remove(payload.analysis);
        if (response.data.success === true) {
          commit(REMOVING_ANALYSIS_SUCCESS, payload.analysis.id);
          return null;
        } else {
          commit(REMOVING_ANALYSIS_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ANALYSIS_ERROR, error);
        return null;
      }
    },
  }
}
