<template>
  <div
    class="product-preview-container sticky-top"
    :style="{ paddingTop: frontHeader.height + 'px', marginTop: -frontHeader.height + 'px' }"
  >
    <!-- Breadcrumb -->
    <b-row v-if="productCategory && product">
      <b-col>
        <div class="product-breadcrumb" ref="breadcrumb">
          <router-link :to="{ name: 'catalog' }">
            {{ $t("front.navbar.catalog.products") }}
          </router-link>
          <i class="fas fa-angle-right"></i>
          <router-link :to="{ name: 'catalog', params: { category: productCategoryUrl }}">
            {{ productCategory.name }}
          </router-link>
          <i class="fas fa-angle-right"></i>
          <div class="active">
            {{ product.name }}
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="product" class="product-name">
      <b-col>
        <h2>{{ product.name }}</h2>
      </b-col>
    </b-row>

    <!-- Product images -->
    <b-row class="image-container">
      <b-col class="mh-100 d-flex flex-column align-items-center">
        <template v-if="isLoadingImages">
          <div class="placeholder" />
        </template>
        <template v-else-if="selectedFile">
          <b-img
            :src="selectedFile.file.src" 
            :alt="selectedFile.alt"
            :title="selectedFile.alt"
            class="main-image"
            fluid
          />
        </template>
        <template v-else>
          <b-img
            :src="product.file.src"
            :alt="product.name"
            class="main-image"
            fluid
          />
        </template>
        <div v-if="files.length > 1" class="preview-carousel">
          <button class="carousel-arrow left" @click.prevent="scrollCarousel(-1)">
            <i class="fas fa-angle-left"></i>
          </button>
          <div class="preview-images-container" ref="carousel">
            <div
              v-for="(file, index) in files"
              :key="index"
              :class="['preview-image-container', {'active-preview': selectedFile === file}]"
              @click.prevent.stop="onClickPreviewImage(index)"
            >
              <div class="preview-image-overlay"></div>
              <b-img
                :src="file.file.src"
                :alt="file.alt"
                :title="file.alt"
              />
            </div>
          </div>
          <button class="carousel-arrow right" @click.prevent="scrollCarousel(1)">
            <i class="fas fa-angle-right"></i>
          </button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: 'ProductPreview',
  props: ["product", "currentFeatureValues", "featureValuesHasChanged"],
  data() {
    return {
      isLoadingImages: true,
      selectedFile: null,
      files: [],
    }
  },
  computed: {
    ...mapState({
      isLoadingProductCategories: (state) => state.product.isLoadingProductCategories,
      productCategories: (state) => state.product.productCategories,
    }),
    productCategory() {
      return this.productCategories.find(
        (productCategory) => productCategory.id === this.product.productCategoryIds[0]
      );
    },
    productCategoryUrl() {
      return this.productCategory.name.toLowerCase().split(' ').join('-');
    },
  },
  methods: {
    handleImages() {
      let productFeatureValuesKey = '';
      for (let i = 0; i < this.currentFeatureValues.length; i++) {
        productFeatureValuesKey += this.currentFeatureValues[i].id;

        if (i < this.currentFeatureValues.length - 1) {
          productFeatureValuesKey += '~'
        }
      }

      const globalFiles = this.product.productPictures.filter(element => element.active && element.global && element.productFeatureValuesKey !== productFeatureValuesKey)
      const currentFiles = this.product.productPictures.filter(element => element.active && element.productFeatureValuesKey === productFeatureValuesKey)

      this.files = globalFiles.concat(currentFiles)
      this.files = [...new Map(this.files.map((element) => [element.file.id, element])).values()]

      this.selectedFile = this.files[0]
      this.isLoadingImages = false;
    },
    scrollCarousel(direction) {
      const carousel = this.$refs.carousel;
      const scrollAmount = 100; // Ajuste la quantité de défilement
      carousel.scrollBy({ left: direction * scrollAmount, behavior: "smooth" });
    },
    onClickPreviewImage(fileIndex) {
      this.selectedFile = this.files[fileIndex];
    },
  },
  created() {
    this.handleImages()
  },
  watch: {
    featureValuesHasChanged() {
      this.handleImages()
    },
  }
}
</script>

<style lang="scss" scoped>
.product-preview-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  z-index: initial !important;

  .product-breadcrumb {
    height: 86px;
    display: flex;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;  
    font-size: 0.9rem;
    gap: 10px;

    > *:not(:first-child) {
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;  
      overflow: hidden;
    }

    .active {
      font-weight: bold;
    }

    a {
      color: $mizogoo-gray-dark;
      font-weight: 400;
    }

    i {
      font-size: 0.8rem;
      color: #7A7D81;
    }
  }

  .product-name {
    display: none;
  }

  .image-container {
    max-height: calc(100% - 86px);
    display: flex;
    flex-grow: 1;
    padding-bottom: 1rem;

    .placeholder {
      width: 100%;
      height: 100%;
      background-color: $mizogoo-gray-light;
      border-radius: 15px;
    }

    .main-image {
      width: 100%;
      height: 100%;
      max-height: 600px;
      object-fit: cover;
      border-radius: 15px;
      background-color: $mizogoo-gray-light;
    }
  }

  .preview-carousel {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;

    .preview-images-container {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .preview-images-container::-webkit-scrollbar {
      display: none;
    }

    .preview-image-container {
      position: relative;
      flex: 0 0 auto;
      margin: 0 0.5rem;
      border: 2px solid transparent;
      border-radius: 8px;
      cursor: pointer;

      &.active-preview {
        border-color: $mizogoo;
      }

      .preview-image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;
        border-radius: 6px;
      }

      &:hover .preview-image-overlay {
        opacity: 1;
      }

      img {
        width: auto;
        height: 40px;
        border-radius: 6px;
        background-color: $mizogoo-gray-light;
      }
    }

    .carousel-arrow {
      border: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      background-color: transparent;

      &.left {
        margin-right: 1rem;
      }
      &.right {
        margin-left: 1rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .product-preview-container {
    height: initial;
  
    .product-breadcrumb {
      display: none;
    }

    .product-name {
      display: initial;
      margin-top: 2rem;
    }

    .image-container {
      .main-image {
        height: initial;
      }
    }
  }
}
</style>
