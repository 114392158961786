import HighlightedMessageAPI from "../api/highlighted_message";

const
  FETCH_THREE_LAST = "FETCH_THREE_LAST",
  FETCH_THREE_LAST_SUCCESS = "FETCH_THREE_LAST_SUCCESS",
  FETCH_THREE_LAST_ERROR = "FETCH_THREE_LAST_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    highlightedMessages: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    error(state) {
      return state.error;
    },
    hasError(state) {
      return state.error !== null;
    },
    highlightedMessages(state) {
      return state.highlightedMessages;
    },
    hasHighlightedMessage(state) {
      return state.highlightedMessages.length > 0;
    },
  },
  mutations: {
    // Fetch Three Last
    [FETCH_THREE_LAST](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_THREE_LAST_SUCCESS](state, highlightedMessages) {
      state.isLoading = false;
      state.error = null;
      state.highlightedMessages = highlightedMessages;
    },
    [FETCH_THREE_LAST_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.highlightedMessages = [];
    },
  },
  actions: {
    async findThreeLast({ commit }) {
      commit(FETCH_THREE_LAST);
      try {
        let response = await HighlightedMessageAPI.findThreeLast();
        if (response.data.success === true) {
          commit(FETCH_THREE_LAST_SUCCESS, response.data.highlightedMessages);
          return response.data.highlightedMessages;
        } else {
          commit(FETCH_THREE_LAST_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_THREE_LAST_ERROR);
        return null;
      }
    },
  }
}
