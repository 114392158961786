// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container[data-v-b2b2295a] {
  display: flex;
  flex: 1;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/workspace/layout/Loading.vue","webpack://./Loading.vue"],"names":[],"mappings":"AAKA;EACE,aAAA;EACA,OAAA;ACJF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.loading-container {\n  display: flex;\n  flex: 1;\n}\n",".loading-container {\n  display: flex;\n  flex: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
