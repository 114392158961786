<template>
  <div class="product-template-preview-container h-100 pt-4 pb-4 text-center">
    <b-row class="h-100">
      <b-col class="mh-100 d-flex flex-column justify-content-center align-items-center">
        <template v-if="selectedFile">
          <b-img
            :src="selectedFile.src"
            :alt="selectedFile.alt"
            :title="selectedFile.alt"
            class="h-100"
            fluid
          />
        </template>
        <template v-else>
          <template v-if="isVoidMatrix">
            <h5 class="mb-2">{{ $t('workspace.creation.matrix.empty') }}</h5>
          </template>
          <template v-else-if="isVoidModel">
            <h5 class="mb-2">{{ $t('workspace.creation.model.empty') }}</h5>
          </template>
        </template>

        <div class="preview-images-container">
          <div
            v-for="(file, index) in files"
            :key="index"
            :class="['preview-image-container', {'active-preview': selectedFile === file}]"
            @click.prevent.stop="onClickPreviewImage(index)"
          >
            <div class="preview-image-overlay"></div>
            <b-img
              :src="file.src"
              :alt="file.alt"
              :title="file.alt"
              fluid
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'ProductTemplatePreview',
  props: {
    matrix: {
      type: Object,
      default: null,
    },
    isVoidMatrix: {
      type: Boolean,
      default: null,
    },
    model: {
      type: Object,
      default: null,
    },
    isVoidModel: {
      type: Boolean,
      default: null,
    },
    productFeatureValueFormat: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      selectedFile: null,
      files: [],
    }
  },
  methods: {
    handleImages () {
      if (this.matrix) {

        if (this.matrix.preview.recto) {
          this.files.push({
            src: this.matrix.preview.recto,
            alt: this.matrix.name,
          })
        }

        if (this.matrix.preview.verso) {
          this.files.push({
            src: this.matrix.preview.verso,
            alt: this.matrix.name,
          })
        }
      } else if (this.model) {
        for (let i = 0; i < this.model.previews.length; i++) {
          this.files.push({
            src: this.model.previews[i],
            alt: this.model.name,
          })
        }
      }

      this.selectedFile = this.files[0]
    },
    onClickPreviewImage (fileIndex) {
      this.selectedFile = this.files[fileIndex]
    },
  },
  created () {
    this.handleImages()
  },
}
</script>

<style lang="scss" scoped>
.product-template-preview-container {
  min-height: 50vh;
  background-color: $mizogoo-gray-light;
  border-radius: 15px;

  img {
    object-fit: contain;
    max-height: 50vh;
    border-radius: 10px;
  }

  .preview-images-container {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;

    .preview-image-container {
      position: relative;
      margin-left: 0.4rem;
      margin-right: 0.4rem;
      border: 2px solid transparent;
      border-radius: 5px;
      cursor: pointer;

      &.active-preview {
        border-color: $mizogoo;
      }

      .preview-image-overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
        pointer-events: none;
        border-radius: 5px;
      }

      &:hover .preview-image-overlay {
        opacity: 1;
      }

      img {
        width: auto;
        height: 100px;
        border-radius: 5px;
      }
    }
  }
}
</style>
