<template>
  <b-container fluid class="contact-form-container" id="contact-form">
    <b-container class="form-inner-container">
      <div class="heading-part">
        <h3>{{ contact.subheading }}</h3>
        <h2>{{ contact.heading }}</h2>
      </div>

      <ContactForm />
    </b-container>
  </b-container>
</template>

<script>
import FlashBag from "@mizogoo/components/FlashBag";
import ContactForm from "@mizogoo/components/global/contact-form/ContactForm";

export default {
  name: "ContactSection",
  components: {
    FlashBag,
    ContactForm,
  },
  computed: {
    contact() {
      return {
        subheading: "Une question ? Une remarque ?",
        heading: "Contactez-nous",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.contact-form-container {
  padding-top: 8rem;
  padding-bottom: 5rem;
  margin-top: 10rem;
  background-image: url("~@mizogoo/assets/img/front/global/banners/we_do_it_for_you.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $background-color;

  .form-inner-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 5rem 6rem;
    background-color: $mizogoo-gray-light;

    .heading-part {
      text-align: center;
      margin-bottom: 3rem;
    }

    .submit-button {
      margin-top: 3rem;
      text-align: center;
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-form-container {
    background-image: none;
    padding: 0;

    .form-inner-container {
      padding: 4rem 1.5rem;
    }
  }
}
</style>

<style lang="scss">
.contact-form-container .form-inner-container .form-control {
  background-color: $white;
}
</style>
