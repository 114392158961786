import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  login (data) {
    return axios.post("/api/security/login", Tool.createFormData(data));
  },
  passwordReset (data) {
    return axios.post("/api/security/password/reset", Tool.createFormData(data));
  },
  passwordResetToken (data) {
    return axios.post("/api/security/password/reset/token", Tool.createFormData(data));
  },
  registration (data) {
    return axios.post("/api/security/registration", Tool.createFormData(data));
  },
  registrationValidation (data) {
    return axios.post("/api/security/registration/validation", Tool.createFormData(data));
  },
  registrationValidationEmail (data) {
    return axios.post("/api/security/registration/validation/email", Tool.createFormData(data));
  }
};
