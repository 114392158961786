import axios from 'axios'
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post('/api/promotion', Tool.createFormData())
  },
  findOne (promotion) {
    return axios.post('/api/promotion/' + promotion, Tool.createFormData())
  },
}
