<template>
  <div>
    <top-banner :heading="article == null ? ' ' : [article.title]">
      <template v-if="isLoading">
        <Loading/>
      </template>
      <template v-else>
        <div v-scrollanimation="{name: 'fade-up', delay: 150}">
          <b-img
            v-if="article"
            fluid
            center
            :src="article.coverSrc"
            :alt="article.coverAlt"
          ></b-img>
        </div>
      </template>
    </top-banner>

    <template v-if="!isLoading && article != null">
      <b-container class="article" v-scrollanimation="{name: 'fade-up', delay: 150}">
        <b-row>
          <template v-if="articleHeadings.length > 0">
            <b-col sm="12" lg="4">
              <div class="summary-container sticky-top">
                <div class="summary">{{ $t("front.blog.summary") }}</div>
                <div
                  v-for="(articleHeading, index) in articleHeadings"
                  :key="'articleHeading' + index"
                  :class="[
                    'article-heading-container',
                    'heading-' + articleHeading.tag.toLowerCase(),
                  ]"
                >
                  <a
                    :href="'#heading-' + (index + 1)"
                    :class="{
                      'active-heading':
                        'heading-' + (index + 1) == activeArticleHeadingId,
                    }"
                  >
                    {{ articleHeading.content }}
                  </a>
                </div>
              </div>
            </b-col>
          </template>

          <b-col sm="12" lg="8">
            <BackButton class="mb-4"/>

            <span v-html="article.content"></span>

          </b-col>
        </b-row>

        <!-- Article footer (author, social, etc.) -->
        <b-row>
          <b-col sm="12" lg="4"></b-col>
          <b-col>
            <div class="article-end-separator"></div>

            <b-row class="mb-5">
              <b-col class="d-flex justify-content-between align-items-center">
                <div class="d-flex">
                  <div class="avatar-container">
                    <template v-if="article.author.photo">
                      <b-img
                        :src="article.author.photo"
                        :alt="$t('front.blog.avatarAlt')"
                      />
                    </template>
                    <template v-else>
                      <span>{{
                          article.author.name.charAt(0).toUpperCase()
                        }}</span>
                    </template>
                  </div>
                  <div>
                    <div>
                      {{ $t("front.blog.redactedBy") }}
                      {{ article.author.name }}
                    </div>
                    <div>
                      {{ $t("front.blog.the") }}
                      {{ $d(new Date(article.date.date), 'string') }}
                    </div>
                  </div>
                </div>

                <div class="share-container">
                  <ShareNetwork
                    class="mr-2"
                    network="facebook"
                    :url="articleAbsoluteUrl"
                    :title="article.title"
                    :description="article.description"
                  >
                    <div class="share-button" id="share-button-facebook">
                      <i class="fab fa-facebook-f"></i>
                    </div>
                  </ShareNetwork>

                  <ShareNetwork
                    class="mr-2"
                    network="twitter"
                    :url="articleAbsoluteUrl"
                    :title="article.title"
                    :description="article.description"
                  >
                    <div class="share-button" id="share-button-twitter">
                      <i class="fab fa-twitter"></i>
                    </div>
                  </ShareNetwork>

                  <ShareNetwork
                    class="mr-2"
                    network="linkedin"
                    :url="articleAbsoluteUrl"
                    :title="article.title"
                  >
                    <div class="share-button" id="share-button-linkedin">
                      <i class="fab fa-linkedin-in"></i>
                    </div>
                  </ShareNetwork>

                  <ShareNetwork
                    class="mr-2"
                    network="email"
                    :url="articleAbsoluteUrl"
                    :title="article.title"
                  >
                    <div class="share-button" id="share-button-email">
                      <i class="fas fa-paper-plane"></i>
                    </div>
                  </ShareNetwork>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <!-- Newsletter subscription -->
      <b-container class="newletter-subscription" v-scrollanimation="{name: 'fade-up', delay: 150}">
        <b-row>
          <b-col offset-lg="4">
            <div class="newletter-subscription-inner-container">
              <h4 class="mb-4">
                {{ $t("front.footer.newsletter.description") }}
              </h4>
              <b-form @submit.stop.prevent="onSubmitEmail">
                <div class="newsletter-input-container">
                  <b-form-input
                    v-model="email"
                    type="email"
                    :placeholder="$t('front.footer.newsletter.placeholder')"
                    class="newsletter-input"
                  ></b-form-input>
                  <button
                    type="submit"
                    class="btn newsletter_button"
                    :disabled="isLoadingAction"
                  >
                    {{ $t("front.footer.newsletter.buttonLabel") }}
                    <b-spinner
                      v-if="isLoadingAction"
                      small
                      class="ml-1"
                    ></b-spinner>
                  </button>
                </div>
              </b-form>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <!-- Related articles -->
      <template v-if="articleCategory">
        <b-container
          v-if="relatedArticles.length > 0"
          class="last-article"
          v-scrollanimation="{name: 'fade-up', delay: 150}"
        >
          <b-row>
            <b-col offset-lg="4">
              <h2>{{ $t("front.blog.latestArticleTitle") }}</h2>

              <b-row class="pb-5">
                <b-col
                  v-for="relatedArticle in relatedArticles"
                  :key="relatedArticle.id"
                  lg="6"
                  md="6"
                  class="d-flex align-items-stretch"
                >
                  <ArticleCard
                    :article="relatedArticle"
                    :articleCategoryName="articleCategory.name"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </template>
  </div>
</template>

<script>
import Loading from "@mizogoo/components/workspace/layout/Loading";
import FlashBag from "@mizogoo/components/FlashBag";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import BackButton from "@mizogoo/components/workspace/layout/BackButton";
import ArticleCard from "@mizogoo/components/front/pages/BlogPage/ArticleCard";

export default {
  name: "Article",
  components: {
    Loading,
    FlashBag,
    TopBanner,
    BackButton,
    ArticleCard,
  },
  data() {
    return {
      article: null,
      articleCategory: null,
      activeArticleHeadingId: "heading-1",
      articleHeadings: [],
      relatedArticles: [],
      isLoading: true,
      email: null,
    };
  },
  computed: {
    // Fetch All, One
    isLoadingArticle() {
      return this.$store.getters["article/isLoading"];
    },
    hasError() {
      return this.$store.getters["article/hasError"];
    },
    error() {
      return this.$store.getters["article/error"];
    },
    articleAbsoluteUrl() {
      return (
        process.env.HOST_SCHEME +
        "://" +
        process.env.HOST_URL +
        this.$route.fullPath
      );
    },
    // Newsletter
    isLoadingAction() {
      return this.$store.getters["newsletterSubscription/isLoadingAction"];
    },
    errorAction() {
      return this.$store.getters["newsletterSubscription/errorAction"];
    },
    hasErrorAction() {
      return this.errorAction !== null;
    },
  },
  methods: {
    async loadArticle(id) {
      //reset current article data before fetching new article
      this.isLoading = true;
      this.article = null;
      this.relatedArticles = [];

      let articleResponse = await this.$store.dispatch("article/findOne", {
        article: parseInt(id),
      });

      this.article = articleResponse;

      let articleTitle = articleResponse.metaTitle
        ? articleResponse.metaTitle
        : articleResponse.title;
      let articleDescription = articleResponse.metaDescription
        ? articleResponse.metaDescription
        : articleResponse.description;

      document.title = articleTitle += " | mizogoo";
      document
        .querySelector('meta[name="description"]')
        .setAttribute("content", articleDescription);

      let articleCategoryResponse = await this.$store.dispatch(
        "articleCategory/findOne",
        {
          articleCategory: this.article.articleCategoryId,
        }
      );

      this.articleCategory = articleCategoryResponse;

      const relatedArticles = articleCategoryResponse.articles.filter(
        (element) => element !== parseInt(id)
      );

      relatedArticles.slice(0, 2).map(async (articleId) => {
        const relatedArticle = await this.$store.dispatch("article/findOne", {
          article: parseInt(articleId),
        });
        if (relatedArticle != null) {
          this.relatedArticles.push(relatedArticle);
        }
      });

      this.isLoading = false;
    },
    // Parse the article content to find every heading
    // Set an id to them that will be used as an anchor
    // And extract these headings to instanciate the summary
    initHeadings() {
      this.activeArticleHeadingId = "heading-1";
      this.articleHeadings = [];
      let articleContentNode = document.querySelector(".article");
      let headingsNode = articleContentNode.querySelectorAll("h2, h3");

      for (let i = 0; i < headingsNode.length; i++) {
        // Add an id to the heading (anchor)
        headingsNode[i].id = "heading-" + (i + 1);

        // Extract the heading list
        this.articleHeadings.push({
          tag: headingsNode[i].tagName,
          content: headingsNode[i].textContent,
        });
      }

      // Add an observer to evry headings (to know which one is active)
      this.handleObservers(headingsNode);
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    handleObservers(headingsNode) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.intersectionRatio > 0) {
              this.activeArticleHeadingId = entry.target.getAttribute("id");
            }
          });
        },
        {
          rootMargin: "0px 0px -98% 0px",
        }
      );
      headingsNode.forEach((heading) => {
        observer.observe(heading);
      });
    },
    async onSubmitEmail() {
      const response = await this.$store.dispatch(
        "newsletterSubscription/create",
        { form: { email: this.email } }
      );

      this.email = null;
      this.toast(response.message);
    },
  },
  async mounted() {
    await this.loadArticle(this.$route.params.id);
    this.initHeadings();
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.params.id != to.params.id) {
      this.scrollToTop();
      await this.loadArticle(to.params.id);
      this.initHeadings();
      next();
    }
  },
};
</script>

<style lang="scss">
.summary-container {
  padding-top: 150px;
  margin-top: -150px;
  padding-right: 2rem;
  padding-bottom: 1rem;
  z-index: initial !important;

  .summary {
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
  }

  .article-heading-container {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;

    &.heading-h3 {
      padding-left: 1rem;
    }

    a {
      color: $mizogoo-gray-medium;

      &.active-heading {
        color: $mizogoo-gray-dark;
      }
    }
  }
}

.article {
  .article-header {
    font-size: 1.2rem !important;
    font-weight: bold !important;
  }

  h2,
  h3,
  h4,
  h5 {
    padding-top: 120px;
    margin-top: -120px;
    pointer-events: none
  }

  h1 {
    font-size: 2.5rem !important;
  }

  h2 {
    font-size: 1.6rem !important;
    margin-bottom: 2rem !important;
    color: $mizogoo-gray-dark !important;
    text-transform: none !important;
    font-weight: 600 !important;
  }

  h3 {
    font-size: 1.4rem !important;
    font-weight: 500 !important;
    color: $mizogoo-gray-dark !important;
    text-transform: none !important;
  }

  h4 {
    font-weight: 400 !important;
    margin-bottom: 1rem !important;
  }

  ul {
    margin-left: 1.5rem;
    margin-bottom: 0.5rem;

    li {
      list-style: initial !important;
      margin-bottom: 1rem !important;
    }
  }

  strong {
    font-weight: 500;
  }

  img {
    border-radius: 10px;
  }

  .btn {
    border: none !important;
  }

  .article-end-separator {
    width: 250px;
    height: 1px;
    margin-top: 1rem;
    margin-bottom: 3rem;
    background-color: $mizogoo-gray-dark;
  }

  .avatar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin-right: 1rem;
    font-weight: 400;
    font-size: 1.4rem;
    background-color: $mizogoo-gray-light;
    border-radius: 50px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      object-fit: cover;
    }
  }

  .share-container {
    display: flex;

    a {
      text-decoration: none;
    }

    .share-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      color: $mizogoo-gray-medium;
      border: 1px solid $mizogoo-gray-medium;
      border-radius: 50px;
      transition: 0.2s ease-in-out;

      &:hover {
        background-color: $mizogoo;
        border-color: $mizogoo;
        color: #fff;
      }
    }
  }
}

.newletter-subscription {
  .newletter-subscription-inner-container {
    margin-top: 2rem;
    padding: 3.5rem;
    background-color: $mizogoo-gray-light;
    text-align: center;
    border-radius: 10px;

    @media (max-width: 576px) {
      padding: 1.5rem 2.5rem;
    }

    h2 {
      font-size: 1.8rem !important;
    }

    .newsletter-input-container {
      position: relative;
      margin-bottom: 1rem;

      .newsletter-input {
        padding-left: 1rem !important;
        padding-right: 9rem !important;
        background-color: $background-color !important;
        border-radius: 150px!important;
      }

      .newsletter_button {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: 5px;
        padding: 0px 24px !important;
        background-color: $mizogoo;
        color: #fff;

        &:hover {
          background-color: color.adjust($mizogoo, $lightness: -5%);
        }
      }
    }
  }
}

.last-article {
  margin-top: 5rem;

  h2 {
    font-size: 1.8rem !important;
  }
}
</style>
