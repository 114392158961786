<template>
  <b-container fluid class="cart-container">
    <Loading v-if="isLoadingCart"/>

    <template v-else>
      <b-row>
        <!-- Left column (steps overview with links to navigate between them) -->
        <b-col md="12" lg="2" class="cart-col">
          <template v-if="!isCartEmpty">
            <Step active="cart"/>
          </template>
        </b-col>

        <!-- Main column (cart elements) -->
        <b-col md="12" lg="8" class="cart-col main-col">
          <b-row>
            <b-col>
              <router-link
                  class="return-button"
                  :to="{ name: 'catalog' }"
              >
                <i class="fas fa-arrow-left mr-2"></i>{{ $t('front.cart.continueShopping') }}
              </router-link>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <h1>{{ $t('front.cart.cart') }}</h1>
            </b-col>
          </b-row>

          <!-- Errors -->
          <b-row v-if="hasError">
            <b-col class="mb-2 large-form-container">
              <FlashBag v-if="hasErrorCart" :message="errorCart"/>
              <FlashBag v-if="hasErrorRemoving" :message="errorRemoving"/>
              <FlashBag v-if="hasErrorAddPromotion" :message="errorAddPromotion"/>
              <FlashBag v-if="hasErrorRemovingPromotion" :message="errorRemovingPromotion"/>
            </b-col>
          </b-row>

          <!-- Empty message -->
          <EmptyMessage
              v-if="isCartEmpty"
              :heading="$t('front.cart.emptyMessageHeading')"
              :buttonLabel="$t('front.cart.returnToProducts')"
              :buttonLink="user !== null ? 'my_creation' : 'catalog'"
          />

          <template v-else>
            <b-row
                v-for="element in cart.elements"
                :key="'element-' + element.id"
                class="pt-4 pb-4 border-bottom large-form-container"
            >
              <!-- Cart element image -->
              <b-col sm="12" md="2">
                <div class="cart-element-image-container">
                  <b-img
                      :src="productFile(element.product).src"
                      :alt="productFile(element.product).alt"
                  />
                </div>
              </b-col>

              <!-- Cart element main infos -->
              <b-col sm="12" md="8">
                <div class="cart-element-info-container">
                  <div class="cart-element-name">
                    <template v-if="element.creation !== null">
                      <template v-if="element.creation.name !== null">
                        {{ element.creation.name }}
                      </template>
                      <template v-else>
                        {{ $t('workspace.creation.noName') }}
                      </template>
                    </template>
                    <template v-else>
                      {{ element.product.name }}
                    </template>
                  </div>

                  <!-- Cart element creation -->
                  <template v-if="element.creation !== null">
                    <div>
                      {{ element.product.name }}
                      <span class="ml-1 mr-1">–</span>
                      <span
                          v-for="(productFeatureValue, index) in element.creation.productFeatureValues"
                          :key="productFeatureValue.id"
                      >
                        {{ productFeatureValue.value }}
                        <template
                            v-if="index !== element.creation.productFeatureValues.length - 1"
                        >
                          <span class="ml-1 mr-1">–</span>
                        </template>
                        <template v-if="productFeatureValue.isFormatVariable">
                          ({{ element.creation.width }}x{{ element.creation.height }}cm)
                        </template>
                      </span>
                    </div>
                    <template v-if="element.isOnlyMaking">
                      <p>{{ $t('front.cart.isOnlyMaking') }}</p>
                    </template>
                  </template>

                  <!-- Cart element features -->
                  <template
                      v-if="element.productPrice">
                    <template
                        v-if="element.productPrice.productFeatureValuesSorted">
                      <div
                          v-for="productFeatureValue in productFeatureValueCustomizable(element.productPrice.productFeatureValuesSorted )"
                          :key="productFeatureValue.id"
                      >
                        <span class="text-muted">{{ productFeatureValue.feature.name }} :</span>
                        {{ productFeatureValue.value }}
                      </div>
                    </template>
                  </template>

                  <!-- Cart element attributs -->
                  <template v-if="element.attributes.length > 0">
                    <div
                        v-for="attribute in element.attributes"
                        :key="attribute.id"
                    >
                      <span class="text-muted">{{ attribute.name }}:</span>
                      {{ attribute.value }} ({{ formatPrice(attribute.priceHt) }})
                    </div>
                  </template>

                  <!-- Will be useful when we'll have a menu maker -->
                  <!-- <template v-if="element.creation !== null">
                    <div class="mt-1">
                      <a href="javascript:;">
                        {{ $t("front.cart.editCreation") }}
                        <i class="fas fa-arrow-right ml-1"></i>
                      </a>
                    </div>
                  </template> -->
                </div>
              </b-col>

              <!-- Cart element price and quantity -->
              <b-col sm="12" md="2" class="text-center">
                <div class="cart-element-price-container">
                  <template v-if="prices[element.id].promotionPriceHt > 0">
                    <p>
                      <strike>{{ formatPrice(prices[element.id].priceHt + prices[element.id].promotionPriceHt) }}</strike>
                      <span class="font-weight-bold">{{ formatPrice(prices[element.id].priceHt) }}</span>
                    </p>
                  </template>
                  <template v-else>
                    <p>{{ formatPrice(prices[element.id].priceHt) }}</p>
                  </template>
                  <div>
                    {{ element.quantity }}
                  </div>

                  <button
                      class="btn btn-link delete-button"
                      @click.prevent.stop="onRemove(element)"
                      :disabled="isLoadingRemoving"
                  >
                    <template v-if="!isLoadingRemoving">
                      {{ $t('workspace.global.delete') }}
                    </template>
                    <b-spinner
                        v-if="isLoadingRemoving"
                        small
                        class="ml-1"
                    ></b-spinner>
                  </button>
                </div>
              </b-col>
            </b-row>

            <template v-if="showWarningForMenuHolder">
              <b-row class="pt-4 large-form-container">
                <b-col>
                  <div class="text-danger">
                    {{ $t("front.cart.crossSellingMenuHolder1") }}
                    <router-link :to="{ name: 'product', params: { id: 26, urlName: 'pochettes-interieures-porte-menus' } }" custom v-slot="{ href, navigate }"><a :href="href" @click="navigate" target="_blank">{{ $t("front.cart.crossSellingMenuHolder2") }}</a></router-link>
                    {{ $t("front.cart.crossSellingMenuHolder3") }}
                    <router-link :to="{ name: 'product', params: { id: 24, urlName: 'feuillets-interieurs-porte-menus' } }" custom v-slot="{ href, navigate }"><a :href="href" @click="navigate" target="_blank">{{ $t("front.cart.crossSellingMenuHolder4") }}</a></router-link>
                    {{ $t("front.cart.crossSellingMenuHolder5") }}
                  </div>
                </b-col>
              </b-row>
            </template>
          </template>
        </b-col>

        <!-- Right column (summary with total prices and link to next step) -->
        <template v-if="!isCartEmpty">
          <b-col md="12" lg="2" class="cart-col cart-right">
            <Summary currentStep="cart" :prices="prices" :cartElements="cart.elements" :isIndex="true"/>
          </b-col>
        </template>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import BackButton from '@mizogoo/components/workspace/layout/BackButton'
import Loading from '@mizogoo/components/workspace/layout/Loading'
import FlashBag from '@mizogoo/components/FlashBag'
import EmptyMessage from '@mizogoo/components/workspace/layout/EmptyMessage'
import InputNumber from '@mizogoo/components/input/InputNumber'
import Step from '@mizogoo/components/front/pages/CartPage/Step'
import Summary from '@mizogoo/components/front/pages/CartPage/Summary'
import FormInput from '@mizogoo/components/form/FormInput'

export default {
  mixins: [MetaMixin],
  name: 'CartPage',
  components: {
    BackButton,
    Loading,
    FlashBag,
    EmptyMessage,
    InputNumber,
    Step,
    Summary,
    FormInput,
  },
  computed: {
    metaTitle() {
      return this.$t('front.metadata.cart.title')
    },
    // Global
    hasError () {
      return (
          this.hasErrorCart ||
          this.hasErrorUpdatingQuantity ||
          this.hasErrorRemoving ||
          this.hasErrorAddPromotion ||
          this.hasErrorRemovingPromotion
      )
    },
    // Cart
    isCartEmpty () {
      return this.cart === null || this.cart.elements.length === 0
    },
    isLoadingCart () {
      return this.$store.getters['cart/isLoadingCart']
    },
    hasErrorCart () {
      return this.$store.getters['cart/hasErrorCart']
    },
    errorCart () {
      return this.$store.getters['cart/errorCart']
    },
    cart () {
      return this.$store.getters['cart/cart']
    },
    prices () {
      return this.$store.getters['cart/prices']
    },
    // MenuHolder
    showWarningForMenuHolder () {
      let showWarningForMenuHolder = false

      let haveMenuHolder = false
      let haveSheetProtector = false
      let haveInnerSheet = false

      for (let element of this.cart.elements) {
        if (element.product.constName === 'MENU_HOLDER_TEXTURED' || element.product.constName === 'MENU_HOLDER_PEL') {
          haveMenuHolder = true
        } else if (element.product.constName === 'SHEET_PROTECTOR') {
          haveSheetProtector = true
        } else if (element.product.constName === 'INNER_SHEET') {
          haveInnerSheet = true
        }
      }

      if (haveMenuHolder && !haveSheetProtector && !haveInnerSheet) {
        showWarningForMenuHolder = true
      }

      return showWarningForMenuHolder
    },
    // Remove cartElement
    isLoadingRemoving () {
      return this.$store.getters['cartElement/isLoadingRemoving']
    },
    hasErrorRemoving () {
      return this.$store.getters['cartElement/hasErrorRemoving']
    },
    errorRemoving () {
      return this.$store.getters['cartElement/errorRemoving']
    },
    // Fetch Add Promotion
    isLoadingAddPromotion () {
      return this.$store.getters['cart/isLoadingAddPromotion']
    },
    hasErrorAddPromotion () {
      return this.$store.getters['cart/hasErrorAddPromotion']
    },
    errorAddPromotion () {
      return this.$store.getters['cart/errorAddPromotion']
    },
    // Removing Promotion
    isLoadingRemovingPromotion () {
      return this.$store.getters['cart/isLoadingRemovingPromotion']
    },
    hasErrorRemovingPromotion () {
      return this.$store.getters['cart/hasErrorRemovingPromotion']
    },
    errorRemovingPromotion () {
      return this.$store.getters['cart/errorRemovingPromotion']
    },
  },
  methods: {
    // Product
    productFile (product) {
      return {
        src: product.file.thumbnails['100_100'],
        alt: product.name,
      }
    },
    productFeatureValueCustomizable (productFeatureValuesSorted) {
      return productFeatureValuesSorted.filter(
          (element) => element.feature.featureFamily.constName !== 'CUSTOMIZABLE'
      )
    },
    // Cart Element
    async onRemove (cartElement) {
      await this.$store.dispatch('cartElement/remove', {
        cartElement: cartElement,
      })
    },
  },
  async created () {
    await this.$store.dispatch('cart/findCart')
  },
  mounted () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', false)
    this.$store.dispatch('global/fetchFrontHeaderVisible', false)
    this.$store.dispatch('global/fetchFrontFooterVisible', false)
  },
  beforeUnmount () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', true)
    this.$store.dispatch('global/fetchFrontHeaderVisible', true)
    this.$store.dispatch('global/fetchFrontFooterVisible', true)
  },
}
</script>

<style lang="scss" scoped>
.cart-container {
  .cart-element-image-container {
    background-color: $mizogoo-gray-light;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }

  .cart-element-info-container {
    .cart-element-name {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  .cart-element-price-container {
    .input-number-container {
      position: relative;

      .input-spinner-container {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 15px;
        background-color: $mizogoo-gray-light;
      }
    }

    .delete-button {
      color: $mizogoo-gray-medium;
      font-weight: 300 !important;
      font-size: 0.95rem;
      padding: 0 0.75rem !important;
    }
  }

  .cart-element-promotion-container {
    .delete-button {
      color: $mizogoo-gray-medium;
      font-weight: 300 !important;
      font-size: 0.95rem;
      padding: 0 0.75rem !important;
    }
  }
}
</style>
