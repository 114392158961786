// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subscription-block .subscription-block-header[data-v-001c08ea] {
  margin-bottom: 1rem;
}
.subscription-block .subscription-block-columns[data-v-001c08ea] {
  position: relative;
  z-index: 1;
  justify-content: center;
}
@media screen and (max-width: 992px) {
.normal-subscription[data-v-001c08ea] {
    margin-top: 0;
    margin-bottom: 2rem;
}
.subscription-block-columns[data-v-001c08ea]:after {
    content: none;
}
}
@media screen and (max-width: 576px) {
.subscription-block[data-v-001c08ea] {
    padding: 0 !important;
}
}
.no-white-space[data-v-001c08ea] {
  white-space: initial !important;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/layout/subscription/SubscriptionBlock.vue","webpack://./SubscriptionBlock.vue"],"names":[],"mappings":"AAME;EACE,mBAAA;ACLJ;ADOE;EACE,kBAAA;EACA,UAAA;EACA,uBAAA;ACLJ;ADSA;AACE;IACE,aAAA;IACA,mBAAA;ACNF;ADSA;IACE,aAAA;ACPF;AACF;ADUA;AACE;IACE,qBAAA;ACRF;AACF;ADWA;EACE,+BAAA;ACTF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.subscription-block {\n  .subscription-block-header {\n    margin-bottom: 1rem;\n  }\n  .subscription-block-columns {\n    position: relative;\n    z-index: 1;\n    justify-content: center;\n  }\n}\n\n@media screen and (max-width: 992px) {\n  .normal-subscription {\n    margin-top: 0;\n    margin-bottom: 2rem;\n  }\n\n  .subscription-block-columns:after {\n    content: none;\n  }\n}\n\n@media screen and (max-width: 576px) {\n  .subscription-block {\n    padding: 0 !important;\n  }\n}\n\n.no-white-space {\n  white-space: initial !important;\n}\n",".subscription-block .subscription-block-header {\n  margin-bottom: 1rem;\n}\n.subscription-block .subscription-block-columns {\n  position: relative;\n  z-index: 1;\n  justify-content: center;\n}\n\n@media screen and (max-width: 992px) {\n  .normal-subscription {\n    margin-top: 0;\n    margin-bottom: 2rem;\n  }\n  .subscription-block-columns:after {\n    content: none;\n  }\n}\n@media screen and (max-width: 576px) {\n  .subscription-block {\n    padding: 0 !important;\n  }\n}\n.no-white-space {\n  white-space: initial !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
