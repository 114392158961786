import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/institution/document", Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/institution/document/create",
      Tool.createFormData(data.form),
      {
        onUploadProgress: function (progressEvent) {
          data.store.dispatch('accountInstitutionDocument/setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }.bind(this)
      })
  },
  update (data) {
    return axios.post("/api/account/institution/document/update/" + data.id, Tool.createFormData(data));
  },
  updatePosition (data) {
    return axios.post("/api/account/institution/document/update/position", Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/institution/document/remove/" + data.id, Tool.createFormData());
  },
  active (data) {
    return axios.post("/api/account/institution/document/active/" + data.id, Tool.createFormData());
  },
};
