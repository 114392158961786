<template>
  <b-container class="video-container">
    <div class="absolute-decoration-dotted-red"></div>
    <div class="content-container">
      <div class="heading-container">
        <h3>{{ $t("front.video.subheading") }}</h3>
        <h2>{{ $t("front.video.heading") }}</h2>
      </div>
      <div v-b-visible.once="(visible) => visible ? videoIsVisible = true : null" class="video-wrapper">
        <b-embed
          v-if="videoIsVisible"
          type="iframe"
          aspect="16by9"
          src="https://www.youtube-nocookie.com/embed/lP9VU0f3g48?controls=0&rel=0"
          allowfullscreen
        ></b-embed>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "FeaturesPresentationVideo",
  data() {
    return {
      videoIsVisible: false,
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 5rem;
  text-align: center;

  .absolute-decoration-dotted-red {
    content: url(../../../../assets/img/front/global/decorations/doted_decoration_colored.svg);
    position: absolute;
    top: 45px;
    right: -35px;
    transform: rotate(90deg) translate(100%, 0);
    transform-origin: 100% 0;
    z-index: 1;
  }

  .content-container {
    position: relative;
    z-index: 2;

    .heading-container {
      background-color: $mizogoo-gray-light;
      padding-bottom: 16rem;
      z-index: 2;
    }

    h3 {
      padding-top: 5rem;
    }

    h2 {
      padding-bottom: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .video-wrapper {
      margin-top: -16rem;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .absolute-decoration-dotted-red {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .video-container {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
