<template>
  <b-container class="product-rating" ref="product-rating" id="product-reviews">
    <b-row>
      <b-col md="5">
        <!-- Heading -->
        <div class="heading-container">
          <h2>{{ $t("front.product.rating.heading") }}</h2>
          <p class="description">{{ $t("front.product.rating.description") }}</p>
        </div>

        <div v-if="isLoadingProductReview" class="loading">
          <div class="text-center">
            <b-spinner label="Spinning"></b-spinner>
          </div>
        </div>
        
        <template v-else-if="productReviewSummary">
          <!-- Reviews summary (displayed inside an highlighted card) -->
          <div class="review-summary-container">
            <!-- Average score -->
            <div class="average-score-container">
              <div class="score">
                {{ productReviewSummary.averageRating.toFixed(1) }}
              </div>
              <div class="average-stars-container">
                <div
                  class="average-stars"
                  :style="'--percent: ' + (productReviewSummary.averageRating / 5) * 100 + '%'"
                ></div>
                <div class="absolute-stars">
                  <template v-for="i in 5" :key="'star-' + i">
                    <img
                      class="star"
                      :src="require('@mizogoo/assets/img/star_border.png')"
                    />
                  </template>
                </div>
              </div>
              <div>({{ productReviewSummary.totalReviews }} {{ $t("front.product.rating.reviews") }})</div>
            </div>

            <!-- Review rating scatter -->
            <div class="scatter-container">
              <template v-for="review in reviewScatter" :key="'scatter-review-' + review.id">
                <div
                  class="scatter-row-container"
                >
                  <div class="scatter-number">{{ review.id }}</div>
                  <div class="scatter-slider-container">
                    <div class="scatter-slider-background"></div>
                    <div
                      class="scatter-slider-data"
                      :style="'width: ' + review.percentage + '%'"
                    ></div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </b-col>

      <b-col md="7">
        <template v-for="(review, index) in filteredProductReviews" :key="review.id">
          <div class="review-container" :class="index === filteredProductReviews.length - 1 ? 'last-review' : ''">
            <!-- Username -->
              <div class="username">
              {{ review.userName }}
              </div>
            <!-- Rating (stars from 1 to 5) -->
            <div class="rating">
              <div class="d-flex flex-row">
                <div v-for="n in 5" :key="n">
                  <template v-if="n <= review.rating">
                    <img
                      class="star"
                      :src="require('@mizogoo/assets/img/star_plain.png')"
                    />
                  </template>
                  <template v-else>
                    <img
                      class="star"
                      :src="require('@mizogoo/assets/img/star_border.png')"
                    />
                  </template>
                </div>
              </div>
            </div>
            <!-- Title -->
            <template v-if="review.title">
              <div class="title">
                {{ review.title }}
              </div>
            </template>
            <!-- Comments -->
            <template v-if="review.comments">
              <div class="comments">
                {{ review.comments }}
              </div>
            </template>
            <!-- Date -->
            <div class="date">
              {{ $t("front.product.rating.published") }}
              {{ $d(new Date(review.date.date), 'string') }}
            </div>
          </div>
        </template>

        <template v-if="totalPage" md="12" class="mt-5">
          <Pagination
            :totalPage="totalPage"
            :perPage="perPage"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          />
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Pagination from "@mizogoo/components/global/pagination/Pagination";

export default {
  name: "ProductRating",
  props: ["productReviews", "productReviewSummary", "isLoadingProductReview"],
  components: {
    Pagination,
  },
  data() {
    return {
      totalPage: null,
      currentPage: 1,
      perPage: 5,
    }
  },
  computed: {
    reviewScatter() {
      return [
        {
          id: 5,
          percentage:
            (this.productReviewSummary.fiveStarsNumber /
              this.productReviewSummary.totalReviews) *
            100,
        },
        {
          id: 4,
          percentage:
            (this.productReviewSummary.fourStarsNumber /
              this.productReviewSummary.totalReviews) *
            100,
        },
        {
          id: 3,
          percentage:
            (this.productReviewSummary.threeStarsNumber /
              this.productReviewSummary.totalReviews) *
            100,
        },
        {
          id: 2,
          percentage:
            (this.productReviewSummary.twoStarsNumber /
              this.productReviewSummary.totalReviews) *
            100,
        },
        {
          id: 1,
          percentage:
            (this.productReviewSummary.oneStarNumber /
              this.productReviewSummary.totalReviews) *
            100,
        },
      ];
    },
    filteredProductReviews() {
      return this.productReviews.slice(
        (this.currentPage - 1) * this.perPage,
        this.perPage * this.currentPage
      );
    },
  },
  methods: {
    onPageChange(page) {
      // Get the top position of the product rating element
      const productRatingElement = this.$refs["product-rating"];
      const elementTop = productRatingElement.getBoundingClientRect().top + window.scrollY;

      // Scroll to the top of the product rating element
      window.scrollTo({
        top: elementTop,
        behavior: 'smooth',
      });

      const scrollPromise = new Promise(resolve => {
        const scrollHandler = () => {
          const distance = Math.abs(window.scrollY - elementTop);
          if (distance <= 10) {
            window.removeEventListener('scroll', scrollHandler);
            resolve();
          }
        };

        // Check if we are already at the target position before adding the listener
        if (Math.abs(window.scrollY - elementTop) > 10) {
          window.addEventListener('scroll', scrollHandler);
        } else {
          resolve();
        }
      });

      scrollPromise.then(() => {
        // Change page when scroll is finished
        this.currentPage = page;
      });    
    },
  },
  mounted() {
    if (this.productReviews.length > this.perPage) {
      this.totalPage = Math.ceil(this.productReviews.length / this.perPage);
    }
  },
}
</script>

<style lang="scss" scoped>
.product-rating {
  padding-top: 8rem;
  padding-bottom: 8rem;

  .heading-container {
    margin-bottom: 2.5rem;

    .description {
      font-style: italic;
    }
  }

  .review-summary-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .scatter-container {
      .scatter-row-container {
        display: flex;
        align-items: center;

        .scatter-number {
          margin-right: 15px;
        }

        .scatter-slider-container {
          position: relative;
          width: 150px;
          height: 7px;

          .scatter-slider-background,
          .scatter-slider-data {
            height: 100%;
            border-radius: 50px;
          }

          .scatter-slider-background {
            background-color: $mizogoo-gray-light;
          }

          .scatter-slider-data {
            position: absolute;
            top: 0;
            background-color: $mizogoo-gray-dark;
          }
        }
      }
    }
  }

  .review-container {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $mizogoo-gray-light;

    &.last-review {
      border: none;
    }

    .username {
      margin-bottom: 0.2rem;
      font-weight: 500;
    }

    .title {
      margin-bottom: 0.8rem;
      font-weight: 500;
    }

    .rating {
      margin-bottom: 1rem;

      .star {
        max-width: 20px;
      }
    }

    .comments {
      margin-bottom: 1rem;
    }

    .date {
      font-style: italic;
    }
  }
}
</style>
