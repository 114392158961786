const FETCHING_IS_WORKSPACE = "FETCHING_IS_WORKSPACE";
const FETCHING_IS_QR_CODE = "FETCHING_IS_QR_CODE";
const FETCHING_IS_MOBILE_APP = "FETCHING_IS_MOBILE_APP";
const FETCHING_FRONT_HEADER_TRANSPARENT = "FETCHING_FRONT_HEADER_TRANSPARENT";
const FETCHING_FRONT_HEADER_HEIGHT = "FETCHING_FRONT_HEADER_HEIGHT";
const FETCHING_FRONT_HEADER_VISIBLE = "FETCHING_FRONT_HEADER_VISIBLE";
const FETCHING_FRONT_FOOTER_VISIBLE = "FETCHING_FRONT_FOOTER_VISIBLE";

export default {
  namespaced: true,
  state: {
    version: "0.1-b",
    langs: [
      { language: "fr", tag: "fr", title: "Français" },
      { language: "en", tag: "en", title: "English" },
    ],
    defaultLang: "fr",
    isWorkspace: false,
    isQrCode: false,
    isMobileApp: false,
    civilities: [
      { text: "M", id: "M" },
      { text: "Mme", id: "Mme" },
    ],
    countries: [
      { text: "France", id: "1" },
      { text: "Allemagne", id: "2" },
    ],
    recaptchaPublicKey: "6LdUM_YUAAAAANJtSAe4w2zOycpaQIXWzrUb3JqG",
    header: {
      transparent: true,
      height: null,
      visible: true,
    },
    footer: {
      visible: true,
    },
  },
  mutations: {
    [FETCHING_IS_WORKSPACE](state, isWorkspace) {
      state.isWorkspace = isWorkspace;
    },
    [FETCHING_IS_QR_CODE](state, isQrCode) {
      state.isQrCode = isQrCode;
    },
    [FETCHING_IS_MOBILE_APP](state, isMobileApp) {
      state.isMobileApp = isMobileApp;
    },
    [FETCHING_FRONT_HEADER_TRANSPARENT](state, transparent) {
      state.header.transparent = transparent;
    },
    [FETCHING_FRONT_HEADER_HEIGHT](state, height) {
      state.header.height = height;
    },
    [FETCHING_FRONT_HEADER_VISIBLE](state, visible) {
      state.header.visible = visible;
    },
    [FETCHING_FRONT_FOOTER_VISIBLE](state, visible) {
      state.footer.visible = visible;
    },
  },
  actions: {
    fetchIsWorkspace({ commit }, isWorkspace) {
      commit(FETCHING_IS_WORKSPACE, isWorkspace);
    },
    fetchIsQrCode({ commit }, isQrCode) {
      commit(FETCHING_IS_QR_CODE, isQrCode);
    },
    fetchIsMobileApp({ commit }, isMobileApp) {
      commit(FETCHING_IS_MOBILE_APP, isMobileApp);
    },
    fetchFrontHeaderTransparent({ commit }, transparent) {
      commit(FETCHING_FRONT_HEADER_TRANSPARENT, transparent);
    },
    fetchFrontHeaderHeight({ commit }, height) {
      commit(FETCHING_FRONT_HEADER_HEIGHT, height);
    },
    fetchFrontHeaderVisible({ commit }, visible) {
      commit(FETCHING_FRONT_HEADER_VISIBLE, visible);
    },
    fetchFrontFooterVisible({ commit }, visible) {
      commit(FETCHING_FRONT_FOOTER_VISIBLE, visible);
    },
  },
  getters: {
    version: (state) => {
      return state.version;
    },
    langs: (state) => {
      return state.langs;
    },
    defaultLang: (state) => {
      return state.defaultLang;
    },
    isWorkspace: (state) => {
      return state.isWorkspace;
    },
    isQrCode: (state) => {
      return state.isQrCode;
    },
    isMobileApp: (state) => {
      return state.isMobileApp;
    },
    civilities: (state) => {
      return state.civilities;
    },
    countries: (state) => {
      return state.countries;
    },
    recaptchaPublicKey: (state) => {
      return state.recaptchaPublicKey;
    },
    header: (state) => {
      return state.header;
    },
    footer: (state) => {
      return state.footer;
    },
  },
};
