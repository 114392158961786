// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-3-blocks[data-v-77845bee] {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.main-explanations[data-v-77845bee] {
  margin-bottom: 1rem;
}
.main-explanations p[data-v-77845bee] {
  max-width: 920px;
  margin: auto;
}
.secondary-explanations > div[data-v-77845bee] {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.secondary-explanations p[data-v-77845bee] {
  font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/layout/features/3Blocks/Feature3Blocks.vue","webpack://./Feature3Blocks.vue"],"names":[],"mappings":"AAKA;EACE,iBAAA;EACA,oBAAA;ACJF;ADMA;EACE,mBAAA;ACHF;ADKA;EACE,gBAAA;EACA,YAAA;ACFF;ADIA;EACE,gBAAA;EACA,mBAAA;ACDF;ADGA;EACE,eAAA;ACAF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.feature-3-blocks {\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n}\n.main-explanations {\n  margin-bottom: 1rem;\n}\n.main-explanations p {\n  max-width: 920px;\n  margin: auto;\n}\n.secondary-explanations > div {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n.secondary-explanations p {\n  font-size: 1rem;\n}\n",".feature-3-blocks {\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n}\n\n.main-explanations {\n  margin-bottom: 1rem;\n}\n\n.main-explanations p {\n  max-width: 920px;\n  margin: auto;\n}\n\n.secondary-explanations > div {\n  margin-top: 2rem;\n  margin-bottom: 2rem;\n}\n\n.secondary-explanations p {\n  font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
