import HelpCenterAPI from "../api/help_center";

const
  // HelpCenter Categories
  FETCH_MAIN_CATEGORY = "FETCH_MAIN_CATEGORY",
  FETCH_MAIN_CATEGORY_SUCCESS = "FETCH_MAIN_CATEGORY_SUCCESS",
  FETCH_MAIN_CATEGORY_ERROR = "FETCH_MAIN_CATEGORY_ERROR",

  FETCH_ONE_CATEGORY = "FETCH_ONE_CATEGORY",
  FETCH_ONE_CATEGORY_SUCCESS = "FETCH_ONE_CATEGORY_SUCCESS",
  FETCH_ONE_CATEGORY_ERROR = "FETCH_ONE_CATEGORY_ERROR",

  // HelpCenter articles
  FETCH_ALL_ARTICLE = "FETCH_ALL_ARTICLE",
  FETCH_ALL_ARTICLE_SUCCESS = "FETCH_ALL_ARTICLE_SUCCESS",
  FETCH_ALL_ARTICLE_ERROR = "FETCH_ALL_ARTICLE_ERROR",
  
  FETCH_ONE_ARTICLE = "FETCH_ONE_ARTICLE",
  FETCH_ONE_ARTICLE_SUCCESS = "FETCH_ONE_ARTICLE_SUCCESS",
  FETCH_ONE_ARTICLE_ERROR = "FETCH_ONE_ARTICLE_ERROR";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    errorFetchCategory: null,
    errorFetchArticle: null,
    categories: [],
    articles: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return (
        state.errorFetchCategory !== null ||
        state.errorFetchArticle !== null
      );
    },
    errorFetchCategory(state) {
      return state.errorFetchCategory;
    },
    errorFetchArticle(state) {
      return state.errorFetchArticle;
    },
    hasCategories(state) {
      return state.categories.length > 0;
    },
    hasArticles(state) {
      return state.articles.length > 0;
    },
    categories(state) {
      return state.categories;
    },
    articles(state) {
      return state.articles;
    }
  },
  mutations: {
    // Fetch Main Categories
    [FETCH_MAIN_CATEGORY](state) {
      state.isLoading = true;
      state.errorFetchCategory = null;
      state.categories = [];
    },
    [FETCH_MAIN_CATEGORY_SUCCESS](state, categories) {
      state.isLoading = false;
      state.errorFetchCategory = null;
      state.categories = categories;
    },
    [FETCH_MAIN_CATEGORY_ERROR](state, error) {
      state.isLoading = false;
      state.errorFetchCategory = error;
      state.categories = [];
    },
    // Fetch One Category
    [FETCH_ONE_CATEGORY](state) {
      state.isLoading = true;
      state.errorFetchCategory = null;
      // state.categories = [];
    },
    [FETCH_ONE_CATEGORY_SUCCESS](state, category) {
      state.isLoading = false;
      state.errorFetchCategory = null;
      state.categories.push(category);
    },
    [FETCH_ONE_CATEGORY_ERROR](state, error) {
      state.isLoading = false;
      state.errorFetchCategory = error;
      state.categories = [];
    },
    // Fetch All HelpCenter Articles
    [FETCH_ALL_ARTICLE](state) {
      state.isLoading = true;
      state.errorFetchArticle = null;
      state.articles = [];
    },
    [FETCH_ALL_ARTICLE_SUCCESS](state, articles) {
      state.isLoading = false;
      state.errorFetchArticle = null;
      state.articles = articles;
    },
    [FETCH_ALL_ARTICLE_ERROR](state, error) {
      state.isLoading = false;
      state.errorFetchArticle = error;
      state.articles = [];
    },
    // Fetch One HelpCenter Articles
    [FETCH_ONE_ARTICLE](state) {
      state.isLoading = true;
      state.errorFetchArticle = null;
      state.articles = [];
    },
    [FETCH_ONE_ARTICLE_SUCCESS](state, article) {
      state.isLoading = false;
      state.errorFetchArticle = null;
      state.articles.push(article);
    },
    [FETCH_ONE_ARTICLE_ERROR](state, error) {
      state.isLoading = false;
      state.errorFetchArticle = error;
      state.articles = [];
    },
  },
  actions: {
    // HelpCener Categories
    async findMainCategory(context) {
      // If the categories array is not empty, it's because the API call has already been made
      // if (context.state.categories.length > 0) {
      //   return context.categories;
      // Otherwise we have to retrieve them from the database
      // } else {
        context.commit(FETCH_MAIN_CATEGORY);
        try {
          let response = await HelpCenterAPI.findMainCategory();
          if (response.data.success === true) {
            context.commit(FETCH_MAIN_CATEGORY_SUCCESS, response.data.categories);
            return response.data.categories;
          } else {
            context.commit(FETCH_MAIN_CATEGORY_ERROR);
            return null;
          }
        } catch (error) {
          context.commit(FETCH_MAIN_CATEGORY_ERROR, error);
          return null;
        }
      // }
    },
    async findOneCategory(context, payload) {
      // Checks if the category has already been loaded and returns it directly if so
      let categoryIndex = context.state.categories.findIndex(element => element.id === payload.categoryId);
      if (categoryIndex != -1) {
        return context.state.categories[categoryIndex];
      // Otherwise we have to retrieve it from the database
      } else {
        context.commit(FETCH_ONE_CATEGORY);
        try {
          let response = await HelpCenterAPI.findCategory(payload.categoryId);
          if (response.data.success === true) {
            context.commit(FETCH_ONE_CATEGORY_SUCCESS, response.data.category);
            return response.data.category;
          } else {
            context.commit(FETCH_ONE_CATEGORY_ERROR);
            return null;
          }
        } catch (error) {
          context.commit(FETCH_ONE_CATEGORY_ERROR, error);
          return null;
        }
      }
    },
    // HelpCenter Articles
    async findAllArticle({ commit }) {
      commit(FETCH_ALL_ARTICLE);
      try {
        let response = await HelpCenterAPI.findAllArticle();
        if (response.data.success === true) {
          commit(FETCH_ALL_ARTICLE_SUCCESS, response.data.articles);
          return response.data.articles;
        } else {
          commit(FETCH_ALL_ARTICLE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ARTICLE_ERROR, error);
        return null;
      }
    },
    async findOneArticle(context, payload) {
      let articleIndex = context.state.articles.findIndex(element => element.id === payload.articleId);
      if (articleIndex != -1) {
        return context.state.articles[articleIndex];
      } else {
        context.commit(FETCH_ONE_ARTICLE);
        try {
          let response = await HelpCenterAPI.findArticle(payload.articleId);
          if (response.data.success === true) {
            context.commit(FETCH_ONE_ARTICLE_SUCCESS, response.data.article);
            return response.data.article;
          } else {
            context.commit(FETCH_ONE_ARTICLE_ERROR);
            return null;
          }
        } catch (error) {
          context.commit(FETCH_ONE_ARTICLE_ERROR, error);
          return null;
        }
      }
    },
    async feedbackArticle(context, payload) {
      await HelpCenterAPI.feedbackArticle(payload.article);  
    }
  },
};
