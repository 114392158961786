import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/drink", Tool.createFormData());
  },
  findOne (data) {
    return axios.post("/api/account/drink/" + data, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/drink/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/drink/update/" + data.id, Tool.createFormData(data));
  },
  updatePosition(data) {
    return axios.post("/api/account/drink/update/position", Tool.createFormData(data));
  },
  updateTranslation(data) {
    return axios.post("/api/account/drink/update/translation", Tool.createFormData(data));
  },
  active (data) {
    return axios.post("/api/account/drink/active/" + data.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/drink/remove/" + data.id, Tool.createFormData());
  },
  checkFile (data) {
    return axios.post("/api/account/drink/checkFile", Tool.createFormData(data));
  },
  validate (data) {
    return axios.post("/api/account/drink/validate", Tool.createFormData(data));
  },
  import (data) {
    return axios.post("/api/account/drink/import", Tool.createFormData(data));
  },
};
