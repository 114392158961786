<template>
  <b-form-group :id="id + '-input-group'" :label="name" :label-for="id" :label-class="inputRequired ? 'required' : ''">
    <b-form-file
      :id="id"
      :name="name"
      v-model="inputValue"
      :class="{ 'is-invalid': errorMessage }"
      :multiple="multiple"
      :placeholder="$t('front.form.global.chooseFile')"
      :drop-placeholder="$t('front.form.global.dropFile')"
      :browse-text="$t('front.form.global.browse')"
    >
    </b-form-file>
    <b-form-text :id="id + '-help-block'">
      {{ helpText }}
    </b-form-text>
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { useField } from "vee-validate";
import { BFormFile } from "bootstrap-vue";

export default {
  name: "FormFile",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  components: {
    BFormFile,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    help: {
      type: String,
      default: null,
    },
    modelValue: {
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField("formFile-" + props.id, props.rules, {
      label: props.name,
      syncVModel: true,
      validateOnMount: false,
      validateOnValueUpdate: false,
    });

    return {
      inputValue,
      errorMessage,
    };
  },
  computed: {
    inputRequired() {
      if (typeof this.rules["required"] !== "undefined") {
        if (this.rules["required"] === true) {
          return true;
        }
      }
      return false;
    },
    helpText() {
      let accepts = [];

      if (this.rules.ext) {
        accepts = accepts.concat(this.rules.ext);
      }

      return accepts.length > 0 ? this.help + accepts.join(", ") : null;
    },
  },
};
</script>

<style lang="scss">
#files-input-group {
  .custom-file-input {
    border-radius: 10px;

    &:focus .custom-file-label {
      box-shadow: none;
    }
  }

  .custom-file-label {
    border-radius: 10px;
  }
}
</style>
