<template>
  <b-container fluid class="contact-page-container">
    <b-container fluid class="contact-page-inner-container"></b-container>
    <b-container class="contact-page-below-container">
      <b-row class="mb-4">
        <b-col md="5" class="contact-text-col">
          <div class="main-heading-container">
            <div class="heading-inner-container">
              <h1 v-scrollanimation="{name: 'fade-up-appear'}">{{ this.$t("front.contact.heading") }}</h1>
            </div>
          </div>
          <p v-scrollanimation="{name: 'fade-up', delay: 250}">{{ this.$t("front.contact.description") }}</p>
          <div v-scrollanimation="{name: 'fade-up', delay: 350}" class="contact-info-container">
            <div class="contact-info-heading">{{ this.$t("front.contact.location") }}</div>
            <div class="contact-address">
              <span>46 rue de la Moselle,</span>
              <span>88190 Golbey. FRANCE</span>
            </div>
            <div class="contact-info-heading">{{ this.$t("front.contact.contactDetail") }}</div>
            <div class="contact-detail">
              <span>
                <a href="mailto:contact@mizogoo.fr">contact@mizogoo.fr</a>
              </span>
              <span>03 72 54 00 74</span>
            </div>
          </div>
        </b-col>
        <b-col offset-md="1" md="6" class="contact-form-col">
          <ContactForm />
        </b-col>
      </b-row>
    </b-container>

    <b-container class="extra-contact-container">
      <b-row>
        <b-col md="4">
          <div class="extra-contact">
            <i class="far fa-comment"></i>
            <div class="extra-contact-heading">{{ this.$t("front.contact.liveChat") }}</div>
            <div class="extra-contact-description">{{ this.$t("front.contact.chatWithUs") }}</div>
            <b-button @click="openLivechat" variant="outline-primary">
              {{ this.$t("front.contact.startChatting") }}
            </b-button>
          </div>
        </b-col>
        <b-col md="4">
          <div class="extra-contact">
            <i class="fas fa-paper-plane"></i>
            <div class="extra-contact-heading">{{ this.$t("front.contact.emailUs") }}</div>
            <div class="extra-contact-description">{{ this.$t("front.contact.writeDownDirectly") }}</div>
            <a href="mailto:contact@mizogoo.fr" class="btn btn-outline-primary">
              {{ this.$t("front.contact.writeDown") }}
            </a>
          </div>
        </b-col>
        <b-col md="4">
          <div class="extra-contact">
            <i class="fas fa-tv"></i>
            <div class="extra-contact-heading">{{ this.$t("front.contact.liveDemo") }}</div>
            <div class="extra-contact-description">{{ this.$t("front.contact.discover") }}</div>
            <a href="https://calendly.com/mizogoo/demo-mizogoo?text_color=151f29&primary_color=ff395e" target="_blank" class="btn btn-outline-primary">
              {{ this.$t("front.contact.askDemo") }}
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import ContactForm from "@mizogoo/components/global/contact-form/ContactForm";

export default {
  mixins: [MetaMixin],
  name: "ContactPage",
  components: {
    ContactForm,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.contact.title");
    },
    metaDescription() {
      return this.$t("front.metadata.contact.description");
    },
  },
  methods: {
    openLivechat() {
      LiveChatWidget.call("maximize");
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-page-container {
  padding: 0;
  padding-bottom: 4rem;

  .contact-page-inner-container {
    padding-top: 9rem;
    padding-bottom: 40rem;
    background-image: url("../../../../assets/img/front/global/decorations/dotted_background.svg"), linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .contact-page-below-container {
    margin-top: -36rem;

    .contact-text-col {
      color: #fff;

      .main-heading-container {
        margin-top: 2rem;
      }

      p {
        margin-bottom: 2rem;
      }

      .contact-info-container {
        .contact-info-heading {
          margin-top: 1.5rem;
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
          font-weight: 600;
          text-transform: uppercase;
        }

        .contact-address,
        .contact-detail {
          display: flex;
          flex-direction: column;
          margin-bottom: 0.8rem;
          margin-left: 0.5rem;
          font-weight: 400;

          a {
            color: #fff;
          }
        }
      }
    }

    .contact-form-col {
      padding: 4rem;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
      border-radius: 25px;
    }
  }

  .extra-contact-container {
    padding-top: 5rem;
    
    .extra-contact {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 2rem;
      padding: 2rem 3rem;
      text-align: center;
      background-color: $mizogoo-gray-light;
      border-radius: 25px;

      i {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }

      .extra-contact-heading {
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      .extra-contact-description {
        margin-bottom: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .contact-page-container {
    text-align: center;

    .contact-page-inner-container {
      padding-top: 2rem;
    }

    .contact-page-below-container {
      .contact-text-col {
        margin-bottom: 4rem;
      }

      .contact-form-col {
        padding: 3rem 2rem;
      }
    }
  }
}
</style>
