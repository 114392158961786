import SubscriptionAPI from "../api/subscription";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    subscriptions: [],
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    subscriptions (state) {
      return state.subscriptions;
    }
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.subscriptions = [];
    },
    [FETCH_ALL_SUCCESS] (state, subscriptions) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.subscriptions = subscriptions;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.subscriptions = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await SubscriptionAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.subscriptions);
          return response.data.subscriptions;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
  }
}
