import ArticleAPI from "../api/article";

const
  FETCH_ALL_WITH_PAGINATION = "FETCH_ALL_WITH_PAGINATION",
  FETCH_ALL_WITH_PAGINATION_SUCCESS = "FETCH_ALL_WITH_PAGINATION_SUCCESS",
  FETCH_ALL_WITH_PAGINATION_ERROR = "FETCH_ALL_WITH_PAGINATION_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  FETCH_TWO_LAST = "FETCH_TWO_LAST",
  FETCH_TWO_LAST_SUCCESS = "FETCH_TWO_LAST_SUCCESS",
  FETCH_TWO_LAST_ERROR = "FETCH_TWO_LAST_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All With Pagination, One
    isLoading: false,
    error: null,
    // Data
    articles: [],
    // Overview
    isLoadingArticleOverview: true,
    articlesOverview: [],
    // Page (blog list pagination)
    page: 1,
  },
  getters: {
    // Fetch All With Pagination, One
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
    hasError(state) {
      return state.error !== null;
    },
    // Data
    articles(state) {
      return state.articles;
    },
    hasArticle(state) {
      return state.articles.length > 0;
    },
    // Articles overview
    isLoadingArticleOverview(state) {
      return state.isLoadingArticleOverview;
    },
    articlesOverview(state) {
      return state.articlesOverview;
    },
    hasArticleOverview(state) {
      return state.articlesOverview.length > 0;
    },
    page(state) {
      return state.page;
    },
  },
  mutations: {
    // Fetch all with pagination (12 by 12)
    [FETCH_ALL_WITH_PAGINATION](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_ALL_WITH_PAGINATION_SUCCESS](state, articles) {
      state.isLoading = false;
      state.error = null;
      state.articles.push(...articles);
    },
    [FETCH_ALL_WITH_PAGINATION_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.articles = [];
    },
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    // Fetch Two Last
    [FETCH_TWO_LAST](state) {
      state.isLoadingArticleOverview = true;
      state.error = null;
    },
    [FETCH_TWO_LAST_SUCCESS](state, articlesOverview) {
      state.isLoadingArticleOverview = false;
      state.error = null;
      state.articlesOverview = articlesOverview;
    },
    [FETCH_TWO_LAST_ERROR](state, error) {
      state.isLoadingArticleOverview = false;
      state.error = error;
      state.articlesOverview = [];
    },
  },
  actions: {
    async findAllWithPagination({ commit, state }) {
      commit(FETCH_ALL_WITH_PAGINATION);
      try {
        let response = await ArticleAPI.findAllWithPagination({ page: state.page++ });
        if (response.data.success === true) {
          commit(FETCH_ALL_WITH_PAGINATION_SUCCESS, response.data.articles);
          return response.data.articles;
        } else {
          commit(FETCH_ALL_WITH_PAGINATION_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_WITH_PAGINATION_ERROR);
        return null;
      }
    },
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await ArticleAPI.findOne(payload.article);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.article;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async findTwoLast({ commit }) {
      commit(FETCH_TWO_LAST);
      try {
        let response = await ArticleAPI.findTwoLast();
        if (response.data.success === true) {
          commit(FETCH_TWO_LAST_SUCCESS, response.data.articles);
          return response.data.articles;
        } else {
          commit(FETCH_TWO_LAST_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_TWO_LAST_ERROR);
        return null;
      }
    },
  }
}
