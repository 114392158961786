import CreationAPI from '../api/creation'

const
  FETCH_ONE = 'FETCH_ONE',
  FETCH_ONE_SUCCESS = 'FETCH_ONE_SUCCESS',
  FETCH_ONE_ERROR = 'FETCH_ONE_ERROR',

  CREATING = 'CREATING',
  CREATING_SUCCESS = 'CREATING_SUCCESS',
  CREATING_ERROR = 'CREATING_ERROR',

  UPDATING = 'UPDATING',
  UPDATING_SUCCESS = 'UPDATING_SUCCESS',
  UPDATING_ERROR = 'UPDATING_ERROR',

  REMOVING = 'REMOVING',
  REMOVING_SUCCESS = 'REMOVING_SUCCESS',
  REMOVING_ERROR = 'REMOVING_ERROR',

  RENAMING = 'RENAMING',
  RENAMING_SUCCESS = 'RENAMING_SUCCESS',
  RENAMING_ERROR = 'RENAMING_ERROR',

  CREATING_COMMENT = 'CREATING_COMMENT',
  CREATING_COMMENT_SUCCESS = 'CREATING_COMMENT_SUCCESS',
  CREATING_COMMENT_ERROR = 'CREATING_COMMENT_ERROR',

  RELATED_DOCUMENT_CREATE = 'RELATED_DOCUMENT_CREATE',
  RELATED_DOCUMENT_CREATE_SUCCESS = 'RELATED_DOCUMENT_CREATE_SUCCESS',
  RELATED_DOCUMENT_CREATE_ERROR = 'RELATED_DOCUMENT_CREATE_ERROR',

  SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'

export default {
  namespaced: true,
  state: {
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Renaming
    isLoadingRenaming: false,
    errorRenaming: null,
    // Creating Comment
    isLoadingCreatingComment: false,
    errorCreatingComment: null,
    // Related Document Create
    isLoadingRelatedDocumentCreate: false,
    errorRelatedDocumentCreate: null,
    uploadProgress: 0
  },
  getters: {
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null
    },
    errorFetchOne (state) {
      return state.errorFetchOne
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null
    },
    errorCreating (state) {
      return state.errorCreating
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null
    },
    errorUpdating (state) {
      return state.errorUpdating
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null
    },
    errorRemoving (state) {
      return state.errorRemoving
    },
    // Renaming
    isLoadingRenaming (state) {
      return state.isLoadingRenaming
    },
    hasErrorRenaming (state) {
      return state.errorRenaming !== null
    },
    errorRenaming (state) {
      return state.errorRenaming
    },
    // Creating Comment
    isLoadingCreatingComment (state) {
      return state.isLoadingCreatingComment
    },
    hasErrorCreatingComment (state) {
      return state.errorCreatingComment !== null
    },
    errorCreatingComment (state) {
      return state.errorCreatingComment
    },
    // Related Document Create
    isLoadingRelatedDocumentCreate (state) {
      return state.isLoadingRelatedDocumentCreate
    },
    hasErrorRelatedDocumentCreate (state) {
      return state.errorRelatedDocumentCreate !== null
    },
    errorRelatedDocumentCreate (state) {
      return state.errorRelatedDocumentCreate
    },
    uploadProgress (state) {
      return state.uploadProgress
    }
  },
  mutations: {
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true
      state.errorFetchOne = null
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false
      state.errorFetchOne = null
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false
      state.errorFetchOne = error
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true
      state.errorCreating = null
    },
    [CREATING_SUCCESS] (state, creation) {
      state.isLoadingCreating = false
      state.errorCreating = null
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false
      state.errorCreating = error
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true
      state.errorUpdating = null
    },
    [UPDATING_SUCCESS] (state, data) {
      state.isLoadingUpdating = false
      state.errorUpdating = null
      this.dispatch('cart/providingCartData', data.cart)
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false
      state.errorUpdating = error
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true
      state.errorRemoving = null
    },
    [REMOVING_SUCCESS] (state, data) {
      state.isLoadingRemoving = false
      state.errorRemoving = null

      const creationCategory = this.getters['creationCategory/creationCategories'].find(element => element.id === data.creation.creationCategoryId)
      creationCategory.creations = creationCategory.creations.filter(element => element.id !== data.creation.id)

      const cart = this.getters['cart/cart']
      cart.elements = cart.elements.filter(element => element.id !== data.cartElementId)
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false
      state.errorRemoving = error
    },
    // Renaming
    [RENAMING] (state) {
      state.isLoadingRenaming = true
      state.errorRenaming = null
    },
    [RENAMING_SUCCESS] (state, creation) {
      state.isLoadingRenaming = false
      state.errorRenaming = null
    },
    [RENAMING_ERROR] (state, error) {
      state.isLoadingRenaming = false
      state.errorRenaming = error
    },
    // Creating Comment
    [CREATING_COMMENT] (state) {
      state.isLoadingCreatingComment = true
      state.errorCreatingComment = null
    },
    [CREATING_COMMENT_SUCCESS] (state) {
      state.isLoadingCreatingComment = false
      state.errorCreatingComment = null
    },
    [CREATING_COMMENT_ERROR] (state, error) {
      state.isLoadingCreatingComment = false
      state.errorCreatingComment = error
    },
    // Related Document Create
    [RELATED_DOCUMENT_CREATE] (state) {
      state.isLoadingRelatedDocumentCreate = true
      state.errorRelatedDocumentCreate = null
    },
    [RELATED_DOCUMENT_CREATE_SUCCESS] (state) {
      state.isLoadingRelatedDocumentCreate = false
      state.errorRelatedDocumentCreate = null
    },
    [RELATED_DOCUMENT_CREATE_ERROR] (state, error) {
      state.isLoadingRelatedDocumentCreate = false
      state.errorRelatedDocumentCreate = error
    },
    [SET_UPLOAD_PROGRESS] (state, uploadProgress) {
      state.uploadProgress = uploadProgress
    },
  },
  actions: {
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE)
      try {
        let response = await CreationAPI.findOne(payload.creation)
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS)
          return response.data.creation
        } else {
          commit(FETCH_ONE_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR)
        return null
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING)
      try {
        let response = await CreationAPI.create(payload.data)
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.creation)
          return response.data
        } else {
          commit(CREATING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(CREATING_ERROR, error)
        return null
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING)
      try {
        let response = await CreationAPI.update(payload.creation, payload.data)
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data)
          return response.data
        } else {
          commit(UPDATING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(UPDATING_ERROR, error)
        return null
      }
    },
    async updateTemplate ({ commit }, payload) {
      commit(UPDATING)
      try {
        let response = await CreationAPI.updateTemplate(payload.creation, payload.data)
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data)
          return response.data
        } else {
          commit(UPDATING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(UPDATING_ERROR, error)
        return null
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING)
      try {
        let response = await CreationAPI.remove(payload.creation)
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, { creation: payload.creation, cartElementId: response.data.cartElementId })
          return null
        } else {
          commit(REMOVING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(REMOVING_ERROR, error)
        return null
      }
    },
    async rename ({ commit }, payload) {
      commit(RENAMING)
      try {
        let response = await CreationAPI.rename(payload.form)
        if (response.data.success === true) {
          commit(RENAMING_SUCCESS, response.data.creation)
          return response.data
        } else {
          commit(RENAMING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(RENAMING_ERROR, error)
        return null
      }
    },
    async createComment ({ commit }, payload) {
      commit(CREATING_COMMENT)
      try {
        let response = await CreationAPI.createComment(payload.creationId, payload.form)
        if (response.data.success === true) {
          commit(CREATING_COMMENT_SUCCESS, response.data)
          return response.data
        } else {
          commit(CREATING_COMMENT_ERROR)
          return null
        }
      } catch (error) {
        commit(CREATING_COMMENT_ERROR)
        return null
      }
    },
    async relatedDocumentCreate ({ commit }, payload) {
      commit(RELATED_DOCUMENT_CREATE)
      try {
        let response = await CreationAPI.relatedDocumentCreate({
          store: this,
          creation: payload.creation,
          form: payload.form
        })

        if (response.data.success === true) {
          commit(RELATED_DOCUMENT_CREATE_SUCCESS, response.data)
          return response.data
        } else {
          commit(RELATED_DOCUMENT_CREATE_ERROR)
          return null
        }
      } catch (error) {
        commit(RELATED_DOCUMENT_CREATE_ERROR)
        return null
      }
    },
    setUploadProgress ({ commit }, uploadProgress) {
      commit(SET_UPLOAD_PROGRESS, uploadProgress)
    },
  }
}
