import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findOne(institution) {
    return axios.post("/api/account/institution/" + institution, Tool.createFormData());
  },
  create(data) {
    return axios.post("/api/account/institution/create", Tool.createFormData(data));
  },
  update(data) {
    return axios.post("/api/account/institution/update/" + data.id, Tool.createFormData(data));
  },
  updateInstitutionLocaleFront(data) {
    return axios.post("/api/account/institution/update/" + data.id + "/locale/front", Tool.createFormData(data));
  },
  delete(data) {
    return axios.post("/api/account/institution/delete/" + data.id, Tool.createFormData());
  },
  default(data) {
    return axios.post("/api/account/institution/default/" + data.id, Tool.createFormData());
  },
  togglePdfMenu(data) {
    return axios.post("/api/account/institution/toggle/pdf/menu/" + data.id, Tool.createFormData(data));
  }
};
