<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
    >
      <!-- Image -->
      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-3-blocks
      :heading="this.feature3Blocks.heading"
      :description="this.feature3Blocks.description"
      :cols="this.feature3Blocks.cols"
    ></feature-3-blocks>

    <long-text
      :subheading="this.story.subheading"
      :heading="this.story.heading"
      :description="this.story.description"
      :numbers="this.story.numbers"
      :images="this.story.images"
    ></long-text>

    <contact-section></contact-section>

    <div class="separator"></div>

    <social-commitments></social-commitments>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import Feature3Blocks from "@mizogoo/components/front/layout/features/3Blocks/Feature3Blocks";
import LongText from "@mizogoo/components/front/layout/LongText";
import ContactSection from "@mizogoo/components/front/layout/ContactSection";
import SocialCommitments from "@mizogoo/components/front/layout/SocialCommitments";

export default {
  mixins: [MetaMixin],
  name: "AboutUsPage",
  components: {
    TopBanner,
    Feature3Blocks,
    LongText,
    ContactSection,
    SocialCommitments,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.aboutUs.title");
    },
    metaDescription() {
      return this.$t("front.metadata.aboutUs.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.aboutUs.topBanner.heading"),
        description: this.$t("front.aboutUs.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/about-us/banner_about_us.jpg"),
          alt: this.$t("front.aboutUs.topBanner.imageAlt"),
        },
        // imageDecoration: require("@mizogoo/assets/img/banner_create_border.png"),
      };
    },
    feature3Blocks() {
      return {
        heading: this.$tm("front.aboutUs.feature3Blocks.heading"),
        description: this.$t("front.aboutUs.feature3Blocks.description"),
        cols: [
          {
            id: 0,
            icon: {
              src: require("@mizogoo/assets/img/front/global/header/header_icon_create.png"),
              alt: this.$t("front.aboutUs.feature3Blocks.cols[id].iconAlt"),
            },
            heading: this.$tm("front.aboutUs.feature3Blocks.cols[0].heading"),
            description: this.$t("front.aboutUs.feature3Blocks.cols[0].description"),
          },
          {
            id: 1,
            icon: {
              src: require("@mizogoo/assets/img/front/global/header/header_icon_dev.png"),
              alt: this.$t("front.aboutUs.feature3Blocks.cols[1].iconAlt"),
            },
            heading: this.$tm("front.aboutUs.feature3Blocks.cols[1].heading"),
            description: this.$t("front.aboutUs.feature3Blocks.cols[1].description"),
          },
          {
            id: 2,
            icon: {
              src: require("@mizogoo/assets/img/front/global/header/header_icon_communicate.png"),
              alt: this.$t("front.aboutUs.feature3Blocks.cols[2].iconAlt"),
            },
            heading: this.$tm("front.aboutUs.feature3Blocks.cols[2].heading"),
            description: this.$t("front.aboutUs.feature3Blocks.cols[2].description"),
          },
        ],
      };
    },
    story() {
      return {
        subheading: this.$t("front.aboutUs.story.subheading"),
        heading: this.$tm("front.aboutUs.story.heading"),
        description: this.$t("front.aboutUs.story.description"),
        numbers: [
          {
            number: this.$t("front.aboutUs.story.numbers[0].number"),
            caption: this.$t("front.aboutUs.story.numbers[0].caption"),
          },
          {
            number: this.$t("front.aboutUs.story.numbers[1].number"),
            caption: this.$t("front.aboutUs.story.numbers[1].caption"),
          },
          {
            number: this.$t("front.aboutUs.story.numbers[2].number"),
            caption: this.$t("front.aboutUs.story.numbers[2].caption"),
          },
        ],
        images: [
          {
            id: 0,
            src: require("@mizogoo/assets/img/front/about-us/mizogoo_workers_at_the_office_1.jpg"),
            alt: this.$t("front.aboutUs.story.imageAlt"),
          },
          {
            id: 1,
            src: require("@mizogoo/assets/img/front/about-us/mizogoo_workers_at_the_office_2.jpg"),
            alt: this.$t("front.aboutUs.story.imageAlt"),
          },
        ],
      };
    },
    contact() {
      return {
        subheading: this.$t("front.aboutUs.contact.subheading"),
        heading: this.$tm("front.aboutUs.contact.heading"),
        inputs: [
          this.$t("front.aboutUs.contact.inputs[0]"),
          this.$t("front.aboutUs.contact.inputs[1]"),
          this.$t("front.aboutUs.contact.inputs[2]"),
        ],
        buttonLabel: this.$t("front.aboutUs.contact.buttonLabel"),
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
