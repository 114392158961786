const
  SET_PREMIUM_ONLY_MODAL_ELEMENTS = "SET_PREMIUM_ONLY_MODAL_ELEMENTS";

export default {
  namespaced: true,
  state: {
    functionality: null,
    heading: null,
    description: null,
    image: {
      src: null,
      alt: null,
    }
  },
  mutations: {
    [SET_PREMIUM_ONLY_MODAL_ELEMENTS](state, elements) {
      state.functionality = elements.functionality;
      state.heading = elements.heading;
      state.description = elements.description;
      state.image = elements.image;
    },
  },
}