import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/dish/category", Tool.createFormData());
  },
  findOne (data) {
    return axios.post("/api/account/dish/category/" + data, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/dish/category/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/dish/category/update/" + data.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/dish/category/remove/" + data, Tool.createFormData());
  },
  analyse (data) {
    return axios.post("/api/account/dish/category/analyse/" + data.id, Tool.createFormData());
  },
  toggleDishesActivation (data) {
    return axios.post("/api/account/dish/category/toggle_dishes_activation/" + data.dishCategory.id, Tool.createFormData(data));
  }
};
