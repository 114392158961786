import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/analysis", Tool.createFormData());
  },
  findOne (analysisId) {
    return axios.post("/api/account/analysis/" + analysisId, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/analysis/create", Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/analysis/remove/" + data.id, Tool.createFormData());
  },
};
