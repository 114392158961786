<template>
  <div id="mizogoo">
    <Workspace v-if="isWorkspace" />
    <QrCode v-else-if="isQrCode" />
    <Front v-else />
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

import Front from "@mizogoo/components/front/Front";
const Workspace = defineAsyncComponent(() =>
  import(/* webpackPrefetch: true */ "@mizogoo/components/workspace/Workspace")
);
import QrCode from "@mizogoo/components/qrcode/QrCode";

export default {
  name: "App",
  components: { Front, Workspace, QrCode },
};
</script>

<style lang="scss">
// Fix bootstrap tooltip position (known issue)
// https://github.com/bootstrap-vue/bootstrap-vue/issues/1732
.tooltip {
  top: 0;
}
</style>
