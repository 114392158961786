<template>
  <div id="stripe-payment-intent">
    <template v-if="!stripe">
      <b-button
          @click.prevent.stop="loadStripeCheckout"
          variant="primary">
        {{ $t("front.cart.payByCreditCard") }}
      </b-button>
    </template>

    <template v-else>
      <Loading v-show="isLoading"/>

      <form
          v-show="!isLoading"
          id="payment-form">
        <!--    <div id="card-element"></div>-->
        <label for="card-number">
          {{ $t("front.cart.cardNumber") }}
        </label>
        <div id="card-number" name="card-number" class="field empty"></div>
        <b-row>
          <b-col sm="6">
            <label for="card-expiry">
              {{ $t("front.cart.expiryDate") }}
            </label>
            <div id="card-expiry" name="card-expiry" class="field"></div>
          </b-col>
          <b-col sm="6">
            <label for="card-cvc">
              {{ $t("front.cart.cvc") }}
            </label>
            <div id="card-cvc" name="card-cvc" class="field"></div>
          </b-col>
        </b-row>
        <button id="submit" class="mt-3">
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">{{ $t("front.cart.pay") }} {{ formatPrice(amount) }}</span>
        </button>
        <p id="card-errors" role="alert"></p>
        <!--    <p class="result-message hidden">-->
        <!--      Payment succeeded, see the result in your-->
        <!--      <a href="" target="_blank">Stripe dashboard.</a> Refresh the page to pay again.-->
        <!--    </p>-->
      </form>
    </template>
  </div>
</template>

<script>
import { loadStripeCheckout } from './load-checkout';
import Loading from "@mizogoo/components/workspace/layout/Loading";

export default {
  name: 'StripePaymentIntent',
  components: {
    Loading
  },
  props: {
    amount: {
      type: Number
    }
  },
  data () {
    return {
      isLoading: false,
      stripe: null,
      elements: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null
    };
  },
  computed: {
    // Payment Validate
    isLoadingPaymentValidate () {
      return this.$store.getters["stripe/isLoadingPaymentValidate"];
    },
    hasErrorPaymentValidate () {
      return this.$store.getters["payment/hasErrorPaymentValidate"];
    },
    errorPaymentValidate () {
      return this.$store.getters["payment/errorPaymentValidate"];
    },
    style () {
      return {
        base: {
          color: "#151f29",
          fontFamily: "Cera Pro",
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#6c757d"
          }
        },
        invalid: {
          fontFamily: "Cera Pro",
          color: "#151f29"
        }
      };
    },
    form () {
      return document.getElementById('payment-form');
    },
  },
  methods: {
    loadStripeCheckout () {
      loadStripeCheckout(this.pk, 'v3', () => {
        this.isLoading = true

        const options = {
          locale: 'fr',
        };
        this.stripe = window.Stripe(process.env.STRIPE_PUBLIC_KEY, options);

        fetch('/api/payment/stripe/create-payment-intent', {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
        })
            .then((result) => {
              return result.json();
            })
            .then((data) => {
              this.elements = this.stripe.elements();

              this.cardNumber = this.elements.create('cardNumber', { style: this.style })
              this.cardNumber.update({ placeholder: 'XXXX XXXX XXXX XXXX' });
              this.cardNumber.mount('#card-number')

              this.cardNumber.on("change", (event) => {
                document.querySelector("button").disabled = event.empty;
                document.querySelector("#card-errors").textContent = event.error ? event.error.message : "";
              });

              this.cardExpiry = this.elements.create('cardExpiry', { style: this.style })
              this.cardExpiry.mount('#card-expiry')

              this.cardExpiry.on("change", (event) => {
                document.querySelector("button").disabled = event.empty;
                document.querySelector("#card-errors").textContent = event.error ? event.error.message : "";
              });

              this.cardCvc = this.elements.create('cardCvc', { style: this.style })
              this.cardCvc.mount('#card-cvc')

              this.cardCvc.on("change", (event) => {
                document.querySelector("button").disabled = event.empty;
                document.querySelector("#card-errors").textContent = event.error ? event.error.message : "";
              });

              this.form.addEventListener("submit", (event) => {
                event.preventDefault();
                this.payWithCard(data.clientSecret);
              });

              this.isLoading = false
            });
      });
    },
    payWithCard (clientSecret) {
      this.loading(true);
      this.stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: this.cardNumber,
            }
          })
          .then((result) => {
            if (result.error) {
              // Show error to your customer
              this.showError(result.error.message);
            } else {
              // The payment succeeded!
              this.orderComplete(result);
            }
          });
    },
    orderComplete (result) {
      this.$store
          .dispatch("stripe/validate", { form: { paymentIntent: result.paymentIntent } })
          .then((response) => {
            if (!this.hasErrorStripeValidate) {
              return this.$router.push({ name: 'cart_validated', params: { reference: response.reference } }).catch(() => {});
            }

            this.loading(false);
            document.querySelector("button").disabled = true;
          });
    },
    showError (errorMsgText) {
      this.loading(false);
      var errorMsg = document.querySelector("#card-errors");
      errorMsg.textContent = errorMsgText;
      setTimeout(() => {
        errorMsg.textContent = "";
      }, 4000);
    },
    loading (isLoading) {
      if (isLoading) {
        // Disable the button and show a spinner
        document.querySelector("button").disabled = true;
        document.querySelector("#spinner").classList.remove("hidden");
        document.querySelector("#button-text").classList.add("hidden");
      } else {
        document.querySelector("button").disabled = false;
        document.querySelector("#spinner").classList.add("hidden");
        document.querySelector("#button-text").classList.remove("hidden");
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/stripe.scss";

#stripe-payment-intent {
  max-width: 450px;
}
</style>
