<template>
  <div id="qr-code">
    <router-view/>
  </div>
</template>

<script>
import '@mizogoo/assets/css/qr-code/qr-code.scss'

export default {
  name: "QrCode",
};
</script>

<style lang="scss" scoped>
</style>
