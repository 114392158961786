<template>
  <b-container fluid class="cart-container">
    <Loading v-if="isLoadingCart || isLoadingPaymentMethods"/>

    <template v-else-if="cart && cart.elements.length > 0">
      <b-row>
        <!-- Left column (steps overview with links to navigate between them) -->
        <b-col md="12" lg="2" class="cart-col">
          <Step active="payment"/>
        </b-col>

        <!-- Main column (delivery address form) -->
        <b-col md="12" lg="8" class="cart-col main-col">
          <BackButton/>

          <b-row>
            <b-col>
              <h1>{{ $t("front.cart.payment") }}</h1>
            </b-col>
          </b-row>

          <!-- Errors -->
          <b-row v-if="hasErrorCart || hasErrorPaymentMethods">
            <b-col class="mb-2 large-form-container">
              <FlashBag v-if="hasErrorCart" :message="errorCart"/>
              <FlashBag v-if="hasErrorPaymentMethods" :message="errorPaymentMethods"/>
            </b-col>
          </b-row>

          <b-row>
            <template v-if="cart.billingAddress && cart.deliveryAddress">
              <b-col class="large-form-container">
                <b-tabs class="custom-tabs">
                  <b-tab
                    v-for="paymentMethod in paymentMethods"
                    :key="'payment-' + paymentMethod.id"
                    :title="paymentMethod.name"
                  >
                    <p>{{ paymentMethod.description }}</p>

                    <!-- Credit card payment -->
                    <template v-if="paymentMethod.constName === 'STRIPE'">
                      <StripePaymentIntent :amount="prices.amountTtc"/>
                      <div class="cards-logos">
                        <i class="fab fa-cc-visa"></i>
                        <i class="fab fa-cc-mastercard"></i>
                        <i class="fab fa-cc-amex"></i>
                        <i class="fab fa-cc-discover"></i>
                        <i class="fab fa-cc-jcb"></i>
                      </div>
                      <!-- Terms and conditions (automatically accepted by subscribing) -->
                      <div class="terms mt-4">
                        {{ $t('workspace.subscription.acceptTerms') }}
                        <router-link :to="{ name: 'terms_sale' }" custom class="dark-link" target="_blank">
                          {{ $t('workspace.subscription.termsSale') }}
                        </router-link>
                        {{ $t('workspace.subscription.acceptPrivacy') }}
                        <router-link :to="{ name: 'privacy' }" custom class="dark-link" target="_blank">
                          {{ $t('workspace.subscription.privacy') }}
                        </router-link>
                        .
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>
              </b-col>
            </template>

            <b-col v-else>
              <p>{{ $t("front.cart.selectAddresses") }}</p>
              <button
                class="btn btn-outline-primary mt-3"
                @click="$router.push({ name: 'cart_address' }).catch(() => {})"
              >
                {{ $t("front.cart.completeDeliveryInfos") }}
              </button>
            </b-col>
          </b-row>
        </b-col>

        <b-col md="12" lg="2" class="cart-col cart-right">
          <Summary currentStep="payment" :prices="prices" :cartElements="cart.elements"/>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import BackButton from "@mizogoo/components/workspace/layout/BackButton";
import Loading from "@mizogoo/components/workspace/layout/Loading";
import FlashBag from "@mizogoo/components/FlashBag";
import Step from "@mizogoo/components/front/pages/CartPage/Step";
import Summary from "@mizogoo/components/front/pages/CartPage/Summary";
import FormInput from "@mizogoo/components/form/FormInput";
import FormSelect from "@mizogoo/components/form/FormSelect";
import FormCheckbox from "@mizogoo/components/form/FormCheckbox";
import StripePaymentIntent from "@mizogoo/components/payment/Stripe/StripePaymentIntent";

export default {
  name: "CartPageAddress",
  components: {
    BackButton,
    Loading,
    FlashBag,
    Step,
    Summary,
    FormInput,
    FormSelect,
    FormCheckbox,
    StripePaymentIntent,
  },
  data() {
    return {};
  },
  computed: {
    // Cart
    isLoadingCart() {
      return this.$store.getters["cart/isLoadingCart"];
    },
    hasErrorCart() {
      return this.$store.getters["cart/hasErrorCart"];
    },
    errorCart() {
      return this.$store.getters["cart/errorCart"];
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
    prices() {
      return this.$store.getters["cart/prices"];
    },
    // Payment Methods
    isLoadingPaymentMethods() {
      return this.$store.getters["cart/isLoadingPaymentMethods"];
    },
    hasErrorPaymentMethods() {
      return this.$store.getters["cart/hasErrorPaymentMethods"];
    },
    errorPaymentMethods() {
      return this.$store.getters["cart/errorPaymentMethods"];
    },
    paymentMethods() {
      return this.$store.getters["cart/paymentMethods"];
    },
  },
  methods: {
    // Product
    productFeatureValueCustomizable(productFeatureValuesSorted) {
      return productFeatureValuesSorted.filter(
        (element) => element.feature.featureFamily.constName !== "CUSTOMIZABLE"
      );
    },
    // Payment Method
    paymentMethodFile(paymentMethod) {
      return {
        src: paymentMethod.file.thumbnails["100_100"],
        alt: paymentMethod.name,
      };
    },
  },
  async created() {
    await this.$store.dispatch("cart/findCart");

    if (!this.cart && this.cart.elements.length === 0) {
      return this.$router.push({ name: "home" }).catch(() => {});
    }

    await this.$store.dispatch("cart/findPaymentMethods");
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
    this.$store.dispatch("global/fetchFrontHeaderVisible", false);
    this.$store.dispatch("global/fetchFrontFooterVisible", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
    this.$store.dispatch("global/fetchFrontHeaderVisible", true);
    this.$store.dispatch("global/fetchFrontFooterVisible", true);
  },
};
</script>

<style lang="scss" scoped>
.cards-logos {
  display: flex;

  i {
    font-size: 3rem;
    margin: 1rem 0.5rem;
    opacity: 0.6;

    &:first-child {
      margin-left: 0;
    }
  }
}
</style>
