import AccountAddressAPI from "../api/account_address";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  DEFAULT = "DEFAULT",
  DEFAULT_SUCCESS = "DEFAULT_SUCCESS",
  DEFAULT_ERROR = "DEFAULT_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    addresses: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Default
    isLoadingDefault: false,
    errorDefault: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll(state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll(state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll(state) {
      return state.errorFetchAll;
    },
    hasAddresses(state) {
      return state.addresses.length > 0;
    },
    addresses(state) {
      return state.addresses;
    },
    // Fetch One
    isLoadingFetchOne(state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne(state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne(state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating(state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating(state) {
      return state.errorCreating !== null;
    },
    errorCreating(state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating(state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating(state) {
      return state.errorUpdating !== null;
    },
    errorUpdating(state) {
      return state.errorUpdating;
    },
    // Removing
    isLoadingRemoving(state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving(state) {
      return state.errorRemoving !== null;
    },
    errorRemoving(state) {
      return state.errorRemoving;
    },
    // Default
    isLoadingDefault(state) {
      return state.isLoadingDefault;
    },
    hasErrorDefault(state) {
      return state.errorDefault !== null;
    },
    errorDefault(state) {
      return state.errorDefault;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.addresses = [];
    },
    [FETCH_ALL_SUCCESS](state, addresses) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.addresses = addresses;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.addresses = [];
    },
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING](state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS](state, address) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.addresses.push(address);
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING](state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS](state, address) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      let addressToUpdateIndex = state.addresses.findIndex(element => element.id === address.id);
      if (addressToUpdateIndex != -1) {
        state.addresses[addressToUpdateIndex] = address;
      }
    },
    [UPDATING_ERROR](state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Removing
    [REMOVING](state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS](state, data) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.addresses = state.addresses.filter(element => element.id !== data.removedAddress.id);
      let addressToRemoveIndex = state.addresses.findIndex(element => element.id === data.newAddressDefault.id);
      if (addressToRemoveIndex != -1) {
        state.addresses[addressToRemoveIndex].isDefault = data.newAddressDefault.isDefault;
      }
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Default
    [DEFAULT](state) {
      state.isLoadingDefault = true;
      state.errorDefault = null;
    },
    [DEFAULT_SUCCESS](state, address) {
      state.isLoadingDefault = false;
      state.errorDefault = null;
      // The previous one (that will not be default anymore)
      let addressToUnsetDefaultIndex = state.addresses.findIndex(element => element.isDefault === true);
      if (addressToUnsetDefaultIndex != -1) {
        state.addresses[addressToUnsetDefaultIndex].isDefault = false;
      }
      // The new one (that it now the new default address)
      let addressToSetDefaultIndex = state.addresses.findIndex(element => element.id === address.id);
      if (addressToSetDefaultIndex != -1) {
        state.addresses[addressToSetDefaultIndex].isDefault = true;
      }
    },
    [DEFAULT_ERROR](state, error) {
      state.isLoadingDefault = false;
      state.errorDefault = error;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await AccountAddressAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.addresses);
          return response.data.addresses;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountAddressAPI.findOne(payload.address);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.address;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountAddressAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.address);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountAddressAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.address);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountAddressAPI.remove(payload.address);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, {
            removedAddress: payload.address,
            newAddressDefault: response.data.newAddressDefault
          });
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async default({ commit }, payload) {
      commit(DEFAULT);
      try {
        let response = await AccountAddressAPI.default(payload.address);
        if (response.data.success === true) {
          commit(DEFAULT_SUCCESS, payload.address);
          return response.data;
        } else {
          commit(DEFAULT_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(DEFAULT_ERROR, error);
        return null;
      }
    },
  }
}
