export const loadStripeCheckout = (pk, version = 'v3', callback) => {
  if (window.Stripe) {
    callback();
    return;
  }
  let e = document.createElement('script');
  e.src = `https://js.stripe.com/${version}`;
  e.type='text/javascript';
  document.getElementsByTagName('head')[0].appendChild(e);
  e.addEventListener('load', callback);
};
