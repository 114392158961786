import axios from 'axios'
import Tool from '@mizogoo/class/tool'

export default {
  // Payment
  validate (data) {
    return axios.post('/api/payment/stripe/payment-validate', Tool.createFormData(data))
  },
  // Subscription
  retriveSubscription () {
    return axios.post('/api/payment/stripe/subscription/retrive-subscription', Tool.createFormData())
  },
  cancelSubscription () {
    return axios.post('/api/payment/stripe/subscription/cancel-subscription', Tool.createFormData())
  },
  uncancelSubscription () {
    return axios.post('/api/payment/stripe/subscription/uncancel-subscription', Tool.createFormData())
  }
}
