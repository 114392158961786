import AccountInstitutionQrCodeAPI from "../api/account_institution_qrcode";

const
  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  CREATING_PDF = "CREATING_PDF",
  CREATING_PDF_SUCCESS = "CREATING_PDF_SUCCESS",
  CREATING_PDF_ERROR = "CREATING_PDF_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Create Pdf
    isLoadingCreatingPdf: false,
    errorCreatingPdf: null,
  },
  getters: {
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne (state) {
      return state.errorFetchOne;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Creating Pdf
    isLoadingCreatingPdf (state) {
      return state.isLoadingCreatingPdf;
    },
    hasErrorCreatingPdf (state) {
      return state.errorCreatingPdf !== null;
    },
    errorCreatingPdf (state) {
      return state.errorCreatingPdf;
    },
  },
  mutations: {
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Creating Pdf
    [CREATING_PDF] (state) {
      state.isLoadingCreatingPdf = true;
      state.errorCreatingPdf = null;
    },
    [CREATING_PDF_SUCCESS] (state) {
      state.isLoadingCreatingPdf = false;
      state.errorCreatingPdf = null;
    },
    [CREATING_PDF_ERROR] (state, error) {
      state.isLoadingCreatingPdf = false;
      state.errorCreatingPdf = error;
    },
  },
  actions: {
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountInstitutionQrCodeAPI.findOne(payload.institutionQrCode);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.institutionQrCode;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountInstitutionQrCodeAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.institutionQrCode);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async createPdf ({ commit }, payload) {
      commit(CREATING_PDF);
      try {
        let response = await AccountInstitutionQrCodeAPI.createPdf(payload.form);
        if (response.data.success === true) {
          commit(CREATING_PDF_SUCCESS);
          return response.data;
        } else {
          commit(CREATING_PDF_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_PDF_ERROR, error);
        return null;
      }
    },
  }
};
