<template>
  <b-container class="services">
    <b-row>
      <b-col>
        <div class="heading-container">
          <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
          <div
            v-for="(headingPart, index) in heading"
            :key="index"
            class="heading-inner-container"
          >
            <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="service-tiles" align-h="center">
      <b-col v-for="(service, index) in services" :key="index" lg="3" md="6" align-selft="stretch" class="service-tile-column">
        <router-link :to="{ name: service.button.link}" class="h-100 text-decoration-none">
          <div
            class="service-tile"
            @mouseover="hoveredServiceId = service.id"
            @mouseleave="hoveredServiceId = null"
            :style="{backgroundImage: 'url(' + service.image.backgroundSrc + ')', backgroundPosition: 'center'}"
          >
            <b-img
              :src="hoveredServiceId === service.id ? service.image.animatedSrc : service.image.fixedSrc"
              :alt="service.image.alt"  
            />
            <div class="service-text">
              {{ service.text }}
            </div>
            
            <div class="service-link">
              <span>
                {{ service.button.label }}
              </span>
              <i class="fas fa-arrow-right pl-2"></i>
            </div>
          </div>
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HomeService",
  props: ["subheading", "heading", "services"],
  data() {
    return {
      hoveredServiceId: null,
    }
  }
}
</script>

<style lang="scss" scoped>
.services {
  .heading-container {
    text-align: center;
    margin-bottom: 3rem!important;
  }

  .service-tiles {
    display: flex;

    .service-tile-column {
      margin-bottom: 2rem;

      .service-tile {
        height: 100%;
        max-width: 300px;
        align-items: stretch;
        padding: 1.5rem;
        background-color: $mizogoo-gray-light;
        border-radius: 20px;
        border: 2px solid transparent;
        transition: border-color 0.2s ease-in-out;

        position: relative;
        background-size: cover;
        background-position: center;

        img {
          height: 200px;
          padding: 2rem;
          margin-bottom: 1rem;
        }

        .service-text {
          margin-bottom: 1rem;
          color: $mizogoo-gray-dark;
          font-size: 1.3rem;
          font-weight: bold;
          line-height: 1.3;
        }

        &:hover {
          border-color: $mizogoo;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .service-tile-column {
    display: flex;
    justify-content: center;
  }
}
</style>
