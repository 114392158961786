<template>
  <div>
    <b-container fluid class="top-banner">
      <b-container fluid class="top-banner-text">
        <b-container>
          <b-row class="mb-4">
            <b-col md="12" class="top">
              <div class="main-heading-container">
                <div v-for="(headingPart, index) in topBanner.heading" :key="index" class="heading-inner-container">
                  <h1 v-scrollanimation="{ name: 'fade-up-appear' }">
                    {{ headingPart }}
                  </h1>
                </div>
              </div>
              <p v-if="templateOptions.length >0"
                 v-scrollanimation="{ name: 'fade-up', delay: 250 }">
                {{ topBanner.description }}
                <i class="far fa-question-circle" id="template-popover"></i>
                <b-popover target="template-popover" triggers="hover" placement="lefttop">
                  <div class="indicative-ex-1">
                    {{ $t('front.template.page.topBanner.popover') }}
                  </div>
                </b-popover>
              </p>
              <b-row v-if="!isLoading" class="template-options" v-scrollanimation="{ name: 'fade-up', delay: 500 }">
                <template v-for="templateOption in templateOptions" :key="templateOption.id">
                  <div
                      v-if="templateOption.count > 0"
                      v-on:click="setTemplateOption(templateOption.id)"
                      class="template-button"
                      :class="currentTemplateOption === templateOption.id ? 'selected-option' : ''"
                  >
                    {{ templateOption.name }}
                  </div>
                </template>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </b-container>
    </b-container>

    <div id="banner-anchor"></div>

    <b-container fluid class="template-container">
      <b-row v-if="hasError">
        <b-col>
          <FlashBag v-if="hasErrorMatrixCategories" :message="errorMatrixCategories"/>
          <FlashBag v-if="hasErrorModelCategories" :message="errorModelCategories"/>
        </b-col>
      </b-row>

      <Loading v-if="isLoading"/>

      <template v-else>
        <b-modal
            centered
            hide-header
            hide-footer
            size="xl"
            @hidden="displayTemplateModal = false"
            :visible="displayTemplateModal"
            id="template-modal"
        >
          <b-row>
            <b-col md="7" class="mb-md-0 mb-sm-4">
              <ProductTemplatePreview
                  :matrix="currentMatrix"
                  :isVoidMatrix="isVoidMatrix"
                  :model="currentModel"
                  :isVoidModel="isVoidModel"
                  :productFeatureValueFormat="productFeatureValueFormat"
              />
            </b-col>
            <b-col md="5">
              <div class="d-flex flex-column h-100">
                <div class="mb-md-0 mb-sm-4">
                  <template v-if="currentMatrix">
                    <h4 class="mb-4 font-weight-bold">{{ currentMatrix.name }}</h4>
                  </template>
                  <template v-else-if="currentModel">
                    <h4 class="mb-4 font-weight-bold">{{ currentModel.name }}</h4>
                  </template>
                  {{ savedProductSettings.productName }} - {{ productFeatureValueFormat.value }}
                </div>

                <div class="template-configuration-buttons-container mt-auto">
                  <div
                      class="template-personalize-button"
                      :class="!isLoadingCreatingCreation ? null : 'disabled-template-button'"
                      @click.prevent.stop="!isLoadingCreatingCreation ? onClickPersonnalized() : null"
                  >
                    <div>
                      <div class="template-button-heading">
                        {{ $t('front.template.page.peronnalizeProduct') }}
                      </div>
                      <div class="template-button-description">
                        {{ $t('front.template.page.accessBuilder') }}
                      </div>
                    </div>
                    <i class="fas fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
          <div class="absolute-close-button" @click="$bvModal.hide('template-modal')">
            <i class="fas fa-times"></i>
          </div>
        </b-modal>

        <b-row>
          <b-col md="4" class="pl-0 mb-4">
            <div class="product-information-sticky-container sticky-top">
              <div class="product-information-container">
                <h5 class="mb-4">{{ $t('front.template.page.productSelected') }}</h5>
                <div class="product-information-inner-container">
                  <b-img :src="savedProductSettings.productThumbnail" class="mb-2 img-fluid"></b-img>
                  <ProductTemplateResume
                      :productId="savedProductSettings.productId"
                      :productName="savedProductSettings.productName"
                      :productFeatureValues="savedProductSettings.productFeatureValues"
                      :productAttributeValues="savedProductSettings.productAttributeValues"
                      :productQuantity="savedProductSettings.productQuantity"
                      :productPrice="savedProductSettings.productPrice"
                      :productIsPrintable="savedProductSettings.productIsPrintable"
                      :productIsDeliverable="savedProductSettings.productIsDeliverable"
                  />

                  <a class="update-feature-button" @click="backToSteps()">
                    <span>{{ $t('front.template.page.update-feature') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </b-col>
          <b-col md="8" ref="list-container">
            <!-- Empty -->
            <template v-if="!this.hasMatrixCategories && !this.hasModelCategories">
              <b-col class="mb-4" sm="12" lg="3">
                <div class="card card-template" @click.prevent.stop="onClickMatrix(null)">
                  <div class="card-template-overlay"/>
                  <i class="fa fa-plus"></i>
                  {{ $t('workspace.creation.matrix.empty') }}
                  <div class="card-template-unlocked"></div>
                </div>
              </b-col>
            </template>
            <!-- Empty -->

            <!-- Matrix -->
            <template v-if="currentTemplateOption === 'MATRIX' && this.hasMatrixCategories">
              <div class="template-action-toolbar-sticky-container">
                <!-- Action toolbar -->
                <b-row class="mb-4" align-h="between">
                  <b-col cols="*" lg="8" class="mt-1 mb-1">
                    <b-input-group class="icon-inside">
                      <b-form-input
                          type="text"
                          id="search-model"
                          v-model="searchMatrix"
                          :placeholder="$t('workspace.global.search')"
                          class="inputWithIcon"
                          @input="backToPageOne"
                      >
                      </b-form-input>
                      <span class="input-icon">
                        <i class="fas fa-search"></i>
                      </span>
                    </b-input-group>
                  </b-col>
                  <b-col cols="12" lg="4" class="mt-1 mb-1">
                    <div class="custom-form-select-container">
                      <b-form-select
                          v-model="currentMatrixCategory"
                          :options="matrixCategoryOptions"
                          value-field="id"
                          text-field="name"
                          @change="backToPageOne"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>
              </div>

              <!-- Matrix list -->
              <b-row>
                <div
                    v-if="isLoadingMatrixCategories"
                    class="position-relative d-flex justify-content-center w-100 mt-5"
                >
                  <div class="loading">
                    <div class="text-center">
                      <b-spinner/>
                    </div>
                  </div>
                </div>

                <template v-else>
                  <template v-if="paginatedFilteredMatrices.length === 0">
                    <b-col md="12">
                      <div class="empty-container">
                        <h2>{{ $t('front.template.page.emptyMessageMatrix') }}</h2>
                        <div class="row align-items-center">
                          <div class="m-auto">
                            <button @click.prevent.stop="onClickMatrix(null)" class="btn btn-link">
                              {{ $t('front.template.page.empptyMessageMatrixButton') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </template>
                  <template v-else>
                    <b-col class="mb-4" sm="12" lg="3">
                      <div class="card card-template" @click.prevent.stop="onClickMatrix(null)">
                        <div class="card-template-overlay"/>
                        <i class="fa fa-plus"></i>
                        {{ $t('workspace.creation.matrix.empty') }}
                        <div class="card-template-unlocked"></div>
                      </div>
                    </b-col>

                    <b-col v-for="matrix in paginatedFilteredMatrices" :key="'matrix-' + matrix.id" class="mb-4" sm="12" lg="3">
                      <ProductTemplateMatrixCard
                          :matrix="matrix"
                          :currentMatrix="currentMatrix"
                          :functionality="functionality"
                          @displayPremiumOnlyModal="displayPremiumOnlyModal"
                          @onClickMatrix="onClickMatrix"
                      />
                    </b-col>
                    <b-col v-if="totalPageMatrix > 1" md="12" class="mt-5">
                      <Pagination
                          :totalPage="totalPageMatrix"
                          :perPage="perPageMatrix"
                          :currentPage="currentPageMatrix"
                          @pagechanged="onPageChangeMatrix"
                      />
                    </b-col>
                  </template>
                </template>
              </b-row>
            </template>
            <!-- Model -->
            <template v-if="currentTemplateOption === 'MODEL' && this.hasModelCategories">
              <div class="template-inner-container">
                <!-- Action toolbar -->
                <b-row class="mb-4" align-h="between">
                  <b-col cols="*" lg="8" class="mt-1 mb-1">
                    <b-input-group class="icon-inside">
                      <b-form-input
                          type="text"
                          id="search-model"
                          v-model="searchModel"
                          :placeholder="$t('workspace.global.search')"
                          class="inputWithIcon"
                          @input="backToPageOne"
                      >
                      </b-form-input>
                      <span class="input-icon">
                        <i class="fas fa-search"></i>
                      </span>
                    </b-input-group>
                  </b-col>
                  <b-col cols="12" lg="4" class="mt-1 mb-1">
                    <div class="custom-form-select-container">
                      <b-form-select
                          v-model="currentModelCategory"
                          :options="modelCategoryOptions"
                          value-field="id"
                          text-field="name"
                          @change="backToPageOne"
                      ></b-form-select>
                    </div>
                  </b-col>
                </b-row>

                <!-- Model list -->
                <b-row>
                  <div
                      v-if="isLoadingModelCategories"
                      class="position-relative d-flex justify-content-center w-100 mt-5"
                  >
                    <div class="loading">
                      <div class="text-center">
                        <b-spinner/>
                      </div>
                    </div>
                  </div>

                  <template v-else>
                    <template v-if="paginatedFilteredModels.length === 0">
                      <b-col md="12">
                        <div class="empty-container">
                          <h2>{{ $t('front.template.page.emptyMessageModel') }}</h2>
                          <div class="row align-items-center">
                            <div class="m-auto">
                              <button @click.prevent.stop="onClickModel(null)" class="btn btn-link">
                                {{ $t('front.template.page.empptyMessageMatrixButton') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </b-col>
                    </template>
                    <template v-else>
                      <b-col class="mb-4" sm="12" lg="3">
                        <div class="card card-template" @click.prevent.stop="onClickModel(null)">
                          <div class="card-template-overlay"/>
                          <i class="fa fa-plus"></i>
                          {{ $t('workspace.creation.model.empty') }}
                          <div class="card-template-unlocked"></div>
                        </div>
                      </b-col>

                      <b-col v-for="model in paginatedFilteredModels" :key="'model-' + model.id" class="mb-4" sm="12" lg="3">
                        <ProductTemplateModelCard
                            v-if="model.type === 'model'"
                            :model="model"
                            :currentModel="currentModel"
                            :functionality="functionality"
                            @displayPremiumOnlyModal="displayPremiumOnlyModal"
                            @onClickModel="onClickModel"
                        />
                        <ProductTemplateMatrixCard
                            v-if="model.type === 'matrix'"
                            :matrix="model"
                            :currentMatrix="currentMatrix"
                            :functionality="functionality"
                            @displayPremiumOnlyModal="displayPremiumOnlyModal"
                            @onClickMatrix="onClickMatrix"
                        />
                      </b-col>

                      <b-col v-if="totalPageModel > 1" md="12" class="mt-5">
                        <Pagination
                            :totalPage="totalPageModel"
                            :perPage="perPageModel"
                            :currentPage="currentPageModel"
                            @pagechanged="onPageChangeModel"
                        />
                      </b-col>
                    </template>
                  </template>
                </b-row>
              </div>
            </template>
          </b-col>
        </b-row>
      </template>
    </b-container>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import MetaMixin from '@mizogoo/mixins/meta.js'
import TopBanner from '@mizogoo/components/front/layout/TopBanner'
import FlashBag from '@mizogoo/components/FlashBag'
import Loading from '@mizogoo/components/workspace/layout/Loading'
import ProductTemplateResume from '@mizogoo/components/front/pages/ProductTemplatePage/ProductTemplateResume'
import Pagination from '@mizogoo/components/global/pagination/Pagination'
import ProductTemplatePreview from '@mizogoo/components/front/pages/ProductTemplatePage/ProductTemplatePreview'
import ProductTemplateModelCard from '@mizogoo/components/front/pages/ProductTemplatePage/ProductTemplateModelCard'
import ProductTemplateMatrixCard from '@mizogoo/components/front/pages/ProductTemplatePage/ProductTemplateMatrixCard'
import EmptyMessage from '@mizogoo/components/workspace/layout/EmptyMessage'

export default {
  mixins: [MetaMixin],
  name: 'ProductPageTemplate',
  components: {
    TopBanner,
    FlashBag,
    Loading,
    ProductTemplateResume,
    Pagination,
    ProductTemplatePreview,
    ProductTemplateModelCard,
    ProductTemplateMatrixCard,
    EmptyMessage,
  },
  data () {
    return {
      functionality: 'BUILDER_DESIGNS',
      templateOptions: [],
      currentTemplateOption: null,
      isLoadingSavedProductSettings: true,
      savedProductSettings: null,
      isLoadingAction: false, // loading before redirect to cart
      // matrix
      currentMatrixCategory: null,
      currentMatrix: null,
      searchMatrix: '',
      isVoidMatrix: false,
      // model
      currentModelCategory: null,
      currentModel: null,
      searchModel: '',
      isVoidModel: false,
      currentPageMatrix: 1,
      perPageMatrix: 47,
      currentPageModel: 1,
      perPageModel: 47,
      // modal
      displayTemplateModal: false,
    }
  },
  computed: {
    ...mapState({
      // Matrix
      isLoadingMatrixCategories: (state) => state.product.isLoadingMatrixCategories,
      errorMatrixCategories: (state) => state.product.errorMatrixCategories,
      matrixCategories: (state) => state.product.matrixCategories,
      isLoadingMatrices: (state) => state.product.isLoadingMatrices,
      errorMatrices: (state) => state.product.errorMatrices,
      matrices: (state) => state.product.matrices,
      // Model
      isLoadingModelCategories: (state) => state.product.isLoadingModelCategories,
      errorModelCategories: (state) => state.product.errorModelCategories,
      modelCategories: (state) => state.product.modelCategories,
      isLoadingModels: (state) => state.product.isLoadingModels,
      errorModels: (state) => state.product.errorModels,
      models: (state) => state.product.models,
    }),
    ...mapGetters('product', {
      hasErrorMatrixCategories: 'hasErrorMatrixCategories',
      hasMatrixCategories: 'hasMatrixCategories',
      hasErrorModelCategories: 'hasErrorModelCategories',
      hasModelCategories: 'hasModelCategories',
    }),
    // Global
    isLoading () {
      return (
          this.isLoadingSavedProductSettings ||
          this.isLoadingMatrixCategories ||
          this.isLoadingMatrices ||
          this.isLoadingModelCategories
      )
    },
    hasError () {
      return (
          this.hasErrorMatrixCategories ||
          this.hasErrorMatrices ||
          this.hasErrorModelCategories
      )
    },
    metaTitle () {
      return this.$t(`front.metadata.productTemplate.title`, {
        product:
          this.savedProductSettings.productName.charAt(0).toLowerCase() +
          this.savedProductSettings.productName.slice(1),
      });
    },
    metaDescription () {
      return this.$t(`front.metadata.productTemplate.description`)
    },
    topBanner () {
      return {
        heading: this.$tm('front.template.page.topBanner.heading'),
        description: this.$t('front.template.page.topBanner.description'),
      }
    },
    matrixCategoryOptions () {
      let matrixCategoryOptions = []

      matrixCategoryOptions.push({
        id: null,
        name: this.$t('front.template.page.allCategories'),
        reference: 'all',
      })

      for (let matrixCategory of this.matrixCategories) {
        matrixCategoryOptions.push(matrixCategory)
      }

      return matrixCategoryOptions
    },
    // Matrix
    filteredMatrices () {
      return this.matrices
          .filter((element) => {
            return (
                this.searchMatrix === '' ||
                this.slugify(element.name).match(
                    this.searchRegex(this.searchMatrix)
                )
            )
          })
          .filter((element) => {
            return (
                this.currentMatrixCategory === null ||
                element.category === this.currentMatrixCategory
            )
          })
    },
    paginatedFilteredMatrices () {
      return this.filteredMatrices.slice(
          (this.currentPageMatrix - 1) * this.perPageMatrix,
          this.perPageMatrix * this.currentPageMatrix
      )
    },
    totalPageMatrix () {
      return Math.ceil(this.filteredMatrices.length / this.perPageMatrix)
    },
    // Model
    modelCategoryOptions () {
      let modelCategoryOptions = []

      modelCategoryOptions.push({
        id: null,
        name: this.$t('front.template.page.allCategories'),
        position: 0,
        reference: 'all',
      })

      for (let modelCategory of this.modelCategories) {
        modelCategoryOptions.push(modelCategory)
      }

      return modelCategoryOptions
    },
    currentProductMenuMaker () {
      if (!this.productFeatureValueFormat) return null

      return this.savedProductSettings.productMenuMakers.find((productMenuMaker) =>
          productMenuMaker.productFeatureValues.some(
              (productFeatureValue) =>
                  productFeatureValue.id === this.productFeatureValueFormat.id
          )
      )
    },
    filteredModels () {
      if (!this.currentProductMenuMaker) return []

      const result = []
      for (const model of this.models) {
        // Search model check
        if (
            this.searchModel &&
            !this.slugify(model.name).match(this.searchRegex(this.searchModel))
        ) {
          continue
        }

        // Model category check
        if (
            this.currentModelCategory !== null &&
            model.category !== this.currentModelCategory
        ) {
          continue
        }

        // modelProductMenuMakers filtering
        if (model.type === 'model') {
          const filteredProductMenuMakers = model.modelProductMenuMakers.filter(
              (modelProductMenuMaker) =>
                  modelProductMenuMaker.productMenuMakerReference ===
                  this.currentProductMenuMaker.reference
          )

          if (filteredProductMenuMakers.length > 0) {
            result.push({
              ...model,
              modelProductMenuMakers: filteredProductMenuMakers
            })
          }
        } else if (model.type === 'matrix') {
          result.push(model)
        }
      }
      return result
    },
    paginatedFilteredModels () {
      return this.filteredModels.slice(
          (this.currentPageModel - 1) * this.perPageModel,
          this.perPageModel * this.currentPageModel
      )
    },
    totalPageModel () {
      return Math.ceil(this.filteredModels.length / this.perPageModel)
    },
    // Feature format
    productFeatureValueFormat () {
      let productFeatureValueFormat = null

      for (const currentFeatureValue of this.savedProductSettings.productFeatureValues) {
        if (currentFeatureValue.feature.constName === 'FORMAT' || currentFeatureValue.feature.printComConstName === 'size') {
          productFeatureValueFormat = currentFeatureValue
        }
      }
      return productFeatureValueFormat
    },
  },
  methods: {
    ...mapMutations('premiumOnlyModal', {
      setPremiumOnlyModalElements: 'SET_PREMIUM_ONLY_MODAL_ELEMENTS',
    }),
    displayPremiumOnlyModal () {
      let elements = {
        functionality: this.functionality,
        heading: this.$tm('workspace.premiumOnlyModal.builderDesigns.heading'),
        description: this.$tm('workspace.premiumOnlyModal.builderDesigns.description').join(''),
        image: {
          src: require('@mizogoo/assets/img/premium_modal/templates.png'),
          alt: this.$t('workspace.premiumOnlyModal.builderDesigns.imageAlt'),
        },
      }
      this.setPremiumOnlyModalElements(elements)
      this.$bvModal.show('premium-only-modal')
    },
    initializeTemplateOptions() {
      let templateOptions = [];

      if (this.hasMatrixCategories) {
        templateOptions.push({
          id: 'MATRIX',
          name: this.$t('front.template.page.topBanner.templateOption.matrix'),
          count: this.matrices.length,
        });
      }

      if (this.hasModelCategories) {
        templateOptions.push({
          id: 'MODEL',
          name: this.$t('front.template.page.topBanner.templateOption.model'),
          count: this.models.length,
        });
      }

      if (this.currentTemplateOption === null) {
        if (templateOptions[0] && templateOptions[0].count > 0) {
          this.currentTemplateOption = templateOptions[0]['id'];
        } else if (templateOptions[1] && templateOptions[1].count > 0) {
          this.currentTemplateOption = templateOptions[1]['id'];
        }
      }

      this.templateOptions = templateOptions;
    },
    setTemplateOption (option) {
      this.currentTemplateOption = option
    },
    // Return
    backToSteps () {
      this.$router.push({ name: 'product', params: { id: this.savedProductSettings.productId } })
    },
    backToPageOne () {
      this.currentPageMatrix = 1
      this.currentPageModel = 1
    },
    onClickMatrix (matrix) {
      if (matrix === null) {
        this.isVoidMatrix = true
      }

      this.currentMatrix = matrix
      this.currentModel = null
      this.isVoidModel = false
      this.displayTemplateModal = true
    },
    onClickModel (model) {
      if (model === null) {
        this.isVoidModel = true
      }

      this.currentModel = model
      this.currentMatrix = null
      this.isVoidMatrix = false
      this.displayTemplateModal = true
    },
    // pagination
    onPageChangeMatrix (page) {
      this.currentPageMatrix = page

      this.$nextTick(() => {
        this.scrollBackToTopOfTheList()
      })
    },
    onPageChangeModel (page) {
      this.currentPageModel = page

      this.$nextTick(() => {
        this.scrollBackToTopOfTheList()
      })
    },
    scrollBackToTopOfTheList () {
      const listElement = this.$refs['list-container']
      const offset = 100
      const elementTop = listElement.getBoundingClientRect().top + window.scrollY

      window.scrollTo({
        top: elementTop - offset,
        behavior: 'smooth',
      })
    },
    // Personnalized
    async onClickPersonnalized () {
      // Update the savedProductSettings in the session storage
      let savedProductSettings = JSON.parse(localStorage.getItem('savedProductSettings')) || {}
      savedProductSettings = {
        ...savedProductSettings,
        matrixId: this.currentMatrix ? this.currentMatrix.id : null,
        modelId: this.currentModel ? this.currentModel.id : null,
      }
      localStorage.setItem('savedProductSettings', JSON.stringify(savedProductSettings))

      // Navigates to the builder (the creation will be created just before accessing the page, inside the router)
      this.$router.push({ name: 'redirect_menubuilder' })
    },
  },
  async created () {
    let savedProductSettings = localStorage.getItem('savedProductSettings')

    if (savedProductSettings) {
      savedProductSettings = JSON.parse(savedProductSettings)

      // Check if it is the same product before actually loading the data
      if (savedProductSettings.productId === parseInt(this.$route.params.productId)) {
        this.savedProductSettings = savedProductSettings
      } else {
        this.$router.push({ name: 'catalog' })
        return
      }
    } else {
      this.$router.push({ name: 'catalog' })
      return
    }

    const currentFeatureValueIds = savedProductSettings.productFeatureValues.map(item => {
      return item.id
    })

    await Promise.all([
      this.$store.dispatch('product/findMatrixCategoriesWithoutMatrices', {
        data: {
          currentFeatureValueIds: currentFeatureValueIds,
        },
      }),
      this.$store.dispatch('product/findMatrices', {
        data: {
          currentFeatureValueIds: currentFeatureValueIds,
        },
      }),
      this.$store.dispatch('product/findModelCategoriesWithoutModels', {
        productId: savedProductSettings.productId,
      }),
      this.$store.dispatch('product/findModels', {
        productId: savedProductSettings.productId,
        data: {
          currentFeatureValueIds: currentFeatureValueIds,
        },
      }),
    ])

    this.isLoadingSavedProductSettings = false;
    this.initializeTemplateOptions();
  },
}
</script>

<style lang="scss" scoped>
.top-banner {
  color: #fff;
  text-align: center;
  padding: 0 0 5rem;
}

.top-banner-text {
  padding-top: 9rem; /* Transparent header */
  background-image: url("../../../../assets/img/front/global/decorations/dotted_background.svg"),
  linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.top-banner-text > .container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.top-banner .top p {
  max-width: 920px;
  margin: auto;
}

.top-banner .buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  div {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .btn-plain-white {
      background-color: #fff;
      color: $mizogoo !important;
    }
  }
}

.template-options {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .template-button {
    margin: 0.4rem 0.2rem;
    padding: 0.6rem 1.4rem;
    background-color: $white;
    color: $mizogoo-gray-dark;
    outline: 2px solid transparent;
    -moz-outline-radius: 2px;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome, Opera, Firefox */

    &:hover {
      color: $mizogoo;
    }
  }

  .selected-option {
    color: $mizogoo;
    outline: 2px solid $mizogoo;
    outline-offset: -5px;
  }
}

.template-container {
  min-height: 50vh;
  padding-bottom: 15rem;

  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
  }

  // .template-action-toolbar-sticky-container {
  // padding-top: 150px;
  //margin-top: -150px;
  //padding-right: 2rem;
  //padding-bottom: 1rem;
  //z-index: initial !important;
  // }

  .product-information-sticky-container {
    padding-top: 150px;
    margin-top: -150px;
    padding-right: 2rem;
    padding-bottom: 1rem;
    z-index: initial !important;

    .product-information-container {
      background-color: $mizogoo-gray-light;
      border-radius: 0 0.5rem 0.5rem 0;
      padding: 1.5rem;

      .product-information-inner-container {
        border: 1px solid #dfdddd;
        padding: 1rem;
        border-radius: 0.5rem;
      }

      .update-feature-button {
        color: #ff395e;
      }
    }
  }

  .card-template {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $mizogoo-gray-dark !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-position: center center;
    min-height: 300px;
    color: $white;
    border-radius: 0.5rem;

    .card-template-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .card-template-overlay {
      opacity: 0.2;
    }

    &.card-template-active {
      outline: 2px solid $mizogoo;
    }

    .card-template-unlocked {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $mizogoo-gray-light;
      opacity: 0.1;
      border-radius: 0.5rem;
    }

    .card-template-locked {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(11, 11, 11, 0.5);
      opacity: 0.5;
      border-radius: 0.5rem;
    }

    .card-template-information {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      border: 2px solid $mizogoo;
    }
  }
}

.template-configuration-buttons-container {
  .template-personalize-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.5rem;
    color: #fff;
    background-color: $mizogoo;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out;

    &:hover:not(.disabled-product-button) {
      background-color: color.adjust($mizogoo, $lightness: -5%);
    }

    .template-button-heading {
      font-weight: 500;
    }

    .fas {
      font-size: 1.5rem;
    }
  }

  .disabled-template-button {
    opacity: 0.8;
    cursor: initial;
  }
}

.empty-container {
  background-color: $mizogoo-gray-light;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  text-align: center;

  h2,
  p {
    max-width: 600px;
  }

  p {
    margin-bottom: 2rem;
  }
}
</style>

<style lang="scss">
#template-modal {
  .modal-content {
    border: none;
    position: relative;
    border-radius: 25px;
  }

  .modal-body {
    padding: 2rem;
  }

  .absolute-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    cursor: pointer;
    z-index: 5;
  }
}
</style>
