import DoItForMeAPI from "../api/do_it_for_me";

const FETCH_DOCUMENTS = "FETCH_DOCUMENTS",
  FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS",
  FETCH_DOCUMENTS_ERROR = "FETCH_DOCUMENTS_ERROR",

  RELATED_DOCUMENT_CREATE = "RELATED_DOCUMENT_CREATE",
  RELATED_DOCUMENT_CREATE_SUCCESS = "RELATED_DOCUMENT_CREATE_SUCCESS",
  RELATED_DOCUMENT_CREATE_ERROR = "RELATED_DOCUMENT_CREATE_ERROR",
  
  MOVE_DOCUMENTS = "MOVE_DOCUMENTS",
  MOVE_DOCUMENTS_SUCCESS = "MOVE_DOCUMENTS_SUCCESS",
  MOVE_DOCUMENTS_ERROR = "MOVE_DOCUMENTS_ERROR",
  
  SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export default {
  namespaced: true,
  state: {
    // Fetch documents
    isLoadingFetchDocuments: false,
    errorFetchDocuments: null,
    // Related Document Create
    isLoadingRelatedDocumentCreate: false,
    errorRelatedDocumentCreate: null,
    uploadProgress: 0,
    // Move documents
    isLoadingMoveDocuments: false,
    errorMoveDocuments: null,
  },
  mutations: {
    // Fetch documents
    [FETCH_DOCUMENTS](state) {
      state.isLoadingFetchDocuments = true;
      state.errorFetchDocuments = null;
    },
    [FETCH_DOCUMENTS_SUCCESS](state) {
      state.isLoadingFetchDocuments = false;
      state.errorFetchDocuments = null;
    },
    [FETCH_DOCUMENTS_ERROR](state, error) {
      state.isLoadingFetchDocuments = false;
      state.errorFetchDocuments = error;
    },
    // Related Document Create
    [RELATED_DOCUMENT_CREATE](state) {
      state.isLoadingRelatedDocumentCreate = true;
      state.errorRelatedDocumentCreate = null;
    },
    [RELATED_DOCUMENT_CREATE_SUCCESS](state) {
      state.isLoadingRelatedDocumentCreate = false;
      state.errorRelatedDocumentCreate = null;
    },
    [RELATED_DOCUMENT_CREATE_ERROR](state, error) {
      state.isLoadingRelatedDocumentCreate = false;
      state.errorRelatedDocumentCreate = error;
    },
    [SET_UPLOAD_PROGRESS](state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },
    // Move documents
    [MOVE_DOCUMENTS](state) {
      state.isLoadingMoveDocuments = true;
      state.errorMoveDocuments = null;
    },
    [MOVE_DOCUMENTS_SUCCESS](state) {
      state.isLoadingMoveDocuments = false;
      state.errorMoveDocuments = null;
    },
    [MOVE_DOCUMENTS_ERROR](state, error) {
      state.isLoadingMoveDocuments = false;
      state.errorMoveDocuments = error;
    },
  },
  actions: {
    async findDocuments({ commit }, payload) {
      commit(FETCH_DOCUMENTS);
      try {
        let response = await DoItForMeAPI.findDocuments(payload);
        if (response.data.success === true) {
          commit(FETCH_DOCUMENTS_SUCCESS, response.data);
          return response.data;
        } else {
          commit(FETCH_DOCUMENTS_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_DOCUMENTS_ERROR);
        return null;
      }
    },
    async relatedDocumentCreate({ commit }, payload) {
      commit(RELATED_DOCUMENT_CREATE);
      try {
        let response = await DoItForMeAPI.relatedDocumentCreate({
          store: this,
          tmpUserId: payload.tmpUserId,
          createdAt: payload.createdAt,
          file: payload.file,
        });

        if (response.data.success === true) {
          commit(RELATED_DOCUMENT_CREATE_SUCCESS, response.data);
          return response.data;
        } else {
          commit(RELATED_DOCUMENT_CREATE_ERROR);
          return null;
        }
      } catch (error) {
        commit(RELATED_DOCUMENT_CREATE_ERROR);
        return null;
      }
    },
    setUploadProgress({ commit }, uploadProgress) {
      commit(SET_UPLOAD_PROGRESS, uploadProgress);
    },
    async moveDocuments({ commit }, payload) {
      commit(MOVE_DOCUMENTS);
      try {
        let response = await DoItForMeAPI.moveDocuments(payload);
        if (response.data.success === true) {
          commit(MOVE_DOCUMENTS_SUCCESS, response.data);
          return response.data;
        } else {
          commit(MOVE_DOCUMENTS_ERROR);
          return null;
        }
      } catch (error) {
        commit(MOVE_DOCUMENTS_ERROR);
        return null;
      }
    },
  },
};
