<template>
  <b-container class="key-numbers">
    <b-row class="numbers">
      <b-col
        v-for="(number, index) in keyNumbers"
        :key="index"
        cols="12"
        sm="3"
      >
        <div class="inner-container">
          <p class="number">{{ number.number }}</p>
          <p class="caption">{{ number.caption }}</p>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "KeyNumbers",
  computed: {
    keyNumbers() {
      return [
        {
          number: this.$t("front.keyNumbers[0].number"),
          caption: this.$t("front.keyNumbers[0].caption"),
        },
        {
          number: this.$t("front.keyNumbers[1].number"),
          caption: this.$t("front.keyNumbers[1].caption"),
        },
        {
          number: this.$t("front.keyNumbers[2].number"),
          caption: this.$t("front.keyNumbers[2].caption"),
        },
        {
          number: this.$t("front.keyNumbers[3].number"),
          caption: this.$t("front.keyNumbers[3].caption"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.key-numbers {
  .numbers {
    margin-top: 2rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    
      .inner-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        padding-top: 1rem;
        padding-bottom: 1rem;

        .number {
          font-size: 2rem !important;
          font-weight: 600 !important;
        }

        .caption {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .inner-container {
    align-items: center!important;
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important;
    text-align: center;
  }
}
</style>
