import AccountInstitutionAPI from "../api/account_insitution";

const
  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  UPDATING_INSTITUTION_LOCALE_FRONT = "UPDATING_INSTITUTION_LOCALE_FRONT",
  UPDATING_INSTITUTION_LOCALE_FRONT_SUCCESS = "UPDATING_INSTITUTION_LOCALE_FRONT_SUCCESS",
  UPDATING_INSTITUTION_LOCALE_FRONT_ERROR = "UPDATING_INSTITUTION_LOCALE_FRONT_ERROR",

  DELETE = "DELETE",
  DELETE_SUCCESS = "DELETE_SUCCESS",
  DELETE_ERROR = "DELETE_ERROR",

  DEFAULT = "DEFAULT",
  DEFAULT_SUCCESS = "DEFAULT_SUCCESS",
  DEFAULT_ERROR = "DEFAULT_ERROR",

  TOGGLE_PDF_MENU = "TOGGLE_PDF_MENU",
  TOGGLE_PDF_MENU_SUCCESS = "TOGGLE_PDF_MENU_SUCCESS",
  TOGGLE_PDF_MENU_ERROR = "TOGGLE_PDF_MENU_ERROR",
  
  TOGGLE_CHANGING_INSTITUTION_LOADING = "TOGGLE_CHANGING_INSTITUTION_LOADING";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isLoadingAction: false,
    isLoadingChangingInstitution: false,
    error: null,
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isLoadingAction(state) {
      return state.isLoadingAction;
    },
    isLoadingChangingInstitution(state) {
      return state.isLoadingChangingInstitution;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    }
  },
  mutations: {
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoading = false;
      state.error = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
    },
    // Creating
    [CREATING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [CREATING_SUCCESS](state, institution) {
      state.isLoadingAction = false;
      state.error = null;

      let institutions = this.getters['security/user'].institutions
      institutions.push(institution)
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Updating
    [UPDATING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [UPDATING_SUCCESS](state, institution) {
      state.isLoadingAction = false;
      state.error = null;

      let institutionToUpdate = this.getters['security/user'].institutions.find(element => element.id === institution.id)

      Object.keys(institutionToUpdate).forEach(function (key) {
        institutionToUpdate[key] = institution[key];
      });
    },
    [UPDATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Updating institution locale front
    [UPDATING_INSTITUTION_LOCALE_FRONT](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [UPDATING_INSTITUTION_LOCALE_FRONT_SUCCESS](state, institution) {
      state.isLoadingAction = false;
      state.error = null;

      let institutionToUpdate = this.getters['security/user'].institutions.find(element => element.id === institution.id)

      Object.keys(institutionToUpdate).forEach(function (key) {
        institutionToUpdate[key] = institution[key];
      });
    },
    [UPDATING_INSTITUTION_LOCALE_FRONT_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Delete
    [DELETE](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [DELETE_SUCCESS](state, institution) {
      state.isLoadingAction = false;
      state.error = null;

      let institutions = this.getters['security/user'].institutions.filter(element => element.id !== institution.id)
      this.dispatch('security/fetchUserInstitutions', institutions);
    },
    [DELETE_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Default
    [DEFAULT](state) {
      // state.isLoadingChangingInstitution = true;
      state.error = null;
    },
    [DEFAULT_SUCCESS](state, institution) {
      let formerSelectedInstitution = this.getters['security/user'].institutions.find(element => element.isDefault === true)
      formerSelectedInstitution.isDefault = false

      let newSelectedInstitution = this.getters['security/user'].institutions.find(element => element.id === institution.id)
      newSelectedInstitution.isDefault = true

      // state.isLoadingChangingInstitution = false;
      state.error = null;
    },
    [DEFAULT_ERROR](state, error) {
      // state.isLoadingChangingInstitution = false;
      state.error = error;
    },
    // Toggle Pdf Menu
    [TOGGLE_PDF_MENU](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [TOGGLE_PDF_MENU_SUCCESS](state, institution) {
      state.isLoadingAction = false;
      state.error = null;

      let institutionToUpdate = this.getters['security/user'].institutions.find(element => element.id === institution.id)
      institutionToUpdate["isPdfMenuActive"] = institution["isPdfMenuActive"];
    },
    [TOGGLE_PDF_MENU_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Toggle the loading that will be displayed over the all workspace during
    // the institution changement (including cache reset triggered just before)
    [TOGGLE_CHANGING_INSTITUTION_LOADING](state) {
      state.isLoadingChangingInstitution = !state.isLoadingChangingInstitution;
    },
  },
  actions: {
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountInstitutionAPI.findOne(payload.institution);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.institution;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountInstitutionAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.institution);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountInstitutionAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.institution);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async updateInstitutionLocaleFront({ commit }, payload) {
      commit(UPDATING_INSTITUTION_LOCALE_FRONT);
      try {
        let response = await AccountInstitutionAPI.updateInstitutionLocaleFront(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_INSTITUTION_LOCALE_FRONT_SUCCESS, response.data.institution);
          return response.data;
        } else {
          commit(UPDATING_INSTITUTION_LOCALE_FRONT_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_INSTITUTION_LOCALE_FRONT_ERROR, error);
        return null;
      }
    },
    async delete({ commit }, payload) {
      commit(DELETE);
      try {
        let response = await AccountInstitutionAPI.delete(payload.institution);
        if (response.data.success === true) {
          commit(DELETE_SUCCESS, response.data.institution);
          return null;
        } else {
          commit(DELETE_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(DELETE_ERROR, error);
        return null;
      }
    },
    async default({ commit }, payload) {
      commit(DEFAULT);
      try {
        let response = await AccountInstitutionAPI.default(payload.institution);
        if (response.data.success === true) {
          commit(DEFAULT_SUCCESS, response.data.institution);
          return response.data;
        } else {
          commit(DEFAULT_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(DEFAULT_ERROR, error);
        return null;
      }
    },
    async togglePdfMenu({ commit }, payload) {
      commit(TOGGLE_PDF_MENU);
      try {
        let response = await AccountInstitutionAPI.togglePdfMenu(payload.form);
        if (response.data.success === true) {
          commit(TOGGLE_PDF_MENU_SUCCESS, response.data.institution);
          return response.data;
        } else {
          commit(TOGGLE_PDF_MENU_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLE_PDF_MENU_ERROR, error);
        return null;
      }
    },
    async toggleChangingInstitutionLoading({ commit }) {
      commit(TOGGLE_CHANGING_INSTITUTION_LOADING);
    },
  }
}
