import { defineRule, configure } from "vee-validate";
import { required, email, mimes, ext } from "@vee-validate/rules";

export default function setupVeeValidate(i18n) {
  // Define custom rules
  defineRule("required", required);
  defineRule("email", email);
  defineRule("mimes", mimes);
  defineRule("ext", ext);
  defineRule("max", (value, [length]) => {
    return value.length < length;
  });
  defineRule("minHour", (value, args) => {
    return args.value > args.min;
  });
  defineRule("alphanum", (value) => {
    return /^[a-z0-9]+$/i.test(value);
  });

  // Golbal config
  configure({
    validateOnBlur: false,
    validateOnChange: false,
    validateOnInput: false,
    validateOnModelUpdate: false,
    generateMessage: (context) => {
      return i18n.global.t(`form.global.${context.rule.name}`, {
        field: context.field,
      });
    },
  });
}
