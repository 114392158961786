<template>
  <b-form-group
    :id="id + '-input-group'"
    :label="name"
    :label-for="id"
    :label-class="inputRequired ? 'required' : ''"
  >
    <b-form-rating
      :id="id"
      :name="id"
      v-model="inputValue"
      :class="{ 'is-invalid': errorMessage }"
      :stars="stars"
      :size="size"
      :variant="variant"
      :inline="inline"
    >
    </b-form-rating>
    <b-form-text :id="id + '-help-block'">
      {{ help }}
    </b-form-text>
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { useField } from "vee-validate";
import { BFormRating } from "bootstrap-vue";

export default {
  name: "FormRating",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  components: {
    BFormRating,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    help: {
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    stars: {
      type: Number,
      default() {
        return 5;
      },
    },
    size: {
      type: String,
      default() {
        return "md";
      },
    },
    variant: {
      type: String,
      default() {
        return null;
      },
    },
    modelValue: {
      type: Number,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(
      "formRating-" + props.id,
      props.rules,
      {
        label: props.name,
        syncVModel: true,
        validateOnMount: false,
        validateOnValueUpdate: false,
      }
    );

    return {
      inputValue,
      errorMessage,
    };
  },
  computed: {
    inputRequired() {
      if (typeof this.rules["required"] !== "undefined") {
        if (this.rules["required"] === true) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
output.b-rating {
  &.form-control {
    background-color: transparent !important;
    outline: 1px solid transparent;
  }
}
</style>
