<template>
  <b-container class="secondary-page-container">
    <template v-if="isLoading">
      <b-row class="text-center">
        <b-col cols="12" class="mt-4">
          <b-spinner></b-spinner>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <template v-if="hasError">
        <FlashBag v-if="hasError" :message="error"/>
      </template>

      <template v-else>
        <template v-if="promotion">
          <b-row>
            <b-card
                class="mizogoo-card text-center">
              <b-card-title>
                <h1>{{ promotion.name }}</h1>
              </b-card-title>
              <b-card-text>
                <p class="font-weight-bolder m-0">{{ promotion.code }}</p>
                {{ promotion.description }}
              </b-card-text>
              <b-card-footer>
                <p style="font-size: 0.7rem !important;" v-html="promotion.conditionOfUse"></p>
                <router-link :to="{ name: 'promotions' }">
                  <span>{{ $t('front.promotion.promotion.backButton') }}</span>
                </router-link>
              </b-card-footer>
            </b-card>
          </b-row>
        </template>
      </template>
    </template>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import Loading from '@mizogoo/components/workspace/layout/Loading'
import FlashBag from '@mizogoo/components/FlashBag'
import BackButton from '@mizogoo/components/workspace/layout/BackButton'

export default {
  mixins: [MetaMixin],
  name: 'PromotionPagePromotion',
  components: {
    Loading,
    FlashBag,
    BackButton
  },
  data () {
    return {
      promotion: null,
    }
  },
  computed: {
    metaTitle() {
      return this.$t('front.metadata.promotion.promotion.title');
    },
    metaDescription() {
      return this.$t('front.metadata.promotion.promotion.description');
    },
    // Fetch All
    isLoading () {
      return this.$store.getters['promotion/isLoading']
    },
    hasError () {
      return this.$store.getters['promotion/hasError']
    },
    error () {
      return this.$store.getters['promotion/error']
    },
    promotions () {
      return this.$store.getters['promotion/promotions']
    },
    hasPromotion () {
      return this.$store.getters['promotion/hasPromotions']
    },
  },
  async created () {
    this.promotion = await this.$store.dispatch('promotion/findOne', {
      promotion: this.$route.params.id
    })

    if (this.promotion.users.length > 0) {
      const user = this.promotion.users.find(element => element === this.user.id)

      if (typeof user === 'undefined') {
        window.location = '/'
      }
    }

    let documentTitle = this.promotion.name
    document.title = documentTitle += ' | mizogoo'
  },
  mounted () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', false)
  },
  beforeUnmount () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', true)
  },
}
</script>

<style lang="scss" scoped>
</style>
