<template>
  <b-container class="advantages">
    <b-row align-v="center" align-h="center">
      <b-col class="text-col" md="7" sm="12">
        <div class="inner-container">
          <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
          <div class="heading-container">
            <div v-for="(headingPart, index) in heading" :key="index" class="heading-inner-container">
              <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
            </div>
          </div>
          <div v-scrollanimation="{name: 'fade-up', delay: 250}">
            <p>{{ description }}</p>
            <b-row class="advantage-list">
              <b-col v-for="(advantage, index) in advantageList" :key="index" cols="6" sm="4">
                <div class="advantage">
                  <i class="fas fa-check advantage-check-icon"></i>
                  <span class="advantage-text">{{ advantage }}</span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col class="image-col" md="5" sm="12">
        <div class="image-col-inner-container" v-b-modal.mizogoo-presentation-video-modal>
          <div class="play-button-container">
            <div class="play-icon-container">
              <i class="fas fa-play"></i>
            </div>
          </div>
          <div class="image-overlay" />
          <b-img-lazy
            fluid
            center
            :src="image.src"
            :alt="image.alt"
          ></b-img-lazy>
        </div>
        <b-modal
          id="mizogoo-presentation-video-modal"
          content-class="mizogoo-presentation-video-modal"
          body-class="mizogoo-presentation-video-modal"
          size="xl"
          centered
          hide-header
          hide-footer
        >
          <div>
            <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube-nocookie.com/embed/Fu8FTbSI4jo?controls=0&rel=0&autoplay=1"
              class="mizogoo-presentation-video-modal"
              allowfullscreen
            ></b-embed>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HomeAdvantages",
  props: ["subheading", "heading", "description", "advantageList", "image"],
  data() {
    return {
      videoIsVisible: false,
    }
  },
}
</script>

<style lang="scss" scoped>
.advantages {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 8rem;

  .image-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
    border-radius: 20px;
  }
  .image-col {
    padding-top: 2rem;
    padding-bottom: 1rem;
    background-color: #f8f8f8;
    border-radius: 20px;
    z-index: 2;

    .image-col-inner-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $mizogoo-gray-light;
        border-radius: 20px;
        transform: rotate(-15deg);
        z-index: -1;
      }

      &::after {
        content: url(../../../../assets/img/front/global/decorations/dotted_decoration_small.svg);
        position: absolute;
        right: -40px;
        bottom: -58px;
        z-index: -2;
      }
    
      .play-button-container {
        position: absolute;
        padding: 1rem;
        background-color: #fff;
        outline: 2px solid #fff;
        outline-offset: 0.5rem;
        border-radius: 50%;
        z-index: 1;

        .play-icon-container {
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 1.5rem;
            color: $mizogoo-gray-dark;
          }
        }
      }

      img {
        width: 100%;
        border-radius: 20px;
      }
    }
  }

  .image-padding {
    padding-left: 5rem;
  }
  .text-col {
    padding-top: 5rem;
    padding-bottom: 5rem;
    padding-right: 5rem;

    .inner-container {
      position: relative;
      z-index: 2;

      .advantage-list {
        display: flex;
        margin-top: 2.5rem;

        .advantage {
          display: flex;
          align-items: flex-start;
          margin-bottom: 1rem;

          .advantage-check-icon {
            margin-top: 0.3rem;
            margin-right: 0.5rem;
            font-size: 0.6rem;
            color: #fff;
            background-color: $mizogoo;
            padding: 0.3rem;
            border-radius: 50%;
          }

          .advantage-text {
            font-weight: 500;
            line-height: 1.3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .advantages {
    .text-col {
      padding-right: 15px;
    }
  }
}

@media screen and (max-width: 768px) {
  .advantages {
    .text-col {
      padding-bottom: 1rem;
    }
    .image-col {
      overflow: hidden;
      padding-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .advantages {
    margin-bottom: 4rem;
  }
}
</style>

<style lang="scss">
.mizogoo-presentation-video-modal {
  padding: 0;
  border-radius: 20px;
}
</style>
