<template>
  <b-container fluid class="top-banner">
    <!-- Classic TopBanner display (centered with elements below each others) -->
    <!-- Top banner text - text part with heading and subheading -->
    <b-container fluid class="top-banner-text">
      <b-container>
        <b-row class="mb-4">
          <b-col md="12" class="top">
            <div class="main-heading-container">
              <div
                v-for="(headingPart, index) in heading"
                :key="index"
                class="heading-inner-container"
              >
                <h1 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h1>
              </div>
            </div>
            <p v-scrollanimation="{ name: 'fade-up', delay: 250 }">{{ description }}</p>
            <div
              v-if="buttons"
              v-scrollanimation="{ name: 'fade-up', delay: 500 }"
              class="buttons-container"
            >
              <div v-for="(button, index) in buttons" :key="'top-banner-button-' + index">
                <router-link
                  :to="{ name: button.link, hash: button.anchor }"
                  custom
                  v-slot="{ navigate }"
                >
                  <span
                    @click="navigate"
                    role="link"
                    :class="['btn btn-white', { 'btn-plain-white': !button.outline }]"
                    >{{ button.label }}</span
                  >
                </router-link>
              </div>
            </div>
            <slot name="inside-banner-text"></slot>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
    <!-- Top banner below - below part with the image, the video, or even some other blocks -->
    <div :class="[fluid ? 'container-fluid' : 'container', 'top-banner-below']">
      <b-row class="justify-content-center">
        <b-col id="bannerImageCol">
          <slot></slot>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "TopBanner",
  props: ["heading", "description", "buttons", "fluid"],
};
</script>

<style lang="scss" scoped>
.top-banner {
  padding: 0;
  color: #fff;
  text-align: center;
  padding-bottom: 5rem;
}

.top-banner-text {
  padding-top: 9rem; /* Transparent header */
  background-image: url("../../../assets/img/front/global/decorations/dotted_background.svg"),
    linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}
.top-banner-text > .container {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.top-banner .top p {
  margin: 0;
  max-width: 920px;
  margin: auto;
}

.top-banner .buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  div {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .btn-plain-white {
      background-color: #fff;
      color: $mizogoo !important;
    }
  }
}

.top-banner-below {
  :slotted(img) {
    border-radius: 25px;
  }
}

/* Media queries space between text and further blocks below (image, video, etc.) */
@media screen and (min-width: 1200px) {
  .top-banner-text {
    padding-bottom: 18rem;
  }
  .top-banner-below {
    margin-top: -20rem;
  }
}
@media screen and (max-width: 1200px) {
  .top-banner-text {
    padding-bottom: 14rem;
  }
  .top-banner-below {
    margin-top: -16rem;
  }
}
@media screen and (max-width: 992px) {
  .top-banner-text {
    padding-bottom: 10rem;
  }
  .top-banner-below {
    margin-top: -12rem;
  }
}
@media screen and (max-width: 768px) {
  .top-banner-text {
    padding-bottom: 6rem;
  }
  .top-banner-below {
    margin-top: -8rem;
  }
}
@media screen and (max-width: 576px) {
  .buttons-container {
    flex-direction: column;

    div {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }

  .top-banner-text {
    padding-top: 6rem; /* Transparent header */
    padding-bottom: 4rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .top-banner-below {
    margin-top: -6rem;
  }
}
</style>
