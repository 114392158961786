// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.do-it-for-me-container[data-v-58757b86] {
  min-height: 50vh;
  max-width: 800px;
}
.do-it-for-me-container .loading[data-v-58757b86] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/ProductPrintMyFilePage/ProductPrintMyFile.vue","webpack://./ProductPrintMyFile.vue"],"names":[],"mappings":"AAKA;EACE,gBAAA;EACA,gBAAA;ACJF;ADME;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;ACJJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.do-it-for-me-container {\n  min-height: 50vh;\n  max-width: 800px;\n\n  .loading {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    margin: auto;\n  }\n}\n",".do-it-for-me-container {\n  min-height: 50vh;\n  max-width: 800px;\n}\n.do-it-for-me-container .loading {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  margin: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
