<template>
  <b-container class="secondary-page-container">
    <b-row class="justify-content-center">
      <b-col>
        <div class="text-center">
          <h3 class="text-primary">{{ $t("front.registration.subheading") }}</h3>
          <h1 class="pb-2">{{ $t("front.registration.heading") }}</h1>
        </div>

        <div class="inner-container">
          <div class="mb-2">
            <a href="/api/security/google/connect" class="btn social-media-button">
              <i class="fab fa-google mr-1"></i> {{ $t("front.login.googleConnect") }}
            </a>
          </div>

          <div class="mb-2">
            <a href="/api/security/facebook/connect" class="btn social-media-button">
              <i class="fab fa-facebook mr-1"></i> {{ $t("front.login.facebookConnect") }}
            </a>
          </div>

          <p class="text-uppercase text-center mt-4">{{ $t("front.login.or") }}</p>

          <b-row v-if="hasError || hasMessage">
            <b-col class="mb-4 text-center">
              <FlashBag :message="error" v-if="hasError" />
              <FlashBag
                :message="message"
                variant="success"
                v-if="hasMessage"
                id="registration-success-message"
              />
            </b-col>
          </b-row>

          <div class="mb-4">
            <Form as="" ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit="handleSubmit(onSubmit)">
                <FormInput
                  type="email"
                  id="email"
                  :name="$t('front.login.email')"
                  :rules="{ required: true, email: true }"
                  v-model="form.email"
                />

                <FormPassword
                  id="password"
                  :name="$t('front.login.password')"
                  :rules="{ required: true }"
                  :newPassword="true"
                  v-model:password="form.password"
                  :help="$t('front.login.passwordRequirements')"
                  :displayRequirements="true"
                ></FormPassword>

                <FormPassword
                  id="password-confirm"
                  :name="$t('front.login.passwordConfirm')"
                  :rules="{ required: true }"
                  :newPassword="true"
                  v-model:password="form.passwordConfirm"
                  :displayRequirements="false"
                ></FormPassword>

                <div class="d-inline-block mt-2">
                  <b-form-checkbox
                    v-model="form.acceptNewsletter"
                    :value="true"
                    :unchecked-value="false"
                  >
                    {{ $t("front.registration.acceptNewsletter") }}
                  </b-form-checkbox>
                </div>

                <div class="mt-4 text-center">
                  <div v-if="isLoading">
                    {{ $t("global.loading") }}
                  </div>
                  <button type="submit" class="btn btn-outline-primary" v-else>
                    {{ $t("front.registration.register") }}
                  </button>
                </div>
              </b-form>
            </Form>
          </div>

          <!-- Terms and conditions (automatically accepted by registering) -->
          <div class="terms mb-4">
            {{ $t("workspace.subscription.acceptTerms") }}
            <router-link
              :to="{ name: 'terms_use' }"
              custom
              class="dark-link"
              target="_blank"
            >
              {{ $t("workspace.subscription.termsUse") }}
            </router-link>
            {{ $t("workspace.subscription.acceptPrivacy") }}
            <router-link
              :to="{ name: 'privacy' }"
              custom
              class="dark-link"
              target="_blank"
            >
              {{ $t("workspace.subscription.privacy") }}
            </router-link>
            .
          </div>

          <div class="mb-4 text-center">
            <router-link :to="{ name: 'login' }">
              {{ $t("front.registration.alreadyRegistered") }}
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { nextTick } from "vue";
import MetaMixin from "@mizogoo/mixins/meta.js";
import FlashBag from "@mizogoo/components/FlashBag";
import FormInput from "@mizogoo/components/form/FormInput";
import FormPassword from "@mizogoo/components/form/FormPassword";

export default {
  mixins: [MetaMixin],
  name: "RegistrationPage",
  components: {
    FlashBag,
    FormInput,
    FormPassword,
  },
  props: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
        passwordConfirm: "",
      },
      message: null,
      acceptNewsletter: false,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.registration.title");
    },
    isLoading() {
      return this.$store.getters["security/isLoadingRegistration"];
    },
    hasError() {
      return this.$store.getters["security/hasErrorRegistration"];
    },
    error() {
      return this.$store.getters["security/errorRegistration"];
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    async onSubmit() {
      const response = await this.$store.dispatch("security/registration", {
        form: this.form,
      });

      if (!this.hasError) {
        this.$gtag.event("conversion", {
          send_to: "AW-10851025598/hwItCPOc_68DEL6FlrYo",
          event_category: "Sign-up",
        });
        if (this.acceptNewsletter) {
          await this.$store.dispatch("newsletterSubscription/create", {
            form: { email: this.form.email },
          });
        }

        this.message = response.message;

        this.form.email = null;
        this.form.password = null;
        this.form.passwordConfirm = null;
      }

      nextTick(() => {
        this.$refs.observer.resetForm();
      });
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>

<style lang="scss" scoped>
.terms {
  font-size: 0.9rem;
  text-align: center;

  .dark-link {
    color: $mizogoo-gray-dark;
  }
}
</style>
