<template>
  <b-container fluid class="cart-container">
    <Loading v-if="isLoadingCart || isLoadingFetchAllAddress" />

    <template v-else-if="cart && cart.elements.length > 0">
      <b-row>
        <!-- Left column (steps overview with links to navigate between them) -->
        <b-col md="12" lg="2" class="cart-col">
          <Step active="delivery" />
        </b-col>

        <!-- Main column (delivery address form) -->
        <b-col md="12" lg="8" class="cart-col main-col">
          <BackButton />

          <b-row>
            <b-col>
              <h1>{{ $t("front.cart.deliveryInformations") }}</h1>
            </b-col>
          </b-row>

          <!-- Errors -->
          <b-row v-if="hasErrorCart || hasErrorFetchAllAddress">
            <b-col class="mb-2 large-form-container">
              <FlashBag v-if="hasErrorCart" :message="errorCart" />
              <FlashBag v-if="hasErrorFetchAllAddress" :message="errorFetchAllAddress" />
            </b-col>
          </b-row>

          <b-row>
            <b-col class="large-form-container">
              <h4>{{ $t("front.cart.deliveryAddress") }}</h4>

              <template v-if="hasAddresses">
                <b-row class="mb-4">
                  <b-col>
                    <b-form-group
                      id="delivery-address-input-group'"
                      :label="$t('front.cart.selectExistingAddress')"
                      label-for="delivery-address"
                    >
                      <div class="custom-form-select-container">
                        <b-form-select
                          id="delivery-address"
                          name="delivery-address"
                          v-model="deliveryAddressInput"
                          :options="addressOptions"
                          value-field="id"
                          text-field="name"
                        ></b-form-select>
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>

              <Form as="" ref="observerDelivery">
                <b-form>
                  <b-row>
                    <b-col cols="12" sm="3">
                      <FormSelect
                        id="delivery-civility"
                        :name="$t('workspace.address.form.civility')"
                        :rules="{ required: true }"
                        :options="civilities"
                        v-model="form.deliveryAddress.civility"
                      />
                    </b-col>

                    <b-col cols="12" sm>
                      <FormInput
                        type="text"
                        id="delivery-lastname"
                        :name="$t('workspace.address.form.lastname')"
                        :rules="{ required: true }"
                        v-model="form.deliveryAddress.lastname"
                      />
                    </b-col>

                    <b-col cols="12" sm>
                      <FormInput
                        type="text"
                        id="delivery-firstname"
                        :name="$t('workspace.address.form.firstname')"
                        :rules="{ required: true }"
                        v-model="form.deliveryAddress.firstname"
                      />
                    </b-col>
                  </b-row>

                  <FormInput
                    type="text"
                    id="delivery-company"
                    :name="$t('workspace.address.form.company')"
                    v-model="form.deliveryAddress.company"
                  />

                  <FormInput
                    type="text"
                    id="delivery-street"
                    :name="$t('workspace.address.form.street')"
                    :rules="{ required: true }"
                    v-model="form.deliveryAddress.street"
                  />

                  <FormInput
                    type="text"
                    id="delivery-additional"
                    :name="$t('workspace.address.form.additional')"
                    v-model="form.deliveryAddress.additional"
                  />

                  <b-row>
                    <b-col sm>
                      <FormInput
                        type="text"
                        id="delivery-postal-code"
                        :name="$t('workspace.address.form.postalCode')"
                        :rules="{ required: true }"
                        v-model="form.deliveryAddress.postalCode"
                      />
                    </b-col>

                    <b-col sm>
                      <FormInput
                        type="text"
                        id="delivery-city"
                        :name="$t('workspace.address.form.city')"
                        :rules="{ required: true }"
                        v-model="form.deliveryAddress.city"
                      />
                    </b-col>

                    <b-col sm>
                      <FormSelect
                        id="delivery-country"
                        :name="$t('workspace.address.form.country')"
                        :rules="{ required: true }"
                        :options="countries"
                        v-model="form.deliveryAddress.country"
                      />
                    </b-col>
                  </b-row>

                  <FormInput
                    type="text"
                    id="delivery-phone"
                    :name="$t('workspace.address.form.phone')"
                    v-model="form.deliveryAddress.phone"
                  />
                </b-form>
              </Form>
            </b-col>
          </b-row>

          <b-row class="mt-4">
            <b-col class="large-form-container">
              <h4>{{ $t("front.cart.billingAddress") }}</h4>

              <b-form-checkbox
                id="same-delivery-as-billing-1"
                v-model="sameBillingAsDeliveryInput"
                name="same-delivery-as-billing-1"
                class="mb-2"
              >
                {{ $t("front.cart.sameDeliveryAsBilling") }}
              </b-form-checkbox>

              <template v-if="!sameBillingAsDelivery">
                <template v-if="hasAddresses">
                  <b-row class="mb-4">
                    <b-col>
                      <b-form-group
                        id="billing-address-input-group'"
                        :label="$t('front.cart.selectExistingAddress')"
                        label-for="billing-address"
                      >
                        <div class="custom-form-select-container">
                          <b-form-select
                            id="billing-address"
                            name="billing-address"
                            v-model="billingAddressInput"
                            :options="addressOptions"
                            value-field="id"
                            text-field="name"
                          ></b-form-select>
                        </div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </template>

                <Form as="" ref="observerBilling">
                  <b-form>
                    <b-row>
                      <b-col cols="12" sm="3">
                        <FormSelect
                          id="billing-civility"
                          :name="$t('workspace.address.form.civility')"
                          :rules="{ required: true }"
                          :options="civilities"
                          v-model="form.billingAddress.civility"
                        />
                      </b-col>

                      <b-col cols="12" sm>
                        <FormInput
                          type="text"
                          id="billing-lastname"
                          :name="$t('workspace.address.form.lastname')"
                          :rules="{ required: true }"
                          v-model="form.billingAddress.lastname"
                        />
                      </b-col>

                      <b-col cols="12" sm>
                        <FormInput
                          type="text"
                          id="billing-firstname"
                          :name="$t('workspace.address.form.firstname')"
                          :rules="{ required: true }"
                          v-model="form.billingAddress.firstname"
                        />
                      </b-col>
                    </b-row>

                    <FormInput
                      type="text"
                      id="billing-company"
                      :name="$t('workspace.address.form.company')"
                      v-model="form.billingAddress.company"
                    />

                    <FormInput
                      type="text"
                      id="billing-street"
                      :name="$t('workspace.address.form.street')"
                      :rules="{ required: true }"
                      v-model="form.billingAddress.street"
                    />

                    <FormInput
                      type="text"
                      id="billing-additional"
                      :name="$t('workspace.address.form.additional')"
                      v-model="form.billingAddress.additional"
                    />

                    <b-row>
                      <b-col sm>
                        <FormInput
                          type="text"
                          id="billing-postal-code"
                          :name="$t('workspace.address.form.postalCode')"
                          :rules="{ required: true }"
                          v-model="form.billingAddress.postalCode"
                        />
                      </b-col>

                      <b-col sm>
                        <FormInput
                          type="text"
                          id="billing-city"
                          :name="$t('workspace.address.form.city')"
                          :rules="{ required: true }"
                          v-model="form.billingAddress.city"
                        />
                      </b-col>

                      <b-col sm>
                        <FormSelect
                          id="billing-country"
                          :name="$t('workspace.address.form.country')"
                          :rules="{ required: true }"
                          :options="countries"
                          v-model="form.billingAddress.country"
                        />
                      </b-col>
                    </b-row>

                    <FormInput
                      type="text"
                      id="billing-phone"
                      :name="$t('workspace.address.form.phone')"
                      v-model="form.billingAddress.phone"
                    />
                  </b-form>
                </Form>
              </template>
            </b-col>
          </b-row>
        </b-col>

        <!-- Right column (summary with cart element and prices) -->
        <b-col md="12" lg="2" class="cart-col cart-right">
          <Summary
            currentStep="delivery"
            :prices="prices"
            :cartElements="cart.elements"
            @onSubmit="onSubmit"
          />
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import BackButton from "@mizogoo/components/workspace/layout/BackButton";
import Loading from "@mizogoo/components/workspace/layout/Loading";
import FlashBag from "@mizogoo/components/FlashBag";
import Step from "@mizogoo/components/front/pages/CartPage/Step";
import Summary from "@mizogoo/components/front/pages/CartPage/Summary";
import FormInput from "@mizogoo/components/form/FormInput";
import FormSelect from "@mizogoo/components/form/FormSelect";
import FormCheckbox from "@mizogoo/components/form/FormCheckbox";

export default {
  name: "CartPageAddress",
  components: {
    BackButton,
    Loading,
    FlashBag,
    Step,
    Summary,
    FormInput,
    FormSelect,
    FormCheckbox,
  },
  data() {
    return {
      currentDeliveryAddress: null,
      currentBillingAdress: null,
      sameBillingAsDelivery: true,
      form: {
        deliveryAddress: {
          id: null,
          name: null,
          civility: null,
          lastname: null,
          firstname: null,
          company: null,
          street: null,
          additional: null,
          postalCode: null,
          city: null,
          country: null,
          phone: null,
        },
        billingAddress: {
          id: null,
          name: null,
          civility: null,
          lastname: null,
          firstname: null,
          company: null,
          street: null,
          additional: null,
          postalCode: null,
          city: null,
          country: null,
          phone: null,
        },
      },
    };
  },
  computed: {
    // Cart
    isLoadingCart() {
      return this.$store.getters["cart/isLoadingCart"];
    },
    hasErrorCart() {
      return this.$store.getters["cart/hasErrorCart"];
    },
    errorCart() {
      return this.$store.getters["cart/errorCart"];
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
    prices() {
      return this.$store.getters["cart/prices"];
    },
    // Adresses
    isLoadingFetchAllAddress() {
      return this.$store.getters["accountAddress/isLoadingFetchAll"];
    },
    hasErrorFetchAllAddress() {
      return this.$store.getters["accountAddress/hasErrorFetchAll"];
    },
    errorFetchAllAddress() {
      return this.$store.getters["accountAddress/errorFetchAll"];
    },
    hasAddresses() {
      return this.$store.getters["accountAddress/hasAddresses"];
    },
    addresses() {
      return this.$store.getters["accountAddress/addresses"];
    },
    // Adresse
    addressOptions() {
      let options = [];

      for (let address of this.addresses) {
        let name =
          address.civility +
          " " +
          address.lastname +
          " " +
          address.firstname +
          " " +
          address.street +
          " " +
          address.postalCode +
          " " +
          address.city;
        if (address.name) {
          name = address.name;
        }

        const option = { id: address.id, name: name };

        options.push(option);
      }

      return options;
    },
    // Delivery address
    deliveryAddressInput: {
      get() {
        return this.currentDeliveryAddress;
      },
      set(newValue) {
        this.currentDeliveryAddress = newValue;
        this.updateDeliveryAdress(
          this.addresses.find((element) => element.id === newValue)
        );

        return newValue;
      },
    },
    // Billing address
    billingAddressInput: {
      get() {
        return this.currentBillingAdress;
      },
      set(newValue) {
        this.currentBillingAdress = newValue;
        this.updateBillingAdress(
          this.addresses.find((element) => element.id === newValue)
        );

        return newValue;
      },
    },
    sameBillingAsDeliveryInput: {
      get() {
        return this.sameBillingAsDelivery;
      },
      set(newValue) {
        this.sameBillingAsDelivery = newValue;

        return newValue;
      },
    },
    // Updating Address
    isLoadingUpdatingAddress() {
      return this.$store.getters["cart/isLoadingUpdatingAddress"];
    },
    hasErrorUpdatingAddress() {
      return this.$store.getters["cart/hasErrorUpdatingAddress"];
    },
    errorUpdatingAddress() {
      return this.$store.getters["cart/errorUpdatingAddress"];
    },
  },
  methods: {
    // Product
    productFeatureValueCustomizable(productFeatureValuesSorted) {
      return productFeatureValuesSorted.filter(
        (element) => element.feature.featureFamily.constName !== "CUSTOMIZABLE"
      );
    },
    // Delivery address
    updateDeliveryAdress(address) {
      this.currentDeliveryAddress = address.id;

      this.form.deliveryAddress.id = address.id;
      this.form.deliveryAddress.name = address.name;
      this.form.deliveryAddress.civility = address.civility;
      this.form.deliveryAddress.lastname = address.lastname;
      this.form.deliveryAddress.firstname = address.firstname;
      this.form.deliveryAddress.company = address.company;
      this.form.deliveryAddress.street = address.street;
      this.form.deliveryAddress.additional = address.additional;
      this.form.deliveryAddress.postalCode = address.postalCode;
      this.form.deliveryAddress.city = address.city;
      this.form.deliveryAddress.country = address.country.id.toString();
      this.form.deliveryAddress.phone = address.phone;
    },
    // Billing address
    updateBillingAdress(address) {
      this.currentBillingAdress = address.id;

      this.form.billingAddress.id = address.id;
      this.form.billingAddress.name = address.name;
      this.form.billingAddress.civility = address.civility;
      this.form.billingAddress.lastname = address.lastname;
      this.form.billingAddress.firstname = address.firstname;
      this.form.billingAddress.company = address.company;
      this.form.billingAddress.street = address.street;
      this.form.billingAddress.additional = address.additional;
      this.form.billingAddress.postalCode = address.postalCode;
      this.form.billingAddress.city = address.city;
      this.form.billingAddress.country = address.country.id.toString();
      this.form.billingAddress.phone = address.phone;
    },
    // Update Address
    async onSubmit() {
      const deliveryValidate = await this.$refs.observerDelivery.validate();

      let billingValidate = true;
      if (typeof this.$refs.observerBilling !== "undefined") {
        billingValidate = await this.$refs.observerBilling.validate();
      }

      if (deliveryValidate && billingValidate) {
        await this.$store.dispatch("cart/updateAddress", {
          sameBillingAsDelivery: this.sameBillingAsDelivery,
          form: this.form,
        });

        if (!this.hasErrorUpdatingAddress) {
          return this.$router.push({ name: "cart_command" }).catch(() => {});
        }
      }
    },
  },
  async created() {
    await this.$store.dispatch("cart/findCart");

    if (!this.cart && this.cart.elements.length === 0) {
      return this.$router.push({ name: "home" }).catch(() => {});
    }

    await this.$store.dispatch("accountAddress/findAll");

    if (this.cart.deliveryAddress) {
      this.updateDeliveryAdress(this.cart.deliveryAddress);
    } else {
      this.form.deliveryAddress.civility = this.civilities[0].id;
      this.form.deliveryAddress.country = this.countries[0].id;
    }

    if (this.cart.deliveryAddress && this.cart.billingAddress) {
      if (this.cart.deliveryAddress.id !== this.cart.billingAddress.id) {
        this.sameBillingAsDelivery = false;
      }
    }
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
    this.$store.dispatch("global/fetchFrontHeaderVisible", false);
    this.$store.dispatch("global/fetchFrontFooterVisible", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
    this.$store.dispatch("global/fetchFrontHeaderVisible", true);
    this.$store.dispatch("global/fetchFrontFooterVisible", true);
  },
};
</script>
