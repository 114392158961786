<template>  
  <div v-if="step.featureValues" class="mt-3 mb-3">
    <!-- Feature name -->
    <div class="feature-name">
      {{ step.name }}
    </div>

    <!-- Feature selection -->
    <div class="features-container">
      <div
        v-for="featureValue in step.featureValues"
        :key="featureValue.id"
        class="feature-container"
        :class="{
          'active-feature-container':
            featureValue.id ===
            currentFeatureValues[step.id - nbStepBeforeFeature].id
        }"
        @click="onClickFeatureValue(step, featureValue)"
      >
        <div class="feature-value w-100 flex-column justify-content-center text-center">
          <div>
            <span class="feature-value-text">{{ featureValue.value }}</span>
            <template v-if="featureValue.fields.length > 0">
              <span
                :id="'feature-value-extra-info-' + featureValue.id"
                class="ml-2"
              >
                <i class="far fa-question-circle"></i>
              </span>
              <b-popover
                :target="'feature-value-extra-info-' + featureValue.id"
                triggers="hover"
                placement="right"
              >
                <div class="">
                  <div v-for="field in featureValue.fields" :key="field.id">
                    <template v-if="field.name !== 'Image'">
                      <p class="m-0">
                        <strong>{{ field.name }}</strong> {{ field.value }}
                      </p>
                    </template>
                  </div>
                </div>
              </b-popover>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomizableFeature",
  props: ["step", "currentFeatureValues", "nbStepBeforeFeature"],
  computed: {
  },
  methods: {
    onClickFeatureValue(step, featureValue) {
      this.$emit('onClickFeatureValue', step, featureValue);
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-value-text {
  font-weight: 500;
}
</style>
