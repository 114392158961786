export const productInfos = {
  // aFrameSign: {
  //   productDescription: {
  //     extraBlockIds: [4, 3],
  //     extraBlockParams: { deliveryDays: 5 },
  //   },
  // },
  // aFrameSignPoster: {
  //   productDescription: {
  //     extraBlockIds: [0, 1, 2, 3],
  //     extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
  //   },
  // },
  backgroundSheet: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  beerMat: {},
  brochure: {},
  businessCard: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 3, extraInfo: "flat" },
    },
  },
  cutleryPouche: {},
  digitalMenu: {
    productDescription: {
      extraBlockIds: [11, 10, 9, 12],
    },
  },
  doorHanger: {},
  enveloppe: {
    productDescription: {
      extraBlockIds: [1, 2, 3],
      extraBlockParams: { deliveryDays: 4, extraInfo: "flat" },
    },
  },
  flapFolder: {},
  flyer: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  foldedLoyaltyCard: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 3, extraInfo: "flat" },
    },
  },
  headedPaper: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 4],
    },
  },
  innerSheet: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  leaflet: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  menuHolderPel: {
    productDescription: {
      extraBlockIds: [0, 1, 3],
      extraBlockParams: { deliveryDays: "5/10" },
    },
  },
  menuHolderPolyColor: {
    productDescription: {
      extraBlockIds: [1, 5, 3],
      extraBlockParams: { deliveryDays: "5/10" },
    },
  },
  menuHolderPolyTransprent: {
    productDescription: {
      extraBlockIds: [0, 1, 5, 3],
      extraBlockParams: { deliveryDays: "10/15" },
    },
  },
  menuHolderTextured: {
    productDescription: {
      extraBlockIds: [1, 8, 3],
      extraBlockParams: { deliveryDays: "10/15" },
    },
  },
  menuPel: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  menuPla: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 3, extraInfo: "flat" },
    },
  },
  napkin: {},
  offsetPrintedPaperBag: {},
  paperWineBag: {},
  polystoneSignHolder: {},
  poster: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  qrcodeStickBoard: {
    productDescription: {
      extraBlockIds: [5, 6, 3],
      extraBlockParams: { deliveryDays: 3, extraInfo: "flat" },
    },
  },
  rollLabel: {},
  sandwichWrapBand: {},
  screenPrintedPaperBag: {},
  sheetProtector: {},
  sign: {},
  signHolder: {},
  socialNetwork: {
    productDescription: {
      extraBlockIds: [0, 1],
    },
  },
  standardLoyaltyCard: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 3, extraInfo: "flat" },
    },
  },
  tableSetFabric: {},
  tableSetPaper: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 5, extraInfo: "flat" },
    },
  },
  tableSetPlastic: {
    productDescription: {
      extraBlockIds: [0, 1, 2, 3],
      extraBlockParams: { deliveryDays: 4, extraInfo: "flat" },
    },
  },
  tableTent: {},
  woodenClipboard: {},
  woodenSignHolder: {}  
};