import AccountSubscriptionApi from '../api/account_subscription'

const
  CREATING = 'CREATING',
  CREATING_SUCCESS = 'CREATING_SUCCESS',
  CREATING_ERROR = 'CREATING_ERROR',

  UPDATING = 'UPDATING',
  UPDATING_SUCCESS = 'UPDATING_SUCCESS',
  UPDATING_ERROR = 'UPDATING_ERROR',

  INVOICE_PDF = 'INVOICE_PDF',
  INVOICE_PDF_SUCCESS = 'INVOICE_PDF_SUCCESS',
  INVOICE_PDF_ERROR = 'INVOICE_PDF_ERROR'

export default {
  namespaced: true,
  state: {
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // InvoicePdf
    isLoadingInvoicePdf: false,
    errorInvoicePdf: null,
  },
  getters: {
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null
    },
    errorCreating (state) {
      return state.errorCreating
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null
    },
    errorUpdating (state) {
      return state.errorUpdating
    },
    // InvoicePdf
    isLoadingInvoicePdf (state) {
      return state.isLoadingInvoicePdf
    },
    hasErrorInvoicePdf (state) {
      return state.errorInvoicePdf !== null
    },
    errorInvoicePdf (state) {
      return state.errorInvoicePdf
    },
  },
  mutations: {
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true
      state.errorCreating = null
    },
    [CREATING_SUCCESS] (state, currentSubscription) {
      state.isLoadingCreating = false
      state.errorCreating = null

      let user = this.getters['security/user']
      user.currentSubscription = currentSubscription
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false
      state.errorCreating = error
    },
    // Updaintg
    [UPDATING] (state) {
      state.isLoadingUpdating = true
      state.errorUpdating = null
    },
    [UPDATING_SUCCESS] (state, currentSubscription) {
      state.isLoadingUpdating = false
      state.errorUpdating = null

      let user = this.getters['security/user']
      user.currentSubscription = currentSubscription
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false
      state.errorUpdating = error
    },
    // InvoicePdf
    [INVOICE_PDF] (state) {
      state.isLoadingInvoicePdf = true
      state.errorInvoicePdf = null
    },
    [INVOICE_PDF_SUCCESS] (state) {
      state.isLoadingInvoicePdf = false
      state.errorInvoicePdf = null
    },
    [INVOICE_PDF_ERROR] (state, error) {
      state.isLoadingInvoicePdf = false
      state.errorInvoicePdf = error
    },
  },
  actions: {
    async create ({ commit }, payload) {
      commit(CREATING)
      try {
        let response = await AccountSubscriptionApi.create(payload.form)
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.currentSubscription)
          return response.data
        } else {
          commit(CREATING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(CREATING_ERROR, error)
        return null
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING)
      try {
        let response = await AccountSubscriptionApi.update(payload.form)
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.currentSubscription)
          return response.data
        } else {
          commit(UPDATING_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(UPDATING_ERROR, error)
        return null
      }
    },
    async invoicePdf ({ commit }, payload) {
      commit(INVOICE_PDF)
      try {
        let response = await AccountSubscriptionApi.invoicePdf(payload.stripeInvoice)
        if (response.data.success === true) {
          commit(INVOICE_PDF_SUCCESS)
          return response.data
        } else {
          commit(INVOICE_PDF_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(INVOICE_PDF_ERROR, error)
        return null
      }
    },
  }
}
