import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/product/category/position", Tool.createFormData());
  },
  update (data) {
    return axios.post("/api/account/product/category/position/update", Tool.createFormData(data));
  },
};
