<template>
  <b-container class="page-container">
    <b-row v-if="isLoading">
      <b-col cols="12">
        {{ $t("global.loading") }}
      </b-col>
    </b-row>

    <div v-else>
      <b-col v-if="!isTokenValid">
        <FlashBag :message="error" v-if="hasError"/>
      </b-col>

      <div v-else>
        <b-row>
          <b-col cols="12" class="mb-4 text-center">
            <FlashBag :message="message" variant="success" v-if="hasMessage"/>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import FlashBag from '@mizogoo/components/FlashBag'

export default {
  name: "RegistrationValidationEmailPage",
  components: {
    FlashBag,
  },
  props: {},
  data () {
    return {
      isTokenValid: false,
      message: null,
    };
  },
  computed: {
    isLoading () {
      return this.$store.getters["security/isLoadingRegistrationValidationEmail"];
    },
    hasError () {
      return this.$store.getters["security/hasErrorRegistrationValidationEmail"];
    },
    error () {
      return this.$store.getters["security/errorRegistrationValidationEmail"];
    },
    hasMessage () {
      return this.message !== null;
    },
  },
  methods: {},
  async created () {
    const response = await this.$store.dispatch(
      "security/registrationValidationEmail",
      { token: this.$route.params.token }
    );

    this.isTokenValid = response.success;
    this.message = response.message;
  },
  mounted () {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount () {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>

<style scoped></style>
