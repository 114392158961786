import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/menu/category", Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/menu/category/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/menu/category/update/" + data.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/menu/category/remove/" + data, Tool.createFormData());
  },
  toggleMenusActivation (data) {
    return axios.post("/api/account/menu/category/toggle_menus_activation/" + data.menuCategory.id, Tool.createFormData(data));
  }
};
