<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <!-- Border decoration -->
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <b-container class="builder-presentation">
      <b-row>
        <b-col>
          <div class="heading-container">
            <div
              v-for="(headingPart, index) in featureTextCenter.heading"
              :key="index"
              class="heading-inner-container"
            >
              <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
            </div>
          </div>
          <p v-scrollanimation="{ name: 'fade-up', delay: 250 }">
            {{ featureTextCenter.description }}
          </p>
          <b-img
            fluid
            center
            :src="featureTextCenter.image.src"
            class="builder-presentation-image"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>

    <features-presentation
      :subheading="featureTextLeft.subheading"
      :heading="featureTextLeft.heading"
      :description="featureTextLeft.description"
    />

    <features-presentation
      :subheading="featureTextRight.subheading"
      :heading="featureTextRight.heading"
      :description="featureTextRight.description"
      :reverse="true"
    />

    <b-container class="testimonial">
      <b-row>
        <b-col>
          <div class="quotation">
            <span>{{ testimonial.quotation }}</span>
          </div>
          <div class="testimonial-extra">
            <div class="author">{{ testimonial.author }}</div>
            <div class="position">{{ testimonial.position }}</div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div class="separator"></div>

    <b-container fluid class="templates-presentation">
      <b-container class="pb-3">
        <b-row>
          <b-col>
            <h3 v-scrollanimation="{ name: 'fade-up-appear' }">
              {{ templates.subheading }}
            </h3>
            <div class="heading-container">
              <div
                v-for="(headingPart, index) in templates.heading"
                :key="index"
                class="heading-inner-container"
              >
                <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
              </div>
            </div>
            <p v-scrollanimation="{ name: 'fade-up' }">{{ templates.description }}</p>
          </b-col>
        </b-row>
      </b-container>

      <div class="scroller" data-direction="right" data-speed="slow">
        <div class="scroller__inner">
          <b-img
            fluid
            center
            :src="templates.image1.src"
            :alt="templates.image1.alt"
            class="builder-presentation-image-1"
          ></b-img>
        </div>
      </div>

      <div class="scroller" data-direction="left" data-speed="slow">
        <div class="scroller__inner">
          <b-img
            fluid
            center
            :src="templates.image2.src"
            :alt="templates.image2.alt"
            class="builder-presentation-image-2"
          ></b-img>
        </div>
      </div>

      <router-link
        :to="{ name: templates.button.link }"
        class="btn btn-outline-primary mt-5 mb-3"
      >
        <span>{{ templates.button.label }}</span>
      </router-link>
      <div class="btn-extra">{{ templates.button.extra }}</div>
    </b-container>

    <b-container fluid class="banner-container">
      <div class="inner-container">
        <b-container>
          <b-row>
            <b-col md="6" sm="12" align-self="end" order="2" order-md="1">
              <b-img-lazy
                fluid
                center
                :src="banner.image.src"
                :alt="banner.image.alt"
                class=""
              ></b-img-lazy>
            </b-col>
            <b-col md="6" sm="12" class="text-container" order="1" order-md="2">
              <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ banner.heading }}</h2>
              <p v-scrollanimation="{ name: 'fade-up', delay: 250 }">
                {{ banner.description }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-container>

    <b-container class="printing-service">
      <b-row>
        <b-col>
          <h3 v-scrollanimation="{ name: 'fade-up-appear' }">
            {{ printingService.subheading }}
          </h3>
          <div class="heading-container">
            <div
              v-for="(headingPart, index) in printingService.heading"
              :key="index"
              class="heading-inner-container"
            >
              <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
            </div>
          </div>
          <p v-scrollanimation="{ name: 'fade-up' }">{{ printingService.description }}</p>
          <router-link
            :to="{ name: printingService.button.link }"
            class="btn btn-outline-primary mt-3 mb-3"
          >
            <span>{{ printingService.button.label }}</span>
          </router-link>
          <div class="image-container">
            <b-img fluid center :src="printingService.image.src"></b-img>
            <div class="image-decoration"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <products-overview />

    <steps
      :subheading="steps.subheading"
      :heading="steps.heading"
      :steps="steps.steps"
    ></steps>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeaturesPresentation from "./FeaturesPresentation.vue";
import ProductsOverview from "@mizogoo/components/front/layout/products/product/ProductsOverview";
import Steps from "@mizogoo/components/front/layout/features/steps/Steps";

export default {
  mixins: [MetaMixin],
  name: "CreatePage",
  components: {
    TopBanner,
    FeaturesPresentation,
    ProductsOverview,
    Steps,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.create.title");
    },
    metaDescription() {
      return this.$t("front.metadata.create.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.create.topBanner.heading"),
        description: this.$t("front.create.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/create/banner_create_restaurant_menu.png"),
          alt: this.$t("front.create.topBanner.imageAlt"),
        },
        imageDecoration: require("@mizogoo/assets/img/front/create/banner_create_border.png"),
        buttons: [
          {
            link: "my_creation_create",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextCenter() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/create/builder_presentation.png"),
          alt: this.$t("front.create.featureTextCenter.imageAlt"),
        },
        subheading: this.$t("front.create.featureTextCenter.subheading"),
        heading: this.$tm("front.create.featureTextCenter.heading"),
        description: this.$t("front.create.featureTextCenter.description"),
      };
    },
    featureTextLeft() {
      return {
        subheading: this.$t("front.create.featureTextLeft.subheading"),
        heading: this.$tm("front.create.featureTextLeft.heading"),
        description: [
          {
            id: 0,
            image: {
              src: require("@mizogoo/assets/img/front/create/builder_presentation_templates.png"),
              alt: this.$t("front.create.featureTextLeft.description[0].imageAlt"),
            },
            heading: this.$tm("front.create.featureTextLeft.description[0].heading"),
            description: this.$t("front.create.featureTextLeft.description[0].description"),
          },
          {
            id: 1,
            image: {
              src: require("@mizogoo/assets/img/front/create/builder_presentation_images.png"),
              alt: this.$t("front.create.featureTextLeft.description[1].imageAlt"),
            },
            heading: this.$tm("front.create.featureTextLeft.description[1].heading"),
            description: this.$t("front.create.featureTextLeft.description[1].description"),
          },
          {
            id: 2,
            image: {
              src: require("@mizogoo/assets/img/front/create/builder_presentation_styles.png"),
              alt: this.$t("front.create.featureTextLeft.description[2].imageAlt"),
            },
            heading: this.$tm("front.create.featureTextLeft.description[2].heading"),
            description: this.$t("front.create.featureTextLeft.description[2].description"),
          },
        ],
      };
    },
    featureTextRight() {
      return {
        subheading: this.$t("front.create.featureTextRight.subheading"),
        heading: this.$tm("front.create.featureTextRight.heading"),
        description: [
          {
            id: 0,
            image: {
              src: require("@mizogoo/assets/img/front/create/builder_presentation_data.png"),
              alt: this.$t("front.create.featureTextRight.description[0].imageAlt"),
            },
            heading: this.$tm("front.create.featureTextRight.description[0].heading"),
            description: this.$t("front.create.featureTextRight.description[0].description"),
          },
          {
            id: 1,
            image: {
              src: require("@mizogoo/assets/img/front/create/builder_presentation_resize.png"),
              alt: this.$t("front.create.featureTextRight.description[1].imageAlt"),
            },
            heading: this.$tm("front.create.featureTextRight.description[1].heading"),
            description: this.$t("front.create.featureTextRight.description[1].description"),
          },
          {
            id: 2,
            image: {
              src: require("@mizogoo/assets/img/front/create/builder_presentation_translation.png"),
              alt: this.$t("front.create.featureTextRight.description[2].imageAlt"),
            },
            heading: this.$tm("front.create.featureTextRight.description[2].heading"),
            description: this.$t("front.create.featureTextRight.description[2].description"),
          },
        ],
      };
    },
    testimonial() {
      return {
        quotation: this.$t("front.create.testimonial.quotation"),
        author: this.$t("front.create.testimonial.author"),
        position: this.$t("front.create.testimonial.position"),
      };
    },
    templates() {
      return {
        image1: {
          src: require("@mizogoo/assets/img/front/create/template_exemples_1.png"),
          alt: this.$t("front.create.printingService.imageAlt"),
        },
        image2: {
          src: require("@mizogoo/assets/img/front/create/template_exemples_2.png"),
          alt: this.$t("front.create.printingService.imageAlt"),
        },
        subheading: this.$t("front.create.templates.subheading"),
        heading: this.$tm("front.create.templates.heading"),
        description: this.$t("front.create.templates.description"),
        button: {
          link: "catalog",
          label: this.$t("front.create.templates.buttonLabel"),
          extra: this.$t("front.create.templates.buttonExtra"),
        },
      };
    },
    banner() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/create/graphic_designer.png"),
          alt: this.$t("front.create.banner.imageAlt"),
        },
        heading: this.$tm("front.create.banner.heading"),
        description: this.$t("front.create.banner.description"),
      };
    },
    printingService() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/create/printing_service_restaurant_menu.png"),
          alt: this.$t("front.create.printingService.imageAlt"),
        },
        subheading: this.$t("front.create.printingService.subheading"),
        heading: this.$tm("front.create.printingService.heading"),
        description: this.$t("front.create.printingService.description"),
        button: {
          link: "printing",
          label: this.$t("front.create.printingService.buttonLabel"),
        },
      };
    },
    steps() {
      return {
        subheading: this.$t("front.create.steps.subheading"),
        heading: this.$tm("front.create.steps.heading"),
        steps: [
          {
            id: 0,
            heading: this.$tm("front.create.steps.steps[0].heading"),
            description: this.$t("front.create.steps.steps[0].description"),
          },
          {
            id: 1,
            heading: this.$tm("front.create.steps.steps[1].heading"),
            description: this.$t("front.create.steps.steps[1].description"),
          },
          {
            id: 2,
            heading: this.$tm("front.create.steps.steps[2].heading"),
            description: this.$t("front.create.steps.steps[2].description"),
          },
        ],
      };
    },
  },
  mounted() {
    // Allow to init the scrolling sections (animated templates)
    // from https://codepen.io/kevinpowell/pen/BavVLra
    const scrollers = document.querySelectorAll(".scroller");

    // If a user hasn't opted in for recuded motion, then we add the animation
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        // add data-animated="true" to every `.scroller` on the page
        scroller.setAttribute("data-animated", true);

        // Make an array from the elements within `.scroller-inner`
        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        // For each item in the array, clone it
        // add aria-hidden to it
        // add it into the `.scroller-inner`
        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.top-banner-button {
  margin-top: 3rem;
}

.builder-presentation {
  padding-top: 5rem;
  padding-bottom: 5rem;
  text-align: center;

  p {
    max-width: 920px;
    margin: auto;
    margin-bottom: 3rem;
  }

  .builder-presentation-image {
    border: 10px solid #fff;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
}

.testimonial {
  margin-top: 12rem;
  margin-bottom: 7rem;

  .quotation {
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 600;
    text-align: center;

    span {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: url(../../../../assets/img/front/home/quote.png);
      position: absolute;
      left: -35px;
      top: -25px;
      z-index: 1;
    }
  }

  .testimonial-extra {
    position: relative;
    float: right;
    max-width: 300px;
    padding: 2rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $background-color;
      z-index: 2;
    }

    &::after {
      content: url(../../../../assets/img/front/global/decorations/dotted_decoration_small.svg);
      position: absolute;
      bottom: -70px;
      left: 130px;
      transform: rotate(90deg);
      z-index: 1;
    }

    .author {
      position: relative;
      margin-bottom: 0.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      z-index: 3;

      &::before {
        content: "";
        width: 25px;
        height: 2px;
        position: absolute;
        left: -35px;
        top: 50%;
        background-color: $mizogoo-gray-dark;
      }
    }

    .position {
      position: relative;
      z-index: 3;
    }
  }
}

.templates-presentation {
  margin-top: 8rem;
  margin-bottom: 4rem;
  padding: 0;
  text-align: center;

  .scroller__inner {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .scroller[data-animated="true"] {
    overflow: hidden;
  }

  .scroller[data-animated="true"] .scroller__inner {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 40s)
      var(--_animation-direction, forwards) linear infinite;
  }

  .scroller[data-direction="right"] {
    --_animation-direction: reverse;
  }

  .scroller[data-direction="left"] {
    --_animation-direction: forwards;
  }

  .scroller[data-speed="slow"] {
    --_animation-duration: 120s;
  }

  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }

  .builder-presentation-image-1,
  .builder-presentation-image-2 {
    height: 270px;
  }

  .btn-extra {
    font-size: 0.9rem;
    font-style: italic;
    color: #9c9c9c;
    max-width: 300px;
    margin: auto;
  }
}

.banner-container {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
  color: #fff;

  .inner-container {
    position: relative;
    background-image: url("../../../../assets/img/front/global/decorations/dotted_background.svg"),
      linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
    border-radius: 30px;

    img {
      margin-top: -2rem;
    }

    .text-container {
      padding-top: 5rem;
      padding-bottom: 5rem;

      p {
        font-weight: 500 !important;
      }
    }
  }
}

.printing-service {
  padding-top: 5rem;
  padding-bottom: 8rem;
  text-align: center;

  .image-container {
    position: relative;

    img {
      position: relative;
      z-index: 2;
    }

    .image-decoration {
      position: absolute;
      top: 40%;
      left: -10%;
      width: 120%;
      height: 75%;
      background-color: $mizogoo-gray-light;
      border-radius: 25px;
    }
  }
}

@media screen and (max-width: 576px) {
  .image-decoration {
    display: none;
  }

  .testimonial {
    margin-top: 8rem;

    .testimonial-extra {
      &::after {
        display: none;
      }
    }
  }

  .banner-container {
    .inner-container {
      .text-container {
        padding: 2rem;
      }

      img {
        margin-top: 0;
      }
    }
  }
}
</style>
