<template>
  <b-container class="secondary-page-container legal-page">
    <b-row>
      <b-col class="mb-4">
        <!-- Page info (heading, last update date and introduction) -->
        <h1 class="text-center pb-2">{{ $t("front.termsSale.heading") }}</h1>
        <p class="last-update-date mb-5">
          {{ $t("front.legal.lastUpdateDate") }}
        </p>
        <div v-html="$t('front.termsSale.description')" />

        <!-- Parts -->
        <div v-for="(part, partIndex) in parts" :key="part.id">
          <h2>
            <div class="white-space-initial">
              {{ $t("front.legal.part") }}
              {{ partIndex + 1}} - {{ part.heading }}
            </div>
          </h2>
          <!-- Sections -->
          <div
            v-for="(section, sectionIndex) in part.sections"
            :key="sectionIndex"
          >
            <h4 class="mt-5 mb-4 pt-2">
              <div class="white-space-initial">
                {{ $t("front.legal.article") }}
                {{ sectionIndex + 1 }}. {{ section.heading }}
              </div>
            </h4>

            <template v-if="section.description">
              <div v-html="section.description" />
            </template>

            <!-- Subsections -->
            <template v-if="section.subsections">
              <div v-for="(subsection, subsectionIndex) in section.subsections" :key="subsectionIndex">
                <h5 v-if="subsection.subheading" class="mt-2 mb-3">
                  {{ sectionIndex + 1 }}.{{ subsectionIndex + 1 }}. {{ subsection.subheading }}
                </h5>
                <div v-html="subsection.description" />
              </div>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";

export default {
  mixins: [MetaMixin],
  name: "TermsSalePage",
  computed: {
    metaTitle() {
      return this.$t("front.metadata.termsSale.title");
    },
    parts() {
      return [
        {
          id: "PART1",
          heading: this.$t("front.termsSale.part1.heading"),
          sections: [
            {
              heading: this.$t("front.termsSale.part1.sections[0].heading"),
              description: this.$t("front.termsSale.part1.sections[0].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[1].heading"),
              description: this.$t("front.termsSale.part1.sections[1].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[2].heading"),
              description: this.$t("front.termsSale.part1.sections[2].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[3].heading"),
              description: this.$t("front.termsSale.part1.sections[3].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[4].heading"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part1.sections[4].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part1.sections[4].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part1.sections[4].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part1.sections[4].subsections[1].description"),
                },
              ]
            },
            {
              heading: this.$t("front.termsSale.part1.sections[5].heading"),
              description: this.$t("front.termsSale.part1.sections[5].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[6].heading"),
              description: this.$t("front.termsSale.part1.sections[6].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[7].heading"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part1.sections[7].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part1.sections[7].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part1.sections[7].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part1.sections[7].subsections[1].description"),
                },
              ]
            },
            {
              heading: this.$t("front.termsSale.part1.sections[8].heading"),
              description: this.$t("front.termsSale.part1.sections[8].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[9].heading"),
              description: this.$t("front.termsSale.part1.sections[9].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[10].heading"),
              description: this.$t("front.termsSale.part1.sections[10].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[11].heading"),
              description: this.$t("front.termsSale.part1.sections[11].description"),
            },
            {
              heading: this.$t("front.termsSale.part1.sections[12].heading"),
              description: this.$t("front.termsSale.part1.sections[12].description"),
            },
          ],
        },
        {
          id: "PART2",
          heading: this.$t("front.termsSale.part2.heading"),
          sections: [
            {
              heading: this.$t("front.termsSale.part2.sections[0].heading"),
              description: this.$t("front.termsSale.part2.sections[0].description"),
            },
            {
              heading: this.$t("front.termsSale.part2.sections[1].heading"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part2.sections[1].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part2.sections[1].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part2.sections[1].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part2.sections[1].subsections[1].description"),
                }
              ]
            },
            {
              heading: this.$t("front.termsSale.part2.sections[2].heading"),
              description: this.$t("front.termsSale.part2.sections[2].description"),
            },
            {
              heading: this.$t("front.termsSale.part2.sections[3].heading"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part2.sections[3].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part2.sections[3].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part2.sections[3].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part2.sections[3].subsections[1].description"),
                }
              ]
            },
            {
              heading: this.$t("front.termsSale.part2.sections[4].heading"),
              description: this.$t("front.termsSale.part2.sections[4].description"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part2.sections[4].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part2.sections[4].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part2.sections[4].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part2.sections[4].subsections[1].description"),
                }
              ]
            },
            {
              heading: this.$t("front.termsSale.part2.sections[5].heading"),
              description: this.$t("front.termsSale.part2.sections[5].description"),
            },
            {
              heading: this.$t("front.termsSale.part2.sections[6].heading"),
              description: this.$t("front.termsSale.part2.sections[6].description"),
            },
          ]
        },
        {
          id: "PART3",
          heading: this.$t("front.termsSale.part3.heading"),
          sections: [
            {
              heading: this.$t("front.termsSale.part3.sections[0].heading"),
              description: this.$t("front.termsSale.part3.sections[0].description"),
            },
            {
              heading: this.$t("front.termsSale.part3.sections[1].heading"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part3.sections[1].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part3.sections[1].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part3.sections[1].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part3.sections[1].subsections[1].description"),
                }
              ]
            },
            {
              heading: this.$t("front.termsSale.part3.sections[2].heading"),
              description: this.$t("front.termsSale.part3.sections[2].description"),
            },
            {
              heading: this.$t("front.termsSale.part3.sections[3].heading"),
              description: this.$t("front.termsSale.part3.sections[3].description"),
            },
            {
              heading: this.$t("front.termsSale.part3.sections[4].heading"),
              description: this.$t("front.termsSale.part3.sections[4].description"),
            },
            {
              heading: this.$t("front.termsSale.part3.sections[5].heading"),
              description: this.$t("front.termsSale.part3.sections[5].description"),
            },
            {
              heading: this.$t("front.termsSale.part3.sections[6].heading"),
              subsections: [
                {
                  subheading: this.$t("front.termsSale.part3.sections[6].subsections[0].subheading"),
                  description: this.$t("front.termsSale.part3.sections[6].subsections[0].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part3.sections[6].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part3.sections[6].subsections[1].description"),
                },
                {
                  subheading: this.$t("front.termsSale.part3.sections[6].subsections[1].subheading"),
                  description: this.$t("front.termsSale.part3.sections[6].subsections[1].description"),
                },
              ]
            },
          ]
        }
      ];
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>
