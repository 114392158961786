import Vue from "vue";
import AccountMenuAPI from "../api/account_menu";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  UPDATING_POSITION = "UPDATING_POSITION",
  UPDATING_POSITION_SUCCESS = "UPDATING_POSITION_SUCCESS",
  UPDATING_POSITION_ERROR = "UPDATING_POSITION_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  ACTIVING = "ACTIVING",
  ACTIVING_SUCCESS = "ACTIVING_SUCCESS",
  ACTIVING_ERROR = "ACTIVING_ERROR",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    menus: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Updating Position
    isLoadingUpdatingPosition: false,
    errorUpdatingPosition: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Activing
    isLoadingActiving: false,
    errorActiving: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasMenus (state) {
      return state.menus.length > 0;
    },
    menus (state) {
      return state.menus;
    },
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne (state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Updating Position
    isLoadingUpdatingPosition(state) {
      return state.isLoadingUpdatingPosition;
    },
    hasErrorUpdatingPosition(state) {
      return state.errorUpdatingPosition !== null;
    },
    errorUpdatingPosition(state) {
      return state.errorUpdatingPosition;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Activing
    isLoadingActiving (state) {
      return state.isLoadingActiving;
    },
    hasErrorActiving (state) {
      return state.errorActiving !== null;
    },
    errorActiving (state) {
      return state.errorActiving;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.menus = [];
    },
    [FETCH_ALL_SUCCESS] (state, menus) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.menus = menus;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.menus = [];
    },
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state, menu) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.menus.push(menu);
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state, menu) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      if (state.menus.length > 0) {
        let menuToUpdateIndex = state.menus.findIndex(element => element.id === menu.id);
        if (menuToUpdateIndex != -1) {
          // If the category has changed, position of the others items of the previous category must be updated
          if (state.menus[menuToUpdateIndex].menuCategoryId != menu.menuCategoryId) {
            for (let i = 0; i < state.menus.length; i++) {
              let currentMenu = state.menus[i];
              // If the menu belong to the same menuCategory
              if (currentMenu.menuCategoryId === menu.menuCategoryId) {
                // And has a superior position
                if (currentMenu.position > menu.position) {
                  // It should be reduced by one
                  currentMenu.position = currentMenu.position - 1;
                }
              }
            }
          }
          state.menus.splice(menuToUpdateIndex, 1, menu);
        }
      }
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Updating Position
    [UPDATING_POSITION](state) {
      state.isLoadingUpdatingPosition = true;
      state.errorUpdatingPosition = null;
    },
    [UPDATING_POSITION_SUCCESS](state, positions) {
      for (let i = 0; i < positions.length; i++) {
        let menuToUpdate = state.menus.find(element => element.id === parseInt(positions[i].id));
        menuToUpdate.position = positions[i].position;
      }
      state.isLoadingUpdatingPosition = false;
      state.errorUpdatingPosition = null;
    },
    [UPDATING_POSITION_ERROR](state, error) {
      state.isLoadingUpdatingPosition = false;
      state.errorUpdatingPosition = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, menu) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      for (let i = 0; i < state.menus.length; i++) {
        let currentMenu = state.menus[i];
        if (currentMenu.menuCategoryId === menu.menuCategoryId) {
          if (currentMenu.position > menu.position) {
            currentMenu.position = currentMenu.position - 1;
          }
        }
      }
      state.menus = state.menus.filter(element => element.id !== menu.id)
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Activing
    [ACTIVING] (state) {
      state.isLoadingActiving = true;
      state.errorActiving = null;
    },
    [ACTIVING_SUCCESS] (state, menu) {
      state.isLoadingActiving = false;
      state.errorActiving = null;
      let menuToActivate = state.menus.find(element => element.id === menu.id)
      menuToActivate.active = menu.active;
    },
    [ACTIVING_ERROR] (state, error) {
      state.isLoadingActiving = false;
      state.errorActiving = error;
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.menus = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.accountMenu.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountMenuAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.menus);
            return response.data.menus;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountMenuAPI.findOne(payload.menu);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.menu;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountMenuAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.menu);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountMenuAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.menu);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async updatePosition({ commit }, payload) {
      commit(UPDATING_POSITION);
      try {
        let response = await AccountMenuAPI.updatePosition(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_POSITION_SUCCESS, payload.form.positions);
          return response.data;
        } else {
          commit(UPDATING_POSITION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_POSITION_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountMenuAPI.remove(payload.menu);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.menu);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async active ({ commit }, payload) {
      commit(ACTIVING);
      try {
        let response = await AccountMenuAPI.active(payload.menu);
        if (response.data.success === true) {
          commit(ACTIVING_SUCCESS, response.data.menu);
          return response.data;
        } else {
          commit(ACTIVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(ACTIVING_ERROR, error);
        return null;
      }
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
