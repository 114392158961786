import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/order", Tool.createFormData());
  },
  findOne (order) {
    return axios.post("/api/account/order/" + order, Tool.createFormData());
  },
  updateAddress (order, data) {
    return axios.post("/api/account/order/" + order.id + "/update/address", Tool.createFormData(data));
  },
  createComment (order, data) {
    return axios.post("/api/account/order/" + order.id + "/comment/create", Tool.createFormData(data));
  },
  makingChangesRequested (order, data) {
    return axios.post("/api/account/order/" + order.id + "/making/changes-requested", Tool.createFormData(data));
  },
  makingValidate (order) {
    return axios.post("/api/account/order/" + order.id + "/making/validate", Tool.createFormData());
  },
  checkingCustomerFile (order) {
    return axios.post("/api/account/order/" + order.id + "/checking-customer-file", Tool.createFormData());
  },
  relatedDocumentDownload (order) {
    return axios.post("/api/account/order/" + order.id + "/related-document/download", Tool.createFormData());
  },
  relatedDocumentCreate (data) {
    return axios.post("/api/account/order/" + data.order + "/related-document/create",
      Tool.createFormData(data.form),
      {
        onUploadProgress: function (progressEvent) {
          data.store.dispatch('accountOrder/setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }.bind(this)
      })
  },
};
