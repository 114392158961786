<template>
  <b-container class="secondary-page-container legal-page">
    <b-row>
      <b-col class="mb-4">
        <h1 class="text-center pb-2">{{ $t("front.termsUse.heading") }}</h1>
        <p class="last-update-date mb-5">{{ $t("front.legal.lastUpdateDate") }}</p>

        <!-- Display legal on top -->
        <div>
          <div v-html="$t('front.termsUse.description')" />
          <h4 class="mt-4 mb-3 pt-2">
            <div class="white-space-initial">
              {{ $t("front.legal.article") }} 1 – 
              {{ $t("front.termsUse.sections[0].heading") }}
            </div>
          </h4>
          <div v-html="$t('front.legal.description')" />
        </div>
        
        <!-- Sections (articles) -->
        <div
          v-for="(section, sectionIndex) in sections"
          :key="sectionIndex"
        >
          <!-- Section heading -->
          <h4 class="mt-4 mb-3 pt-2">
            <div class="white-space-initial">
              {{ $t("front.legal.article") }}
              {{ sectionIndex + 2 }} – {{ section.heading }}
            </div>
          </h4>
          
          <!-- Section content -->
          <template v-if="section.description">
            <div v-html="section.description" />
          </template>

          <!-- Subsections -->
          <template v-if="section.subsections">
            <div v-for="(subsection, subsectionIndex) in section.subsections" :key="subsectionIndex">
              <h5 class="mt-2 mb-3">
                {{ sectionIndex + 2 }}.{{subsectionIndex + 1 }}. {{ subsection.subheading }}
              </h5>
              <div v-html="subsection.description" />
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";

export default {
  mixins: [MetaMixin],
  name: "TermsUsePage",
  computed: {
    metaTitle() {
      return this.$t("front.metadata.termsUse.title");
    },
    sections() {
      return [
        {
          heading: this.$t("front.termsUse.sections[1].heading"),
          description: this.$t("front.termsUse.sections[1].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[2].heading"),
          subsections: [
            {
              subheading: this.$t("front.termsUse.sections[2].subsections[0].subheading"),
              description: this.$t("front.termsUse.sections[2].subsections[0].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[2].subsections[1].subheading"),
              description: this.$t("front.termsUse.sections[2].subsections[1].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[2].subsections[2].subheading"),
              description: this.$t("front.termsUse.sections[2].subsections[2].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[2].subsections[3].subheading"),
              description: this.$t("front.termsUse.sections[2].subsections[3].description"),
            }
          ] 
        },
        {
          heading: this.$t("front.termsUse.sections[3].heading"),
          subsections: [
            {
              subheading: this.$t("front.termsUse.sections[3].subsections[0].subheading"),
              description: this.$t("front.termsUse.sections[3].subsections[0].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[3].subsections[1].subheading"),
              description: this.$t("front.termsUse.sections[3].subsections[1].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[3].subsections[2].subheading"),
              description: this.$t("front.termsUse.sections[3].subsections[2].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[3].subsections[3].subheading"),
              description: this.$t("front.termsUse.sections[3].subsections[3].description"),
            }
          ] 
        },
        {
          heading: this.$t("front.termsUse.sections[4].heading"),
          description: this.$t("front.termsUse.sections[4].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[5].heading"),
          subsections: [
            {
              subheading: this.$t("front.termsUse.sections[5].subsections[0].subheading"),
              description: this.$t("front.termsUse.sections[5].subsections[0].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[5].subsections[1].subheading"),
              description: this.$t("front.termsUse.sections[5].subsections[1].description"),
            },
          ] 
        },
        {
          heading: this.$t("front.termsUse.sections[6].heading"),
          description: this.$t("front.termsUse.sections[6].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[7].heading"),
          description: this.$t("front.termsUse.sections[7].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[8].heading"),
          description: this.$t("front.termsUse.sections[8].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[9].heading"),
          description: this.$t("front.termsUse.sections[9].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[10].heading"),
          subsections: [
            {
              subheading: this.$t("front.termsUse.sections[10].subsections[0].subheading"),
              description: this.$t("front.termsUse.sections[10].subsections[0].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[10].subsections[1].subheading"),
              description: this.$t("front.termsUse.sections[10].subsections[1].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[10].subsections[2].subheading"),
              description: this.$t("front.termsUse.sections[10].subsections[2].description"),
            },
          ] 
        },
        {
          heading: this.$t("front.termsUse.sections[11].heading"),
          description: this.$t("front.termsUse.sections[11].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[12].heading"),
          description: this.$t("front.termsUse.sections[12].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[13].heading"),
          description: this.$t("front.termsUse.sections[13].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[14].heading"),
          description: this.$t("front.termsUse.sections[14].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[15].heading"),
          description: this.$t("front.termsUse.sections[15].description"),
        },
        {
          heading: this.$t("front.termsUse.sections[16].heading"),
          subsections: [
            {
              subheading: this.$t("front.termsUse.sections[16].subsections[0].subheading"),
              description: this.$t("front.termsUse.sections[16].subsections[0].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[16].subsections[1].subheading"),
              description: this.$t("front.termsUse.sections[16].subsections[1].description"),
            },
            {
              subheading: this.$t("front.termsUse.sections[16].subsections[2].subheading"),
              description: this.$t("front.termsUse.sections[16].subsections[2].description"),
            },
          ] 
        },
      ];
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>

<style lang="scss" scoped>
.subheading {
  margin-top: 1rem;
  text-decoration: underline;
}
</style>