import { createRouter, createWebHistory } from 'vue-router'
import store from '@mizogoo/store/index'

// Front
import StylePage from '@mizogoo/components/front/pages/StylePage/Index'

import HomePage from '@mizogoo/components/front/pages/HomePage/Index'
import AppPage from '@mizogoo/components/front/pages/AppPage/Index'
import AppEnrichedPage from '@mizogoo/components/front/pages/AppPage/AppEnriched'
import AppWinepairingPage from '@mizogoo/components/front/pages/AppPage/AppWinepairing'
import AppGamesPage from '@mizogoo/components/front/pages/AppPage/AppGames'
import PdfMenuPage from '@mizogoo/components/front/pages/PdfMenuPage/Index'
import CreatePage from '@mizogoo/components/front/pages/CreatePage/Index'
import PrintingPage from '@mizogoo/components/front/pages/PrintingPage/Index'
import TranslationPage from '@mizogoo/components/front/pages/TranslationPage/Index'
import AnalysisPage from '@mizogoo/components/front/pages/AnalysisPage/Index'
import CatalogPage from '@mizogoo/components/front/pages/CatalogPage/Index'
import ProductPage from '@mizogoo/components/front/pages/ProductPage/Index'
import ProductTemplatePage from '@mizogoo/components/front/pages/ProductTemplatePage/Index'
import ProductDoItForMePage from '@mizogoo/components/front/pages/ProductDoItForMePage/ProductDoItForMe'
import ProductPrintMyFilePage from '@mizogoo/components/front/pages/ProductPrintMyFilePage/ProductPrintMyFile'
import SubscribtionPage from '@mizogoo/components/front/pages/SubscribtionPage/Index'
import AboutUsPage from '@mizogoo/components/front/pages/AboutUsPage/Index'
import ContactPage from '@mizogoo/components/front/pages/ContactPage/Index'
import ReviewPageSite from '@mizogoo/components/front/pages/ReviewPage/Site'
import ReviewPageCreation from '@mizogoo/components/front/pages/ReviewPage/Creation'
import WebinarPageSubscription from '@mizogoo/components/front/pages/WebinarPage/Subscription'
import PromotionPage from '@mizogoo/components/front/pages/PromotionPage/Index'
import PromotionPagePromotion from '@mizogoo/components/front/pages/PromotionPage/Promotion'

import BlogPage from '@mizogoo/components/front/pages/BlogPage/Index'
import ArticlePage from '@mizogoo/components/front/pages/BlogPage/Article'

import SocialCommitmentsPage from '@mizogoo/components/front/pages/SocialCommitmentsPage/Index'

import SecurityPageLogin from '@mizogoo/components/front/pages/SecurityPage/Login'
import SecurityPagePasswordReset from '@mizogoo/components/front/pages/SecurityPage/PasswordReset'
import SecurityPagePasswordResetToken from '@mizogoo/components/front/pages/SecurityPage/PasswordResetToken'
import SecurityRegistration from '@mizogoo/components/front/pages/SecurityPage/Registration'
import SecurityPageRegistrationValidation from '@mizogoo/components/front/pages/SecurityPage/RegistrationValidation'
import SecurityPageRegistrationValidationEmail from '@mizogoo/components/front/pages/SecurityPage/RegistrationValidationEmail'

import CartPage from '@mizogoo/components/front/pages/CartPage/Index'
import CartPageAddress from '@mizogoo/components/front/pages/CartPage/Address'
import CartPageCommand from '@mizogoo/components/front/pages/CartPage/Command'
import CartPageValidated from '@mizogoo/components/front/pages/CartPage/Validated'

import LegalPage from '@mizogoo/components/front/pages/LegalPage/Index'
import TermsUsePage from '@mizogoo/components/front/pages/TermsUsePage/Index'
import TermsSalePage from '@mizogoo/components/front/pages/TermsSalePage/Index'
import PrivacyPage from '@mizogoo/components/front/pages/PrivacyPage/Index'

import RedirectMenubuilderPage from '@mizogoo/components/front/pages/RedirectPages/RedirectMenubuilder'
import RedirectCartPage from '@mizogoo/components/front/pages/RedirectPages/RedirectCart'

// Workspace
const DashboardPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/DashboardPage/Index')

const MyCreationPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyCreationPage/Index')
const MyCreationPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyCreationPage/Create')
const MyCreationPagePrint = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyCreationPage/Print')
const MyCreationPageMockup = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyCreationPage/Mockup')
const MyAppPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyAppPage/Index')
const AddProductTypeSelection = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyAppPage/Product/AddProductTypeSelection')
const ImportProductTypeSelection = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyAppPage/Product/ImportProductTypeSelection')

const MyDishPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDishPage/Create')
const MyDishPageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDishPage/Update')
const MyDishPageImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDishPage/Import')
const MyDishPageCreateMultiple = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDishPage/CreateMultiple')

const MyWinePageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyWinePage/Create')
const MyWinePageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyWinePage/Update')
const MyWinePageImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyWinePage/Import')
const MyWinePageCreateMultiple = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyWinePage/CreateMultiple')

const MyBeerPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyBeerPage/Create')
const MyBeerPageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyBeerPage/Update')
const MyBeerPageImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyBeerPage/Import')
const MyBeerPageCreateMultiple = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyBeerPage/CreateMultiple')

const MyDrinkPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDrinkPage/Create')
const MyDrinkPageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDrinkPage/Update')
const MyDrinkPageImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDrinkPage/Import')
const MyDrinkPageCreateMultiple = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyDrinkPage/CreateMultiple')

const MyMenuPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyMenuPage/Create')
const MyMenuPageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyMenuPage/Update')

const MyContestPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyContestPage/Create')
const MyContestPageDetail = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyContestPage/Detail')
const MyContestPageVerify = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyContestPage/Verify')

// import MyOfferPage from '@mizogoo/components/workspace/pages/MyOfferPage/Index'
// import MyOfferPageCreate from '@mizogoo/components/workspace/pages/MyOfferPage/Create'
// import MyOfferPageUpdate from '@mizogoo/components/workspace/pages/MyOfferPage/Update'

const MyPdfMenuPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyPdfMenuPage/Index')
const MyPdfMenuImport = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyPdfMenuPage/Document/PdfMenuImport')

const MyAnalysisPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/AnalysisPage/Index')
const MyAnalysisPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/AnalysisPage/Create')

const MyAccountPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyAccountPage/Index')

const MyOrderPageView = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyOrderPage/View')
const MyOrderPageUpdateAddress = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyOrderPage/UpdateAddress')

const MyAddressPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyAddressPage/Create')
const MyAddressPageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyAddressPage/Update')

const MyInstitutionPageCreate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyInstitutionPage/Create')
const MyInstitutionPageUpdate = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/MyInstitutionPage/Update')

const HelpCenterPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/HelpPage/HelpCenter')
const HelpCenterCategoryPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/HelpPage/HelpCategory')
const HelpCenterArticlePage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/HelpPage/HelpArticle')
const HelpCenterContactPage = () => import(/* webpackPrefetch: true */ /* webpackChunkName: "dashboard-section" */ '@mizogoo/components/workspace/pages/HelpPage/HelpCenterContact')

// QrCode
import InstitutionHomePage from '@mizogoo/components/qrcode/pages/InstitutionHomePage/Index'

export const router = createRouter({
  history: createWebHistory(),
  // Manage the scroll behavior
  scrollBehavior (to, from, savedPosition) {
    // Return to saved position when using back/forward buttons (browser arrows)
    if (savedPosition) {
      return savedPosition
      // Enables navigation to the anchor
    } else if (to.hash) {
      return { el: to.hash }
      // Reset the scroll to the top of the page when switching from one to another
    } else {
      return { top: 0, left: 0 }
    }
  },
  routes: [
    // FRONT
    { name: 'style', path: '/style', component: StylePage },
    { name: 'home', path: '/', component: HomePage },
    { name: 'app', path: '/application-mobile', component: AppPage },
    { name: 'app_enriched', path: '/application-contenus-enrichis', component: AppEnrichedPage },
    { name: 'app_winepairing', path: '/application-accords-mets-vins', component: AppWinepairingPage },
    { name: 'app_games', path: '/application-jeux', component: AppGamesPage },
    { name: 'pdf_menu', path: '/menus-pdf-par-qrcode', component: PdfMenuPage },
    { name: 'create', path: '/creation-menu-gratuit', component: CreatePage },
    { name: 'printing', path: '/impression-carte-restaurant', component: PrintingPage },
    { name: 'translation', path: '/traduction-automatique', component: TranslationPage },
    { name: 'analysis', path: '/analyse-de-votre-offre', component: AnalysisPage },
    { name: 'catalog', path: '/catalogue/:category?', component: CatalogPage },
    { name: 'product', path: '/produit/:id/:urlName?', component: ProductPage },
    { name: 'product_template', path: '/template/:productId', component: ProductTemplatePage },
    { name: 'product_do_it_for_me', path: '/faites-le-pour-moi/:productId', component: ProductDoItForMePage },
    { name: 'product_print_my_file', path: '/imprimer-mon-fichier/:productId', component: ProductPrintMyFilePage },
    { name: 'subscription', path: '/nos-formules', component: SubscribtionPage },
    { name: 'about_us', path: '/qui-sommes-nous', component: AboutUsPage },
    { name: 'contact', path: '/contact', component: ContactPage },
    { name: 'blog', path: '/blog', component: BlogPage },
    { name: 'article', path: '/blog/article/:id/:name', component: ArticlePage },
    { name: 'social_commitments', path: '/engagements-sociaux-et-environnementaux', component: SocialCommitmentsPage },
    { name: 'legal', path: '/mentions-legales', component: LegalPage },
    { name: 'terms_use', path: '/conditions-generales-utilisation', component: TermsUsePage },
    { name: 'terms_sale', path: '/conditions-generales-vente', component: TermsSalePage },
    { name: 'privacy', path: '/politique-confidentialite', component: PrivacyPage },
    { name: 'review_page_site', path: '/avis/site', component: ReviewPageSite },
    { name: 'review_page_creation', path: '/avis/creation', component: ReviewPageCreation },
    { name: 'webinar_page_subscription', path: '/webinaire/inscription', component: WebinarPageSubscription },
    { name: 'promotions', path: '/nos-promotions', component: PromotionPage },
    { name: 'promotion', path: '/nos-promotions/:id/:code', component: PromotionPagePromotion },

    { name: 'login', path: '/connexion', component: SecurityPageLogin, meta: { requiresAnonymous: true } },
    { name: 'password_reset', path: '/mot-de-passe-oublie', component: SecurityPagePasswordReset, meta: { requiresAnonymous: true } },
    { name: 'password_reset_token', path: '/mot-de-passe-oublie/token/:token', component: SecurityPagePasswordResetToken, meta: { requiresAnonymous: true } },
    { name: 'registration', path: '/inscription', component: SecurityRegistration, meta: { requiresAnonymous: true } },
    { name: 'registration_validation', path: '/inscription/validation/:token', component: SecurityPageRegistrationValidation, meta: { requiresAnonymous: true } },
    { name: 'registration_validation_email', path: '/inscription/validation/email/:token', component: SecurityPageRegistrationValidationEmail, meta: { requiresAnonymous: true } },

    { name: 'cart', path: '/mon-panier', component: CartPage },
    { name: 'cart_address', path: '/mon-panier/livraison', component: CartPageAddress, meta: { requiresAuth: true } },
    { name: 'cart_command', path: '/mon-panier/commander', component: CartPageCommand, meta: { requiresAuth: true } },
    { name: 'cart_validated', path: '/mon-panier/confirmation/:reference', component: CartPageValidated, meta: { requiresAuth: true } },
    // Cart (fake route, redirects to the real one, but added here to be intagrated into connection redirection logic)
    { name: 'redirect_cart', path: '/redirect-cart', component: RedirectCartPage, meta: { requiresAuth: true } },

    // WORKSPACE
    { name: 'workspace', path: '/workspace/dashboard', component: DashboardPage, meta: { workspace: true, requiresAuth: true } },

    // Creations
    { name: 'my_creation', path: '/workspace/mes-creations', component: MyCreationPage, meta: { workspace: true, requiresAuth: true } },
    { name: 'my_creation_create', path: '/workspace/mes-creations/ajouter', component: MyCreationPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_creation' } },
    { name: 'my_creation_print', path: '/workspace/mes-creations/:id/imprimer/:mokup?', component: MyCreationPagePrint, meta: { workspace: true, requiresAuth: true, parent: 'my_creation' } },
    { name: 'my_creation_mockup', path: '/workspace/mes-creations/:id/maquetter', component: MyCreationPageMockup, meta: { workspace: true, requiresAuth: true, parent: 'my_creation' } },

    // App
    { name: 'my_app', path: '/workspace/application', component: MyAppPage, meta: { workspace: true, requiresAuth: true } },
    { name: 'my_product_create', path: '/workspace/mes-produits/ajouter', component: AddProductTypeSelection, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_product_import', path: '/workspace/mes-produits/importer', component: ImportProductTypeSelection, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    { name: 'my_dish_create', path: '/workspace/mes-produits/ajouter/plat/:categoryId?', component: MyDishPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_dish_duplicate', path: '/workspace/mes-produits/dupliquer/plat/:id', component: MyDishPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_dish_update', path: '/workspace/mes-produits/modifier/plat/:id', component: MyDishPageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_dish_import', path: '/workspace/mes-produits/importer/plat', component: MyDishPageImport, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_dish_create_multiple', path: '/workspace/mes-produits/ajouter/plats', component: MyDishPageCreateMultiple, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    { name: 'my_wine_create', path: '/workspace/mes-produits/ajouter/vin/:categoryId?', component: MyWinePageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_wine_duplicate', path: '/workspace/mes-produits/dupliquer/vin/:id', component: MyWinePageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_wine_update', path: '/workspace/mes-produits/modifier/vin/:id', component: MyWinePageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_wine_import', path: '/workspace/mes-produits/importer/vin', component: MyWinePageImport, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_wine_create_multiple', path: '/workspace/mes-produits/ajouter/vins', component: MyWinePageCreateMultiple, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    { name: 'my_beer_create', path: '/workspace/mes-produits/ajouter/biere/:categoryId?', component: MyBeerPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_beer_duplicate', path: '/workspace/mes-produits/dupliquer/biere/:id', component: MyBeerPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_beer_update', path: '/workspace/mes-produits/modifier/biere/:id', component: MyBeerPageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_beer_import', path: '/workspace/mes-produits/importer/biere', component: MyBeerPageImport, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_beer_create_multiple', path: '/workspace/mes-produits/ajouter/bieres', component: MyBeerPageCreateMultiple, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    { name: 'my_drink_create', path: '/workspace/mes-produits/ajouter/boisson/:categoryId?', component: MyDrinkPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_drink_duplicate', path: '/workspace/mes-produits/dupliquer/boisson/:id', component: MyDrinkPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_drink_update', path: '/workspace/mes-produits/modifier/boisson/:id', component: MyDrinkPageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_drink_import', path: '/workspace/mes-produits/importer/boisson', component: MyDrinkPageImport, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_drink_create_multiple', path: '/workspace/mes-produits/ajouter/boissons', component: MyDrinkPageCreateMultiple, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    { name: 'my_menu_create', path: '/workspace/mes-produits/ajouter/menu/:categoryId?', component: MyMenuPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_menu_duplicate', path: '/workspace/mes-produits/dupliquer/menu/:id', component: MyMenuPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_menu_update', path: '/workspace/mes-produits/modifier/menu/:id', component: MyMenuPageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    { name: 'my_contest_create', path: '/workspace/mes-jeux-concours/ajouter', component: MyContestPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_contest_detail', path: '/workspace/mes-jeux-concours/details/:id', component: MyContestPageDetail, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },
    { name: 'my_contest_verify', path: '/workspace/mes-jeux-concours/verifier/:id/ticket/:hash', component: MyContestPageVerify, meta: { workspace: true, requiresAuth: true, parent: 'my_app' } },

    // PdfMenu
    { name: 'my_pdf_menu', path: '/workspace/menu-pdf', component: MyPdfMenuPage, meta: { workspace: true, requiresAuth: true } },
    { name: 'my_pdf_menu_import', path: '/workspace/menu-pdf/importer', component: MyPdfMenuImport, meta: { workspace: true, requiresAuth: true, parent: 'my_pdf_menu' } },

    // Analysis
    { name: 'my_analysis', path: '/workspace/analyse', component: MyAnalysisPage, meta: { workspace: true, requiresAuth: true } },
    { name: 'my_analysis_create', path: '/workspace/analyse/ajouter', component: MyAnalysisPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_analysis' } },

    // Account
    { name: 'my_account', path: '/workspace/mon-compte/:tabIndex?', component: MyAccountPage, meta: { workspace: true, requiresAuth: true } },

    { name: 'my_order_view', path: '/workspace/mes-commandes/voir/:id', component: MyOrderPageView, meta: { workspace: true, requiresAuth: true } },
    { name: 'my_order_update_address', path: '/workspace/mes-commandes/modifier/:id/adresse/', component: MyOrderPageUpdateAddress, meta: { workspace: true, requiresAuth: true, parent: 'my_account' } },

    { name: 'my_address_create', path: '/workspace/mes-adresses/ajouter', component: MyAddressPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_account' } },
    { name: 'my_address_update', path: '/workspace/mes-adresses/modifier/:id', component: MyAddressPageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_account' } },

    { name: 'my_institution_create', path: '/workspace/mes-restaurants/ajouter', component: MyInstitutionPageCreate, meta: { workspace: true, requiresAuth: true, parent: 'my_account' } },
    { name: 'my_institution_update', path: '/workspace/mes-restaurants/modifier/:id', component: MyInstitutionPageUpdate, meta: { workspace: true, requiresAuth: true, parent: 'my_account' } },

    // Help Center
    { name: 'help_center', path: '/workspace/centre-aide', component: HelpCenterPage, meta: { workspace: true, requiresAuth: true } },
    { name: 'help_center_category', path: '/workspace/centre-aide/categorie/:id', component: HelpCenterCategoryPage, meta: { workspace: true, requiresAuth: true, parent: 'help_center' } },
    { name: 'help_center_article', path: '/workspace/centre-aide/article/:id', component: HelpCenterArticlePage, meta: { workspace: true, requiresAuth: true, parent: 'help_center' } },
    { name: 'help_center_contact', path: '/workspace/centre-aide/contact', component: HelpCenterContactPage, meta: { workspace: true, requiresAuth: true, parent: 'help_center' } },

    // QrCode
    { name: 'institution_home', path: '/pdfmenu/institution/:hash', component: InstitutionHomePage, meta: { isQrCode: true } },

    // Menubuilder (fake route, redirects to the real one, but added here to be intagrated into connection redirection logic)
    { name: 'redirect_menubuilder', path: '/redirect-menubuilder', component: RedirectMenubuilderPage, meta: { requiresAuth: true } },

    { path: '/:pathMatch(.*)*', redirect: '/' }
  ]
})

router.beforeEach((to, from, next) => {
  if (store.getters['security/isAuthenticated'] == null) {
    const isAuthenticated = JSON.parse(atob(document.getElementById('mizogoo').getAttribute('data-is-authenticated')))
    const user = JSON.parse(atob(document.getElementById('mizogoo').getAttribute('data-user')))

    store.dispatch('security/onRefresh', { isAuthenticated: isAuthenticated, user: user })
  }

  // Check if the route requires authentication
  if (to.meta.requiresAuth) {
    if (store.getters['security/isAuthenticated']) {
      // If the user is authenticated, check if there is a route saved before login
      const redirectAfterLogin = JSON.parse(localStorage.getItem('redirectAfterLogin'));
      localStorage.removeItem('redirectAfterLogin');

      if (redirectAfterLogin) {
        // Redirect to the saved route
        next({ name: redirectAfterLogin.name, params: redirectAfterLogin.params });
      } else {
        next();
      }
    } else {
      // Saves the requested route before redirect to login
      localStorage.setItem('redirectAfterLogin', JSON.stringify({ name: to.name, params: to.params }));
      next({ name: 'login' });
    }

  // Routes that request to be anonymous
  } else if (to.meta.requiresAnonymous) {
    if (store.getters['security/isAuthenticated']) {
      next({ name: 'workspace' }); // If the user is aloreay logged in, redirect to the workspace
    } else {
      next(); // If not, access to the requested route
    }

  // Routes without any restrictions
  } else {
    next(); // No redirect, free access anytime
  }

})

router.afterEach((to) => {
  // Met à jour les états globaux en fonction de la route, mais **après** la navigation
  if (to.meta.isQrCode) {
    store.dispatch('global/fetchIsQrCode', true);
    store.dispatch('global/fetchIsWorkspace', false);
  } else if (to.meta.workspace) {
    store.dispatch('global/fetchIsWorkspace', true);
    store.dispatch('global/fetchIsQrCode', false);
  } else {
    store.dispatch('global/fetchIsWorkspace', false);
    store.dispatch('global/fetchIsQrCode', false);
  }
});

