import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/drink/category", Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/drink/category/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/drink/category/update/" + data.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/drink/category/remove/" + data, Tool.createFormData());
  },
  analyse (data) {
    return axios.post("/api/account/drink/category/analyse/" + data.id, Tool.createFormData());
  },
  toggleDrinksActivation (data) {
    return axios.post("/api/account/drink/category/toggle_drinks_activation/" + data.drinkCategory.id, Tool.createFormData(data));
  }
};
