// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps[data-v-500a8cba] {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.steps-inner-container[data-v-500a8cba] {
  position: relative;
  padding: 4rem 0;
  overflow: hidden;
}
.steps-inner-container .row[data-v-500a8cba] {
  display: flex;
  flex-wrap: wrap;
}
.steps-inner-container .row [class*=col-][data-v-500a8cba] {
  display: flex;
  flex-direction: column;
}
.absolute-line[data-v-500a8cba] {
  position: absolute;
  top: 2.5rem;
  left: -1rem;
}
.heading[data-v-500a8cba] {
  text-align: center;
}
@media screen and (max-width: 992px) {
.steps-inner-container[data-v-500a8cba] {
    padding-top: 2rem;
}
.absolute-line[data-v-500a8cba] {
    display: none;
}
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/layout/features/steps/Steps.vue","webpack://./Steps.vue"],"names":[],"mappings":"AAKA;EACE,iBAAA;EACA,oBAAA;ACJF;ADMA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;ACHF;ADKA;EACE,aAAA;EACA,eAAA;ACFF;ADIA;EACE,aAAA;EACA,sBAAA;ACDF;ADGA;EACE,kBAAA;EACA,WAAA;EACA,WAAA;ACAF;ADGA;EACE,kBAAA;ACAF;ADGA;AACE;IACE,iBAAA;ACAF;ADEA;IACE,aAAA;ACAF;AACF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.steps {\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n}\n.steps-inner-container {\n  position: relative;\n  padding: 4rem 0;\n  overflow: hidden;\n}\n.steps-inner-container .row {\n  display: flex;\n  flex-wrap: wrap;\n}\n.steps-inner-container .row [class*='col-'] {\n  display: flex;\n  flex-direction: column;\n}\n.absolute-line {\n  position: absolute;\n  top: 2.5rem;\n  left: -1rem;\n}\n\n.heading {\n  text-align: center;\n}\n\n@media screen and (max-width: 992px) {\n  .steps-inner-container {\n    padding-top: 2rem;\n  }\n  .absolute-line {\n    display: none;\n  }\n}\n",".steps {\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n}\n\n.steps-inner-container {\n  position: relative;\n  padding: 4rem 0;\n  overflow: hidden;\n}\n\n.steps-inner-container .row {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.steps-inner-container .row [class*=col-] {\n  display: flex;\n  flex-direction: column;\n}\n\n.absolute-line {\n  position: absolute;\n  top: 2.5rem;\n  left: -1rem;\n}\n\n.heading {\n  text-align: center;\n}\n\n@media screen and (max-width: 992px) {\n  .steps-inner-container {\n    padding-top: 2rem;\n  }\n  .absolute-line {\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
