import SecurityAPI from "../api/security";

const
  FETCH_LOGIN = "FETCH_LOGIN",
  FETCH_LOGIN_SUCCESS = "FETCH_LOGIN_SUCCESS",
  FETCH_LOGIN_ERROR = "FETCH_LOGIN_ERROR",

  FETCHING_USER = "FETCHING_USER",

  FETCHING_USER_INSTITUTION = "FETCHING_USER_INSTITUTION",

  PROVIDING_DATA_ON_REFRESH_SUCCESS = "PROVIDING_DATA_ON_REFRESH_SUCCESS",

  FETCH_PASSWORD_RESET = "FETCH_PASSWORD_RESET",
  FETCH_PASSWORD_RESET_SUCCESS = "FETCH_PASSWORD_RESET_SUCCESS",
  FETCH_PASSWORD_RESET_ERROR = "FETCH_PASSWORD_RESET_ERROR",

  FETCH_PASSWORD_RESET_TOKEN = "FETCH_PASSWORD_RESET_TOKEN",
  FETCH_PASSWORD_RESET_TOKEN_SUCCESS = "FETCH_PASSWORD_RESET_TOKEN_SUCCESS",
  FETCH_PASSWORD_RESET_TOKEN_ERROR = "FETCH_PASSWORD_RESET_TOKEN_ERROR",

  FETCH_REGISTRATION = "FETCH_REGISTRATION",
  FETCH_REGISTRATION_SUCCESS = "FETCH_REGISTRATION_SUCCESS",
  FETCH_REGISTRATION_ERROR = "FETCH_REGISTRATION_ERROR",

  FETCH_REGISTRATION_VALIDATION = "FETCH_REGISTRATION_VALIDATION",
  FETCH_REGISTRATION_VALIDATION_SUCCESS = "FETCH_REGISTRATION_VALIDATION_SUCCESS",
  FETCH_REGISTRATION_VALIDATION_ERROR = "FETCH_REGISTRATION_VALIDATION_ERROR",

  FETCH_REGISTRATION_VALIDATION_EMAIL = "FETCH_REGISTRATION_VALIDATION_EMAIL",
  FETCH_REGISTRATION_VALIDATION_EMAIL_SUCCESS = "FETCH_REGISTRATION_VALIDATION_EMAIL_SUCCESS",
  FETCH_REGISTRATION_VALIDATION_EMAIL_ERROR = "FETCH_REGISTRATION_VALIDATION_EMAIL_ERROR",

  ADD_ONBOARDING_INTO_USER = "ADD_ONBOARDING_INTO_USER";

export default {
  namespaced: true,
  state: {
    isAuthenticated: null,
    user: null,
    // Login
    isLoadingLogin: false,
    errorLogin: null,
    // Password Reset
    isLoadingPasswordReset: false,
    errorPasswordReset: null,
    // Password Reset Token
    isLoadingPasswordResetToken: false,
    errorPasswordResetToken: null,
    // Registration
    isLoadingRegistration: false,
    errorRegistration: null,
    // Registration Validation
    isLoadingRegistrationValidation: false,
    errorRegistrationValidation: null,
    // Registration Validation Email
    isLoadingRegistrationValidationEmail: false,
    errorRegistrationValidationEmail: null,
  },
  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    user(state) {
      return state.user;
    },
    hasRole(state) {
      return role => {
        return state.user.roles.indexOf(role) !== -1;
      }
    },
    hasActivePremiumSubscription(state) {
      let isPremium = false;
      if (state.user) {
        if (state.user.currentSubscription !== null) {
          if (state.user.currentSubscription.isValid) {
            isPremium = true;
          }
        }
      }
      return isPremium;
    },
    hasAccessFunctionality(state) {
      return functionality => {
        if (
          state.user.isFreePremiumTrialValid ||
          (state.user.currentSubscription &&
            state.user.currentSubscription.stripeSubscription &&
            state.user.currentSubscription.stripeSubscription.status ===
              "trialing")
        ) {
          return true;
        }
        if (state.user.currentSubscription !== null && state.user.currentSubscription.isValid) {
          return state.user.currentSubscription.subscription.functionalities.indexOf(functionality) !== -1;
        } else {
          return false
        }
      }
    },
    // Login
    isLoadingLogin(state) {
      return state.isLoadingLogin;
    },
    hasErrorLogin(state) {
      return state.errorLogin !== null;
    },
    errorLogin(state) {
      return state.errorLogin;
    },
    // Password Reset
    isLoadingPasswordReset(state) {
      return state.isLoadingPasswordReset;
    },
    hasErrorPasswordReset(state) {
      return state.errorPasswordReset !== null;
    },
    errorPasswordReset(state) {
      return state.errorPasswordReset;
    },
    // Password Reset Token
    isLoadingPasswordResetToken(state) {
      return state.isLoadingPasswordResetToken;
    },
    hasErrorPasswordResetToken(state) {
      return state.errorPasswordResetToken !== null;
    },
    errorPasswordResetToken(state) {
      return state.errorPasswordResetToken;
    },
    // Registration
    isLoadingRegistration(state) {
      return state.isLoadingRegistration;
    },
    hasErrorRegistration(state) {
      return state.errorRegistration !== null;
    },
    errorRegistration(state) {
      return state.errorRegistration;
    },
    // Registration Validation
    isLoadingRegistrationValidation(state) {
      return state.isLoadingRegistrationValidation;
    },
    hasErrorRegistrationValidation(state) {
      return state.errorRegistrationValidation !== null;
    },
    errorRegistrationValidation(state) {
      return state.errorRegistrationValidation;
    },
    // Registration Validation Email
    isLoadingRegistrationValidationEmail(state) {
      return state.isLoadingRegistrationValidationEmail;
    },
    hasErrorRegistrationValidationEmail(state) {
      return state.errorRegistrationValidationEmail !== null;
    },
    errorRegistrationValidationEmail(state) {
      return state.errorRegistrationValidationEmail;
    },
  },
  mutations: {
    // Login
    [FETCH_LOGIN](state) {
      state.isLoadingLogin = true;
      state.errorLogin = null;
      state.isAuthenticated = false;
      state.user = null;
    },
    [FETCH_LOGIN_SUCCESS](state, user) {
      state.isLoadingLogin = false;
      state.errorLogin = null;
      state.isAuthenticated = true;
      state.user = user;
    },
    [FETCH_LOGIN_ERROR](state, error) {
      state.isLoadingLogin = false;
      state.errorLogin = error;
      state.isAuthenticated = false;
      state.user = null;
    },
    [FETCHING_USER](state, user) {
      state.user = user
    },
    [FETCHING_USER_INSTITUTION](state, institutions) {
      state.user.institutions = institutions
    },
    [PROVIDING_DATA_ON_REFRESH_SUCCESS](state, payload) {
      state.isLoadingLogin = false;
      state.errorLogin = null;
      state.isAuthenticated = payload.isAuthenticated;
      state.user = payload.user;
    },
    // Password Reset
    [FETCH_PASSWORD_RESET](state) {
      state.isLoadingPasswordReset = true;
      state.errorPasswordReset = null;
    },
    [FETCH_PASSWORD_RESET_SUCCESS](state) {
      state.isLoadingPasswordReset = false;
      state.errorPasswordReset = null;
    },
    [FETCH_PASSWORD_RESET_ERROR](state, error) {
      state.isLoadingPasswordReset = false;
      state.errorPasswordReset = error;
    },
    // Password Reset Token
    [FETCH_PASSWORD_RESET_TOKEN](state) {
      state.isLoadingPasswordResetToken = true;
      state.errorPasswordResetToken = null;
    },
    [FETCH_PASSWORD_RESET_TOKEN_SUCCESS](state) {
      state.isLoadingPasswordResetToken = false;
      state.errorPasswordResetToken = null;
    },
    [FETCH_PASSWORD_RESET_TOKEN_ERROR](state, error) {
      state.isLoadingPasswordResetToken = false;
      state.errorPasswordResetToken = error;
    },
    // Registration
    [FETCH_REGISTRATION](state) {
      state.isLoadingRegistration = true;
      state.errorRegistration = null;
    },
    [FETCH_REGISTRATION_SUCCESS](state) {
      state.isLoadingRegistration = false;
      state.errorRegistration = null;
    },
    [FETCH_REGISTRATION_ERROR](state, error) {
      state.isLoadingRegistration = false;
      state.errorRegistration = error;
    },
    // Registration Validation
    [FETCH_REGISTRATION_VALIDATION](state) {
      state.isLoadingRegistrationValidation = true;
      state.errorRegistrationValidation = null;
    },
    [FETCH_REGISTRATION_VALIDATION_SUCCESS](state) {
      state.isLoadingRegistrationValidation = false;
      state.errorRegistrationValidation = null;
    },
    [FETCH_REGISTRATION_VALIDATION_ERROR](state, error) {
      state.isLoadingRegistrationValidation = false;
      state.errorRegistrationValidation = error;
    },
    // Registration Validation Email
    [FETCH_REGISTRATION_VALIDATION_EMAIL](state) {
      state.isLoadingRegistrationValidationEmail = true;
      state.errorRegistrationValidationEmail = null;
    },
    [FETCH_REGISTRATION_VALIDATION_EMAIL_SUCCESS](state) {
      state.isLoadingRegistrationValidationEmail = false;
      state.errorRegistrationValidationEmail = null;
    },
    [FETCH_REGISTRATION_VALIDATION_EMAIL_ERROR](state, error) {
      state.isLoadingRegistrationValidationEmail = false;
      state.errorRegistrationValidationEmail = error;
    },
    // Add item into category
    [ADD_ONBOARDING_INTO_USER](state, onboarding) {
      let userOnboardings = state.user.onboardings;
      userOnboardings.push(onboarding);
    },
  },
  actions: {
    // Login
    async login({ commit }, payload) {
      commit(FETCH_LOGIN);
      try {
        let response = await SecurityAPI.login(payload.form);
        if (response.data.success === true) {
          commit(FETCH_LOGIN_SUCCESS, response.data.user);
          return response.data.user;
        } else {
          commit(FETCH_LOGIN_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(FETCH_LOGIN_ERROR, error);
        return null;
      }
    },
    fetchUser({ commit }, user) {
      commit('FETCHING_USER', user)
    },
    fetchUserInstitutions({ commit }, institutions) {
      commit('FETCHING_USER_INSTITUTION', institutions)
    },
    onRefresh({ commit }, payload) {
      commit(PROVIDING_DATA_ON_REFRESH_SUCCESS, payload);
    },
    // Password Reset
    async passwordReset({ commit }, payload) {
      commit(FETCH_PASSWORD_RESET);
      try {
        let response = await SecurityAPI.passwordReset(payload.form);
        if (response.data.success === true) {
          commit(FETCH_PASSWORD_RESET_SUCCESS);
          return response.data;
        } else {
          commit(FETCH_PASSWORD_RESET_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(FETCH_PASSWORD_RESET_ERROR, error);
        return null;
      }
    },
    // Password Reset Token
    async passwordResetToken({ commit }, payload) {
      commit(FETCH_PASSWORD_RESET_TOKEN);
      try {
        let response = await SecurityAPI.passwordResetToken(payload);
        if (response.data.success === true) {
          commit(FETCH_PASSWORD_RESET_TOKEN_SUCCESS);
          return response.data;
        } else {
          commit(FETCH_PASSWORD_RESET_TOKEN_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_PASSWORD_RESET_TOKEN_ERROR, error);
        return null;
      }
    },
    // Registration
    async registration({ commit }, payload) {
      commit(FETCH_REGISTRATION);
      try {
        let response = await SecurityAPI.registration(payload.form);
        if (response.data.success === true) {
          commit(FETCH_REGISTRATION_SUCCESS);
          return response.data;
        } else {
          commit(FETCH_REGISTRATION_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_REGISTRATION_ERROR, error);
        return null;
      }
    },
    // Registration Validation
    async registrationValidation({ commit }, payload) {
      commit(FETCH_REGISTRATION_VALIDATION);
      try {
        let response = await SecurityAPI.registrationValidation(payload);
        if (response.data.success === true) {
          commit(FETCH_REGISTRATION_VALIDATION_SUCCESS);
          return response.data;
        } else {
          commit(FETCH_REGISTRATION_VALIDATION_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_REGISTRATION_VALIDATION_ERROR, error);
        return null;
      }
    },
    // Registration Validation Email
    async registrationValidationEmail({ commit }, payload) {
      commit(FETCH_REGISTRATION_VALIDATION_EMAIL);
      try {
        let response = await SecurityAPI.registrationValidationEmail(payload);
        if (response.data.success === true) {
          commit(FETCH_REGISTRATION_VALIDATION_EMAIL_SUCCESS);
          return response.data;
        } else {
          commit(FETCH_REGISTRATION_VALIDATION_EMAIL_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_REGISTRATION_VALIDATION_EMAIL_ERROR, error);
        return null;
      }
    },
    async addOnboardingIntoUser({ commit }, payload) {
      commit(ADD_ONBOARDING_INTO_USER, payload.onboarding);
    },
  }
};
