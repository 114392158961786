import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findOne (institutionAppPersonalization) {
    return axios.post("/api/account/institution/app/personalization/" + institutionAppPersonalization, Tool.createFormData());
  },
  update (data) {
    return axios.post("/api/account/institution/app/personalization/update", Tool.createFormData(data));
  },
};
