import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findMainCategory() {
    return axios.post("/api/help/center/category/main", Tool.createFormData());
  },
  findCategory(categoryId) {
    return axios.post("/api/help/center/category/" + categoryId, Tool.createFormData());
  },
  findAllArticle() {
    return axios.post("/api/help/center/article", Tool.createFormData());
  },
  findArticle(articleId) {
    return axios.post("/api/help/center/article/" + articleId, Tool.createFormData());
  },
  feedbackArticle(article) {
    return axios.post("/api/help/center/article/" + article.id + "/feedback", Tool.createFormData(article));
  }
}