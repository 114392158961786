<template>
  <div class="mt-2 mb-2">
    <Loading v-if="isLoadingAttributs" />

    <template v-else>
      <h4 class="mb-1">{{ productName }}</h4>
      <template v-if="productIsPrintable || productIsDeliverable">
        <div class="font-weight-bolder mb-2">{{ productQuantity }} {{ $t("front.product.quantityPrice") }} {{ formatPrice(productPrice) }} ({{ $t("front.product.vatExcluded") }})</div>
      </template>
      <div
        v-for="productFeatureValue in productFeatureValues"
        :key="productFeatureValue.id"
      >
        {{ productFeatureValue.value }}
      </div>
      <template v-if="productIsPrintable || productIsDeliverable">
        <template v-for="(attributValue, index) in productAttributeValues">
          <div v-if="attributValue" :key="index">
            <strong class="mr-2">{{ getAttributeNameFromId(attributValue.attributId) }}</strong>{{ attributValue.name }}
            <template v-if="attributValue.priceHt > 0">
              (+{{ formatPrice(attributValue.priceHt) }} {{ $t("front.product.vatExcluded") }})
            </template>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Loading from "@mizogoo/components/workspace/layout/Loading";

export default {
  name: 'ProductTemplateResume',
  props: [
    "productId",
    "productName",
    "productFeatureValues",
    "productAttributeValues",
    "productQuantity",
    "productPrice",
    "productIsPrintable",
    "productIsDeliverable"
  ],
  components: {
    Loading,
  },
  computed: {
    ...mapState({
      isLoadingAttributs: (state) => state.product.isLoadingAttributs,
      errorAttributs: (state) => state.product.errorAttributs,
      attributs: (state) => state.product.attributs,
    }),
  },
  methods: {
    getAttributeNameFromId(attributeId) {
      return this.attributs.find(attribut => attribut.id === attributeId).name;
    }
  },
  created() {
    this.$store.dispatch("product/findAttributs", { productId: this.productId });
  }
}
</script>
