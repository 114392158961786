<template>
  <Form as="" ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit="handleSubmit(onSubmit)">
      <FlashBag :message="error" v-if="hasError" />
      <FlashBag :message="message" v-if="hasMessage" variant="success" />

      <FormInput
        id="contact-email"
        type="email"
        :name="contact.inputs[0].label"
        :rules="{ required: true, email: true }"
        v-model="form.email"
      />

      <FormInput
        id="contact-subject"
        type="text"
        :name="contact.inputs[1].label"
        :rules="{ required: true }"
        v-model="form.subject"
      />

      <FormTextarea
        id="contact-content"
        :name="contact.inputs[2].label"
        :rules="{ required: true }"
        v-model="form.content"
        rows="4"
      />

      <vue-recaptcha
        ref="recaptcha"
        @verify="onCaptchaVerified"
        @expired="onCaptchaExpired"
        size="invisible"
        :sitekey="recaptchaPublicKey"
        :loadRecaptchaScript="true"
      >
      </vue-recaptcha>

      <div :class="[isWorkspace ? null : 'mt-4 text-center', 'submit-button']">
        <button
          type="submit"
          :class="[isWorkspace ? 'btn-primary' : 'btn-outline-primary', 'btn']"
          :disabled="isLoading"
        >
          {{ contact.buttonLabel }}
          <b-spinner v-if="isLoading" small class="ml-1"></b-spinner>
        </button>
      </div>

      <div :class="[isWorkspace ? null : 'text-center', 'extra-info']">
        {{ $t("front.aboutUs.contact.extraInfo") }}
      </div>
    </b-form>
  </Form>
</template>

<script>
import { nextTick } from "vue";
import FlashBag from "@mizogoo/components/FlashBag";
import FormInput from "@mizogoo/components/form/FormInput";
import FormTextarea from "@mizogoo/components/form/FormTextarea";
import { VueRecaptcha } from "vue-recaptcha";

export default {
  name: "ContactForm",
  components: {
    FlashBag,
    FormInput,
    FormTextarea,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        email: "",
        subject: "",
        content: "",
        recaptchaToken: null,
      },
      message: null,
    };
  },
  computed: {
    contact() {
      return {
        inputs: [
          {
            id: 0,
            label: this.$t("front.aboutUs.contact.inputs[0]"),
          },
          {
            id: 1,
            label: this.$t("front.aboutUs.contact.inputs[1]"),
          },
          {
            id: 2,
            label: this.$t("front.aboutUs.contact.inputs[2]"),
          },
        ],
        buttonLabel: this.$t("front.aboutUs.contact.buttonLabel"),
      };
    },
    isLoading() {
      return this.$store.getters["contactForm/isLoadingContactForm"];
    },
    hasError() {
      return this.$store.getters["contactForm/hasErrorContactForm"];
    },
    error() {
      return this.$store.getters["contactForm/errorContactForm"];
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      const self = this;
      self.status = "submitting";
      self.$refs.recaptcha.reset();

      this.form.recaptchaToken = recaptchaToken;

      const response = await this.$store.dispatch("contactForm/createContactForm", {
        form: this.form,
      });

      if (!this.hasError) {
        this.message = response.message;
        this.form.email = null;
        this.form.subject = null;
        this.form.content = null;

        nextTick(() => {
          this.$refs.observer.resetForm();
        });
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.extra-info {
  margin-top: 1rem;
  font-size: 0.9rem;
}
</style>
