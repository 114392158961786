<template>
  <b-container class="faq">
    <b-row class="text-center">
      <b-col>
        <div class="heading-container">
          <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
          <div
            v-for="(headingPart, index) in heading"
            :key="index"
            class="heading-inner-container"
          >
            <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="questions-container" align-v="baseline">
      <b-col md="6" cols="12" >
        <div
          v-for="question in evenQuestions"
          :key="question.id"
          class="question-container"
          v-b-toggle="'answer-' + question.id"
          @click="setVisibleId(question.id)"
        >
          <div class="question-row">
            <h4 class="pr-5">{{ question.question }}</h4>
            <i :class="[visibleId === question.id ? 'fa-minus' : 'fa-plus', 'fas']" />
          </div>
          <b-collapse :id="'answer-' + question.id" accordion="my-accordion">
            <div class="pt-3">
              <p v-html="question.answer"></p>
            </div>
          </b-collapse>
        </div>
      </b-col>
      <b-col md="6" cols="12" >
        <div
          v-for="question in oddQuestions"
          :key="question.id"
          class="question-container"
          v-b-toggle="'answer-' + question.id"
          @click="setVisibleId(question.id)"
        >
          <div class="question-row">
            <h4 class="pr-5">{{ question.question }}</h4>
            <i :class="[visibleId === question.id ? 'fa-minus' : 'fa-plus', 'fas']" />
          </div>
          <b-collapse :id="'answer-' + question.id" accordion="my-accordion">
            <div class="pt-3">
              <p v-html="renderAnswer(question.answer)"></p>
            </div>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "HomeFAQ",
  props: ["subheading", "heading", "description", "questions"],
  data() {
    return {
      visibleId: "",
    };
  },
  computed: {
    evenQuestions() {
      return this.questions.filter((element, index, array) => {
        return (index % 2 === 0);
      });
    },
    oddQuestions() {
      return this.questions.filter((element, index, array) => {
        return (index % 2 !== 0);
      });
    }
  },
  methods: {
    setVisibleId(id) {
      this.visibleId === id ? (this.visibleId = "") : (this.visibleId = id);
    },
    renderAnswer(answer) {
      return answer.replace(/<a href="([^"]+)"[^>]*>(.*?)<\/a>/g, (_, to, content) => {
        return `<a href="${to}" @click="navigateToRoute('${to}')">${content}</a>`;
      });
    },
    navigateToRoute(route) {
      this.$router.push(route);
    },
  },
}
</script>

<style lang="scss" scoped>
.faq {
  padding-top: 5rem;
  padding-bottom: 7rem;

  .heading-container {
    padding-bottom: 4rem;
  }

  .questions-container {
    .question-container {
      padding: 1rem;
      margin-bottom: 1rem;
      background-color: $mizogoo-gray-light;
      border-radius: 10px;

      .question-row {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-weight: 500;
        transition: color 0.2s ease-in-out;

        h4 {
          margin-bottom: 0;
          font-weight: 500!important;
        }
      }
    }

    .not-collapsed {
      .question-row {
        color: $primary;
      }
    }
  }
}
</style>