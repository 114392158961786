import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/address", Tool.createFormData());
  },
  findOne (address) {
    return axios.post("/api/account/address/" + address, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/address/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/address/update/" + data.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/address/remove/" + data.id, Tool.createFormData());
  },
  default (data) {
    return axios.post("/api/account/address/default/" + data.id, Tool.createFormData());
  },
};
