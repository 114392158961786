<template>
  <b-container class="secondary-page-container">
    <b-row class="justify-content-center">
      <b-col>
        <div class="text-center">
          <h1>Mot de passe oublié</h1>
        </div>

        <div class="inner-container">
          <b-row v-if="hasError || hasMessage">
            <b-col cols="12" class="mb-4 text-center">
              <FlashBag :message="error" v-if="hasError" />
              <FlashBag :message="message" variant="success" v-if="hasMessage" />
            </b-col>
          </b-row>

          <div class="mb-4">
            <Form as="" ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit="handleSubmit(onSubmit)">
                <FormInput
                  type="email"
                  id="email"
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-model="form.email"
                />

                <vue-recaptcha
                  ref="recaptcha"
                  @verify="onCaptchaVerified"
                  @expired="onCaptchaExpired"
                  size="invisible"
                  :sitekey="recaptchaPublicKey"
                  :loadRecaptchaScript="true"
                >
                </vue-recaptcha>

                <div class="mt-4 text-center">
                  <div v-if="isLoading">
                    {{ $t("global.loading") }}
                  </div>
                  <button type="submit" class="btn btn-outline-primary" v-else>
                    Envoyer
                  </button>
                </div>
              </b-form>
            </Form>
          </div>

          <div class="mb-4 text-center">
            <router-link :to="{ name: 'login' }">
              Retour à la page de connexion
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { nextTick } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import MetaMixin from "@mizogoo/mixins/meta.js";
import FlashBag from "@mizogoo/components/FlashBag";
import FormInput from "@mizogoo/components/form/FormInput";

export default {
  mixins: [MetaMixin],
  name: "PasswordResetPage",
  components: {
    FlashBag,
    FormInput,
    VueRecaptcha,
  },
  props: {},
  data() {
    return {
      form: {
        email: null,
        recaptchaToken: null,
      },
      message: null,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.passwordReset.title");
    },
    isLoading() {
      return this.$store.getters["security/isLoadingPasswordReset"];
    },
    hasError() {
      return this.$store.getters["security/hasErrorPasswordReset"];
    },
    error() {
      return this.$store.getters["security/errorPasswordReset"];
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      const self = this;
      self.status = "submitting";
      self.$refs.recaptcha.reset();

      this.form.recaptchaToken = recaptchaToken;

      const response = await this.$store.dispatch("security/passwordReset", {
        form: this.form,
      });

      if (!this.hasError) {
        this.message = response.message;
      }

      this.form.email = null;

      nextTick(() => {
        this.$refs.observer.resetForm();
      });
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>
