// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `output.b-rating.form-control[data-v-3a22844c] {
  background-color: transparent !important;
  outline: 1px solid transparent;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/form/FormRating.vue","webpack://./FormRating.vue"],"names":[],"mappings":"AAME;EACE,wCAAA;EACA,8BAAA;ACLJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\noutput.b-rating {\n  &.form-control {\n    background-color: transparent !important;\n    outline: 1px solid transparent;\n  }\n}\n","output.b-rating.form-control {\n  background-color: transparent !important;\n  outline: 1px solid transparent;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
