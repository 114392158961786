const SAVE_CURRENT_TABS = "SAVE_CURRENT_TABS";

export default {
  namespaced: true,
  state: {
    // currentTabs array contains currentTab objects with this structure: { tabsId: "APP_TABS", currentTabIndex: 0 }
    // tabsId is used to identify the tab group and currentTabIndex the active tab inside these tabs
    currentTabs: [],
  },
  getters: {
    currentTabs(state) {
      return state.currentTabs;
    },
  },
  mutations: {
    [SAVE_CURRENT_TABS](state, currentTab) {
      let tabsId = currentTab.tabsId;
      let currentTabIndex = currentTab.currentTabIndex;
      let storedTabIndex = state.currentTabs.findIndex(element => element.tabsId === tabsId);
      if (storedTabIndex != -1) {
        state.currentTabs[storedTabIndex].currentTabIndex = currentTabIndex;
      } else {
        state.currentTabs.push(currentTab);
      }
    },
  },
  actions: {
    async save({ commit }, payload) {
      commit(SAVE_CURRENT_TABS, payload);
    },
  },
};
