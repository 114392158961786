<template>
  <b-container fluid class="associated-products-container">
    <b-container>
      <h2 class="mb-5 text-center">{{ $t('front.product.associatedProducts') }}</h2>
      
      <div v-if="isLoadingAssociatedProducts" class="loading">
        <div class="text-center">
          <b-spinner label="Spinning"></b-spinner>
        </div>
      </div>

      <div v-else class="position-relative">
        <div
          v-show="associatedProductsScrollAmount > 0"
          class="arrow-left"
          @click="slideLeft"
        >
          <i class="fas fa-chevron-left"></i>
        </div>
        <div
          v-show="displayRightArrow"
          class="arrow-right"
          @click="slideRight"
        >
          <i class="fas fa-chevron-right"></i>
        </div>
        <b-row class="associated-products-inner-container" ref="associated-products-inner-container">
          <b-col
            v-for="(product, index) in associatedProducts"
            :key="product.id"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            :id="index == 0 ? 'first-associated-item' : null"
          >
            <CatalogItem
              :ref="'associated-item'"
              :id="product.id"
              :index="index"
              :image="productImage(product)"
              :name="product.name"
              :description="product.description"
              :displayPrice="product.isClassic || product.isPrintable"
              :minQuantity="product.minQuantity"
              :minPrice="'150€'"
              :urlName="product.urlName"
            ></CatalogItem>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-container>
</template>

<script>
import CatalogItem from "@mizogoo/components/front/layout/products/catalog/CatalogItem";

export default {
  name: "AssociatedProducts",
  props: ["associatedProducts", "isLoadingAssociatedProducts"],
  components: {
    CatalogItem,
  },
  data() {
    return {
      associatedProductsScrollAmount: 0,
    }
  },
  computed: {
    slider() {
      return this.$refs['associated-products-inner-container'];
    },
    displayRightArrow() {
      let windowWidth = window.innerWidth;
      let maxItemsDisplayed = 3;
      if (windowWidth <= 768) {
        maxItemsDisplayed = 2;
      }
      if (windowWidth <= 576) {
        maxItemsDisplayed = 1;
      }

      return this.associatedProductsScrollAmount < (this.associatedProducts.length - maxItemsDisplayed) * 380
    }
  },
  methods: {
    productImage(product) {
      return {
        src: product.file.thumbnails['576_576'],
        alt: product.name
      }
    },
    slideLeft() {
      const slider = this.$refs['associated-products-inner-container'];
      if (slider.scrollLeft > 0) {
        slider.scrollTo({
          left: (this.associatedProductsScrollAmount -= document.getElementById('first-associated-item').clientWidth),
          behavior: 'smooth',
        });
      }
    },
    slideRight() {
      const slider = this.$refs['associated-products-inner-container'];
      let itemWidth = document.getElementById('first-associated-item').clientWidth;
      if (slider.scrollLeft < itemWidth * this.associatedProducts.length) {
        slider.scrollTo({
          left: this.associatedProductsScrollAmount += itemWidth,
          behavior: 'smooth',
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.associated-products-container {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: $mizogoo-gray-light;

  .loading {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .associated-products-inner-container {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  .arrow-left, .arrow-right {
    position: absolute;
    top: calc(125px + 1.5rem);
    cursor: pointer;
    z-index: 5;
  }

  .arrow-left {
    left: -25px;
  }

  .arrow-right {
    right: -25px;
  }
}
</style>
