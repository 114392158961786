import AccountAPI from "../api/account";

const
  UPDATE_PROFILE = "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS",
  UPDATE_PROFILE_ERROR = "UPDATE_PROFILE_ERROR";

export default {
  namespaced: true,
  state: {
    isLoadingAction: false,
    error: null,
  },
  getters: {
    isLoadingAction (state) {
      return state.isLoadingAction;
    },
    hasError (state) {
      return state.error !== null;
    },
    error (state) {
      return state.error;
    },
  },
  mutations: {
    [UPDATE_PROFILE] (state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [UPDATE_PROFILE_SUCCESS] (state, user) {
      state.isLoadingAction = false;
      state.error = null;
      this.dispatch('security/fetchUser', user);
    },
    [UPDATE_PROFILE_ERROR] (state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
  },
  actions: {
    async updateProfile ({ commit }, payload) {
      commit(UPDATE_PROFILE);
      try {
        let response = await AccountAPI.updateProfile(payload.form);
        if (response.data.success === true) {
          commit(UPDATE_PROFILE_SUCCESS, response.data.user);
          return response.data.user;
        } else {
          commit(UPDATE_PROFILE_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATE_PROFILE_ERROR);
        return null;
      }
    },
  }
}
