// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formats-container .format-main {
  margin-bottom: 0.2rem;
  font-weight: 500;
  line-height: 1.3rem;
}
.formats-container .format-extra {
  font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/ProductPage/CustomizableFormat.vue","webpack://./CustomizableFormat.vue"],"names":[],"mappings":"AAME;EACE,qBAAA;EACA,gBAAA;EACA,mBAAA;ACLJ;ADQE;EACE,iBAAA;ACNJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.formats-container {\n  .format-main {\n    margin-bottom: 0.2rem;\n    font-weight: 500;\n    line-height: 1.3rem;\n  }\n\n  .format-extra {\n    font-size: 0.8rem;\n  }\n}\n",".formats-container .format-main {\n  margin-bottom: 0.2rem;\n  font-weight: 500;\n  line-height: 1.3rem;\n}\n.formats-container .format-extra {\n  font-size: 0.8rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
