// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.order-confirmation-container[data-v-822c97fe] {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.order-confirmation-container .order-element-container[data-v-822c97fe] {
  border-bottom: 1px solid #dee2e6;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
}
.order-confirmation-container .order-element-container[data-v-822c97fe]:first-child {
  border-top: 1px solid #dee2e6;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/CartPage/Validated.vue","webpack://./Validated.vue"],"names":[],"mappings":"AAKA;EACE,iBAAA;EACA,oBAAA;ACJF;ADME;EACE,gCAAA;EACA,mBAAA;EACA,sBAAA;ACJJ;ADMI;EACE,6BAAA;ACJN","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.order-confirmation-container {\n  padding-top: 8rem;\n  padding-bottom: 8rem;\n\n  .order-element-container {\n    border-bottom: 1px solid $gray-300;\n    padding-top: 1.2rem;\n    padding-bottom: 1.2rem;\n\n    &:first-child {\n      border-top: 1px solid $gray-300;\n    }\n  }\n}\n",".order-confirmation-container {\n  padding-top: 8rem;\n  padding-bottom: 8rem;\n}\n.order-confirmation-container .order-element-container {\n  border-bottom: 1px solid #dee2e6;\n  padding-top: 1.2rem;\n  padding-bottom: 1.2rem;\n}\n.order-confirmation-container .order-element-container:first-child {\n  border-top: 1px solid #dee2e6;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
