<template>
  <b-container class="secondary-page-container">
    <b-row>
      <b-col cols="12" class="mb-4 text-center">
        <h1>Nouveau mot de passe</h1>
      </b-col>
    </b-row>

    <b-row v-if="isLoading">
      <b-col cols="12">
        {{ $t("global.loading") }}
      </b-col>
    </b-row>

    <div v-else>
      <b-col v-if="!isTokenValid">
        <FlashBag :message="error" v-if="hasError" />
      </b-col>

      <div v-else>
        <b-row v-if="hasError || hasMessage">
          <b-col cols="12" class="mb-4 text-center">
            <FlashBag :message="error" v-if="hasError" />
            <FlashBag :message="message" variant="success" v-if="hasMessage" />
          </b-col>
        </b-row>

        <div class="inner-container">
          <b-row class="justify-content-center">
            <b-col md="12">
              <Form as="" ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit="handleSubmit(onSubmit)">
                  <FormInput
                    type="password"
                    id="password"
                    name="Changer de mot de passe"
                    :newPassword="true"
                    v-model="form.password"
                    help="Votre mot de passe doit avoir une longueur minimum de 8 caractères et contenir au moins 3 des 4 types suivants : majuscule, minuscules, chiffres, caractères spéciaux."
                  />

                  <FormInput
                    type="password"
                    id="password-confirm"
                    name="Confirmer le nouveau mot de passe"
                    :newPassword="true"
                    v-model="form.passwordConfirm"
                  />

                  <vue-recaptcha
                    ref="recaptcha"
                    @verify="onCaptchaVerified"
                    @expired="onCaptchaExpired"
                    size="invisible"
                    :sitekey="recaptchaPublicKey"
                    :loadRecaptchaScript="true"
                  >
                  </vue-recaptcha>

                  <div class="mt-4 text-center">
                    <div v-if="isLoading">
                      {{ $t("global.loading") }}
                    </div>
                    <button type="submit" class="btn btn-outline-primary" v-else>
                      <i class="far fa-dot-circle mr-1"></i>Envoyer
                    </button>
                  </div>
                </b-form>
              </Form>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { nextTick } from "vue";
import { VueRecaptcha } from "vue-recaptcha";
import MetaMixin from "@mizogoo/mixins/meta.js";
import FlashBag from "@mizogoo/components/FlashBag";
import FormInput from "@mizogoo/components/form/FormInput";

export default {
  mixins: [MetaMixin],
  name: "PasswordResetTokenPage",
  components: {
    FlashBag,
    FormInput,
    VueRecaptcha,
  },
  data() {
    return {
      form: {
        password: "",
        passwordConfirm: "",
      },
      isTokenValid: false,
      message: null,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.passwordResetToken.title");
    },
    isLoading() {
      return this.$store.getters["security/isLoadingPasswordResetToken"];
    },
    hasError() {
      return this.$store.getters["security/hasErrorPasswordResetToken"];
    },
    error() {
      return this.$store.getters["security/errorPasswordResetToken"];
    },
    hasMessage() {
      return this.message !== null;
    },
  },
  methods: {
    async onSubmit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      const self = this;
      self.status = "submitting";
      self.$refs.recaptcha.reset();

      const response = await this.$store.dispatch("security/passwordResetToken", {
        password: this.form.password,
        passwordConfirm: this.form.passwordConfirm,
        token: this.$route.params.token,
        recaptchaToken: recaptchaToken,
      });

      if (!this.hasError) {
        this.message = response.message;
      }

      this.form.password = null;
      this.form.passwordConfirm = null;
      this.form.token = null;

      nextTick(() => {
        this.$refs.observer.resetForm();
      });
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    // async onSubmit () {
    //   const response = await this.$store.dispatch("security/passwordResetToken", {
    //     password: this.form.password,
    //     passwordConfirm: this.form.passwordConfirm,
    //     token: this.$route.params.token,
    //   });
    //
    //   if (!this.hasError) {
    //     this.message = response.message
    //   }
    //
    //   this.password = null
    //   this.passwordConfirm = null
    //
    //   nextTick(() => {
    //     this.$refs.observer.resetForm();
    //   });
    // }
  },
  async created() {
    const response = await this.$store.dispatch("security/passwordResetToken", {
      token: this.$route.params.token,
    });

    this.isTokenValid = response.success;
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>
