import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/offer", Tool.createFormData());
  },
  findOne (data) {
    return axios.post("/api/account/offer/" + data, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/offer/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/offer/update/" + data.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/offer/remove/" + data.id, Tool.createFormData());
  }
};
