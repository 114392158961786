<template>
  <b-container class="app-features-grid-container">
    <b-row>
      <b-col
        v-for="(col, index) in items.cols"
        :key="index"
        cols="12"
        :lg="col.colItems[0].main ? 6 : 3"
        class="grid-col"
      >
        <template v-if="col.colItems[0].main">
          <app-feature-mobile
            :images="col.colItems[0].images"
            :activeFeatureId="activeFeatureId"
          ></app-feature-mobile>
          <div class="test-main"></div>
        </template>
        <template v-else>
          <app-feature-item
            v-for="item in col.colItems"
            :key="item.id"
            :id="item.id"
            :activeFeatureId="activeFeatureId"
            :heading="item.heading"
            :description="item.description"
            :image="item.image"
            :link="item.link"
            :alignment="index == 0 ? 'right' : 'left'"
            @setActiveFeatureId="setActiveFeatureId"
          ></app-feature-item>
        </template>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AppFeatureItem from "@mizogoo/components/front/layout/features/appFeatures/AppFeatureItem";
import AppFeatureMobile from "@mizogoo/components/front/layout/features/appFeatures/AppFeatureMobile";

export default {
  name: "AppFeatureGrid",
  components: {
    AppFeatureItem,
    AppFeatureMobile,
  },
  props: ["items"],
  data() {
    return {
      activeFeatureId: 1,
    };
  },
  methods: {
    setActiveFeatureId(featureId) {
      this.activeFeatureId = featureId;
    },
  },
};
</script>

<style lang="scss" scoped>
.app-features-grid-container {
  padding-bottom: 5rem;

  .row {
    .grid-col {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.test-main {
  background-color: $primary;
}
</style>
