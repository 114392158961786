<template>
  <b-container class="secondary-page-container legal-page">
    <b-row>
      <b-col class="mb-4">
        <h1 class="text-center pb-2">{{ $t("front.privacy.heading") }}</h1>
        <p class="last-update-date mb-5">{{ $t("front.legal.lastUpdateDate") }}</p>
        <div v-html="$t('front.privacy.introduction')" />

        <div v-for="(section, sectionIndex) in sections" :key="sectionIndex">
          <h4 class="mt-5 mb-4 pt-2">
            <div class="white-space-initial">
              {{ $t("front.legal.article") }}
              {{ sectionIndex + 1 }} &#8211; {{ section.heading }}
            </div>    
          </h4>
          <template v-if="section.description">
            <div v-html="section.description" />
          </template>

          <template v-if="section.table">
            <b-table
              :items="section.table.items"
              :fields="section.table.fields"
              bordered
              head-variant="light"
            />
          </template>

          <template v-if="section.description2">
            <div v-html="section.description2" />
          </template>

          <template v-if="section.table2">
            <b-table
              :items="section.table2.items"
              :fields="section.table2.fields"
              bordered
              head-variant="light"
            />
          </template>

          <!-- Subsections (if exists) -->
          <template v-if="section.subsections">
            <div v-for="(subsection, subsectionIndex) in section.subsections" :key="subsectionIndex">
              <h5 class="mt-2 mb-3">
                {{ sectionIndex + 1 }}.{{ subsectionIndex + 1 }}. {{ subsection.subheading }}
              </h5>
              <div v-html="subsection.description" />
              <template v-if="subsection.table">
                <b-table
                  :items="subsection.table.items"
                  :fields="subsection.table.fields"
                  bordered
                  head-variant="light"
                >
                </b-table>
              </template>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";

export default {
  mixins: [MetaMixin],
  name: "PrivacyPage",
  computed: {
    metaTitle() {
      return this.$t("front.metadata.privacy.title");
  },
    sections() {
      return [
        {
          heading: this.$tm("front.privacy.sections[0].heading"),
          description: this.$tm("front.privacy.sections[0].description"),
        },
        {
          heading: this.$tm("front.privacy.sections[1].heading"),
          description: this.$tm("front.privacy.sections[1].description"),
        },
        {
          heading: this.$tm("front.privacy.sections[2].heading"),
          description: this.$tm("front.privacy.sections[2].description"),
        },
        {
          heading: this.$tm("front.privacy.sections[3].heading"),
          description: this.$tm("front.privacy.sections[3].description"),
        },
        {
          heading: this.$tm("front.privacy.sections[4].heading"),
          description: this.$t("front.privacy.sections[4].description"),
          table: {
            items: [
              {
                id: 1,
                goal: this.$t("front.privacy.sections[4].table[1].col1"),
                data: this.$t("front.privacy.sections[4].table[1].col2"),
              },
              {
                id: 2,
                goal: this.$t("front.privacy.sections[4].table[2].col1"),
                data: this.$t("front.privacy.sections[4].table[2].col2"),
              },
              {
                id: 3,
                goal: this.$t("front.privacy.sections[4].table[3].col1"),
                data: this.$t("front.privacy.sections[4].table[3].col2"),
              },
              {
                id: 4,
                goal: this.$t("front.privacy.sections[4].table[4].col1"),
                data: this.$t("front.privacy.sections[4].table[4].col2"),
              },
              {
                id: 5,
                goal: this.$t("front.privacy.sections[4].table[5].col1"),
                data: this.$t("front.privacy.sections[4].table[5].col2"),
              },
              {
                id: 6,
                goal: this.$t("front.privacy.sections[4].table[6].col1"),
                data: this.$t("front.privacy.sections[4].table[6].col2"),
              },
              {
                id: 7,
                goal: this.$t("front.privacy.sections[4].table[7].col1"),
                data: this.$t("front.privacy.sections[4].table[7].col2"),
              },
              {
                id: 8,
                goal: this.$t("front.privacy.sections[4].table[8].col1"),
                data: this.$t("front.privacy.sections[4].table[8].col2"),
              },
              {
                id: 9,
                goal: this.$t("front.privacy.sections[4].table[9].col1"),
                data: this.$t("front.privacy.sections[4].table[9].col2"),
              },
              {
                id: 10,
                goal: this.$t("front.privacy.sections[4].table[10].col1"),
                data: this.$t("front.privacy.sections[4].table[10].col2"),
              },
            ],
            fields: [
              { key: "goal", label: this.$t("front.privacy.sections[4].table[0].col1") },
              { key: "data", label: this.$t("front.privacy.sections[4].table[0].col2"), tdClass: "white-space-pre-line" }
            ]
          },
          description2: this.$tm("front.privacy.sections[4].description2"),
          table2: {
            items: [
              {
                id: 1,
                goal: this.$t("front.privacy.sections[4].table2[1].col1"),
                data: this.$t("front.privacy.sections[4].table2[1].col2")
              },
            ],
            fields: [
              { key: "goal", label: this.$t("front.privacy.sections[4].table2[0].col1") },
              { key: "data", label: this.$t("front.privacy.sections[4].table2[0].col2"), tdClass: "white-space-pre-line" }
            ],
          }
        },
        {
          heading: this.$tm("front.privacy.sections[5].heading"),
          description: this.$tm("front.privacy.sections[5].description"),
          table: {
            items: [
              {
                id: 1,
                goal: this.$t("front.privacy.sections[5].table[1].col1"),
                details: this.$t("front.privacy.sections[5].table[1].col2"),
                legal: this.$t("front.privacy.sections[5].table[1].col3"),
                period: this.$t("front.privacy.sections[5].table[1].col4"),
              },
              {
                id: 2,
                goal: this.$t("front.privacy.sections[5].table[2].col1"),
                details: this.$t("front.privacy.sections[5].table[2].col2"),
                legal: this.$t("front.privacy.sections[5].table[2].col3"),
                period: this.$t("front.privacy.sections[5].table[2].col4")
              },
              {
                id: 3,
                goal: this.$t("front.privacy.sections[5].table[3].col1"),
                details: this.$t("front.privacy.sections[5].table[3].col2"),
              },
              {
                id: 4,
                goal: this.$t("front.privacy.sections[5].table[4].col1"),
                details: this.$t("front.privacy.sections[5].table[4].col2"),
              },
              {
                id: 5,
                goal: this.$t("front.privacy.sections[5].table[5].col1"),
                details: this.$t("front.privacy.sections[5].table[5].col2"),
                legal: this.$t("front.privacy.sections[5].table[5].col3"),
                period: this.$t("front.privacy.sections[5].table[5].col4")
              },
              {
                id: 6,
                goal: this.$t("front.privacy.sections[5].table[6].col1"),
                details: this.$t("front.privacy.sections[5].table[6].col2"),
                legal: this.$t("front.privacy.sections[5].table[6].col3"),
                period: this.$t("front.privacy.sections[5].table[6].col4")
              },
              {
                id: 7,
                goal: this.$t("front.privacy.sections[5].table[7].col1"),
                details: this.$t("front.privacy.sections[5].table[7].col2"),
                legal: this.$t("front.privacy.sections[5].table[7].col3"),
                period: this.$t("front.privacy.sections[5].table[7].col4")
              },
              {
                id: 8,
                goal: this.$t("front.privacy.sections[5].table[8].col1"),
                details: this.$t("front.privacy.sections[5].table[8].col2"),
                legal: this.$t("front.privacy.sections[5].table[8].col3"),
                period: this.$t("front.privacy.sections[5].table[8].col4")
              },
              {
                id: 9,
                goal: this.$t("front.privacy.sections[5].table[9].col1"),
                details: this.$t("front.privacy.sections[5].table[9].col2"),
                legal: this.$t("front.privacy.sections[5].table[9].col3"),
                period: this.$t("front.privacy.sections[5].table[9].col4")
              },
              {
                id: 10,
                goal: this.$t("front.privacy.sections[5].table[10].col1"),
                details: this.$t("front.privacy.sections[5].table[10].col2"),
                legal: this.$t("front.privacy.sections[5].table[10].col3"),
                period: this.$t("front.privacy.sections[5].table[10].col4")
              },
              {
                id: 11,
                goal: this.$t("front.privacy.sections[5].table[11].col1"),
                details: this.$t("front.privacy.sections[5].table[11].col2"),
                legal: this.$t("front.privacy.sections[5].table[11].col3"),
                period: this.$t("front.privacy.sections[5].table[11].col4")
              },
              {
                id: 12,
                goal: this.$t("front.privacy.sections[5].table[12].col1"),
                details: this.$t("front.privacy.sections[5].table[12].col2"),
                legal: this.$t("front.privacy.sections[5].table[12].col3"),
                period: this.$t("front.privacy.sections[5].table[12].col4")
              },
              {
                id: 13,
                goal: this.$t("front.privacy.sections[5].table[13].col1"),
                details: this.$t("front.privacy.sections[5].table[13].col2"),
                legal: this.$t("front.privacy.sections[5].table[13].col3"),
                period: this.$t("front.privacy.sections[5].table[13].col4")
              },
              {
                id: 14,
                goal: this.$t("front.privacy.sections[5].table[14].col1"),
                details: this.$t("front.privacy.sections[5].table[14].col2"),
                legal: this.$t("front.privacy.sections[5].table[14].col3"),
                period: this.$t("front.privacy.sections[5].table[14].col4")
              },
            ],
            fields: [
              { key: "goal", label: this.$t("front.privacy.sections[5].table[0].col1") },
              { key: "details", label: this.$t("front.privacy.sections[5].table[0].col2") },
              { key: "legal", label: this.$t("front.privacy.sections[5].table[0].col3") },
              { key: "period", label: this.$t("front.privacy.sections[5].table[0].col4") },
            ]
          },
          description2: this.$tm("front.privacy.sections[5].description2"),
        },
        {
          heading: this.$tm("front.privacy.sections[6].heading"),
          description: this.$tm("front.privacy.sections[6].description"),
          subsections: [
            {
              subheading: this.$t("front.privacy.sections[6].subsections[0].subheading"),
              description: this.$tm("front.privacy.sections[6].subsections[0].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[6].subsections[1].subheading"),
              description: this.$tm("front.privacy.sections[6].subsections[1].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[6].subsections[2].subheading"),
              description: this.$tm("front.privacy.sections[6].subsections[2].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[6].subsections[3].subheading"),
              description: this.$tm("front.privacy.sections[6].subsections[3].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[6].subsections[4].subheading"),
              description: this.$tm("front.privacy.sections[6].subsections[4].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[6].subsections[5].subheading"),
              description: this.$tm("front.privacy.sections[6].subsections[5].description"),
            },
          ]
        },
        {
          heading: this.$tm("front.privacy.sections[7].heading"),
          description: this.$tm("front.privacy.sections[7].description"),
        },
        {
          heading: this.$tm("front.privacy.sections[8].heading"),
          description: this.$t("front.privacy.sections[8].description"),
          subsections: [
            {
              subheading: this.$t("front.privacy.sections[8].subsections[0].subheading"),
              description: this.$tm("front.privacy.sections[8].subsections[0].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[8].subsections[1].subheading"),
              description: this.$tm("front.privacy.sections[8].subsections[1].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[8].subsections[2].subheading"),
              description: this.$tm("front.privacy.sections[8].subsections[2].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[8].subsections[3].subheading"),
              table: {
                items: [
                  {
                    id: 1,
                    name: this.$t("front.cookie.cookieList.functionnal.list[0].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[0].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[0].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[0].expiry"),
                  },
                  {
                    id: 2,
                    name: this.$t("front.cookie.cookieList.functionnal.list[1].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[1].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[1].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[1].expiry"),
                  },
                  {
                    id: 3,
                    name: this.$t("front.cookie.cookieList.functionnal.list[2].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[2].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[2].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[2].expiry"),
                  },
                  {
                    id: 4,
                    name: this.$t("front.cookie.cookieList.functionnal.list[3].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[3].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[3].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[3].expiry"),
                  },
                  {
                    id: 5,
                    name: this.$t("front.cookie.cookieList.functionnal.list[4].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[4].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[4].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[4].expiry"),
                  },
                  {
                    id: 6,
                    name: this.$t("front.cookie.cookieList.functionnal.list[5].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[5].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[5].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[5].expiry"),
                  },
                  {
                    id: 7,
                    name: this.$t("front.cookie.cookieList.functionnal.list[6].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[6].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[6].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[6].expiry"),
                  },
                  {
                    id: 8,
                    name: this.$t("front.cookie.cookieList.functionnal.list[7].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[7].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[7].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[7].expiry"),
                  },
                  {
                    id: 9,
                    name: this.$t("front.cookie.cookieList.functionnal.list[8].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[8].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[8].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[8].expiry"),
                  },
                  {
                    id: 10,
                    name: this.$t("front.cookie.cookieList.functionnal.list[9].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[9].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[9].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[9].expiry"),
                  },
                  {
                    id: 11,
                    name: this.$t("front.cookie.cookieList.functionnal.list[10].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[10].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[10].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[10].expiry"),
                  },
                  {
                    id: 12,
                    name: this.$t("front.cookie.cookieList.functionnal.list[11].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[11].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[11].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[11].expiry"),
                  },
                  {
                    id: 13,
                    name: this.$t("front.cookie.cookieList.functionnal.list[12].name"),
                    goal: this.$t("front.cookie.cookieList.functionnal.list[12].description"),
                    provider: this.$t("front.cookie.cookieList.functionnal.list[12].provider"),
                    period: this.$t("front.cookie.cookieList.functionnal.list[12].expiry"),
                  },
                  {
                    id: 14,
                    name: this.$t("front.cookie.cookieList.analytics.list[0].name"),
                    goal: this.$t("front.cookie.cookieList.analytics.list[0].description"),
                    provider: this.$t("front.cookie.cookieList.analytics.list[0].provider"),
                    period: this.$t("front.cookie.cookieList.analytics.list[0].expiry"),
                  },
                  {
                    id: 15,
                    name: this.$t("front.cookie.cookieList.analytics.list[1].name"),
                    goal: this.$t("front.cookie.cookieList.analytics.list[1].description"),
                    provider: this.$t("front.cookie.cookieList.analytics.list[1].provider"),
                    period: this.$t("front.cookie.cookieList.analytics.list[1].expiry"),
                  },
                  {
                    id: 16,
                    name: this.$t("front.cookie.cookieList.analytics.list[2].name"),
                    goal: this.$t("front.cookie.cookieList.analytics.list[2].description"),
                    provider: this.$t("front.cookie.cookieList.analytics.list[2].provider"),
                    period: this.$t("front.cookie.cookieList.analytics.list[2].expiry"),
                  },
                  {
                    id: 17,
                    name: this.$t("front.cookie.cookieList.analytics.list[3].name"),
                    goal: this.$t("front.cookie.cookieList.analytics.list[3].description"),
                    provider: this.$t("front.cookie.cookieList.analytics.list[3].provider"),
                    period: this.$t("front.cookie.cookieList.analytics.list[3].expiry"),
                  },
                  {
                    id: 18,
                    name: this.$t("front.cookie.cookieList.tracking.list[0].name"),
                    goal: this.$t("front.cookie.cookieList.tracking.list[0].description"),
                    provider: this.$t("front.cookie.cookieList.tracking.list[0].provider"),
                    period: this.$t("front.cookie.cookieList.tracking.list[0].expiry"),
                  },
                  {
                    id: 19,
                    name: this.$t("front.cookie.cookieList.tracking.list[1].name"),
                    goal: this.$t("front.cookie.cookieList.tracking.list[1].description"),
                    provider: this.$t("front.cookie.cookieList.tracking.list[1].provider"),
                    period: this.$t("front.cookie.cookieList.tracking.list[1].expiry"),
                  },
                  {
                    id: 20,
                    name: this.$t("front.cookie.cookieList.tracking.list[2].name"),
                    goal: this.$t("front.cookie.cookieList.tracking.list[2].description"),
                    provider: this.$t("front.cookie.cookieList.tracking.list[2].provider"),
                    period: this.$t("front.cookie.cookieList.tracking.list[2].expiry"),
                  },
                  {
                    id: 21,
                    name: this.$t("front.cookie.cookieList.tracking.list[3].name"),
                    goal: this.$t("front.cookie.cookieList.tracking.list[3].description"),
                    provider: this.$t("front.cookie.cookieList.tracking.list[3].provider"),
                    period: this.$t("front.cookie.cookieList.tracking.list[3].expiry"),
                  },
                  {
                    id: 22,
                    name: this.$t("front.cookie.cookieList.tracking.list[4].name"),
                    goal: this.$t("front.cookie.cookieList.tracking.list[4].description"),
                    provider: this.$t("front.cookie.cookieList.tracking.list[4].provider"),
                    period: this.$t("front.cookie.cookieList.tracking.list[4].expiry"),
                  },
                ],
                fields: [
                  { key: "name", label: this.$t("front.cookie.cookieDetail[0]") },
                  { key: "goal", label: this.$t("front.cookie.cookieDetail[1]") },
                  { key: "provider", label: this.$t("front.cookie.cookieDetail[2]") },
                  { key: "period", label: this.$t("front.cookie.cookieDetail[3]") },
                ],
              }
            },
            {
              subheading: this.$t("front.privacy.sections[8].subsections[4].subheading"),
              description: this.$tm("front.privacy.sections[8].subsections[4].description"),
            },
          ],
        },
        {
          heading: this.$tm("front.privacy.sections[9].heading"),
          description: this.$tm("front.privacy.sections[9].description"),
        },
        {
          heading: this.$tm("front.privacy.sections[10].heading"),
          description: this.$tm("front.privacy.sections[10].description"),
          subsections: [
            {
              subheading: this.$t("front.privacy.sections[10].subsections[0].subheading"),
              description: this.$tm("front.privacy.sections[10].subsections[0].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[10].subsections[1].subheading"),
              description: this.$tm("front.privacy.sections[10].subsections[1].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[10].subsections[2].subheading"),
              description: this.$tm("front.privacy.sections[10].subsections[2].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[10].subsections[3].subheading"),
              description: this.$tm("front.privacy.sections[10].subsections[3].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[10].subsections[4].subheading"),
              description: this.$tm("front.privacy.sections[10].subsections[4].description"),
            },
            {
              subheading: this.$t("front.privacy.sections[10].subsections[5].subheading"),
              description: this.$tm("front.privacy.sections[10].subsections[5].description"),
            },
          ],
        },
        {
          heading: this.$tm("front.privacy.sections[11].heading"),
          description: this.$tm("front.privacy.sections[11].description"),
        },
      ]
    }
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
    document.getElementById("cookie-consent").classList.add("d-none");
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
    document.getElementById("cookie-consent").classList.remove("d-none");
  },
};
</script>
