import AccountInstitutionDocumentAPI from "../api/account_institution_document";
import Vue from "vue";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",
  SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  UPDATING_POSITION = "UPDATING_POSITION",
  UPDATING_POSITION_SUCCESS = "UPDATING_POSITION_SUCCESS",
  UPDATING_POSITION_ERROR = "UPDATING_POSITION_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  ACTIVING = "ACTIVING",
  ACTIVING_SUCCESS = "ACTIVING_SUCCESS",
  ACTIVING_ERROR = "ACTIVING_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    institutionDocuments: [],
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    uploadProgress: 0,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Updating Position
    isLoadingUpdatingPosition: false,
    errorUpdatingPosition: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Activing
    isLoadingActiving: false,
    errorActiving: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasInstitutionDocuments (state) {
      return state.institutionDocuments.length > 0;
    },
    institutionDocuments (state) {
      return state.institutionDocuments;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    uploadProgress (state) {
      return state.uploadProgress;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Updating Position
    isLoadingUpdatingPosition (state) {
      return state.isLoadingUpdatingPosition;
    },
    hasErrorUpdatingPositione (state) {
      return state.errorUpdatingPosition !== null;
    },
    errorUpdatingPosition (state) {
      return state.errorUpdatingPosition;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Activing
    isLoadingActiving (state) {
      return state.isLoadingActiving;
    },
    hasErrorActiving (state) {
      return state.errorActiving !== null;
    },
    errorActiving (state) {
      return state.errorActiving;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.institutionDocuments = [];
    },
    [FETCH_ALL_SUCCESS] (state, institutionDocuments) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.institutionDocuments = institutionDocuments;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.institutionDocuments = [];
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state, institutionDocument) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.institutionDocuments.push(institutionDocument)
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    [SET_UPLOAD_PROGRESS] (state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state, institutionDocument) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;

      const data = state.institutionDocuments.find(element => element.id === institutionDocument.id)
      data.name = institutionDocument.name;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Updating Position
    [UPDATING_POSITION] (state, data) {
      state.isLoadingUpdatingPosition = true;
      state.errorUpdatingPosition = null;
    },
    [UPDATING_POSITION_SUCCESS] (state) {
      state.isLoadingUpdatingPosition = false;
      state.errorUpdatingPosition = null;

      // for (let i = 0; i < data.form.positions.length; i++) {
      //   let document = state.institutionDocuments.find(
      //     (element) => element.id === data.form.positions[i].documentId
      //   );
      //   document.position = data.form.positions[i].position;
      // }
    },
    [UPDATING_POSITION_ERROR] (state, error) {
      state.isLoadingUpdatingPosition = false;
      state.errorUpdatingPosition = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, institutionDocument) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.institutionDocuments = state.institutionDocuments.filter(element => element.id !== institutionDocument.id)
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Activing
    [ACTIVING] (state) {
      state.isLoadingActiving = true;
      state.errorActiving = null;
    },
    [ACTIVING_SUCCESS] (state) {
      state.isLoadingActiving = false;
      state.errorActiving = null;
    },
    [ACTIVING_ERROR] (state, error) {
      state.isLoadingActiving = false;
      state.errorActiving = error;
    },
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await AccountInstitutionDocumentAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.institutionDocuments);
          return response.data.institutionDocuments;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountInstitutionDocumentAPI.create({
          store: this,
          form: payload.form
        });
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.institutionDocument);
          return response.data;
        } else {
          commit(CREATING_ERROR);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    setUploadProgress ({ commit }, uploadProgress) {
      commit(SET_UPLOAD_PROGRESS, uploadProgress)
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountInstitutionDocumentAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.institutionDocument);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async updatePosition ({ commit }, payload) {
      commit(UPDATING_POSITION);
      try {
        let response = await AccountInstitutionDocumentAPI.updatePosition(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_POSITION_SUCCESS, payload);
          return response.data;
        } else {
          commit(UPDATING_POSITION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_POSITION_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountInstitutionDocumentAPI.remove(payload.institutionDocument);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.institutionDocument);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async active ({ commit }, payload) {
      commit(ACTIVING);
      try {
        let response = await AccountInstitutionDocumentAPI.active(payload.institutionDocument);
        if (response.data.success === true) {
          commit(ACTIVING_SUCCESS);
          return response.data;
        } else {
          commit(ACTIVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(ACTIVING_ERROR, error);
        return null;
      }
    },
  }
};
