import AccountOrderAPI from "../api/account_order";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  UPDATING_ADDRESS = "UPDATING_ADDRESS",
  UPDATING_ADDRESS_SUCCESS = "UPDATING_ADDRESS_SUCCESS",
  UPDATING_ADDRESS_ERROR = "UPDATING_ADDRESS_ERROR",

  CREATING_COMMENT = "CREATING_COMMENT",
  CREATING_COMMENT_SUCCESS = "CREATING_COMMENT_SUCCESS",
  CREATING_COMMENT_ERROR = "CREATING_COMMENT_ERROR",

  MAKING_CHANGES_REQUESTED = "MAKING_CHANGES_REQUESTED",
  MAKING_CHANGES_REQUESTED_SUCCESS = "MAKING_CHANGES_REQUESTED_SUCCESS",
  MAKING_CHANGES_REQUESTED_ERROR = "MAKING_CHANGES_REQUESTED_ERROR",

  MAKING_VALIDATE = "MAKING_VALIDATE",
  MAKING_VALIDATE_SUCCESS = "MAKING_VALIDATE_SUCCESS",
  MAKING_VALIDATE_ERROR = "MAKING_VALIDATE_ERROR",

  CHECKING_CUSTOMER_FILE = "CHECKING_CUSTOMER_FILE",
  CHECKING_CUSTOMER_FILE_SUCCESS = "CHECKING_CUSTOMER_FILE_SUCCESS",
  CHECKING_CUSTOMER_FILE_ERROR = "CHECKING_CUSTOMER_FILE_ERROR",

  RELATED_DOCUMENT_DOWNLOAD = "RELATED_DOCUMENT_DOWNLOAD",
  RELATED_DOCUMENT_DOWNLOAD_SUCCESS = "RELATED_DOCUMENT_DOWNLOAD_SUCCESS",
  RELATED_DOCUMENT_DOWNLOAD_ERROR = "RELATED_DOCUMENT_DOWNLOAD_ERROR",

  RELATED_DOCUMENT_CREATE = "RELATED_DOCUMENT_CREATE",
  RELATED_DOCUMENT_CREATE_SUCCESS = "RELATED_DOCUMENT_CREATE_SUCCESS",
  RELATED_DOCUMENT_CREATE_ERROR = "RELATED_DOCUMENT_CREATE_ERROR",

  SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    orders: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Updating Address
    isLoadingUpdatingAddress: false,
    errorUpdatingAddress: null,
    // Creating Comment
    isLoadingCreatingComment: false,
    errorCreatingComment: null,
    // Making Changes Requested
    isLoadingMakingChangesRequested: false,
    errorMakingChangesRequested: null,
    // Making Validate
    isLoadingMakingValidate: false,
    errorMakingValidate: null,
    // Checking Customer File
    isLoadingCheckingCustomerFile: false,
    errorCheckingCustomerFile: null,
    // Related Document Download
    isLoadingRelatedDocumentDownload: false,
    errorRelatedDocumentDownload: null,
    // Related Document Create
    isLoadingRelatedDocumentCreate: false,
    errorRelatedDocumentCreate: null,
    uploadProgress: 0
  },
  getters: {
    // Fetch All
    isLoadingFetchAll(state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll(state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll(state) {
      return state.errorFetchAll;
    },
    hasOrders(state) {
      return state.orders.length > 0;
    },
    orders(state) {
      return state.orders;
    },
    // Fetch One
    isLoadingFetchOne(state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne(state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne(state) {
      return state.errorFetchOne;
    },
    // Updating Address
    isLoadingUpdatingAddress(state) {
      return state.isLoadingUpdatingAddress;
    },
    hasErrorUpdatingAddress(state) {
      return state.errorUpdatingAddress !== null;
    },
    errorUpdatingAddress(state) {
      return state.errorUpdatingAddress;
    },
    // Creating Comment
    isLoadingCreatingComment(state) {
      return state.isLoadingCreatingComment;
    },
    hasErrorCreatingComment(state) {
      return state.errorCreatingComment !== null;
    },
    errorCreatingComment(state) {
      return state.errorCreatingComment;
    },
    // Making Changes Requested
    isLoadingMakingChangesRequested(state) {
      return state.isLoadingMakingChangesRequested;
    },
    hasErrorMakingChangesRequested(state) {
      return state.errorMakingChangesRequested !== null;
    },
    errorMakingChangesRequested(state) {
      return state.errorMakingChangesRequested;
    },
    // Making Validate
    isLoadingMakingValidate(state) {
      return state.isLoadingMakingValidate;
    },
    hasErrorMakingValidate(state) {
      return state.errorMakingValidate !== null;
    },
    errorMakingValidate(state) {
      return state.errorMakingValidate;
    },
    // Checking Customer File
    isLoadingCheckingCustomerFile(state) {
      return state.isLoadingCheckingCustomerFile;
    },
    hasErrorCheckingCustomerFile(state) {
      return state.errorCheckingCustomerFile !== null;
    },
    errorCheckingCustomerFile(state) {
      return state.errorCheckingCustomerFile;
    },
    // Related Document Download
    isLoadingRelatedDocumentDownload(state) {
      return state.isLoadingRelatedDocumentDownload;
    },
    hasErrorRelatedDocumentDownload(state) {
      return state.errorRelatedDocumentDownload !== null;
    },
    errorRelatedDocumentDownload(state) {
      return state.errorRelatedDocumentDownload;
    },
    // Related Document Create
    isLoadingRelatedDocumentCreate(state) {
      return state.isLoadingRelatedDocumentCreate;
    },
    hasErrorRelatedDocumentCreate(state) {
      return state.errorRelatedDocumentCreate !== null;
    },
    errorRelatedDocumentCreate(state) {
      return state.errorRelatedDocumentCreate;
    },
    uploadProgress(state) {
      return state.uploadProgress;
    }
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.orders = [];
    },
    [FETCH_ALL_SUCCESS](state, orders) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.orders = orders;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.orders = [];
    },
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Updating Address
    [UPDATING_ADDRESS](state) {
      state.isLoadingUpdatingAddress = true;
      state.errorUpdatingAddress = null;
    },
    [UPDATING_ADDRESS_SUCCESS](state) {
      state.isLoadingUpdatingAddress = false;
      state.errorUpdatingAddress = null;
    },
    [UPDATING_ADDRESS_ERROR](state, error) {
      state.isLoadingUpdatingAddress = false;
      state.errorUpdatingAddress = error;
    },
    // Creating Comment
    [CREATING_COMMENT](state) {
      state.isLoadingCreatingComment = true;
      state.errorCreatingComment = null;
    },
    [CREATING_COMMENT_SUCCESS](state) {
      state.isLoadingCreatingComment = false;
      state.errorCreatingComment = null;
    },
    [CREATING_COMMENT_ERROR](state, error) {
      state.isLoadingCreatingComment = false;
      state.errorCreatingComment = error;
    },
    // Making Changes Requested
    [MAKING_CHANGES_REQUESTED](state) {
      state.isLoadingMakingChangesRequested = true;
      state.errorMakingChangesRequested = null;
    },
    [MAKING_CHANGES_REQUESTED_SUCCESS](state) {
      state.isLoadingMakingChangesRequested = false;
      state.errorMakingChangesRequested = null;
    },
    [MAKING_CHANGES_REQUESTED_ERROR](state, error) {
      state.isLoadingMakingChangesRequested = false;
      state.errorMakingChangesRequested = error;
    },
    // Making Validate
    [MAKING_VALIDATE](state) {
      state.isLoadingMakingValidate = true;
      state.errorMakingValidate = null;
    },
    [MAKING_VALIDATE_SUCCESS](state) {
      state.isLoadingMakingValidate = false;
      state.errorMakingValidate = null;
    },
    [MAKING_VALIDATE_ERROR](state, error) {
      state.isLoadingMakingValidate = false;
      state.errorMakingValidate = error;
    },
    // Checking Customer File
    [CHECKING_CUSTOMER_FILE](state) {
      state.isLoadingCheckingCustomerFile = true;
      state.errorCheckingCustomerFile = null;
    },
    [CHECKING_CUSTOMER_FILE_SUCCESS](state) {
      state.isLoadingCheckingCustomerFile = false;
      state.errorCheckingCustomerFile = null;
    },
    [CHECKING_CUSTOMER_FILE_ERROR](state, error) {
      state.isLoadingCheckingCustomerFile = false;
      state.errorCheckingCustomerFile = error;
    },
    // Related Document Download
    [RELATED_DOCUMENT_DOWNLOAD](state) {
      state.isLoadingRelatedDocumentDownload = true;
      state.errorRelatedDocumentDownload = null;
    },
    [RELATED_DOCUMENT_DOWNLOAD_SUCCESS](state) {
      state.isLoadingRelatedDocumentDownload = false;
      state.errorRelatedDocumentDownload = null;
    },
    [RELATED_DOCUMENT_DOWNLOAD_ERROR](state, error) {
      state.isLoadingRelatedDocumentDownload = false;
      state.errorRelatedDocumentDownload = error;
    },
    // Related Document Create
    [RELATED_DOCUMENT_CREATE](state) {
      state.isLoadingRelatedDocumentCreate = true;
      state.errorRelatedDocumentCreate = null;
    },
    [RELATED_DOCUMENT_CREATE_SUCCESS](state) {
      state.isLoadingRelatedDocumentCreate = false;
      state.errorRelatedDocumentCreate = null;
    },
    [RELATED_DOCUMENT_CREATE_ERROR](state, error) {
      state.isLoadingRelatedDocumentCreate = false;
      state.errorRelatedDocumentCreate = error;
    },
    [SET_UPLOAD_PROGRESS](state, uploadProgress) {
      state.uploadProgress = uploadProgress;
    },
  },
  actions: {
    async findAll({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await AccountOrderAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.orders);
          return response.data.orders;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountOrderAPI.findOne(payload.order);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.order;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async updateAddress({ commit }, payload) {
      commit(UPDATING_ADDRESS);
      try {
        let response = await AccountOrderAPI.updateAddress(payload.order, payload.form);
        if (response.data.success === true) {
          commit(UPDATING_ADDRESS_SUCCESS, response.data);
          return null;
        } else {
          commit(UPDATING_ADDRESS_ERROR);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ADDRESS_ERROR);
        return null;
      }
    },
    async createComment({ commit }, payload) {
      commit(CREATING_COMMENT);
      try {
        let response = await AccountOrderAPI.createComment(payload.order, payload.form);
        if (response.data.success === true) {
          commit(CREATING_COMMENT_SUCCESS, response.data);
          return response.data;
        } else {
          commit(CREATING_COMMENT_ERROR);
          return null;
        }
      } catch (error) {
        commit(CREATING_COMMENT_ERROR);
        return null;
      }
    },
    async makingChangesRequested({ commit }, payload) {
      commit(MAKING_CHANGES_REQUESTED);
      try {
        let response = await AccountOrderAPI.makingChangesRequested(payload.order, payload.form);
        if (response.data.success === true) {
          commit(MAKING_CHANGES_REQUESTED_SUCCESS, response.data);
          return response.data;
        } else {
          commit(MAKING_CHANGES_REQUESTED_ERROR);
          return null;
        }
      } catch (error) {
        commit(MAKING_CHANGES_REQUESTED_ERROR);
        return null;
      }
    },
    async makingValidate({ commit }, payload) {
      commit(MAKING_VALIDATE);
      try {
        let response = await AccountOrderAPI.makingValidate(payload.order);
        if (response.data.success === true) {
          commit(MAKING_VALIDATE_SUCCESS, response.data);
          return response.data;
        } else {
          commit(MAKING_VALIDATE_ERROR);
          return null;
        }
      } catch (error) {
        commit(MAKING_VALIDATE_ERROR);
        return null;
      }
    },
    async checkingCustomerFile({ commit }, payload) {
      commit(CHECKING_CUSTOMER_FILE);
      try {
        let response = await AccountOrderAPI.checkingCustomerFile(payload.order);
        if (response.data.success === true) {
          commit(CHECKING_CUSTOMER_FILE_SUCCESS, response.data);
          return response.data;
        } else {
          commit(CHECKING_CUSTOMER_FILE_ERROR);
          return null;
        }
      } catch (error) {
        commit(CHECKING_CUSTOMER_FILE_ERROR);
        return null;
      }
    },
    async relatedDocumentDownload({ commit }, payload) {
      commit(RELATED_DOCUMENT_DOWNLOAD);
      try {
        let response = await AccountOrderAPI.relatedDocumentDownload(payload.order);
        if (response.data.success === true) {
          commit(RELATED_DOCUMENT_DOWNLOAD_SUCCESS, response.data);
          return response.data;
        } else {
          commit(RELATED_DOCUMENT_DOWNLOAD_ERROR);
          return null;
        }
      } catch (error) {
        commit(RELATED_DOCUMENT_DOWNLOAD_ERROR);
        return null;
      }
    },
    async relatedDocumentCreate({ commit }, payload) {
      commit(RELATED_DOCUMENT_CREATE);
      try {
        let response = await AccountOrderAPI.relatedDocumentCreate({
          store: this,
          order: payload.order,
          form: payload.form
        });

        if (response.data.success === true) {
          commit(RELATED_DOCUMENT_CREATE_SUCCESS, response.data);
          return response.data;
        } else {
          commit(RELATED_DOCUMENT_CREATE_ERROR);
          return null;
        }
      } catch (error) {
        commit(RELATED_DOCUMENT_CREATE_ERROR);
        return null;
      }
    },
    setUploadProgress({ commit }, uploadProgress) {
      commit(SET_UPLOAD_PROGRESS, uploadProgress)
    },
  }
}
