<template>
  <div class="main-container">
    <router-link
      :to="{ name: 'product', params: { id: id, urlName: urlName } }"
      class="h-100"
    >
      <div class="inner-container">
        <div class="image-part">
          <div class="image-overlay"></div>
          <b-img :src="image.src" :alt="image.alt"></b-img>
        </div>
        <div class="text-part">
          <h4>{{ name }}</h4>
          <p>{{ description }}</p>
          <template v-if="displayPrice && minProductPrice">
            <div class="product-price">
              {{ minProductPrice.step }}
              {{ $t('front.catalog.startingPrice') }}
              {{ formatPrice(minProductPrice.priceHt) }} (HT)
            </div>
          </template>
          <div class="product-link">
            <span>
              {{ $t("front.catalog.seeProduct") }}
            </span>
            <i class="fas fa-arrow-right pl-2"></i>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "CatalogItem",
  props: ["id", "image", "name", "description", "displayPrice", "minProductPrice", "urlName"],
};
</script>

<style lang="scss" scoped>
.main-container {
  padding: 1.5rem;

  .inner-container {
    display: inline-block;
    height: 100%;
    width: 100%;
    max-width: 390px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    cursor: pointer;

    position: relative;
    overflow: hidden;

    &:hover {
      .image-overlay {
        background-color: rgba(0, 0, 0, 0.2);
      }

      .product-link {
        border-bottom: 2px solid $mizogoo;
      }
    }
  }

  .image-part {
    position: relative;
    width: 100%;
    height: 250px;

    .image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: background-color .2s;
      border-radius: 15px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 15px;
    }
  }

  .text-part {
    color: $mizogoo-gray-dark;

    h4 {
      margin-top: 1rem;
    }

    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      margin-bottom: 0.5rem !important;
    }

    .product-price {
      display: inline-flex;
      background-color: $mizogoo-gray-light;
      margin-bottom: 1rem;
      font-weight: 500;
    }

    .product-link {
      display: inline-flex;
      align-items: center;
      color: $mizogoo;
      font-weight: 500;
      text-transform: uppercase;
      border-bottom: 2px solid transparent;
    }
  }
}
</style>
