import { createI18n } from 'vue-i18n'
import store from '@mizogoo/store'

function loadLocaleMessages () {
  const locales = require.context('@mizogoo/translations', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}

  locales.keys().forEach(key => {
    let locale = key.replace('./', '').replace('.json', '')

    if (locale && locale.length > 0) {
      const split = locale.split('.')

      if (split.length > 1) {
        locale = split[1]

        if (typeof messages[locale][split[0]] === 'undefined') {
          messages[locale][split[0]] = locales(key)
        } else {
          Object.assign(messages[locale][split[0]], locales(key))
        }

      } else {
        if (typeof messages[locale] === 'undefined') {
          messages[locale] = locales(key)
        } else {
          Object.assign(messages[locale], locales(key))
        }
      }
    }
  })

  return messages
}

const datetimeFormats = {
  'fr': {
    string: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    digit: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    digitExtended: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
  },
  'en': {
    string: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    },
    digit: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    digitExtended: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
  }
}

const i18n = createI18n({
  locale: store.getters['global/defaultLang'],
  fallbackLocale: store.getters['global/defaultLang'],
  messages: loadLocaleMessages(),
  datetimeFormats,
  globalInjection: true,
  legacy: false
})

export default i18n
