import Vue from "vue";
import AccountDrinkAPI from "../api/account_drink";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  UPDATING_POSITION = "UPDATING_POSITION",
  UPDATING_POSITION_SUCCESS = "UPDATING_POSITION_SUCCESS",
  UPDATING_POSITION_ERROR = "UPDATING_POSITION_ERROR",

  UPDATING_TRANSLATION = "UPDATING_TRANSLATION",
  UPDATING_TRANSLATION_SUCCESS = "UPDATING_TRANSLATION_SUCCESS",
  UPDATING_TRANSLATION_ERROR = "UPDATING_TRANSLATION_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  ACTIVING = "ACTIVING",
  ACTIVING_SUCCESS = "ACTIVING_SUCCESS",
  ACTIVING_ERROR = "ACTIVING_ERROR",
  
  CHECKING_FILE = "CHECKING_FILE",
  CHECKING_FILE_SUCCESS = "CHECKING_FILE_SUCCESS",
  CHECKING_FILE_ERROR = "CHECKING_FILE_ERROR",

  VALIDATING = "VALIDATING",
  VALIDATING_SUCCESS = "VALIDATING_SUCCESS",
  VALIDATING_ERROR = "VALIDATING_ERROR",

  IMPORTING = "IMPORTING",
  IMPORTING_SUCCESS = "IMPORTING_SUCCESS",
  IMPORTING_ERROR = "IMPORTING_ERROR",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    drinks: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Updating Position
    isLoadingUpdatingPosition: false,
    errorUpdatingPosition: null,
    // Updating Translation
    isLoadingUpdatingTranslation: false,
    errorUpdatingTranslation: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Activing
    isLoadingActiving: false,
    errorActiving: null,
    // Checking File
    isLoadingCheckingFile: false,
    errorCheckingFile: null,
    // Validating
    isLoadingValidating: false,
    errorValidating: null,
    // Importing
    isLoadingImporting: false,
    errorImporting: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasDrinks (state) {
      return state.drinks.length > 0;
    },
    drinks (state) {
      return state.drinks;
    },
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne (state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Updating Position
    isLoadingUpdatingPosition (state) {
      return state.isLoadingUpdatingPosition;
    },
    hasErrorUpdatingPosition (state) {
      return state.errorUpdatingPosition !== null;
    },
    errorUpdatingPosition (state) {
      return state.errorUpdatingPosition;
    },
    // Updating Translation
    isLoadingUpdatingTranslation (state) {
      return state.isLoadingUpdatingTranslation;
    },
    hasErrorUpdatingTranslation (state) {
      return state.errorUpdatingTranslation !== null;
    },
    errorUpdatingTranslation (state) {
      return state.errorUpdatingTranslation;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Activing
    isLoadingActiving (state) {
      return state.isLoadingActiving;
    },
    hasErrorActiving (state) {
      return state.errorActiving !== null;
    },
    errorActiving (state) {
      return state.errorActiving;
    },
    // Checking File
    isLoadingCheckingFile (state) {
      return state.isLoadingCheckingFile;
    },
    hasErrorCheckingFile (state) {
      return state.errorCheckingFile !== null;
    },
    errorCheckingFile (state) {
      return state.errorCheckingFile;
    },
    // Validating
    isLoadingValidating (state) {
      return state.isLoadingValidating;
    },
    hasErrorValidating (state) {
      return state.errorValidating !== null;
    },
    errorValidating (state) {
      return state.errorValidating;
    },
    // Importing
    isLoadingImporting (state) {
      return state.isLoadingImporting;
    },
    hasErrorImporting (state) {
      return state.errorImporting !== null;
    },
    errorImporting (state) {
      return state.errorImporting;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.drinks = [];
    },
    [FETCH_ALL_SUCCESS] (state, drinks) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.drinks = drinks;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.drinks = [];
    },
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state, drink) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.drinks.push(drink);
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state, drink) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      if (state.drinks.length > 0) {
        let drinkToUpdateIndex = state.drinks.findIndex(element => element.id === drink.id);
        if (drinkToUpdateIndex != -1) {
          // If the category has changed, position of the others items of the previous category must be updated
          if (state.drinks[drinkToUpdateIndex].drinkCategoryId != drink.drinkCategoryId) {
            for (let i = 0; i < state.drinks.length; i++) {
              let currentDrink = state.drinks[i];
              // If the drink belong to the same drinkCategory
              if (currentDrink.drinkCategoryId === drink.drinkCategoryId) {
                // And has a superior position
                if (currentDrink.position > drink.position) {
                  // It should be reduced by one
                  currentDrink.position = currentDrink.position - 1;
                }
              }
            }
          }
          state.drinks.splice(drinkToUpdateIndex, 1, drink);
        }
      }
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Updating Position
    [UPDATING_POSITION] (state) {
      state.isLoadingUpdatingPosition = true;
      state.errorUpdatingPosition = null;
    },
    [UPDATING_POSITION_SUCCESS] (state, positions) {
      for (let i = 0; i < positions.length; i++) {
        let drinkToUpdate = state.drinks.find(element => element.id === parseInt(positions[i].id));
        drinkToUpdate.position = positions[i].position;
      }
      state.isLoadingUpdatingPosition = false;
      state.errorUpdatingPosition = null;
    },
    [UPDATING_POSITION_ERROR] (state, error) {
      state.isLoadingUpdatingPosition = false;
      state.errorUpdatingPosition = error;
    },
    // Updating Translation
    [UPDATING_TRANSLATION] (state) {
      state.isLoadingUpdatingTranslation = true;
      state.errorUpdatingTranslation = null;
    },
    [UPDATING_TRANSLATION_SUCCESS] (state) {
      state.isLoadingUpdatingTranslation = false;
      state.errorUpdatingTranslation = null;
    },
    [UPDATING_TRANSLATION_ERROR] (state, error) {
      state.isLoadingUpdatingTranslation = false;
      state.errorUpdatingTranslation = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, drink) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      for (let i = 0; i < state.drinks.length; i++) {
        let currentDrink = state.drinks[i];
        if (currentDrink.drinkCategoryId === drink.drinkCategoryId) {
          if (currentDrink.position > drink.position) {
            currentDrink.position = currentDrink.position - 1;
          }
        }
      }
      state.drinks = state.drinks.filter(element => element.id !== drink.id)
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Activing
    [ACTIVING] (state) {
      state.isLoadingActiving = true;
      state.errorActiving = null;
    },
    [ACTIVING_SUCCESS] (state, drink) {
      state.isLoadingActiving = false;
      state.errorActiving = null;
      const drinkToActivate = state.drinks.find(element => element.id === drink.id);
      drinkToActivate.active = drink.active;
    },
    [ACTIVING_ERROR] (state, error) {
      state.isLoadingActiving = false;
      state.errorActiving = error;
    },
    // Checking File
    [CHECKING_FILE] (state) {
      state.isLoadingCheckingFile = true;
      state.errorCheckingFile = null;
    },
    [CHECKING_FILE_SUCCESS] (state) {
      state.isLoadingCheckingFile = false;
      state.errorCheckingFile = null;
    },
    [CHECKING_FILE_ERROR] (state, error) {
      state.isLoadingCheckingFile = false;
      state.errorCheckingFile = error;
    },
    // Validating
    [VALIDATING] (state) {
      state.isLoadingValidating = true;
      state.errorValidating = null;
    },
    [VALIDATING_SUCCESS] (state) {
      state.isLoadingValidating = false;
      state.errorValidating = null;
    },
    [VALIDATING_ERROR] (state, error) {
      state.isLoadingValidating = false;
      state.errorValidating = error;
    },
    // Importing
    [IMPORTING] (state) {
      state.isLoadingImporting = true;
      state.errorImporting = null;
    },
    [IMPORTING_SUCCESS] (state, drinks) {
      state.isLoadingImporting = false;
      state.errorImporting = null;
      state.drinks.push(...drinks);
    },
    [IMPORTING_ERROR] (state, error) {
      state.isLoadingImporting = false;
      state.errorImporting = error;
      state.drinks = [];
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.drinks = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.accountDrink.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountDrinkAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.drinks);
            return response.data.drinks;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountDrinkAPI.findOne(payload.drink);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.drink;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountDrinkAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.drink);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountDrinkAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.drink);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async updatePosition ({ commit }, payload) {
      commit(UPDATING_POSITION);
      try {
        let response = await AccountDrinkAPI.updatePosition(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_POSITION_SUCCESS, payload.form.positions);
          return response.data;
        } else {
          commit(UPDATING_POSITION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_POSITION_ERROR, error);
        return null;
      }
    },
    async updateTranslation ({ commit }, payload) {
      commit(UPDATING_TRANSLATION);
      try {
        let response = await AccountDrinkAPI.updateTranslation(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_TRANSLATION_SUCCESS);
          return response.data;
        } else {
          commit(UPDATING_TRANSLATION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_TRANSLATION_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountDrinkAPI.remove(payload.drink);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.drink);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async active ({ commit }, payload) {
      commit(ACTIVING);
      try {
        let response = await AccountDrinkAPI.active(payload.drink);
        if (response.data.success === true) {
          commit(ACTIVING_SUCCESS, response.data.drink);
          return response.data;
        } else {
          commit(ACTIVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(ACTIVING_ERROR, error);
        return null;
      }
    },
    async checkFile ({ commit }, payload) {
      commit(CHECKING_FILE);
      try {
        let response = await AccountDrinkAPI.checkFile(payload.form);
        if (response.data.success === true) {
          commit(CHECKING_FILE_SUCCESS);
          return response.data.rows;
        } else {
          commit(CHECKING_FILE_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CHECKING_FILE_ERROR, error);
        return null;
      }
    },
    async validate ({ commit }, payload) {
      commit(VALIDATING);
      try {
        let response = await AccountDrinkAPI.validate(payload.form);
        if (response.data.success === true) {
          commit(VALIDATING_SUCCESS);
          return response.data.importDrinks;
        } else {
          commit(VALIDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(VALIDATING_ERROR, error);
        return null;
      }
    },
    async import ({ commit }, payload) {
      commit(IMPORTING);
      try {
        let response = await AccountDrinkAPI.import(payload.drinks);
        if (response.data.success === true) {
          commit(IMPORTING_SUCCESS, response.data.drinks);
          return response.data;
        } else {
          commit(IMPORTING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(IMPORTING_ERROR, error);
        return null;
      }
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
