import mizogooLogo from "../assets/img/mizogoo_logo.png";
import mizogooLogoSquare from "../assets/img/mizogoo_logo_square.png";
import mizogooLogoSquareWhite from "../assets/img/mizogoo_logo_square_white.png";

export default {
  data() {
    return {
      mizogooLogo: mizogooLogo,
      mizogooLogoSquare: mizogooLogoSquare,
      mizogooLogoSquareWhite: mizogooLogoSquareWhite,
    };
  },
  methods: {
    handleFormErrors(refs) {
      const errors = Object.entries(refs)
        .map(([key, value]) => ({ key, value }))
        .filter((error) => {
          if (!error || !error.value || !error.value.failedRules) return false;
          return Object.keys(error.value.failedRules).length > 0;
        });
      if (errors && errors.length > 0) {
        refs[errors[0]["key"]].$el.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    },
    /**
     * Displays a Bootstrap toast. Can be used to display notification when an action is complete.
     * Exemple: A well removed item
     * @param {string} message
     **/
    toast(message) {
      setTimeout(
        () => {
          this.$bvToast.toast(message, {
            title: message,
            toaster: "b-toaster-bottom-center",
            solid: true,
            appendToast: false,
            toastClass: "action-notification-feedback",
            bodyClass: "d-none",
            autoHideDelay: 5000,
          })
        },
        800
      );
    },
    /**
     * Programmaticaly close the dotted dropdown
     * Example: Used when the user leave the parent container
     */
    closeDottedDropdown() {
      this.$refs['dotted-dropdown'].visible = false;
    },
    async resetCache() {
      await Promise.all([
        this.$store.dispatch("accountDishCategory/resetCache"),
        this.$store.dispatch("accountDish/resetCache"),
        this.$store.dispatch("accountWineCategory/resetCache"),
        this.$store.dispatch("accountWine/resetCache"),
        this.$store.dispatch("accountBeerCategory/resetCache"),
        this.$store.dispatch("accountBeer/resetCache"),
        this.$store.dispatch("accountDrinkCategory/resetCache"),
        this.$store.dispatch("accountDrink/resetCache"),
        this.$store.dispatch("accountMenuCategory/resetCache"),
        this.$store.dispatch("accountMenu/resetCache"),
        this.$store.dispatch("accountProductCategoryPosition/resetCache"),
        this.$store.dispatch("accountWinePairing/resetCache"),
        this.$store.dispatch("accountInstitutionReview/resetCache"),
      ]);
    }
  },
  computed: {
    isWorkspace() {
      return this.$store.getters["global/isWorkspace"];
    },
    isQrCode() {
      return this.$store.getters["global/isQrCode"];
    },
    isMobileApp() {
      return this.$store.getters["global/isMobileApp"];
    },
    civilities() {
      return this.$store.getters["global/civilities"];
    },
    countries() {
      return this.$store.getters["global/countries"];
    },
    recaptchaPublicKey() {
      return this.$store.getters["global/recaptchaPublicKey"];
    },
    isAuthenticated() {
      return this.$store.getters["security/isAuthenticated"];
    },
    user() {
      return this.$store.getters["security/user"];
    },
    userDefaultInstitution() {
      return this.user.institutions.find(element => element.isDefault === true);
    },
    frontHeader() {
      return this.$store.getters["global/header"];
    },
    frontFooter() {
      return this.$store.getters["global/footer"];
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        dragClass: "drag-item",
        ghostClass: "hidden-ghost",
        // scrollSensitivity: 80,
        // scrollSpeed: 30,
        forceFallback: true,
      };
    },
  },
}
