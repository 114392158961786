<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :fluid="true"
    >
      <!-- Toggle monthly/yearly subscription plan -->
      <template v-if="displayBillingFrequency" v-slot:inside-banner-text>
        <b-row
          class="subscription-options"
          v-scrollanimation="{ name: 'fade-up', delay: 400 }"
        >
          <template v-for="subscription in subscriptionOptions" :key="subscription.id">
            <div
              v-on:click="setBillingFrequency(subscription.id)"
              class="subscription-button"
              :class="billingFrequency === subscription.id ? 'selected-option' : ''"
            >
              {{ subscription.name }}
            </div>
          </template>
        </b-row>
        <b-row
          align-v="center"
          v-scrollanimation="{ name: 'fade-up', delay: 500 }"
          class="mt-2"
        >
          <b-col class="text-center font-italic">
            {{ topBanner.yearlyDiscount }}
          </b-col>
        </b-row>
      </template>

      <subscription-block
        :billingFrequency="billingFrequency"
        :currentPlan="currentSubscriptionPlan ? currentSubscriptionPlan : null"
      />

      <!-- Free subscription block (displayed below) -->
      <b-container>
        <div class="free-subscription-header">
          <h2>{{ freeSubscription.sectionHeader }}</h2>
        </div>

        <div class="free-subscription-column">
          <b-row class="subscription-column-content-container">
            <b-col class="subscription-column-header">
              <div class="subscription-column-name">{{ freeSubscription.name }}</div>
              <div class="subscription-column-price-container">
                <span class="subscription-column-price"
                  >{{ freeSubscription.price }}€</span
                >
                <span class="subscription-column-subprice">
                  /{{ this.$t("front.subscription.global.month") }}
                </span>
              </div>
              <div class="subscription-column-extra-info">
                {{ freeSubscription.extraInfo }}
              </div>
              <div class="subscription-column-description">
                {{ freeSubscription.description }}
              </div>
              <div class="subscription-column-button">
                <router-link
                  :to="{ name: freeSubscription.button.link }"
                  custom
                  v-slot="{ navigate }"
                >
                  <button
                    class="btn btn-outline-primary"
                    @click="navigate"
                    @keypress.enter="navigate"
                  >
                    {{ freeSubscription.button.label }}
                  </button>
                </router-link>
              </div>
            </b-col>

            <b-col class="subscription-column-features">
              <div class="feature-list">
                <div
                  v-for="feat in freeSubscription.features"
                  :key="feat.id"
                  class="feature-list-item"
                >
                  <div class="active-feature">
                    <span class="feature-text">{{ feat.name }}</span>
                    <span class="feature-icon fas fa-check"></span>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </top-banner>

    <div id="banner-anchor"></div>

    <banner
      :image="this.banner.image"
      :subheading="this.banner.subheading"
      :heading="this.banner.heading"
      :description="this.banner.description"
      :button="this.banner.button"
      :overlay="this.banner.overlay"
      :centered="this.banner.centered"
    ></banner>

    <subscription-details
      :billingFrequency="billingFrequency"
      :currentPlan="currentSubscriptionPlan ? currentSubscriptionPlan : null"
    />

    <div id="faq" class="separator"></div>

    <faq></faq>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import SubscriptionBlock from "@mizogoo/components/front/layout/subscription/SubscriptionBlock";
import Banner from "@mizogoo/components/front/layout/Banner";
import SubscriptionDetails from "@mizogoo/components/front/layout/subscription/SubscriptionDetails";
import Faq from "@mizogoo/components/front/layout/subscription/FAQ";

export default {
  mixins: [MetaMixin],
  name: "SubscriptionPage",
  components: {
    TopBanner,
    SubscriptionBlock,
    Banner,
    SubscriptionDetails,
    Faq,
  },
  data() {
    return {
      billingFrequency: "MONTHLY",
    };
  },
  methods: {
    setBillingFrequency(option) {
      this.billingFrequency = option;
    },
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.subscription.title");
    },
    metaDescription() {
      return this.$t("front.metadata.subscription.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.subscription.page.topBanner.heading"),
        description: this.$t("front.subscription.page.topBanner.description"),
        yearlyDiscount: this.$t("front.subscription.page.topBanner.yearlyDiscount"),
      };
    },
    subscriptionOptions() {
      return [
        {
          id: "MONTHLY",
          name: this.$t("front.subscription.global.monthly"),
        },
        {
          id: "YEARLY",
          name: this.$t("front.subscription.global.yearly"),
        },
      ];
    },
    freeSubscription() {
      return {
        sectionHeader: this.$t("front.subscription.global.columns[0].sectionHeader"),
        name: this.$t("front.subscription.global.columns[0].name"),
        price: 0,
        description: this.$t("front.subscription.global.columns[0].description"),
        extraInfo: this.$t("front.subscription.global.columns[0].extraInfo"),
        features: [
          {
            id: 0,
            name: this.$t("front.subscription.global.columns[0].features[0]"),
          },
          {
            id: 1,
            name: this.$t("front.subscription.global.columns[0].features[1]"),
          },
          {
            id: 2,
            name: this.$t("front.subscription.global.columns[0].features[2]"),
          },
          {
            id: 3,
            name: this.$t("front.subscription.global.columns[0].features[3]"),
          },
          {
            id: 4,
            name: this.$t("front.subscription.global.columns[0].features[4]"),
          },
          {
            id: 5,
            name: this.$t("front.subscription.global.columns[0].features[5]"),
          },
        ],
        button: {
          label: this.$t("front.subscription.global.columns[0].buttonLabel"),
          link: "workspace",
        },
      };
    },
    banner() {
      return {
        image: {
          background: {
            src: require("@mizogoo/assets/img/front/global/banners/digital_menu_creation.jpg"),
            alt: this.$t("front.subscription.page.banner.imageAlt"),
          },
        },
        subheading: this.$t("front.subscription.page.banner.subheading"),
        heading: this.$tm("front.subscription.page.banner.heading"),
        description: this.$t("front.subscription.page.banner.description"),
        overlay: true,
        centered: true,
      };
    },
    currentSubscriptionPlan() {
      if (
        this.user &&
        this.user.currentSubscription &&
        this.user.currentSubscription.isValid &&
        !this.user.currentSubscription.isFree
      ) {
        return this.user.currentSubscription.subscription.constName;
      }
    },
    // Do not display monthly if the user already have an annual subscription
    displayBillingFrequency() {
      return this.user &&
        this.user.currentSubscription &&
        !this.user.currentSubscription.subscription.isMonthly
        ? false
        : true;
    },
  },
  mounted() {
    if (!this.displayBillingFrequency) {
      this.billingFrequency = "YEARLY";
    }
  },
};
</script>

<style lang="scss" scoped>
// Monthly or yearly switch
.subscription-options {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .subscription-button {
    margin: 0.4rem 0.2rem;
    padding: 0.6rem 1.4rem;
    background-color: $white;
    color: $mizogoo-gray-dark;
    outline: 2px solid transparent;
    -moz-outline-radius: 2px;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome, Opera, Firefox */

    &:hover {
      color: $mizogoo;
    }
  }

  .selected-option {
    color: $mizogoo;
    outline: 2px solid $mizogoo;
    outline-offset: -5px;
  }
}

// Free subscription section
.free-subscription-header {
  padding-top: 6rem;
  padding-bottom: 2.5rem;
  color: $mizogoo-gray-dark;
}

.free-subscription-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: $mizogoo-gray-dark;
  background-color: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  padding: 3rem !important;
  outline: 3px solid transparent;
  transition: outline 0.25s ease;
  position: relative;
  z-index: 2;
  border-radius: 15px;

  &:hover {
    outline: 3px solid #ff395e;
  }

  .subscription-column-name {
    width: 100%;
    font-weight: 600;
    color: #c2c2c2;
    text-transform: uppercase;
  }

  .subscription-column-price {
    width: 100%;
    position: relative;
    font-size: 2.6rem;
    font-weight: bold;
    line-height: 1.2;
    color: #151f29;
  }

  .subscription-column-subprice {
    font-weight: bold;
    color: $mizogoo-gray-dark;
  }

  .subscription-column-extra-info {
    margin-top: 0.5rem;
    color: #c2c2c2;
    font-style: italic;
  }

  .subscription-column-description {
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: $mizogoo-gray-dark;
  }

  .subscription-column-features {
    .feature-list {
      .feature-list-item {
        border: 0;
        padding: 0.5rem;
        color: #151f29;

        .active-feature {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .feature-text {
            text-align: left;
          }

          .feature-icon {
            margin-left: 1rem;
            width: 15px;
            height: 15px;
            color: #ff395e;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .subscription-details {
    display: none;
  }
}
</style>
