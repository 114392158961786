import StripeAPI from '../api/stripe'

const
  PAYMENT_VALIDATE = 'PAYMENT_VALIDATE',
  PAYMENT_VALIDATE_SUCCESS = 'PAYMENT_VALIDATE_SUCCESS',
  PAYMENT_VALIDATE_ERROR = 'PAYMENT_VALIDATE_ERROR',

  RETRIVE_SUBSCRIPTION = 'RETRIVE_SUBSCRIPTION',
  RETRIVE_SUBSCRIPTION_SUCCESS = 'RETRIVE_SUBSCRIPTION_SUCCESS',
  RETRIVE_SUBSCRIPTION_ERROR = 'RETRIVE_SUBSCRIPTION_ERROR',

  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR',

  UNCANCEL_SUBSCRIPTION = 'UNCANCEL_SUBSCRIPTION',
  UNCANCEL_SUBSCRIPTION_SUCCESS = 'UNCANCEL_SUBSCRIPTION_SUCCESS',
  UNCANCEL_SUBSCRIPTION_ERROR = 'UNCANCEL_SUBSCRIPTION_ERROR'

export default {
  namespaced: true,
  state: {
    // Payment Validate
    isLoadingPaymentValidate: false,
    errorPaymentValidate: null,
    // Retrive Subscription
    isLoadingRetriveSubscription: false,
    errorRetriveSubscription: null,
    // Cancel Subscription
    isLoadingCancelSubscription: false,
    errorCancelSubscription: null,
    // Uncancel Subscription
    isLoadingUncancelSubscription: false,
    errorUncancelSubscription: null
  },
  getters: {
    // Payment Validate
    isLoadingPaymentValidate (state) {
      return state.isLoadingPaymentValidate
    },
    hasErrorPaymentValidate (state) {
      return state.errorPaymentValidate !== null
    },
    errorPaymentValidate (state) {
      return state.errorPaymentValidate
    },
    // Retrive Subscription
    isLoadingRetriveSubscription (state) {
      return state.isLoadingRetriveSubscription
    },
    hasErrorRetriveSubscription (state) {
      return state.errorRetriveSubscription !== null
    },
    errorRetriveSubscription (state) {
      return state.errorRetriveSubscription
    },
    // Cancel Subscription
    isLoadingCancelSubscription (state) {
      return state.isLoadingCancelSubscription
    },
    hasErrorCancelSubscription (state) {
      return state.errorCancelSubscription !== null
    },
    errorCancelSubscription (state) {
      return state.errorCancelSubscription
    },
    // Uncancel Subscription
    isLoadingUncancelSubscription (state) {
      return state.isLoadingUncancelSubscription
    },
    hasErrorUncancelSubscription (state) {
      return state.errorUncancelSubscription !== null
    },
    errorUncancelSubscription (state) {
      return state.errorUncancelSubscription
    }
  },
  mutations: {
    // Payment Validate
    [PAYMENT_VALIDATE] (state) {
      state.isLoadingPaymentValidate = true
      state.errorPaymentValidate = null
    },
    [PAYMENT_VALIDATE_SUCCESS] (state) {
      state.isLoadingPaymentValidate = false
      state.errorPaymentValidate = null
    },
    [PAYMENT_VALIDATE_ERROR] (state, error) {
      state.isLoadingPaymentValidate = false
      state.errorPaymentValidate = error
    },
    // Retrive Subscription
    [RETRIVE_SUBSCRIPTION] (state) {
      state.isLoadingRetriveSubscription = true
      state.errorRetriveSubscription = null
    },
    [RETRIVE_SUBSCRIPTION_SUCCESS] (state) {
      state.isLoadingRetriveSubscription = false
      state.errorRetriveSubscription = null
    },
    [RETRIVE_SUBSCRIPTION_ERROR] (state, error) {
      state.isLoadingRetriveSubscription = false
      state.errorRetriveSubscription = error
    },
    // Cancel Subscription
    [CANCEL_SUBSCRIPTION] (state) {
      state.isLoadingCancelSubscription = true
      state.errorCancelSubscription = null
    },
    [CANCEL_SUBSCRIPTION_SUCCESS] (state, currentSubscription) {
      state.isLoadingCancelSubscription = false
      state.errorCancelSubscription = null

      let user = this.getters['security/user']
      user.currentSubscription = currentSubscription
    },
    [CANCEL_SUBSCRIPTION_ERROR] (state, error) {
      state.isLoadingCancelSubscription = false
      state.errorCancelSubscription = error
    },
    // Uncancel Subscription
    [UNCANCEL_SUBSCRIPTION] (state) {
      state.isLoadingUncancelSubscription = true
      state.errorUncancelSubscription = null
    },
    [UNCANCEL_SUBSCRIPTION_SUCCESS] (state, currentSubscription) {
      state.isLoadingUncancelSubscription = false
      state.errorUncancelSubscription = null

      let user = this.getters['security/user']
      user.currentSubscription = currentSubscription
    },
    [UNCANCEL_SUBSCRIPTION_ERROR] (state, error) {
      state.isLoadingUncancelSubscription = false
      state.errorUncancelSubscription = error
    }
  },
  actions: {
    async validate ({ commit }, payload) {
      commit(PAYMENT_VALIDATE)
      try {
        let response = await StripeAPI.validate(payload.form)
        if (response.data.success === true) {
          commit(PAYMENT_VALIDATE_SUCCESS)
          return response.data
        } else {
          commit(PAYMENT_VALIDATE_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(PAYMENT_VALIDATE_ERROR, error)
        return null
      }
    },
    async retriveSubscription ({ commit }) {
      commit(RETRIVE_SUBSCRIPTION)
      try {
        let response = await StripeAPI.retriveSubscription()
        if (response.data.success === true) {
          commit(RETRIVE_SUBSCRIPTION_SUCCESS)
          return response.data
        } else {
          commit(RETRIVE_SUBSCRIPTION_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(RETRIVE_SUBSCRIPTION_ERROR, error)
        return null
      }
    },
    async cancelSubscription ({ commit }) {
      commit(CANCEL_SUBSCRIPTION)
      try {
        let response = await StripeAPI.cancelSubscription()
        if (response.data.success === true) {
          commit(CANCEL_SUBSCRIPTION_SUCCESS, response.data.currentSubscription)
          return response.data
        } else {
          commit(CANCEL_SUBSCRIPTION_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(CANCEL_SUBSCRIPTION_ERROR, error)
        return null
      }
    },
    async uncancelSubscription ({ commit }) {
      commit(UNCANCEL_SUBSCRIPTION)
      try {
        let response = await StripeAPI.uncancelSubscription()
        if (response.data.success === true) {
          commit(UNCANCEL_SUBSCRIPTION_SUCCESS, response.data.currentSubscription)
          return response.data
        } else {
          commit(UNCANCEL_SUBSCRIPTION_ERROR, response.data.message)
          return null
        }
      } catch (error) {
        commit(UNCANCEL_SUBSCRIPTION_ERROR, error)
        return null
      }
    }
  }
}
