import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  create(data) {
    return axios.post("/api/newsletter/subscription/create", Tool.createFormData(data));
  },
  remove(data) {
    return axios.post("/api/newsletter/subscription/remove/" + data.email, Tool.createFormData());
  },
};
