import AccountInstitutionAppPersonalizationAPI from "../api/account_institution_app_personalization";

const
  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
  },
  getters: {
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne (state) {
      return state.errorFetchOne;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
  },
  mutations: {
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
  },
  actions: {
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountInstitutionAppPersonalizationAPI.findOne(payload.institutionAppPersonalization);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS, response.data.institutionAppPersonalization);
          return response.data.institutionAppPersonalization;
        } else {
          commit(FETCH_ONE_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR, error);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountInstitutionAppPersonalizationAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.institutionAppPersonalization);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
  }
};
