<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <b-container class="main-explanations">
      <b-row>
        <b-col>
          <h3 v-scrollanimation="{ name: 'fade-up-appear' }">
            {{ mainExplanations.subheading }}
          </h3>
          <div class="heading-container">
            <div
              v-for="(headingPart, index) in mainExplanations.heading"
              :key="index"
              class="heading-inner-container"
            >
              <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
            </div>
          </div>
          <p v-scrollanimation="{ name: 'fade-up' }">
            {{ mainExplanations.description }}
          </p>
          <div class="benefits-container">
            <div
              v-for="(benefit, index) in mainExplanations.benefits"
              :key="index"
              class="benefit-container"
            >
              <div class="benefit-id">
                <span>0</span>
                <span>{{ index + 1 }}</span>
                <span class="dot">.</span>
              </div>
              <div class="benefit-text">{{ benefit }}</div>
            </div>
            <div class="benefits-decoration" />
          </div>
        </b-col>
      </b-row>
    </b-container>

    <feature-text-left
      :image="this.featureTextLeft.image"
      :subheading="this.featureTextLeft.subheading"
      :heading="this.featureTextLeft.heading"
      :description="this.featureTextLeft.description"
      :button="this.featureTextLeft.button"
    ></feature-text-left>

    <feature-text-right
      :image="this.featureTextRight.image"
      :subheading="this.featureTextRight.subheading"
      :heading="this.featureTextRight.heading"
      :description="this.featureTextRight.description"
      :button="this.featureTextRight.button"
    ></feature-text-right>

    <b-container fluid class="ai-translation-container">
      <b-container class="ai-translation-inner">
        <b-row>
          <b-col>
            <div class="heading-container">
              <h3 v-scrollanimation="{ name: 'fade-up-appear' }">
                {{ featureCentered.subheading }}
              </h3>
              <div
                v-for="(headingPart, index) in featureCentered.heading"
                :key="index"
                class="heading-inner-container"
              >
                <h2 v-scrollanimation="{ name: 'fade-up-appear' }">{{ headingPart }}</h2>
              </div>
            </div>
            <p v-scrollanimation="{ name: 'fade-up' }">
              {{ featureCentered.description }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import FeatureTextRight from "@mizogoo/components/front/layout/features/FeatureTextRight";

export default {
  mixins: [MetaMixin],
  name: "TranslationPage",
  components: {
    TopBanner,
    FeatureTextLeft,
    FeatureTextRight,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.translation.title");
    },
    metaDescription() {
      return this.$t("front.metadata.translation.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.translation.topBanner.heading"),
        description: this.$t("front.translation.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/translation/banner_translation.png"),
          alt: this.$t("front.translation.topBanner.imageAlt"),
        },
        buttons: [
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    mainExplanations() {
      return {
        subheading: this.$t("front.translation.mainExplanations.subheading"),
        heading: this.$tm("front.translation.mainExplanations.heading"),
        description: this.$t("front.translation.mainExplanations.description"),
        benefits: this.$tm("front.translation.mainExplanations.benefits"),
      };
    },
    featureTextLeft() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/app_enriched/app_translation.png"),
          alt: this.$t("front.translation.featureTextRight.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.translation.featureTextLeft.subheading"),
        heading: this.$tm("front.translation.featureTextLeft.heading"),
        description: this.$t("front.translation.featureTextLeft.description"),
      };
    },
    featureTextRight() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/translation/builder_menu_translation.png"),
          alt: this.$t("front.translation.featureTextRight.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.translation.featureTextRight.subheading"),
        heading: this.$tm("front.translation.featureTextRight.heading"),
        description: this.$t("front.translation.featureTextRight.description"),
      };
    },
    featureCentered() {
      return {
        subheading: this.$t("front.translation.featureCentered.subheading"),
        heading: this.$tm("front.translation.featureCentered.heading"),
        description: this.$t("front.translation.featureCentered.description"),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.main-explanations {
  padding-top: 6rem;
  text-align: center;

  .benefits-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    .benefit-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      min-width: 130px;
      z-index: 2;

      .benefit-id {
        display: flex;
        flex: 0 0 70px;
        justify-content: center;
        align-items: center;
        width: 70px;
        height: 70px;
        margin-bottom: 1rem;
        font-size: 1.4rem;
        font-weight: bold;
        background-color: $mizogoo-gray-light;
        border-radius: 10px;

        .dot {
          color: $mizogoo;
        }
      }

      .benefit-text {
        font-weight: 500;
      }
    }

    .benefits-decoration {
      position: absolute;
      top: 68px;
      width: 100%;
      height: 5px;
      background-color: $mizogoo-gray-light;
      border-radius: 10px;
      z-index: 1;
    }
  }
}

.ai-translation-container {
  padding-top: 5rem;
  padding-bottom: 10rem;

  .ai-translation-inner {
    padding: 7rem 5rem;
    text-align: center;
    background-color: $mizogoo-gray-light;
    background-image: url(../../../../assets/img/front/translation/world_map.png);
    background-position: center;
    border-radius: 25px;

    p {
      max-width: 800px;
      margin: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .benefits-decoration {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .ai-translation-container {
    .ai-translation-inner {
      padding: 5rem 2rem;
    }
  }
}
</style>
