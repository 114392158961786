<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
      :buttons="this.topBanner.buttons"
    >
      <!-- Border decoration -->
      <b-img
        fluid
        center
        :src="this.topBanner.imageDecoration"
        class="decoration-top-banner-image"
      ></b-img>

      <b-img
        fluid
        center
        :src="this.topBanner.image.src"
        :alt="this.topBanner.image.alt"
      ></b-img>
    </top-banner>

    <feature-text-left
      :image="this.featureTextLeft1.image"
      :subheading="this.featureTextLeft1.subheading"
      :heading="this.featureTextLeft1.heading"
      :description="this.featureTextLeft1.description"
    ></feature-text-left>

    <feature-text-right
      :image="this.featureTextRight1.image"
      :subheading="this.featureTextRight1.subheading"
      :heading="this.featureTextRight1.heading"
      :description="this.featureTextRight1.description"
    ></feature-text-right>

    <feature-text-left
      :image="this.featureTextLeft2.image"
      :subheading="this.featureTextLeft2.subheading"
      :heading="this.featureTextLeft2.heading"
      :description="this.featureTextLeft2.description"
    ></feature-text-left>

    <div class="separator"></div>

    <steps :heading="this.steps.heading" :steps="this.steps.steps"></steps>

    <features-presentation-video></features-presentation-video>

    <key-numbers></key-numbers>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import FeatureTextLeft from "@mizogoo/components/front/layout/features/FeatureTextLeft";
import FeatureTextRight from "@mizogoo/components/front/layout/features/FeatureTextRight";
import Steps from "@mizogoo/components/front/layout/features/steps/Steps";
import FeaturesPresentationVideo from "@mizogoo/components/front/layout/features/FeaturesPresentationVideo";
import KeyNumbers from "@mizogoo/components/front/layout/KeyNumbers";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "AppEnrichedPage",
  components: {
    TopBanner,
    FeatureTextLeft,
    FeatureTextRight,
    Steps,
    FeaturesPresentationVideo,
    KeyNumbers,
    InternalLinks,
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.appEnriched.title");
    },
    metaDescription() {
      return this.$t("front.metadata.appEnriched.description");
    },
    topBanner() {
      return {
        heading: this.$tm("front.appEnriched.topBanner.heading"),
        description: this.$t("front.appEnriched.topBanner.description"),
        image: {
          src: require("@mizogoo/assets/img/front/app/app_enriched/banner_app_enriched.png"),
          alt: this.$t("front.appEnriched.topBanner.imageAlt"),
        },
        buttons: [
          {
            link: "workspace",
            label: this.$t("front.navbar.freeTrial"),
          },
        ],
      };
    },
    featureTextLeft1() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/app_enriched/app_highlight.png"),
          alt: this.$t("front.appEnriched.featureTextLeft1.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.appEnriched.featureTextLeft1.subheading"),
        heading: this.$tm("front.appEnriched.featureTextLeft1.heading"),
        description: this.$t("front.appEnriched.featureTextLeft1.description"),
      };
    },
    featureTextRight1() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/app_enriched/app_allergens.png"),
          alt: this.$t("front.appEnriched.featureTextRight1.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.appEnriched.featureTextRight1.subheading"),
        heading: this.$tm("front.appEnriched.featureTextRight1.heading"),
        description: this.$t("front.appEnriched.featureTextRight1.description"),
      };
    },
    featureTextLeft2() {
      return {
        image: {
          src: require("@mizogoo/assets/img/front/app/app_enriched/app_translation.png"),
          alt: this.$t("front.appEnriched.featureTextLeft2.imageAlt"),
          padding: false,
        },
        subheading: this.$t("front.appEnriched.featureTextLeft2.subheading"),
        heading: this.$tm("front.appEnriched.featureTextLeft2.heading"),
        description: this.$t("front.appEnriched.featureTextLeft2.description"),
      };
    },
    steps() {
      return {
        heading: this.$tm("front.appEnriched.steps.heading"),
        steps: [
          {
            id: 0,
            heading: this.$tm("front.appEnriched.steps.steps[0].heading"),
            description: this.$t("front.appEnriched.steps.steps[0].description"),
          },
          {
            id: 1,
            heading: this.$tm("front.appEnriched.steps.steps[1].heading"),
            description: this.$t("front.appEnriched.steps.steps[1].description"),
          },
          {
            id: 2,
            heading: this.$tm("front.appEnriched.steps.steps[2].heading"),
            description: this.$t("front.appEnriched.steps.steps[2].description"),
          },
        ],
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "app_enriched",
            label: this.$t("front.internalLinks.buttons.appEnriched"),
          },
          {
            id: 1,
            link: "app_winepairing",
            label: this.$t("front.internalLinks.buttons.appWinepairing"),
          },
          {
            id: 2,
            link: "app_games",
            label: this.$t("front.internalLinks.buttons.appGames"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ],
      };
    },
  },
};
</script>

<style></style>
