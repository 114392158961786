<template>
  <router-link
    :to="({ name: 'article', params: {
      id: this.article.id, name: this.slugify(this.article.title)
    }})"
    class="card card-acticle mb-5"
  >
    <img :src="article.coverSrc" :alt="article.coverAlt" class="card-img-top">

    <div class="card-body">
      <div class="blog-category-tag">{{ articleCategoryName }}</div>
      <h4 class="card-title">{{ article.title }}</h4>
    </div>

    <div class="card-footer">
      <div>
        <i class="fas fa-calendar mr-1"></i>
        {{ $t("front.blog.postedAt") }}
        {{ $d(new Date(article.date.date), 'digit') }}
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: "ArticleCard",
  props: ['article', 'articleCategoryName'],
}
</script>

<style lang="scss" scoped>
.card-acticle {
  background-color: $mizogoo-gray-light;
  border: none;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  text-decoration: none!important;
  border-radius: 15px;

  &:hover {
    background-color: color.adjust($mizogoo-gray-light, $lightness: -10%);
  }

  img {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  .card-title {
    color: $mizogoo-gray-dark;
  }

  .card-footer {
    padding: 1.2rem !important;
    color: $mizogoo-gray-dark;
    font-weight: 300;
  }
}
</style>
