<template>
  <b-container class="secondary-page-container legal-page">
    <b-row>
      <b-col class="mb-4">
        <h1 class="text-center pb-2">{{ $t("front.legal.heading") }}</h1>
        <p class="last-update-date mb-5">{{ $t("front.legal.lastUpdateDate") }}</p>

        <div v-html="$t('front.legal.description')" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";

export default {
  mixins: [MetaMixin],
  name: "LegalPage",
  computed: {
    metaTitle() {
      return this.$t("front.metadata.legal.title");
    },
  },
  methods: {
    getEmailAdress() {
      let coded = "rED3Ar3@ObErA9.tT";
      let key =
        "2KAz9ysh4CTkFlWS6iJpqBPIXGUfjg7HZLONbMcQnd1ot5vaxmV0Ruer8DYwE3";
      let shift = coded.length;
      let link = "";
      let ltr = "";
      for (let i = 0; i < coded.length; i++) {
        if (key.indexOf(coded.charAt(i)) == -1) {
          ltr = coded.charAt(i);
          link += ltr;
        } else {
          ltr =
            (key.indexOf(coded.charAt(i)) - shift + key.length) % key.length;
          link += key.charAt(ltr);
        }
      }
      return '<a href="mailto:' + link + '">' + link + "</a>";
    },
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>

<style lang="scss" scoped>
.subheading {
  margin-top: 1rem;
  text-decoration: underline;
}
</style>
