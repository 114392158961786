import Vue from "vue";
import AccountWineCategoryAPI from "../api/account_wine_category";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  TOGGLE_WINES_ACTIVATION = "TOGGLE_WINES_ACTIVATION",
  TOGGLE_WINES_ACTIVATION_SUCCESS = "TOGGLE_WINES_ACTIVATION_SUCCESS",
  TOGGLE_WINES_ACTIVATION_ERROR = "TOGGLE_WINES_ACTIVATION_ERROR",
  
  ADD_ITEM_INTO_CATEGORY = "ADD_ITEM_INTO_CATEGORY",
  REMOVE_ITEM_FROM_CATEGORY = "REMOVE_ITEM_FROM_CATEGORY",
  
  ADD_CATEGORY = "ADD_CATEGORY",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    wineCategories: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Analysing
    isLoadingAnalysing: false,
    errorAnalysing: null,
    // Toggle Wines Activation
    isLoadingToggleWinesActivation: false,
    errorToggleWinesActivation: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll(state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll(state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll(state) {
      return state.errorFetchAll;
    },
    hasWineCategories(state) {
      return state.wineCategories.length > 0;
    },
    wineCategories(state) {
      return state.wineCategories;
    },
    // Fetch One
    isLoadingFetchOne(state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne(state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne(state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating(state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating(state) {
      return state.errorCreating !== null;
    },
    errorCreating(state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating(state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating(state) {
      return state.errorUpdating !== null;
    },
    errorUpdating(state) {
      return state.errorUpdating;
    },
    // Removing
    isLoadingRemoving(state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving(state) {
      return state.errorRemoving !== null;
    },
    errorRemoving(state) {
      return state.errorRemoving;
    },
    // Analysing
    isLoadingAnalysing(state) {
      return state.isLoadingAnalysing;
    },
    hasErrorAnalysing(state) {
      return state.errorAnalysing !== null;
    },
    errorAnalysing(state) {
      return state.errorAnalysing;
    },
    // Toggle Wines Activation
    isLoadingToggleWinesActivation (state) {
      return state.isLoadingToggleWinesActivation;
    },
    hasErrorToggleWinesActivation (state) {
      return state.errorToggleWinesActivation !== null;
    },
    errorToggleWinesActivation (state) {
      return state.errorToggleWinesActivation;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.wineCategories = [];
    },
    [FETCH_ALL_SUCCESS](state, wineCategories) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.wineCategories = wineCategories;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.wineCategories = [];
    },
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING](state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS](state, wineCategory) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.wineCategories.push(wineCategory);
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING](state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS](state, wineCategory) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      let wineCategoryToUpdate = state.wineCategories.find(element => element.id === wineCategory.id);
      wineCategoryToUpdate.translations = wineCategory.translations;
    },
    [UPDATING_ERROR](state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Removing
    [REMOVING](state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS](state, wineCategory) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.wineCategories = state.wineCategories.filter(element => element.id !== wineCategory);
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Toggle Wines Activation
    [TOGGLE_WINES_ACTIVATION] (state) {
      state.isLoadingToggleWinesActivation = true;
      state.errorToggleWinesActivation = null;
    },
    [TOGGLE_WINES_ACTIVATION_SUCCESS] (state, payload) {
      state.isLoadingToggleWinesActivation = false;
      state.errorToggleWinesActivation = null;

      const winesFromCategory = this.getters['accountWine/wines'].filter(element => element.wineCategoryId === payload.wineCategory.id);

      for (let i = 0; i < winesFromCategory.length; i++) {
        let wineToActivate = winesFromCategory[i];
        wineToActivate.active = payload.value;
      }
    },
    [TOGGLE_WINES_ACTIVATION_ERROR] (state, error) {
      state.isLoadingToggleWinesActivation = false;
      state.errorToggleWinesActivation = error;
    },
    // Add item into category
    [ADD_ITEM_INTO_CATEGORY] (state, wine) {
      let wineCategoryToUpdate = state.wineCategories.find(element => element.id === wine.wineCategoryId);
      wineCategoryToUpdate.wines.push(wine);
    },
    // Remove item from category
    [REMOVE_ITEM_FROM_CATEGORY] (state, wine) {
      let wineCategoryToUpdate = state.wineCategories.find(element => element.id === wine.wineCategoryId);
      let wineToRemoveIndex = wineCategoryToUpdate.wines.findIndex(element => element.id === wine.id);
      wineCategoryToUpdate.wines.splice(wineToRemoveIndex, 1);
    },
    // Add category (used when a new category is created via import)
    [ADD_CATEGORY] (state, wineCategory) {
      state.wineCategories.push(wineCategory);
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.wineCategories = [];
    },
  },
  actions: {
    async findAll({ commit }) {
      if (!this.state.accountWineCategory.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountWineCategoryAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.wineCategories);
            return response.data.wineCategories;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountWineCategoryAPI.findOne(payload.wineCategory);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.wineCategory;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountWineCategoryAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.wineCategory);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountWineCategoryAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.wineCategory);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountWineCategoryAPI.remove(payload.wineCategory);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.wineCategory);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async toggleWinesActivation ({ commit }, payload) {
      commit(TOGGLE_WINES_ACTIVATION);
      try {
        let response = await AccountWineCategoryAPI.toggleWinesActivation(payload.form);
        if (response.data.success === true) {
          commit(TOGGLE_WINES_ACTIVATION_SUCCESS, payload.form);
          return response.data;
        } else {
          commit(TOGGLE_WINES_ACTIVATION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLE_WINES_ACTIVATION_ERROR, error);
        return null;
      }
    },
    async addItemIntoCategory ({ commit }, payload) {
      commit(ADD_ITEM_INTO_CATEGORY, payload.wine);
    },
    async removeItemFromCategory ({ commit }, payload) {
      commit(REMOVE_ITEM_FROM_CATEGORY, payload.wine);
    },
    async addCategory ({ commit }, payload) {
      commit(ADD_CATEGORY, payload.wineCategory);
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
