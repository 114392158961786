<template>
  <div>
    <top-banner :heading="topBanner.heading" :description="topBanner.description">
      <template v-slot:inside-banner-text v-if="isLoading">
        <b-row>
          <b-col cols="12" class="mt-4">
            <div v-scrollanimation="{ name: 'fade-up', delay: 450 }">
              <b-spinner></b-spinner>
            </div>
          </b-col>
        </b-row>
      </template>

      <template v-if="!isLoading">
        <template v-if="hasErrorArticle || hasErrorArticleCategory">
          <FlashBag v-if="hasErrorArticle" :message="errorArticle" />
          <FlashBag v-if="hasErrorArticleCategory" :message="errorArticleCategory" />
        </template>

        <template v-else>
          <div
            class="article-front-page"
            @click="goToArticle(highlightedArticle)"
            v-scrollanimation="{ name: 'fade-up', delay: 150 }"
          >
            <b-img
              fluid
              center
              :src="highlightedArticle.coverSrc"
              :alt="highlightedArticle.coverAlt"
            ></b-img>
            <div class="article-front-page-content-container">
              <div class="article-front-page-content-inner-container">
                <div class="blog-category-tag">
                  {{ articleCategoryName(highlightedArticle) }}
                </div>
                <h2 class="blog-article-title">{{ highlightedArticle.title }}</h2>
                <p class="blog-article-description">
                  {{ highlightedArticle.description }}
                </p>
                <div class="blog-article-publication-date">
                  <i class="fas fa-calendar mr-1"></i> {{ $t("front.blog.postedAt") }}
                  {{ $d(new Date(highlightedArticle.date.date), "string") }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </template>
    </top-banner>

    <template v-if="!isLoading && !hasErrorArticle && !hasErrorArticleCategory">
      <b-container v-scrollanimation="{ name: 'fade-up', delay: 350 }">
        <b-row class="category-filters mb-5">
          <div v-for="category in optionArticleCategories" :key="category.id">
            <div
              v-on:click="toggleCategoryFilter(category.id)"
              class="category-button"
              :class="currentCategory === category.id ? 'selected-category' : ''"
            >
              {{ category.name }}
            </div>
          </div>
        </b-row>

        <ArticlesContainer
          :filteredArticles="filteredArticles"
          :articleCategoryName="articleCategoryName"
          :allArticlesLoaded="allArticlesLoaded"
          @loadNextArticles="loadNextArticles"
        />

        <template v-if="isLoadingArticle && this.page > 1">
          <b-row class="text-center">
            <b-col cols="12" class="mb-5 pb-5">
              <b-spinner />
            </b-col>
          </b-row>
        </template>
      </b-container>
    </template>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import Loading from "@mizogoo/components/workspace/layout/Loading";
import FlashBag from "@mizogoo/components/FlashBag";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import ArticlesContainer from "@mizogoo/components/front/pages/BlogPage/ArticlesContainer";

export default {
  mixins: [MetaMixin],
  name: "BlogPage",
  components: {
    Loading,
    FlashBag,
    TopBanner,
    ArticlesContainer,
  },
  data() {
    return {
      currentCategory: null,
      highlightedArticle: null,
      isLoading: true,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.blog.title");
    },
    metaDescription() {
      return this.$t("front.metadata.blog.description");
    },
    // Fetch All Articles
    isLoadingArticle() {
      return this.$store.getters["article/isLoading"];
    },
    hasErrorArticle() {
      return this.$store.getters["article/hasError"];
    },
    errorArticle() {
      return this.$store.getters["article/error"];
    },
    articles() {
      return this.$store.getters["article/articles"];
    },
    hasArticle() {
      return this.$store.getters["article/hasArticle"];
    },
    page() {
      return this.$store.getters["article/page"];
    },
    // Fetch All Article Categories
    isLoadingArticleCategory() {
      return this.$store.getters["articleCategory/isLoading"];
    },
    hasErrorArticleCategory() {
      return this.$store.getters["articleCategory/hasError"];
    },
    errorArticleCategory() {
      return this.$store.getters["articleCategory/error"];
    },
    articleCategories() {
      return this.$store.getters["articleCategory/articleCategories"];
    },
    hasArticleCategory() {
      return this.$store.getters["articleCategory/hasArticleCategory"];
    },
    optionArticleCategories() {
      let options = [{ id: null, name: "Toutes" }];

      for (let articleCategory of this.articleCategories) {
        if (articleCategory.articles.length > 0 && articleCategory.active) {
          options.push(articleCategory);
        }
      }

      return options;
    },
    filteredArticles() {
      let articles = this.articles;

      if (this.currentCategory !== null) {
        articles = articles.filter(
          (article) => article.articleCategoryId === this.currentCategory
        );
      }

      return articles;
    },
    totalArticleNumber() {
      let res = 0;
      for (let i = 0; i < this.articleCategories.length; i++) {
        res = res + this.articleCategories[i].articles.length;
      }
      return res;
    },
    allArticlesLoaded() {
      return this.totalArticleNumber == this.articles.length ? true : false;
    },
    topBanner() {
      return {
        heading: this.$tm("front.blog.topBanner.heading"),
        description: this.$t("front.blog.topBanner.description"),
      };
    },
  },
  methods: {
    toggleCategoryFilter(categoryId) {
      categoryId === this.currentCategory
        ? (this.currentCategory = null)
        : (this.currentCategory = categoryId);
    },
    articleCategoryName(article) {
      return this.articleCategories.find(
        (element) => element.id === article.articleCategoryId
      ).name;
    },
    goToArticle(article) {
      this.$router
        .push({
          name: "article",
          params: { id: article.id, name: this.slugify(article.title) },
        })
        .catch(() => {});
    },
    async loadNextArticles() {
      await this.$store.dispatch("article/findAllWithPagination");
    },
  },
  async created() {
    if (!this.hasArticle && !this.hasArticleCategory) {
      await Promise.all([
        this.$store.dispatch("articleCategory/findAll"),
        this.$store.dispatch("article/findAllWithPagination"),
      ]);
    }

    const highlightedArticle = this.articles.find(
      (article) => article.isFrontPage === true
    );

    highlightedArticle != undefined
      ? (this.highlightedArticle = highlightedArticle)
      : (this.highlightedArticle = this.articles[0]);

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.category-filters {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .category-button {
    margin: 0.4rem 0.2rem;
    padding: 0.6rem 1.4rem;
    background-color: $white;
    color: $mizogoo-gray-dark;
    outline: 2px solid transparent;
    -moz-outline-radius: 2px;
    border-radius: 10px;
    box-shadow: 0 0px 6px -2px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: 0.2s ease-in-out;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Chrome, Opera, Firefox */

    &:hover {
      color: $mizogoo;
    }
  }

  .selected-category {
    color: $mizogoo;
    outline: 2px solid $mizogoo;
    outline-offset: -5px;
  }
}

.top-banner {
  padding-bottom: 4rem;
}

.article-front-page {
  position: relative;
  text-align: left;

  &:hover .article-front-page-content-container {
    background-color: rgba(11, 11, 11, 0.6);
  }

  .article-front-page-content-container {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: rgba(11, 11, 11, 0.5);
    border-radius: 25px;
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;

    .article-front-page-content-inner-container {
      padding: 2.5rem;
    }
  }
}

@media screen and (max-width: 768px) {
  .article-front-page {
    .article-front-page-content-container {
      .article-front-page-content-inner-container {
        padding: 1rem;

        .blog-article-title {
          font-size: 1.5rem !important;
          margin-bottom: 0 !important;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          overflow: hidden;
        }
      }
    }

    .blog-article-description,
    .blog-article-publication-date {
      display: none;
    }
  }
}
</style>
