<template>
  <b-form-group
    :id="id + '-input-group'"
    :label="displayName && name"
    :label-for="id"
    :label-class="inputRequired ? 'required' : ''"
  >
    <b-input-group class="input-group" :append="append">
      <b-form-input
        :type="type"
        :id="id"
        :name="id"
        v-model="inputValue"
        :aria-describedby="id + '-live-feedback'"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :autocomplete="newPassword ? 'new-password' : ''"
        :class="{ 'is-invalid': errorMessage, inputWithIcon: $slots.icon }"
        :size="small ? 'sm' : null"
        :min="min"
        :max="max"
        :formatter="formatter"
      >
      </b-form-input>
      <!-- Allows to add an icon inside the input -->
      <slot name="icon"></slot>
    </b-input-group>
    <b-form-text :id="id + '-help-block'">
      {{ help }}
    </b-form-text>
    <!-- Allows to add helper other than just a string -->
    <slot name="helper"></slot>
    <b-form-text :id="id + '-live-feedback'" class="invalid-feedback-text">
      {{ errorMessage }}
    </b-form-text>
  </b-form-group>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "FormInput",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    type: {
      type: String,
      default: "text",
    },
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
    },
    help: {
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    newPassword: {
      type: Boolean,
      default: false,
    },
    labelSrOnly: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    formatter: {
      default: null,
    },
    append: {
      type: String,
    },
    displayName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(
      "formInput-" + props.id,
      props.rules,
      {
        label: props.name,
        syncVModel: true,
        validateOnMount: false,
        validateOnValueUpdate: false,
      }
    );

    return {
      inputValue,
      errorMessage,
    };
  },
  data() {
    return {
      hasEmailRule: false,
    };
  },
  computed: {
    hasRules() {
      return Object.keys(this.rules).length > 0;
    },
    inputRequired() {
      if (typeof this.rules["required"] !== "undefined") {
        if (this.rules["required"] === true) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.hasEmailRule = !(this.rules.email === undefined || this.rules.email === false);
  },
};
</script>

<style lang="scss" scoped>
.input-group {
  display: flex;
  align-items: center;

  .inputWithIcon {
    padding-right: 58px;
  }
}
</style>
