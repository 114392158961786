<template>
  <b-row>
    <b-col>
      <a class="return-button" @click="previous">
        <i class="fas fa-arrow-left"></i>
        <span>{{ $t("workspace.global.return") }}</span>
      </a>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "BackButton",
  methods: {
    previous() {
      return this.$router.go(-1)
    }
  }
};
</script>

<style lang="scss" scoped>
.return-button {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: $mizogoo-gray-dark;

  span {
    margin-left: 15px;
  }

  &:hover {
    text-decoration: none;
    cursor: pointer;

    span {
      text-decoration: underline;
    }
  }
}
</style>
