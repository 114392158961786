import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findOne(product) {
    return axios.post("/api/product/" + product, Tool.createFormData());
  },
  findOneByConstName(data) {
    return axios.post("/api/product/find-by-const-name", Tool.createFormData(data));
  },
  findProductCategories() {
    return axios.post("/api/product/category", Tool.createFormData());
  },
  findModelCategories(productId, data) {
    return axios.post("/api/product/" + productId + "/model-category", Tool.createFormData(data));
  },
  findModelCategoriesWithoutModels(productId) {
    return axios.post("/api/product/" + productId + "/model-category-without-model", Tool.createFormData());
  },
  findModels(productId, data) {
    return axios.post("/api/product/" + productId + "/models", Tool.createFormData(data));
  },
  hasModels(productId) {
    return axios.post("/api/product/" + productId + "/has-models", Tool.createFormData());
  },
  findFeatures(product) {
    return axios.post("/api/product/" + product.id + "/feature", Tool.createFormData());
  },
  findFeatureValues(product) {
    return axios.post("/api/product/" + product.id + "/feature-value", Tool.createFormData());
  },
  findMatrixCategories(productId, data) {
    return axios.post("/api/product/" + productId + "/matrix-category", Tool.createFormData(data));
  },
  findMatrixCategoriesWithoutMatrices(data) {
    return axios.post("/api/product/matrix-category-without-matrix", Tool.createFormData(data));
  },
  findMatrices(data) {
    return axios.post("/api/product/matrices", Tool.createFormData(data));
  },
  hasMatrices(data) {
    return axios.post("/api/product/has-matrices", Tool.createFormData(data));
  },
  findAttributs(productId, data) {
    return axios.post("/api/product/" + productId + "/attribut", Tool.createFormData(data));
  },
  findPrices(product, data) {
    return axios.post("/api/product/" + product.id + "/price", Tool.createFormData(data));
  },
  findAssociatedProducts(productId) {
    return axios.post("/api/product/" + productId + "/associated-products", Tool.createFormData());
  }
};
