import Vue from "vue";
import AccountDishCategoryAPI from "../api/account_dish_category";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  ANALYSING = "ANALYSING",
  ANALYSING_SUCCESS = "ANALYSING_SUCCESS",
  ANALYSING_ERROR = "ANALYSING_ERROR",

  TOGGLE_DISHES_ACTIVATION = "TOGGLE_DISHES_ACTIVATION",
  TOGGLE_DISHES_ACTIVATION_SUCCESS = "TOGGLE_DISHES_ACTIVATION_SUCCESS",
  TOGGLE_DISHES_ACTIVATION_ERROR = "TOGGLE_DISHES_ACTIVATION_ERROR",
  
  ADD_ITEM_INTO_CATEGORY = "ADD_ITEM_INTO_CATEGORY",
  REMOVE_ITEM_FROM_CATEGORY = "REMOVE_ITEM_FROM_CATEGORY",
  
  ADD_CATEGORY = "ADD_CATEGORY",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    dishCategories: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Analysing
    isLoadingAnalysing: false,
    errorAnalysing: null,
    // Toggle Dishes Activation
    isLoadingToggleDishesActivation: false,
    errorToggleDishesActivation: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasDishCategories (state) {
      return state.dishCategories.length > 0;
    },
    dishCategories (state) {
      return state.dishCategories;
    },
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne (state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Analysing
    isLoadingAnalysing (state) {
      return state.isLoadingAnalysing;
    },
    hasErrorAnalysing (state) {
      return state.errorAnalysing !== null;
    },
    errorAnalysing (state) {
      return state.errorAnalysing;
    },
    // Toggle Dishes Activation
    isLoadingToggleDishesActivation (state) {
      return state.isLoadingToggleDishesActivation;
    },
    hasErrorToggleDishesActivation (state) {
      return state.errorToggleDishesActivation !== null;
    },
    errorToggleDishesActivation (state) {
      return state.errorToggleDishesActivation;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.dishCategories = [];
    },
    [FETCH_ALL_SUCCESS] (state, dishCategories) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.dishCategories = dishCategories;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.dishCategories = [];
    },
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state, dishCategory) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.dishCategories.push(dishCategory);
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state, dishCategory) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      let dishCategoryToUpdate = state.dishCategories.find(element => element.id === dishCategory.id);
      dishCategoryToUpdate.translations = dishCategory.translations;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, dishCategory) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.dishCategories = state.dishCategories.filter(element => element.id !== dishCategory);
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Analysing
    [ANALYSING] (state) {
      state.isLoadingAnalysing = true;
      state.errorAnalysing = null;
    },
    [ANALYSING_SUCCESS] (state, dishCategory) {
      state.isLoadingAnalysing = false;
      state.errorAnalysing = null;
      let dishCategoryToUpdate = state.dishCategories.find(element => element.id === dishCategory.id);
      dishCategoryToUpdate.analyse = dishCategory.analyse;
    },
    [ANALYSING_ERROR] (state, error) {
      state.isLoadingAnalysing = false;
      state.errorAnalysing = error;
    },
    // Toggle Dishes Activation
    [TOGGLE_DISHES_ACTIVATION] (state) {
      state.isLoadingToggleDishesActivation = true;
      state.errorToggleDishesActivation = null;
    },
    [TOGGLE_DISHES_ACTIVATION_SUCCESS] (state, payload) {
      state.isLoadingToggleDishesActivation = false;
      state.errorToggleDishesActivation = null;

      const dishesFromCategory = this.getters['accountDish/dishes'].filter(element => element.dishCategoryId === payload.dishCategory.id);

      for (let i = 0; i < dishesFromCategory.length; i++) {
        let dishToActivate = dishesFromCategory[i];
        dishToActivate.active = payload.value;
      }
    },
    [TOGGLE_DISHES_ACTIVATION_ERROR] (state, error) {
      state.isLoadingToggleDishesActivation = false;
      state.errorToggleDishesActivation = error;
    },
    // Add item into category
    [ADD_ITEM_INTO_CATEGORY] (state, dish) {
      let dishCategoryToUpdate = state.dishCategories.find(element => element.id === dish.dishCategoryId);
      dishCategoryToUpdate.dishes.push(dish);
    },
    // Remove item from category
    [REMOVE_ITEM_FROM_CATEGORY] (state, dish) {
      let dishCategoryToUpdate = state.dishCategories.find(element => element.id === dish.dishCategoryId);
      let dishToRemoveIndex = dishCategoryToUpdate.dishes.findIndex(element => element.id === dish.id);
      dishCategoryToUpdate.dishes.splice(dishToRemoveIndex, 1);
    },
    // Add category (used when a new category is created via import)
    [ADD_CATEGORY] (state, dishCategory) {
      state.dishCategories.push(dishCategory);
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.dishCategories = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.accountDishCategory.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountDishCategoryAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.dishCategories);
            return response.data.dishCategories;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountDishCategoryAPI.findOne(payload.dishCategory);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.dishCategory;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountDishCategoryAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.dishCategory);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountDishCategoryAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.dishCategory);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountDishCategoryAPI.remove(payload.dishCategory);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.dishCategory);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async analyse ({ commit }, payload) {
      commit(ANALYSING);
      try {
        let response = await AccountDishCategoryAPI.analyse(payload.dishCategory);
        if (response.data.success === true) {
          commit(ANALYSING_SUCCESS, response.data.dishCategory);
          return response.data;
        } else {
          commit(ANALYSING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(ANALYSING_ERROR, error);
        return null;
      }
    },
    async toggleDishesActivation ({ commit }, payload) {
      commit(TOGGLE_DISHES_ACTIVATION);
      try {
        let response = await AccountDishCategoryAPI.toggleDishesActivation(payload.form);
        if (response.data.success === true) {
          commit(TOGGLE_DISHES_ACTIVATION_SUCCESS, payload.form);
          return response.data;
        } else {
          commit(TOGGLE_DISHES_ACTIVATION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLE_DISHES_ACTIVATION_ERROR, error);
        return null;
      }
    },
    async addItemIntoCategory ({ commit }, payload) {
      commit(ADD_ITEM_INTO_CATEGORY, payload.dish);
    },
    async removeItemFromCategory ({ commit }, payload) {
      commit(REMOVE_ITEM_FROM_CATEGORY, payload.dish);
    },
    async addCategory ({ commit }, payload) {
      commit(ADD_CATEGORY, payload.dishCategory);
    },
    async resetCache ({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
