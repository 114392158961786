<template>
  <div id="cookie-consent">
    <template v-if="visible">
      <div class="cookie-consent-alert" :class="[askedToConfig ? 'max-height-550' : 'max-heigt-auto']">
        <!-- First part of the popup (accept, decline or configure cookies) -->
        <template v-if="!askedToConfig">
          <h4 class="cookie-alert-title">
            {{ $t("front.cookie.cookieAlertHeading") }}
          </h4>
          <div class="cookie-alert-explanation">
            {{ $t("front.cookie.cookieAlertExplanation") }}
          </div>
          <div class="cookie-alert-policy">
            {{ $t("front.cookie.cookieAlertPolicy1") }}
            <router-link :to="{ name: 'privacy' }">
              {{ $t("front.cookie.cookieAlertPolicy2") }}
            </router-link>
          </div>
          <div class="cookie-button-container">
            <b-button variant="secondary" @click.prevent.stop="declineCookies">
              {{ $t("front.cookie.cookieDecline") }}
            </b-button>

            <b-button variant="secondary" @click.prevent.stop="askedToConfig=true">
              {{ $t("front.cookie.cookieConfig") }}
            </b-button>

            <b-button variant="primary" @click.prevent.stop="acceptCookies">
              {{ $t("front.cookie.cookieAccept") }}
            </b-button>
          </div>
        </template>

        <!-- Configuration page with cookie details -->
        <template v-else>
          <h4 class="cookie-config-title">
            {{ $t("front.cookie.cookieConfigHeading") }}
            <div @click="askedToConfig=false">
              <i class="fas fa-times"></i>
            </div>
          </h4>
          <div class="cookies-config-container">
            <template v-for="cookieCategory in cookieList" :key="cookieCategory.id">
              <div class="cookie-item-config-container">
                <div class="cookie-toggle">
                  <b-form-checkbox
                    v-model="cookieConfig[cookieCategory.id.toLowerCase()]"
                    name="functionnal-cookie-check"
                    :disabled="cookieCategory.id == 'FUNCTIONNAL'"
                  />
                </div>
                <div class="cookie-item-content">
                  <h5 class="cookie-category-name">{{ cookieCategory.name }}</h5>
                  <div class="cookie-category-description">{{ cookieCategory.description }}</div>
                  <div class="cookie-category-show-more" v-b-toggle="cookieCategory.id.toLowerCase() + '-cookie-list'">
                    {{ $t('front.cookie.cookieShowMore') }}
                  </div>
                  <b-collapse :id="cookieCategory.id.toLowerCase() + '-cookie-list'" class="mt-2">
                    <template v-for="cookie in cookieCategory.list" :key="cookie.id">
                      <div class="cookie-container">
                        <div class="cookie-info-row">
                          <div class="cookie-info-title">
                            {{ $t('front.cookie.cookieDetail[0]') }}
                          </div>
                          <div class="cookie-info-content">
                            {{ cookie.name }}
                          </div>
                        </div>
                        <div class="cookie-info-row">
                          <div class="cookie-info-title">
                            {{ $t('front.cookie.cookieDetail[1]') }}
                          </div>
                          <div class="cookie-info-content">
                            {{ cookie.description }}
                          </div>
                        </div>
                        <div class="cookie-info-row">
                          <div class="cookie-info-title">
                            {{ $t('front.cookie.cookieDetail[2]') }}
                          </div>
                          <div class="cookie-info-content">
                            {{ cookie.provider }}
                          </div>
                        </div>
                        <div class="cookie-info-row">
                          <div class="cookie-info-title">
                            {{ $t('front.cookie.cookieDetail[3]') }}
                          </div>
                          <div class="cookie-info-content">
                            {{ cookie.expiry }}
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-collapse>
                </div>
              </div>
            </template>
          </div>

          <div class="cookie-button-container">
            <b-button variant="secondary" @click.prevent.stop="declineCookies">
              {{ $t("front.cookie.cookieDeclineAll") }}
            </b-button>

            <b-button variant="primary" @click.prevent.stop="acceptSelectedCookies">
              {{ $t("front.cookie.cookieAcceptSelection") }}
            </b-button>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  data() {
    return {
      gaProperty: 'UA-146819894-4',
      visible: true,
      askedToConfig: false,
      cookieConfig: {
        functionnal: true,
        analytics: false,
        tracking: false,
      }
    }
  },
  computed: {
    cookieList() {
      return [
        // Functionnal cookies
        {
          id: 'FUNCTIONNAL',
          name:  this.$t("front.cookie.cookieList.functionnal.name"),
          description:  this.$t("front.cookie.cookieList.functionnal.description"),
          list: [
            {
              id: 0,
              name: this.$t("front.cookie.cookieList.functionnal.list[0].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[0].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[0].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[0].expiry"),
            },
            {
              id: 1,
              name: this.$t("front.cookie.cookieList.functionnal.list[1].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[1].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[1].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[1].expiry"),
            },
            {
              id: 2,
              name: this.$t("front.cookie.cookieList.functionnal.list[2].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[2].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[2].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[2].expiry"),
            },
            {
              id: 3,
              name: this.$t("front.cookie.cookieList.functionnal.list[3].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[3].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[3].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[3].expiry"),
            },
            {
              id: 4,
              name: this.$t("front.cookie.cookieList.functionnal.list[4].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[4].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[4].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[4].expiry"),
            },
            {
              id: 5,
              name: this.$t("front.cookie.cookieList.functionnal.list[5].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[5].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[5].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[5].expiry"),
            },
            {
              id: 6,
              name: this.$t("front.cookie.cookieList.functionnal.list[6].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[6].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[6].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[6].expiry"),
            },
            {
              id: 7,
              name: this.$t("front.cookie.cookieList.functionnal.list[7].name"),
              description: this.$t("front.cookie.cookieList.functionnal.list[7].description"),
              provider: this.$t("front.cookie.cookieList.functionnal.list[7].provider"),
              expiry: this.$t("front.cookie.cookieList.functionnal.list[7].expiry"),
            },
          ]
        },
        // Analytics cookies
        {
          id: 'ANALYTICS',
          name:  this.$t("front.cookie.cookieList.analytics.name"),
          description:  this.$t("front.cookie.cookieList.analytics.description"),
          list: [
            {
              id: 0,
              name: this.$t("front.cookie.cookieList.analytics.list[0].name"),
              description: this.$t("front.cookie.cookieList.analytics.list[0].description"),
              provider: this.$t("front.cookie.cookieList.analytics.list[0].provider"),
              expiry: this.$t("front.cookie.cookieList.analytics.list[0].expiry"),
            },
            {
              id: 1,
              name: this.$t("front.cookie.cookieList.analytics.list[1].name"),
              description: this.$t("front.cookie.cookieList.analytics.list[1].description"),
              provider: this.$t("front.cookie.cookieList.analytics.list[1].provider"),
              expiry: this.$t("front.cookie.cookieList.analytics.list[1].expiry"),
            },
            {
              id: 2,
              name: this.$t("front.cookie.cookieList.analytics.list[2].name"),
              description: this.$t("front.cookie.cookieList.analytics.list[2].description"),
              provider: this.$t("front.cookie.cookieList.analytics.list[2].provider"),
              expiry: this.$t("front.cookie.cookieList.analytics.list[2].expiry"),
            },
            {
              id: 3,
              name: this.$t("front.cookie.cookieList.analytics.list[3].name"),
              description: this.$t("front.cookie.cookieList.analytics.list[3].description"),
              provider: this.$t("front.cookie.cookieList.analytics.list[3].provider"),
              expiry: this.$t("front.cookie.cookieList.analytics.list[3].expiry"),
            },
          ]
        },
        // Tracking cookies
        {
          id: 'TRACKING',
          name:  this.$t("front.cookie.cookieList.tracking.name"),
          description:  this.$t("front.cookie.cookieList.tracking.description"),
          list: [
            {
              id: 0,
              name: this.$t("front.cookie.cookieList.tracking.list[0].name"),
              description: this.$t("front.cookie.cookieList.tracking.list[0].description"),
              provider: this.$t("front.cookie.cookieList.tracking.list[0].provider"),
              expiry: this.$t("front.cookie.cookieList.tracking.list[0].expiry"),
            },
            {
              id: 1,
              name: this.$t("front.cookie.cookieList.tracking.list[1].name"),
              description: this.$t("front.cookie.cookieList.tracking.list[1].description"),
              provider: this.$t("front.cookie.cookieList.tracking.list[1].provider"),
              expiry: this.$t("front.cookie.cookieList.tracking.list[1].expiry"),
            },
            {
              id: 2,
              name: this.$t("front.cookie.cookieList.tracking.list[2].name"),
              description: this.$t("front.cookie.cookieList.tracking.list[2].description"),
              provider: this.$t("front.cookie.cookieList.tracking.list[2].provider"),
              expiry: this.$t("front.cookie.cookieList.tracking.list[2].expiry"),
            },
            {
              id: 3,
              name: this.$t("front.cookie.cookieList.tracking.list[3].name"),
              description: this.$t("front.cookie.cookieList.tracking.list[3].description"),
              provider: this.$t("front.cookie.cookieList.tracking.list[3].provider"),
              expiry: this.$t("front.cookie.cookieList.tracking.list[3].expiry"),
            },
            {
              id: 4,
              name: this.$t("front.cookie.cookieList.tracking.list[4].name"),
              description: this.$t("front.cookie.cookieList.tracking.list[4].description"),
              provider: this.$t("front.cookie.cookieList.tracking.list[4].provider"),
              expiry: this.$t("front.cookie.cookieList.tracking.list[4].expiry"),
            },
          ]
        }
      ]
    }
  },
  methods: {
    /**
     * Disable Google Analytics tracking
     */
    disableAnalyticsCookie() {
      document.cookie = 'ga-disable-' + this.gaProperty + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
      document.cookie = 'gid-disable-' + this.gaProperty + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
      window['ga-disable-' + this.gaProperty] = true;
      window['gid-disable-' + this.gaProperty] = true;
    },
    /**
     * Delete Google and Facebook tracking cookies
     */
    disableTrackingCookie() {
      document.cookie = '1P_JAR' + '=; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/;domain=.' + location.host;
      document.cookie = '_fbp' + '=; expires=Thu, 01 Jan 1970 00:00:01 UTC; path=/;domain=.' + location.host;
    },
    /**
     * Disable every non essential cookie
     */
    declineCookies() {
      this.disableAnalyticsCookie();
      this.disableTrackingCookie();
      document.cookie = 'cookie-consent=NONE; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
      location.reload();
    },
    /**
     * Accept every cookies (keep track of the user's choice and close the cookie popup)
     */
    acceptCookies() {
      document.cookie = 'cookie-consent=ALL; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
      this.visible = false;
    },
    /**
     * Accept selected cookies
     */
    acceptSelectedCookies() {
      let cookieValue = '';
      if (!this.cookieConfig.analytics) {
        this.disableAnalyticsCookie();
        cookieValue += '-A';
      }
      if (!this.cookieConfig.tracking) {
        this.disableTrackingCookie();
        cookieValue += '-T';
      }
      document.cookie = 'cookie-consent=' + cookieValue + '; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
      this.visible = false;
    },
  },
  mounted() {
    if (document.cookie.indexOf('cookie-consent') > -1) {
      if (
        document.cookie.indexOf("cookie-consent=NONE") > -1 ||
        document.cookie.indexOf("cookie-consent=-A-T") > -1
      ) {
        this.disableAnalyticsCookie();
        this.disableTrackingCookie();
      } else if (document.cookie.indexOf("cookie-consent=-A") > -1) {
        this.disableAnalyticsCookie();
      } else if (document.cookie.indexOf("cookie-consent=-T") > -1) {
        this.disableTrackingCookie();
      }
      this.visible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
#cookie-consent {
  .max-height-auto {
    max-height: 310px;
  }

  .max-height-550 {
    max-height: 550px;
  }

  .cookie-consent-alert {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    max-width: 500px;
    padding: 1.8rem 2.6rem;
    background-color: #fff;
    box-shadow: 0 0 15px 10px #36363612;
    border-left: 5px solid $mizogoo;
    border-radius: 5px;
    z-index: 30;
    transition: max-height 2s ease-in-out;

    // Cookie first choice (accept, decline or configure)
    .cookie-alert-title {
      font-size: 1.1rem;
      margin-bottom: 1rem;
      white-space: initial!important;
    }

    .cookie-alert-explanation,
    .cookie-alert-policy {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      color: $mizogoo-gray;
      line-height: 1.4;
    }

    .cookie-button-container {
      margin-top: 1.2rem;

      .btn {
        padding: 10px 24px!important;
        margin-right: 5px;
        border: none!important;
      }
    }

    // Cookie config section
    .cookie-config-title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 1.1rem;
      margin-bottom: 2.5rem;
      white-space: initial!important;
    }

    .cookies-config-container {
      overflow: auto;
      max-height: 350px;
      padding-right: 1rem;

      .cookie-item-config-container {
        display: flex;
        align-items: flex-start;

        .cookie-toggle {
          margin-right: 0.5rem;
        }
        
        .cookie-category-name {
          font-size: 1rem!important;
          font-weight: 500!important;
          line-height: 1.4;
        }

        .cookie-category-description {
          margin-bottom: 0.2rem;
          font-size: 0.9rem;
          color: $mizogoo-gray;
          line-height: 1.4;
        }

        .cookie-category-show-more {
          margin-bottom: 1.5rem;
          font-size: 0.9rem;
          color: $mizogoo-gray;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .cookie-container {
        margin-bottom: 0.5rem;
        padding: 1rem;
        font-size: 0.8rem;
        background-color: $mizogoo-gray-light;
        border-radius: 5px;

        &:last-child {
          margin-bottom: 1.5rem;
        }

        .cookie-info-row {
          display: flex;

          .cookie-info-title {
            flex: 2;
            font-weight: 400;
          }

          .cookie-info-content {
            flex: 4;
            line-height: 1.2rem;
          }
        }
      }
    }
  }
}
</style>
