<template>
  <b-modal centered hide-header hide-footer no-stacking size="xl" id="premium-only-modal">
    <b-row fluid>
      <!-- Explanations part -->
      <b-col md="7" class="explanations-part">
        <div>
          <div class="premium-badge-container">
            <b-img
              :src="require('@mizogoo/assets/img/crown.png')"
              alt=""
              class="premium-icon"
            />
            <div class="ml-2">
              <div class="premium-label">Premium</div>
              <div class="premium-requirements">
                {{
                  hadFreePremiumTrial
                    ? user.currentSubscription && user.currentSubscription.isValid
                      ? $t("workspace.premiumOnlyModal.upgradeRequired")
                      : $t("workspace.premiumOnlyModal.subscriptionRequired")
                    : $t("workspace.premiumOnlyModal.freeTry")
                }}
              </div>
            </div>
          </div>

          <div v-if="hadFreePremiumTrial" class="ml-4">
            <h2 class="mb-4">{{ heading }}</h2>
            <p>{{ description }}</p>
            <p
              v-if="!isLoadingFetchAllSubscriptions && minimalSubscriptionPlan"
              class="minimal-subscription"
            >
              {{
                $t("workspace.premiumOnlyModal.minimalSubscription", {
                  subscriptionPlanName: minimalSubscriptionPlan.name,
                  higher:
                    minimalSubscriptionPlan.constName != "ADVANCED"
                      ? $t("workspace.premiumOnlyModal.higher")
                      : "",
                })
              }}
            </p>
            <div class="mt-5">
              <button class="btn subscribe-button" @click="displaySubscriptionModal">
                {{
                  user.currentSubscription && user.currentSubscription.isValid
                    ? $t("workspace.premiumOnlyModal.changeSubscription")
                    : $t("workspace.premiumOnlyModal.subscribe")
                }}
              </button>
            </div>
          </div>

          <div v-else class="ml-4">
            <h2 class="mb-4">{{ $t("workspace.premiumOnlyModal.freeTryHeading") }}</h2>
            <p>{{ $t("workspace.premiumOnlyModal.freeTryExplanation1") }}</p>
            <ul class="bullets mb-3">
              <li
                v-for="(bullet, index) in $tm(
                  'workspace.premiumOnlyModal.freeTryBullets'
                )"
                :key="index"
              >
                <i class="fas fa-circle"></i>
                {{ bullet }}
              </li>
            </ul>
            <p class="mb-0">{{ $t("workspace.premiumOnlyModal.freeTryExplanation2") }}</p>
            <div class="mt-4">
              <button
                class="btn subscribe-button"
                @click="startFreeTrial"
                :disabled="isLoadingCreatingSubscription"
              >
                {{ $t("workspace.premiumOnlyModal.freeTry") }}
                <b-spinner
                  v-if="isLoadingCreatingSubscription"
                  small
                  class="ml-1"
                ></b-spinner>
              </button>
            </div>
            <p class="free mt-3 mb-0">
              {{ $t("workspace.premiumOnlyModal.freeTryExtra") }}
            </p>
          </div>
        </div>
      </b-col>

      <!-- Image part -->
      <b-col md="5" class="image-part">
        <b-img v-if="hadFreePremiumTrial" fluid :src="image.src" :alt="image.alt" />
        <b-img
          v-else
          fluid
          :src="require('@mizogoo/assets/img/premium_modal/templates.png')"
          alt=""
        />
        <div class="absolute-close-button" @click="$bvModal.hide('premium-only-modal')">
          <i class="fas fa-times"></i>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "PremiumOnlyModal",
  props: ["functionality", "heading", "description", "button", "image"],
  computed: {
    ...mapGetters("subscription", {
      isLoadingFetchAllSubscriptions: "isLoadingFetchAll",
      hasErrorFetchAllSubscriptions: "hasErrorFetchAll",
      errorFetchAllSubscriptions: "errorFetchAll",
      subscriptions: "subscriptions",
    }),
    ...mapGetters("accountSubscription", {
      isLoadingCreatingSubscription: "isLoadingCreating",
      hasErrorCreatingSubscription: "hasErrorCreating",
      errorCreatingSubscription: "errorCreating",
    }),
    minimalSubscriptionPlan() {
      if (this.subscriptions && this.functionality) {
        return this.subscriptions
          .filter((subscription) => subscription.active && subscription.isMonthly)
          .sort((a, b) => parseFloat(a.priceHt) - parseFloat(b.priceHt))
          .find((subscription) =>
            subscription.functionalities.includes(this.functionality)
          );
      }
    },
    hadFreePremiumTrial() {
      let hadFreePremiumTrial = false;
      if (this.user) {
        if (this.user.hadFreePremiumTrial) {
          hadFreePremiumTrial = true;
        } else if (this.user.currentSubscription !== null) {
          if (
            this.user.currentSubscription.isValid &&
            this.user.currentSubscription.isFree
          ) {
            hadFreePremiumTrial = true;
          } else {
            if (
              this.user.currentSubscription.stripeSubscription &&
              this.user.currentSubscription.stripeSubscription.status === "trialing"
            ) {
              hadFreePremiumTrial = true;
            }
          }
        }
      }
      return hadFreePremiumTrial;
    },
  },
  methods: {
    ...mapMutations("subscriptionModal", {
      setFunctionality: "SET_FUNCTIONALITY",
    }),
    displaySubscriptionModal() {
      this.setFunctionality(this.functionality);
      this.$bvModal.show("subscription-modal");
    },
    async startFreeTrial() {
      let bestMonthlySubscription = this.subscriptions
        .filter((subscription) => subscription.active && subscription.isMonthly)
        .sort((a, b) => parseFloat(b.priceHt) - parseFloat(a.priceHt));

      await this.$store.dispatch("accountSubscription/create", {
        form: {
          subscription: bestMonthlySubscription[0].id,
        },
      });

      this.$gtag.event("conversion", {
        send_to: "AW-10851025598/-bw7CIiF7ocYEL6FlrYo",
        event_category: "Free trial",
      });

      this.$bvModal.show("subscription-confirmation-modal");
    },
  },
};
</script>

<style lang="scss">
#premium-only-modal {
  .modal-dialog {
    max-width: 1000px;
    max-height: 660px;
  }

  .modal-content {
    border-radius: 22px;
    border: none;
    position: relative;
    background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
    padding: 5px;
    border-radius: 25px;
  }

  .modal-header {
    border: 0;
    padding-right: 2rem;
    padding-bottom: 1.2rem;
  }

  .modal-body {
    padding: 0;
    background-color: #fff;
    border-radius: 22px;
  }

  .premium-badge-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    text-align: left;

    .premium-icon {
      width: 30px;
      height: 30px;
      padding: 5px;
      background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
      border-radius: 150px;
    }

    .premium-label {
      font-size: 1.4rem;
      font-weight: bold;
      color: $mizogoo;
      line-height: initial;
    }

    .premium-requirements {
      font-size: 0.9rem;
      font-weight: bold;
      color: $mizogoo;
    }
  }

  .explanations-part {
    padding: 4rem;
    overflow-y: auto;
    max-height: 650px;

    h2 {
      font-size: 2.1rem;
      font-weight: 600;
      margin-bottom: 1rem;
    }

    h3 {
      font-size: 0.8rem;
      font-weight: bold;
      text-transform: uppercase;
      color: $mizogoo;
      margin-bottom: 1.2rem !important;
    }

    .bullets {
      li {
        display: flex;
        align-items: center;
        margin-left: 1rem;

        i {
          font-size: 8px;
          margin-right: 1rem;
        }
      }
    }

    p.free {
      font-size: 0.9rem !important;
      color: $mizogoo-gray;
    }

    .minimal-subscription {
      color: #929598;
      font-style: italic;
      text-decoration: underline;
    }

    .subscribe-button {
      padding: 0.5rem 2rem;
      color: #fff;
      background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
      border-radius: 150px;
    }
  }

  .image-part {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    background-color: $mizogoo-gray-light;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 22px;
      border-bottom-right-radius: 22px;
    }

    .absolute-close-button {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50px;
      cursor: pointer;
      z-index: 5;
    }
  }
}
</style>
