<template>
  <b-container class="custom-container">
    <b-row class="subscription-block-columns">
      <b-col
        v-for="col in subscription.columns"
        :key="col.id"
        lg="3"
        sm="12"
        class="col-100 mb-4"
      >
        <div
          v-scrollanimation="{ name: 'fade-up', delay: 500 + 100 * col.id }"
          class="h-100"
        >
          <subscription-column
            :id="col.id"
            :name="col.name"
            :price="col.price"
            :formerPrice="col.formerPrice"
            :billingFrequency="billingFrequency"
            :description="col.description"
            :extraInfo="col.extraInfo"
            :extraFeature="col.extraFeature"
            :features="col.features"
            :button="col.button"
            :recommended="col.id === recommendedPlan"
            :current="isCurrentPlan(col.id)"
            :functionality="functionality"
          ></subscription-column>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SubscriptionColumn from "@mizogoo/components/front/layout/subscription/SubscriptionColumn";

export default {
  name: "SubscriptionBlock",
  components: {
    SubscriptionColumn,
  },
  props: ["billingFrequency", "recommendedPlan", "currentPlan", "functionality"],
  computed: {
    userHadFreePremiumTrial() {
      let hadFreePremiumTrial = false;
      if (this.user) {
        if (this.user.hadFreePremiumTrial) {
          hadFreePremiumTrial = true;
        } else if (this.user.currentSubscription !== null) {
          if (
            this.user.currentSubscription.isValid &&
            this.user.currentSubscription.isFree
          ) {
            hadFreePremiumTrial = true;
          } else {
            if (
              this.user.currentSubscription.stripeSubscription &&
              this.user.currentSubscription.stripeSubscription.status === "trialing"
            ) {
              hadFreePremiumTrial = true;
            }
          }
        }
      }
      return hadFreePremiumTrial;
    },
    subscription() {
      return {
        heading: this.$tm("front.subscription.global.heading"),
        description: this.$t("front.subscription.global.description"),
        columns: [
          {
            id: "BASIC",
            name: this.$t("front.subscription.global.columns[1].name"),
            price: this.billingFrequency === "MONTHLY" ? "4,90" : "49,90",
            description: this.$t("front.subscription.global.columns[1].description"),
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[1].extraInfo")
                : this.$t("front.subscription.global.columns[1].extraInfoYearly"),
            features: [
              {
                id: 0,
                name: this.$t("front.subscription.global.columns[1].features[0]"),
                functionality: "",
              },
              {
                id: 1,
                name: this.$t("front.subscription.global.columns[1].features[1]"),
                functionality: "",
              },
              {
                id: 2,
                name: this.$t("front.subscription.global.columns[1].features[2]"),
                functionality: "PDF-ONE-CLICK-GENERATION",
              },
              {
                id: 3,
                name: this.$t("front.subscription.global.columns[1].features[3]"),
                functionality: "UNLIMITED_PDF_UPLOAD",
              },
            ],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[1].buttonLabel")
                : this.$t("front.subscription.global.columns[1].buttonLabelFreeTrial"),
            },
          },
          {
            id: "STANDARD",
            name: this.$t("front.subscription.global.columns[2].name"),
            price: this.billingFrequency === "MONTHLY" ? "9,90" : "98,90",
            description: this.$t("front.subscription.global.columns[2].description"),
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[2].extraInfo")
                : this.$t("front.subscription.global.columns[2].extraInfoYearly"),
            extraFeature: this.$t("front.subscription.global.columns[2].extraFeature"),
            features: [
              {
                id: 0,
                name: this.$t("front.subscription.global.columns[2].features[0]"),
                functionality: "BUILDER_DESIGNS",
              },
              {
                id: 1,
                name: this.$t("front.subscription.global.columns[2].features[1]"),
                functionality: "RESIZE",
              },
              {
                id: 2,
                name: this.$t("front.subscription.global.columns[2].features[2]"),
                functionality: "BUILDER_TRANSLATION",
              },
            ],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[2].buttonLabel")
                : this.$t("front.subscription.global.columns[2].buttonLabelFreeTrial"),
            },
          },
          {
            id: "INTERMEDIATE",
            name: this.$t("front.subscription.global.columns[3].name"),
            price: this.billingFrequency === "MONTHLY" ? "14,90" : "148,90",
            description: this.$t("front.subscription.global.columns[3].description"),
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[3].extraInfo")
                : this.$t("front.subscription.global.columns[3].extraInfoYearly"),
            extraFeature: this.$t("front.subscription.global.columns[3].extraFeature"),
            features: [
              {
                id: 0,
                name: this.$t("front.subscription.global.columns[3].features[0]"),
                functionality: "MY_PRODUCTS",
              },
              {
                id: 1,
                name: this.$t("front.subscription.global.columns[3].features[1]"),
                functionality: "",
              },
              {
                id: 2,
                name: this.$t("front.subscription.global.columns[3].features[2]"),
                functionality: "APP_TRANSLATION",
              },
              {
                id: 3,
                name: this.$t("front.subscription.global.columns[3].features[3]"),
                functionality: "ANALYSIS",
              },
            ],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[3].buttonLabel")
                : this.$t("front.subscription.global.columns[3].buttonLabelFreeTrial"),
            },
          },
          {
            id: "ADVANCED",
            name: this.$t("front.subscription.global.columns[4].name"),
            price: this.billingFrequency === "MONTHLY" ? "24,90" : "248,90",
            description: this.$t("front.subscription.global.columns[4].description"),
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[4].extraInfo")
                : this.$t("front.subscription.global.columns[4].extraInfoYearly"),
            extraFeature: this.$t("front.subscription.global.columns[4].extraFeature"),
            features: [
              {
                id: 0,
                name: this.$t("front.subscription.global.columns[4].features[0]"),
                functionality: "WINEPAIRING",
              },
              {
                id: 1,
                name: this.$t("front.subscription.global.columns[4].features[1]"),
                functionality: "BEERPAIRING",
              },
              {
                id: 2,
                name: this.$t("front.subscription.global.columns[4].features[2]"),
                functionality: "",
              },
              {
                id: 3,
                name: this.$t("front.subscription.global.columns[4].features[3]"),
                functionality: "GAMES",
              },
            ],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[4].buttonLabel")
                : this.$t("front.subscription.global.columns[4].buttonLabelFreeTrial"),
            },
          },
        ],
      };
    },
  },
  methods: {
    isCurrentPlan(planId) {
      let planConstName = planId;
      if (this.billingFrequency === "YEARLY") {
        planConstName = planConstName + "_" + "ANNUAL";
      }
      return this.currentPlan === planConstName;
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-block {
  .subscription-block-header {
    margin-bottom: 1rem;
  }
  .subscription-block-columns {
    position: relative;
    z-index: 1;
    justify-content: center;
  }
}

@media screen and (max-width: 992px) {
  .normal-subscription {
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .subscription-block-columns:after {
    content: none;
  }
}

@media screen and (max-width: 576px) {
  .subscription-block {
    padding: 0 !important;
  }
}

.no-white-space {
  white-space: initial !important;
}
</style>
