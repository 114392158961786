export default {
  // watch: {
  //   '$i18n.locale': {
  //     // immediate: true,
  //     handler(newLang) {
  //       this.updateMeta();
  //     }
  //   }
  // },
  mounted() {
    this.updateMeta();
  },
  methods: {
    updateMeta() {
      const title = this.metaTitle;
      if (title) {
        document.title = title;
      }

      const description = this.metaDescription;
      if (description) {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute("content", description);
        }
      }
    },
  }
};
