// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#files-input-group .custom-file-input {
  border-radius: 10px;
}
#files-input-group .custom-file-input:focus .custom-file-label {
  box-shadow: none;
}
#files-input-group .custom-file-label {
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/form/FormFile.vue","webpack://./FormFile.vue"],"names":[],"mappings":"AAME;EACE,mBAAA;ACLJ;ADOI;EACE,gBAAA;ACLN;ADSE;EACE,mBAAA;ACPJ","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n#files-input-group {\n  .custom-file-input {\n    border-radius: 10px;\n\n    &:focus .custom-file-label {\n      box-shadow: none;\n    }\n  }\n\n  .custom-file-label {\n    border-radius: 10px;\n  }\n}\n","#files-input-group .custom-file-input {\n  border-radius: 10px;\n}\n#files-input-group .custom-file-input:focus .custom-file-label {\n  box-shadow: none;\n}\n#files-input-group .custom-file-label {\n  border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
