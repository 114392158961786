<template>
  <b-container fluid class="long-text-container pr-lg-0 pl-lg-0">
    <!-- Absolute image on the left displayed on large screens -->
    <div class="absolute-image">
      <!-- <b-img :src="image.src" :alt="image.alt"></b-img> -->
      <b-row v-for="image in images" :key="image.id">
        <b-img :src="image.src" :alt="image.alt"></b-img>
      </b-row>
    </div>
    <div class="long-text-inner-container">
      <div class="background-container">
        <div class="absolute-background"></div>
      </div>

      <b-container>
        <b-row>
          <!-- Image displayed on top of the text on small screens -->
          <b-col lg="5" class="d-lg-none">
            <b-row v-for="image in images" :key="image.id">
              <b-img :src="image.src" :alt="image.alt"></b-img>
            </b-row>
          </b-col>
          <!-- Text on the right on large screens and below on smaller ones -->
          <b-col lg="6" offset-lg="6" class="text-part">
            <h3>{{ subheading }}</h3>
            <h2>{{ heading }}</h2>
            <p>{{ description }}</p>
            <b-row class="blocs mt-4">
              <b-col
                cols="12"
                sm="6"
                v-for="(bloc, index) in blocs"
                :key="index"
                class="mt-3 mb-3"
              >
                <h5 class="bloc-heading">
                  <span>0</span><!--
                  --><span>{{ index + 1 }}</span><!--
                  --><span class="dot">.</span>
                  <span>{{ bloc.heading }}</span>
                </h5>
                <p class="bloc-description">{{ bloc.description }}</p>
              </b-col>
            </b-row>
            <b-row class="numbers">
              <b-col
                cols="12"
                sm="4"
                v-for="(number, index) in numbers"
                :key="index"
              >
                <p class="number">{{ number.number }}</p>
                <p class="caption">{{ number.caption }}</p>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "LongText",
  props: ["subheading", "heading", "description", "blocs", "numbers", "images"],
};
</script>

<style lang="scss" scoped>
/* Main container */
.long-text-container {
  position: relative;
  margin-top: 5rem;
  margin-bottom: 5rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  overflow: hidden;

  /* Image */
  .absolute-image {
    position: absolute;
    width: 40%;
    height: 100%;
    left: 0;
    top: -5rem;
    padding-top: inherit;
    z-index: 2;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .long-text-inner-container {
    position: relative;
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;

    /* Absolute gray decoration background */
    .background-container {
      .absolute-background {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 20%;
        background-color: $mizogoo-gray-light;
      }
    }

    /* Text */
    .text-part {
      padding-top: 6rem;
      padding-bottom: 6rem;

      .blocs {
        .dot {
          color: $mizogoo;
        }
      }
      
      /* Big numbers */
      .numbers {
        margin-top: 2rem;

        div {
          padding-top: 1rem;
          padding-bottom: 1rem;

          .number {
            font-size: 2rem !important;
            font-weight: 600 !important;
          }

          .caption {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/* Media queries */
@media screen and (max-width: 992px) {
  /* Replaces the former padding given that absolute background is not displayed anymore */
  .long-text-inner-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  /* Hides decoration grey background and absolute image */
  .absolute-background,
  .absolute-image {
    display: none;
  }
}
</style>
