<template>
  <div>
    <template v-if="modelIsAvailable(model)">
      <div
        class="card card-template"
        :class="{ 'card-template-active': currentModel && currentModel.id === model.id }"
        @click.prevent.stop="onClickModel(model)"
        :style="{ backgroundImage: 'url(' + model.previews[0] + ')' }"
      >
        <div class="card-template-overlay" />
        <div class="card-template-unlocked"></div>
      </div>
    </template>
    <template v-else>
      <div
        class="card card-template"
        @click="displayPremiumOnlyModal"
        :style="{ backgroundImage: 'url(' + model.previews[0] + ')' }"
      >
        <div class="card-template-overlay" />
        <div class="card-template-locked"></div>
        <div class="card-body card-img-overlay">
          <div class="card-template-information">
            <b-img
              :src="require('@mizogoo/assets/img/crown.png')"
              class="premium-badge"
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProductTemplateModelCard",
  props: {
    model: {
      type: Object,
      default: null,
    },
    currentModel: {
      type: Object,
      default: null,
    },
    functionality: {
      type: String,
      default: null,
    },
  },
  emits: ["onClickModel", "displayPremiumOnlyModal"],
  methods: {
    modelIsAvailable(model) {
      let modelIsAvailable = false;

      if (!model.free) {
        if (this.user) {
          if (this.user.currentSubscription !== null) {
            if (this.user.currentSubscription.isValid) {
              if (
                this.user.currentSubscription.subscription.functionalities.includes(
                  this.functionality
                )
              ) {
                modelIsAvailable = true;
              }
            }
          }
        }
      } else {
        modelIsAvailable = true;
      }

      return modelIsAvailable;
    },
    onClickModel(model) {
      this.$emit("onClickModel", model);
    },
    displayPremiumOnlyModal() {
      this.$emit("displayPremiumOnlyModal");
    },
  },
};
</script>

<style lang="scss" scoped>
.template-container {
  min-height: 50vh;
  padding-bottom: 15rem;

  .card-template {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $mizogoo-gray-dark !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-origin: border-box;
    background-position: center center;
    min-height: 300px;
    color: $white;
    border-radius: 0.5rem;

    .card-template-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    &:hover .card-template-overlay {
      opacity: 0.2;
    }

    &.card-template-active {
      outline: 2px solid $mizogoo;
    }

    .card-template-unlocked {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: $mizogoo-gray-light;
      opacity: 0.1;
      border-radius: 0.5rem;
    }

    .card-template-locked {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(11, 11, 11, 0.5);
      opacity: 0.5;
      border-radius: 0.5rem;
    }

    .card-template-information {
      position: absolute;
      top: 15px;
      right: 15px;
    }

    &:hover {
      cursor: pointer;
    }

    &.selected {
      border: 2px solid $mizogoo;
    }
  }
}
</style>
