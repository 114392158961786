import Vue from "vue";
import AccountDrinkCategoryAPI from "../api/account_drink_category";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  ANALYSING = "ANALYSING",
  ANALYSING_SUCCESS = "ANALYSING_SUCCESS",
  ANALYSING_ERROR = "ANALYSING_ERROR",

  TOGGLE_DRINKS_ACTIVATION = "TOGGLE_DRINKS_ACTIVATION",
  TOGGLE_DRINKS_ACTIVATION_SUCCESS = "TOGGLE_DRINKS_ACTIVATION_SUCCESS",
  TOGGLE_DRINKS_ACTIVATION_ERROR = "TOGGLE_DRINKS_ACTIVATION_ERROR",
  
  ADD_ITEM_INTO_CATEGORY = "ADD_ITEM_INTO_CATEGORY",
  REMOVE_ITEM_FROM_CATEGORY = "REMOVE_ITEM_FROM_CATEGORY",
  
  ADD_CATEGORY = "ADD_CATEGORY",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    drinkCategories: [],
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Analysing
    isLoadingAnalysing: false,
    errorAnalysing: null,
    // Toggle Drinks Activation
    isLoadingToggleDrinksActivation: false,
    errorToggleDrinksActivation: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasDrinkCategories (state) {
      return state.drinkCategories.length > 0;
    },
    drinkCategories (state) {
      return state.drinkCategories;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating (state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating (state) {
      return state.errorUpdating !== null;
    },
    errorUpdating (state) {
      return state.errorUpdating;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Analysing
    isLoadingAnalysing (state) {
      return state.isLoadingAnalysing;
    },
    hasErrorAnalysing (state) {
      return state.errorAnalysing !== null;
    },
    errorAnalysing (state) {
      return state.errorAnalysing;
    },
    // Toggle Drinks Activation
    isLoadingToggleDrinksActivation (state) {
      return state.isLoadingToggleDrinksActivation;
    },
    hasErrorToggleDrinksActivation (state) {
      return state.errorToggleDrinksActivation !== null;
    },
    errorToggleDrinksActivation (state) {
      return state.errorToggleDrinksActivation;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.drinkCategories = [];
    },
    [FETCH_ALL_SUCCESS] (state, drinkCategories) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.drinkCategories = drinkCategories;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.drinkCategories = [];
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state, drinkCategory) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.drinkCategories.push(drinkCategory);
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING] (state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS] (state, drinkCategory) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      let drinkCategoryToUpdate = state.drinkCategories.find(element => element.id === drinkCategory.id);
      drinkCategoryToUpdate.translations = drinkCategory.translations;
    },
    [UPDATING_ERROR] (state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, drinkCategory) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.drinkCategories = state.drinkCategories.filter(element => element.id !== drinkCategory);
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Analysing
    [ANALYSING] (state) {
      state.isLoadingAnalysing = true;
      state.errorAnalysing = null;
    },
    [ANALYSING_SUCCESS] (state, drinkCategory) {
      state.isLoadingAnalysing = false;
      state.errorAnalysing = null;
      let drinkCategoryToUpdate = state.drinkCategories.find(element => element.id === drinkCategory.id);
      drinkCategoryToUpdate.analyse = currentDrinkCategory.analyse;
    },
    [ANALYSING_ERROR] (state, error) {
      state.isLoadingAnalysing = false;
      state.errorAnalysing = error;
    },
    // Toggle Drinks Activation
    [TOGGLE_DRINKS_ACTIVATION] (state) {
      state.isLoadingToggleDrinksActivation = true;
      state.errorToggleDrinksActivation = null;
    },
    [TOGGLE_DRINKS_ACTIVATION_SUCCESS] (state, payload) {
      state.isLoadingToggleDrinksActivation = false;
      state.errorToggleDrinksActivation = null;

      const drinksFromCategory = this.getters['accountDrink/drinks'].filter(element => element.drinkCategoryId === payload.drinkCategory.id);

      for (let i = 0; i < drinksFromCategory.length; i++) {
        let drinkToActivate = drinksFromCategory[i];
        drinkToActivate.active = payload.value;
      }
    },
    [TOGGLE_DRINKS_ACTIVATION_ERROR] (state, error) {
      state.isLoadingToggleDrinksActivation = false;
      state.errorToggleDrinksActivation = error;
    },
    // Add item into category
    [ADD_ITEM_INTO_CATEGORY] (state, drink) {
      let drinkCategoryToUpdate = state.drinkCategories.find(element => element.id === drink.drinkCategoryId);
      drinkCategoryToUpdate.drinks.push(drink);
    },
    // Remove item from category
    [REMOVE_ITEM_FROM_CATEGORY] (state, drink) {
      let drinkCategoryToUpdate = state.drinkCategories.find(element => element.id === drink.drinkCategoryId);
      let drinkToRemoveIndex = drinkCategoryToUpdate.drinks.findIndex(element => element.id === drink.id);
      drinkCategoryToUpdate.drinks.splice(drinkToRemoveIndex, 1);
    },
    // Add category (used when a new category is created via import)
    [ADD_CATEGORY] (state, drinkCategory) {
      state.drinkCategories.push(drinkCategory);
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.drinkCategories = [];
    },
  },
  actions: {
    async findAll ({ commit }) {
      if (!this.state.accountDrinkCategory.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountDrinkCategoryAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.drinkCategories);
            return response.data.drinkCategories;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR, error);
          return null;
        }
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountDrinkCategoryAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.drinkCategory);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update ({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountDrinkCategoryAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.drinkCategory);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountDrinkCategoryAPI.remove(payload.drinkCategory);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.drinkCategory);
          return response.data;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async analyse ({ commit }, payload) {
      commit(ANALYSING);
      try {
        let response = await AccountDrinkCategoryAPI.analyse(payload.drinkCategory);
        if (response.data.success === true) {
          commit(ANALYSING_SUCCESS, response.data.drinkCategory);
          return response.data;
        } else {
          commit(ANALYSING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(ANALYSING_ERROR, error);
        return null;
      }
    },
    async toggleDrinksActivation ({ commit }, payload) {
      commit(TOGGLE_DRINKS_ACTIVATION);
      try {
        let response = await AccountDrinkCategoryAPI.toggleDrinksActivation(payload.form);
        if (response.data.success === true) {
          commit(TOGGLE_DRINKS_ACTIVATION_SUCCESS, payload.form);
          return response.data;
        } else {
          commit(TOGGLE_DRINKS_ACTIVATION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLE_DRINKS_ACTIVATION_ERROR, error);
        return null;
      }
    },
    async addItemIntoCategory ({ commit }, payload) {
      commit(ADD_ITEM_INTO_CATEGORY, payload.drink);
    },
    async removeItemFromCategory ({ commit }, payload) {
      commit(REMOVE_ITEM_FROM_CATEGORY, payload.drink);
    },
    async addCategory ({ commit }, payload) {
      commit(ADD_CATEGORY, payload.drinkCategory);
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
