<template>
  <div>
    <b-modal
      centered
      hide-header
      hide-footer
      no-stacking
      no-fade
      size="xl"
      id="subscription-modal"
    >
      <div class="subscription-modal-container">
        <!-- Header: title and explanations -->
        <b-container>
          <b-row>
            <b-col class="text-center">
              <h2>{{ $tm("front.subscription.page.topBanner.heading").join("") }}</h2>
              <p :class="{ 'mb-5': !displayBillingFrequency }">
                {{ $t("front.subscription.page.topBanner.description") }}
              </p>
              <template v-if="displayBillingFrequency">
                <!-- Toggle monthly/yearly subscription plan -->
                <b-row class="subscription-options">
                  <template
                    v-for="subscription in subscriptionOptions"
                    :key="subscription.id"
                  >
                    <div
                      v-on:click="setBillingFrequency(subscription.id)"
                      class="subscription-button"
                      :class="
                        billingFrequency === subscription.id ? 'selected-option' : ''
                      "
                    >
                      {{ subscription.name }}
                    </div>
                  </template>
                </b-row>
                <!-- Yearly discount tag -->
                <b-row align-v="center" class="mt-2 mb-5">
                  <b-col class="text-center font-italic">
                    {{ $t("front.subscription.page.topBanner.yearlyDiscount") }}
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <!-- Close button -->
          <div class="absolute-close-button" @click="$bvModal.hide('subscription-modal')">
            <i class="fas fa-times"></i>
          </div>
        </b-container>

        <subscription-block
          :billingFrequency="billingFrequency"
          :recommendedPlan="
            minimalSubscriptionPlan ? minimalSubscriptionPlan.constName : null
          "
          :currentPlan="currentSubscriptionPlan ? currentSubscriptionPlan : null"
          :functionality="functionality ? functionality : null"
        />
      </div>
    </b-modal>

    <b-modal
      centered
      hide-header
      hide-footer
      no-stacking
      no-fade
      size="xl"
      id="checkout-modal"
    >
      <div class="subscription-modal-container">
        <StripeSubscription :subscriptions="activeSubscriptions" />
        <div class="absolute-close-button" @click="$bvModal.hide('checkout-modal')">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubscriptionBlock from "@mizogoo/components/front/layout/subscription/SubscriptionBlock";
import StripeSubscription from "@mizogoo/components/payment/Stripe/StripeSubscription";

export default {
  name: "SubscriptionModal",
  components: {
    SubscriptionBlock,
    StripeSubscription,
  },
  props: ["functionality"],
  data() {
    return {
      billingFrequency: "MONTHLY",
    };
  },
  computed: {
    ...mapGetters("subscription", {
      isLoadingFetchAllSubscriptions: "isLoadingFetchAll",
      hasErrorFetchAllSubscriptions: "hasErrorFetchAll",
      errorFetchAllSubscriptions: "errorFetchAll",
      subscriptions: "subscriptions",
    }),
    activeSubscriptions() {
      return this.subscriptions.filter((element) => element.active);
    },
    minimalSubscriptionPlan() {
      if (this.subscriptions && this.functionality) {
        return this.subscriptions
          .filter((subscription) => subscription.active && subscription.isMonthly)
          .sort((a, b) => parseFloat(a.priceHt) - parseFloat(b.priceHt))
          .find((subscription) =>
            subscription.functionalities.includes(this.functionality)
          );
      }
    },
    currentSubscriptionPlan() {
      if (
        this.user &&
        this.user.currentSubscription &&
        this.user.currentSubscription.isValid &&
        !this.user.currentSubscription.isFree
      ) {
        return this.user.currentSubscription.subscription.constName;
      }
    },
    // Do not display monthly if the user already have an annual subscription
    displayBillingFrequency() {
      return this.user &&
        this.user.currentSubscription &&
        !this.user.currentSubscription.subscription.isMonthly
        ? false
        : true;
    },
    subscriptionOptions() {
      return [
        {
          id: "MONTHLY",
          name: this.$t("front.subscription.global.monthly"),
        },
        {
          id: "YEARLY",
          name: this.$t("front.subscription.global.yearly"),
        },
      ];
    },
  },
  methods: {
    setBillingFrequency(option) {
      this.billingFrequency = option;
    },
  },
  mounted() {
    if (!this.displayBillingFrequency) {
      this.billingFrequency = "YEARLY";
    }
  },
};
</script>

<style lang="scss">
#subscription-modal,
#checkout-modal {
  .modal-dialog {
    max-width: 1000px;
    max-height: 660px;
  }

  .modal-content {
    border-radius: 22px;
    border: none;
    position: relative;
    background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
    padding: 5px;
    border-radius: 25px;
  }

  .modal-header {
    border: 0;
    padding-right: 2rem;
    padding-bottom: 1.2rem;
  }

  .modal-body {
    padding: 0;
    background-color: #fff;
    border-radius: 22px;
  }

  .absolute-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50px;
    cursor: pointer;
    z-index: 5;
  }
}

#subscription-modal {
  .modal-dialog {
    width: calc(100vw - 20px);
    height: calc(100vh - 3.5rem);
    max-width: 1400px;
    max-height: 800px;
    max-width: none;
    transition: all ease-in-out 0.4s;
  }

  .subscription-modal-container {
    max-height: calc(100vh - (2.5rem + 10px));
    margin: 10px;
    overflow-y: auto;
  }

  h2 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: bold;
  }

  .custom-container {
    max-width: 1310px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }

  // Monthly or yearly switch
  .subscription-options {
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;

    .subscription-button {
      margin: 0.4rem 0.2rem;
      padding: 0.6rem 1.4rem;
      background-color: #f2f2f2;
      color: $mizogoo-gray-dark;
      outline: 2px solid transparent;
      -moz-outline-radius: 2px;
      border-radius: 10px;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Chrome, Opera, Firefox */

      &:hover {
        color: $mizogoo;
      }
    }

    .selected-option {
      color: $mizogoo;
      outline: 2px solid $mizogoo;
      outline-offset: -5px;
    }
  }

  .absolute-close-button {
    top: 15px;
    right: 40px;
  }
}
</style>
