<template>
  <b-container fluid class="top-banner">
    <b-container fluid class="top-banner-text">
      <b-container>
        <b-row class="mb-4">
          <b-col md="12" class="top">
            <div class="main-heading-container">
              <div
                v-for="(headingPart, index) in heading"
                :key="index"
                class="heading-inner-container"
              >
                <h1 v-scrollanimation="{ name: 'fade-up-appear'}">{{ headingPart }}</h1>
              </div>
            </div>
            <p v-scrollanimation="{ name: 'fade-up', delay: 250}">{{ description }}</p>
            <div class="buttons-container" v-scrollanimation="{ name: 'fade-up', delay: 500}">
              <router-link
                :to="{ name: buttons[0].link }"
                class="btn btn-white btn-plain-white"
              >
                <span>{{ buttons[0].label }}</span>
              </router-link>

              <a href="https://calendly.com/mizogoo/demo-mizogoo?text_color=151f29&primary_color=ff395e" target="_blank" class="btn btn-white">
                {{ buttons[1].label }}
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <b-container>
        <b-row class="home-top-banner-image-container">
          <b-col class="home-top-banner-image-left-col">
            <b-img
              fluid
              :src="require('@mizogoo/assets/img/front/home/banner/restaurant_owner_home_man.png')"
              class="banner-restaurant-owner"
            ></b-img>
            <b-img
              fluid
              :src="require('@mizogoo/assets/img/front/home/banner/orange_decorative_shape.png')"
              class="banner-decorative-shape"
            ></b-img>
          </b-col>
          <b-col class="home-top-banner-image-right-col">
            <b-img
              fluid
              :src="require('@mizogoo/assets/img/front/home/banner/restaurant_owner_home_woman.png')"
              class="banner-restaurant-owner"
            ></b-img>
            <b-img
              fluid
              :src="require('@mizogoo/assets/img/front/home/banner/red_decorative_shape.png')"
              class="banner-decorative-shape"
            ></b-img>
          </b-col>
        </b-row>
      </b-container>
      <div class="decorative-wire">
        <b-img
          fluid
          :src="require('@mizogoo/assets/img/front/home/banner/wire_decoration.png')"
        ></b-img>
      </div>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "HomeTopBanner",
  props: ["heading", "description", "buttons"],
};
</script>

<style lang="scss" scoped>
.top-banner {
  padding: 0;
  margin-bottom: 8rem;
  color: #fff;
  text-align: center;
  background-image: url("../../../../assets/img/front/global/decorations/dotted_background.svg"), linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.top-banner-text {
  position: relative;
  overflow: hidden;
  padding-top: 13rem;
}

.top-banner .top p {
  margin: 0;
  max-width: 700px;
  margin: auto;
}

.top-banner .buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  a {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    &.btn-plain-white {
      background-color: #fff;
      color: $mizogoo!important;
    }
  }
}

.home-top-banner-image-container {
  margin-top: -6rem;
  pointer-events: none;

  .home-top-banner-image-left-col {
    display: flex;
    align-items: end;
    margin-left: -4rem;
  }

  .home-top-banner-image-right-col {
    display: flex;
    justify-content: flex-end;
    align-items: end;
    margin-right: -4rem;
  }

  .banner-restaurant-owner {    
    position: relative;
    width: 380px;
    z-index: 2;
  }

  .banner-decorative-shape {
    position: absolute;
    bottom: 0;
    width: 450px;
  }
}

.decorative-wire {
  position: absolute;
  bottom: 50px;
  left: -10px;
  width: 102%;
}

@media screen and (max-width: 1200px) {
  .home-top-banner-image-container {
    margin-top: 0;
  }
  .home-top-banner-image-left-col {
    margin-left: 0;
  }
  .home-top-banner-image-right-col {
    margin-right: 0;
  }
}

@media screen and (max-width: 576px) {
  .top-banner {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  
  .top-banner-text {
    padding-top: 10rem;
  }
}
</style>
