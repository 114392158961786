import AccountOnboardingAPI from "../api/account_onboarding";

const
  ADDING_ONBOARDING = "ADDING_ONBOARDING",
  ADDING_ONBOARDING_SUCCESS = "ADDING_ONBOARDING_SUCCESS",
  ADDING_ONBOARDING_ERROR = "ADDING_ONBOARDING_ERROR";

export default {
  namespaced: true,
  state: {
    isLoadingAction: false,
    error: null,
    onboardings: []
  },
  getters: {
    isLoadingAction (state) {
      return state.isLoadingAction;
    },
    hasError (state) {
      return state.error !== null;
    },
    error (state) {
      return state.error;
    },
  },
  mutations: {
    // Add shown onboarding to user
    [ADDING_ONBOARDING] (state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [ADDING_ONBOARDING_SUCCESS] (state) {
      state.isLoadingAction = false;
      state.error = null;
    },
    [ADDING_ONBOARDING_ERROR] (state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
  },
  actions: {
    async add ({ commit }, payload) {
      commit(ADDING_ONBOARDING);
      try {
        let response = await AccountOnboardingAPI.add(payload.form);
        if (response.data.success === true) {
          commit(ADDING_ONBOARDING_SUCCESS);
          return response.data.onboarding;
        } else {
          commit(ADDING_ONBOARDING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(ADDING_ONBOARDING_ERROR, error);
        return null;
      }
    },
  }
}
