<template>
	<div v-if="isLoading" class="loading">
		<div class="text-center">
			<b-spinner label="Spinning"></b-spinner>
		</div>
	</div>
</template>

<script>
export default {
	name: "RedirectMenuBuilder",
	data() {
		return {
			isLoading: true,
		}
	},
	async mounted() {
		// Hide header and footer
		let header = document.getElementById('header');
		let footer = document.getElementById('footer');
		
    if (header) { header.style.display = 'none'; }
		if (footer) { footer.style.display = 'none'; }
    
		try {
      const dataCreation = await this.createCreation();
      if (dataCreation) {
        // Delete product settings from the session storage
        localStorage.removeItem("savedProductSettings");
				window.location.replace('/workspace/menubuilder/' + dataCreation.creation.id);
      }
    } catch (error) {
      console.error("Erreur lors de la création :", error);
      this.$router.replace({ name: "catalog" });
    }

		this.isLoading = false;
	},
	beforeUnmount() {
		// Display again header and footer
		let header = document.getElementById('header');
		let footer = document.getElementById('footer');
		
		if (header) { header.style.display = 'inherit'; }
		if (footer) { footer.style.display = 'inherit'; }
	}
}
</script>

<style lang="scss" scoped>
.loading {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	margin: auto;
}
</style>
