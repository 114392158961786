<template>
  <div v-if="step.attributValues" class="mt-3 mb-3">
    <!-- Attribute name -->
    <div class="attribute-name">
      {{ step.name }}
    </div>

    <!-- Attribute selection -->
    <div class="attributes-container">
      <div
        v-for="attributValue in step.attributValues"
        :key="attributValue.id"
        class="attribute-container"
        :class="{
          'active-attribute-container':
            attributValue.id ===
            currentAttributeValues[step.id - nbStepBeforeAttribute].id,
        }"
        @click="onClickAttributeValue(step, attributValue)"
      >
        <div class="attribute-value w-100 justify-content-center align-items-center text-center">
          <span class="feature-value-text">{{ attributValue.name }}</span>
          <template v-if="attributValue.priceHt > 0">
            <small class="ml-1"
              >(+{{ formatPrice(attributValue.priceHt) }})</small
            >
          </template>
          <template v-if="attributValue.description">
            <span
              :id="'attribut-value-extra-info-' + attributValue.id"
              class="ml-2"
            >
              <i class="far fa-question-circle"></i>
            </span>
            <b-popover
              :target="'attribut-value-extra-info-' + attributValue.id"
              triggers="hover"
              placement="right"
            >
              <div class="">
                <p class="m-0">
                  {{ attributValue.description }}
                </p>
              </div>
            </b-popover>
          </template>
        </div>
      </div>
    </div>
  </div>
   
</template>

<script>
export default {
  name: "CustomizableAttribute",
  props: ["step", "currentAttributeValues", "nbStepBeforeAttribute"],
  methods: {
    onClickAttributeValue(step, attributeValue) {
      this.$emit('onClickAttributeValue', step, attributeValue);
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-value-text {
  font-weight: 500;
}
</style>
