<template>
  <b-container class="secondary-page-container">
    <b-row class="justify-content-center">
      <b-col>
        <div class="text-center">
          <h3 class="text-primary">{{ $t("front.login.subheading") }}</h3>
          <h1 class="pb-2">{{ $t("front.login.heading") }}</h1>
        </div>

        <div class="inner-container">
          <div class="mb-2">
            <a href="/api/security/google/connect" class="btn social-media-button">
              <i class="fab fa-google mr-1"></i>
              {{ $t("front.login.googleConnect") }}
            </a>
          </div>

          <div class="mb-2">
            <a href="/api/security/facebook/connect" class="btn social-media-button">
              <i class="fab fa-facebook mr-1"></i>
              {{ $t("front.login.facebookConnect") }}
            </a>
          </div>

          <p class="text-uppercase text-center mt-4">{{ $t("front.login.or") }}</p>

          <div class="mb-4">
            <Form as="" v-slot="{ handleSubmit }">
              <b-form @submit="handleSubmit(onSubmit)">
                <FlashBag :message="error" v-if="hasError" />
                <FlashBag :message="errorUrl" v-if="hasErrorUrl" />

                <FormInput
                  type="email"
                  id="email"
                  :name="$t('front.login.email')"
                  :rules="{ required: true, email: true }"
                  v-model="form.email"
                />

                <FormPassword
                  id="password"
                  :name="$t('front.login.password')"
                  :rules="{ required: true }"
                  v-model:password="form.password"
                  :displayRequirements="false"
                ></FormPassword>

                <div class="clearfix mb-3 font-sm">
                  <router-link
                    class="text-muted float-right"
                    :to="{ name: 'password_reset' }"
                  >
                    {{ $t("front.login.forgotPassword") }}
                  </router-link>

                  <div class="overflow-hidden">
                    <b-form-checkbox>
                      {{ $t("front.login.rememberMe") }}
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="mt-4 text-center">
                  <div v-if="isLoading">
                    {{ $t("global.loading") }}
                  </div>
                  <button type="submit" class="btn btn-outline-primary" v-else>
                    {{ $t("front.login.connection") }}
                  </button>
                </div>
              </b-form>
            </Form>
          </div>

          <div class="mb-4 text-center">
            {{ $t("front.login.noAccount") }}
            <router-link :to="{ name: 'registration' }">
              {{ $t("front.login.createAccount") }}
            </router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import FlashBag from "@mizogoo/components/FlashBag";
import FormInput from "@mizogoo/components/form/FormInput";
import FormPassword from "@mizogoo/components/form/FormPassword";

export default {
  mixins: [MetaMixin],
  name: "LoginPage",
  components: {
    FlashBag,
    FormInput,
    FormPassword,
  },
  props: {},
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errorUrl: null,
    };
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.login.title");
    },
    isLoading() {
      return this.$store.getters["security/isLoadingLogin"];
    },
    hasError() {
      return this.$store.getters["security/hasErrorLogin"];
    },
    error() {
      return this.$store.getters["security/errorLogin"];
    },
    hasErrorUrl() {
      return this.errorUrl !== null;
    },
  },
  methods: {
    async onSubmit() {
      await this.$store.dispatch("security/login", { form: this.form });
      if (!this.hasError) {
        this.$router.go(0);
      }
    },
  },
  created() {
    if (typeof this.$route.query.s !== "undefined") {
      if (this.$route.query.s === "user_disabled") {
        this.errorUrl = this.$t("security.login.userDisabled");
      } else if (this.$route.query.s === "user_not_validated") {
        this.errorUrl = this.$t("security.login.userNotValidated");
      } else if (this.$route.query.s === "bad_user") {
        this.errorUrl = this.$t("security.login.invalidGrant");
      }
    }
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
};
</script>
