import PromotionAPI from '../api/promotion'

const
  FETCH_ALL = 'FETCH_ALL',
  FETCH_ALL_SUCCESS = 'FETCH_ALL_SUCCESS',
  FETCH_ALL_ERROR = 'FETCH_ALL_ERROR',

  FETCH_ONE = 'FETCH_ONE',
  FETCH_ONE_SUCCESS = 'FETCH_ONE_SUCCESS',
  FETCH_ONE_ERROR = 'FETCH_ONE_ERROR'

export default {
  namespaced: true,
  state: {
    // Fetch All, One
    isLoading: false,
    error: null,
    // Data
    promotions: [],
  },
  getters: {
    // Fetch All, One
    isLoading (state) {
      return state.isLoading
    },
    error (state) {
      return state.error
    },
    hasError (state) {
      return state.error !== null
    },
    // Data
    promotions (state) {
      return state.promotions
    },
    hasPromotions (state) {
      return state.promotions.length > 0
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoading = true
      state.error = null
      state.promotions = []
    },
    [FETCH_ALL_SUCCESS] (state, dishes) {
      state.isLoading = false
      state.error = null
      state.promotions = dishes
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
      state.promotions = []
    },
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoading = true
      state.error = null
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoading = false
      state.error = null
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoading = false
      state.error = error
    },
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ALL)
      try {
        let response = await PromotionAPI.findAll()
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.promotions)
          return response.data.promotions
        } else {
          commit(FETCH_ALL_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR)
        return null
      }
    },
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE)
      try {
        let response = await PromotionAPI.findOne(payload.promotion)
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS)
          return response.data.promotion
        } else {
          commit(FETCH_ONE_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR)
        return null
      }
    },
  }
}
