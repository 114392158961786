<template>
  <b-container>
    <b-row class="mt-5 mb-5">
      <b-col>
        <h1>h1. Bootstrap heading</h1>
        <h2>h2. Bootstrap heading</h2>
        <h3>h3. Bootstrap heading</h3>
        <h4>h4. Bootstrap heading</h4>
        <h5>h5. Bootstrap heading</h5>
        <h6>h6. Bootstrap heading</h6>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5">
      <b-col>
        <p>p. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean mattis placerat neque eget placerat.</p>
        <p class="text-primary">p. text-primary</p>
        <p class="text-mizogoo-gray">p. text-mizogoo-gray</p>
        <p class="text-mizogoo-gray-dark">p. text-mizogoo-gray-dark</p>
        <p class="text-mizogoo-gray-light">p. text-mizogoo-gray-light</p>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5">
      <b-col>
        <a href="javascript:;">Link</a>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5">
      <b-col>
        <button type="button" class="btn btn-primary">Primary</button>
        <button type="button" class="btn btn-secondary">Secondary</button>
        <button type="button" class="btn btn-success">Success</button>
        <button type="button" class="btn btn-danger">Danger</button>
        <button type="button" class="btn btn-warning">Warning</button>
        <button type="button" class="btn btn-info">Info</button>
        <button type="button" class="btn btn-light">Light</button>
        <button type="button" class="btn btn-dark">Dark</button>

        <button type="button" class="btn btn-link">Link</button>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5">
      <b-col>
        <button type="button" class="btn btn-outline-primary">Primary</button>
        <button type="button" class="btn btn-outline-secondary">Secondary</button>
        <button type="button" class="btn btn-outline-success">Success</button>
        <button type="button" class="btn btn-outline-danger">Danger</button>
        <button type="button" class="btn btn-outline-warning">Warning</button>
        <button type="button" class="btn btn-outline-info">Info</button>
        <button type="button" class="btn btn-outline-light">Light</button>
        <button type="button" class="btn btn-outline-dark">Dark</button>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5">
      <b-col>
        <div class="form-group">
          <label for="exampleFormControlInput1">Email address</label>
          <input type="email" class="form-control" id="exampleFormControlInput1" placeholder="name@example.com">
        </div>

        <div class="form-group">
          <label for="exampleFormControlInput2">Email address</label>
          <input type="text" class="form-control" id="exampleFormControlInput2" placeholder="name@example.com" disabled>
        </div>

        <div class="form-group">
          <label for="exampleFormControlSelect1">Example select</label>
          <select class="form-control" id="exampleFormControlSelect1">
            <option>1</option>
            <option>2</option>
            <option>3</option>
            <option>4</option>
            <option>5</option>
          </select>
        </div>

        <div class="form-group">
          <label for="exampleFormControlTextarea1">Example textarea</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-5 mb-5">
      <b-col>
        <b-alert show variant="primary">Primary Alert</b-alert>
        <b-alert show variant="secondary">Secondary Alert</b-alert>
        <b-alert show variant="success">Success Alert</b-alert>
        <b-alert show variant="danger">Danger Alert</b-alert>
        <b-alert show variant="warning">Warning Alert</b-alert>
        <b-alert show variant="info">Info Alert</b-alert>
        <b-alert show variant="light">Light Alert</b-alert>
        <b-alert show variant="dark">Dark Alert</b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'StylePage',
  props: {}
}
</script>
