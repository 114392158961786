import CartAPI from '../api/cart'

const
  FETCH_CART = 'FETCH_CART',
  FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS',
  FETCH_CART_ERROR = 'FETCH_CART_ERROR',

  PROVIDING_CART_DATA = 'PROVIDING_CART_DATA',
  PROVIDING_PRICES_DATA = 'PROVIDING_PRICES_DATA',

  UPDATING_ADDRESS = 'UPDATING_ADDRESS',
  UPDATING_ADDRESS_SUCCESS = 'UPDATING_ADDRESS_SUCCESS',
  UPDATING_ADDRESS_ERROR = 'UPDATING_ADDRESS_ERROR',

  FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS',
  FETCH_PAYMENT_METHODS_SUCCESS = 'FETCH_PAYMENT_METHODS_SUCCESS',
  FETCH_PAYMENT_METHODS_ERROR = 'FETCH_PAYMENT_METHODS_ERROR',

  FETCH_VALIDATED = 'FETCH_VALIDATED',
  FETCH_VALIDATED_SUCCESS = 'FETCH_VALIDATED_SUCCESS',
  FETCH_VALIDATED_ERROR = 'FETCH_VALIDATED_ERROR',

  FETCH_ADD_PROMOTION = 'FETCH_ADD_PROMOTION',
  FETCH_ADD_PROMOTION_SUCCESS = 'FETCH_ADD_PROMOTION_SUCCESS',
  FETCH_ADD_PROMOTION_ERROR = 'FETCH_ADD_PROMOTION_ERROR',

  REMOVING_PROMOTION = 'REMOVING_PROMOTION',
  REMOVING_PROMOTION_SUCCESS = 'REMOVING_PROMOTION_SUCCESS',
  REMOVING_PROMOTION_ERROR = 'REMOVING_PROMOTION_ERROR'

export default {
  namespaced: true,
  state: {
    // Fetch Cart
    isLoadingCart: false,
    errorCart: null,
    cart: null,
    prices: [],
    // Updating Address
    isLoadingUpdatingAddress: false,
    errorUpdatingAddress: null,
    // Fetch PaymentMethods
    isLoadingPaymentMethods: false,
    errorPaymentMethods: null,
    paymentMethods: [],
    // Fetch Validated
    isLoadingValidated: false,
    errorValidated: null,
    orders: [],
    payment: null,
    // Fetch Add Promotion
    isLoadingAddPromotion: false,
    errorAddPromotion: null,
    // Removing Promotion
    isLoadingRemovingPromotion: false,
    errorRemovingPromotion: null,
  },
  getters: {
    // Fetch Cart
    isLoadingCart (state) {
      return state.isLoadingCart
    },
    hasErrorCart (state) {
      return state.errorCart !== null
    },
    errorCart (state) {
      return state.errorCart
    },
    cart (state) {
      return state.cart
    },
    prices (state) {
      return state.prices
    },
    // Updating Address
    isLoadingUpdatingAddress (state) {
      return state.isLoadingUpdatingAddress
    },
    hasErrorUpdatingAddress (state) {
      return state.errorUpdatingAddress !== null
    },
    errorUpdatingAddress (state) {
      return state.errorUpdatingAddress
    },
    // Fetch PaymentMethods
    isLoadingPaymentMethods (state) {
      return state.isLoadingPaymentMethods
    },
    hasErrorPaymentMethods (state) {
      return state.errorPaymentMethods !== null
    },
    errorPaymentMethods (state) {
      return state.errorPaymentMethods
    },
    paymentMethods (state) {
      return state.paymentMethods
    },
    // Fetch Validated
    isLoadingValidated (state) {
      return state.isLoadingValidated
    },
    hasErrorValidated (state) {
      return state.errorValidated !== null
    },
    errorValidated (state) {
      return state.errorValidated
    },
    orders (state) {
      return state.orders
    },
    payment (state) {
      return state.payment
    },
    // Fetch Add Promotion
    isLoadingAddPromotion (state) {
      return state.isLoadingAddPromotion
    },
    hasErrorAddPromotion (state) {
      return state.errorAddPromotion !== null
    },
    errorAddPromotion (state) {
      return state.errorAddPromotion
    },
    // Removing Promotion
    isLoadingRemovingPromotion (state) {
      return state.isLoadingRemovingPromotion
    },
    hasErrorRemovingPromotion (state) {
      return state.errorRemovingPromotion !== null
    },
    errorRemovingPromotion (state) {
      return state.errorRemovingPromotion
    },
  },
  mutations: {
    // Fetch Cart
    [FETCH_CART] (state) {
      state.isLoadingCart = true
      state.errorCart = null
      state.cart = null
      state.prices = []
    },
    [FETCH_CART_SUCCESS] (state, payload) {
      state.isLoadingCart = false
      state.errorCart = null
      state.cart = payload.cart
      state.prices = payload.prices
    },
    [FETCH_CART_ERROR] (state, error) {
      state.isLoadingCart = false
      state.errorCart = error
      state.cart = null
      state.prices = []
    },
    // Providing Cart Data
    [PROVIDING_CART_DATA] (state, cart) {
      state.cart = cart
    },
    // Providing Prices Data
    [PROVIDING_PRICES_DATA] (state, prices) {
      state.prices = prices
    },
    // Updating Address
    [UPDATING_ADDRESS] (state) {
      state.isLoadingUpdatingAddress = true
      state.errorUpdatingAddress = null
    },
    [UPDATING_ADDRESS_SUCCESS] (state) {
      state.isLoadingUpdatingAddress = false
      state.errorUpdatingAddress = null
    },
    [UPDATING_ADDRESS_ERROR] (state, error) {
      state.isLoadingUpdatingAddress = false
      state.errorUpdatingAddress = error
    },
    // Fetch PaymentMethods
    [FETCH_PAYMENT_METHODS] (state) {
      state.isLoadingPaymentMethods = true
      state.errorPaymentMethods = null
      state.paymentMethods = []
    },
    [FETCH_PAYMENT_METHODS_SUCCESS] (state, payload) {
      state.isLoadingPaymentMethods = false
      state.errorPaymentMethods = null
      state.paymentMethods = payload.paymentMethods
    },
    [FETCH_PAYMENT_METHODS_ERROR] (state, error) {
      state.isLoadingPaymentMethods = false
      state.errorPaymentMethods = error
      state.paymentMethods = []
    },
    // Fetch Validated
    [FETCH_VALIDATED] (state) {
      state.isLoadingValidated = true
      state.errorValidated = null
      state.orders = []
      state.payment = null
    },
    [FETCH_VALIDATED_SUCCESS] (state, payload) {
      state.isLoadingValidated = false
      state.errorValidated = null
      state.orders = payload.orders
      state.payment = payload.payment
    },
    [FETCH_VALIDATED_ERROR] (state, error) {
      state.isLoadingValidated = false
      state.errorValidated = error
      state.orders = []
      state.payment = null
    },
    // Fetch Add Promotion
    [FETCH_ADD_PROMOTION] (state) {
      state.isLoadingAddPromotion = true
      state.errorAddPromotion = null
    },
    [FETCH_ADD_PROMOTION_SUCCESS] (state, payload) {
      state.isLoadingAddPromotion = false
      state.errorAddPromotion = null
      state.cart = payload.cart
      state.prices = payload.prices
    },
    [FETCH_ADD_PROMOTION_ERROR] (state, error) {
      state.isLoadingAddPromotion = false
      state.errorAddPromotion = error
    },
    // Removing Promotion
    [REMOVING_PROMOTION] (state) {
      state.isLoadingRemovingPromotion = true
      state.errorRemovingPromotion = null
    },
    [REMOVING_PROMOTION_SUCCESS] (state, payload) {
      state.isLoadingRemovingPromotion = false
      state.errorRemovingPromotion = null
      state.cart = payload.cart
      state.prices = payload.prices
    },
    [REMOVING_PROMOTION_ERROR] (state, error) {
      state.isLoadingRemovingPromotion = false
      state.errorRemovingPromotion = error
    },
  },
  actions: {
    async findCart ({ commit }) {
      commit(FETCH_CART)
      try {
        let response = await CartAPI.findCart()
        if (response.data.success === true) {
          commit(FETCH_CART_SUCCESS, response.data)
          return response.data.cart
        } else {
          commit(FETCH_CART_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_CART_ERROR)
        return null
      }
    },
    providingCartData ({ commit }, cart) {
      commit(PROVIDING_CART_DATA, cart)
    },
    providingPricesData ({ commit }, prices) {
      commit(PROVIDING_PRICES_DATA, prices)
    },
    async updateAddress ({ commit }, payload) {
      commit(UPDATING_ADDRESS)
      try {
        let response = await CartAPI.updateAddress(payload)
        if (response.data.success === true) {
          commit(UPDATING_ADDRESS_SUCCESS, response.data)
          return null
        } else {
          commit(UPDATING_ADDRESS_ERROR)
          return null
        }
      } catch (error) {
        commit(UPDATING_ADDRESS_ERROR)
        return null
      }
    },
    async findPaymentMethods ({ commit }) {
      commit(FETCH_PAYMENT_METHODS)
      try {
        let response = await CartAPI.findPaymentMethods()
        if (response.data.success === true) {
          commit(FETCH_PAYMENT_METHODS_SUCCESS, response.data)
          return response.data.paymentMethods
        } else {
          commit(FETCH_PAYMENT_METHODS_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_PAYMENT_METHODS_ERROR)
        return null
      }
    },
    async findValidated ({ commit }, payload) {
      commit(FETCH_VALIDATED)
      try {
        let response = await CartAPI.findValidated(payload.reference)
        if (response.data.success === true) {
          commit(FETCH_VALIDATED_SUCCESS, response.data)
          return response.data.orders
        } else {
          commit(FETCH_VALIDATED_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_VALIDATED_ERROR)
        return null
      }
    },
    async addPromotion ({ commit }, payload) {
      commit(FETCH_ADD_PROMOTION)
      try {
        let response = await CartAPI.addPromotion(payload)
        if (response.data.success === true) {
          commit(FETCH_ADD_PROMOTION_SUCCESS, response.data)
          return response.data
        } else {
          commit(FETCH_ADD_PROMOTION_ERROR)
          return null
        }
      } catch (error) {
        commit(FETCH_ADD_PROMOTION_ERROR)
        return null
      }
    },
    async removePromotion ({ commit }) {
      commit(REMOVING_PROMOTION)
      try {
        let response = await CartAPI.removePromotion()
        if (response.data.success === true) {
          commit(REMOVING_PROMOTION_SUCCESS, response.data)
          return response.data
        } else {
          commit(REMOVING_PROMOTION_ERROR)
          return null
        }
      } catch (error) {
        commit(REMOVING_PROMOTION_ERROR)
        return null
      }
    },
  }
}
