<template>  
  <div v-if="step.featureValues" class="mt-3 mb-3">
    <!-- Format section name -->
    <div class="feature-name">
      {{ step.name }}
    </div>

    <!-- Format selection (only the 4 first initialy) -->
    <div class="formats-container">
      <div
        v-for="featureValue in limitedFormats"
        :key="featureValue.id"
        class="format-container"
        :class="{
          'active-format-container':
            featureValue.id ===
            currentFeatureValues[step.id - nbStepBeforeFeature].id,
          'illustrated-format-container':
            (featureImage = getFeatureImage(featureValue))
        }"
        @click="onClickFeatureValue(step, featureValue)"
      >
        <div class="feature-value w-100 flex-column justify-content-center text-center">
          <template v-if="featureImage">
            <img :src="featureImage" class="format-illustration" />
          </template>
          
          <div>
            <div class="format-main">
              <span v-html="replaceSpacesAfterDash(featureValue.value)"></span>
            </div>
            <div class="format-extra">{{ getUnfoldedFormat(featureValue) }}</div>
            <template v-if="getFilteredFeatureFields(featureValue).length > 0">
              <span
                :id="'feature-value-extra-info-' + featureValue.id"
                class="ml-2"
              >
                <i class="far fa-question-circle"></i>
              </span>
              <b-popover
                :target="'feature-value-extra-info-' + featureValue.id"
                triggers="hover"
                placement="right"
              >
                <div class="">
                  <div v-for="field in getFilteredFeatureFields(featureValue)" :key="field.id">
                    <p class="m-0">
                      <strong>{{ field.name }}</strong> {{ field.value }}
                    </p>
                  </div>
                </div>
              </b-popover>
            </template>
          </div>
        </div>
      </div>

      <!-- Remaining formats -->
      <b-collapse v-model="allFormatsVisible" class="features-container w-100">
        <div
          v-for="featureValue in remainingFormats"
          :key="featureValue.id"
          class="format-container"
          :class="{
            'active-format-container':
              featureValue.id ===
              currentFeatureValues[step.id - nbStepBeforeFeature].id,
            'illustrated-format-container':
              (featureImage = getFeatureImage(featureValue))
          }"
          @click="onClickFeatureValue(step, featureValue)"
        >
          <div class="feature-value w-100 flex-column justify-content-center text-center">
            <template v-if="featureImage">
              <img :src="featureImage" class="format-illustration" />
            </template>
            <div>
              <div class="format-main">
                <span v-html="replaceSpacesAfterDash(featureValue.value)"></span>
              </div>
              <div class="format-extra">{{ getUnfoldedFormat(featureValue) }}</div>
              <template v-if="filteredFeatureFields = getFilteredFeatureFields(featureValue).length > 0">
                <span
                  :id="'feature-value-extra-info-' + featureValue.id"
                  class="ml-2"
                >
                  <i class="far fa-question-circle"></i>
                </span>
                <b-popover
                  :target="'feature-value-extra-info-' + featureValue.id"
                  triggers="hover"
                  placement="right"
                >
                  <div class="">
                    <div v-for="field in filteredFeatureFields" :key="field.id">
                      <p class="m-0">
                        <strong>{{ field.name }}</strong> {{ field.value }}
                      </p>
                    </div>
                  </div>
                </b-popover>
              </template>
            </div>
          </div>
        </div>
      </b-collapse>

      <!-- Toggle collapse button -->
      <div v-if="Object.keys(remainingFormats).length > 0" class="show-more" @click="allFormatsVisible = !allFormatsVisible">
        <template v-if="allFormatsVisible">
          {{ $t('front.product.showLess') }}
        </template>
        <template v-else>
          {{ $t('front.product.showMore') }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomizableFormat",
  props: ["step", "currentFeatureValues", "nbStepBeforeFeature"],
  data() {
    return {
      allFormatsVisible: false,
    }
  },
  computed: {
    limitedFormats() {
      return Object.fromEntries(Object.entries(this.step.featureValues).slice(0, 4));
    },
    remainingFormats() {
      return Object.fromEntries(Object.entries(this.step.featureValues).slice(4));
    },
  },
  methods: {
    onClickFeatureValue(step, featureValue) {
      this.$emit('onClickFeatureValue', step, featureValue);
    },
    getFilteredFeatureFields(featureValue) {
      return featureValue.fields.filter(
        (field) =>
          field.name != "Image" &&
          field.name != "Format Ouvert" &&
          field.name != "Format Fermé" &&
          field.name != "Nombre de volets"
      );
    },
    getFeatureImage(featureValue) {
      let featureImage = featureValue.fields.find((filed) => filed.name == 'Image');
      return featureImage ? featureImage.value : null;
    },
    getUnfoldedFormat(featureValue) {
      let featureFormat = featureValue.fields.find((filed) => filed.name == 'Format Ouvert');
      return featureFormat
        ? "(" + featureFormat.value.replace(/ /g, '\u202F') + " " + this.$t("front.product.unfolded") + ")"
        : null;
    },
    replaceSpacesAfterDash(input) {
      const SPACE_FINE = '\u202F';
      const parts = input.split(/[-–] /);

      if (parts.length > 1) {
        parts[1] = parts[1].replace(/ /g, SPACE_FINE);
      }

      return parts.join('<br/>');
    },
  },
}
</script>

<style lang="scss" scoped>
.formats-container {
  .format-container {
    width: calc(25% - 9px);

    @media (max-width: 992px) {
      width: calc(50% - 9px);
    }

    .format-illustration {
      width: auto;
      height: 100px;
      margin-bottom: 1rem;
      max-width: 85%;
      object-fit: contain;
    }
  }

  .illustrated-format-container {
    padding: 0.8rem 0.5rem!important;
  }

  .show-more {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
    
    &:hover {
      color: color.adjust($mizogoo-gray-dark, $lightness: -5%);
    }
  }
}
</style>

<style lang="scss">
.formats-container {
  .format-main {
    margin-bottom: 0.2rem;
    font-weight: 500;
    line-height: 1.3rem;
  }

  .format-extra {
    font-size: 0.8rem;
  }
}
</style>
