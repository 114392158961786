<template>
  <div :class="{ preview: isPreview }">
    <!-- Displays black corners to improve fake smartphone preview -->
    <template v-if="isPreview">
      <div class="corner corner-top-left"></div>
      <div class="corner corner-top-right"></div>
      <div class="corner corner-bottom-left"></div>
      <div class="corner corner-bottom-right"></div>
    </template>

    <!-- Ad displayed on top of the page for those who don't have subscribed to any plan -->
    <template v-if="displayAds && !isPreview">
      <div class="ad-container">
        <ins
          class="adsbygoogle"
          style="display: block; width: 100%; height: 90px"
          data-ad-client="ca-pub-2674192338820553"
          data-ad-slot="4128646763"
        >
        </ins>
      </div>
    </template>

    <b-container fluid class="qr-code" :style="sectionStyles">
      <b-container class="inner-container">
        <b-row>
          <b-col v-if="hasLogo" md="12" class="text-center mb-4 mt-5">
            <b-img
              :src="logo.src"
              fluid
              class="logo"
              :alt="hasCompanyName ? company : 'Logo'"
            ></b-img>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" class="text-center" :class="{ 'mt-5': !hasLogo }">
            <h1
              :style="{
                fontSize: '2rem',
                fontWeight: 'bold',
                color: titleColor,
              }"
            >
              {{ getTitle() }}
            </h1>
          </b-col>
        </b-row>

        <b-row>
          <!-- Pdf documents -->
          <b-col v-if="hasDocument" md="12" class="text-center">
            <h2
              class="mb-5"
              :style="{
                color: subTitleColor,
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }"
            >
              {{ subTitle }}
            </h2>

            <ul class="list-unstyled mt-5 mb-0">
              <li
                v-for="(institutionDocument, index) in sortedInstitutionDocuments"
                :key="index"
                class="pb-4"
              >
                <a
                  :href="institutionDocument.document.src"
                  class="btn btn-block"
                  :style="{
                    backgroundColor: buttonBackgroundColor,
                    color: buttonTextColor,
                    borderRadius: '7px',
                    fontWeight: 400,
                    border: '1px solid ' + buttonBackgroundColor,
                  }"
                  target="_blank"
                >
                  {{ getButtonName(institutionDocument) }}
                </a>
              </li>
            </ul>
          </b-col>
          <b-col v-else cols="12" class="text-center">
            <h2
              class="mb-5"
              :style="{
                color: subTitleColor,
                fontSize: '1.2rem',
                fontWeight: 'bold',
              }"
            >
              Ce restaurant n'a aucune carte active pour le moment.
            </h2>
          </b-col>
        </b-row>
      </b-container>

      <b-row v-if="!isPreview && !isPremium" class="footer">
        <b-col>
          <p><a href="https://www.mizogoo.fr" target="_blank">mizogoo.fr</a></p>
          <p>Solutions de communication pour les professionnels de la restauration.</p>
          <p>Tous droits réservés. &copy; {{ fullYear }}</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "InstitutionHomePageView",
  props: [
    "isPreview",
    "hash",
    "isPremium",
    "company",
    "institutionDocuments",
    "logo",
    "title",
    "subTitle",
    "backgroundColor",
    "titleColor",
    "subTitleColor",
    "buttonBackgroundColor",
    "buttonTextColor",
    "displayAds",
  ],
  computed: {
    hasLogo() {
      return this.logo !== null;
    },
    hasCompanyName() {
      return this.company !== null;
    },
    hasDocument() {
      return this.institutionDocuments.length > 0;
    },
    sectionStyles() {
      let sectionStyles = {};

      sectionStyles.backgroundColor = this.backgroundColor;

      if (this.isPreview) {
        // sectionStyles.border = '1px solid #212529'

        sectionStyles.overflowY = "scroll";
        sectionStyles.width = "100%";
        sectionStyles.height = "100%";
      }

      return sectionStyles;
    },
    sortedInstitutionDocuments() {
      return this.institutionDocuments.slice().sort((a, b) => {
        return a.position - b.position;
      });
    },
    fullYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
  methods: {
    getButtonName(institutionDocument) {
      if (institutionDocument.name !== null) {
        return institutionDocument.name.length > 0
          ? institutionDocument.name
          : institutionDocument.document.name;
      }

      return institutionDocument.document.name;
    },
    getTitle() {
      let title = this.title;

      if (title.indexOf("{restaurant}") !== -1 && this.hasCompanyName) {
        const regexp = new RegExp(/{restaurant}/, "gmi");
        title = title.replace(regexp, this.company);
      }

      return title;
    },
  },
  mounted() {
    // Load Ads when the component is mounted
    if (this.displayAds && !adsbygoogle) {
      (adsbygoogle = window.adsbygoogle || []).push({});
    }
  },
};
</script>

<style lang="scss" scoped>
.inner-container {
  max-width: 700px;
  padding: 0;

  .logo {
    width: 50%;
    max-width: 200px;
  }
}

.preview {
  height: 100%;
  width: 100%;

  .qr-code {
    scrollbar-width: thin;
    scrollbar-color: $mizogoo-gray-medium transparent;
  }
  .qr-code::-webkit-scrollbar {
    width: 8px; /* width of the entire scrollbar */
  }
  .qr-code::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }
  .qr-code::-webkit-scrollbar-thumb {
    background-color: $mizogoo-gray-medium; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid transparent; /* creates padding around scroll thumb */
  }
}

div.footer {
  padding: 0.5rem !important;
  background-color: #ffffff;
  text-align: center;
  color: #6c757d;

  p {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 1rem !important;
  }
}

.corner {
  position: absolute;
  width: 50px;
  height: 50px;
  z-index: 1;
}

.corner-top-left {
  top: -14px;
  left: -14px;
  border-top: 14px solid $mizogoo-gray-dark;
  border-left: 14px solid $mizogoo-gray-dark;
  border-top-left-radius: 40px;
}

.corner-top-right {
  top: -14px;
  right: -14px;
  border-top: 14px solid $mizogoo-gray-dark;
  border-right: 14px solid $mizogoo-gray-dark;
  border-top-right-radius: 40px;
}

.corner-bottom-left {
  bottom: -14px;
  left: -14px;
  border-bottom: 14px solid $mizogoo-gray-dark;
  border-left: 14px solid $mizogoo-gray-dark;
  border-bottom-left-radius: 40px;
}

.corner-bottom-right {
  bottom: -14px;
  right: -14px;
  border-bottom: 14px solid $mizogoo-gray-dark;
  border-right: 14px solid $mizogoo-gray-dark;
  border-bottom-right-radius: 40px;
}

.ad-container {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
