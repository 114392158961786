<template>
  <b-container class="product-description" id="full-description">
    <b-tabs
      v-model="currentTabIndex"
      @activate-tab="tabActivated"
      class="custom-tabs h-100 position-relative"
      content-class="flex-grow-1"
    >
      <b-tab :title="$t('front.product.description')">
        <div class="description-heading">{{ $t(`front.product.products.${productName}.heading`) }}</div>
        <p v-html="$t(`front.product.products.${productName}.description`)" />
        <div v-if="extraBlocks" class="extra-blocks-container">
          <div v-for="extraBlock in currentProductExtraBlocks" :key="extraBlock.id" class="extra-block-container">
            <div v-if="extraBlock.icon != undefined" class="icon-container">
              <b-img :src="extraBlock.icon.src" :alt="extraBlock.icon.alt"></b-img>
            </div>
            <p>{{ extraBlock.description }}</p>
          </div>
        </div>
      </b-tab>
      <!-- <b-tab :title="$t('front.product.features')">
        
      </b-tab>
      <b-tab :title="$t('front.product.templates')">
        
      </b-tab> -->
      <b-tab v-if="productFAQ" :title="$t('front.product.faq')">
        <div
          v-for="question in productFAQ.questions"
          :key="question.id"
          v-b-toggle="'answer-' + question.id"
          v-on:click="setVisibleId(question.id)"
          class="question-container"
        >
          <div class="question-row">
            <h4 class="pr-5">{{ question.question }}</h4>
            <i :class="[visibleId === question.id ? 'fa-minus' : 'fa-plus', 'fas']"></i>
          </div>
          <b-collapse :id="'answer-' + question.id" accordion="my-accordion">
            <p v-html="question.answer"></p>
          </b-collapse>
        </div>
      </b-tab>
      <b-tab :title="$t('front.product.returnPolicy')">
        <div v-html="$t('front.product.returnPolicyDescription')" />
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import { productInfos } from "@mizogoo/helpers/productInfos";

export default {
  name: "ProductDescription",
  props: ["productDescriptionTabIndex", "productName"],
  data() {
    return {
      currentTabIndex: 0,
      visibleId: "", // id of the visible question
    };
  },
  watch: {
    productDescriptionTabIndex(newTabIndex) {
      this.currentTabIndex = newTabIndex;
    },
    currentTabIndex(newTabIndex) {
      this.$emit("productDescriptionTabChanged", newTabIndex);
    },
  },
  computed: {
    extraBlocks() {
      return [
        {
          id: 0,
          heading: this.$t("front.product.productExtraBlocks.personalization.heading"),
          description: this.$t("front.product.productExtraBlocks.personalization.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/personalize.png"),
            alt: "",
          },
        },
        {
          id: 1,
          heading: this.$t("front.product.productExtraBlocks.doItForMe.heading"),
          description: this.$t("front.product.productExtraBlocks.doItForMe.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/do_it_for_me.png"),
            alt: "",
          },
        },
        {
          id: 2,
          heading: this.$t("front.product.productExtraBlocks.customFormat.heading"),
          description: this.$t("front.product.productExtraBlocks.customFormat.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/custom-format.png"),
            alt: "",
          },
        },
        {
          id: 3,
          heading: this.$t("front.product.productExtraBlocks.shipping.heading"),
          description: 
            this.currentProductInfos?.productDescription?.extraBlockParams
              ? this.$t("front.product.productExtraBlocks.shipping.description", {
                nbOfDays: this.currentProductInfos.productDescription.extraBlockParams.deliveryDays,
                extraInfo: this.currentProductInfos.productDescription.extraBlockParams.extraInfo 
                  ? this.$t(`front.product.productExtraBlocks.${this.currentProductInfos.productDescription.extraBlockParams.extraInfo}`)
                  : "",
                })
              : null,
          icon: {
            src: require("@mizogoo/assets/img/products/delivery.png"),
            alt: "",
          },
        },
        {
          id: 4,
          heading: this.$t("front.product.productExtraBlocks.shippingHeadedPaper.heading"),
          description: this.$t("front.product.productExtraBlocks.shippingHeadedPaper.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/delivery.png"),
            alt: "",
          },
        },
        {
          id: 5,
          heading: this.$t("front.product.productExtraBlocks.waterproof.heading"),
          description: this.$t("front.product.productExtraBlocks.waterproof.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/waterproof.png"),
            alt: "",
          },
        },
        {
          id: 6,
          heading: this.$t("front.product.productExtraBlocks.multilingual.heading"),
          description: this.$t("front.product.productExtraBlocks.multilingual.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/translation.png"),
            alt: "",
          },
        },
        {
          id: 7,
          heading: this.$t("front.product.productExtraBlocks.sustainable.heading"),
          description: this.$t("front.product.productExtraBlocks.sustainable.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/leaves.png"),
            alt: "",
          },
        },
        {
          id: 8,
          heading: this.$t("front.product.productExtraBlocks.finishing.heading"),
          description: this.$t("front.product.productExtraBlocks.finishing.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/finishing.png"),
            alt: "",
          },
        },
        {
          id: 9,
          heading: this.$t("front.product.productExtraBlocks.winepairing.heading"),
          description: this.$t("front.product.productExtraBlocks.winepairing.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/wine.png"),
            alt: "",
          },
        },
        {
          id: 10,
          heading: this.$t("front.product.productExtraBlocks.translation.heading"),
          description: this.$t("front.product.productExtraBlocks.translation.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/translation.png"),
            alt: "",
          },
        },
        {
          id: 11,
          heading: this.$t("front.product.productExtraBlocks.designMenu.heading"),
          description: this.$t("front.product.productExtraBlocks.designMenu.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/finishing.png"),
            alt: "",
          },
        },
        {
          id: 12,
          heading: this.$t("front.product.productExtraBlocks.easyUpdate.heading"),
          description: this.$t("front.product.productExtraBlocks.easyUpdate.description"),
          icon: {
            src: require("@mizogoo/assets/img/products/easy.png"),
            alt: "",
          },
        },
      ];
    },
    currentProductInfos() {
      return productInfos[this.productName];
    },
    currentProductExtraBlocks() {
      const currentInfos = this.currentProductInfos;
      if (!currentInfos) return [];

      const ids = currentInfos.productDescription?.extraBlockIds;
      
      if (!ids) return [];
      return ids
        .map((id) => this.extraBlocks.find((block) => block.id === id))
        .filter((block) => block !== undefined);
    },
    productFAQ() {
      let questions = [];
      let questionNb = this.$tm(`front.product.products.${this.productName}.faq`).length - 1;
      
      for (let i = 0; i <= questionNb; i++) {
        let question = {
          id: i,
          question: this.$t(`front.product.products.${this.productName}.faq.${i}.question`),
          answer: this.$t(`front.product.products.${this.productName}.faq.${i}.answer`),
        };
        questions.push(question);
      }

      return {
        questions: questions,
      };
    },
  },
  methods: {
    setVisibleId(id) {
      this.visibleId === id ? (this.visibleId = "") : (this.visibleId = id);
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-tabs .nav-tabs {
  margin-bottom: 2rem;
}

.product-description {
  position: relative;
  padding-top: 8rem;
  padding-bottom: 8rem;

  .description-heading {
    margin-bottom: 1rem;
    font-size: 1.4rem;
    font-weight: bold;
  }

  .extra-blocks-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 2.5rem;

    .extra-block-container {
      display: flex;
      align-items: center;
      width: 400px;
      
      .icon-container {
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        background-color: $mizogoo-gray-light;
        border-radius: 12px;

        img {
          width: auto;
          height: 30px;
        }
      }
      
      p {
        flex: 1;
        font-weight: 400!important;
        margin-bottom: 0;
        line-height: 1.2;
      }
    }
  }

  .question-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 2px solid $mizogoo-gray-light;

    &:last-child {
      border-bottom: none;
    }

    .question-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      transition: color 0.2s ease-in-out;

      h4 {
        font-size: 1.1rem!important;
      }
    }

    p {
      margin-top: 0.5rem;
    }
  }

  .not-collapsed {
    .question-row {
      color: $primary;
    }
  }
}
</style>

<style lang="scss">
.product-container {
  .custom-tabs .nav-tabs {
    margin-bottom: 2rem;
  }
}
</style>
