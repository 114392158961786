<template>
  <b-modal
    centered
    hide-header
    hide-footer
    no-stacking
    size="xl"
    id="subscription-confirmation-modal"
  >
    <b-row>
      <b-col class="free-trial-feedback">
        <div class="logo-container">
          <b-img
            :src="require('@mizogoo/assets/img/mizogoo_logo_square_white.png')"
            class="logo"
          />
        </div>
        <template v-if="user.currentSubscription">
          <template v-if="user.currentSubscription.isFree">
            <h2>{{ $t("workspace.subscriptionConfirmationModal.freeTrialHeading") }}</h2>
            <p class="mb-5">
              {{ $t("workspace.subscriptionConfirmationModal.freeTrialDescription") }}
            </p>
          </template>
          <template v-else>
            <h2>
              {{
                $t("workspace.subscriptionConfirmationModal.subscriptionHeading", {
                  subscriptionPlanName: subscriptionPlanName,
                })
              }}
            </h2>
            <div v-if="user.currentSubscription.nextSubscription" class="mb-5">
              <div>
                {{
                  $t(
                    "workspace.subscriptionConfirmationModal.subscriptionDowngradeDescription1"
                  )
                }}
                <strong>{{ user.currentSubscription.subscription.name }}</strong>
                {{
                  $t(
                    "workspace.subscriptionConfirmationModal.subscriptionDowngradeDescription2"
                  )
                }}
                <strong>{{
                  $d(
                    new Date(
                      user.currentSubscription.stripeSubscription.current_period_end *
                        1000
                    ),
                    "digit"
                  )
                }}</strong>
                {{
                  $t(
                    "workspace.subscriptionConfirmationModal.subscriptionDowngradeDescription3"
                  )
                }}
                <strong>{{ user.currentSubscription.nextSubscription.name }}</strong
                >.
              </div>
            </div>
            <p v-else class="mb-5">
              {{ $t("workspace.subscriptionConfirmationModal.subscriptionDescription") }}
            </p>
          </template>
        </template>
        <button
          class="btn close-button"
          @click="$bvModal.hide('subscription-confirmation-modal')"
        >
          {{ $t("workspace.subscriptionConfirmationModal.close") }}
        </button>
        <div class="premium-badge-container">
          <b-img
            :src="require('@mizogoo/assets/img/crown.png')"
            alt=""
            class="premium-icon"
          />
          <div class="ml-2">
            <div class="premium-label">Premium</div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
export default {
  name: "SubscriptionConfirmationModal",
  computed: {
    subscriptionPlanName() {
      if (this.user.currentSubscription && !this.user.currentSubscription.isFree) {
        if (this.user.currentSubscription.nextSubscription) {
          return this.user.currentSubscription.nextSubscription.name;
        } else {
          return this.user.currentSubscription.subscription.name;
        }
      }
    },
  },
};
</script>

<style lang="scss">
#subscription-confirmation-modal {
  .modal-dialog {
    max-width: 1000px;
    max-height: 660px;
  }

  .modal-content {
    border-radius: 22px;
    border: none;
    position: relative;
    background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
    padding: 5px;
    border-radius: 25px;
  }

  .modal-header {
    border: 0;
    padding-right: 2rem;
    padding-bottom: 1.2rem;
  }

  .modal-body {
    padding: 0;
    background-color: #fff;
    border-radius: 22px;
  }

  .free-trial-feedback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    text-align: center;

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      margin-bottom: 2rem;
      background-color: $mizogoo;
      border-radius: 15px;

      .logo {
        width: 50px;
        height: 50px;
      }
    }

    h2 {
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    .close-button {
      margin-bottom: 3rem;
      padding: 0.5rem 2rem;
      color: #fff;
      background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
      border-radius: 150px;
    }

    .premium-badge-container {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      text-align: left;

      .premium-icon {
        width: 30px;
        height: 30px;
        padding: 5px;
        background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
        border-radius: 150px;
      }

      .premium-label {
        font-size: 1.4rem;
        font-weight: bold;
        color: $mizogoo;
        line-height: initial;
      }

      .premium-requirements {
        font-size: 0.9rem;
        font-weight: bold;
        color: $mizogoo;
      }
    }
  }
}
</style>
