<template>
  <div :class="[{ 'full-height': fullHeight }, 'main-container']">
    <router-link
      :to="{ name: 'product', params: { id: product.id, urlName: product.urlName } }"
      class="inner-container h-100"
    >
      <div class="image-part">
        <div class="image-overlay"></div>
        <b-img :src="product.file.thumbnails['576_576']" :alt="product.name"></b-img>
      </div>
      <div class="text-part">
        <h4>{{ product.name }}</h4>
        <template v-if="product.minProductPrice">
          <div class="product-price">
            {{ product.minProductPrice.step }}
            {{ $t('front.catalog.startingPrice') }}
            {{ formatPrice(product.minProductPrice.priceHt) }} (HT)
          </div>
        </template>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProductOverviewItem",
  props: ["product", "fullHeight"]
}
</script>

<style lang="scss" scoped>
.main-container {
  height: calc(100% - 20px);
  margin-bottom: 20px;
  border: 1px solid #D0CFCF;
  border-radius: 5px; 

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 5px;
    border: 2px solid transparent;
    transition: border 0.2s ease-in-out;
    text-decoration: none!important;

    &:hover {
      border-color: $mizogoo;
    }
  }

  .image-part {
    position: relative;
    width: 100%;
    height: 230px;
    margin-bottom: 0.8rem;

    .image-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: background-color .2s;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .text-part {
    width: 100%;
    color: $mizogoo-gray-dark;

    h4 {
      font-size: 1.1rem!important;
      margin-bottom: 0.2rem!important;
    }

    .product-price {
      font-size: 0.9rem;
    }
  }

  &.full-height {
    .image-part {
      height: 500px;
    }
  }
}
</style>
