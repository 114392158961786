<template>
  <b-container class="subscription-details custom-container">
    <!-- Heading -->
    <h2 class="heading">{{ subscription.heading }}</h2>

    <b-table-simple>
      <!-- Table header - subscription plans name and price -->
      <b-thead>
        <b-tr>
          <b-th></b-th>
          <template v-for="col in subscription.columns" :key="col.id">
            <b-td>
              <div class="subscription-column-name">{{ col.name }}</div>
              <div class="subscription-column-price-container">
                <span class="subscription-column-price">{{ col.price }}€</span>
                <span class="subscription-column-subprice">
                  /{{
                    billingFrequency === "MONTHLY"
                      ? $t("front.subscription.global.month")
                      : $t("front.subscription.global.year")
                  }}
                </span>
              </div>
              <div class="subscription-column-extra-info">{{ col.extraInfo }}</div>
            </b-td>
            <b-td class="spacer"></b-td>
          </template>
        </b-tr>
      </b-thead>

      <!-- Table body - feature list, with hidden description that can be show (collapsed) -->
      <b-tbody>
        <b-tr v-for="feature in subscription.features" :key="feature.id">
          <b-th class="feature-name">
            <div
              v-b-toggle="'collapse-' + feature.id"
              @click="toggleVisibleIds(feature.id)"
              class="feature-name-header"
            >
              <div class="mr-2">{{ feature.name }}</div>
              <div>
                <template v-if="isFeatureVisible(feature.id)">
                  <i class="fa fa-angle-up"></i>
                </template>
                <template v-else>
                  <i class="fa fa-angle-down"></i>
                </template>
              </div>
            </div>
            <b-collapse :id="'collapse-' + feature.id" class="feature-description">
              {{ feature.description }}
            </b-collapse>
          </b-th>
          <template v-for="col in subscription.columns" :key="col.id">
            <b-td>
              <span
                v-if="isFeatureInSubscription(col.id, feature.id)"
                class="active-feature fas fa-check"
              ></span>
              <span v-else class="inactive-feature fas fa-times"></span>
            </b-td>
            <b-td class="spacer"></b-td>
          </template>
        </b-tr>
      </b-tbody>

      <!-- Table footer - subscription buttons -->
      <b-tfoot>
        <b-tr>
          <b-th></b-th>
          <template v-for="col in subscription.columns" :key="col.id">
            <b-td>
              <button
                v-if="!isCurrentPlan(col.id)"
                class="btn btn-outline-primary"
                :class="subscriptionButtonId(col.id)"
                @click="displayCheckoutModal(col.id)"
              >
                {{ col.button.label }}
              </button>
            </b-td>
            <b-td class="spacer"></b-td>
          </template>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-container>
</template>

<script>
import { mapMutations } from "vuex";
import { BTableSimple, BThead, BTbody, BTfoot, BTr, BTh, BTd } from "bootstrap-vue";

export default {
  name: "SubscriptionDetails",
  props: ["billingFrequency", "currentPlan"],
  components: {
    BTableSimple,
    BThead,
    BTbody,
    BTfoot,
    BTr,
    BTh,
    BTd,
  },
  data() {
    return {
      visibleIds: [], // id of the visible features
    };
  },
  computed: {
    userHadFreePremiumTrial() {
      let hadFreePremiumTrial = false;
      if (this.user) {
        if (this.user.hadFreePremiumTrial) {
          hadFreePremiumTrial = true;
        } else if (this.user.currentSubscription !== null) {
          if (
            this.user.currentSubscription.isValid &&
            this.user.currentSubscription.isFree
          ) {
            hadFreePremiumTrial = true;
          } else {
            if (
              this.user.currentSubscription.stripeSubscription &&
              this.user.currentSubscription.stripeSubscription.status === "trialing"
            ) {
              hadFreePremiumTrial = true;
            }
          }
        }
      }
      return hadFreePremiumTrial;
    },
    subscription() {
      return {
        heading: this.$tm("front.subscription.page.details.heading"),
        features: [
          {
            id: 0,
            name: this.$t("front.subscription.page.details.features[0].name"),
            description: this.$t("front.subscription.page.details.features[0].description"),
          },
          {
            id: 1,
            name: this.$t("front.subscription.page.details.features[1].name"),
            description: this.$t("front.subscription.page.details.features[1].description"),
          },
          {
            id: 2,
            name: this.$t("front.subscription.page.details.features[2].name"),
            description: this.$t("front.subscription.page.details.features[2].description"),
          },
          {
            id: 3,
            name: this.$t("front.subscription.page.details.features[3].name"),
            description: this.$t("front.subscription.page.details.features[3].description"),
          },
          {
            id: 4,
            name: this.$t("front.subscription.page.details.features[4].name"),
            description: this.$t("front.subscription.page.details.features[4].description"),
          },
          {
            id: 5,
            name: this.$t("front.subscription.page.details.features[5].name"),
            description: this.$t("front.subscription.page.details.features[5].description"),
          },
          {
            id: 6,
            name: this.$t("front.subscription.page.details.features[6].name"),
            description: this.$t("front.subscription.page.details.features[6].description"),
          },
          {
            id: 7,
            name: this.$t("front.subscription.page.details.features[7].name"),
            description: this.$t("front.subscription.page.details.features[7].description"),
          },
          {
            id: 8,
            name: this.$t("front.subscription.page.details.features[8].name"),
            description: this.$t("front.subscription.page.details.features[8].description"),
          },
          {
            id: 9,
            name: this.$t("front.subscription.page.details.features[9].name"),
            description: this.$t("front.subscription.page.details.features[9].description"),
          },
          {
            id: 10,
            name: this.$t("front.subscription.page.details.features[10].name"),
            description: this.$t("front.subscription.page.details.features[10].description"),
          },
          {
            id: 11,
            name: this.$t("front.subscription.page.details.features[11].name"),
            description: this.$t("front.subscription.page.details.features[11].description"),
          },
          {
            id: 12,
            name: this.$t("front.subscription.page.details.features[12].name"),
            description: this.$t("front.subscription.page.details.features[12].description"),
          },
          {
            id: 13,
            name: this.$t("front.subscription.page.details.features[13].name"),
            description: this.$t("front.subscription.page.details.features[13].description"),
          },
          {
            id: 14,
            name: this.$t("front.subscription.page.details.features[14].name"),
            description: this.$t("front.subscription.page.details.features[14].description"),
          },
          {
            id: 15,
            name: this.$t("front.subscription.page.details.features[15].name"),
            description: this.$t("front.subscription.page.details.features[15].description"),
          },
          {
            id: 16,
            name: this.$t("front.subscription.page.details.features[16].name"),
            description: this.$t("front.subscription.page.details.features[16].description"),
          },
          {
            id: 17,
            name: this.$t("front.subscription.page.details.features[17].name"),
            description: this.$t("front.subscription.page.details.features[17].description"),
          },
          {
            id: 18,
            name: this.$t("front.subscription.page.details.features[18].name"),
            description: this.$t("front.subscription.page.details.features[18].description"),
          },
          {
            id: 19,
            name: this.$t("front.subscription.page.details.features[19].name"),
            description: this.$t("front.subscription.page.details.features[19].description"),
          },
          {
            id: 20,
            name: this.$t("front.subscription.page.details.features[20].name"),
            description: this.$t("front.subscription.page.details.features[20].description"),
          },
          {
            id: 21,
            name: this.$t("front.subscription.page.details.features[21].name"),
            description: this.$t("front.subscription.page.details.features[21].description"),
          },
          {
            id: 22,
            name: this.$t("front.subscription.page.details.features[22].name"),
            description: this.$t("front.subscription.page.details.features[22].description"),
          },
          {
            id: 23,
            name: this.$t("front.subscription.page.details.features[23].name"),
            description: this.$t("front.subscription.page.details.features[23].description"),
          },
        ],
        columns: [
          {
            id: "BASIC",
            name: this.$t("front.subscription.global.columns[1].name"),
            price: this.billingFrequency === "MONTHLY" ? "4,90" : "49,90",
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[1].extraInfo")
                : this.$t("front.subscription.global.columns[1].extraInfoYearly"),
            featureIds: [0, 1, 2, 3, 4],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[1].buttonLabel")
                : this.$t("front.subscription.global.columns[1].buttonLabelFreeTrial"),
            },
          },
          {
            id: "STANDARD",
            name: this.$t("front.subscription.global.columns[2].name"),
            price: this.billingFrequency === "MONTHLY" ? "9,90" : "98,90",
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[2].extraInfo")
                : this.$t("front.subscription.global.columns[2].extraInfoYearly"),
            featureIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[2].buttonLabel")
                : this.$t("front.subscription.global.columns[2].buttonLabelFreeTrial"),
            },
          },
          {
            id: "INTERMEDIATE",
            name: this.$t("front.subscription.global.columns[3].name"),
            price: this.billingFrequency === "MONTHLY" ? "14,90" : "148,90",
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[3].extraInfo")
                : this.$t("front.subscription.global.columns[3].extraInfoYearly"),
            featureIds: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
            ],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[3].buttonLabel")
                : this.$t("front.subscription.global.columns[3].buttonLabelFreeTrial"),
            },
          },
          {
            id: "ADVANCED",
            name: this.$t("front.subscription.global.columns[4].name"),
            price: this.billingFrequency === "MONTHLY" ? "24,90" : "248,90",
            extraInfo:
              this.billingFrequency === "MONTHLY"
                ? this.$t("front.subscription.global.columns[4].extraInfo")
                : this.$t("front.subscription.global.columns[4].extraInfoYearly"),
            featureIds: [
              0,
              1,
              2,
              3,
              4,
              5,
              6,
              7,
              8,
              9,
              10,
              11,
              12,
              13,
              14,
              15,
              16,
              17,
              18,
              19,
              20,
              21,
              22,
              23,
            ],
            button: {
              label: this.userHadFreePremiumTrial
                ? this.$t("front.subscription.global.columns[4].buttonLabel")
                : this.$t("front.subscription.global.columns[4].buttonLabelFreeTrial"),
            },
          },
        ],
      };
    },
  },
  methods: {
    ...mapMutations("subscriptionModal", {
      setSelectedSubscriptionConstName: "SET_SELECTED_SUBSCRIPTION_CONST_NAME",
      setSelectedSubscriptionFeatures: "SET_SELECTED_SUBSCRIPTION_FEATURES",
    }),
    isCurrentPlan(planId) {
      let planConstName = planId;
      if (this.billingFrequency === "YEARLY") {
        planConstName = planConstName + "_" + "ANNUAL";
      }
      return this.currentPlan === planConstName;
    },
    isFeatureInSubscription(columnId, featureId) {
      return this.subscription.columns
        .find((subscription) => subscription.id === columnId)
        .featureIds.includes(featureId);
    },
    toggleVisibleIds(id) {
      let featureIndex = this.visibleIds.findIndex((featureId) => featureId === id);
      if (featureIndex !== -1) {
        this.visibleIds.splice(featureIndex, 1);
      } else {
        this.visibleIds.push(id);
      }
    },
    isFeatureVisible(id) {
      return this.visibleIds.includes(id);
    },
    subscriptionButtonId(planId) {
      return (
        "subscribe-" + planId.toLowerCase() + "-" + this.billingFrequency.toLowerCase()
      );
    },
    displayCheckoutModal(planId) {
      if (this.user) {
        if (this.userHadFreePremiumTrial) {
          let constName = planId;
          if (this.billingFrequency === "YEARLY") {
            constName = constName + "_" + "ANNUAL";
          }
          this.setSelectedSubscriptionConstName(constName);
          this.$bvModal.show("checkout-modal");
        } else {
          this.$bvModal.show("premium-only-modal");
        }
      } else {
        window.localStorage.setItem("displaySubscriptionModal", true);
        this.$router.push({ name: "login" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.subscription-details {
  padding-top: 7rem;
  padding-bottom: 7rem;

  .heading {
    text-align: center;
    margin-bottom: 5rem !important;
  }

  table {
    padding: 2rem 3rem;
    margin-bottom: 0;
  }

  thead {
    background-color: $background-color;

    .subscription-column-name,
    .subscription-column-price-container {
      width: 100%;
    }

    .subscription-column-name {
      font-weight: 600;
      color: #c2c2c2;
      text-transform: uppercase;
    }

    .subscription-column-price {
      font-size: 2.6rem;
      font-weight: bold;
      line-height: 1.2;
      color: #151f29;
    }

    .subscription-column-subprice {
      font-weight: bold;
      color: #151f29;
    }

    .subscription-column-extra-info {
      margin-top: 0.5rem;
      color: #c2c2c2;
      font-style: italic;
    }

    td {
      position: relative;
      z-index: 1;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border: none;
      padding: 2rem 0;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }

  tbody {
    tr:first-child th {
      padding-top: 3rem;
      border-top-left-radius: 15px;
    }

    tr:last-child th {
      padding-bottom: 3rem;
      border-bottom-left-radius: 15px;
    }

    tr:last-child td {
      position: relative;
      z-index: 2;
      vertical-align: top;

      &:last-child {
        border-bottom-right-radius: 15px;
      }
    }

    tr:first-child td {
      position: relative;
      z-index: 2;
      border: none;

      &:not(.spacer) {
        border-bottom: 2px solid #dee2e6;
      }

      &:last-child {
        border-top-right-radius: 15px;
      }
    }

    th {
      width: 25rem;
      padding: 1.2rem 3rem;
      background-color: $mizogoo-gray-light;
    }

    .section-name {
      height: 5rem;
    }

    td.spacer {
      background-color: $mizogoo-gray-light;
    }

    td.spacer:last-child {
      width: 2rem;
    }

    .active-feature {
      color: #ff395e;
    }

    .inactive-feature {
      color: #c2c2c2;
    }
  }

  tfoot {
    background-color: $background-color;

    td {
      position: relative;
      z-index: 1;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
      border: none;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
    }

    .btn {
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: $mizogoo;
    }

    .btn:hover {
      background-color: $mizogoo;
      color: $white;
    }
  }

  tr {
    height: 3rem;
  }

  th {
    border: none;
    vertical-align: middle;
  }

  td {
    min-width: 8rem;
    max-width: 8rem;
    text-align: center;
    vertical-align: middle;
    background-color: $white;

    &.spacer {
      width: 1rem;
      min-width: 0;
      padding: 0;
      background-color: $background-color;
      border: none;
      box-shadow: none;
      z-index: 0;
    }
  }

  tr td.spacer:last-child {
    width: 2rem;
  }

  .section-name {
    text-transform: uppercase;
  }

  .feature-name {
    .feature-name-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 0.5rem;
      font-weight: 500;
    }
  }

  .feature-description {
    font-weight: 300;
  }
}
</style>
