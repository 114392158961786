<template>
  <b-container class="faq-container">
    <b-row>
      <b-col cols="12" lg="4">
        <div class="heading-container">
          <h2>{{ faq.heading }}</h2>
          <p>{{ faq.description }}</p>
        </div>
      </b-col>
      <b-col cols="12" lg="8">
        <div
          v-for="question in faq.questions"
          :key="question.id"
          v-b-toggle="'answer-' + question.id"
          v-on:click="setVisibleId(question.id)"
          class="question-container"
        >
          <div class="question-row">
            <h4 class="pr-5">{{ question.question }}</h4>
            <i :class="[visibleId === question.id ? 'fa-minus' : 'fa-plus', 'fas']"></i>
          </div>
          <b-collapse :id="'answer-' + question.id" accordion="my-accordion">
            <p v-html="question.answer"></p>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      visibleId: "", // id of the visible question
    };
  },
  computed: {
    faq() {
      let questions = [];
      let questionNb = this.$tm("front.subscription.page.faq.questions").length - 1;
      for (let i = 0; i <= questionNb; i++) {
        let question = {
          id: i,
          question: this.$t("front.subscription.page.faq.questions[" + i + "].question"),
          answer: this.$t("front.subscription.page.faq.questions[" + i + "].answer"),
        };
        questions.push(question);
      }

      return {
        heading: this.$tm("front.subscription.page.faq.heading"),
        description: this.$t("front.subscription.page.faq.description"),
        questions: questions,
      };
    },
  },
  methods: {
    setVisibleId(id) {
      this.visibleId === id ? (this.visibleId = "") : (this.visibleId = id);
    },
  },
};
</script>

<style lang="scss" scoped>
.faq-container {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .heading-container {
    background-color: $mizogoo-gray-light;
    padding: 3rem;
    margin-right: 1rem;
    border-radius: 15px;
  }

  .question-container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 2px solid $mizogoo-gray-light;

    &:last-child {
      border-bottom: none;
    }

    .question-row {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      transition: color 0.2s ease-in-out;
    }

    p {
      margin-top: 0.5rem;
    }
  }

  .not-collapsed {
    .question-row {
      color: $primary;
    }
  }
}

@media screen and (max-width: 992px) {
  .faq-container {
    .heading-container {
      background-color: transparent;
      text-align: center;
      padding: 0;
      padding-bottom: 2rem;
      margin: 0;
    }
  }
}
</style>
