import store from '@mizogoo/store/index'

export default {
  methods: {
    cloneObject (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    searchRegex (search) {
      const searchArray = search.trim().split(' ')
      let regexString = ''

      searchArray.forEach((item, index) => {
        regexString +=
          searchArray.length !== index + 1
            ? this.slugify(item) + '|'
            : this.slugify(item)
      })

      return new RegExp(regexString, 'gmi')
    },
    strictSearchRegex (search) {
      const searchArray = search.trim().split(' ')
      let regexString = ''

      searchArray.forEach(item => {
        regexString += '(?=.*' + this.slugify(item) + ')'
      })

      return new RegExp(regexString, 'gmi')
    },
    slugify (string) {
      const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
      const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------'
      const p = new RegExp(a.split('').join('|'), 'g')

      return (
        string
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/&/g, '-and-') // Replace & with 'and'
          // eslint-disable-next-line no-useless-escape
          .replace(/[^\w\-]+/g, '') // Remove all non-word characters
          // eslint-disable-next-line no-useless-escape
          .replace(/\-\-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '')
      ) // Trim - from end of text
    },
    formatPrice (number) {
      return new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
      }).format(number / 100)
    },
    formatAddress (address) {
      if (!address) return ''

      let html = ''

      if (address.firstname || address.lastname) {
        html += html ? '<br>' : ''
        html += address.civility ? address.civility + ' ' : ''
        html += address.firstname ? address.firstname + ' ' : ''
        html += address.lastname
      }

      if (address.company) {
        html += html ? '<br>' : ''
        html += address.company
      }

      if (address.street) {
        html += html ? '<br>' : ''
        html += address.street
      }

      if (address.additional) {
        html += html ? '<br>' : ''
        html += address.additional
      }

      if (address.postalCode || address.city) {
        html += html ? '<br>' : ''
        html += address.postalCode ? address.postalCode + ' ' : ''
        html += address.city
      }

      if (address.country) {
        html += html ? '<br>' : ''
        html += address.country.name
      }

      if (address.phone) {
        html += html ? '<br>' : ''
        html += address.phone
      }

      return html
    },
    truncate (string, length, append = '...') {
      return (string.length > length ? string.split('').slice(0, length).join('') + append : string)
    },
    /// SORTABLE ITEMS ///
    /**
     * Return an object that contains positions create via the array of objects given as parameter
     * @param {object} items - array of sortable objects (dish, dishCategory, qrCodeDocuments, etc.). N.B. Need an id
     * @returns {object} positions - an object of positions created with the given items
     */
    initPositions (items) {
      let positions = []
      for (let i = 0; i < items.length; i++) {
        let position = {
          position: items[i].position,
          id: items[i].id,
          name: items[i].name,
        }
        positions.push(position)
      }
      return positions
    },
    /**
     * Update positions object after a drag and drop event
     * @param {*} event - the moved event that include oldIndex and newIndex
     * @param {*} positions - the positions object to update (can be a dish positions object, documents, etc.)
     * @returns positions - the updated positions object
     */
    updatePositions (event, positions) {
      let evt = event.moved
      if (evt.oldIndex < evt.newIndex) {
        for (let i = 0; i < positions.length; i++) {
          if (
            positions[i].position > evt.oldIndex &&
            positions[i].position <= evt.newIndex
          ) {
            positions[i].position = positions[i].position - 1
          } else if (positions[i].position === evt.oldIndex) {
            positions[i].position = evt.newIndex
          }
        }
      } else {
        for (let i = 0; i < positions.length; i++) {
          if (
            positions[i].position < evt.oldIndex &&
            positions[i].position >= evt.newIndex
          ) {
            positions[i].position = positions[i].position + 1
          } else if (positions[i].position === evt.oldIndex) {
            positions[i].position = evt.newIndex
          }
        }
      }
      return positions
    },

    /**
     * Format string with first letter capitalized
     * @param {string} string
     */
    capitalizeFirstLetter (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },

    /**
     * Retrieves the translated texts
     * @param {Array} translations
     * @param {String} localeName
     */
    translate (translations, localeName = 'fr') {
      // 'fr' is the locale default
      if (localeName in translations) {
        return translations[localeName]
      } else {
        return translations['fr']
      }
    },

    /**
     * Check if the user has already seen a specific onboarding page and if not,
     * disable the onboarding presentation page for next times by adding it into the DB
     * @param {string} onboardingName - name of the onboarding to check
     */
    async checkOnboarding (onboardingName) {
      if (this.user.onboardings.findIndex(
        (onboarding) => onboarding.name === onboardingName
      ) === -1) {
        // Add the onboarding to the database
        let createdOnboarding = await this.$store.dispatch('accountOnboarding/add', {
          form: { name: onboardingName },
        })
        // And also add it to the user
        await this.$store.dispatch('security/addOnboardingIntoUser', {
          onboarding: createdOnboarding
        })
      }
    },
    shuffleArray (inputArray) {
      inputArray.sort(() => Math.random() - 0.5)
    },
    luminance (r, g, b) {
      let a = [r, g, b].map(function (v) {
        v /= 255
        return v <= 0.03928 ?
          v / 12.92 :
          Math.pow((v + 0.055) / 1.055, 2.4)
      })
      return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
    },
    contrast (rgb1, rgb2) {
      let lum1 = this.luminance(rgb1[0], rgb1[1], rgb1[2])
      let lum2 = this.luminance(rgb2[0], rgb2[1], rgb2[2])
      let brightest = Math.max(lum1, lum2)
      let darkest = Math.min(lum1, lum2)
      return (brightest + 0.05) /
        (darkest + 0.05)
    },
    lightenColor (color, percentage) {
      const rgbaValues = color.match(/[\d.]+/g)
      const [r, g, b, a] = rgbaValues.map(parseFloat)

      let adjustedR = Math.min(255, r + (255 - r) * (percentage / 100))
      let adjustedG = Math.min(255, g + (255 - g) * (percentage / 100))
      let adjustedB = Math.min(255, b + (255 - b) * (percentage / 100))

      return `rgba(${adjustedR}, ${adjustedG}, ${adjustedB}, ${a})`
    },
    darkenColor (color, percentage) {
      const rgbaValues = color.match(/[\d.]+/g)
      const [r, g, b, a] = rgbaValues.map(parseFloat)

      let adjustedR = Math.max(0, r * (1 - percentage / 100))
      let adjustedG = Math.max(0, g * (1 - percentage / 100))
      let adjustedB = Math.max(0, b * (1 - percentage / 100))

      return `rgba(${adjustedR}, ${adjustedG}, ${adjustedB}, ${a})`
    },
    /**
     * Create a new creation based on savedProductSettings object
     * @returns dataCreation
     */
    async createCreation () {
      const savedProductSettings = JSON.parse(localStorage.getItem('savedProductSettings'))

      if (savedProductSettings) {
        let productFeatureValueIds = []
        for (const currentFeatureValue of savedProductSettings.productFeatureValues) {
          productFeatureValueIds.push(currentFeatureValue.id)
        }

        let productAttributeValueIds = []
        for (const currentAttributeValue of savedProductSettings.productAttributeValues) {
          productAttributeValueIds.push(currentAttributeValue.id)
        }

        let dataCreation = await store.dispatch('creation/create', {
          data: {
            productId: savedProductSettings.productId,
            productFeatureValueIds: productFeatureValueIds,
            attributValueIds: productAttributeValueIds,
            quantity: savedProductSettings.productQuantity,
            matrixId: savedProductSettings.matrixId,
            modelId: savedProductSettings.modelId,
            name: null,
          },
        })

        if (!store.getters['creation/hasErrorCreating'] && dataCreation) {
          return dataCreation
        }
      }
    }
  },
}
