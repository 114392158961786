<template>
  <div
    class="product-container"
    :style="{ paddingTop: frontHeader.height + 'px', minHeight: '100vh' }">

    <div v-if="isLoadingProduct" class="loading">
      <div class="text-center">
        <b-spinner label="Spinning"></b-spinner>
      </div>
    </div>

    <template v-else-if="product">
      <div>
        <b-container fluid>
          <template v-if="product.isCustomizable">
            <Customizable
              :product="productExtended"
              :matchProductName="matchProductName"
              :key="product.id"
              @showDescriptionTab="switchToDescriptionTab"
            />
          </template>
          <template v-else-if="product.isClassic">
            <Classic
              :product="productExtended"
              :matchProductName="matchProductName"
              :key="product.id"
              @showDescriptionTab="switchToDescriptionTab"
            />
          </template>
        </b-container>

        <div id="product-description">
          <ProductDescription
            :productName="matchProductName"
            :productDescriptionTabIndex="productDescriptionTabIndex"
            @productDescriptionTabChanged="updateActiveProductDescriptionTab"
          />
        </div>

        <!-- Associated products -->
        <template v-if="associatedProducts && associatedProducts.length > 0">
          <AssociatedProducts
            :associatedProducts="associatedProducts"
            :isLoadingAssociatedProducts="isLoadingAssociatedProducts"
            :key="product.id"
          />
        </template>

        <!-- Product review -->
        <template v-if="productReviews && productReviews.length > 0">
          <ProductRating
            :productReviews="productReviews"
            :productReviewSummary="productReviewSummary"
            :isLoadingProductReview="isLoadingProductReview"
            :key="product.id"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import FlashBag from '@mizogoo/components/FlashBag'
import Classic from '@mizogoo/components/front/pages/ProductPage/Classic'
import Customizable from '@mizogoo/components/front/pages/ProductPage/Customizable'
import ProductDescription from "@mizogoo/components/front/pages/ProductPage/ProductDescription";
import AssociatedProducts from "@mizogoo/components/front/pages/ProductPage/AssociatedProducts";
import ProductRating from "@mizogoo/components/front/pages/ProductPage/ProductRating.vue";

export default {
  name: "ProductPage",
  components: {
    FlashBag,
    Classic,
    Customizable,
    ProductDescription,
    AssociatedProducts,
    ProductRating,
  },
  data() {
    return {
      product: null,
      productDescriptionTabIndex: null,
      associatedProducts: [],
    }
  },
  computed: {
    ...mapState({
      isLoadingProduct: (state) => state.product.isLoadingProduct,
      isLoadingAssociatedProducts: (state) => state.product.isLoadingAssociatedProducts,
      isLoadingProductReview: (state) => state.productReview.isLoading,
      productReviews: (state) => state.productReview.productReviews,
      productReviewSummary: (state) => state.productReviewSummary.productReviewSummary,
    }),
    ...mapGetters("product", {
      products: "products",
    }),
    matchProductName() {
      return this.product.constName
        .split('__')[0]
        .toLowerCase()
        .replace(/_([a-z])/g, (g) => g[1].toUpperCase());
    },
    productExtended() {
      return {
        ...this.product,
        longDescription: this.$t(`front.product.products.${this.matchProductName}.description`),
        productReviewSummary: this.productReviewSummary,
      }
    }
  },
  methods: {
    async loadProduct(productId) {
      // Check if products are already loaded
      if (this.products !== undefined && this.products.length > 0) {
        this.product = this.products.find(product => product.id === productId);
        this.associatedProducts = [];
        this.associatedProducts = this.products.filter(product => this.product.associatedProductIds.includes(product.id));
      } else {
        this.product = await this.$store.dispatch("product/findOne", { product: parseInt(productId) });
        this.associatedProducts = [];
        this.associatedProducts = await this.$store.dispatch(
          "product/findAssociatedProducts",
          { product: parseInt(productId) }
        );
      }

      this.$store.dispatch("productReview/find", {
        product: parseInt(productId),
      });
      this.$store.dispatch("productReviewSummary/find", {
        product: parseInt(productId),
      });
    },
     // Update product description tab index (triggered when it changes inside the child component)
    updateActiveProductDescriptionTab(index) {
      this.productDescriptionTabIndex = index;
    },
    // Change the active tab inside the ProductDescription component
    switchToDescriptionTab() {
      this.productDescriptionTabIndex = 0;
    },
  },
  async created() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
    await this.loadProduct(parseInt(this.$route.params.id));
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
  },
  async beforeRouteUpdate(to, from, next) {
    if (from.params.id != to.params.id) {
      window.scrollTo(0, 0);
      await this.loadProduct(parseInt(to.params.id));
      this.switchToDescriptionTab();
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
.product-container {
  min-height: 50vh;
  width: 100%;

  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
  }
}

:deep(ul),
:deep(ol) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: revert;
  list-style: revert;
}
</style>
