<template>
  <div
    id="header"
    :class="{ transparent: transparentHeader, 'd-none': !headerIsVisible }"
  >
    <!-- Scroll progress indicator -->
    <div class="progress-indicator-wrapper">
      <div class="progress-indicator" :style="{ width: scrollProgress }"></div>
    </div>

    <!-- Second subnavbar (news, socials) -->
    <b-navbar v-if="frontHeader.transparent" class="extra-navbar">
      <b-navbar-nav v-if="hasHighlightedMessage" class="extra-navbar-inner">
        <Transition name="fade">
          <div v-if="isActiveHighlightedMessage" class="d-flex align-items-center">
            <span class="extra-navbar-tag d-none d-sm-block">{{
              currentHighlightedMessage.tag
            }}</span>
            <template v-if="currentHighlightedMessage.link">
              <template v-if="currentHighlightedMessage.link.includes('https')">
                <a
                  :href="currentHighlightedMessage.link"
                  target="_blank"
                  class="d-flex align-items-center font-weight-light"
                >
                  <span>{{ currentHighlightedMessage.message }}</span>
                  <span class="extra-navbar-learn-more">
                    <span class="d-none d-sm-block">En savoir plus</span>
                    <i class="fas fa-angle-right"></i>
                  </span>
                </a>
              </template>
              <template v-else>
                <router-link
                  :to="{ name: currentHighlightedMessage.link }"
                  class="d-flex align-items-center font-weight-light"
                >
                  <span>{{ currentHighlightedMessage.message }}</span>
                  <span class="extra-navbar-learn-more">
                    <span class="d-none d-sm-block">En savoir plus</span>
                    <i class="fas fa-angle-right"></i>
                  </span>
                </router-link>
              </template>
            </template>
            <template v-else>
              <span class="font-weight-light">{{
                currentHighlightedMessage.message
              }}</span>
            </template>
          </div>
        </Transition>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto d-none d-md-block">
        <div class="social">
          <a href="https://www.facebook.com/mizogoo" target="_blank"
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a href="https://www.instagram.com/mizogoofr" target="_blank"
            ><i class="fab fa-instagram"></i
          ></a>
          <router-link target="_blank" :to="{ name: 'blog' }">
            <i class="fas fa-blog"></i>
          </router-link>
          <a
            href="https://www.youtube.com/channel/UC1fj69bI51r9giRY8DjnK3Q"
            target="_blank"
            ><i class="fab fa-youtube"></i
          ></a>
        </div>
      </b-navbar-nav>
    </b-navbar>

    <!-- Main navbar -->
    <b-navbar
      :class="{ 'fixed-top': isStickyNavBar, sticky: isStickyNavBar }"
      ref="navbar"
      id="navbar"
    >
      <b-container fluid>
        <!-- Left part, Mizogoo logo -->
        <b-navbar-brand class="logo-container">
          <div
            class="mizogoo-logo mizogoo-header-logo dark d-lg-block"
            id="mizogoo-header-logo"
          >
            <router-link :to="{ name: 'home' }">
              <b-img
                :src="transparentHeader ? mizogooLogoSquareWhite : mizogooLogoSquare"
                alt="mizogoo"
                width="30"
                fluid
              />
              <span
                :class="[transparentHeader ? 'logo-text-part' : 'logo-text-part-sticky']"
              >
                mizogoo
              </span>
            </router-link>
          </div>
        </b-navbar-brand>

        <!-- Custom responsive header (only displayed under lg size) -->
        <div v-on:click="toggleBurgerHeader" class="custom-responsive-icon">
          <i class="fas fa-bars"></i>
        </div>

        <!-- Centered part, classic entries (about main pages) -->
        <b-collapse id="nav-collapse" is-nav class="justify-content-center">
          <!-- Desktop navbar -->
          <b-navbar-nav class="d-none d-lg-flex">
            <router-link :to="{ name: 'home' }" custom v-slot="{ navigate, isActive }">
              <li
                class="nav-item"
                :class="{ 'router-link-active': isActive }"
                @click="navigate"
                @keypress.enter="navigate"
              >
                <a class="nav-link">{{ $t("front.navbar.home") }}</a>
              </li>
            </router-link>

            <div @mouseover="onOverProducts" @mouseleave="onLeaveProducts">
              <b-nav-item-dropdown
                ref="productsDropdown"
                class="products-dropdown"
                :class="{ 'router-link-active': subProductsIsActive }"
                no-caret
              >
                <template v-slot:button-content>
                  <div>
                    {{ $t("front.navbar.catalog.products") }}
                    <i
                      class="fas fa-angle-down"
                      :style="[transparentHeader ? { color: '#fff' } : null]"
                    ></i>
                  </div>
                </template>

                <div class="products-dropdown-inner">
                  <template v-if="isLoadingProductCategories">
                    <div class="spinner-container">
                      <b-spinner />
                    </div>
                  </template>
                  <template v-else-if="hasErrorProductCategory">
                    <div class="error-container">
                      <b-alert show variant="danger">{{
                        errorProductCategories
                      }}</b-alert>
                    </div>
                  </template>
                  <template v-else-if="hasProductCategories">
                    <div class="products-dropdown-cols">
                      <div class="products-dropdown-category-col">
                        <div class="products-dropdown-col-header">
                          {{ $t("front.navbar.catalog.categories") }}
                        </div>
                        <div
                          v-for="productCategory in productCategories"
                          :key="productCategory.id"
                        >
                          <div
                            class="product-category-container"
                            :class="[
                              productCategory.id === hoveredProductCategoryId
                                ? 'hover'
                                : null,
                            ]"
                            @mouseover="hoveredProductCategoryId = productCategory.id"
                          >
                            <div>
                              <div class="product-category-name">
                                {{ productCategory.name }}
                              </div>
                              <div class="product-category-number">
                                {{ productCategory.products.length }} produits
                              </div>
                            </div>
                            <i class="fas fa-angle-right"></i>
                          </div>
                        </div>
                      </div>
                      <div class="products-dropdown-product-col">
                        <div class="products-dropdown-col-header">
                          {{ $t("front.navbar.catalog.products") }}
                        </div>
                        <b-dropdown-item
                          v-for="product in productsFromHoveredProductCategory"
                          :key="'product-' + product.id"
                          :to="{
                            name: 'product',
                            params: { id: product.id, urlName: product.urlName },
                          }"
                          class="product-container"
                        >
                          <div class="product-icon">
                            <b-img :src="product.file.thumbnails['350_250']"></b-img>
                          </div>
                          <div class="product-text-container">
                            <div class="product-name">
                              {{ product.name }}
                            </div>
                            <div class="product-description">
                              {{ product.description }}
                            </div>
                          </div>
                        </b-dropdown-item>
                      </div>
                    </div>
                    <div class="product-dropdown-extra">
                      <b-dropdown-item :to="{ name: 'catalog' }">
                        {{ $t("front.navbar.catalog.seeAllProducts") }}
                        <i class="fas fa-arrow-right pl-2"></i>
                      </b-dropdown-item>
                    </div>
                  </template>
                </div>
              </b-nav-item-dropdown>
            </div>

            <div @mouseover="onOverFeatures" @mouseleave="onLeaveFeatures">
              <b-nav-item-dropdown
                ref="featuresDropdown"
                class="features-dropdown"
                :class="{ 'router-link-active': subFeaturesIsActive }"
                no-caret
              >
                <template v-slot:button-content>
                  <div>
                    {{ $t("front.navbar.features.heading") }}
                    <i
                      class="fas fa-angle-down"
                      :style="[transparentHeader ? { color: '#fff' } : null]"
                    ></i>
                  </div>
                </template>

                <b-dropdown-item
                  v-for="featuresItem in featuresDropdownItems"
                  :key="featuresItem.id"
                  :to="{ name: featuresItem.link }"
                >
                  <div class="features-icon">
                    <b-img :src="featuresItem.icon"></b-img>
                  </div>
                  <div class="features-container">
                    <div class="features-heading">
                      {{ featuresItem.heading }}
                    </div>
                    <div class="features-description">
                      {{ featuresItem.description }}
                    </div>
                  </div>
                </b-dropdown-item>
              </b-nav-item-dropdown>
            </div>

            <router-link :to="{ name: 'subscription' }" custom v-slot="{ navigate, isActive }">
              <li
                class="nav-item"
                :class="{ 'router-link-active': isActive }"
                @click="navigate"
                @keypress.enter="navigate"
              >
                <a class="nav-link">{{ $t("front.navbar.subscription") }}</a>
              </li>
            </router-link>

            <div @mouseover="onOverRessources" @mouseleave="onLeaveRessources">
              <b-nav-item-dropdown
                ref="ressourcesDropdown"
                class="ressources-dropdown"
                :class="{ 'router-link-active': subRessourcesIsActive }"
                no-caret
              >
                <template v-slot:button-content>
                  <div>
                    {{ $t("front.navbar.ressources.heading") }}
                    <i
                      class="fas fa-angle-down"
                      :style="[transparentHeader ? { color: '#fff' } : null]"
                    ></i>
                  </div>
                </template>

                <div class="ressources-dropdown-inner">
                  <div class="ressources-dropdown-cols">
                    <div class="ressources-dropdown-blog-col">
                      <div class="ressources-dropdown-blog-col-inner">
                        <div class="ressources-dropdown-col-header">
                          {{ $t("front.navbar.ressources.blog") }}
                        </div>
                        <template v-if="isLoadingArticleOverview">
                          <div class="spinner-container">
                            <b-spinner />
                          </div>
                        </template>
                        <template v-else-if="hasErrorArticle">
                          <div class="error-container">
                            <b-alert show variant="danger">{{ errorArticle }}</b-alert>
                          </div>
                        </template>
                        <template v-else-if="hasArticleOverview">
                          <b-dropdown-item
                            v-for="articleOverview in articlesOverview"
                            :key="articleOverview.id"
                            class="blog-article-container"
                            :to="{
                              name: 'article',
                              params: {
                                id: articleOverview.id,
                                name: slugify(articleOverview.title),
                              },
                            }"
                          >
                            <div class="blog-image">
                              <b-img
                                :src="articleOverview.coverSrc"
                                :alt="articleOverview.coverAlt"
                              />
                            </div>
                            <div class="blog-text">
                              <div class="blog-category">
                                {{ articleOverview.articleCategory }}
                              </div>
                              <div class="blog-title">
                                {{ articleOverview.title }}
                              </div>
                            </div>
                          </b-dropdown-item>
                        </template>
                      </div>
                      <div class="ressources-dropdown-blog-col-extra">
                        <b-dropdown-item :to="{ name: 'blog' }">
                          {{ $t("front.navbar.ressources.accessBlog") }}
                          <i class="fas fa-arrow-right pl-2"></i>
                        </b-dropdown-item>
                      </div>
                    </div>

                    <div class="ressources-dropdown-link-col">
                      <div class="ressources-dropdown-col-header">
                        {{ $t("front.navbar.ressources.company") }}
                      </div>
                      <b-dropdown-item
                        v-for="ressourcesItem in ressourcesDropdownItems"
                        :key="ressourcesItem.id"
                        :to="{ name: ressourcesItem.link }"
                      >
                        <div class="ressources-icon">
                          <b-img :src="ressourcesItem.icon"></b-img>
                        </div>
                        <div class="ressources-container">
                          <div class="ressources-heading">
                            {{ ressourcesItem.heading }}
                          </div>
                        </div>
                      </b-dropdown-item>
                    </div>
                  </div>
                </div>
              </b-nav-item-dropdown>
            </div>
          </b-navbar-nav>

          <!-- Responsive navbar -->
          <Transition name="slide">
            <b-navbar-nav
              v-if="isResponsiveHeaderOpen"
              class="d-lg-none custom-responsive-menu"
            >
              <!-- Closing icon -->
              <div class="custom-responsive-menu-header">
                <div class="">
                  <i v-on:click="toggleBurgerHeader" class="fas fa-times"></i>
                </div>
              </div>
              <!-- Navigation items -->
              <div class="inner-container">
                <div v-on:click="toggleBurgerHeader">
                  <router-link :to="{ name: 'home' }" custom v-slot="{ navigate }">
                    <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                      <a class="nav-link">{{ $t("front.navbar.home") }}</a>
                    </li>
                  </router-link>
                </div>

                <div v-on:click="toggleBurgerHeader">
                  <router-link :to="{ name: 'catalog' }" custom v-slot="{ navigate }">
                    <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                      <a class="nav-link">{{ $t("front.navbar.catalog.products") }}</a>
                    </li>
                  </router-link>
                </div>

                <div>
                  <li class="nav-item m-0 py-2" v-b-toggle.features-inner-collapse>
                    <a class="nav-link">
                      {{ $t("front.navbar.features.heading") }}
                      <i class="fas fa-angle-down ml-2"></i>
                    </a>
                  </li>
                  <b-collapse id="features-inner-collapse">
                    <div v-on:click="toggleBurgerHeader">
                      <router-link
                        v-for="featuresItem in featuresDropdownItems"
                        :key="featuresItem.id"
                        :to="{ name: featuresItem.link }"
                        custom
                        v-slot="{ navigate }"
                      >
                        <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                          <a class="nav-link">{{ featuresItem.heading }}</a>
                        </li>
                      </router-link>
                    </div>
                  </b-collapse>
                </div>

                <div v-on:click="toggleBurgerHeader">
                  <router-link
                    :to="{ name: 'subscription' }"
                    custom
                    v-slot="{ navigate }"
                  >
                    <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                      <a class="nav-link">{{ $t("front.navbar.subscription") }}</a>
                    </li>
                  </router-link>
                </div>

                <div>
                  <li class="nav-item m-0 py-2" v-b-toggle.ressources-inner-collapse>
                    <a class="nav-link">
                      {{ $t("front.navbar.ressources.heading") }}
                      <i class="fas fa-angle-down ml-2"></i>
                    </a>
                  </li>
                  <b-collapse id="ressources-inner-collapse">
                    <div v-on:click="toggleBurgerHeader">
                      <router-link
                        v-for="ressourcesItem in ressourcesDropdownItems"
                        :key="ressourcesItem.id"
                        :to="{ name: ressourcesItem.link }"
                        custom
                        v-slot="{ navigate }"
                      >
                        <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                          <a class="nav-link">{{ ressourcesItem.heading }}</a>
                        </li>
                      </router-link>
                      <router-link :to="{ name: 'blog' }" custom v-slot="{ navigate }">
                        <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                          <a class="nav-link">{{ $t("front.navbar.ressources.blog") }}</a>
                        </li>
                      </router-link>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </b-navbar-nav>
          </Transition>
        </b-collapse>

        <!-- Right part, user's items (login, account, basket, language, etc.) -->
        <b-navbar-nav class="menu-right" align="right">
          <!-- Language selection (always displayed, logged and not) -->
          <!-- <b-nav-item-dropdown
            no-caret
            v-if="langs.length > 1"
            :text="$i18n.locale"
            id="language-selection"
            right
          >
            <template v-slot:button-content>
              <div class="language-dropdown">
                <b-img
                  :src="
                    transparentHeader
                      ? require('@mizogoo/assets/img/header_language_white.png')
                      : require('@mizogoo/assets/img/header_language.png')
                  "
                  alt="language icon"
                  class="language-icon"
                ></b-img>
                <i
                  class="fas fa-angle-down"
                  :style="[transparentHeader ? { color: '#fff' } : null]"
                ></i>
              </div>
            </template>
            <b-dropdown-item
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              @click="changeLang(lang)"
            >
              {{ lang.title }}
            </b-dropdown-item>
          </b-nav-item-dropdown> -->
          <!-- Shopping cart (always displayed, logged and not) -->
          <router-link :to="{ name: 'cart' }" custom v-slot="{ href, navigate }">
            <li class="nav-item m-0">
              <a class="nav-link position-relative" :href="href" @click="navigate">
                <i class="fas fa-shopping-basket"></i>
                <template v-if="!isCartEmpty">
                  <div class="cart-badge-container">
                    <div class="cart-badge">
                      <template v-if="cart.elements.length < 10">
                        {{ cart.elements.length }}
                      </template>
                      <template v-else> 9+ </template>
                    </div>
                  </div>
                </template>
              </a>
            </li>
          </router-link>

          <!-- Ask for a demo (always displayed) -->
          <!-- <a
            href="https://calendly.com/mizogoo/demo-mizogoo?text_color=151f29&primary_color=ff395e"
            target="_blank"
            class="nav-item demo"
          >
            {{ $t("front.navbar.demo") }}
          </a> -->

          <!-- Not logged in -->
          <router-link
            v-if="!user"
            :to="{ name: extraHeader.notlogged[0].link }"
            custom
            v-slot="{ navigate }"
          >
            <li class="nav-item login" @click="navigate" @keypress.enter="navigate">
              <div class="d-none d-md-block">
                {{ extraHeader.notlogged[0].name }}
              </div>
              <div class="d-md-none">
                <i class="fas fa-user"></i>
              </div>
            </li>
          </router-link>

          <!-- Logged in -->
          <b-nav-item-dropdown v-else no-caret right class="loggedin">
            <template v-slot:button-content>
              <i class="fas fa-user"></i>
            </template>
            <b-dropdown-item v-for="item in extraHeader.logged" :key="item.id">
              <router-link
                :to="{
                  name: item.link,
                  params: item.linkParams && { ...item.linkParams },
                }"
                custom
                v-slot="{ navigate }"
              >
                <li class="nav-item" @click="navigate" @keypress.enter="navigate">
                  <i :class="item.icon"></i>
                  {{ item.name }}
                </li>
              </router-link>
            </b-dropdown-item>

            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item :href="extraHeader.logout.link" class="nav-item">
              <i :class="extraHeader.logout.icon"></i>
              {{ extraHeader.logout.name }}
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      scrollProgress: "0%",
      isStickyNavBar: false,
      isResponsiveHeaderOpen: false,
      hoveredProductCategoryId: null,
      currentHighlightedMessageIndex: 0,
      isActiveHighlightedMessage: true,
    };
  },
  watch: {
    $route(/* to, from */) {
      this.updateScrollProgressIndicator();
    },
  },
  computed: {
    featuresDropdownItems() {
      return [
        {
          id: 0,
          heading: this.$tm("front.navbar.features.children[0].heading"),
          description: this.$t("front.navbar.features.children[0].description"),
          link: "create",
          icon: require("@mizogoo/assets/img/front/global/header/header_icon_create.png"),
        },
        {
          id: 1,
          heading: this.$tm("front.navbar.features.children[1].heading"),
          description: this.$t("front.navbar.features.children[1].description"),
          link: "printing",
          icon: require("@mizogoo/assets/img/front/global/header/header_icon_print.png"),
        },
        {
          id: 2,
          heading: this.$tm("front.navbar.features.children[2].heading"),
          description: this.$t("front.navbar.features.children[2].description"),
          link: "app",
          icon: require("@mizogoo/assets/img/front/global/header/header_icon_app.png"),
        },
        {
          id: 3,
          heading: this.$tm("front.navbar.features.children[3].heading"),
          description: this.$t("front.navbar.features.children[3].description"),
          link: "pdf_menu",
          icon: require("@mizogoo/assets/img/front/global/header/header_icon_qrcode.png"),
        },
        {
          id: 4,
          heading: this.$tm("front.navbar.features.children[4].heading"),
          description: this.$t("front.navbar.features.children[4].description"),
          link: "translation",
          icon: require("@mizogoo/assets/img/front/global/header/header_icon_translate.png"),
        },
        {
          id: 5,
          heading: this.$tm("front.navbar.features.children[5].heading"),
          description: this.$t("front.navbar.features.children[5].description"),
          link: "analysis",
          icon: require("@mizogoo/assets/img/front/global/header/header_icon_analysis.png"),
        },
      ];
    },
    ressourcesDropdownItems() {
      return [
        {
          id: 0,
          heading: this.$tm("front.navbar.ressources.aboutUs"),
          description: this.$t("front.navbar.features.children[0].description"),
          link: "about_us",
          icon: require("@mizogoo/assets/img/front/global/header/about_us.png"),
        },
        {
          id: 1,
          heading: this.$tm("front.navbar.ressources.commitments"),
          description: this.$t("front.navbar.features.children[1].description"),
          link: "social_commitments",
          icon: require("@mizogoo/assets/img/front/global/header/commitments.png"),
        },
        {
          id: 2,
          heading: this.$tm("front.navbar.ressources.contact"),
          description: this.$t("front.navbar.features.children[2].description"),
          link: "contact",
          icon: require("@mizogoo/assets/img/front/global/header/contact.png"),
        },
      ];
    },
    extraHeader() {
      return {
        logged: [
          {
            id: 0,
            name: this.$t("front.navbar.workspace"),
            link: "workspace",
            icon: "fas fa-pen",
          },
          {
            id: 1,
            name: this.$t("front.navbar.basket"),
            link: "cart",
            icon: "fas fa-shopping-basket",
          },
          {
            id: 2,
            name: this.$t("front.navbar.account"),
            link: "my_account",
            linkParams: { tabIndex: 0 },
            icon: "fas fa-cog",
          },
        ],
        notlogged: [{ id: 0, name: this.$t("front.navbar.login"), link: "login" }],
        logout: {
          name: this.$t("front.navbar.logout"),
          link: "/api/security/logout",
          icon: "fas fa-sign-out-alt",
        },
      };
    },
    langs() {
      return this.$store.getters["global/langs"];
    },
    transparentHeader() {
      return this.frontHeader.transparent && this.isStickyNavBar === false ? true : false;
    },
    /**
     * Ensures that the 'Features' menu item is active when the user is on a sub-menu page
     */
    subFeaturesIsActive() {
      let pathList = [
        "create",
        "printing",
        "app",
        "app_enriched",
        "app_winepairing",
        "app_games",
        "pdf_menu",
        "translation",
        "analysis",
      ]; // list of sub-pages
      // return true if at least one path of the list match the current path
      return pathList.some((path) => {
        return this.$route.name && this.$route.name.indexOf(path) !== -1;
      });
    },
    /**
     * Ensures that the 'Products' menu item is active when the user is on a sub-menu page
     */
    subProductsIsActive() {
      let pathList = ["catalog", "product", "product_do_it_for_me", "product_print_my_file"]; // list of sub-pages
      // return true if at least one path of the list match the current path
      return pathList.some((path) => {
        return this.$route.name && this.$route.name.indexOf(path) !== -1;
      });
    },
    /**
     * Ensures that the 'Products' menu item is active when the user is on a sub-menu page
     */
    subRessourcesIsActive() {
      let pathList = ["about_us", "social_commitments", "contact", "blog", "article"]; // list of sub-pages
      // return true if at least one path of the list match the current path
      return pathList.some((path) => {
        return this.$route.name && this.$route.name.indexOf(path) !== -1;
      });
    },
    headerIsVisible() {
      return this.frontHeader.visible;
    },
    // Cart
    isCartEmpty() {
      return this.cart === null || this.cart.elements.length === 0;
    },
    cart() {
      return this.$store.getters["cart/cart"];
    },
    // Highlighted Message
    isLoadingHighlightedMessage() {
      return this.$store.getters["highlightedMessage/isLoading"];
    },
    hasErrorHighlightedMessage() {
      return this.$store.getters["highlightedMessage/hasError"];
    },
    errorHighlightedMessage() {
      return this.$store.getters["highlightedMessage/error"];
    },
    highlightedMessages() {
      return this.$store.getters["highlightedMessage/highlightedMessages"];
    },
    hasHighlightedMessage() {
      return this.$store.getters["highlightedMessage/hasHighlightedMessage"];
    },
    currentHighlightedMessage() {
      return this.highlightedMessages[this.currentHighlightedMessageIndex];
    },
    // Product Category
    isLoadingProductCategories() {
      return this.$store.getters["product/isLoadingProductCategories"];
    },
    hasErrorProductCategory() {
      return this.$store.getters["product/hasErrorProductCategory"];
    },
    errorProductCategories() {
      return this.$store.getters["product/errorProductCategories"];
    },
    hasProductCategories() {
      return this.$store.getters["product/hasProductCategories"];
    },
    productCategories() {
      return this.$store.getters["product/productCategories"];
    },
    productsFromHoveredProductCategory() {
      if (this.hoveredProductCategoryId) {
        return this.productCategories.find(
          (productCategory) => productCategory.id === this.hoveredProductCategoryId
        ).products;
      } else {
        return this.productCategories[0].products;
      }
    },
    // Fetch Articles Overview
    isLoadingArticleOverview() {
      return this.$store.getters["article/isLoadingArticleOverview"];
    },
    hasErrorArticle() {
      return this.$store.getters["article/hasError"];
    },
    errorArticle() {
      return this.$store.getters["article/error"];
    },
    articlesOverview() {
      return this.$store.getters["article/articlesOverview"];
    },
    hasArticleOverview() {
      return this.$store.getters["article/hasArticleOverview"];
    },
  },
  methods: {
    updateScrollProgressIndicator() {
      const { documentElement, body } = document;
      let windowScroll = body.scrollTop || documentElement.scrollTop;
      let height = documentElement.scrollHeight - documentElement.clientHeight;
      this.scrollProgress = (windowScroll / height) * 100 + "%";
    },
    onScroll() {
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }

      if (this.frontHeader.transparent) {
        this.isStickyNavBar = window.pageYOffset > 51;
      } else {
        this.isStickyNavBar = window.pageYOffset > 0;
      }
    },
    /**
     * Open dropdown (triggered on mousehover - cf. template part)
     */
    onOverFeatures() {
      this.$refs.featuresDropdown.visible = true;
    },
    /**
     * Close dropdown (triggered on mouseleave - cf. template part)
     */
    onLeaveFeatures() {
      this.$refs.featuresDropdown.visible = false;
    },
    onOverProducts() {
      this.$refs.productsDropdown.visible = true;
    },
    onLeaveProducts() {
      this.$refs.productsDropdown.visible = false;
    },
    onOverRessources() {
      this.$refs.ressourcesDropdown.visible = true;
    },
    onLeaveRessources() {
      this.$refs.ressourcesDropdown.visible = false;
    },
    changeLang(lang) {
      this.$i18n["locale"] = lang.language;
    },
    roundHeaderHeight() {
      let header = document.getElementById("header");
      let navbar = document.getElementById("navbar");
      let headerHeight = navbar.clientHeight;
      header.style.height = headerHeight + "px";
      return headerHeight;
    },
    toggleBurgerHeader() {
      this.isResponsiveHeaderOpen = !this.isResponsiveHeaderOpen;
    },
    // Automatically change the current highlighted message every 10 seconds
    highlightedMessagesAutomaticToggle() {
      setInterval(() => {
        this.isActiveHighlightedMessage = false;
        setTimeout(() => {
          this.currentHighlightedMessageIndex =
            (this.currentHighlightedMessageIndex + 1) % this.highlightedMessages.length;
          this.isActiveHighlightedMessage = true;
        }, 600);
      }, 10000);
    },
  },
  async created() {
    await this.$store.dispatch("cart/findCart");
    this.highlightedMessagesAutomaticToggle();
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("scroll", this.updateScrollProgressIndicator);

    let height = this.roundHeaderHeight(); // Round header height
    this.$store.dispatch("global/fetchFrontHeaderHeight", height); // and send it to the store
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("scroll", this.updateScrollProgressIndicator);
  },
};
</script>

<style lang="scss">
// White classic header (transparent override classic rules)
#header {
  // Fixed header (need a padding-top over every pages)
  position: absolute;
  width: 100%;
  z-index: 10;

  transition: all 0.5s;

  // Scroll progress indicator
  .progress-indicator-wrapper {
    position: fixed;
    height: 5px;
    background-color: transparent;
    width: 100%;
    top: 0;
    z-index: 1040;

    .progress-indicator {
      height: 5px;
      background: linear-gradient(135deg, #fd6f63 0%, #ff395e 60%);
    }
  }

  .extra-navbar {
    display: flex;
    align-items: center;
    height: 50px;
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.8rem 2rem !important;

    .extra-navbar-inner {
      font-size: 0.8rem;
      overflow: hidden;
    }

    .fade {
      opacity: 1;
      transform: translateY(0px);
      transition: all 0.6s ease-in-out;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: all 0.6s ease-in-out;
    }

    .fade-enter-from {
      opacity: 0;
      transform: translateY(40px);
    }

    .fade-leave-to {
      opacity: 0;
      transform: translateY(-40px);
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover .extra-navbar-learn-more span {
        text-decoration: underline;
      }
    }

    ul {
      align-items: center;
    }

    .extra-navbar-tag {
      margin-right: 1rem;
      padding: 0.2rem 0.7rem;
      font-weight: 500;
      font-size: 0.75rem;
      color: $mizogoo-gray-dark;
      background-color: rgb(255, 255, 255, 0.6);
      border-radius: 4px;
    }

    .extra-navbar-learn-more {
      display: flex;
      align-items: center;
      margin-left: 1rem;

      i {
        margin-left: 0.4rem;
      }
    }

    .social a {
      margin: 0.3rem;
      color: #fff;
    }
  }

  .navbar {
    padding: 0.5rem 2rem;
    background-color: #fff;
    transition: background-color 0.3s;

    /* Logo part */
    .logo-container {
      padding: 0;

      .logo-text-part,
      .logo-text-part-sticky {
        display: inline-block;
        color: #706e81;
        font-size: 1.6rem;
        font-weight: 600;
        font-family: "SF New Republic";
        vertical-align: middle;
      }

      .logo-text-part::first-letter,
      .logo-text-part-sticky::first-letter {
        color: #ff395e;
      }
    }

    /* Central menu part */
    .custom-responsive-icon {
      color: $mizogoo-logo-font-color;
      display: none;
    }

    #nav-collapse {
      .nav-item {
        padding: 1rem;
        height: 100%;

        .nav-link {
          position: relative;
          padding: 0;
          color: $mizogoo-logo-font-color;
        }

        .nav-link:after {
          content: "";
          position: absolute;
          width: 0;
          height: 1px;
          display: block;
          margin-top: 5px;
          right: 0;
          background: $mizogoo-logo-font-color;
          transition: width 0.2s ease;
          -webkit-transition: width 0.2s ease;
        }

        .nav-link:hover:after {
          width: 100%;
          left: 0;
        }

        /* Active nav item */
        &.router-link-active {
          a.nav-link {
            color: $mizogoo-gray-dark;
            font-weight: 500;
          }
        }
      }

      .dropdown-menu {
        left: 50% !important;
        transform: translateX(-50%);
        overflow: initial;

        // Dropdown arrow (beafore and after for shadow and arrow itself)
        &::before {
          content: "";
          position: absolute;
          top: -8px;
          left: 50%;
          display: inline-block;
          border-bottom: 9px solid rgba(0, 0, 0, 0.2);
          border-left: 9px solid rgba(0, 0, 0, 0);
          border-right: 9px solid rgba(0, 0, 0, 0);
        }

        &::after {
          content: "";
          position: absolute;
          top: -7px;
          left: 50%;
          display: inline-block;
          border-bottom: 8px solid #fff;
          border-left: 9px solid rgba(0, 0, 0, 0);
          border-right: 9px solid rgba(0, 0, 0, 0);
        }
      }

      .features-dropdown {
        .dropdown-menu {
          padding: 1rem;

          li {
            margin: 0;

            .dropdown-item {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 0.7rem;
              border-radius: 10px;

              .features-icon {
                min-width: 20px;
                text-align: center;

                img {
                  max-width: 50px;
                }
              }

              .features-container {
                display: flex;
                flex-direction: column;
                margin-left: 1rem;

                .features-heading {
                  font-weight: 400;
                }

                .features-description {
                  font-size: 0.9rem;
                  color: #acacac;
                }
              }
            }
          }
        }
      }

      .ressources-dropdown {
        // .dropdown-menu {
        .ressources-dropdown-inner {
          width: 700px;

          .ressources-dropdown-cols {
            display: flex;

            .ressources-dropdown-col-header {
              margin-bottom: 1rem;
              margin-left: 1rem;
              font-size: 0.9rem;
              color: #b3b5b7;
            }

            .ressources-dropdown-blog-col {
              width: 450px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              .ressources-dropdown-blog-col-inner {
                position: relative;
                padding: 1.5rem;
                border-right: 1px solid $mizogoo-gray-light;

                .spinner-container,
                .error-container {
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .error-container {
                  .alert {
                    padding: 1rem 2rem;
                    border-radius: 15px;
                  }
                }

                .blog-article-container {
                  display: flex;
                  padding: 0.5rem 1rem;
                  border-radius: 10px;

                  &:hover {
                    background-color: $background-color;
                  }

                  .dropdown-item {
                    display: flex;
                    padding: 0;
                    white-space: initial;

                    &:hover {
                      background-color: transparent;
                    }
                  }

                  .blog-image {
                    img {
                      width: 140px;
                      height: 90px;
                      object-fit: cover;
                      border-radius: 10px;
                    }
                  }

                  .blog-text {
                    margin-left: 1rem;
                    font-weight: 500;

                    .blog-category {
                      margin-bottom: 0.2rem;
                      font-size: 0.75rem;
                      text-transform: uppercase;
                      color: $mizogoo;
                    }

                    .blog-title {
                      line-height: 1.2;
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: 3;
                      overflow: hidden;
                    }
                  }
                }
              }

              .ressources-dropdown-blog-col-extra {
                padding: 1rem 2rem;
                background-color: $mizogoo-gray-light;
                border-bottom-left-radius: 15px;
                border-bottom-right-radius: 15px;

                a {
                  color: $mizogoo-gray-dark !important;
                  font-weight: 500;
                  padding: 0;

                  &:hover {
                    background-color: initial;
                  }
                }
              }
            }

            .ressources-dropdown-link-col {
              width: 250px;
              padding: 1.5rem;

              li {
                margin: 0;

                .dropdown-item {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  padding: 0.7rem;
                  border-radius: 10px;

                  .ressources-icon {
                    min-width: 20px;
                    text-align: center;

                    img {
                      max-width: 25px;
                    }
                  }

                  .ressources-container {
                    display: flex;
                    flex-direction: column;
                    margin-left: 1rem;

                    .ressources-heading {
                      font-weight: 400;
                    }

                    .ressources-description {
                      font-size: 0.9rem;
                      color: #acacac;
                    }
                  }
                }
              }
            }
          }
        }
        // }
      }

      .products-dropdown {
        .products-dropdown-inner {
          width: 700px;
          min-height: 300px;

          .spinner-container,
          .error-container {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .error-container {
            .alert {
              padding: 1rem 2rem;
              border-radius: 15px;
            }
          }

          .products-dropdown-cols {
            display: flex;
            padding: 1.5rem;

            .products-dropdown-category-col {
              width: 200px;
            }

            .products-dropdown-product-col {
              width: 475px;

              .products-dropdown-col-header {
                margin-left: calc(1rem + 25px);
              }
            }

            .products-dropdown-col-header {
              margin-bottom: 1rem;
              margin-left: 1rem;
              font-size: 0.9rem;
              color: #b3b5b7;
            }

            .product-category-container {
              display: flex;
              justify-content: space-between;
              align-items: flex-start;
              padding: 0.5rem 1rem;
              border-radius: 10px;
              transition: all 0.2s ease-in-out;

              &.hover {
                background-color: $background-color;

                .product-category-name,
                i.fa-angle-right {
                  color: $mizogoo;
                }
              }

              .product-category-name {
                font-weight: 500;
              }

              .product-category-number {
                font-size: 0.9rem;
                color: #b3b5b7;
              }

              i {
                margin-top: 0.35rem;
              }
            }

            .product-container {
              margin-left: 1rem;

              .dropdown-item {
                display: flex;
                margin: 0;
                padding: 0.5rem 1rem;
                border-radius: 10px;
                transition: background-color 0.2s ease-in-out;
                text-decoration: none !important;

                &:hover {
                  background-color: $background-color;
                }

                .product-icon {
                  img {
                    width: 60px;
                    height: 60px;
                    object-fit: cover;
                    border-radius: 10px;
                  }
                }

                .product-text-container {
                  margin-left: 1rem;
                  text-decoration: none !important;

                  .product-name {
                    font-weight: 500;
                    color: $mizogoo-gray-dark !important;
                  }

                  .product-description {
                    max-width: 70%;
                    line-height: 1.2;
                    font-size: 0.9rem;
                    color: #b3b5b7;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    white-space: initial;
                  }
                }
              }
            }
          }

          .product-dropdown-extra {
            padding: 1rem 2rem;
            background-color: $mizogoo-gray-light;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;

            a {
              color: $mizogoo-gray-dark !important;
              font-weight: 500;
              padding: 0;

              &:hover {
                background-color: initial;
              }
            }
          }
        }
      }
    }

    /* Right menu part */
    .menu-right {
      align-items: center;

      @media screen and (max-width: 992px) {
        flex-direction: row;

        li {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }
      }

      .language-dropdown {
        display: flex;

        .language-icon {
          width: 17px;
          height: 17px;
        }

        .fas {
          margin-left: 3px;
        }
      }

      /* Not logged in */
      .login,
      .demo {
        height: 100%;
        padding: 0.2rem 1rem;
        margin-left: 0.5rem;
        border: 2px solid $mizogoo-logo-font-color;
        border-radius: 50px;
        color: $mizogoo-logo-font-color;
        cursor: pointer;
        transition: 0.2s;

        &:hover {
          color: $white;
          background-color: $mizogoo-logo-font-color;
        }
      }

      /* Logged in */
      .loggedin {
        .fa-user {
          background-color: $mizogoo-gray-light;
          border-radius: 100px;
          padding: 0.6rem;
        }

        .dropdown-item {
          .fas {
            margin-right: 0.5rem;
          }
        }
      }
    }

    /* Sticky menu adjustments */
    &.sticky {
      background-color: $white;
      -webkit-box-shadow: 0px 4px 6px 0px rgba(0, 0, 30, 0.1);
      -moz-box-shadow: 0px 4px 6px 0px rgba(0, 0, 30, 0.1);
      box-shadow: 0px 4px 6px 0px rgba(0, 0, 30, 0.1);
    }

    /* Media queries */
    @media screen and (max-width: 992px) {
      /* General display */
      .logo-container,
      .custom-responsive-icon,
      .menu-right {
        flex-basis: 100%;
      }

      .logo-container {
        order: 2;
        text-align: center;
      }
      .custom-responsive-icon {
        order: 1;
        display: initial;
      }
      .menu-right {
        order: 3;
      }

      /* Central menu part */
      #nav-collapse {
        flex-direction: column;
        position: absolute;
        top: 90px;
        background-color: #fff;
        width: 100%;
        text-align: left;
        align-items: flex-start;
        left: 0;

        a.nav-link {
          color: $mizogoo-logo-font-color;
        }

        .navbar-nav {
          flex-direction: column;

          .inner-container {
            display: block;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      .extra-navbar {
        .social {
          display: none;
        }
      }
    }

    @media screen and (max-width: 500px) {
      padding: 0.8rem 1rem !important;

      .logo-container {
        margin-right: 0;

        .logo-text-part,
        .logo-text-part-sticky {
          display: none !important;
        }
      }

      .menu-right {
        .nav-item {
          padding: 0 !important;
          border: none !important;
        }

        // a.nav-link {
        // padding-left: 0;
        // padding-right: 0;
        // }
      }
    }
  }
}

/* Transparent header (override classic rules) */
#header.transparent {
  .navbar {
    background-color: transparent;

    .logo-container {
      .logo-text-part,
      .logo-text-part-sticky {
        color: $white;
      }

      .logo-text-part::first-letter,
      .logo-text-part-sticky::first-letter {
        color: $white;
      }
    }

    .custom-responsive-icon {
      color: $white;
    }

    #nav-collapse {
      .nav-item {
        .nav-link {
          color: $white;

          @media screen and (max-width: 992px) {
            color: $mizogoo-logo-font-color;
          }
        }

        .nav-link:after {
          background: $white;
        }

        /* Active nav item */
        &.router-link-active {
          a.nav-link {
            color: $white;

            @media screen and (max-width: 992px) {
              color: $mizogoo-gray-dark;
            }
          }
        }
      }
    }

    .menu-right {
      .login,
      .demo {
        border: 2px solid $white;
        color: $white;

        &:hover {
          color: $mizogoo-secondary;
          background-color: $white;
        }
      }

      .nav-item:not(.loggedin) {
        .nav-link {
          color: $white;
        }
      }
    }
  }
}

/* Custom collapse menu (full page) */
#header {
  .navbar {
    #nav-collapse {
      .custom-responsive-menu {
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        padding: 2rem 1rem;
        background-color: $white;
        overflow: auto;
        z-index: 5;

        .custom-responsive-menu-header {
          display: flex;
          justify-content: space-between;
          padding-bottom: 2rem;
          color: $mizogoo-logo-font-color;
        }

        .inner-container {
          padding-left: 0.5rem;

          .nav-item {
            height: initial;

            .nav-link {
              font-size: 1.2rem;
              font-weight: 500;

              &:hover:after {
                width: 0;
              }
            }
          }
        }

        // Sublinks under the dropdown
        #features-inner-collapse,
        #ressources-inner-collapse {
          margin-left: 1rem;

          .nav-item {
            padding: 0.5rem;

            &:last-child {
              margin-bottom: 1rem;
            }

            .nav-link {
              font-size: 1.05rem;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

// Responsive menu animation
.slide-leave-active,
.slide-enter-active {
  transition: 0.3s;
}

.slide-enter-from {
  transform: translate(-100%, 0);
}

.slide-leave-to {
  transform: translate(-100%, 0);
}
</style>

<style lang="scss">
#header {
  .dropdown-menu {
    margin-top: 0;
    padding: 0;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    transition: all 0.2s ease-in-out;
    display: block;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;

    &.show {
      visibility: visible;
      opacity: 1;
    }

    .dropdown-item:hover,
    .dropdown-item:focus,
    .dropdown-item:active {
      background-color: $background-color;
      color: initial;
    }
  }

  a.nav-link {
    &:focus,
    &:focus-visible {
      outline: none !important;
    }
  }
}
</style>
