import NewsletterSubscriptionAPI from "../api/newsletter_subscription";

const
  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR";

export default {
  namespaced: true,
  state: {
    // Creating, Removing
    isLoadingAction: false,
    errorAction: null,
  },
  getters: {
    // Creating, Removing
    isLoadingAction(state) {
      return state.isLoadingAction;
    },
    errorAction(state) {
      return state.errorAction;
    },
    hasErrorAction(state) {
      return state.errorAction !== null;
    },
  },
  mutations: {
    [CREATING](state) {
      state.isLoadingAction = true;
      state.errorAction = null;
    },
    [CREATING_SUCCESS](state) {
      state.isLoadingAction = false;
      state.errorAction = null;
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.errorAction = error;
    },
    [REMOVING](state) {
      state.isLoadingAction = true;
      state.errorAction = null;
    },
    [REMOVING_SUCCESS](state) {
      state.isLoadingAction = false;
      state.errorAction = null;
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.errorAction = error;
    },
  },
  actions: {
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await NewsletterSubscriptionAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await NewsletterSubscriptionAPI.remove(payload.form);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS);
          return response.data;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return response.data;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
  }
}
