<template>
  <b-container>
    <b-row v-if="hasError">
      <b-col>
        <FlashBag v-if="hasErrorProductCategory" :message="errorProductCategories" />
        <FlashBag v-if="hasErrorProduct" :message="errorProduct" />
        <FlashBag v-if="hasErrorFeature" :message="errorFeatures" />
        <FlashBag v-if="hasErrorFeatureValues" :message="errorFeatureValues" />
        <FlashBag v-if="hasErrorPrices" :message="errorPrices" />
        <FlashBag v-if="hasErrorCreatingCartElement" :message="errorCreatingCartElement" />
      </b-col>
    </b-row>

    <b-row>
      <b-col class="product-preview-col-container" md="5">
        <ProductPreview
          :product="product"
          :currentFeatureValues="currentFeatureValues"
          :featureValuesHasChanged="featureValuesHasChanged"
        />
      </b-col>

      <!-- Product feature selection -->
      <b-col class="product-configuration-container" md="7">
        <div class="product-configuration-inner-container">
          <!-- Feature loading (displayed when changing product features) -->
          <Form as="" ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit="handleSubmit(onClickAddCart)" class="d-flex flex-column">
              <!-- Product main informations (name and description) -->
              <div class="main-product-info-container">
                <h2 class="product-name">{{ product.name }}</h2>
                <!-- Average score -->
                <template v-if="product.productReviewSummary">
                  <div class="average-score-container" @click="onClickShowReviews">
                    <div class="average-stars-container">
                      <div
                        class="average-stars"
                        :style="'--percent: ' + (product.productReviewSummary.averageRating / 5) * 100 + '%'"
                      ></div>
                      <div class="absolute-stars">
                        <template v-for="i in 5" :key="'star-' + i">
                          <img
                            class="star"
                            :src="require('@mizogoo/assets/img/star_border.png')"
                          />
                        </template>
                      </div>
                    </div>
                    <div class="score">
                      {{ product.productReviewSummary.averageRating.toFixed(1) }}
                    </div>
                    <div>({{ product.productReviewSummary.totalReviews }} {{ $t("front.product.rating.reviews") }})</div>
                  </div>
                </template>
                <p class="product-tagline">{{ product.description }}</p>
                <p class="product-description">
                  <div class="product-description-show-more" @click="onClickShowFullDescription">
                    {{ $t('front.product.showMore') }}
                  </div><span v-html="product.longDescription"></span>
                </p>
              </div>

              <!-- Initial loading -->
              <template v-if="isInitialLoading && isLoading">
                <div class="loading-overlay" />
                <div class="loading-initial">
                  <b-spinner />
                </div>
              </template>

              <!-- Feature loading (displayed when changing product features) -->
              <div v-if="!isInitialLoading && isLoading" class="loading-prices">
                <b-spinner />
              </div>

              <div
                v-if="!isInitialLoading"
                v-for="step in steps"
                :key="'step-' + step.id"
              >
                <template v-if="step.type === 'feature'">
                  <template v-if="step.constName === 'FORMAT' || step.printComConstName === 'size'">
                    <CustomizableFormat
                      :step="step"
                      :currentFeatureValues="currentFeatureValues"
                      :nbStepBeforeFeature="nbStepBeforeFeature"
                      @onClickFeatureValue="onClickFeatureValue"
                    />
                  </template>
                  <template v-else>
                    <CustomizableFeature
                      :step="step"
                      :currentFeatureValues="currentFeatureValues"
                      :nbStepBeforeFeature="nbStepBeforeFeature"
                      @onClickFeatureValue="onClickFeatureValue"
                    />
                  </template>
                </template>

                <template v-if="step.type === 'quantity'">
                  <CustomizableQuantity
                    :step="step"
                    :prices="prices"
                    :maxQuantity="maxQuantity"
                    :currentQuantity="currentQuantity"
                    @onClickQuantity="onClickQuantity"
                  />
                </template>
              </div>

              <template v-if="product.constName === 'DIGITAL_MENU'">
                <!-- Comments -->
                <div class="mt-3 mb-1">
                  <FormTextarea
                    id="comments"
                    :name="$t('front.product.comments').toUpperCase()"
                    v-model="comment"
                    rows="3"
                    maxRows="3"
                  />
                </div>

                <!-- File -->
                <div class="mt-3 mb-3">
                  <FormFile
                    id="files"
                    :name="$t('front.product.files').toUpperCase()"
                    :rules="{
                      required: true,
                      ext: ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'odt', 'xls', 'xlsx', 'ods', 'csv'],
                      mimes: [
                        'image/jpeg',
                        'image/png',
                        'application/pdf',
                        'application/msword',
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        'application/vnd.oasis.opendocument.text',
                        'application/vnd.ms-excel',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'application/vnd.oasis.opendocument.spreadsheet',
                        'text/csv',
                      ],
                    }"
                    :help="$t('front.form.global.acceptedFormats') + ' :' "
                    v-model="files"
                    :multiple="true"
                  />

                  <p class="text-wrap">
                    {{ $t("front.product.exempleFiles") }}
                    <a :href="dishFile">{{ $t("front.product.dishFileTemplate") }}</a>,
                    <a :href="wineFile">{{ $t("front.product.wineFileTemplate") }}</a>,
                    <a :href="beerFile">{{ $t("front.product.beerFileTemplate") }}</a>,
                    <a :href="drinkFile">{{ $t("front.product.drinkFileTemplate") }}</a>.
                  </p>
                </div>
              </template>

              <div class="product-price-container">
                <div class="product-price">
                  <template v-if="product.isDeliverable">
                    {{ $t("front.product.productPriceWithoutDelivery") }}
                  </template>
                  <template v-else>
                    {{ $t("front.product.productPrice") }}
                  </template>
                  <span v-if="currentPrice" class="product-price-value">
                    {{ formatPrice(currentPrice.priceHt) }}
                  </span>
                </div>

                <div class="product-team-for-the-planet">
                  <span>{{ $t("front.product.with") }}</span>
                  <img :src="require('@mizogoo/assets/img/products/team_for_the_planet_logo.png')" />
                  <span>Team for the Planet,</span>
                  {{ $t("front.product.teamForThePlanet1") }}<br />
                  {{ $t("front.product.teamForThePlanet2") }}
                  <i class="far fa-question-circle" id="team-for-the-planet-popover"></i>
                  <b-popover target="team-for-the-planet-popover" triggers="hover" placement="lefttop">
                    <div class="indicative-ex-1">
                      {{ $t("front.product.teamForThePlanetExplanations1") }}
                    </div>
                    <div class="indicative-ex-2">
                      <a href="https://join-time.com/fr" target="_blank">
                        {{ $t("front.product.teamForThePlanetExplanations2") }}
                      </a>
                    </div>
                  </b-popover>
                </div>
              </div>

              <div class="product-configuration-buttons-container">
                <div
                  role="button"
                  class="product-add-cart-button"
                  :class="{ 'disabled-product-button': !isButtonEnabled || showSubscriptionWarning }"
                  @click.prevent.stop="!showSubscriptionWarning && isButtonEnabled ? handleSubmit(onClickAddCart) : null"
                >
                  <div class="product-button-heading">
                    {{ $t("front.product.addCart") }}
                  </div>
                  <i class="fas fa-angle-right"></i>
                </div>

                <p v-if="showSubscriptionWarning" class="subscription-warning">
                  {{ $t("front.product.subscriptionWarning1") }}
                  <router-link :to="{ name: 'subscription' }">
                    {{ $t("front.product.subscriptionWarning2") }}
                  </router-link>.
                </p>
              </div>
            </b-form>
          </Form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MetaMixin from "@mizogoo/mixins/meta.js";
import FlashBag from "@mizogoo/components/FlashBag";
import ProductPreview from "@mizogoo/components/front/pages/ProductPage/ProductPreview";
import CustomizableFormat from "@mizogoo/components/front/pages/ProductPage/CustomizableFormat";
import CustomizableFeature from "@mizogoo/components/front/pages/ProductPage/CustomizableFeature";
import CustomizableQuantity from "@mizogoo/components/front/pages/ProductPage/CustomizableQuantity";
import FormTextarea from "@mizogoo/components/form/FormTextarea";
import FormFile from "@mizogoo/components/form/FormFile";
import dishFile from "@mizogoo/assets/xlsx/modele_plat.xlsx";
import wineFile from "@mizogoo/assets/xlsx/modele_vin.xlsx";
import beerFile from "@mizogoo/assets/xlsx/modele_biere.xlsx";
import drinkFile from "@mizogoo/assets/xlsx/modele_boisson.xlsx";

export default {
  mixins: [MetaMixin],
  name: "ProductPageClasic",
  components: {
    FlashBag,
    ProductPreview,
    CustomizableFormat,
    CustomizableFeature,
    CustomizableQuantity,
    FormTextarea,
    FormFile,
  },
  props: ["product", "matchProductName"],
  emits: ["showDescriptionTab"],
  data() {
    return {
      isInitialLoading: true,
      nbStepBeforeFeature: null,
      steps: [],
      currentFeatureValues: [],
      currentQuantity: 1,
      comment: null,
      files: [],
      featureValuesHasChanged: false,
      dishFile,
      wineFile,
      beerFile,
      drinkFile,
    };
  },
  computed: {
    ...mapState({
      errorProductCategories: (state) => state.product.errorProductCategories,
      productCategories: (state) => state.product.productCategories,
      errorProduct: (state) => state.product.errorProduct,
      isLoadingFeatures: (state) => state.product.isLoadingFeatures,
      errorFeatures: (state) => state.product.errorFeatures,
      features: (state) => state.product.features,
      isLoadingFeatureValues: (state) => state.product.isLoadingFeatureValues,
      errorFeatureValues: (state) => state.product.errorFeatureValues,
      featureValues: (state) => state.product.featureValues,
      isLoadingPrices: (state) => state.product.isLoadingPrices,
      errorPrices: (state) => state.product.errorPrices,
      prices: (state) => state.product.prices,
      isLoadingCreatingCartElement: (state) => state.cartElement.isLoadingCreating,
      errorCreatingCartElement: (state) => state.cartElement.errorCreating,
    }),
    ...mapGetters("security", {
      hasActivePremiumSubscription: "hasActivePremiumSubscription",
    }),
    ...mapGetters("product", {
      hasErrorProductCategory: "hasErrorProductCategory",
      hasErrorProduct: "hasErrorProduct",
      hasErrorFeature: "hasErrorFeature",
      hasErrorFeatureValues: "hasErrorFeatureValues",
      hasErrorPrices: "hasErrorPrices",
      minQuantity: "minQuantity",
      maxQuantity: "maxQuantity",
    }),
    ...mapGetters("cartElement", {
      hasErrorCreatingCartElement: "hasErrorCreating",
    }),
    // Global
    isLoading() {
      return (
        this.isLoadingFeatures ||
        this.isLoadingFeatureValues ||
        this.isLoadingPrices
      );
    },
    hasError() {
      return (
        this.hasErrorFeature ||
        this.hasErrorFeatureValues ||
        this.hasErrorPrices ||
        this.hasErrorCreatingCartElement
      );
    },
    metaTitle() {
      return this.$t(`front.metadata.products.${this.matchProductName}.title`);
    },
    metaDescription() {
      return this.$t(`front.metadata.products.${this.matchProductName}.description`);
    },
    currentPrice() {
      if (this.prices.length === 0) {
        return false;
      }

      return this.prices.find((element) => element.quantity === this.currentQuantity);
    },
    isButtonEnabled() {
      return this.isLoadingPrices === false && this.isLoadingCreatingCartElement === false && this.currentPrice;
    },
    showSubscriptionWarning() {
      return this.product.constName === "DIGITAL_MENU" && !this.hasActivePremiumSubscription;
    },
  },
  methods: {
    // Step
    loadingSteps() {
      let id = 0;

      this.nbStepBeforeFeature = id;

      for (const key in this.features) {
        if (this.features.hasOwnProperty(key)) {
          this.steps.push({
            id: id,
            type: "feature",
            name: this.features[key].name,
            featureId: this.features[key].id,
            constName: this.features[key].constName,
            printComConstName: this.features[key].printComConstName,
            featureValues: [],
          });

          id++;
        }
      }

      if (this.product.isPrintable || this.product.isDeliverable) {
        this.steps.push({
          id: id++,
          type: "quantity",
          name: "Quantité",
        });
      }

      this.buildFirstFeatureValue();
    },
    // Feature Value
    buildFirstFeatureValue() {
      // On récupère la première featureValue de la liste
      let firstFeatureValue = this.featureValues[Object.keys(this.featureValues)[0]];

      // On récupère la step liée à cette featureValue
      let step = this.steps.find((element) => element.featureId === firstFeatureValue.feature.id);

      // On vérifie s'il y a une feature pour une pré chargement
      if (this.preLoadProductCustomizable && this.preLoadProductCustomizable.preLoadFeatureValues) {
        if (
          typeof this.featureValues[
            this.preLoadProductCustomizable.preLoadFeatureValues[step.id - this.nbStepBeforeFeature]
          ] !== "undefined"
        ) {
          firstFeatureValue =
            this.featureValues[
              this.preLoadProductCustomizable.preLoadFeatureValues[step.id - this.nbStepBeforeFeature]
            ];
        }
      }

      // On asigne à la step la liste complète des featureValues
      step.featureValues = this.featureValues;

      // On définit la featureValue comme valeur de base
      this.currentFeatureValues[step.id - this.nbStepBeforeFeature] = firstFeatureValue;

      // Si la première featureValue à des enfants on build
      if (Object.keys(firstFeatureValue.children).length) {
        this.buildFeatureValues(firstFeatureValue.children);
      } else {
        this.findPrices();
      }
    },
    buildFeatureValues(featureValues) {
      // On récupère la première featureValue de la liste (des enfants)
      let firstFeatureValue = featureValues[Object.keys(featureValues)[0]];

      // On récupère la step liée à cette featureValue
      let step = this.steps.find((element) => element.featureId === firstFeatureValue.feature.id);

      // On vérifie s'il y a une feature pour une pré chargement
      if (this.preLoadProductCustomizable && this.preLoadProductCustomizable.preLoadFeatureValues) {
        if (
          typeof featureValues[
            this.preLoadProductCustomizable.preLoadFeatureValues[step.id - this.nbStepBeforeFeature]
          ] !== "undefined"
        ) {
          firstFeatureValue =
            featureValues[this.preLoadProductCustomizable.preLoadFeatureValues[step.id - this.nbStepBeforeFeature]];
        }
      }

      // On assigne à la step la liste complète des featureValues
      step.featureValues = featureValues;

      // On définit la featureValue comme valeur de base
      this.currentFeatureValues[step.id - this.nbStepBeforeFeature] = firstFeatureValue;

      // Si la première featureValue à des enfants on rappel la methode
      if (Object.keys(firstFeatureValue.children).length) {
        this.buildFeatureValues(firstFeatureValue.children);
      } else {
        this.findPrices();
        this.featureValuesHasChanged = !this.featureValuesHasChanged;
      }
    },
    onClickFeatureValue(step, featureValue) {
      this.currentFeatureValues[step.id - this.nbStepBeforeFeature] = featureValue;

      if (Object.keys(featureValue.children).length) {
        this.checkCurrentFeatureValue(step.id + 1, featureValue.children);
      } else {
        this.findPrices();
      }

      this.featureValuesHasChanged = !this.featureValuesHasChanged;
    },
    checkCurrentFeatureValue(currentStepId, featureValues) {
      // On récupère la step liée à currentStepId
      let step = this.steps.find((element) => element.id === currentStepId);

      // On assigne à la step la liste complète des featureValues
      step.featureValues = featureValues;

      // On récupère la currentFeatureValue liée à la step
      let currentFeatureValue = this.currentFeatureValues[step.id - this.nbStepBeforeFeature];

      // On vérifie si la currentFeatureValue existe dans la liste featureValues
      if (typeof featureValues[currentFeatureValue.position + "~" + currentFeatureValue.id] === "undefined") {
        // Si elle n'existe pas on réassigne la première
        this.currentFeatureValues[step.id - this.nbStepBeforeFeature] = featureValues[Object.keys(featureValues)[0]];
      } else {
        // Sinon on la met à jour
        this.currentFeatureValues[step.id - this.nbStepBeforeFeature] =
          featureValues[currentFeatureValue.position + "~" + currentFeatureValue.id];
      }

      // Si la currentFeatureValue à des enfants on rappel la methode
      if (Object.keys(this.currentFeatureValues[step.id - this.nbStepBeforeFeature].children).length) {
        this.checkCurrentFeatureValue(
          currentStepId + 1,
          this.currentFeatureValues[step.id - this.nbStepBeforeFeature].children
        );
      } else {
        this.findPrices();
      }
    },
    // Price
    async findPrices() {
      let productFeatureValues = [];
      for (const currentFeatureValue of this.currentFeatureValues) {
        productFeatureValues.push(currentFeatureValue.position + "~" + currentFeatureValue.id);
      }

      await this.$store.dispatch("product/findPrices", {
        product: this.product,
        data: {
          allProductFeatureValues: this.featureValues,
          productFeatureValues: productFeatureValues,
        },
      });

      if (this.minQuantity) {
        this.currentQuantity = this.minQuantity.quantity;
      }

      // Initial loading is ending when we have prices
      this.isInitialLoading = false;
    },
    // Quantity
    onClickQuantity(price) {
      this.currentQuantity = price.quantity;
    },
    // Cart
    async onClickAddCart() {
      this.$refs.observer.validate().then(async (success) => {
        if (success) {
          let productFeatueValues = [];
          for (const currentFeatureValue of this.currentFeatureValues) {
            productFeatueValues.push(currentFeatureValue.position + "~" + currentFeatureValue.id);
          }

          await this.$store.dispatch("cartElement/create", {
            productId: this.product.id,
            data: {
              productFeatureValues: productFeatueValues,
              quantity: this.currentQuantity,
              comment: this.comment,
              files: this.files,
            },
          });

          if (!this.hasErrorCreatingCartElement) {
            return this.$router.push({ name: "cart" }).catch(() => {});
          }
        } else {
          this.handleFormErrors(this.$refs.observer.refs);
        }
      });
    },
    onClickShowFullDescription() {
      document.querySelector('#full-description').scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });

      this.$emit('showDescriptionTab');
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch("product/findFeatures", { product: this.product }),
      this.$store.dispatch("product/findFeatureValues", { product: this.product }),
    ]);

    this.loadingSteps();
  },
};
</script>

<style lang="scss" scoped>
.product-preview-col-container {
  @media (max-width: 768px) {
    display: none;
  }
}

.product-configuration-container {
  position: relative;
  overflow: auto;
  padding: 0;
}

::v-deep(.product-configuration-inner-container) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 2.5rem 0 2.5rem 2rem;
  font-size: 1rem!important;

  @media (max-width: 576px) {
    padding: 0rem;
  }

  p {
    font-size: 1rem!important;
  }

  .main-product-info-container {
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      .product-name {
        display: none;
      }
    }

    .product-name {
      margin-bottom: 0.5rem!important;
    }

    .average-score-container {
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      margin-right: 0;
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      .score {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        font-size: 1rem;
      }
    }

    .product-tagline {
      font-style: italic;
    }

    .product-description {
      --line-clamp: 4;

      display: -webkit-box;
      -webkit-line-clamp: var(--line-clamp);
      line-clamp: var(--line-clamp);
      -webkit-box-orient: vertical;
      hyphens: auto;
      overflow: hidden;
      width: 100%;
    }

    .product-description::before {
      content: '';
      float: right;
      height: calc((var(--line-clamp) - 1) * 1lh);
    }

    .product-description .product-description-show-more {
      clear: both;
      float: right;
      margin-left: 1rem;
      margin-right: 3rem;
      text-decoration: underline;
      color: $mizogoo-gray-dark;
    }
  }

  .feature-name {
    text-transform: uppercase;
  }

  .features-container,
  .quantities-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0 12px;

    &.collapsing {
      display: flex !important;
      flex-wrap: wrap !important;
      gap: 0 12px !important;
    }

    .feature-container,
    .quantity-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.8rem 1.5rem;
      margin-top: 12px;
      background-color: $mizogoo-gray-light;
      transition: background-color 0.2s ease-in-out;
      cursor: pointer;
      border: 2px solid transparent;
      border-radius: 10px;

      &:hover {
        background-color: color.adjust($mizogoo-gray-light, $lightness: -5%);
      }

      &.active-feature-container,
      &.active-quantity-container {
        border-color: $mizogoo;
      }
    }
  }

  .product-price-container {
    margin-top: 1rem;

    .product-price-value {
      margin-left: 0.8rem;
      font-size: 1.3rem;
      font-weight: 600;
    }

    .product-team-for-the-planet {
      margin-top: 1rem;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.product-configuration-buttons-container {
  margin-top: 3rem;

  .product-add-cart-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 1.5rem;
    margin-bottom: 1rem;
    background-color: $mizogoo;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    transition: background-color 0.2s ease-in-out;

    &:hover:not(.disabled-product-button) {
      background-color: color.adjust($mizogoo, $lightness: -5%);
    }

    .product-button-heading {
      font-weight: 500;
    }

    .fas {
      font-size: 1.5rem;
    }
  }

  .disabled-product-button {
    opacity: 0.8;
    cursor: initial;
  }

  .subscription-warning {
    margin: 1rem 0.5rem;
    white-space: normal!important;
    font-size: 0.9rem!important;
    font-style: italic;
    color: #9c9c9c;
  }
}

.loading-overlay,
.loading-prices {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}

.loading-initial {
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-prices {
  backdrop-filter: blur(2px);
}
</style>
