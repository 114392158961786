import MobileAppAPI from "../api/mobile_app";

const
  FETCH_INSTITUTION_OVERVIEW = "FETCH_INSTITUTION_OVERVIEW",
  FETCH_INSTITUTION_OVERVIEW_SUCCESS = "FETCH_INSTITUTION_OVERVIEW_SUCCESS",
  FETCH_INSTITUTION_OVERVIEW_ERROR = "FETCH_INSTITUTION_OVERVIEW_ERROR";

export default {
  namespaced: true,
  state: {
    isCached: false,
    isLoading: false,
    error: null,
    institutionOverview: undefined,
  },
  mutations: {
    [FETCH_INSTITUTION_OVERVIEW] (state) {
      state.isLoading = true;
      state.error = null;
    },
    [FETCH_INSTITUTION_OVERVIEW_SUCCESS] (state, institutionOverview) {
      state.isLoading = false;
      state.error = null;
      state.institutionOverview = institutionOverview;
      state.isCached = true;
    },
    [FETCH_INSTITUTION_OVERVIEW_ERROR] (state, error) {
      state.isLoading = false;
      state.error = error;
      state.institutionOverview = undefined;
    },
  },
  actions: {
    async findInstitutionOverview ({ commit, state }, payload) {
      if (!state.isCached) {
        commit(FETCH_INSTITUTION_OVERVIEW);
        let response = await MobileAppAPI.findInstitutionOverview(payload.institutionId, payload.language);
        if (response.data.success === true) {
          commit(FETCH_INSTITUTION_OVERVIEW_SUCCESS, response.data.institution);
          return response.data.institution;
        } else {
          commit(FETCH_INSTITUTION_OVERVIEW_ERROR);
          return null;
        }
      }
    },
  },
};
