<!-- Password wrapper for the FormInput component (adds requirements and visibility option) -->
<template>
  <FormInput
    :type="passwordVisibility ? 'text' : 'password'"
    :id="id"
    :name="name"
    :rules="rules"
    :newPassword="newPassword"
    v-model="passwordVal"
    :help="help"
  >
    <!-- Display clickable eye icon -->
    <template v-slot:icon>
      <span class="input-icon">
        <i
          :class="[passwordVisibility ? 'fa-eye-slash' : 'fa-eye', 'far']"
          @mousedown="$event.preventDefault()"
          @click="togglePasswordVisibility()"
        ></i>
      </span>
    </template>

    <!-- Display bullet points feedback on the password requirements -->
    <template v-slot:helper v-if="displayRequirements">
      <b-row class="password-requirements">
        <b-col
          v-for="(requirementsColumn, index) in chunkedPasswordRequirements"
          :key="index"
          sm="6"
          xs="12"
        >
          <ul>
            <li
              v-for="requirement in requirementsColumn"
              :key="requirement.id"
              :class="[
                requirement.met ? 'met-requirement' : 'unmet-requirement',
              ]"
            >
              {{ requirement.message }}
            </li>
          </ul>
        </b-col>
      </b-row>
    </template>
  </FormInput>
</template>

<script>
import FormInput from "@mizogoo/components/form/FormInput";

export default {
  name: "FormPassword",
  components: {
    FormInput,
  },
  props: ["id", "name", "rules", "newPassword", "password", "help", "displayRequirements"],
  data() {
    return {
      passwordVisibility: false,
      passwordRequirements: [
        {
          id: 0,
          message: this.$t("workspace.form.passwordRequirements.minSize"),
          regex: /.{8,}/,
          met: false,
        },
        {
          id: 1,
          message: this.$t("workspace.form.passwordRequirements.lowercase"),
          regex: /[a-z]/,
          met: false,
        },
        {
          id: 2,
          message: this.$t("workspace.form.passwordRequirements.uppercase"),
          regex: /[A-Z]/,
          met: false,
        },
        {
          id: 3,
          message: this.$t("workspace.form.passwordRequirements.digit"),
          regex: /\d+/,
          met: false,
        },
        {
          id: 4,
          message: this.$t("workspace.form.passwordRequirements.specialChar"),
          regex: /[^A-z\s\d][\\\^]?/,
          met: false,
        },
      ],
    };
  },
  watch: {
    // Called each time password change to check requirements
    password(newPassword, oldPassword) {
      for (let i = 0; i < this.passwordRequirements.length; i++) {
        let requirement = this.passwordRequirements[i];
        requirement.regex.test(this.passwordVal)
          ? (requirement.met = true)
          : (requirement.met = false);
      }
    },
  },
  computed: {
    // Divide the requirements into 2 separated columns
    chunkedPasswordRequirements() {
      let columns = [];
      let mid = Math.ceil(this.passwordRequirements.length / 2);
      columns.push(this.passwordRequirements.slice(0, mid)); // first column
      columns.push(
        this.passwordRequirements.slice(mid, this.passwordRequirements.length)
      ); // second column
      return columns;
    },
    passwordVal: {
      get() {
        return this.password;
      },
      set(val) {
        this.$emit("update:password", val);
      },
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisibility = !this.passwordVisibility;
    },
  },
};
</script>

<style lang="scss" scoped>
.input-icon {
  cursor: pointer;
}

.password-requirements {
  font-size: 0.8rem;
  font-weight: 400;
  color: $gray-600;

  ul {
    li {
      &:before {
        content: "";
        display: inline-block;
        width: 7px;
        height: 7px;
        border: 1px solid $gray-600;
        border-radius: 50px;
        vertical-align: middle;
        margin-right: 0.5rem;
      }

      &.met-requirement {
        color: $mizogoo-gray-medium;
        text-decoration: line-through;

        &:before {
          border-color: $mizogoo-gray-medium;
        }
      }
    }
  }
}
</style>
