import axios from "axios";
import store from "@mizogoo/store";
import { router } from "@mizogoo/router";

// Add institutionId to "account" API requests
axios.interceptors.request.use((config) => {
  if (
    config.url.includes("/api/account") ||
    config.url.includes("/api/creation") ||
    config.url.includes("/api/grape")
  ) {
    const institutionId = store.getters["security/user"]?.institutions?.find(
      (inst) => inst.isDefault
    )?.id;
    if (institutionId) {
      if (config.method === "get") {
        config.params = { ...config.params, institutionId };
      } else {
        if (config.data instanceof FormData) {
          config.data.append("institutionId", institutionId);
        } else {
          config.data = { ...config.data, institutionId };
        }
      }
    }
  }
  return config;
});

// 401 errors handling (not connected)
axios.interceptors.response.use(
  (response) => {
    const { responseURL } = response.request;
    if (responseURL.endsWith("api/security/login")) {
      if (router.currentRoute.value.name !== "login") {
        router.push({ name: "login" }).catch(() => {});
      }
    }
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      router.push({ name: "login" }).catch(() => {});
    }
    return Promise.reject(error);
  }
);

export default axios;
