import { createStore } from 'vuex'

import AccountAddressModule from './account_address'
import AccountAnalysisModule from './account_analysis'
import AccountBeerModule from './account_beer'
import AccountBeerCategoryModule from './account_beer_category'
import AccountBeerPairingModule from './account_beer_pairing'
import AccountCapacityModule from './account_capacity'
import AccountDishCategoryModule from './account_dish_category'
import AccountDishModule from './account_dish'
import AccountMenuCategoryModule from './account_menu_category'
import AccountMenuModule from './account_menu'
import AccountOfferModule from './account_offer'
import AccountContestModule from './account_contest'
import AccountDrinkCategoryModule from './account_drink_category'
import AccountDrinkModule from './account_drink'
import AccountInstitutionAppPersonalizationModule from './account_institution_app_personalization'
import AccountInstitutionModule from './account_institution'
import AccountInstitutionDocumentModule from './account_institution_document'
import AccountInstitutionQrCodeModule from './account_institution_qrcode'
import AccountInstitutionReviewModule from './account_institution_review'
import AccountModule from './account'
import AccountOnboardingModule from './account_onboarding'
import AccountOrder from './account_order'
import AccountProductCategoryPosition from './account_product_category_position'
import AccountSubscription from './account_subscription'
import AccountWineModule from './account_wine'
import AccountWineCategoryModule from './account_wine_category'
import AccountWinePairingModule from './account_wine_pairing'
import AccountCurrentTabModule from './account_current_tab'
import AllergenModule from './allergen'
import ArticleModule from './article'
import ArticleCategoryModule from './article_category'
import LabelModule from './label'
import LocaleFrontModule from './locale_front'
import BeerColorModule from './beer_color'
import CartElementModule from './cart_element'
import CartModule from './cart'
import ContactForm from './contact_form'
import CreationCategoryModule from './creation_category'
import CreationModule from './creation'
import FinderModule from './finder'
import GlobalModule from './global'
import GrapeModule from './grape'
import HelpCenterModule from './help_center'
import HighlightedMessageModule from './highlighted_message'
import DoItForMeModule from './do_it_for_me'
import PrintMyFileModule from './print_my_file'
import PremiumOnlyModalModule from './premium_only_modal'
import ProductModule from './product'
import ProductReviewModule from './product_review'
import ProductReviewSummaryModule from './product_review_summary'
import PromotionModule from './promotion'
import ReviewModule from './review'
import SecurityModule from './security'
import SubscriptionModule from './subscription'
import SubscriptionModalModule from './subscription_modal'
import WineColorModule from './wine_color'
import StripeModule from './stripe'
import InstitutionHomeModule from './institution_home'
import InstitutionDocumentHomeModule from './institution_document_home'
import InstitutionQrCodeHomeModule from './institution_qrcode_home'
import MobileAppModule from './mobile_app'
import NewsletterSubscriptionModule from './newsletter_subscription'
import WebinarModule from './webinar'

const store = createStore({
  devtools: true,
  modules: {
    beerColor: BeerColorModule,
    wineColor: WineColorModule,
    account: AccountModule,
    accountAddress: AccountAddressModule,
    accountAnalysis: AccountAnalysisModule,
    accountBeer: AccountBeerModule,
    accountBeerCategory: AccountBeerCategoryModule,
    accountBeerPairing: AccountBeerPairingModule,
    accountCapacity: AccountCapacityModule,
    accountDish: AccountDishModule,
    accountDishCategory: AccountDishCategoryModule,
    accountMenu: AccountMenuModule,
    accountMenuCategory: AccountMenuCategoryModule,
    accountOffer: AccountOfferModule,
    accountContest: AccountContestModule,
    accountDrink: AccountDrinkModule,
    accountDrinkCategory: AccountDrinkCategoryModule,
    accountInstitution: AccountInstitutionModule,
    accountInstitutionAppPersonalization: AccountInstitutionAppPersonalizationModule,
    accountInstitutionDocument: AccountInstitutionDocumentModule,
    accountInstitutionQrCode: AccountInstitutionQrCodeModule,
    accountInstitutionReview: AccountInstitutionReviewModule,
    accountOnboarding: AccountOnboardingModule,
    accountOrder: AccountOrder,
    accountProductCategoryPosition: AccountProductCategoryPosition,
    accountSubscription: AccountSubscription,
    accountWine: AccountWineModule,
    accountWineCategory: AccountWineCategoryModule,
    accountWinePairing: AccountWinePairingModule,
    accountCurrentTab: AccountCurrentTabModule,
    allergen: AllergenModule,
    article: ArticleModule,
    articleCategory: ArticleCategoryModule,
    label: LabelModule,
    localeFront: LocaleFrontModule,
    cart: CartModule,
    cartElement: CartElementModule,
    contactForm: ContactForm,
    creation: CreationModule,
    creationCategory: CreationCategoryModule,
    finder: FinderModule,
    global: GlobalModule,
    grape: GrapeModule,
    helpCenter: HelpCenterModule,
    highlightedMessage: HighlightedMessageModule,
    doItForMe: DoItForMeModule,
    printMyFile: PrintMyFileModule,
    premiumOnlyModal: PremiumOnlyModalModule,
    product: ProductModule,
    productReview: ProductReviewModule,
    productReviewSummary: ProductReviewSummaryModule,
    promotion: PromotionModule,
    review: ReviewModule,
    security: SecurityModule,
    subscription: SubscriptionModule,
    subscriptionModal: SubscriptionModalModule,
    stripe: StripeModule,
    institutionHome: InstitutionHomeModule,
    institutionDocumentHome: InstitutionDocumentHomeModule,
    institutionQrCodeHome: InstitutionQrCodeHomeModule,
    mobileApp: MobileAppModule,
    newsletterSubscription: NewsletterSubscriptionModule,
    webinar: WebinarModule,
  },
})

export default store
