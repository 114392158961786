<template>
  <footer id="footer" :class="{ 'd-none': !footerIsVisible }">
    <b-container class="footer-banner-container">
      <b-row align-v="center">
        <b-col md="7" cols="12">
          <div class="heading-container">
            <div v-for="(headingPart, index) in footer.innerBanner.heading" :key="index" class="heading-inner-container">
              <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
            </div>
          </div>
          <div class="footer-banner-button-container">
            <router-link :to="{ name: footer.innerBanner.button.link }" custom v-slot="{ navigate }">
              <button @click="navigate" @keypress.enter="navigate" class="btn btn-white btn-plain-white">
                {{ footer.innerBanner.button.label }}
              </button>
            </router-link>
            <div class="button-extra">
              <b-img :src="require('@mizogoo/assets/img/front/global/decorations/white_arrow.svg')" />
              <span>{{ footer.innerBanner.button.extra }}</span>
            </div>
          </div>
        </b-col>
        <b-col md="5" cols="12" class="d-none d-md-block">
          <b-img
            fluid
            center
            :src="footer.innerBanner.image.src"
            :alt="footer.innerBanner.image.alt"
            class="footer-banner-image"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>

    <b-container>
      <div class="separator" />
    </b-container>

    <b-container class="footer-link-container">
      <b-row class="justify-content-center mb-5">
        <!-- Main info column (logo, address, etc.) -->
        <b-col md="4" sm="12" class="mb-sm-2">
          <div class="mizogoo-logo pt-3 pb-4">
            <router-link
              :to="{ name: 'home' }"
              custom
              v-slot="{ href, navigate }"
            >
              <a :href="href" @click="navigate">
                <b-img :src="mizogooLogoSquare" alt="mizogoo" fluid width="35" />
                <span class="logo-text-part">mizogoo</span>
              </a>
            </router-link>
          </div>
          <div class="contact-address">
            <span>46 rue de la Moselle,</span>
            <span>88190 Golbey. FRANCE</span>
          </div>
          <div class="contact-mail">
            <a href="mailto:contact@mizogoo.fr">contact@mizogoo.fr</a>
          </div>
          <div class="contact-phone">
            03 72 54 00 74
          </div>
          <div class="social mb-4 pt-2">
            <a href="https://www.facebook.com/mizogoo" target="_blank"
              ><i class="fab fa-facebook-f"></i
            ></a>
            <a href="https://www.instagram.com/mizogoofr" target="_blank"
              ><i class="fab fa-instagram"></i
            ></a>
            <router-link target="_blank" :to="{ name: 'blog' }">
              <i class="fas fa-blog"></i>
            </router-link>
            <a href="https://www.youtube.com/channel/UC1fj69bI51r9giRY8DjnK3Q" target="_blank"
              ><i class="fab fa-youtube"></i
            ></a>
          </div>
        </b-col>
        <!-- 2 navigation columns (internal links to main pages) -->
        <b-col
          v-for="nav in footer.navigation"
          :key="nav.id"
          md="2"
          sm="12"
          class="mb-sm-2"
        >
          <h5 class="mb-4">
            {{ nav.heading }}
          </h5>
          <ul>
            <router-link
              v-for="link in nav.links"
              :key="link.id"
              :to="{ name: link.link, hash: link.anchor }"
              custom
              v-slot="{ href, navigate }"
            >
              <li class="mb-1">
                <a :href="href" @click="navigate">{{ link.name }}</a>
              </li>
            </router-link>
          </ul>
        </b-col>
        <!-- Newsletter column -->
        <b-col md="2" sm="12" class="mb-sm-2">
          <h5 class="mb-4">
            {{ $t("front.footer.newsletter.heading") }}
          </h5>
          <div class="mb-3">
            {{ $t("front.footer.newsletter.description") }}
          </div>
          <b-form @submit.stop.prevent="onSubmitEmail">
            <b-form-input
              v-model="email"
              type="email"
              :placeholder="$t('front.footer.newsletter.placeholder')"
              class="footer-newsletter-input"
            ></b-form-input>
            <button type="submit" class="btn footer-btn" :disabled="isLoadingAction">
              {{ $t("front.footer.newsletter.buttonLabel") }}
              <b-spinner v-if="isLoadingAction" small class="ml-1"></b-spinner>
            </button>
          </b-form>
        </b-col>
        <!-- Download app column -->
        <b-col md="2" sm="12" class="mb-sm-2">
          <h5 class="mb-4">
            {{ $t("front.footer.downloadApp.heading") }}
          </h5>
          <div class="google-play-button">
            <a href="https://play.google.com/store/apps/details?id=com.mizogoo_app" target="_blank">
              <i class="fab fa-google-play"></i>
              <div>
                <div class="store-catch-phrase">Get it on</div>
                <div class="store-name">Google Play</div>
              </div>
            </a>
          </div>
          <div class="app-store-button">
            <a href="https://apps.apple.com/fr/app/mizogoo/id1558129706" target="_blank">
              <i class="fab fa-apple"></i>
              <div>
                <div class="store-catch-phrase">Download on the</div>
                <div class="store-name">App Store</div>
              </div>
            </a>
          </div>
        </b-col>
      </b-row>

      <b-row class="legal" align-h="between">
        <b-col md="6" cols="12" class="copy mb-4">
          &copy; {{ fullYear }} mizogoo. {{ footer.legal.copyright }}
        </b-col>

        <b-col md="6" cols="12" class="text-md-right">
          <template v-for="legalLink in footer.legal.links" :key="legalLink.id">
            <div class="d-inline">
              <router-link
                custom
                v-slot="{ href, navigate, isActive, isExactActive }"
                :to="{ name: legalLink.link }"
              >
                <a
                  :href="href"
                  :class="{
                    'router-link-exact-active': isExactActive,
                    'router-link-active': isActive,
                  }"
                  @click="navigate"
                >
                  {{ legalLink.name }}
                </a>
              </router-link>
              <template
                v-if="
                  legalLink.id !=
                  footer.legal.links[footer.legal.links.length - 1].id
                "
              >
                <span>&bull;</span>
              </template>
            </div>
          </template>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      email: null,
    };
  },
  computed: {
    /* Page content */
    footer() {
      return {
        innerBanner: {
          heading: this.$tm("front.footer.innerBanner.heading"),
          button: {
            label: this.$t("front.footer.innerBanner.buttonLabel"),
            link: "workspace",
            extra: this.$t("front.footer.innerBanner.buttonExtra"),
          },
          image: {
            src: require("@mizogoo/assets/img/front/global/footer/footer_inner_banner.png"),
            alt: this.$t("front.footer.innerBanner.imageAlt"),
          }
        },
        navigation: [
          {
            id: 0,
            heading: this.$tm("front.footer.navigation[0].heading"),
            links: [
              {
                id: 0,
                name: this.$t("front.footer.navigation[0].links[0]"),
                link: "create",
              },
              {
                id: 1,
                name: this.$t("front.footer.navigation[0].links[1]"),
                link: "printing",
              },
              {
                id: 2,
                name: this.$t("front.footer.navigation[0].links[2]"),
                link: "app",
              },
              {
                id: 3,
                name: this.$t("front.footer.navigation[0].links[3]"),
                link: "pdf_menu",
              },
              {
                id: 4,
                name: this.$t("front.footer.navigation[0].links[4]"),
                link: "translation",
              },
              {
                id: 5,
                name: this.$t("front.footer.navigation[0].links[5]"),
                link: "analysis",
              },
            ],
          },
          {
            id: 1,
            heading: this.$tm("front.footer.navigation[1].heading"),
            links: [
              {
                id: 0,
                name: this.$t("front.footer.navigation[1].links[0]"),
                link: "subscription",
              },
              {
                id: 1,
                name: this.$t("front.footer.navigation[1].links[1]"),
                link: "contact",
              },
              {
                id: 2,
                name: this.$t("front.footer.navigation[1].links[2]"),
                link: "about_us",
              },
              {
                id: 3,
                name: this.$t("front.footer.navigation[1].links[3]"),
                link: "social_commitments",
              },
              {
                id: 4,
                name: this.$t("front.footer.navigation[1].links[4]"),
                link: "subscription",
                anchor: "#faq",
              },
              {
                id: 5,
                name: this.$t("front.footer.navigation[1].links[5]"),
                link: "blog",
              },
            ],
          },
        ],
        legal: {
          links: [
            {
              id: 0,
              name: this.$t("front.footer.legal.links[0]"),
              link: "legal",
            },
            {
              id: 1,
              name: this.$t("front.footer.legal.links[1]"),
              link: "terms_use",
            },
            {
              id: 2,
              name: this.$t("front.footer.legal.links[2]"),
              link: "terms_sale",
            },
            {
              id: 3,
              name: this.$t("front.footer.legal.links[3]"),
              link: "privacy",
            },
          ],
          copyright: this.$t("front.footer.legal.copyright"),
        },
      };
    },
    fullYear() {
      const date = new Date();
      return date.getFullYear();
    },
    footerIsVisible() {
      return this.frontFooter.visible;
    },
    isLoadingAction() {
      return this.$store.getters["newsletterSubscription/isLoadingAction"];
    },
    errorAction() {
      return this.$store.getters["newsletterSubscription/errorAction"];
    },
    hasErrorAction() {
      return this.errorAction !== null;
    },
  },
  methods: {
    async onSubmitEmail() {
      const response = await this.$store.dispatch(
        "newsletterSubscription/create",
        { form: { email: this.email} }
      );

      this.email = null;
      this.toast(response.message);
    }
  }
};
</script>

<style lang="scss" scoped>
/* General */
footer {
  position: relative;
  background: $mizogoo-gray-dark;
  color: $footer-font-color;
  width: 100%;
  padding: 1rem;
  font-size: 0.85rem;
  font-weight: 400;
  overflow: hidden;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;

  .footer-banner-container {
    padding-top: 2rem;

    .heading-container {
      margin-bottom: 4rem!important;
      color: #fff;
    }

    .footer-banner-button-container {
      position: relative;
      display: inline-block;
      
      .btn-plain-white {
        background-color: #fff;
        color: $mizogoo-gray-dark!important;
      }

      .button-extra {
        img {
          position: absolute;
          right: -95px;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 55px;
        }

        span {
          position: absolute;
          right: -170px;
          top: -35px;
          width: 120px;
          font-family: Segoe Script;
          font-size: 0.8rem;
          color: #fff;
        }
      }
    }

    .footer-banner-image {
      margin-top: -9rem;
    }
  }

  .separator {
    background-color: $footer-font-color!important;
  }

  .footer-link-container {
    /* Logo */
    .mizogoo-logo {
      padding-bottom: 2rem !important;
    }

    .logo-text-part {
      display: inline-block;
      vertical-align: middle;
      font-family: "SF New Republic";
      font-size: 1.8rem;
      font-weight: 600;
      color: $white;
    }

    .logo-text-part::first-letter {
      color: $mizogoo;
    }

    .contact-address {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.8rem;
      font-weight: 500;
    }

    .contact-mail {
      margin-bottom: 0.8rem;
      text-decoration: underline;

      a {
        font-weight: 500;
      }
    }

    .contact-phone {
      margin-bottom: 1.2rem;
      font-weight: 500;
      color: #fff;
    }

    /* Social media icons */
    .social {
      display: flex;
      gap: 10px;

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        height: 25px;
        width: 25px;
        background-color: #1F2832;
        font-size: 1rem;
        cursor: pointer;

        &:hover {
          color: $white!important;
          transition: 0.2s ease-in-out 0s;
        }

        i {
          vertical-align: initial;
        }
      }
    }

    .google-play-button,
    .app-store-button {
      display: flex;
      align-items: center;
      max-width: 220px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1rem;
      border-radius: 5px;
      background-color: #1F2832;
      cursor: pointer;
      transition: all 0.2s ease-in-out;

      a {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        border-radius: 5px;

        &:hover {
          background-color: $footer-font-color!important;
          color: #fff!important;
        }
      }

      i {
        font-size: 1.5rem;
        margin: 0 0.5rem;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 1.2;

        .store-catch-phrase {
          font-size: 0.8rem;
        }

        .store-name {
          font-size: 1.1rem;
          font-weight: 600;
        }
      }
    }

    .google-play-button {
      .store-catch-phrase {
        text-transform: uppercase;
      }
    }
  }
}

h5 {
  font-size: 1.2rem;
  color: $white;
}

a {
  color: $footer-font-color;
  transition: all 0.2s ease-in-out;
}

a:hover {
  color: #fff;
  text-decoration: none;
}

li {
  padding: 0.1rem 0;
}

.footer-newsletter-input {
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  max-height: 40px;
  border: 2px solid $footer-font-color;
  outline: none !important;
  background-color: transparent;
  font-size: 0.9rem;
  color: #fff !important;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &:focus {
    background-color: transparent !important;
    border-color: #fff;
  }
}

.footer-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  max-height: 40px;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #fff;
  background-color: $footer-font-color;
  border: none!important;
  border-radius: 4px!important;

  &:hover {
    background-color: color.adjust($footer-font-color, $lightness: -10%);
  }
}

.legal,
.copy {
  font-size: 0.85rem;
}

.legal {
  padding-bottom: 0.4rem;
  justify-content: space-between;
}

.legal a {
  display: inline-block;
  margin: 0 0.4rem;
  font-size: 0.85rem;
  height: 0.8rem;
  line-height: 0.8rem;
}

.legal a:hover {
  text-decoration: underline;
  color: $white;
}

/* Media queries - adjusting category spacing */
@media screen and (max-width: 768px) {  
  footer {
    text-align: center;

    h5 {
      margin-top: 2.5rem;
      margin-bottom: 1rem !important;
    }

    .footer-banner-container {

      .heading-container {
        margin-bottom: 2rem;
        text-align: center;
      }

      .footer-banner-button-container {
        .button-extra {
          display: flex;
          justify-content: center;
          margin-top: 1rem;

          img {
            position: initial;
            transform: rotate(0);
            -moz-transform: scale(-1, 1);
            -webkit-transform: scale(-1, 1);
            -o-transform: scale(-1, 1);
            -ms-transform: scale(-1, 1);
            transform: scale(-1, 1);
          }
          
          span {
            position: initial;
          }
        }
      }
    }

    .social {
      justify-content: center;
      padding-top: 1.5rem;
      padding-bottom: 2rem;
    }
  }
}

@media screen and (max-width: 576px) {
  footer {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }
}
</style>
