<template>
  <b-container fluid class="feature-presentation">
    <b-container>
      <b-row align-v="center" align-h="center">
        <b-col class="text-col" md="6" sm="12" :offset-md="reverse ? 1 : 0" :order="1" :order-md="reverse ? 2 : 1">
          <div class="inner-container">
            <h3 v-scrollanimation="{name: 'fade-up-appear'}">{{ subheading }}</h3>
            <div class="heading-container">
              <div v-for="(headingPart, index) in heading" :key="index" class="heading-inner-container">
                <h2 v-scrollanimation="{name: 'fade-up-appear'}">{{ headingPart }}</h2>
              </div>
            </div>
            <div class="descriptions-container">
              <div
                v-for="(section, index) in description"
                :key="index"
                class="description-container"
                @mouseover="setActiveFeatureId(section.id)"
                v-scrollanimation="{name: 'fade-up', delay: 150 + 100 * index}"
              >
                <div :class="[{'active': section.id === activeFeatureId} , 'description-id']">
                  <div class="description-id-background"></div>
                  <div class="description-id-text">
                    <span>0</span>
                    <span>{{ section.id + 1 }}</span>
                    <span class="dot">.</span>
                  </div>
                </div>
                <div class="description-text">
                  <h5>{{ section.heading }}</h5>
                  <p>{{ section.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col class="image-col" :offset-md="reverse ? 0 : 1" md="5" sm="12" :order="2" :order-md="reverse ? 1 : 2">
          <div class="image-col-inner-container">
            <b-img-lazy
              fluid
              center
              :src="activeFeature.image.src"
              :alt="activeFeature.image.alt"
            ></b-img-lazy>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "FeaturesPresentation",
  props: ["heading", "subheading", "description", "reverse"],
  data() {
    return {
      activeFeatureId: 0,
    }
  },
  computed: {
    activeFeature() {
      return this.description.find(
        (section) => section.id == this.activeFeatureId
      );
    }
  },
  methods: {
    setActiveFeatureId(id) {
      this.activeFeatureId = id;
    },
  },
}
</script>

<style lang="scss" scoped>
.feature-presentation {
  margin-top: 5rem;
  margin-bottom: 8rem;

  .text-col {
    .descriptions-container {
      margin-top: 3rem;

      .description-container {
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        .description-id {
          position: relative;
          display: flex;
          flex: 0 0 70px;
          justify-content: center;
          align-items: center;
          width: 70px;
          height: 70px;
          margin-top: 0.5rem;
          margin-right: 1.5rem;
          font-size: 1.4rem;
          font-weight: bold;
          z-index: 2;

          .description-id-background {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: $mizogoo-gray-light;
            border-radius: 10px;
            z-index: 2;
          }

          .description-id-text {
            display: flex;
            z-index: 3;
          }

          &::after {
            content: "";
            position: absolute;
            width: 95%;
            height: 95%;
            left: 0px;
            bottom: 0px;
            background-color: $mizogoo;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 12px;
            z-index: 1;
            transition: 0.2s ease-in-out;
          }

          &.active::after {
            transform: translate(-5px, 5px);
          }

          .dot {
            color: $mizogoo;
          }
        }

        .description-text {
          display: flex;
          flex-direction: column;

          h5 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .image-col {
    padding-top: 2rem;
    padding-bottom: 1rem;
    background-color: #f8f8f8;
    border-radius: 20px;
    z-index: 2;

    .image-col-inner-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        border-radius: 15px;
        max-width: 300px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 45%;
        width: 100%;
        height: 70%;
        background-color: $mizogoo-gray-light;
        border-radius: 20px;
        z-index: -1;
      }

      &::after {
        content: url(../../../../assets/img/front/global/decorations/dotted_decoration_small_extended.svg);
        position: absolute;
        top: -35px;
        z-index: -2;
      }
    }
  }
}

@media screen and (max-width: 576px) {
  .image-col-inner-container {
    &::before,
    &::after {
      display: none;
    }
  }
}
</style>
