<template>
  <div>
    <top-banner
      :heading="this.topBanner.heading"
      :description="this.topBanner.description"
    >
    </top-banner>

    <b-container fluid class="product-container">
      <!-- Loading -->
      <template v-if="isLoadingProductCategories">
        <div class="loading-container">
          <b-spinner></b-spinner>
        </div>
      </template>

      <template v-else>
        <b-row>
          <!-- Category filters -->
          <b-col md="3" class="pl-0">
            <div class="category-filter-container">
              <div class="absolute-decoration-dotted-red"></div>
              <div class="category-filter-inner-container">
                <h4 class="category-filter-header">{{ $t('front.catalog.categories') }}</h4>
                <div class="category-filters">
                  <b-form-group>
                    <b-form-checkbox-group
                      v-model="selectedCategories"
                      stacked
                    >
                      <b-form-checkbox
                        v-for="category in optionProductCategories"
                        :key="category.id"
                        :value="category.id"
                        class="mb-3"
                      >
                        {{ category.name }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </b-col>

          <b-col>
            <div class="product-list-container">
              <!-- Search bar -->
              <div class="product-search-bar">
                <b-input-group class="icon-inside">
                  <b-form-input
                    type="text"
                    v-model="search"
                    :placeholder="this.$t('front.catalog.searchProducts')"
                    class="inputWithIcon"
                  >
                  </b-form-input>
                  <span class="input-icon">
                    <i class="fas fa-search"></i>
                  </span>
                </b-input-group>
              </div>

              <!-- Product list -->
              <div v-if="!isLoadingProductCategories">
                <b-row class="products-container">
                  <b-col
                    v-for="product in filteredProducts"
                    :key="product.id"
                    lg="4"
                    md="6"
                  >
                    <CatalogItem
                      :id="product.id"
                      :image="productImage(product)"
                      :name="product.name"
                      :description="product.description"
                      :displayPrice="product.isClassic || product.isPrintable"
                      :minProductPrice="product.minProductPrice"
                      :urlName="product.urlName"
                    ></CatalogItem>
                  </b-col>
                </b-row>
              </div>
            </div>
          </b-col>
        </b-row>
      </template>
    </b-container>

    <internal-links
      :subheading="this.internalLinks.subheading"
      :heading="this.internalLinks.heading"
      :buttons="this.internalLinks.buttons"
    ></internal-links>
  </div>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import TopBanner from "@mizogoo/components/front/layout/TopBanner";
import CatalogItem from "@mizogoo/components/front/layout/products/catalog/CatalogItem";
import InternalLinks from "@mizogoo/components/front/layout/InternalLinks";

export default {
  mixins: [MetaMixin],
  name: "CatalogPage",
  components: {
    TopBanner,
    CatalogItem,
    InternalLinks,
  },
  data() {
    return {
      urlCategoryParam: null,
      selectedCategories: [],
      search: "",
    };
  },
  methods: {
    productImage(product) {
      return {
        src: product.file.thumbnails['576_576'],
        alt: product.name
      }
    },
    selectCategory() {
      const category = this.productCategories.find((productCategory) =>
        productCategory.name.toLowerCase().replace(/\s+/g, "-") === this.urlCategoryParam
      );
      if (category) {
        this.selectedCategories.push(category.id);
      }
    },
    waitForProductCategories() {
      return new Promise((resolve) => {
        const checkCategories = () => {
          if (this.productCategories.length > 0) {
            resolve();
          } else {
            setTimeout(checkCategories, 100);
          }
        };
        checkCategories();
      });
    },
  },
  computed: {
    metaTitle() {
      return this.$t("front.metadata.catalog.title");
    },
    metaDescription() {
      return this.$t("front.metadata.catalog.description");
    },
    // Product Category
    isLoadingProductCategories() {
      return this.$store.getters["product/isLoadingProductCategories"];
    },
    hasErrorProductCategory() {
      return this.$store.getters["product/hasErrorProductCategory"];
    },
    errorProductCategories() {
      return this.$store.getters["product/errorProductCategories"];
    },
    hasProductCategories() {
      return this.$store.getters["product/hasProductCategories"];
    },
    productCategories() {
      return this.$store.getters["product/productCategories"];
    },
    optionProductCategories() {
      let options = [];

      for (let productCategory of this.productCategories) {
        options.push({
          id: productCategory.id,
          name: productCategory.name,
          value: productCategory.id,
        });
      }

      return options
    },
    // Product
    products() {
      return this.$store.getters["product/products"]
    },
    filteredProducts() {
      let filteredProducts = [];
      if (this.selectedCategories.length > 0) {
        for (let i = 0; i < this.products.length; i++) {
          for (let j = 0; j < this.selectedCategories.length; j++) {
            if (this.products[i].productCategoryIds.includes(this.selectedCategories[j])) {
              if (this.search != "") {
                if (this.slugify(this.products[i].name).match(this.searchRegex(this.search))) {
                  filteredProducts.push(this.products[i]);
                }
              } else {
                filteredProducts.push(this.products[i]);
              }
            }
          }
        }
        return filteredProducts;
      } else {
        if (this.search != "") {
          return this.products
            .filter((product) => {
              return this.slugify(product.name).match(this.searchRegex(this.search));
            });
        } else {
          return this.products;
        }
      }
    },
    // Page content
    topBanner() {
      return {
        heading: this.$tm("front.catalog.topBanner.heading"),
        description: this.$t("front.catalog.topBanner.description"),
      };
    },
    internalLinks() {
      return {
        subheading: this.$t("front.internalLinks.subheading"),
        heading: this.$tm("front.internalLinks.heading"),
        buttons: [
          {
            id: 0,
            link: "create",
            label: this.$t("front.internalLinks.buttons.create"),
          },
          {
            id: 1,
            link: "printing",
            label: this.$t("front.internalLinks.buttons.print"),
          },
          {
            id: 2,
            link: "analysis",
            label: this.$t("front.internalLinks.buttons.analysis"),
          },
          {
            id: 3,
            link: "app",
            label: this.$t("front.internalLinks.buttons.app"),
          },
          {
            id: 4,
            link: "pdf_menu",
            label: this.$t("front.internalLinks.buttons.pdfMenu"),
          },
        ]
      };
    },
  },
  async mounted() {
    if (!this.$route.params.category) return;

    // If the categories are not yet available, wait for them to load
    if (this.productCategories.length === 0) {
      await this.waitForProductCategories();
    }

    // Search for the correct category
    const category = this.productCategories.find((productCategory) =>
      productCategory.name.toLowerCase().split(' ').join('-') === this.$route.params.category
    );

    // If the category is found, select it
    if (category) {
      this.selectedCategories = [category.id];
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.top-banner) {
  padding-bottom: 20rem;

  .top-banner-text {
    padding-bottom: 0;

    .container {
      padding-bottom: 8rem;
    }
  }
}

.product-container {
  padding-top: 4rem;
  min-height: 50vh;
  background-color: $background-color;

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // Category filters
  .category-filter-container {
    position: relative;

    .absolute-decoration-dotted-red {
      content: url(../../../../assets/img/front/global/decorations/doted_decoration_colored.svg);
      position: absolute;
      bottom: -165px;
      left: 55px;
      transform: rotate(90deg) translate(100%, 0);
      transform-origin: 100% 0;
      z-index: 0;
    }

    .category-filter-inner-container {
      margin-bottom: 8rem;
      padding: 4rem;
      background-color: $mizogoo-gray-light;
      position: relative;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      z-index: 1;

      .category-filter-header {
        margin-bottom: 2rem;
        font-size: 1.6rem !important;
      }

      .category-filters {
        padding-left: 1rem;
      }
    }
  }

  // Product List
  .product-list-container {
    padding-left: 2rem;
    padding-right: 3rem;

    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }

    .product-search-bar {
      margin-bottom: 2rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    .products-container {
      display: flex;
      flex-wrap: wrap;

      [class*="col-"] {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
