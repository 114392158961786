<template>
  <div>
    <div v-if="isLoading" class="absolute-loading">
      <b-row align-v="center" class="d-flex flex-1">
        <b-col cols="12" align-self="center" class="text-center">
          <b-spinner :label="$t('workspace.global.loading')"></b-spinner>
        </b-col>
      </b-row>
    </div>

    <div v-else>
      <b-row v-if="hasError">
        <b-col>
          <FlashBag v-if="hasErrorInstitutionHome" :message="errorInstitutionHome" />
          <FlashBag v-if="hasErrorInstitutionQrCode" :message="errorInstitutionQrCode" />
          <FlashBag
            v-if="hasErrorFetchAllInstitutionDocument"
            :message="errorFetchAllInstitutionDocument"
          />
        </b-col>
      </b-row>

      <template v-else>
        <InstitutionHomePageView
          v-if="institution && institutionQrCode"
          :isPreview="false"
          :hash="$route.params.hash"
          :isPremium="institution.isPremium"
          :company="institution.name"
          :institutionDocuments="institutionDocuments"
          :logo="institutionQrCode.displayLogo ? institution.logo : null"
          :title="institutionQrCode.title"
          :subTitle="institutionQrCode.subTitle"
          :backgroundColor="institutionQrCode.backgroundColor"
          :titleColor="institutionQrCode.titleColor"
          :subTitleColor="institutionQrCode.subTitleColor"
          :buttonBackgroundColor="institutionQrCode.buttonBackgroundColor"
          :buttonTextColor="institutionQrCode.buttonTextColor"
          :displayAds="institutionQrCode.displayAds"
        />
      </template>
    </div>
  </div>
</template>

<script>
import FlashBag from "@mizogoo/components/FlashBag";
import InstitutionHomePageView from "@mizogoo/components/qrcode/pages/InstitutionHomePage/View";

export default {
  name: "InstitutionHomePage",
  components: {
    FlashBag,
    InstitutionHomePageView,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    // Global
    hasError() {
      return (
        this.hasErrorInstitutionHome ||
        this.hasErrorFetchOneInstitutionQrCode ||
        this.hasErrorFetchAllInstitutionDocument
      );
    },
    // Institution Home
    isLoadingInstitutionHome() {
      return this.$store.getters["institutionHome/isLoadingInstitution"];
    },
    hasErrorInstitutionHome() {
      return this.$store.getters["institutionHome/hasErrorInstitution"];
    },
    errorInstitutionHome() {
      return this.$store.getters["institutionHome/errorInstitution"];
    },
    institution() {
      return this.$store.getters["institutionHome/institution"];
    },
    // Fetch One InstitutionQrCode
    institutionQrCode() {
      return this.$store.getters["institutionQrCodeHome/institutionQrCode"];
    },
    isLoadingInstitutionQrCode() {
      return this.$store.getters["institutionQrCodeHome/isLoadingInstitutionQrCode"];
    },
    hasErrorInstitutionQrCode() {
      return this.$store.getters["institutionQrCodeHome/hasErrorInstitutionQrCode"];
    },
    errorInstitutionQrCode() {
      return this.$store.getters["institutionQrCodeHome/errorInstitutionQrCode"];
    },
    // Fetch All Institution Document
    isLoadingFetchAllInstitutionDocument() {
      return this.$store.getters["institutionDocumentHome/isLoadingFetchAll"];
    },
    hasErrorFetchAllInstitutionDocument() {
      return this.$store.getters["institutionDocumentHome/hasErrorFetchAll"];
    },
    errorFetchAllInstitutionDocument() {
      return this.$store.getters["institutionDocumentHome/errorFetchAll"];
    },
    hasInstitutionDocuments() {
      return this.$store.getters["institutionDocumentHome/hasInstitutionDocuments"];
    },
    institutionDocuments() {
      return this.$store.getters["institutionDocumentHome/institutionDocuments"];
    },
  },
  async created() {
    this.isLoading = true;

    await this.$store.dispatch("institutionHome/fetchInstitution", {
      hash: this.$route.params.hash,
    });

    if (this.institution) {
      await Promise.all([
        await this.$store.dispatch("institutionDocumentHome/findAll", {
          institutionId: this.institution.id,
        }),
        await this.$store.dispatch("institutionQrCodeHome/findOne", {
          institutionQrCodeId: this.institution.institutionQrCodeId,
        }),
      ]);
    } else {
      return this.$router.push({ name: "home" }).catch(() => {});
    }

    this.isLoading = false;
  },
};
</script>

<style lang="scss" scoped>
.absolute-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
