<template>
  <b-container class="secondary-page-container">
    <b-row>
      <b-col cols="12" class="mb-4">
        <h1 class="text-center">{{ $t('front.promotion.index.heading') }}</h1>
        <p>{{ $t('front.promotion.index.subHeading') }}</p>
      </b-col>
    </b-row>

    <template v-if="isLoading">
      <b-row class="text-center">
        <b-col cols="12" class="mt-4">
          <b-spinner></b-spinner>
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <template v-if="hasError">
        <FlashBag v-if="hasError" :message="error"/>
      </template>

      <template v-else>
        <template v-if="hasPromotion">
          <b-row>
            <b-col
                md="4"
                v-for="promotion in promotions"
                :key="'promotion-' + promotion.id">
              <b-card
                  class="mizogoo-card text-center">
                <b-card-title>
                  {{ promotion.name }}
                </b-card-title>
                <b-card-text>
                  <p class="font-weight-bolder m-0">{{ promotion.code }}</p>
                  {{ promotion.description }}
                </b-card-text>
                <b-card-footer>
                  <p style="font-size: 0.7rem !important;" v-html="promotion.conditionOfUse"></p>
                  <router-link :to="{ name: 'promotion', params: {id: promotion.id, code: slugify(promotion.code)} }" class="more-info-link">
                    <span>{{ $t('workspace.global.learnMore') }}</span>
                  </router-link>
                </b-card-footer>
              </b-card>
            </b-col>
          </b-row>

        </template>
        <template v-else>
          Aucune promotions disponibles pour le moment.
        </template>
      </template>
    </template>

  </b-container>
</template>

<script>
import MetaMixin from "@mizogoo/mixins/meta.js";
import Loading from '@mizogoo/components/workspace/layout/Loading'
import FlashBag from '@mizogoo/components/FlashBag'

export default {
  mixins: [MetaMixin],
  name: 'PromotionPage',
  components: {
    Loading,
    FlashBag,
  },
  computed: {
    metaTitle() {
      return this.$t('front.metadata.promotion.index.title');
    },
    metaDescription() {
      return this.$t('front.metadata.promotion.index.description');
    },
    // Fetch All
    isLoading () {
      return this.$store.getters['promotion/isLoading']
    },
    hasError () {
      return this.$store.getters['promotion/hasError']
    },
    error () {
      return this.$store.getters['promotion/error']
    },
    promotions () {
      return this.$store.getters['promotion/promotions']
    },
    hasPromotion () {
      return this.$store.getters['promotion/hasPromotions']
    },
  },
  async created () {
    await Promise.all([
      this.$store.dispatch('promotion/findAll'),
    ])
  },
  mounted () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', false)
  },
  beforeUnmount () {
    this.$store.dispatch('global/fetchFrontHeaderTransparent', true)
  },
}
</script>

<style lang="scss" scoped>
</style>
