import Vue from "vue";
import AccountBeerCategoryAPI from "../api/account_beer_category";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  TOGGLE_BEERS_ACTIVATION = "TOGGLE_BEERS_ACTIVATION",
  TOGGLE_BEERS_ACTIVATION_SUCCESS = "TOGGLE_BEERS_ACTIVATION_SUCCESS",
  TOGGLE_BEERS_ACTIVATION_ERROR = "TOGGLE_BEERS_ACTIVATION_ERROR",
  
  ADD_ITEM_INTO_CATEGORY = "ADD_ITEM_INTO_CATEGORY",
  REMOVE_ITEM_FROM_CATEGORY = "REMOVE_ITEM_FROM_CATEGORY",
  
  ADD_CATEGORY = "ADD_CATEGORY",
  
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    // Cache
    isCached: false,
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    beerCategories: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Updating
    isLoadingUpdating: false,
    errorUpdating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Analysing
    isLoadingAnalysing: false,
    errorAnalysing: null,
    // Toggle Beers Activation
    isLoadingToggleBeersActivation: false,
    errorToggleBeersActivation: null,
  },
  getters: {
    // Fetch All
    isLoadingFetchAll(state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll(state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll(state) {
      return state.errorFetchAll;
    },
    hasBeerCategories(state) {
      return state.beerCategories.length > 0;
    },
    beerCategories(state) {
      return state.beerCategories;
    },
    // Fetch One
    isLoadingFetchOne(state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne(state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne(state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating(state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating(state) {
      return state.errorCreating !== null;
    },
    errorCreating(state) {
      return state.errorCreating;
    },
    // Updating
    isLoadingUpdating(state) {
      return state.isLoadingUpdating;
    },
    hasErrorUpdating(state) {
      return state.errorUpdating !== null;
    },
    errorUpdating(state) {
      return state.errorUpdating;
    },
    // Removing
    isLoadingRemoving(state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving(state) {
      return state.errorRemoving !== null;
    },
    errorRemoving(state) {
      return state.errorRemoving;
    },
    // Analysing
    isLoadingAnalysing(state) {
      return state.isLoadingAnalysing;
    },
    hasErrorAnalysing(state) {
      return state.errorAnalysing !== null;
    },
    errorAnalysing(state) {
      return state.errorAnalysing;
    },
    // Toggle Beers Activation
    isLoadingToggleBeersActivation (state) {
      return state.isLoadingToggleBeersActivation;
    },
    hasErrorToggleBeersActivation (state) {
      return state.errorToggleBeersActivation !== null;
    },
    errorToggleBeersActivation (state) {
      return state.errorToggleBeersActivation;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.beerCategories = [];
    },
    [FETCH_ALL_SUCCESS](state, beerCategories) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.beerCategories = beerCategories;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.beerCategories = [];
    },
    // Fetch One
    [FETCH_ONE](state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS](state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR](state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING](state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS](state, beerCategory) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
      state.beerCategories.push(beerCategory);
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Updating
    [UPDATING](state) {
      state.isLoadingUpdating = true;
      state.errorUpdating = null;
    },
    [UPDATING_SUCCESS](state, beerCategory) {
      state.isLoadingUpdating = false;
      state.errorUpdating = null;
      let beerCategoryToUpdate = state.beerCategories.find(element => element.id === beerCategory.id);
      beerCategoryToUpdate.translations = beerCategory.translations;
    },
    [UPDATING_ERROR](state, error) {
      state.isLoadingUpdating = false;
      state.errorUpdating = error;
    },
    // Removing
    [REMOVING](state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS](state, beerCategory) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.beerCategories = state.beerCategories.filter(element => element.id !== beerCategory);
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Toggle Beers Activation
    [TOGGLE_BEERS_ACTIVATION] (state) {
      state.isLoadingToggleBeersActivation = true;
      state.errorToggleBeersActivation = null;
    },
    [TOGGLE_BEERS_ACTIVATION_SUCCESS] (state, payload) {
      state.isLoadingToggleBeersActivation = false;
      state.errorToggleBeersActivation = null;

      const beersFromCategory = this.getters['accountBeer/beers'].filter(element => element.beerCategoryId === payload.beerCategory.id);

      for (let i = 0; i < beersFromCategory.length; i++) {
        let beerToActivate = beersFromCategory[i];
        beerToActivate.active = payload.value;
      }
    },
    [TOGGLE_BEERS_ACTIVATION_ERROR] (state, error) {
      state.isLoadingToggleBeersActivation = false;
      state.errorToggleBeersActivation = error;
    },
    // Add item into category
    [ADD_ITEM_INTO_CATEGORY] (state, beer) {
      let beerCategoryToUpdate = state.beerCategories.find(element => element.id === beer.beerCategoryId);
      beerCategoryToUpdate.beers.push(beer);
    },
    // Remove item from category
    [REMOVE_ITEM_FROM_CATEGORY] (state, beer) {
      let beerCategoryToUpdate = state.beerCategories.find(element => element.id === beer.beerCategoryId);
      let beerToRemoveIndex = beerCategoryToUpdate.beers.findIndex(element => element.id === beer.id);
      beerCategoryToUpdate.beers.splice(beerToRemoveIndex, 1);
    },
    // Add category (used when a new category is created via import)
    [ADD_CATEGORY] (state, beerCategory) {
      state.beerCategories.push(beerCategory);
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.beerCategories = [];
    },
  },
  actions: {
    async findAll({ commit }) {
      if (!this.state.accountBeerCategory.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountBeerCategoryAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.beerCategories);
            return response.data.beerCategories;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async findOne({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountBeerCategoryAPI.findOne(payload.beerCategory);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.beerCategory;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountBeerCategoryAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.beerCategory);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountBeerCategoryAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, response.data.beerCategory);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountBeerCategoryAPI.remove(payload.beerCategory);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.beerCategory);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async toggleBeersActivation ({ commit }, payload) {
      commit(TOGGLE_BEERS_ACTIVATION);
      try {
        let response = await AccountBeerCategoryAPI.toggleBeersActivation(payload.form);
        if (response.data.success === true) {
          commit(TOGGLE_BEERS_ACTIVATION_SUCCESS, payload.form);
          return response.data;
        } else {
          commit(TOGGLE_BEERS_ACTIVATION_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLE_BEERS_ACTIVATION_ERROR, error);
        return null;
      }
    },
    async addItemIntoCategory ({ commit }, payload) {
      commit(ADD_ITEM_INTO_CATEGORY, payload.beer);
    },
    async removeItemFromCategory ({ commit }, payload) {
      commit(REMOVE_ITEM_FROM_CATEGORY, payload.beer);
    },
    async addCategory ({ commit }, payload) {
      commit(ADD_CATEGORY, payload.beerCategory);
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  }
}
