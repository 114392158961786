<template>
  <b-container
    class="do-it-for-me-container"
    :style="{ paddingTop: frontHeader.height + 40 + 'px', minHeight: '100vh' }"
  >
    <b-row v-if="hasError">
      <b-col>
        <FlashBag v-if="hasErrorAttribut" :message="errorAttributs"/>
      </b-col>
    </b-row>

    <Loading v-if="isLoading"/>

    <template v-else>
      <b-row>
        <b-col>
          <!-- Return -->
          <b-row>
            <b-col>
              <a class="return-button" @click="backToSteps()">
                <i class="fas fa-arrow-left"></i>
                <span>{{ $t("workspace.global.return") }}</span>
              </a>
            </b-col>
          </b-row>

          <h2 class="">{{ $t("front.product.doItForMe") }}</h2>
          <div class="mb-4">{{ $tm('workspace.creation.form.commentsExplanationsMockup').join("") }}</div>

          <!-- Summary -->
          <b-row class="mb-4">
            <b-col>
              <h4 class="mt-2 mb-3">1. {{ $t('front.product.summary') }}</h4>
              <ul v-if="savedProductSettings.productFeatureValues">
                <li
                  v-for="productFeatureValue in savedProductSettings.productFeatureValues"
                  :key="productFeatureValue.id"
                >
                  <strong class="mr-2">{{ productFeatureValue.feature.name }}</strong>{{ productFeatureValue.value }}
                </li>
                <template v-for="(attributValue, index) in savedProductSettings.productAttributeValues">
                  <li v-if="attributValue" :key="index">
                    <strong class="mr-2">{{ getAttributeNameFromId(attributValue.attributId) }}</strong>{{ attributValue.name }}
                    <template v-if="attributValue.priceHt > 0">
                      (+{{ formatPrice(attributValue.priceHt) }} {{ $t("front.product.vatExcluded") }})
                    </template>
                  </li>
                </template>
                <template v-if="savedProductSettings.productIsPrintable || savedProductSettings.productIsDeliverable">
                  <li><strong class="mr-2">{{ $t("front.product.quantity") }}</strong>{{ savedProductSettings.productQuantity }} ({{ formatPrice((savedProductSettings.productPrice) / savedProductSettings.productQuantity) }} {{ $t("front.product.theUnit") }} {{ $t("front.product.vatExcluded") }})</li>
                </template>
                <li v-if="savedProductSettings.productPrice">
                  <strong class="mr-2">{{ $t("front.product.productPrice") }}</strong>
                  {{ formatPrice(savedProductSettings.productPrice + mockupPrice) }}
                </li>
              </ul>
            </b-col>
          </b-row>

          <!-- Comments -->
          <b-row class="mb-4">
            <b-col>
              <h4 class="mt-2 mb-3">2. {{ $t('workspace.creation.form.comments') }}</h4>
              <FormTextarea
                id="comments"
                v-model="comments"
                rows="3"
                maxRows="3"
              />
            </b-col>
          </b-row>

          <!-- Attachments -->
          <b-row class="mb-4">
            <b-col>
              <h4 class="mt-2 mb-2">3. {{ $t("workspace.creation.attachedFiles") }}</h4>
              <div class="mb-3">{{ $tm("workspace.creation.form.attachmentsExplanationsMockup").join("") }}</div>

              <FormDropzone
                mode="do-it-for-me"
                :dataId="tmpUserId"
                :extensions="['doc', 'docx', 'pdf']"
                :maxFileSize="50"
                :autoUpload="true"
                @onUpload="onUploadAttachment"
              />

              <ul class="list-group related-docs mb-2">
                <li
                  v-for="document in attachedFiles"
                  :key="document.id"
                >
                  <a
                    :href="document.src"
                    target="_blank"
                    class="list-group-item"
                  >
                    <img :src="document.icon" class="file-icon" alt="icon"/>
                    {{ document.name }}
                  </a>
                </li>
              </ul>
            </b-col>
          </b-row>

          <!-- Validation button (puts the product into the cart) -->
          <b-row class="mb-4">
            <b-col>
              <button
                class="btn btn-primary"
                @click.prevent.stop="onClickAddCart()"
                :disabled="isLoadingAction"
              >
                {{ $t("workspace.global.addToCart") }}
                <b-spinner
                  v-if="isLoadingAction"
                  small
                  class="ml-1"
                ></b-spinner>
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import { mapState } from "vuex";
import FlashBag from '@mizogoo/components/FlashBag'
import Loading from "@mizogoo/components/workspace/layout/Loading"
import FormTextarea from "@mizogoo/components/form/FormTextarea"
import FormDropzone from "@mizogoo/components/form/FormDropzone"

export default {
  name: "ProductPageDoItForMe",
  components: {
    FlashBag,
    Loading,
    FormTextarea,
    FormDropzone,
  },
  data() {
    return {
      tmpUserId: null,
      isLoadingSavedProductSettings: true,
      savedProductSettings: null,
      mockupPrice: null,
      comments: null,
      attachedFiles: [],
      isLoadingAction: false, // loading before redirect to cart
    }
  },
  computed: {
    ...mapState({
      // Attributs
      isLoadingAttributs: (state) => state.product.isLoadingAttributs,
      errorAttributs: (state) => state.product.errorAttributs,
      attributs: (state) => state.product.attributs,
    }),
    // Global
    isLoading() {
      return (
        this.isLoadingSavedProductSettings ||
        this.isLoadingAttributs
      );
    },
    hasError() {
      return (
        this.hasErrorAttribut
      );
    },
    hasErrorAttribut() {
      return this.errorAttributs !== null;
    },
  },
  methods: {
    generateTmpUserId() {
      let result = "";
      let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let charactersLength = characters.length;
      for (let i = 0; i < 20; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return 'tmp-' + this.$route.params.productId + '-' + result;
    },
    // Return
    backToSteps() {
      this.$router.push({name: "product", params: { id: this.savedProductSettings.productId }});
    },
    getAttributeNameFromId(attributeId) {
      return this.attributs.find(attribut => attribut.id === attributeId).name;
    },
    saveDoItForMeData() {
      localStorage.setItem("tmpDoItForMe-" + this.$route.params.productId, JSON.stringify({
        tmpUserId: this.tmpUserId,
        comments: this.comments,
        files: this.attachedFiles.map(file => file.id),
        createdAt: new Date().getTime(),
      }));
    },
    // Add the attachment to the array in order to display it
    onUploadAttachment(attachment) {
      this.attachedFiles.push(attachment);
    },
    // Cart
    async onClickAddCart() {
      this.isLoadingAction = true;

      this.saveDoItForMeData();
      this.$router.push({ name: 'redirect_cart' });

      this.isLoadingAction = false;
    },
  },
  async created() {
    let savedProductSettings = localStorage.getItem("savedProductSettings");

    if (savedProductSettings) {
      savedProductSettings = JSON.parse(savedProductSettings);

      // Check if it is the same product before actually loading the data
      if (savedProductSettings.productId === parseInt(this.$route.params.productId)) {
        this.savedProductSettings = savedProductSettings;
      } else {
        this.$router.push({ name: "catalog" });
        return;
      }
    } else {
      this.$router.push({ name: "catalog" });
      return;
    }

    let productFeatureValueFormat = this.savedProductSettings.productFeatureValues.find(
      featureValue => featureValue.feature.constName === 'FORMAT' || featureValue.feature.printComConstName === 'size'
    );

    await this.$store.dispatch("product/findAttributs", {
      productId: savedProductSettings.productId,
      data: {
        featureValueFormat: productFeatureValueFormat.id,
      }
    });

    let makingAttribute = this.attributs.find(attribute => attribute.constName == 'MAKING');
    let hasMakingAttribute = savedProductSettings.productAttributeValues.findIndex(item => item.attributId == makingAttribute.attributValues[0].attributId) !== -1;

    if (makingAttribute) {
      if (!hasMakingAttribute) {
        this.savedProductSettings.productAttributeValues.push(makingAttribute.attributValues[0]);
      }
      this.mockupPrice = makingAttribute.attributValues[0].priceHt;
    }

    localStorage.setItem(
      "savedProductSettings",
      JSON.stringify(this.savedProductSettings)
    );

    let tmpDoItForMeData = JSON.parse(localStorage.getItem("tmpDoItForMe-" + this.$route.params.productId));

    // If there is do it for me data, load them form the local storage
    if (tmpDoItForMeData) {
      this.tmpUserId = tmpDoItForMeData.tmpUserId;
      this.comments = tmpDoItForMeData.comments;
      if (tmpDoItForMeData.files.length > 0) {
        // Retrieves attached files
        let files = await this.$store.dispatch("doItForMe/findDocuments", {
          files: tmpDoItForMeData.files
        });

        if (files) {
          this.attachedFiles = files.documents;
        }
      }
    } else {
      this.tmpUserId = this.generateTmpUserId();
    }

    this.isLoadingSavedProductSettings = false;
  },
  mounted() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", false);
    // Add a listener to save product settings before unload (in case of a refresh)
    window.addEventListener("beforeunload", this.saveDoItForMeData);
  },
  beforeUnmount() {
    this.$store.dispatch("global/fetchFrontHeaderTransparent", true);
    window.removeEventListener("beforeunload", this.saveDoItForMeData);
  },
};
</script>

<style lang="scss" scoped>
.do-it-for-me-container {
  min-height: 50vh;
  max-width: 800px;

  .loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: auto;
  }
}
</style>
