import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  create (productId, data) {
    return axios.post("/api/cart/element/create/" + productId, Tool.createFormData(data));
  },
  updatingQuantity (cartElement, data) {
    return axios.post("/api/cart/element/quantity/" + cartElement.id, Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/cart/element/remove/" + data.id, Tool.createFormData(data));
  }
};
