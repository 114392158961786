<template>
  <div
    class="dropzone"
    :class="{ dragging: dragging, disabled: disabled }"
    @click="onClickDropzone()"
    @drop.prevent="addFile"
    @dragover.prevent="dragging = true"
    @dragleave="dragging = false"
  >
    <b-row v-if="hasError">
      <b-col cols="12">
        <FlashBag v-if="hasError" :message="errorMessage" />
      </b-col>
    </b-row>

    <b-row class="header">
      <b-col md="12">
        <i class="fas fa-cloud-upload-alt"></i>
        <h2>
          {{ $t("workspace.form.dragAndDrop") }}
        </h2>
        <p class="text-file-size">
          {{ $t("workspace.form.maxSize") }} {{ maxFileSize }}Mo
        </p>
        <p v-if="hasRules" class="text-file-type">
          {{ $t("workspace.form.validFileType") }}
          {{ extensions.concat(accepts).join(", ") }}
        </p>
        <input
          id="fileInput"
          ref="fileInput"
          type="file"
          @change="onChangeFileInput($event)"
          multiple="multiple"
          style="display: none"
        />
      </b-col>
    </b-row>

    <b-row class="content">
      <b-col v-if="hasInvalidFileType || hasInvalidFileSize" md="12" sm="12" cols="12">
        <div class="alert alert-danger">
          <ul>
            <li v-if="hasInvalidFileType" class="font-weight-bold">
              {{ $t("workspace.form.invalidExtension") }}
            </li>
            <li v-for="(errorFile, index) in errorFiles.invalidType" :key="index">
              {{ errorFile.name }} ({{ octetToMo(errorFile) }} Mo)
            </li>

            <li v-if="hasInvalidFileSize" class="font-weight-bold">
              {{ $t("workspace.form.invalidFileSize") }}
            </li>
            <li v-for="(errorFile, index) in errorFiles.invalidSize" :key="index">
              {{ errorFile.name }} ({{ octetToMo(errorFile) }} Mo)
            </li>
          </ul>
        </div>
      </b-col>

      <b-col v-if="hasValidFile && mode != 'importData'" md="12" sm="12" cols="12">
        <div class="alert alert-light">
          <ul>
            <li class="font-weight-bold">
              {{ $t("workspace.form.validFile") }}
            </li>
            <li v-for="(validFile, index) in validFiles" :key="index">
              {{ validFile.name }} ({{ octetToMo(validFile) }} Mo)

              <a v-if="!uploading" @click.prevent.stop="removeFile(validFile)">
                <i class="fa fa-times"></i>
              </a>
            </li>
          </ul>

          <b-progress
            v-if="uploading"
            class="mt-2"
            :value="uploadProgress"
            max="100"
            show-progress
            animated
            variant="mizogoo"
          />
        </div>
      </b-col>

      <b-col v-if="mode === 'importData' && isLoadingData" class="text-center">
        <b-spinner></b-spinner>
      </b-col>
    </b-row>

    <b-row v-if="hasValidFile && !autoUpload" class="footer">
      <b-col md="12">
        <b-button variant="mizogoo" @click.prevent.stop="upload">
          <i class="fas fa-upload mr-1"></i>
          {{ $t("workspace.global.send") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import FlashBag from "@mizogoo/components/FlashBag";

export default {
  name: "FormDropzone",
  components: {
    FlashBag,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    dataId: {
      type: Number,
      required: false,
    },
    extensions: {
      type: Array,
      default() {
        return [];
      },
    },
    accepts: {
      type: Array,
      default() {
        return ["image/jpeg", "image/png"];
      },
    },
    maxFileSize: {
      type: Number,
      default: 10,
    },
    autoUpload: {
      type: Boolean,
      default: false,
    },
    isLoadingData: {
      type: Boolean,
      default: false,
    },
    maxNumberOfFile: {
      type: Number,
    },
    functionality: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onUpload"],
  data() {
    return {
      uploading: false,
      dragging: false,
      validFiles: [],
      errorFiles: {
        invalidType: [],
        invalidSize: [],
      },
      errorMessage: [],
    };
  },
  computed: {
    // Document Create
    isLoading() {
      if (this.mode === "accountOrder") {
        return this.$store.getters["accountOrder/isLoadingRelatedDocumentCreate"];
      } else if (this.mode === "creation") {
        return this.$store.getters["creation/isLoadingRelatedDocumentCreate"];
      } else if (this.mode === "qrcode") {
        return this.$store.getters["accountInstitutionDocument/isLoadingCreating"];
      }
    },
    hasError() {
      if (this.mode === "accountOrder") {
        return this.$store.getters["accountOrder/hasErrorRelatedDocumentCreate"];
      } else if (this.mode === "creation") {
        return this.$store.getters["creation/hasErrorRelatedDocumentCreate"];
      } else if (this.mode === "qrcode") {
        return this.$store.getters["accountInstitutionDocument/hasErrorCreating"];
      }
    },
    error() {
      if (this.mode === "accountOrder") {
        return this.$store.getters["accountOrder/errorRelatedDocumentCreate"];
      } else if (this.mode === "creation") {
        return this.$store.getters["creation/errorRelatedDocumentCreate"];
      } else if (this.mode === "qrcode") {
        return this.$store.getters["accountInstitutionDocument/errorCreating"];
      }
    },
    uploadProgress() {
      if (this.mode === "accountOrder") {
        return this.$store.getters["accountOrder/uploadProgress"];
      } else if (this.mode === "creation") {
        return this.$store.getters["creation/uploadProgress"];
      } else if (this.mode === "qrcode") {
        return this.$store.getters["accountInstitutionDocument/uploadProgress"];
      }
    },
    // Rules
    hasRules() {
      return this.accepts.length > 0 || this.extensions.length > 0;
    },
    // Valid Files
    hasValidFile() {
      return this.validFiles.length > 0;
    },
    // Error Files
    hasInvalidFile() {
      return this.hasInvalidFileType || this.hasInvalidFileSize;
    },
    hasInvalidFileType() {
      return this.errorFiles.invalidType.length > 0;
    },
    hasInvalidFileSize() {
      return this.errorFiles.invalidSize.length > 0;
    },
  },
  methods: {
    ...mapMutations("premiumOnlyModal", {
      setPremiumOnlyModalElements: "SET_PREMIUM_ONLY_MODAL_ELEMENTS",
    }),
    displayPremiumOnlyModal() {
      let elements = {
        functionality: this.functionality,
        heading: this.$tm("workspace.premiumOnlyModal.pdfMenu.heading"),
        description: this.$tm("workspace.premiumOnlyModal.pdfMenu.description").join(""),
        image: {
          src: require("@mizogoo/assets/img/premium_modal/qrcode.jpg"),
          alt: this.$t("workspace.premiumOnlyModal.pdfMenu.imageAlt"),
        },
      };
      this.setPremiumOnlyModalElements(elements);
      this.$bvModal.show("premium-only-modal");
    },
    // Dropzone
    addFile(e) {
      if (!this.isLoadingData) {
        if (this.mode == "qrcode" && this.disabled) {
          this.displayPremiumOnlyModal();
        } else {
          this.dragging = false;

          let droppedFiles = e.dataTransfer.files;
          if (!droppedFiles) return;

          let numberOfFilesToUpload = this.maxNumberOfFile
            ? Math.min(this.maxNumberOfFile, droppedFiles.length)
            : droppedFiles.length;

          for (let i = 0; i < numberOfFilesToUpload; i++) {
            this.fileChecking(droppedFiles[i]);
          }
        }
      }
    },
    removeFile(file) {
      this.validFiles = this.validFiles.filter((f) => {
        return f !== file;
      });
    },
    onClickDropzone() {
      if (!this.isLoadingData) {
        if (this.mode == "qrcode" && this.disabled) {
          this.displayPremiumOnlyModal();
        } else {
          document.getElementById("fileInput").click();
        }
      }
    },
    onChangeFileInput() {
      let numberOfFilesToUpload = this.maxNumberOfFile
        ? this.maxNumberOfFile
        : this.$refs.fileInput.files.length;

      for (let i = 0; i < numberOfFilesToUpload; i++) {
        this.fileChecking(this.$refs.fileInput.files[i]);
      }
    },
    async upload(file) {
      this.uploading = true;

      let dispatchDocumentCreate = null;
      let dispatchUploadProgress = null;
      let payload = {};

      if (this.mode === "accountOrder") {
        dispatchDocumentCreate = "accountOrder/relatedDocumentCreate";
        dispatchUploadProgress = "accountOrder/setUploadProgress";

        payload = {
          order: this.dataId,
          form: {
            file: file,
          },
        };
      } else if (this.mode === "creation") {
        dispatchDocumentCreate = "creation/relatedDocumentCreate";
        dispatchUploadProgress = "creation/setUploadProgress";

        payload = {
          creation: this.dataId,
          form: {
            file: file,
          },
        };
      // Do it for me anonymous (allow to ask for a design before creating an account)
      } else if (this.mode === "do-it-for-me") {
        dispatchDocumentCreate = "doItForMe/relatedDocumentCreate";
        dispatchUploadProgress = "doItForMe/setUploadProgress";

        payload = {
          tmpUserId: this.dataId,
          file: file,
        };
      } else if (this.mode === "qrcode") {
        dispatchDocumentCreate = "accountInstitutionDocument/create";
        dispatchUploadProgress = "accountInstitutionDocument/setUploadProgress";

        payload = {
          form: {
            file: file,
          },
        };
      } else if (this.mode === "importData") {
        this.$emit("onUpload", file);
      }

      if (dispatchDocumentCreate) {
        await this.$store.dispatch(dispatchDocumentCreate, payload).then((response) => {
          if (this.hasError) {
            let message =
              this.$t("workspace.form.theFile") +
              " " +
              file.name +
              " " +
              this.$t("workspace.form.fail") +
              ".";
            this.errorMessage.push(message);
          } else {
            this.$emit("onUpload", response.document);
          }

          this.removeFile(file);
          this.$store.dispatch(dispatchUploadProgress, 0);
        });
      }

      this.uploading = false;
    },
    // File Checking
    fileChecking(file) {
      let isValidType = false;

      if (this.extensions.length > 0) {
        if (this.isValidExtension(file)) {
          isValidType = true;
        }
      }

      if (this.accepts.length > 0) {
        if (this.isValidMimeType(file)) {
          isValidType = true;
        }
      }

      if (!isValidType) {
        this.errorFiles.invalidType.push(file);
      } else if (!this.isValidFileSize(file)) {
        this.errorFiles.invalidSize.push(file);
      } else {
        this.validFiles.push(file);

        if (this.autoUpload) {
          this.upload(file);
        }
      }
    },
    // File Extension
    getExtension(file) {
      return file.name.split(".").pop();
    },
    isValidExtension(file) {
      return this.extensions.includes(this.getExtension(file));
    },
    isValidMimeType(file) {
      return this.accepts.includes(file.type);
    },
    // File Size
    octetToMo(file) {
      return (file.size / (1024 * 1024)).toFixed(2);
    },
    isValidFileSize(file) {
      return file.size <= 1024 * 1024 * this.maxFileSize;
    },
  },
};
</script>

<style lang="scss" scoped>
div.dropzone {
  position: relative;
  min-height: 200px;
  color: #777777;
  padding: 4rem;
  background-color: $mizogoo-gray-light;
  border: 2px dashed $gray-500;
  border-radius: 2px;

  &.dragging {
    background-color: rgba(233, 233, 233, 0.5);
    border: 2px dashed $mizogoo-gray-dark;
  }

  &.disabled::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
  }

  &:hover {
    cursor: pointer;
  }

  div.header {
    text-align: center;

    i {
      padding: 0.8rem;
      font-size: 3rem !important;
    }

    h2 {
      margin: 0 !important;
      padding-bottom: 1rem;
    }

    p.text-file-size {
      margin: 0;
      padding: 0;
    }

    p.text-file-type {
      font-size: 0.9rem !important;
      margin: 0;
      padding-bottom: 0.5rem;
    }
  }

  div.content {
    margin-top: 2rem;
    margin-bottom: 1rem;

    i {
      font-size: 1rem !important;
    }

    .btn {
      padding: 0.1rem 0.5rem;
    }
  }

  div.footer {
    text-align: center;

    i {
      font-size: 1rem !important;
    }
  }
}
</style>
