import AccountContestAPI from "../api/account_contest";

const
  FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",

  FETCH_ONE = "FETCH_ONE",
  FETCH_ONE_SUCCESS = "FETCH_ONE_SUCCESS",
  FETCH_ONE_ERROR = "FETCH_ONE_ERROR",

  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",

  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",

  CLAIMING = "CLAIMING",
  CLAIMING_SUCCESS = "CLAIMING_SUCCESS",
  CLAIMING_ERROR = "CLAIMING_ERROR",

  TOGGLING = "TOGGLING",
  TOGGLING_SUCCESS = "TOGGLING_SUCCESS",
  TOGGLING_ERROR = "TOGGLING_ERROR";

export default {
  namespaced: true,
  state: {
    // Fetch All
    isLoadingFetchAll: false,
    errorFetchAll: null,
    contests: [],
    // Fetch One
    isLoadingFetchOne: false,
    errorFetchOne: null,
    // Creating
    isLoadingCreating: false,
    errorCreating: null,
    // Removing
    isLoadingRemoving: false,
    errorRemoving: null,
    // Claiming
    isLoadingClaiming: false,
    errorClaiming: null,
    // Toggling
    isLoadingToggling: false,
    errorToggling: null
  },
  getters: {
    // Fetch All
    isLoadingFetchAll (state) {
      return state.isLoadingFetchAll;
    },
    hasErrorFetchAll (state) {
      return state.errorFetchAll !== null;
    },
    errorFetchAll (state) {
      return state.errorFetchAll;
    },
    hasContests (state) {
      return state.contests.length > 0;
    },
    contests (state) {
      return state.contests;
    },
    // Fetch One
    isLoadingFetchOne (state) {
      return state.isLoadingFetchOne;
    },
    hasErrorFetchOne (state) {
      return state.errorFetchOne !== null;
    },
    errorFetchOne (state) {
      return state.errorFetchOne;
    },
    // Creating
    isLoadingCreating (state) {
      return state.isLoadingCreating;
    },
    hasErrorCreating (state) {
      return state.errorCreating !== null;
    },
    errorCreating (state) {
      return state.errorCreating;
    },
    // Removing
    isLoadingRemoving (state) {
      return state.isLoadingRemoving;
    },
    hasErrorRemoving (state) {
      return state.errorRemoving !== null;
    },
    errorRemoving (state) {
      return state.errorRemoving;
    },
    // Claiming
    isLoadingClaiming (state) {
      return state.isLoadingClaiming;
    },
    hasErrorClaiming (state) {
      return state.errorClaiming !== null;
    },
    errorClaiming (state) {
      return state.errorClaiming;
    },
    // Toggling
    isLoadingToggling (state) {
      return state.isLoadingToggling;
    },
    hasErrorToggling (state) {
      return state.errorToggling !== null;
    },
    errorToggling (state) {
      return state.errorToggling;
    }
  },
  mutations: {
    // Fetch All
    [FETCH_ALL] (state) {
      state.isLoadingFetchAll = true;
      state.errorFetchAll = null;
      state.contests = [];
    },
    [FETCH_ALL_SUCCESS] (state, contests) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = null;
      state.contests = contests;
    },
    [FETCH_ALL_ERROR] (state, error) {
      state.isLoadingFetchAll = false;
      state.errorFetchAll = error;
      state.contests = [];
    },
    // Fetch One
    [FETCH_ONE] (state) {
      state.isLoadingFetchOne = true;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_SUCCESS] (state) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = null;
    },
    [FETCH_ONE_ERROR] (state, error) {
      state.isLoadingFetchOne = false;
      state.errorFetchOne = error;
    },
    // Creating
    [CREATING] (state) {
      state.isLoadingCreating = true;
      state.errorCreating = null;
    },
    [CREATING_SUCCESS] (state) {
      state.isLoadingCreating = false;
      state.errorCreating = null;
    },
    [CREATING_ERROR] (state, error) {
      state.isLoadingCreating = false;
      state.errorCreating = error;
    },
    // Removing
    [REMOVING] (state) {
      state.isLoadingRemoving = true;
      state.errorRemoving = null;
    },
    [REMOVING_SUCCESS] (state, contest) {
      state.isLoadingRemoving = false;
      state.errorRemoving = null;
      state.contests = state.contests.filter(element => element.id !== contest.id)
    },
    [REMOVING_ERROR] (state, error) {
      state.isLoadingRemoving = false;
      state.errorRemoving = error;
    },
    // Claiming
    [CLAIMING] (state) {
      state.isLoadingClaiming = true;
      state.errorClaiming = null;
    },
    [CLAIMING_SUCCESS] (state, contest) {
      state.isLoadingClaiming = false;
      state.errorClaiming = null;
    },
    [CLAIMING_ERROR] (state, error) {
      state.isLoadingClaiming = false;
      state.errorClaiming = error;
    },
    // Toggling
    [TOGGLING] (state) {
      state.isLoadingToggling = true;
      state.errorToggling = null;
    },
    [TOGGLING_SUCCESS] (state, contest) {
      state.isLoadingToggling = false;
      state.errorToggling = null;
      state.contests = state.contests.map(element => element.id == contest.id ? {...element, isPlaying: !element.isPlaying} : element)
    },
    [TOGGLING_ERROR] (state, error) {
      state.isLoadingToggling = false;
      state.errorToggling = error;
    }
  },
  actions: {
    async findAll ({ commit }) {
      commit(FETCH_ALL);
      try {
        let response = await AccountContestAPI.findAll();
        if (response.data.success === true) {
          commit(FETCH_ALL_SUCCESS, response.data.contests);
          return response.data.contests;
        } else {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ALL_ERROR);
        return null;
      }
    },
    async findOne ({ commit }, payload) {
      commit(FETCH_ONE);
      try {
        let response = await AccountContestAPI.findOne(payload.contest);
        if (response.data.success === true) {
          commit(FETCH_ONE_SUCCESS);
          return response.data.contest;
        } else {
          commit(FETCH_ONE_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ONE_ERROR);
        return null;
      }
    },
    async create ({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountContestAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async remove ({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountContestAPI.remove(payload.contest);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.contest);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async claim ({ commit }, payload) {
      commit(CLAIMING);
      try {
        let response = await AccountContestAPI.claim(payload);
        if (response.data.success === true) {
          commit(CLAIMING_SUCCESS, payload.ticket);
          return null;
        } else {
          commit(CLAIMING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CLAIMING_ERROR, error);
        return null;
      }
    },
    async toggle ({ commit }, payload) {
      commit(TOGGLING);
      try {
        let response = await AccountContestAPI.toggle(payload.contest);
        if (response.data.success === true) {
          commit(TOGGLING_SUCCESS, payload.contest);
          return null;
        } else {
          commit(TOGGLING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(TOGGLING_ERROR, error);
        return null;
      }
    }
  }
}
