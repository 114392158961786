import ProductAPI from "../api/product";

const
  // Product
  FETCH_PRODUCT = "FETCH_PRODUCT",
  FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS",
  FETCH_PRODUCT_ERROR = "FETCH_PRODUCT_ERROR",

  // Product Categories
  FETCH_PRODUCT_CATEGORIES = "FETCH_PRODUCT_CATEGORIES",
  FETCH_PRODUCT_CATEGORIES_SUCCESS = "FETCH_PRODUCT_CATEGORIES_SUCCESS",
  FETCH_PRODUCT_CATEGORIES_ERROR = "FETCH_PRODUCT_CATEGORIES_ERROR",

  // Model Categories
  FETCH_MODEL_CATEGORIES = "FETCH_MODEL_CATEGORIES",
  FETCH_MODEL_CATEGORIES_SUCCESS = "FETCH_MODEL_CATEGORIES_SUCCESS",
  FETCH_MODEL_CATEGORIES_ERROR = "FETCH_MODEL_CATEGORIES_ERROR",

  // Models
  FETCH_MODELS = "FETCH_MODELS",
  FETCH_MODELS_SUCCESS = "FETCH_MODELS_SUCCESS",
  FETCH_MODELS_ERROR = "FETCH_MODELS_ERROR",

  // Has Models
  FETCH_HAS_MODELS = "FETCH_HAS_MODELS",
  FETCH_HAS_MODELS_SUCCESS = "FETCH_HAS_MODELS_SUCCESS",
  FETCH_HAS_MODELS_ERROR = "FETCH_HAS_MODELS_ERROR",

  // Features
  FETCH_FEATURES = "FETCH_FEATURES",
  FETCH_FEATURES_SUCCESS = "FETCH_FEATURES_SUCCESS",
  FETCH_FEATURES_ERROR = "FETCH_FEATURES_ERROR",

  // Feature Values
  FETCH_FEATURE_VALUES = "FETCH_FEATURE_VALUES",
  FETCH_FEATURE_VALUES_SUCCESS = "FETCH_FEATURE_VALUES_SUCCESS",
  FETCH_FEATURE_VALUES_ERROR = "FETCH_FEATURE_VALUES_ERROR",

  // Model Categories
  FETCH_MATRIX_CATEGORIES = "FETCH_MATRIX_CATEGORIES",
  FETCH_MATRIX_CATEGORIES_SUCCESS = "FETCH_MATRIX_CATEGORIES_SUCCESS",
  FETCH_MATRIX_CATEGORIES_ERROR = "FETCH_MATRIX_CATEGORIES_ERROR",

  // Matrices
  FETCH_MATRICES = "FETCH_MATRICES",
  FETCH_MATRICES_SUCCESS = "FETCH_MATRICES_SUCCESS",
  FETCH_MATRICES_ERROR = "FETCH_MATRICES_ERROR",

  // Has Matrices
  FETCH_HAS_MATRICES = "FETCH_HAS_MATRICES",
  FETCH_HAS_MATRICES_SUCCESS = "FETCH_HAS_MATRICES_SUCCESS",
  FETCH_HAS_MATRICES_ERROR = "FETCH_HAS_MATRICES_ERROR",

  // Product Attributs
  FETCH_ATTRIBUTS = "FETCH_ATTRIBUTS",
  FETCH_ATTRIBUTS_SUCCESS = "FETCH_ATTRIBUTS_SUCCESS",
  FETCH_ATTRIBUTS_ERROR = "FETCH_ATTRIBUTS_ERROR",

  // Product Prices
  FETCH_PRICES = "FETCH_PRICES",
  FETCH_PRICES_SUCCESS = "FETCH_PRICES_SUCCESS",
  FETCH_PRICES_ERROR = "FETCH_PRICES_ERROR",

  // Associated Products
  FETCH_ASSOCIATED_PRODUCTS = "FETCH_ASSOCIATED_PRODUCTS",
  FETCH_ASSOCIATED_PRODUCTS_SUCCESS = "FETCH_ASSOCIATED_PRODUCTS_SUCCESS",
  FETCH_ASSOCIATED_PRODUCTS_ERROR = "FETCH_ASSOCIATED_PRODUCTS_ERROR";

export default {
  namespaced: true,
  state: {
    // Product
    isLoadingProduct: false,
    errorProduct: null,
    // Product Categories
    isLoadingProductCategories: true,
    errorProductCategories: null,
    productCategories: [],
    // Product Model Categories
    isLoadingModelCategories: false,
    errorModelCategories: null,
    modelCategories: [],
    // Models
    isLoadingModels: false,
    errorModels: null,
    models: [],
    // Has Models
    isLoadingHasModels: false,
    errorHasModels: null,
    hasModels: false,
    // Product Features
    isLoadingFeatures: false,
    errorFeatures: null,
    features: [],
    // Product Feature Values
    isLoadingFeatureValues: false,
    errorFeatureValues: null,
    featureValues: [],
    // Matrix Categories
    isLoadingMatrixCategories: false,
    errorMatrixCategories: null,
    matrixCategories: [],
    // Matrices
    isLoadingMatrices: false,
    errorMatrices: null,
    matrices: [],
    // Has Matrices
    isLoadingHasMatrices: false,
    errorHasMatrices: null,
    hasMatrices: false,
    // Product Attributs
    isLoadingAttributs: false,
    errorAttributs: null,
    attributs: [],
    // Product Prices
    isLoadingPrices: false,
    errorPrices: null,
    prices: [],
    // Associated products
    isLoadingAssociatedProducts: false,
    errorAssociatedProducts: null,
  },
  getters: {
    // Product
    isLoadingProduct(state) {
      return state.isLoadingProduct;
    },
    hasErrorProduct(state) {
      return state.errorProduct !== null;
    },
    errorProduct(state) {
      return state.errorProduct;
    },
    // Product Categories
    isLoadingProductCategories(state) {
      return state.isLoadingProductCategories;
    },
    hasErrorProductCategory(state) {
      return state.errorProductCategories !== null;
    },
    errorProductCategories(state) {
      return state.errorProductCategories;
    },
    hasProductCategories(state) {
      return state.productCategories.length > 0;
    },
    productCategories(state) {
      return state.productCategories;
    },
    // Products
    products(state) {
      let products = [];

      for (let productCategorie of state.productCategories) {
        products.push(...productCategorie.products)
      }

      return products;
    },
    // Model Categories
    isLoadingModelCategories(state) {
      return state.isLoadingModelCategories;
    },
    hasErrorModelCategories(state) {
      return state.errorModelCategories !== null;
    },
    errorModelCategories(state) {
      return state.errorModelCategories;
    },
    hasModelCategories(state) {
      return state.modelCategories.length > 0;
    },
    modelCategories(state) {
      return state.modelCategories;
    },
    hasErrorModels(state) {
      return state.errorModels !== null;
    },
    // Product Features
    isLoadingFeatures(state) {
      return state.isLoadingFeatures;
    },
    hasErrorFeature(state) {
      return state.errorFeatures !== null;
    },
    errorFeatures(state) {
      return state.errorFeatures;
    },
    hasFeatures(state) {
      return state.features.length > 0;
    },
    features(state) {
      return state.features;
    },
    featureCustomizables(state) {
      return state.features.filter(feature => feature.isCustomizable)
    },
    featurePrintables(state) {
      return state.features.filter(feature => feature.isPrintable)
    },
    // Product Feature Values
    isLoadingFeatureValues(state) {
      return state.isLoadingFeatureValues;
    },
    hasErrorFeatureValues(state) {
      return state.errorFeatureValues !== null;
    },
    errorFeatureValues(state) {
      return state.errorFeatureValues;
    },
    hasFeatureValues(state) {
      return state.featureValues.length > 0;
    },
    featureValues(state) {
      return state.featureValues;
    },
    // Matrix Categories
    isLoadingMatrixCategories(state) {
      return state.isLoadingMatrixCategories;
    },
    hasErrorMatrixCategories(state) {
      return state.errorMatrixCategories !== null;
    },
    errorMatrixCategories(state) {
      return state.errorMatrixCategories;
    },
    hasMatrixCategories(state) {
      return state.matrixCategories.length > 0;
    },
    matrixCategories(state) {
      return state.matrixCategories;
    },
    hasErrorMatrices(state) {
      return state.errorMatrices !== null;
    },
    // Product Attributs
    isLoadingAttributs(state) {
      return state.isLoadingAttributs;
    },
    hasErrorAttribut(state) {
      return state.errorAttributs !== null;
    },
    errorAttributs(state) {
      return state.errorAttributs;
    },
    hasAttribut(state) {
      return state.attributs.length > 0;
    },
    attributs(state) {
      return state.attributs;
    },
    // Product Prices
    isLoadingPrices(state) {
      return state.isLoadingPrices;
    },
    hasErrorPrices(state) {
      return state.errorPrices !== null;
    },
    errorPrices(state) {
      return state.errorPrices;
    },
    hasPrices(state) {
      return state.prices.length > 0;
    },
    prices(state) {
      return state.prices;
    },
    minQuantity(state) {
      return state.prices.length > 0 ? state.prices[0] : null;
    },
    maxQuantity(state) {
      return state.prices.length > 0 ? state.prices[state.prices.length - 1] : null;
    },
  },
  mutations: {
    // Product
    [FETCH_PRODUCT](state) {
      state.isLoadingProduct = true;
      state.errorProduct = null;
    },
    [FETCH_PRODUCT_SUCCESS](state) {
      state.isLoadingProduct = false;
      state.errorProduct = null;
    },
    [FETCH_PRODUCT_ERROR](state, error) {
      state.isLoadingProduct = false;
      state.errorProduct = error;
    },
    // Product Categories
    [FETCH_PRODUCT_CATEGORIES](state) {
      state.isLoadingProductCategories = true;
      state.errorProductCategories = null;
      state.productCategories = [];
    },
    [FETCH_PRODUCT_CATEGORIES_SUCCESS](state, productCategories) {
      state.isLoadingProductCategories = false;
      state.errorProductCategories = null;
      state.productCategories = productCategories;
    },
    [FETCH_PRODUCT_CATEGORIES_ERROR](state, error) {
      state.isLoadingProductCategories = false;
      state.errorProductCategories = error;
      state.productCategories = [];
    },
    // Product Model Categories
    [FETCH_MODEL_CATEGORIES](state) {
      state.isLoadingModelCategories = true;
      state.errorModelCategories = null;
      state.modelCategories = [];
    },
    [FETCH_MODEL_CATEGORIES_SUCCESS](state, modelCategories) {
      state.isLoadingModelCategories = false;
      state.errorModelCategories = null;
      state.modelCategories = modelCategories;
    },
    [FETCH_MODEL_CATEGORIES_ERROR](state, error) {
      state.isLoadingModelCategories = false;
      state.errorModelCategories = error;
      state.modelCategories = [];
    },
    // Models
    [FETCH_MODELS](state) {
      state.isLoadingModels = true;
      state.errorModels = null;
      state.models = [];
    },
    [FETCH_MODELS_SUCCESS](state, models) {
      state.isLoadingModels = false;
      state.errorModels = null;
      state.models = models;
    },
    [FETCH_MODELS_ERROR](state, error) {
      state.isLoadingModels = false;
      state.errorModels = error;
      state.models = [];
    },
    // Has Models
    [FETCH_HAS_MODELS](state) {
      state.isLoadingHasModels = true;
      state.errorHasModels = null;
      state.hasModels = [];
    },
    [FETCH_HAS_MODELS_SUCCESS](state, models) {
      state.isLoadingHasModels = false;
      state.errorHasModels = null;
      state.hasModels = models;
    },
    [FETCH_HAS_MODELS_ERROR](state, error) {
      state.isLoadingHasModels = false;
      state.errorHasModels = error;
      state.hasModels = [];
    },
    // Product Features
    [FETCH_FEATURES](state) {
      state.isLoadingFeatures = true;
      state.errorFeatures = null;
      state.features = [];
    },
    [FETCH_FEATURES_SUCCESS](state, features) {
      state.isLoadingFeatures = false;
      state.errorFeatures = null;
      state.features = features;
    },
    [FETCH_FEATURES_ERROR](state, error) {
      state.isLoadingFeatures = false;
      state.errorFeatures = error;
      state.features = [];
    },
    // Product Feature Values
    [FETCH_FEATURE_VALUES](state) {
      state.isLoadingFeatureValues = true;
      state.errorFeatureValues = null;
      state.featureValues = [];
    },
    [FETCH_FEATURE_VALUES_SUCCESS](state, features) {
      state.isLoadingFeatureValues = false;
      state.errorFeatureValues = null;
      state.featureValues = features;
    },
    [FETCH_FEATURE_VALUES_ERROR](state, error) {
      state.isLoadingFeatureValues = false;
      state.errorFeatureValues = error;
      state.featureValues = [];
    },
    // Matrix Categories
    [FETCH_MATRIX_CATEGORIES](state) {
      state.isLoadingMatrixCategories = true;
      state.errorMatrixCategories = null;
      state.matrixCategories = [];
    },
    [FETCH_MATRIX_CATEGORIES_SUCCESS](state, matrixCategories) {
      state.isLoadingMatrixCategories = false;
      state.errorMatrixCategories = null;
      state.matrixCategories = matrixCategories;
    },
    [FETCH_MATRIX_CATEGORIES_ERROR](state, error) {
      state.isLoadingMatrixCategories = false;
      state.errorMatrixCategories = error;
      state.matrixCategories = [];
    },
    // Matrices
    [FETCH_MATRICES](state) {
      state.isLoadingMatrices = true;
      state.errorMatrices = null;
      state.matrices = [];
    },
    [FETCH_MATRICES_SUCCESS](state, matrices) {
      state.isLoadingMatrices = false;
      state.errorMatrices = null;
      state.matrices = matrices;
    },
    [FETCH_MATRICES_ERROR](state, error) {
      state.isLoadingMatrices = false;
      state.errorMatrices = error;
      state.matrices = [];
    },
    // Has Matrices
    [FETCH_HAS_MATRICES](state) {
      state.isLoadingHasMatrices = true;
      state.errorHasMatrices = null;
      state.hasMatrices = [];
    },
    [FETCH_HAS_MATRICES_SUCCESS](state, matrices) {
      state.isLoadingHasMatrices = false;
      state.errorHasMatrices = null;
      state.hasMatrices = matrices;
    },
    [FETCH_HAS_MATRICES_ERROR](state, error) {
      state.isLoadingHasMatrices = false;
      state.errorHasMatrices = error;
      state.hasMatrices = [];
    },
    // Product Attributs
    [FETCH_ATTRIBUTS](state) {
      state.isLoadingAttributs = true;
      state.errorAttributs = null;
      state.attributs = [];
    },
    [FETCH_ATTRIBUTS_SUCCESS](state, attributs) {
      state.isLoadingAttributs = false;
      state.errorAttributs = null;
      state.attributs = attributs;
    },
    [FETCH_ATTRIBUTS_ERROR](state, error) {
      state.isLoadingAttributs = false;
      state.errorAttributs = error;
      state.attributs = [];
    },
    // Product Prices
    [FETCH_PRICES](state) {
      state.isLoadingPrices = true;
      state.errorPrices = null;
      // state.prices = [];
    },
    [FETCH_PRICES_SUCCESS](state, prices) {
      state.isLoadingPrices = false;
      state.errorPrices = null;
      state.prices = prices;
    },
    [FETCH_PRICES_ERROR](state, error) {
      state.isLoadingPrices = false;
      state.errorPrices = error;
      state.prices = [];
    },
    // Associated Product
    [FETCH_ASSOCIATED_PRODUCTS](state) {
      state.isLoadingAssociatedProducts = true;
      state.errorAssociatedProducts = null;
    },
    [FETCH_ASSOCIATED_PRODUCTS_SUCCESS](state) {
      state.isLoadingAssociatedProducts = false;
      state.errorAssociatedProducts = null;
    },
    [FETCH_ASSOCIATED_PRODUCTS_ERROR](state, error) {
      state.isLoadingAssociatedProducts = false;
      state.errorAssociatedProducts = error;
    }
  },
  actions: {
    async findOne({ commit }, payload) {
      commit(FETCH_PRODUCT);
      try {
        let response = await ProductAPI.findOne(payload.product);
        if (response.data.success === true) {
          commit(FETCH_PRODUCT_SUCCESS);
          return response.data.product;
        } else {
          commit(FETCH_PRODUCT_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_PRODUCT_ERROR);
        return null;
      }
    },
    async findOneByConstName({ commit }, payload) {
      commit(FETCH_PRODUCT);
      try {
        let response = await ProductAPI.findOneByConstName(payload.data);
        if (response.data.success === true) {
          commit(FETCH_PRODUCT_SUCCESS);
          return response.data.product;
        } else {
          commit(FETCH_PRODUCT_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_PRODUCT_ERROR);
        return null;
      }
    },
    async findProductCategories({ commit }) {
      commit(FETCH_PRODUCT_CATEGORIES);
      try {
        let response = await ProductAPI.findProductCategories();
        if (response.data.success === true) {
          commit(FETCH_PRODUCT_CATEGORIES_SUCCESS, response.data.productCategories);
          return response.data.productCategories;
        } else {
          commit(FETCH_PRODUCT_CATEGORIES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_PRODUCT_CATEGORIES_ERROR);
        return null;
      }
    },
    async findModelCategories({ commit }, payload) {
      commit(FETCH_MODEL_CATEGORIES);
      try {
        let response = await ProductAPI.findModelCategories(payload.productId, payload.data);
        if (response.data.success === true) {
          commit(FETCH_MODEL_CATEGORIES_SUCCESS, response.data.modelCategories);
          return response.data.modelCategories;
        } else {
          commit(FETCH_MODEL_CATEGORIES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_MODEL_CATEGORIES_ERROR);
        return null;
      }
    },
    async findModelCategoriesWithoutModels({ commit }, payload) {
      commit(FETCH_MODEL_CATEGORIES);
      try {
        let response = await ProductAPI.findModelCategoriesWithoutModels(payload.productId);
        if (response.data.success === true) {
          commit(FETCH_MODEL_CATEGORIES_SUCCESS, response.data.modelCategories);
          return response.data.modelCategories;
        } else {
          commit(FETCH_MODEL_CATEGORIES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_MODEL_CATEGORIES_ERROR);
        return null;
      }
    },
    async findModels({ commit }, payload) {
      commit(FETCH_MODELS);
      try {
        let response = await ProductAPI.findModels(payload.productId, payload.data);
        if (response.data.success === true) {
          commit(FETCH_MODELS_SUCCESS, response.data.models);
          return response.data.models;
        } else {
          commit(FETCH_MODELS_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_MODELS_ERROR);
        return null;
      }
    },
    async hasModels({ commit }, payload) {
      commit(FETCH_HAS_MODELS);
      try {
        let response = await ProductAPI.hasModels(payload.productId);
        if (response.data.success === true) {
          commit(FETCH_HAS_MODELS_SUCCESS, response.data.hasModels);
          return response.data.hasModels;
        } else {
          commit(FETCH_HAS_MODELS_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_HAS_MODELS_ERROR);
        return null;
      }
    },
    async findFeatures({ commit }, payload) {
      commit(FETCH_FEATURES);
      try {
        let response = await ProductAPI.findFeatures(payload.product);
        if (response.data.success === true) {
          commit(FETCH_FEATURES_SUCCESS, response.data.features);
          return response.data.features;
        } else {
          commit(FETCH_FEATURES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_FEATURES_ERROR);
        return null;
      }
    },
    async findFeatureValues({ commit }, payload) {
      commit(FETCH_FEATURE_VALUES);
      try {
        let response = await ProductAPI.findFeatureValues(payload.product);
        if (response.data.success === true) {
          commit(FETCH_FEATURE_VALUES_SUCCESS, response.data.featureValues);
          return response.data.featureValues;
        } else {
          commit(FETCH_FEATURE_VALUES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_FEATURE_VALUES_ERROR);
        return null;
      }
    },
    async findMatrixCategories({ commit }, payload) {
      commit(FETCH_MATRIX_CATEGORIES);
      try {
        let response = await ProductAPI.findMatrixCategories(payload.productId, payload.data);
        if (response.data.success === true) {
          commit(FETCH_MATRIX_CATEGORIES_SUCCESS, response.data.matrixCategories);
          return response.data.matrixCategories;
        } else {
          commit(FETCH_MATRIX_CATEGORIES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_MATRIX_CATEGORIES_SUCCESS);
        return null;
      }
    },
    async findMatrixCategoriesWithoutMatrices({ commit }, payload) {
      commit(FETCH_MATRIX_CATEGORIES);
      try {
        let response = await ProductAPI.findMatrixCategoriesWithoutMatrices(payload.data);
        if (response.data.success === true) {
          commit(FETCH_MATRIX_CATEGORIES_SUCCESS, response.data.matrixCategories);
          return response.data.matrixCategories;
        } else {
          commit(FETCH_MATRIX_CATEGORIES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_MATRIX_CATEGORIES_SUCCESS);
        return null;
      }
    },
    resetMatrixCategories({ commit }) {
      commit(FETCH_MATRIX_CATEGORIES_SUCCESS, []);
    },
    async findMatrices({ commit }, payload) {
      commit(FETCH_MATRICES);
      try {
        let response = await ProductAPI.findMatrices(payload.data);
        if (response.data.success === true) {
          commit(FETCH_MATRICES_SUCCESS, response.data.matrices);
          return response.data.matrices;
        } else {
          commit(FETCH_MATRICES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_MATRICES_SUCCESS);
        return null;
      }
    },
    async hasMatrices({ commit }, payload) {
      commit(FETCH_HAS_MATRICES);
      try {
        let response = await ProductAPI.hasMatrices(payload.data);
        if (response.data.success === true) {
          commit(FETCH_HAS_MATRICES_SUCCESS, response.data.hasMatrices);
          return response.data.hasMatrices;
        } else {
          commit(FETCH_HAS_MATRICES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_HAS_MATRICES_ERROR);
        return null;
      }
    },
    async findAttributs({ commit }, payload) {
      commit(FETCH_ATTRIBUTS);
      try {
        let response = await ProductAPI.findAttributs(payload.productId, payload.data);
        if (response.data.success === true) {
          commit(FETCH_ATTRIBUTS_SUCCESS, response.data.attributs);
          return response.data.attributs;
        } else {
          commit(FETCH_ATTRIBUTS_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ATTRIBUTS_ERROR);
        return null;
      }
    },
    async findPrices({ commit }, payload) {
      commit(FETCH_PRICES);
      try {
        let response = await ProductAPI.findPrices(payload.product, payload.data);
        if (response.data.success === true) {
          commit(FETCH_PRICES_SUCCESS, response.data.prices);
          return response.data.prices;
        } else {
          commit(FETCH_PRICES_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_PRICES_ERROR);
        return null;
      }
    },
    async findAssociatedProducts({ commit }, payload) {
      commit(FETCH_ASSOCIATED_PRODUCTS);
      try {
        let response = await ProductAPI.findAssociatedProducts(payload.product);
        if (response.data.success === true) {
          commit(FETCH_ASSOCIATED_PRODUCTS_SUCCESS);
          return response.data.products;
        } else {
          commit(FETCH_ASSOCIATED_PRODUCTS_ERROR);
          return null;
        }
      } catch (error) {
        commit(FETCH_ASSOCIATED_PRODUCTS_ERROR);
        return null;
      }
    },
  }
}
