import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/contest", Tool.createFormData());
  },
  findOne (data) {
    return axios.post("/api/account/contest/" + data, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/contest/create", Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/contest/remove/" + data.id, Tool.createFormData());
  },
  claim (data) {
    return axios.post("/api/account/contest/claim/" + data.contest + "/ticket/" + data.ticket, Tool.createFormData());
  },
  toggle (data) {
    return axios.post("/api/account/contest/toggle/" + data.id, Tool.createFormData());
  }
};
