import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/account/menu", Tool.createFormData());
  },
  findOne (data) {
    return axios.post("/api/account/menu/" + data, Tool.createFormData());
  },
  create (data) {
    return axios.post("/api/account/menu/create", Tool.createFormData(data));
  },
  update (data) {
    return axios.post("/api/account/menu/update/" + data.id, Tool.createFormData(data));
  },
  updatePosition(data) {
    return axios.post("/api/account/menu/update/position", Tool.createFormData(data));
  },
  remove (data) {
    return axios.post("/api/account/menu/remove/" + data.id, Tool.createFormData());
  },
  active (data) {
    return axios.post("/api/account/menu/active/" + data.id, Tool.createFormData());
  },
};
