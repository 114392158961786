<template>
  <div>
    <div
      class="absolute-background"
      :class="_matchAbsoluteBackground(this.id)"
    ></div>
    <div class="main-content">
      <div class="icon-container">
        <b-img :src="icon.src" :alt="icon.alt"></b-img>
      </div>
      <h4>{{ heading }}</h4>
      <p>{{ description }}</p>
      <a v-if="link" :href="link" class="link"
        >En savoir plus<span class="fas fa-arrow-right"></span
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeatureSmallBlock",
  props: ["id", "icon", "heading", "description", "link"],
  methods: {
    /*
     * Match the corresponding absolute background thanks to the block id
     */
    _matchAbsoluteBackground(id) {
      switch (id) {
        case 0:
          return "first-background";
          break;
        case 1:
          return "second-background";
          break;
        case 2:
          return "third-background";
          break;
        default:
          return "first-background";
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
/* Block content image and text */
.main-content {
  position: relative;
  z-index: 1;
}
.icon-container {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
img {
  margin-top: 1rem;
  width: 90px;
  height: auto;
}
.main-content h4 {
  margin-top: 2.5rem!important;
  margin-bottom: 1rem!important;
}
.link {
  font-weight: 500;
}
.fa-arrow-right {
  margin-left: 1rem;
}

/* Absolutes grey backgrounds */
.absolute-background {
  position: absolute;
  z-index: 0;
  background-color: #e9e9e9;
}
.first-background {
  top: 0;
  left: 15%;
  width: 45%;
  height: 95%;
}
.second-background {
  top: 20%;
  left: 10%;
  width: 80%;
  height: 50%;
}
.third-background {
  top: 15%;
  left: 36%;
  width: 45%;
  height: 55%;
}
</style>
