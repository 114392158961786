import Vue from "vue";
import AccountWinePairingAPI from "../api/account_wine_pairing";

const FETCH_ALL = "FETCH_ALL",
  FETCH_ALL_SUCCESS = "FETCH_ALL_SUCCESS",
  FETCH_ALL_ERROR = "FETCH_ALL_ERROR",
  
  CREATING = "CREATING",
  CREATING_SUCCESS = "CREATING_SUCCESS",
  CREATING_ERROR = "CREATING_ERROR",
  
  UPDATING = "UPDATING",
  UPDATING_SUCCESS = "UPDATING_SUCCESS",
  UPDATING_ERROR = "UPDATING_ERROR",
  
  REMOVING = "REMOVING",
  REMOVING_SUCCESS = "REMOVING_SUCCESS",
  REMOVING_ERROR = "REMOVING_ERROR",
  
  UPDATE_STORE = "UPDATE_STORE",
  RESET_CACHE = "RESET_CACHE";

export default {
  namespaced: true,
  state: {
    isCached: false,
    isLoading: false,
    isLoadingAction: false,
    error: null,
    winePairings: [],
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    isLoadingAction(state) {
      return state.isLoadingAction;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    hasWinePairings(state) {
      return state.winePairings.length > 0;
    },
    winePairings(state) {
      return state.winePairings;
    },
  },
  mutations: {
    // Fetch All
    [FETCH_ALL](state) {
      state.isLoading = true;
      state.error = null;
      state.winePairings = [];
    },
    [FETCH_ALL_SUCCESS](state, winePairings) {
      state.isLoading = false;
      state.error = null;
      state.winePairings = winePairings;
      state.isCached = true;
    },
    [FETCH_ALL_ERROR](state, error) {
      state.isLoading = false;
      state.error = error;
      state.winePairings = [];
    },
    // Creating
    [CREATING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [CREATING_SUCCESS](state, winePairing) {
      state.isLoadingAction = false;
      state.error = null;
      // state.winePairings.push(winePairing);
    },
    [CREATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Updating
    [UPDATING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [UPDATING_SUCCESS](state, winePairing) {
      state.isLoadingAction = false;
      state.error = null;
      // let winePairings = state.winePairings.filter(element => element.id !== winePairing.id);
      // winePairings.push(winePairing);
      // state.winePairings = winePairings;
    },
    [UPDATING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    // Removing
    [REMOVING](state) {
      state.isLoadingAction = true;
      state.error = null;
    },
    [REMOVING_SUCCESS](state, winePairing) {
      state.isLoadingAction = false;
      state.error = null;
      // state.winePairings = state.winePairings.filter(
      //   (element) => element.id !== winePairing.id
      // );
    },
    [REMOVING_ERROR](state, error) {
      state.isLoadingAction = false;
      state.error = error;
    },
    [UPDATE_STORE] (state, pairings) {
      let newPairings = [...state.winePairings];
      for (let i = 0; i < pairings.length; i++) {
        switch (pairings[i].operationType) {
          case 'CREATE':
            let pairingToCreate = pairings[i];
            delete pairingToCreate.operationType;
            newPairings.push(pairingToCreate);
            break;
          case 'UPDATE':
            newPairings = newPairings.filter(element => element.id != parseInt(pairings[i].id));
            let pairingToUpdate = pairings[i];
            delete pairingToUpdate.operationType;
            newPairings.push(pairingToUpdate);
            break;
          case 'REMOVE':
            newPairings = newPairings.filter(element => element.id != parseInt(pairings[i].id));
            break;
        }
      }
      state.winePairings = newPairings;
    },
    [RESET_CACHE] (state) {
      state.isCached = false;
      state.winePairings = [];
    },
  },
  actions: {
    async findAll({ commit }) {
      if (!this.state.accountWinePairing.isCached) {
        commit(FETCH_ALL);
        try {
          let response = await AccountWinePairingAPI.findAll();
          if (response.data.success === true) {
            commit(FETCH_ALL_SUCCESS, response.data.winePairings);
            return response.data.winePairings;
          } else {
            commit(FETCH_ALL_ERROR);
            return null;
          }
        } catch (error) {
          commit(FETCH_ALL_ERROR);
          return null;
        }
      }
    },
    async create({ commit }, payload) {
      commit(CREATING);
      try {
        let response = await AccountWinePairingAPI.create(payload.form);
        if (response.data.success === true) {
          commit(CREATING_SUCCESS, response.data.winePairing);
          return response.data;
        } else {
          commit(CREATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATING_ERROR, error);
        return null;
      }
    },
    async update({ commit }, payload) {
      commit(UPDATING);
      try {
        let response = await AccountWinePairingAPI.update(payload.form);
        if (response.data.success === true) {
          commit(UPDATING_SUCCESS, payload.form);
          return response.data;
        } else {
          commit(UPDATING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(UPDATING_ERROR, error);
        return null;
      }
    },
    async remove({ commit }, payload) {
      commit(REMOVING);
      try {
        let response = await AccountWinePairingAPI.remove(payload.winePairing);
        if (response.data.success === true) {
          commit(REMOVING_SUCCESS, payload.winePairing);
          return null;
        } else {
          commit(REMOVING_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(REMOVING_ERROR, error);
        return null;
      }
    },
    async updateStore({ commit }, payload) {
      commit(UPDATE_STORE, payload.pairings);
    },
    async resetCache({ commit }) {
      commit(RESET_CACHE);
    },
  },
};
