<template>
  <b-row align-v="center" class="loading-container">
    <b-col cols="12" align-self="center" class="text-center">
      <b-spinner :label="$t('workspace.global.loading')"></b-spinner>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  flex: 1;
}
</style>
