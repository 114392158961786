import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  // Folder
  findAllFolder () {
    return axios.post("/api/finder/folder", Tool.createFormData());
  },
  createFolder (data) {
    return axios.post("/api/finder/folder/create", Tool.createFormData(data));
  },
  updateFolder (data) {
    return axios.post("/api/finder/folder/update/" + data.id, Tool.createFormData(data));
  },
  removeFolder (data) {
    return axios.post("/api/finder/folder/remove/" + data.id, Tool.createFormData());
  },
  // File
  findAllFile () {
    return axios.post("/api/finder/document", Tool.createFormData());
  },
  createFile (data) {
    return axios.post("/api/finder/document/create",
      Tool.createFormData(data.form),
      {
        onUploadProgress: function (progressEvent) {
          data.store.dispatch('finder/setUploadProgress', parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)))
        }.bind(this)
      })
  },
  updateFile (data) {
    return axios.post("/api/finder/document/update/" + data.id, Tool.createFormData(data));
  },
  updateFileFolder (data) {
    return axios.post("/api/finder/document/update/folder/" + data.id, Tool.createFormData(data));
  },
  removeFile (data) {
    return axios.post("/api/finder/document/remove/" + data.id, Tool.createFormData());
  },
};
