import axios from "axios";

export default {
  /**
   * API call that retrives institution's overview (basic restaurant and products infos)
   * Basically all dishes, wines, beers... name, price, description and image. In order to fill the HomePage
   * @param {int} institutionId - id of the institution which we want to retrieve the data
   * @param {string} locale  - the translation language (tag format: 'fr', 'en', etc.)
   * @returns {Object} - Institution data (overview only as explain previously)
   */
  findInstitutionOverview(institutionId, language) {
    return axios.post("/api/mobile/app/" + language + "/institution/overview/" + institutionId);
  },
};
