<template>
  <b-form-group
    :id="id + '-input-group'"
    :label="name"
    :label-for="id"
    :label-class="inputRequired ? 'required' : ''"
  >
    <b-form-textarea
      :id="id"
      :name="id"
      v-model="inputValue"
      :placeholder="placeholder"
      :rows="rows"
      :max-rows="maxRows"
      :class="{ 'is-invalid': errorMessage }"
    ></b-form-textarea>
    <b-form-text :id="id + '-help-block'">
      {{ help }}
    </b-form-text>
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ errorMessage }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { useField } from "vee-validate";

export default {
  name: "FormTextarea",
  compatConfig: {
    COMPONENT_V_MODEL: false,
  },
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    help: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    rows: {
      type: String,
    },
    maxRows: {
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    modelValue: {
      type: String,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { value: inputValue, errorMessage } = useField(
      "formTextarea-" + props.id,
      props.rules,
      {
        label: props.name,
        syncVModel: true,
        validateOnMount: false,
        validateOnValueUpdate: false,
      }
    );

    return {
      inputValue,
      errorMessage,
    };
  },
  computed: {
    inputRequired() {
      if (typeof this.rules["required"] !== "undefined") {
        if (this.rules["required"] === true) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
