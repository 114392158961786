// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formats-container .format-container[data-v-75045752] {
  width: calc(25% - 9px);
}
@media (max-width: 992px) {
.formats-container .format-container[data-v-75045752] {
    width: calc(50% - 9px);
}
}
.formats-container .format-container .format-illustration[data-v-75045752] {
  width: auto;
  height: 100px;
  margin-bottom: 1rem;
  max-width: 85%;
  object-fit: contain;
}
.formats-container .illustrated-format-container[data-v-75045752] {
  padding: 0.8rem 0.5rem !important;
}
.formats-container .show-more[data-v-75045752] {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
}
.formats-container .show-more[data-v-75045752]:hover {
  color: #0c1218;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/ProductPage/CustomizableFormat.vue","webpack://./CustomizableFormat.vue"],"names":[],"mappings":"AAME;EACE,sBAAA;ACLJ;ADOI;AAHF;IAII,sBAAA;ACJJ;AACF;ADMI;EACE,WAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;ACJN;ADQE;EACE,iCAAA;ACNJ;ADSE;EACE,aAAA;EACA,yBAAA;EACA,WAAA;EACA,kBAAA;EACA,0BAAA;EACA,eAAA;ACPJ;ADSI;EACE,cAAA;ACPN","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.formats-container {\n  .format-container {\n    width: calc(25% - 9px);\n\n    @media (max-width: 992px) {\n      width: calc(50% - 9px);\n    }\n\n    .format-illustration {\n      width: auto;\n      height: 100px;\n      margin-bottom: 1rem;\n      max-width: 85%;\n      object-fit: contain;\n    }\n  }\n\n  .illustrated-format-container {\n    padding: 0.8rem 0.5rem!important;\n  }\n\n  .show-more {\n    display: flex;\n    justify-content: flex-end;\n    width: 100%;\n    margin-top: 0.5rem;\n    text-decoration: underline;\n    cursor: pointer;\n    \n    &:hover {\n      color: color.adjust($mizogoo-gray-dark, $lightness: -5%);\n    }\n  }\n}\n",".formats-container .format-container {\n  width: calc(25% - 9px);\n}\n@media (max-width: 992px) {\n  .formats-container .format-container {\n    width: calc(50% - 9px);\n  }\n}\n.formats-container .format-container .format-illustration {\n  width: auto;\n  height: 100px;\n  margin-bottom: 1rem;\n  max-width: 85%;\n  object-fit: contain;\n}\n.formats-container .illustrated-format-container {\n  padding: 0.8rem 0.5rem !important;\n}\n.formats-container .show-more {\n  display: flex;\n  justify-content: flex-end;\n  width: 100%;\n  margin-top: 0.5rem;\n  text-decoration: underline;\n  cursor: pointer;\n}\n.formats-container .show-more:hover {\n  color: #0c1218;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
