// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-container[data-v-7c4fbb7e] {
  min-height: 50vh;
  width: 100%;
}
.product-container .loading[data-v-7c4fbb7e] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
}
[data-v-7c4fbb7e] ul,[data-v-7c4fbb7e] ol {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: revert;
  list-style: revert;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/ProductPage/Index.vue","webpack://./Index.vue"],"names":[],"mappings":"AAKA;EACE,gBAAA;EACA,WAAA;ACJF;ADME;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;ACJJ;ADQA;EAEE,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,kBAAA;ACLF","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.product-container {\n  min-height: 50vh;\n  width: 100%;\n\n  .loading {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    margin: auto;\n  }\n}\n\n:deep(ul),\n:deep(ol) {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  padding: revert;\n  list-style: revert;\n}\n",".product-container {\n  min-height: 50vh;\n  width: 100%;\n}\n.product-container .loading {\n  position: absolute;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  margin: auto;\n}\n\n:deep(ul),\n:deep(ol) {\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n  padding: revert;\n  list-style: revert;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
