// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-logos[data-v-709c7d7e] {
  display: flex;
}
.cards-logos i[data-v-709c7d7e] {
  font-size: 3rem;
  margin: 1rem 0.5rem;
  opacity: 0.6;
}
.cards-logos i[data-v-709c7d7e]:first-child {
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./assets/mizogoo/components/front/pages/CartPage/Command.vue","webpack://./Command.vue"],"names":[],"mappings":"AAKA;EACE,aAAA;ACJF;ADME;EACE,eAAA;EACA,mBAAA;EACA,YAAA;ACJJ;ADMI;EACE,cAAA;ACJN","sourcesContent":["\n            @use 'sass:color';\n            @import \"@mizogoo/assets/css/_variables.scss\";\n          \n\n.cards-logos {\n  display: flex;\n\n  i {\n    font-size: 3rem;\n    margin: 1rem 0.5rem;\n    opacity: 0.6;\n\n    &:first-child {\n      margin-left: 0;\n    }\n  }\n}\n",".cards-logos {\n  display: flex;\n}\n.cards-logos i {\n  font-size: 3rem;\n  margin: 1rem 0.5rem;\n  opacity: 0.6;\n}\n.cards-logos i:first-child {\n  margin-left: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
