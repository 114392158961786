import ContactFormApi from "../api/contact_form";

const CREATE_CONTACT_FROM = "CREATE_CONTACT_FROM",
  CREATE_CONTACT_FROM_SUCCESS = "CREATE_CONTACT_FROM_SUCCESS",
  CREATE_CONTACT_FROM_ERROR = "CREATE_CONTACT_FROM_ERROR";

export default {
  namespaced: true,
  state: {
    isLoadingContactForm: false,
    errorContactForm: null,
  },
  getters: {
    isLoadingContactForm(state) {
      return state.isLoadingContactForm;
    },
    hasErrorContactForm(state) {
      return state.errorContactForm !== null;
    },
    errorContactForm(state) {
      return state.errorContactForm;
    },
  },
  mutations: {
    [CREATE_CONTACT_FROM](state) {
      state.isLoadingContactForm = true;
      state.errorContactForm = null;
    },
    [CREATE_CONTACT_FROM_SUCCESS](state) {
      state.isLoadingContactForm = false;
      state.errorContactForm = null;
    },
    [CREATE_CONTACT_FROM_ERROR](state, error) {
      state.isLoadingContactForm = false;
      state.errorContactForm = error;
    },
  },
  actions: {
    async createContactForm({ commit }, payload) {
      commit(CREATE_CONTACT_FROM);
      try {
        let response = await ContactFormApi.create(payload.form);
        if (response.data.success === true) {
          commit(CREATE_CONTACT_FROM_SUCCESS);
          return response.data;
        } else {
          commit(CREATE_CONTACT_FROM_ERROR, response.data.message);
          return null;
        }
      } catch (error) {
        commit(CREATE_CONTACT_FROM_ERROR, error);
        return null;
      }
    },
  },
};
