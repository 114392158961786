import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAllWithPagination(data) {
    return axios.post("/api/article", Tool.createFormData(data));
  },
  findOne(article) {
    return axios.post("/api/article/" + article, Tool.createFormData());
  },
  findTwoLast() {
    return axios.post("/api/article/last", Tool.createFormData());
      
  },
};
