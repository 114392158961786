import axios from "axios";
import Tool from '@mizogoo/class/tool'

export default {
  findAll () {
    return axios.post("/api/grape", Tool.createFormData());
  },
  create(data) {
    return axios.post("/api/grape/create", Tool.createFormData(data));
  },
  update(data) {
    return axios.post("/api/grape/update/" + data.id, Tool.createFormData(data));
  },
  remove(data) {
    return axios.post("/api/grape/remove/" + data, Tool.createFormData());
  },
};
